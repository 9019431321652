import { useEffect, useState } from "react";
import { Contract, CreateContractBody } from "../../models/Contract.models";
import { DataGroup } from "../../models/DataGroup.models";
import useContractState from "../../store/contractState";
import { replaceAll } from "../../lib/utils";
import DynamicForm from "./DynamicForm";
import useBiddingState from "src/store/biddings/biddingState";

type Props = {
    action: "create" | "edit";
    preLoadedDataGroups?: any[];
    transformNumberWithPattern?: any;
    transformDateWithPattern?: any;
};

const ContractDataGroupsForms = ({ action, preLoadedDataGroups, transformNumberWithPattern, transformDateWithPattern }: Props) => {
    //selecciono state dinamicamente segun el action
    const currentContract = useContractState((s) => (action === "create" ? s.currentNewContract : s.selectedContract));
    const setCurrentContract = useContractState((s) => (action === "create" ? s.setCurrentNewContract : s.setSelectedContract));

    const [populatedDataGroups, setPopulatedDataGroups] = useState<Partial<any>[]>(null);
    const [populatedDataGroupsCopy, setPopulatedDataGroupsCopy] = useState<Partial<any>[]>(null);
    const [populatedText, setPopulatedText] = useState<string>(null);
    

    function handleContractText() {
        let auxText = currentContract?.template?.text;
      //  console.log("current text",auxText)
        populatedDataGroups?.forEach((dg) => {
            dg.types.forEach((dt) => {
                if (dt.value) {
                    if (!dt.value || /^\s*$/.test(dt.value)) {
                        auxText = auxText.replaceAll(`{{${dt.idName}}}`, `{{${dt.idName}}}`);
                    } else {
                        auxText = replaceAll(
                            auxText,
                            `<span><span class="__se__comodin" contenteditable="false"[^>]*>{{${dt.idName}}}</span></span>`,
                            dt.value
                        );
                        auxText = replaceAll(
                            auxText,
                            `<span class="__se__comodin" contenteditable="false"[^>]*>{{${dt.idName}}}</span>`,
                            dt.value
                        );
                    }
                }
            });
        });
        setPopulatedText(auxText);
    }

    useEffect(() => {
        if (currentContract?.template) {
            const exsistentDataGroups = preLoadedDataGroups?.filter((dg) =>
                currentContract?.template?.dataGroup?.find((dg2) => dg2.idName === dg.idName)
            );
            setPopulatedDataGroups(exsistentDataGroups ? exsistentDataGroups : currentContract.template.dataGroup);
            setPopulatedDataGroupsCopy(exsistentDataGroups ? exsistentDataGroups : currentContract.template.dataGroup);
        }
    }, [currentContract?.template]);


    useEffect(() => {
        handleContractText();
    }, [populatedDataGroups]);

    useEffect(() => {
        setCurrentContract({
            ...currentContract,
            text: populatedText,
            auxDataGroups: populatedDataGroups,
        } as Partial<CreateContractBody> & Contract);
    }, [populatedText]);

    return (
        <div className="w-full mt-8">
            <div className="md:masonry-2-col space-y-4">
                {populatedDataGroups?.map((dg, idx) => (
                    <div key={dg.idName} className="w-full md:w-auto md:break-inside p-2">
                        <h3 className="text-xl font-medium text-dark leading-none w-full">{/*dg.name*/}</h3>
                        <DynamicForm
                            currentContract={currentContract}
                            dataGroup={{ ...dg }}
                            dataGroupIdx={idx}
                            setPopulatedDataGroups={setPopulatedDataGroups}
                            setPopulatedDataGroupsCopy={setPopulatedDataGroupsCopy}
                            action={action}
                            transformNumberWithPattern1={transformNumberWithPattern}
                            transformDateWithPattern1={transformDateWithPattern}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContractDataGroupsForms;
