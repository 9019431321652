import { Button } from "@chakra-ui/button";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { SimpleAlertModalData } from "../../../models/Modals.models";
import CommonModal from "../CommonModal";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: SimpleAlertModalData;
};

const SimpleAlertModal = ({ isOpen, onClose, data }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];

    const parsedText1 = data?.whitTranslation ? t(data?.text1 as any) : data?.text1;
    const parsedText2 = data?.whitTranslation ? t(data?.text2 as any) : data?.text2;

    function handleClick() {
        onClose();
    }


    return (
        <CommonModal size="sm" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-2">
                <div className="leading-none">
                    <h3 className="text-left title-4">{parsedText1}</h3>
                    <p className="text-dark-2 text-lg mt-2">{parsedText2}</p>
                </div>

                <div className="mt-10">
                    <Button rounded="full" size="md" fontWeight="medium" px={"2rem"} onClick={handleClick}>
                        Ok
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default SimpleAlertModal;
