import { Icon } from "@chakra-ui/react";

const UserOutlineIcon = (props) => {
    return (
        <Icon viewBox="0 0 73 73" {...props}>
            <defs>
                <style>{`.cls-1f34f3f{fill:none}.cls-2dsad4{fill:${props.color ?? "inherit"}}`}</style>
            </defs>
            <g id="Capa_1-2">
                <path
                    className="cls-1f34f3f"
                    d="M36.49 47.46c-9.69 0-17.59 7.67-18.02 17.25C23.68 68.05 29.87 70 36.5 70s12.8-1.94 18.01-5.27c-.42-9.59-8.33-17.27-18.02-17.27Z"
                />
                <path
                    className="cls-1f34f3f"
                    d="M36.5 3C18.03 3 3 18.03 3 36.5c0 10.59 4.94 20.04 12.64 26.18 1.39-10.27 10.2-18.23 20.85-18.23s19.47 7.96 20.85 18.24C65.05 56.55 70 47.09 70 36.49c0-18.47-15.03-33.5-33.5-33.5Zm-.01 38.2c-6.29 0-11.41-5.12-11.41-11.41s5.12-11.41 11.41-11.41S47.9 23.5 47.9 29.79 42.78 41.2 36.49 41.2Z"
                />
                <circle className="cls-1f34f3f" cx={36.49} cy={29.79} r={8.41} />
                <path
                    className="cls-2dsad4"
                    d="M36.5 0C16.37 0 0 16.37 0 36.5 0 49 6.32 60.05 15.92 66.63c.1.09.21.17.34.23C22.06 70.74 29.02 73 36.5 73s14.55-2.3 20.38-6.23h.02c9.71-6.58 16.11-17.69 16.11-30.27C73 16.37 56.63 0 36.5 0ZM18.47 64.71c.43-9.58 8.33-17.25 18.02-17.25s17.6 7.68 18.02 17.27C49.3 68.06 43.13 70 36.5 70s-12.82-1.95-18.03-5.29Zm38.87-2.01c-1.38-10.28-10.19-18.24-20.85-18.24s-19.46 7.95-20.85 18.23C7.94 56.55 3 47.1 3 36.51 3 18.03 18.03 3 36.5 3S70 18.03 70 36.5c0 10.6-4.95 20.06-12.66 26.2Z"
                />
                <path
                    className="cls-2dsad4"
                    d="M36.49 18.38c-6.29 0-11.41 5.12-11.41 11.41S30.2 41.2 36.49 41.2 47.9 36.08 47.9 29.79s-5.12-11.41-11.41-11.41Zm0 19.82c-4.64 0-8.41-3.77-8.41-8.41s3.77-8.41 8.41-8.41 8.41 3.77 8.41 8.41-3.77 8.41-8.41 8.41Z"
                />
            </g>
        </Icon>
    );
};

export default UserOutlineIcon;
