import { Tooltip } from "@chakra-ui/react";

const en = {
    global: {
        //words
        new: "New",
        create: "Create",
        userUpdatingError: "User updating error",
        userUpdatedSuccessfully: "User updated successfully",
        external: "External",
        internal: "Internal",
        editUser: "Edit User",
        uploadRequirement: "Upload",
        documentType: "Document Type",
        loadingDocument: "Loading document",
        max120Chars: "Max 120 characters",
        deletedBiddingTypeError: "Error deleting bidding type",
        deletedBiddingTypeSuccess: "Deleted bidding type successfully",
        biddingTypeWillBeDeleted: "You are about to delete the bidding type",
        biddingWillBeDeleted: "You are about to delete the bidding",
        documentSet: "Document Set",
        backToBiddings: "Back to biddings",
        generateBiddingType: "New bidding type",
        myBiddingTypes: "My bidding types",
        listingBiddingsTypesError: "Listing biddings types error",
        beginDateGreaterThanEndDate: "Begin date must be less than end date",
        creatingBiddingError: "Creating bidding error",
        biddingCreatedSuccessfully: "Bidding created successfully",
        listingBiddingTypesError: "Listing bidding types error",
        listingBiddingsError: "Listing biddings error",
        deletedBiddingError: "Error deleting bidding",
        deletedBiddingSuccess: "Deleted bidding successfully",
        myBiddings: "My Biddings",
        biddingTypes: "Bidding Types",
        generateBidding: "New Bidding",
        fileAlreadyExists: "File already exists",
        biddings: "Biddings",
        saveChangesBeforeRevision: "Save changes before revision",
        uploadDocxFile: "Upload docx file",
        createTemplateFromDocx: "Create template from docx file",
        invalidDocxFile: "Invalid docx file",
        sessionNotFoundDescription: "The session you are trying to access does not exist or has expired.",
        sessionNotFound: "Session not found",
        sessionCode: "Session Code",
        joinToSession: "Join to session",
        copy: "Copy",
        createSession: "Create Session",
        videoConference: "Video Conference",
        copiedToClipboard: "Copied to clipboard",
        contractAlreadyVerified: "Contract already verified",
        onlyPdfFiles: "Only PDF files are allowed",
        emailAlreadyRegistered: "E-mail already registered",
        inputAnotherEmail: "Please enter another email",
        headerAndFooter: "Header and Footer",
        here: "here",
        downloadExcelFile: "Download Excel File",
        uploadExcelFile: "Upload Excel File",
        contractsBulkLoad: "Bulk upload of contracts",
        invalidFileType: "Invalid file type",
        clonedContractSuccess: "Cloned contract successfully",
        clonedContractError: "Error cloning contract",
        clonedBiddingTypeSuccess: "Cloned Bidding Type successfully",
        clonedBiddingTypeSError: "Error cloning Bidding Type",
        clonedTemplateSuccess: "Cloned template successfully",
        clonedTemplateError: "Error cloning template",
        sessionExpired: "Session expired, please login again",
        invalidPage: "Invalid page",
        page: "Page",
        finish: "Finish",
        thereAreStillLabelsToPosition: "There are still labels to position",
        finishTagsPositioning: "Finish tags positioning",
        newSignatureGenerated: "New signature generated",
        addSignatureFor: "Add signature for",
        newSignatureQuestion: "New signature?",
        signaturePositionGenerated: "Signature position generated successfully",
        onceSavedCannotBeChanged: "Once saved, this cannot be changed.",
        areYouSure: "Are you sure?",
        userHasNotGeneratedTag: "This user has no tag generated",
        savePosition: "Save position",
        addSignature: "Add Signature",
        generateSignature: "New Signature",
        atLeastOneSignerRequired: "At least one signer is required",
        requiredCommentWhenRejecting: "Required comment when not approving",
        fetchingContractError: "Fetching contract error",
        anErrorOcurred: "An error occurred",
        document: "Document",
        markAsRead: "Mark as read",
        addAnnex: "Add annex",
        uploadedFileSuccessfully: "Uploaded file successfully",
        mustUploadFile: "You must upload a file",
        fetchingSignaturesUsersError: "Fetching signatures users error",
        addType: "Add Type",
        addNewType: "Add new type",
        contractTypes: "Contract Types",
        manageContractTypes: "Manage Contract Types",
        typeAlreadyExists: "Type already exists",
        positionSignatures: "Position signatures",
        noSignersSelectedYet: "No signers selected yet",
        selectSigners: "Select signers",
        atLeastOneRevisorRequired: "At least one revisor is required",
        noReviewerSelectedYet: "No reviewer selected yet",
        newRevision: "New revision",
        fetchingRevisionUsersError: "Fetching revision users error",
        revisionCreatedSuccessfully: "Revision process created successfully",
        revisionCreationError: "Revision process creation error",
        revisor: "Revisor",
        dataGroupWillBeDeleted: "You are about to delete the data group",
        selectOneOrManyDataGroups: "Select one or many data groups",
        templateWillBeDeleted: "You are about to delete the template",
        statistics: "Statistics",
        editProfile: "Edit Profile",
        changeImage: "Change Image",
        createdDataTypeSuccessfully: "Created data type successfully",
        creatingDataTypeError: "Creating data type error",
        newDocument: "New Document",
        uploadingDocumentError: "Uploading document error",
        uploadedDcoumentSuccessfully: "Uploaded document successfully",
        invalidExtension: "Invalid extension",
        fileRequired: "File is required",
        newFolder: "New Folder",
        createdFolderSuccessfully: "Created folder successfully",
        selectFile: "Select File",
        dragFileHereToUpload: "Drag file here to upload it",
        onlyOneFilePerTimeCanBeUploaded: "Only one file per time can be uploaded",
        profileImage: "Profile Image",
        username: "Username",
        userCreatingError: "User creating error",
        onlyImagesAllowed: "Only images are allowed",
        leastOneRoleRequired: "At least one role is required",
        leastOneNameRequired: "At least one name is required",
        leastOneDescriptionRequired: "At least one description is required",
        invalidEmail: "Invalid email",
        accept: "Accept",
        doYouAgree: "Do you agree?",
        addNewUser: "New user",
        addNewUserGroup: "Create new users group",
        userWillBeDeleted: "You are about to delete the user",
        userGroupWillBeDeleted: "You are about to delete the users group",
        users: "Users",
        usersGroup: "Users Groups",
        tokenize: "Tokenize",
        cancelSignatures: "Cancel signatures",
        backToEdit: "Back to edit",
        applyChanges: "Apply changes",
        changedStateTo: "changed state to",
        showOnEtherScan: "Show on Etherscan",
        contractToken: "Contract Token",
        contractName: "Contract Name",
        tokenizedContractSuccessfully: "Tokenized contract successfully",
        tokenizingContractFailed: "Tokenizing contract error",
        canceledSignaturesSuccessfully: "Canceled signatures successfully",
        cancelingSignaturesError: "Error canceling signatures",
        sendingSignaturesError: "Sending signatures error",
        signaturesSendedSuccessfully: "Signatures sended successfully",
        cancelingRevisionError: "Canceling revision process error",
        revisionCanceled: "Revision process canceled",
        noTypeToThisGroup: "No types added to this group",
        general: "General",
        savingContractError: "Error saving contract",
        contractSavedSuccessfully: "Contract saved successfully",
        templateRequired: "Template is required",
        errorWhileGeneratingPreview: "Error while generating preview",
        fetchingDataGroupsError: "Fetching data groups error",
        updatedDataGroupError: "Error updating data group",
        typeWillBeDeleted: "You are about to delete the type",
        addNewDataType: "Add new data type",
        fetchingDataTypesError: "Faile to fetch data types",
        leastOneDataTypeRequired: "At least one data type is required",
        editDataGroups: "Edit data groups",
        signatures: "Signatures",
        revisions: "Revisions",
        generic: "Generic",
        myDataGroups: "My Data Groups",
        backToTemplates: "Back to templates",
        activationDate: "Activation date",
        editTemplate: "Edit Template",
        activatedTemplate: "Activated Template",
        loadData: "Load data",
        noDataGroupSelected: "No data group selected",
        addNew: "Add new",
        fetchingContractTypesError: "Failed to fetch contract types",
        thereIsNoContentToPreview: "There is no content to preview",
        templateActivatedSuccessfully: "Template activated successfully",
        templateActivatedFailed: "Template activation failed",
        templateSavedSuccessfully: "Template saved successfully",
        templateSavedFailed: "Template saved failed",
        templateCreatedSuccessfully: "Template created successfully",
        templateCreationFailed: "Template creation failed",
        showAll: "Show All",
        documents: "Documents",
        folders: "Folders",
        size: "Size",
        extension: "Extension",
        listingContractsError: "Error listing contracts",
        all: "All",
        borrador: "Draft",
        revision: "Revision",
        aprobado: "Approved",
        rechazado: "Rejected",
        "en boveda": "In Vault",
        "pendiente de firmas": "Pending Signatures",
        firmado: "Signed",
        tokenizado: "Tokenized",
        myContracts: "My Contracts",
        selectAnActiveTemplate: "Select an active template",
        activo: "Active",
        warning: "Warning",
        contractTypeRequired: "Contract type is required",
        search: "Search",
        filter: "Filter",
        notElementsFound: "No elements found",
        myTemplates: "My Templates",
        cleanFilters: "Clean Filters",
        creation: "Creation",
        lastVersion: "Last version",
        actions: "Actions",
        goTo: "Go to",
        alreadyRegistered: "Already registered?",
        notAccount: "I don't have an account",
        register: "Register",
        createUserGroup: "Assign your users to a group",
        newUserGroup: "New user group",
        editUserGroup: "Edit user group",
        activeGroups: "Active",
        selectUser: "User",
        //texts
        assignYourUserGroup: "Select the groups you want to add them to a user",
        youWillBeRedirectedBPM: "You will be redirected to BPMN automatically, if you are not redirected click",
        smartContractMaker: "Agile contract and security titles manager",
        newTemplateText:
            "A template is a file format baseline that allows the user to create the content of the contract, this includes clauses and variables, in an organized and versioned way.",
        activatedTemplateText:
            "The active template, allows you to edit the body of the template keeping name, type and data groups locked.",
        editTemplateText:
            "Modify your template until you reach the desired version, this includes data groups, name of the template and type.",
        editDataGroupsText:
            "Adjust the data groups as you need them, the type of variable is very important for tokenization purposes, it also allows the user to ease up while filling information on next steps.",
        createDataGroupText: "Create your data groups according to your documents needs, group the variables neatly for each group.",
        createContractText:
            "Create a contract with an active template in your archive, fill in carefully the information needed for revision.",
        revisionText: "Review the document thoroughly for acceptance or rejection. In case of rejection, share the reasons below.",
        signaturesText: "Sign your contracts legitimately and in a safe environment.",
        vaultText: "Unerrring place for safekeeping your contracts and documents, based on blockchain technology.",
        administrationText: "Register your users, check platform roles, change language or cutomize your profile name.",
        newRevisionText: "Add the stakeholders who are going to participate in the review as well as an order of participation.",
        newSignerText: "Add the stakeholders who will participate in the signing process.",

        //v1
        login: "Log in",
        logout: "Log out",
        welcome: "We welcome you",
        history: "History",
        generate: "Generate",
        contract: "Contract",
        contracts: "Contracts XD",
        dynamicDocs: "Dynamic documents",
        templates: "Templates",
        active: "Active",
        pending: "Pending",
        information: "Information",
        traceability: "Traceability",
        smartContract: "Smart contract",
        name: "Name",
        author: "Author",
        status: "Status",
        undefined: "Undefined",
        draft: "Draft",
        creationDate: "Creation date",
        completeName: "Complete name",
        nationality: "Nationality",
        street: "street",
        edition: "Edition",
        preview: "Preview",
        leasing: "Leasing", //arendamiento
        escrow: "Escrow", //fideicomiso
        saveChanges: "Save changes",
        addDataGroup: "Add Data Group",
        add: "Add",
        select: "Select",
        save: "Save",
        cancel: "Cancel",
        publish: "Publish",
        group: "Group",
        remove: "Remove",
        delete: "Delete",
        newDataType: "New data type",
        generateDataType: "New data type",
        generateNewContract: "New contract",
        // generateContract: "Start",
        generateContract: "Start",
        generatedContracts: "Generated contracts",
        contractsByStatus: "Contracts by status",
        top5contracts: "Top 5 contracts by type",
        generalIndicators: "General indicators",
        contractsTypes: "Contracts types",
        contractsFinishedIn: "Contracts finished in",
        contractsCanceledOrNotNegotiable: "Canceled or non-negotiable",
        selectATraceabilityEventForDetails: "Select A Traceability Event For Details",
        selectDataGroup: "Select data group",
        searchDocumnets: "Search documents",
        selectCompany: "Select a company",
        companies: "Companies",
        addDocument: "Add document",
        addFolder: "Add folder",
        details: "Details",
        activity: "Activity",
        noInformation: "There is no information",
        noElementSelected: "There is no item selected",
        uploadedBy: "Uploaded by",
        file: "File",
        description: "Description",
        address: "Address",
        lastName: "Last name",
        date: "Date",
        day: "Day",
        month: "Month",
        year: "Year",
        state: "State",
        text: "Text",
        numeric: "Numeric",
        selectable: "Select",
        flag: "Flag",
        home: "Home",
        vault: "Vault",
        notifications: "Notifications",
        dataGroups: "Data groups",
        template: "Template",
        inVault: "In vault",
        type: "Type",
        noAuthor: "No author",
        generated: "Generated",
        noGenerated: "No generated",
        generateTemplate: "New template",
        generateDataGroups: "New data group",
        filterByName: "Filter by name",
        contractsTotal: "Contracts total",
        templatesTotal: "Templates total",
        dataGroupsTotal: "Data groups total",
        groupsTotal: "Groups total",
        totalSelected: "Total selected",
        requiredField: "Required field",
        password: "Password",
        email: "E-mail",
        keepSession: "Keep my session open",
        forgetPassword: "Did you forget your password?",
        resetPass: "Restore password",
        enter: "Enter",
        approvals: "Approvals",
        subscribers: "Subscribers",
        download: "Download",
        sign: "Sign",
        user: "User",
        userName: "Username",
        creationOf: "Creation of",
        loadingFolders: "Loading folders",
        createDocument: "Create document",
        createFolder: "Create folder",
        documentName: "Document name",
        folderName: "Folder name",
        attachFiles: "Attach files",
        dragDocumentHere: "Drag the document here to upload it",
        or: "OR",
        searchInYourFiles: "Search into your files",
        onlySelectOneFile: "You can only select one file",
        upgrade: "Update",
        updatedContract: "Updated contract",
        updatingDataError: "Error updating data",
        pendingSignatures: "Pending signatures",
        success: "Success",
        successFirmamex: "Sent to firmamex and signers were notified by mail",
        signingProcessError: "Error creating signing process",
        fileCreatedAndAssocited: "File created and associated with the contract",
        associateFileError: "Failed to associate file",
        creatingFileError: "Error creating File",
        creatingFolderError: "Error creating Folder",
        dataNameAlreadyExists: "Data name already exists",
        dataGroupCreated: "Data group created",
        creatingDataGroupError: "Failed to create data group",
        dataGroup: "Data group",
        updatedDataGroup: "Updated data group",
        templateCreated: "Template created",
        templateUpdated: "Template updated",
        creatingContractError: "Error creating contract",
        creatingTraceabilityError: "Error creating traceability",
        contractCreatedTraceability: "Contract created with traceability",
        edit: "Edit",
        saveDraft: "Save draft",
        back: "Back",
        templateType: "Template type",
        selectDataType: "Select data type",
        templateName: "Template name",
        process: "Process",
        data: "Data",
        relevantDate: "Relevant date",
        option: "Option",
        postalCode: "Postal code",
        activateTemplate: "Activate template",
        documentSetCreated: "Document set successfully created",
        unexpectedError: "An unexpected error has occurred, please try again!!!",
        language: "Language",
        upload: "Upload",
        updatedFile: "File uploaded successfully",
        uploadingFileError: "File upload error",
        category: "Category",
        approved: "Approved",
        rejected: "Rejected",
        activated: "Activated",
        finalized: "Finalized",
        smartContractRule: "Smart contract rule",
        approvalReminder: "Approval reminder",
        optionalAuditorRejection: "Optional auditor rejection",
        requiredAuditorRejection: "Required auditor rejection",
        optionalAuditorApproval: "Optional auditor approval",
        requiredAuditorApproval: "Required auditor approval",
        requiredAuditorRevision: "Required auditor revision",
        optionalAuditorRevision: "Optional auditor revision",

        donwloadExcelFormat: "Download Excel for mass registration of contracts",
        uploadExcelFormat: "Mass registration of contracts",
        loading: "Loading",

        wantDeleteContract: "¿Do you want to delete the contract?",
        deletedContractSuccess: "Contract deleted successfully",
        deletedContractError: "Failed to delete contract",
        wantDeleteTemplate: "¿Do you want to delete the template?",
        deletedTemplateSuccess: "Template deleted successfully",
        deletedTemplateError: "Failed to delete template",
        wantDeleteDataGroup: "¿Do you want to delete the data group?",
        deletedDataGroupSuccess: "Data group deleted successfully",
        deletedDataGroupError: "Failed to delete data group",
        wantDeleteDataType: "¿Do you want to delete the data type?",
        deletedDataTypeSuccess: "Data type deleted successfully",
        deletedDataTypeError: "Failed to delete data type",

        generateNewContractType: "Generate new contract type",
        contractTypeCreated: "Contract type created",
        creatingContractTypeError: "Error creating contract type",
        deletedContractTypeError: "Failed to delete contract type",
        deletedContractTypeSuccess: "Contract type deleted successfully",

        wantDeleteContractType: "¿Do you want to delete the contract type?",
        generateContractType: "Generate new contract type",
        contractType: "Contract type",

        confirm: "Confirm",
        error: "Error",
        created: "Created",

        dragFilesHere: "Drag and drop files here for upload",
        uploadAnnex: "Upload annex",
        uploadAnnexes: "Upload annexes",
        files: "Files",
        userInfo: "User info",
        fileType: "File type",
        showInVault: "Show in vault",

        selectParticipants: "Select participants",
        submitForReview: "Submit contract for review",
        editor: "Editor",
        order: "Order",
        submit: "Submit",
        invalidField: "Invalid field",
        addExternalUser: "Add external user",
        important: "Important",
        externalUserInfo: "If you want to add an internal user go to 'Admin' section",
        revisionNotificationSended: "Notification has been sent to participants",
        revisionCancelAlert: "You are about to stop the current review process for this contract.",
        cancelRevision: "Cancel revision",
        IMPORTANT: "IMPORTANT",
        revisionScreenAlert: `Validate all the information that is shown, if you wish, attach the comments you consider to
    as of your review (The comments are merely informative, no content of the comments represents their
    response of approval or rejection), by pressing the "Approve" button it is assumed that you have read and
    understood the content of the document, in such a way that it APPROVES it to start the signing process, by pressing the
    "Reject" button assumes that you have read and understood the content of the document, in such a way that it
    REJECT to start the signing process.`,
        youHaveBeenSelectedRevision1: "You have been selected to participate in the contract review process ",
        youHaveBeenSelectedRevision2: "of the company ",
        reject: "Reject",
        comments: "Comments",
        approve: "Approve",
        templateNameExist: "Template name already exists",

        deleteSelectedUserAsk: "Are you sure you want to delete the selected user?",
        newUser: "New user",
        roles: "Roles",
        administration: "Administration",
        dragIamgeHere: "Drag the image here to upload it",
        attachProfilePhoto: "Attach profile photo",
        userCreatedSuccessfully: "User created successfully",
        userAlreadyExists: "User already exists",
        userDeletedSuccessfully: "User deleted successfully",
        deletingUserError: "User could not be deleted",

        userGroupCreatedSuccessfully: "User Group created successfully",
        userGroupCreatingError: "User group could not be deleted",

        genericError: "An error has ocurred",
        phone: "Phone",

        revisionAproved: "Revision has been approved",
        revisionRejected: "Revision has been rejected",
        saveInVault: "Save in vault",
        contractTypeAlredyExists: "Contract type already exists",

        cancelSigns: "Cancel signatures",

        reminder: "Reminder",
        reminderSendSuccess: "Reminder sent successfully",
        reminderSendError: "Failed to send reminder",
        docApproved: "Document approved",
        docRejected: "Document rejected",
        awaitingResponse: "Awaiting response",
        newOnes: "New",
        previousOnes: "Previous",
        withoutNotifications: "There are not notifications",

        revisionTracking: "Revision tracking",
        sendRevision: "Send to revision",
        mustCompleteInfoAndSave: "Must complete info and save changes",
        signatureTracking: "Signature tracking",

        revisionCompleted: "Revision completed",
        signaturesCompleted: "Signatures completed",
        contractAlreadyExists: "Contract with the same name already exists",

        showChanges: "Show changes",
        hideChanges: "Hide changes",

        signers: "Signers",
        open: "Open",
        editor_lang: "en",
        typeFieldRequired: "TYPE field is required",
        nameRequired: "Name is required",
        commentsRequired: "Required comments when revision is rejected",
        withoutType: "Without type",
        requiredDataType: "At least one data type is required",

        nameBidding: "Name Bidding",
        bidding: "Biddings",
        newBidding: "New Bidding",
        typeBidding: "Type of Bidding",
        newTypeBidding: "New Type Bidding",
        editTypeBidding: "Edit Type Bidding",
        myBidding: "My Biddings",
        addNewTypeBiddingStage: "Add new bidding stage",
        editTypeBiddingStage: "Edit bidding stage",
        deleteTypeBiddingStage: "Delete bidding stage",
        addNewTypeBiddingGroup: "Add new bidding group",
        editTypeBiddingGroup: "Edit bidding group",
        deleteTypeBiddingGroup: "Delete bidding group",
        addNewTypeBiddingRequirement: "Add new bidding requirement",
        editTypeBiddingRequirement: "Add new bidding requirement",
        deleteTypeBiddingRequirement: "Add new bidding requirement",
        addStage: "Add new stage",
        actionsGroup: "Actions group",
        addGroup: "Add new group",
        groups: "Groups",
        requirements: "requeriment",
        addRequirement: "Add new requirement",

        processes: "Process",
        newProcess: "New process",
        typeProcess: "Process Types",
        myProccess: "My Process",
        backToProccess: "Back To Process",
        generateProccessType: "Generate Process Type",
        myProccessTypes: "My Process Types",
        newTypeProccess: "New Type Process",
        editTypeProccess: "Edit Type Process ",
        listingProccessTypesError: "Listing Process Types Error",
        proccessTypeWillBeDeleted: "Process Will Be Deleted",
        deletedProccessTypeSuccess: "Deleted Process Success",
        deletedProccessTypeError: "Deleted Process Error",
        nameProccess: "Name Process",
        proccessCreatedSuccessfully: "Process Created Successfully",
        creatingProccessError: "Creating Process Error",
        fileCorrupt: "The file is empty (0 KB) or corrupt",
        groupUser: "Group Users",
        userGroups: " User Groups",
        generateUserGroups: "User groups",
        myUserGroups: "My Data Groups",
        permissionLevel: "Permission Level",
        profiles: "Profiles",
        profilesTitle: "Process profiles",
        profilesSubTitle: "View the profiles created in this process",
        createNewContract: "Create new document",
        // process
        vaultPageInfoModal:
            "Este apartado es únicamente para consultar la información que has subido, si deseas subir información ingresa en la pestaña de procesos.",
        createSignatureProcess: "Crear proceso de firma",
        biddingPageTooltip:
            "Son los datos personales que se van recabando durante la creación de tu proceso. Por ejemplo, RFC, Razón social, Nombres, etc...",
        createNewProcessType: "Crear nuevo tipo de proceso",
        generateNewProcess: "Generar un nuevo Proceso",
        createNewProcess: "New process",
        formatNameInProcess: "Name format in new processes",
        nameFormat: "Name format",
        newProcessTypeCreated: "Nuevo tipo de Proceso creado",
        processNameRequired: "Falta nombre del tipo de Proceso",
        errorCreatingProcessType: "Error al crear tipo de Proceso",
        updatedProcessType: "Tipo de Proceso actualizado",
        errorUpdatingProcessType: "Error al actualizar tipo de Proceso",
        addNewStageToProcess: "Agregar nueva etapa al proceso",
        addProperties: "Add properties",
        addPropertiesToProcess: "Agregar propiedades al proceso",
        processProperties: "Process Properties",
        aditionalFeatures:
            "Las caracteristicas adicionales representan valores por default que se asocian a cada proceso generado apartir de este Tipo de Proceso.",
        processNotificationConfig: "Notification settings for this process",
        processNotifications: "Process notifications",
        seeNotificationsInProcess: "View the notifications created in this process",
        profileProcessConfig: "Configuración de perfiles para este proceso",
        assignUsersToProcess: "Asignar usuarios a proceso",
        addPermissionsToProcess: "Configurar permisos al proceso",
        processType: "Process type",
        noPowerBIURLasociatedWithProcess: "No cuenta con ningún reporte Power Bi asociado a este proceso",
        generatingProcess: "Generando proceso",
        shortcuts: "Shortcuts",
        recentDocuments: "Recent documents",
        seeMore: "See more",
        aceptado: "Approved",
        ownershipCompany: "Ownership company",
        affiliatedCompany: "Affiliated company",
        startDate: "Start date",
        endDate: "End date",
        stages: "Stages",
        editExpedientData: "Edit expedient data",
        addReportDashboard: "Add report dashboard",
        notificationSetting: "Notification settings",
        createGroup: "Create Group",
        superCategoryLabel: "Super category label",
        label: "Label",
        addLabels: "Add labels",
        addNewConfig: "Add new settings",
        assignUsersToNotifications: "Assign users to notifications",
        configName: "Settings name",
        configEvents: "Configure the events for stages, groups, requirements, and documents",
        rolesAndPermissions: "Roles and permissions",
        Insertaunanuevoproceso: "Insert a new process",
        Actualizaunproceso: "Update a process",
        Agregaunnuevoanexoaunproceso: "Add a new annex to a process",
        Agregaunnuevodocumentoaunproceso: "Add a new document to a process",
        AgregaunnuevodocumentotipoSmartContractaunproceso: "Add a new Smart Contract-type document to a process",
        Actualizaunurlpowerbi: "Update url power bi",
        noBiddingSelected: "No stage selected",
        requirementType: "Requirement type",
        minDocumentsRequired: "Minimum documents required",
        maxDocumentsRequired: "Maximum documents required",
        optionalDocs: "The number of documents is optional",
        unlimitedDocs: "The number of documents is unlimited",
        alwaysNew: "Always new requirement",
        validationType: "Validation type",
        nameId: "Id",
        documentSendReviewSuccessfully: "Document successfully sent for review",
        maxLifeStage: " Max stage",
        // Administration
        configuration: "Configuration",
        authentication: "Authentication",
        policies: "Policies",
        noPoliciesData: "There are no policies defined for two steps authentication",
        otpTitle: "Two Steps Authentication",
        addExceptions: "Add exceptions",
        userType: "User type",
        userExceptions: "User exceptions",
        // template settings footer & header
        saveTemplateAsDraft: "Save your template as a draft",
        msgTemplateAsDraft: "After saving it as a draft, you will see the option to add header and footer.",
        generalInfo : "Document details",
        calendarTabName: "Calendario",
        errorSession : "Has been an interruption with the internet connection. Please login again to continue.",
        reviewExternalUserText: "Add external participants or reviewers to the review process.",
        tooltipExternalUserText : "These users are external,they do not have access to the platform,but they can participate in the review process."

    },
};
export default en;

export type TranslationObject = typeof en;
export type TranslationKeys = keyof typeof en.global;
