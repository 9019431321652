import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

const biddingAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/bidding`,
    timeout: 20000,
});

const axiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_GENERAL}/api/general`,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});

const utilAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/general/util`,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});

const axiosScmRequestV2 = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/`,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});


const createBidding = async (body: any) => {
    try {
        return await biddingAxiosRequest.put(``, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateBidding = async (body: any) => {
    try {
        return await biddingAxiosRequest.patch(`/`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteBidding = async (biddingId: string) => {
    try {
        return await biddingAxiosRequest.delete(`/${biddingId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getBiddingsByCompany = async (companyOwnerId: string) => {
    try {
        return await biddingAxiosRequest.get(`/companyOwner/${companyOwnerId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getBidding = async (biddingId: string) => {
    try {
        return await biddingAxiosRequest.get(`/${biddingId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getBiddingsByCompanyPartner = async (companyOwnerId: string, companyPartnerId: string) => {
    try {
        return await biddingAxiosRequest.get(`/companyOwner/${companyOwnerId}/companyPartner/${companyPartnerId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const addDocumentToSession = async (body: any) => {
    try {
        return await utilAxiosRequest.post(`/uploadToSession`, body, {
            headers: getMultipartHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const addDocumentToBiddingReq = async (biddingId: string, requirementUuid: string, body: any) => {
    try {
        return await biddingAxiosRequest.post(`/addDocument/bidding/${biddingId}/requirement/${requirementUuid}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const edditDocumentToBiddingReq = async (biddingId: string, documentUuid: string, body: any) => {
    try {
        return await biddingAxiosRequest.patch(`/updateDocument/bidding/${biddingId}/document/${documentUuid}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const addAnnexToBiddingReq = async (biddingId: string, requirementUuid: string, body: any) => {
    try {
        return await biddingAxiosRequest.post(`/addAnnexeDocument/bidding/${biddingId}/requirement/${requirementUuid}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const enableBiddingReq = async (biddingId: string, requirementUuid: string) => {
    try {
        return await biddingAxiosRequest.post(`/enableRequirement/bidding/${biddingId}/requirement/${requirementUuid}`, "", {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
};

const addSmartContractToBiddingReq = async (biddingId: string, requirementUuid: string, body: any) => {
    try {
        return await biddingAxiosRequest.post(`/addSmartContract/bidding/${biddingId}/requirement/${requirementUuid}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error.response.data);
        return error.response.data;
    }
};

const removeSmartContractToBiddingReq = async (biddingId: string, requirementUuid: string, documentUuid: string) => {
    try {
        return await biddingAxiosRequest.delete(
            `/removeSmartContract/bidding/${biddingId}/requirement/${requirementUuid}/document/${documentUuid}`,
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateExpedient = async (biddingId: string, body: any) => {
    try {
        return await biddingAxiosRequest.post(`/updateExpedient/bidding/${biddingId}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const removeDocumentOrAnnexe = async (biddingId: string, requirementUuid: string, documentUuid: string) => {
    try {
        return await biddingAxiosRequest.delete(
            `/removeDocumentOrAnnexe/bidding/${biddingId}/requirement/${requirementUuid}/document/${documentUuid}`,
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateUrlPowerBi = async (biddingId: string, urlPowerBi: string) => {
    try {
        return await biddingAxiosRequest.patch(
            `biddingId/${biddingId}/urlPowerBi`,
            { urlPowerBi },
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

const transformPatternBidding = async (body: any) => {
    try {
        return await axiosRequest.post(`/util/transformStringToStringWithPatternDate`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDataMovil = async () => {
    try {
        return await axiosScmRequestV2.get(`movilCheckInOut/checkInOut`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDataMovilImg = async () => {
    try {
        return await axiosScmRequestV2.get(`movilImages/gpsImageSent`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    createBidding,
    updateBidding,
    deleteBidding,
    getBiddingsByCompany,
    getBiddingsByCompanyPartner,
    addDocumentToBiddingReq,
    edditDocumentToBiddingReq,
    addDocumentToSession,
    enableBiddingReq,
    addAnnexToBiddingReq,
    removeSmartContractToBiddingReq,
    getBidding,
    addSmartContractToBiddingReq,
    updateExpedient,
    removeDocumentOrAnnexe,
    updateUrlPowerBi,
    transformPatternBidding,
    getDataMovil,
    getDataMovilImg
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}

function getMultipartHeaders() {
    return {
        "Content-Type": "multipart/form-data",
        ...getAuthHeader(),
    };
}
