import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Button, useBoolean } from "@chakra-ui/react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import SafetyIndicator from "./SafetyIndicator";
import { useFormik } from "formik";
import useCustomToast from "../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import authService from "../../services/auth.service";
import useAuthState from "../../store/authState";
import useGlobalState from "src/store/globalState";
import { Link } from '@chakra-ui/react'
import { Checkbox, Text } from '@chakra-ui/react'
import * as Yup from "yup";
import { UserAuth } from "src/models/User.models";
import SafetyPolicies from "./SafetyPolicies";
import { useEffect, useState } from "react";
import ResetPassSafetyPolicies from "./ResetPassSafetyPolicies";
import useAdministrationState from "src/store/administrationState";
import { PasswordPolicyList } from "src/models/Aministration.models";

interface FormFields {
  newPassword: string;
  newPasswordConfirm: string;
  tAndC: boolean;
}

const ResetPasswordForm = () => {
  const token_restore = new URLSearchParams(window.location.search).get("token");
  const { getPoliciesByUserList } = useAdministrationState();

  const [showPass, setShowPass] = useBoolean(false);
  const [showPass2, setShowPass2] = useBoolean(false);
  const [isLoading, toggleLoading] = useBoolean(false);

  const [validToken, setValidToken] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [ policies, setPolicies ] = useState<PasswordPolicyList[]>([]);

  const getUserInfo = useGlobalState((s) => s.getUserInfo);

  const { userId } = useAuthState();
  const navigate = useNavigate();
  const toast = useCustomToast();
  const [errors, setErrors] = useState(null);

  const handleSubmit = async (values: FormFields) => {
    toggleLoading.on();

    const userBody = {
      ...userData,
      updatedPassword: true,
      password: values.newPassword,
    };

    const res = await authService.confirmRestPassword(userBody);

    if (res?.data?.codigo === 0) {
      const info = await getUserInfo(userId)
      toggleLoading.off();
      toast.success("Contraseña actualizada");
      navigate("/", { replace: true });

      /* if (info) {
        const loggedUserData = JSON.parse(sessionStorage.getItem('authUser'));

        loggedUserData.state.updatedPassword = true;

        sessionStorage.setItem('authUser', JSON.stringify(loggedUserData));

        navigate("/", { replace: true });
      } */
    } else {
      toast.error("Error al actualizar contraseña", "Login fallido");
      setErrors(res.data.respuesta);
      toggleLoading.off();
    }
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      newPassword: "",
      newPasswordConfirm: "",
      tAndC: false,
    },
    onSubmit: handleSubmit,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("El campo es requerido"),
      // .min(10, "La contraseña no puede ser menor a 10 caracteres")
      // .max(20, "La contraseña no puede ser mayor a 20 caracteres"),
      newPasswordConfirm: Yup.string()
        .required("El campo es requerido")
        .oneOf([Yup.ref("newPassword")], "Las contraseñas deben coincidir."),
      tAndC: Yup.boolean().isTrue("Si no acepta los términos y condiciones no se puede concluir el registro de usuario."),
    })
  });

  useEffect(() => {
    authService.validateResetPassword(token_restore).then((data) => {
      if (data.data.codigo === 0) {
        setValidToken(true);
        getPoliciesByUserList(data.data.respuesta.id).then((result) => {
          if (result.codigo === 0) {
            const passwordPolicyList = result.respuesta.passwordPolicyList;
            const activePolicies = passwordPolicyList.filter((item: PasswordPolicyList) => {
              if (item.active && item.applicableInUpdatePassword) return item;
            });
            setPolicies(activePolicies)
          }
        })
        setUserData(data.data.respuesta);
      } else {
        toast.error("Error al actualizar contraseña", "Token ha expirado");
        navigate("/", { replace: true });
        setUserData(null);
      }
    })
  }, [token_restore])

  return (
    <div className="w-full max-w-[25rem] p-2">
      <h1 className="text-brand-500 text-3xl font-medium">Actualizar Contraseña</h1>
      <form className="mt-8 flex flex-col items-center" onSubmit={formik.handleSubmit}>
        <FormControl className="mt-6" isInvalid={!!formik.errors.newPassword}>
          <label htmlFor="newPassword" className="text-sm leading-8">
            {"Nueva Contraseña"}
          </label>
          <InputGroup borderColor="gray.400">
            <Input
              id="newPassword"
              type={showPass ? "text" : "password"}
              rounded="none"
              onChange={formik.handleChange}
              value={formik.values.newPassword}
            />
            <InputRightElement
              children={
                <button type="button" onClick={setShowPass.toggle}>
                  {showPass ? (
                    <IoEyeOffOutline size={22} color="inherit" />
                  ) : (
                    <IoEyeOutline size={22} color="inherit" />
                  )}
                </button>
              }
            />
          </InputGroup>
          <FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
        </FormControl>
        <FormControl className="mt-6" isInvalid={!!formik.errors.newPasswordConfirm}>
          <label htmlFor="newPasswordConfirm" className="text-sm leading-8">
            {"Confirmar Contraseña"}
          </label>
          <InputGroup borderColor="gray.400">
            <Input
              id="newPasswordConfirm"
              type={showPass2 ? "text" : "password"}
              rounded="none"
              onChange={formik.handleChange}
              value={formik.values.newPasswordConfirm}
            />
            <InputRightElement
              children={
                <button type="button" onClick={setShowPass2.toggle}>
                  {showPass2 ? (
                    <IoEyeOffOutline size={22} color="inherit" />
                  ) : (
                    <IoEyeOutline size={22} color="inherit" />
                  )}
                </button>
              }
            />
          </InputGroup>
          <FormErrorMessage>{formik.errors.newPasswordConfirm}</FormErrorMessage>
        </FormControl>
        <div className="mt-10 w-full">
          {/* <SafetyIndicator password={formik.values.newPassword} /> */}
          <ResetPassSafetyPolicies errorPolicies={errors} policies={policies} setPolicies={setPolicies} />
        </div>
        <FormControl className="mt-6" isInvalid={!!formik.errors.tAndC}>
          <InputGroup borderColor="gray.400">
            <Checkbox
              rounded="10"
              id="tAndC"
              name="tAndC"
              checked={formik.values.tAndC}
              onChange={formik.handleChange}
              size="md"
              className="pb-1"
            />
            <Text className="pl-2">
              {"Acepto los "}
              <Link color='teal.500' onClick={() => window.open("/docs/Términos y condiciones OrkestaDoc.pdf", "_blank")}>
                {" Términos y Condiciones"}
              </Link>
            </Text>
          </InputGroup>
          <FormErrorMessage>{formik.errors.tAndC}</FormErrorMessage>
        </FormControl>
        <div className="mt-10">
          <Button
            type="submit"
            rounded="full"
            size="lg"
            fontWeight="medium"
            isLoading={isLoading}
            disabled= {!validToken}
          >
            Reestablecer contraseña
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
