import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import DottedSubtitle from "../common/DottedSubtitle";
import { useState } from "react";
import AuthenticationTabAdmin from "./AuthenticationTabAdmin";
import CalendarTabAdmin from "./CalendarTabAdmin";

const ConfigAdmin: () => JSX.Element = () => {
    /** */
    const t: ( key: TranslationKeys ) => string = useTranslation( "global" )[0];
    const [selectedTab, setSelectedTab] = useState( 0 );
    const configTabs: string[] = [ t("authentication"), t("calendarTabName") ];
    const handleTab = ( index: number ) => {
        /** */
        setSelectedTab( index );
    }
    const defaultTabClasses: string = "config__tabs-tab px-4 py-4 rounded-lg cursor-pointer";
    const listConfigTabs = configTabs.map( ( tab, index ) =>
        <li key={index}
            className={`${defaultTabClasses} ${index === selectedTab ? "bg-brand-500 text-white" : "bg-gray-light text-brand-500"}`}
            onClick={ () => handleTab( index )}>
            {tab}
        </li>
    );

    return (
        <>
            <div className="mt-4 mb-4">
                <DottedSubtitle text={t("configuration")} />
            </div>

            <div className="config flex gap-4">
                <ul className="config__tabs flex flex-col gap-4">
                    {listConfigTabs}
                </ul>
                <div className="config__content w-full">
                    {/* Autenticación */}
                    {selectedTab === 0 && <AuthenticationTabAdmin />}
                    {selectedTab === 1 && <CalendarTabAdmin />}
                </div>
            </div>
        </>
    );
}

export default ConfigAdmin;