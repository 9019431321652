import { Button } from "@chakra-ui/button";
import { CircularProgress } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CommonModal from "../../CommonModal";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import CommonTh from "../../table/CommonTh";
import { useNavigate } from "react-router-dom";
import useCustomToast from "src/hooks/useCustomToast";
import { CompanyLite } from "src/models/Aministration.models";
import useAdministrationState from "src/store/administrationState";
import useModalState from "src/store/modalsState";
import SearchInput from "../../SearchInput";

const TableRow = ({ company, selected, onSelect }: { company: any; selected: boolean; onSelect: (contractId: string) => any }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    return (
        <tr
            onClick={() => onSelect(company.id)}
            className={`${"border-b  transition-colors duration-200 cursor-pointer"} ${
                selected ? " bg-teal-600 text-white" : " hover:bg-slate-100"
            }`}
        >
            <td className="p-2 py-3">{company.name}</td>
            <td className="p-2 py-3">{company.rfc}</td>
        </tr>
    );
};

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const AddNewCompanyModal = ({ isOpen, onClose, data }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const navigate = useNavigate();
    const [selectedCompany, setSelectedCompany] = useState<CompanyLite>(null);
    const [searchQuery, setSearchQuery] = useState("");

    const {
        allCompanies: { data: companies, loading },
        getAllCompanies,
        associateCompany,
        getAssociatedCompanies,
    } = useAdministrationState();

    const setCreateNewCompanyModal = useModalState((s) => s.setCreateNewCompanyModal);

    const onSearch = (query) => {
        setSearchQuery(query);
    };

    const filteredCompanies = companies?.filter((company) => company?.name?.toLowerCase().includes(searchQuery?.toLowerCase()));

    async function handleAssociateCompany() {
        const associated = await associateCompany(selectedCompany?.id);
        if (associated) {
            toast.success("Empresa asociada con éxito");
            getAssociatedCompanies(false);
            onClose();
        } else {
            toast.error("Error al asociar la empresa");
        }
    }

    async function handleCreateNew() {
        setCreateNewCompanyModal({ show: true });
        onClose();
    }

    useEffect(() => {
        getAllCompanies(false);
    }, []);

    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div>
                    <h2 className="title-3 text-center">{data?.requirement?.name}</h2>
                </div>
                <div className="w-full flex flex-col items-center">
                    <div>
                        <h4 className="text-center text-lg text-gray-600 font-semibold mb-2">Agregar empresa</h4>
                        <Button variant={"outline"} onClick={handleCreateNew}>
                            Crear nueva empresa
                        </Button>
                    </div>
                    <div className="w-full mt-8">
                        <h4 className="text-center text-lg text-gray-600 font-semibold mb-2">Agregar empresa existente</h4>
                        <div className="mb-4">
                            <SearchInput onChange={onSearch} />
                        </div>
                        <div className="border p-4 rounded-lg max-h-[400px] overflow-y-auto">
                            <table className="w-full text-sm text-left text-dark-2">
                                <thead className="text-base text-brand-500 font-medium border-b">
                                    <tr>
                                        <th scope="col" className="p-2">
                                            <CommonTh text={t("name")} />
                                        </th>
                                        <th scope="col" className="p-2">
                                            <CommonTh text="RFC" />
                                        </th>
                                    </tr>
                                    <tr></tr>
                                </thead>
                                <tbody className="m-h-[434px] overflow-y-auto">
                                    {!loading &&
                                        filteredCompanies?.map((item) => (
                                            <TableRow
                                                key={item.id}
                                                company={item}
                                                selected={selectedCompany?.id === item.id}
                                                onSelect={() => setSelectedCompany(item)}
                                            />
                                        ))}
                                    {loading && (
                                        <tr>
                                            <td colSpan={3} className="px-2 pt-2 text-center">
                                                <CircularProgress isIndeterminate color="brand.500" size="40px" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-4 text-center">
                            <Button onClick={handleAssociateCompany} disabled={!selectedCompany} rounded="full">
                                Agregar empresa
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
};

export default AddNewCompanyModal;
