import { useEffect, useState } from "react";

const usePageZoom = () => {
    const [zoom, setZoom] = useState(125);

    useEffect(() => {
        //TODO: https://stackoverflow.com/questions/25258663/detect-window-resolution-on-mac-and-surface
        const dpr = Math.round(window?.devicePixelRatio * 100);
        setZoom(dpr ?? 125);
    }, []);

    return { zoom };
};

export default usePageZoom;

/**
 * 
 * const usePageZoom = () => {
    const [zoom, setZoom] = useState(100); // Establece el zoom inicial al 100% o cualquier valor que desees

    useEffect(() => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;

        // Establece la resolución de referencia
        const referenceWidth = 1366;
        const referenceHeight = 768;

        // Calcula el zoom basándote en la relación entre la resolución de pantalla actual y la resolución de referencia
        const zoomWidth = (screenWidth / referenceWidth) * 100;
        const zoomHeight = (screenHeight / referenceHeight) * 100;

        // Usa el zoom más pequeño para asegurarte de que el contenido se ajuste en ambas dimensiones (ancho y alto)
        const calculatedZoom = Math.min(zoomWidth, zoomHeight);

        setZoom(calculatedZoom);
    }, []);

    return { zoom };
};

 * 
 * 
 * 
 */
