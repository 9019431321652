import { Tooltip } from "@chakra-ui/react";
import { sliceText } from "src/lib/utils";

const SlicedText = ({maxLength, text, tooltipPlacement}: {maxLength: number, text: string, tooltipPlacement?: "top" | "bottom" | "left" | "right"}) => {
    text = text ?? "--";
    return (
        <Tooltip label={text.length > maxLength ? text : ""} placement={tooltipPlacement ?? "auto"}>
            {sliceText(text, maxLength)}
        </Tooltip>
    );
}

export default SlicedText;