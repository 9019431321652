import { useEffect } from "react";
import newContractImg from "../../../assets/images/new-contract.png";
import newTemplateImg from "../../../assets/images/new-template.png";
import newProcessImg from "../../../assets/images/new-process.png";
import newUserImg from "../../../assets/images/new-user.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useGlobalState from "../../../store/globalState";
import DottedSubtitle from "../../common/DottedSubtitle";
import { Tooltip } from "@chakra-ui/react";

const StaristicsList = () => {
    //const setEditCompanyModal = useModalState((s) => s.setEditCompanyModal);
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const { getUserCompanyInfo, userInfo } = useGlobalState();
    const additionalCompanyInfo = useGlobalState((s) => s.additionalCompanyInfo);

    useEffect(() => {
        if (userInfo.data) {
            getUserCompanyInfo();
        }
    }, [userInfo?.data]);

    const renderShortCuts = () => {
        switch (additionalCompanyInfo.dashBoardType) {
            case 'DashV2':
                return <ShortcutsAmericaMovil />;
            case 'DashKivit':
                return <ShortCutsKivit />;
            default:
                return <ShortcutsAmericaMovil />; 
        }
    };

    
const ShortCutsKivit = () => {
    return (
        <div>
        <div className="flex w-full items-center gap-4">
            <h1 className="text-brand-600 text-2xl capitalize">{t("shortcuts")}</h1>
        </div>
        <div className="w-full items-center mt-4 grid grid-cols-4 gap-4">
                <BoxItem onClick={() => navigate("/biddings")}>
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[35%] mr-3" src={newContractImg} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" title="Operaciones tipo especial">
                                {"Mis Generadores"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" data-tip="Operaciones tipo especial">
                                <Tooltip label={"Mis Generadores"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
                <BoxItem onClick={() => navigate("/administration")}>
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[30%] mr-3" src={newProcessImg} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" title="Operaciones tipo especial">
                                {"Adminstración"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" data-tip="Operaciones tipo especial">
                                <Tooltip label={"Adminstración"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
        </div>
    </div>
    );
}

const ShortcutsAmericaMovil = () => {
    return (
        <div>
        {/* <div className="flex w-full items-center gap-4">
            <h1 className="text-brand-600 text-2xl capitalize">{t("shortcuts")}</h1>
        </div> */}
        <div className="w-full items-center grid grid-cols-4 gap-4">
                <BoxItem onClick={() => navigate("/biddings")}>
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[35%] mr-3" src={newContractImg} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" title="Operaciones tipo especial">
                                {"Mis operaciones"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" data-tip="Operaciones tipo especial">
                                <Tooltip label={"Mis operaciones"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
                <BoxItem onClick={() => navigate("/biddings")}>
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[30%] mr-3" src={newTemplateImg} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" title="Operaciones tipo especial">
                                {"Operaciones tipo riesgo"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" data-tip="Operaciones tipo especial">
                                <Tooltip label={"Operaciones tipo riesgo"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
                <BoxItem onClick={() => navigate("/biddings")}>
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[30%] mr-3" src={newProcessImg} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" title="Operaciones tipo especial">
                                {"Operaciones tipo fiscal"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" data-tip="Operaciones tipo especial">
                                <Tooltip label={"Operaciones tipo fiscal"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
                <BoxItem onClick={() => navigate("/biddings")}>
                    <div className="flex-auto p-5">
                        <div className="flex items-center justify-between">
                            <img className="w-[30%] mr-3" src={newUserImg} alt="" />
                            <p className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:block hidden" title="Operaciones tipo especial">
                                {"Operaciones tipo especial"}
                            </p>
                            <div className="flex-1 text-0.5xl text-brand-600 leading-8 text-left lg:hidden" data-tip="Operaciones tipo especial">
                                <Tooltip label={"Operaciones tipo especial"}>...</Tooltip>
                            </div>
                        </div>
                    </div>
                </BoxItem>
        </div>
    </div>
    );
}

    return (
        <>
         {renderShortCuts()}
        </>
    );
};

export default StaristicsList;

const BoxItem = ({ children, onClick }) => {
    return (
        <div
            onClick={onClick}
            className="relative flex border-transparent flex-col min-w-0 break-words bg-white
            transition duration-600 ease-in-out transform hover:scale-105
            shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border cursor-pointer">
            {children}
        </div>
    );
};

/**
 * 
 * 
 * 
 *  <BoxItem onClick={() => navigate("/biddings")}>
                    <div className="flex-auto p-4">
                        <div className="flex flex-row -mx-3">
                            <div className="flex-none w-2/3 max-w-full px-3">
                                <div>
                                    <p className="mb-0 font-sans font-semibold leading-normal uppercase text-sm">Operaciones Tipo Riesgos</p>
                                    <h5 className="mb-2 font-bold dark:text-gray">$53,000</h5>
                                    <p className="mb-0 dark:text-white dark:opacity-60">
                                        <span className="font-bold leading-normal text-sm text-emerald-500">3</span>
                                        since yesterday
                                    </p>
                                </div>
                            </div>
                            <div className="px-3 text-right basis-1/3">
                                <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                                    <i className="fa fa-coins text-lg relative top-3.5 text-gray"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </BoxItem>

                <BoxItem onClick={() => navigate("/biddings")}>
                    <div className="flex-auto p-4">
                        <div className="flex flex-row -mx-3">
                            <div className="flex-none w-2/3 max-w-full px-3">
                                <div>
                                    <p className="mb-0 font-sans font-semibold leading-normal uppercase text-sm">Operaciones tipo Fiscal</p>
                                    <h5 className="mb-2 font-bold dark:text-gray">$53,000</h5>
                                    <p className="mb-0 dark:text-white dark:opacity-60">
                                        <span className="font-bold leading-normal text-sm text-emerald-500">2</span>
                                        since yesterday
                                    </p>
                                </div>
                            </div>
                            <div className="px-3 text-right basis-1/3">
                                <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                                    <i className="fa fa-coins text-lg relative top-3.5 text-gray"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </BoxItem>
                <BoxItem onClick={() => navigate("/biddings")}>
                    <div className="flex-auto p-4">
                        <div className="flex flex-row -mx-3">
                            <div className="flex-none w-2/3 max-w-full px-3">
                                <div>
                                    <p className="mb-0 font-sans font-semibold leading-normal uppercase text-sm">Operaciones tipo Especiales</p>
                                    <h5 className="mb-2 font-bold dark:text-gray">$53,000</h5>
                                    <p className="mb-0 dark:text-white dark:opacity-60">
                                        <span className="font-bold leading-normal text-sm text-emerald-500">5</span>
                                        since yesterday
                                    </p>
                                </div>
                            </div>
                            <div className="px-3 text-right basis-1/3">
                                <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                                    <i className="fa fa-coins text-lg relative top-3.5 text-gray"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </BoxItem>
 */