import { Checkbox, Tooltip } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import useCustomToast from "../../hooks/useCustomToast";
import usePagination from "../../hooks/usePagination";
import { AssociatedCompany } from "../../models/Aministration.models";
import useAdministrationState from "../../store/administrationState";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { BiChevronRight, BiUserPlus } from "react-icons/bi";
import useModalState, { confirmAlert } from "../../store/modalsState";
import SearchInput from "../common/SearchInput";
import CommonTh from "../common/table/CommonTh";
import TablePaginator from "../common/table/TablePaginator";
import LinkButton from "../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { AiOutlineMinusCircle } from "react-icons/ai";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";
import useBiddingProfileState from "src/store/biddings/biddingProfileState";
import { IoTrashOutline } from "react-icons/io5";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineNotificationAdd } from "react-icons/md";
import useBiddingNotificationState from "src/store/biddings/biddingNotificationsState";

const TableRow = ({
    profile,
    onDelete,
    onEdit,
    onSelect,
    selectedProfile,
}: {
    profile: any;
    onDelete: (profile: any) => any;
    onEdit: (profile: any, edit: boolean) => any;
    onSelect: any;
    selectedProfile: string;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 py-3">
                <Checkbox onChange={() => onSelect(profile?.id, profile?.userIdList)} isChecked={selectedProfile === profile.id} />
            </td>
            <td className="p-2 py-3">{`${profile.name}`}</td>
            <td className="p-2 py-3">{profile.description}</td>
            {
                <td className="px-2 py-3">
                    <Tooltip label={t("edit")} fontSize="12px">
                        <button onClick={() => onEdit(profile, true)}>
                            <AiOutlineEdit size={25} />
                        </button>
                    </Tooltip>
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={() => onDelete(profile)} className="ml-4">
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </td>
            }
        </tr>
    );
};

const ProfilesTable = ({
    profiles,
    onDelete,
    onEdit,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
    onSelect,
    selectedProfile,
}: {
    profiles: any;
    onDelete: (profile: any) => any;
    onEdit: (profileId: string, edit: boolean) => any;
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
    onSelect: any;
    selectedProfile: string;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const stages = useBiddingNotificationState((state) => state.stages);
    return (
        <div className="w-full">
            {/* <div className="w-full flex justify-between items-center sticky top-0 z-10 py-3 bg-white border-b">
                <SearchInput onChange={onSearch} />
            </div> */}
            <table className="w-full text-xs text-left text-dark-2">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2"></th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("description")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("actions")} />
                        </th>
                    </tr>
                    <tr></tr>
                </thead>
                <tbody>
                {profiles?.length !== undefined &&
                    profiles
                        ?.filter((profile) =>
                            profile.topicList.some((topic) =>
                                topic.componentId && // Verifica que componentId no sea null o undefined
                                topic.componentId
                                    .split('|') // Divide el componentId en un array
                                    .some((id) =>
                                        stages.some((stage: any) => stage.name === id.trim()) // Compara con los nombres en stages
                                    )
                            )
                        )
                        .map((item) => (
                            <TableRow
                                key={item.id}
                                profile={item}
                                onDelete={() => onDelete(item)}
                                onEdit={() => onEdit(item, true)}
                                onSelect={onSelect}
                                selectedProfile={selectedProfile}
                            />
                        ))
                }
                </tbody>
            </table>
            {profiles?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

const ProfilesBidding = ({ profilesData, onEditProfile, biddingId, handleSelectProfile, selectedProfile, templateId }) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [searchQuery, setSearchQuery] = useState("");
    const { setNewEditUserModal, setDeleteCommonModal } = useModalState();
    const { getProfilesBiddingById, profilesBidding, deleteBiddingProfile } = useBiddingProfileState();
    const [companyType, setCompanyType] = useState<"internal" | "external">("internal");
    const [selectedSubscription, setSelectedSubscription] = useState();
    const {
        allCompanies,
        associatedCompanies: { data: associatedCompanies },
        getAssociatedCompanies,
        deleteCompanyAssociation,
    } = useAdministrationState();

    const { getBiddingTypeNotificacionCompany, deleteBiddingnotificacion } = useBiddingNotificationState();

    const { setAddNewCompanyModal } = useModalState();

    const filteredResults = useMemo(() => {
        return associatedCompanies?.filter(
            (comp) =>
                comp.foreignCompanyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                comp.foreignCompanyRfc.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [searchQuery, associatedCompanies]);

    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({ data: filteredResults, itemsPerPage: 6 });

    function handleAddCompany() {
        setAddNewCompanyModal({ show: true });
    }

    async function onDelete(profile: any) {
        const deleted = await deleteBiddingnotificacion(profile);
        if (deleted) {
            toast.success("Notificación eliminada");
            // getProfilesBiddingById(biddingId);
            getBiddingTypeNotificacionCompany(templateId);
        } else {
            toast.error("Error al eliminar el Notificación");
        }
    }

    function handleDelete(profile: any) {
        confirmAlert.show({
            title: "Eliminar Notificación",
            msg: "¿Estas seguro de eliminar este Notificación?",
            severity: "warning",
            onConfirm: () => onDelete(profile.id),
        });
    }

    const handleSaveSubscription = (subscription) => {
        setSelectedSubscription(subscription);
    };

    function handleEdit(profile: string, edit: boolean) {
        confirmAlert.show({
            title: "Editar Notificación",
            msg: "¿Estas seguro de editar este Notificación?",
            severity: "info",
            onConfirm: () => onEditProfile(profile, edit),
        });
    }

    useEffect(() => {
        // getProfilesBiddingById(biddingId)
    }, []);

    return (
        <>
            <div className="w-full flex justify-between items-center pb-4 pr-2">
                <DottedSubtitle
                    text={t("notifications")}
                    beforeSearchElement={
                        <LinkButton leftIcon={<MdOutlineNotificationAdd size={20} />} onClick={() => onEditProfile([], false)}>
                            {t("addNewConfig")}
                        </LinkButton>
                    }
                />
            </div>
            {
    profilesData && profilesData.length > 0 && (
        <ProfilesTable
            profiles={profilesData}
            onDelete={handleDelete}
            onEdit={handleEdit}
            currentPage={currentPage}
            maxPages={maxPages}
            prev={prev}
            next={next}
            setPage={setPage}
            onSelect={handleSelectProfile}
            selectedProfile={selectedProfile}
        />
    )
}

        </>
    );
};

export default ProfilesBidding;
