import { Button, IconButton, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { BiddingDocumentType } from "src/models/biddings/BiddingDocumentType.models";
import SlicedText from "../common/SlicedText";
import { FiEdit3 } from "react-icons/fi";
import { BiTrash } from "react-icons/bi";
import useBiddingDocumentTypeState from "src/store/biddings/biddingDocumentTypesState";
import { extensionIcons } from "../../Utils/iconsSelector";
import { useEffect } from "react";

interface RequerimentsProps {
    alwaysNew: boolean
    biddingValidationTypeId: string
    documentTypeId: string[]
    fileLabel: string
    maxUploadable: number
    minUploadable: number
    name: string
    requerimentIdList: string[]
    reviewStatus: string
    signStatus: string
    templateId: string
}

const NewRequirementCard = ({
    requirement,
    onEdit,
    onDelete,
}: {
    requirement?: RequerimentsProps;
    onEdit: () => any;
    onDelete: () => any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const {
        getBiddingDocumentTypes,
        biddingsDocumentTypes: { data: biddingsDocumentTypes },
    } = useBiddingDocumentTypeState();

    const documentsExtensions = biddingsDocumentTypes.filter((document) => requirement?.documentTypeId?.includes(document.id));

    useEffect(() => {
       // getBiddingDocumentTypes();
    }, []);

    return (
        <>
            <div className="w-full min-w-[290px] border border-brand-500 flex flex-col">
                <div className="bg-slate-100 flex flex-col justify-end items-center gap-2 p-2">
                    <h4 className="text-xl font-semibold text-brand-600/80 text-center min-h-[56px]">
                        <SlicedText text={requirement?.name} maxLength={42} />
                    </h4>
                    <div className="w-full flex justify-between items-center mt-2">
                        <div className="text-xs text-brand-500">
                            {/* extension */}
                            {documentsExtensions?.map((doc, index) => (
                                <Tooltip key={index} label={`Tipo de documento: ${doc.extension}`} fontSize="12px">
                                    <button>
                                        <img
                                            key={index}
                                            src={extensionIcons[doc.extension?.toLowerCase()] || ""}
                                            alt={doc.extension}
                                            className="icono-clase-css mr-2 w-6"
                                        />{" "}
                                    </button>
                                </Tooltip>
                            ))}
                        </div>

                        <div className="flex gap-1">
                            <Button
                                size="xs"
                                fontSize="xs"
                                fontWeight="medium"
                                colorScheme={"teal"}
                                leftIcon={<FiEdit3 size={16} />}
                                onClick={onEdit}
                            >
                                {t("edit")}
                            </Button>
                            <IconButton
                                size="xs"
                                fontSize="xs"
                                fontWeight="medium"
                                colorScheme={"red"}
                                aria-label="Delete"
                                icon={<BiTrash size={16} />}
                                onClick={onDelete}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewRequirementCard;
