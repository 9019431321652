import axios from "axios";
import { getAuthHeader } from "../lib/utils";

const axiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_GENERAL}/api/general/auth`,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});

const axiosAuthRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_AUTH}/api/v1`,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});

const login = async (body: { email: string; password: string }) => {
    try {
        return await axiosRequest.post("/login", body);
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateSession = async( token: string ) => {
    try {
        return await axiosRequest.post( "/refreshToken", { token } )
    } catch ( error ) {
        console.log( error )
        return error
    }
}

const resetPassword = async (email) => {
    const modifiedEmail = email.replace(/@/g, '%40');

    try {
        return await axiosAuthRequest.put(`/users/resetPass/${modifiedEmail}`, { idApp: "SCM" });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const validateResetPassword = async (token) => {
    try {
        return await axiosAuthRequest.get(`/users/resetPass/validate/${token}`).catch( error => {
            return error.response;
        });
    } catch (error) {
        console.log(error);
        return error;
    }
}

const confirmRestPassword = async (body) => {
    try {
        return await axiosAuthRequest.put(`/users/acceptAlta`, body, {
            headers: getHeaders(),
        }).catch( error => {
            return error.response;
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const sessionLogout = async () => {
    try {
        return await axiosAuthRequest.post(
            `/auth/logout`,
            {},
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}

export default {
    login,
    resetPassword,
    confirmRestPassword,
    sessionLogout,
    updateSession,
    validateResetPassword
};
