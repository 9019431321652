import { Role } from "src/models/Roles.models";
import roleService from "src/services/roles-permissions/role.service";
import userRoleService from "src/services/roles-permissions/user-role.service";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import useAuthState from "./authState";
import useModalState from "./modalsState";

interface State {
    roles: { loading: boolean; error: boolean; data: Role[] };
    userRoles: { loading: boolean; error: boolean; data: any };
    userRolInfo: { loading: boolean; error: boolean; data: any };
    currentNewRole: Partial<Role>;
    selectedRole: Role;
    setCurrentNewRole: (role: Partial<Role>) => void;
    setSelectedRole: (role: Partial<Role>) => void;
    getRoles: (withLoading?: boolean) => void;
    getRolesByUser: (idUser: string, withLoading?: boolean) => void;
    updateRolesByUser: (userRolId: string ,listRole: any, withLoading?: boolean) => void;
    createRole: (role: Partial<Role>, withLoading?: boolean) => Promise<boolean>;
    updateRole: (role: Partial<Role>, withLoading?: boolean) => Promise<boolean>;
    deleteRole: (roleId: string, withLoading?: boolean) => Promise<boolean>;
    reset: () => void;
}

const initialState = {
    roles: { loading: false, error: false, data: [] },
    userRoles: { loading: false, error: false, data: [] },
    userRolInfo : { loading: false, error: false, data: [] },
    currentNewRole: null,
    selectedRole: null,
};

const useRolesState = create<State>((set, get) => ({
    ...initialState,
    setCurrentNewRole: setCurrentNewRole(set, get),
    getRoles: getRoles(set, get),
    getRolesByUser: getRolesByUser(set, get),
    updateRolesByUser: updateRolesByUser(set, get),
    createRole: createRole(set, get),
    updateRole: updateRole(set, get),
    deleteRole: deleteRole(set, get),
    setSelectedRole: setSelectedRole(set, get),
    reset: () => set((state) => ({ ...state, ...initialState })),
}));

export default useRolesState;

//SETTER FUNCTIONS
function setCurrentNewRole(set: NamedSet<State>, get: GetState<State>) {
    return (role: Partial<Role>) => set((state) => ({ currentNewRole: role }));
}

function setSelectedRole(set: NamedSet<State>, get: GetState<State>) {
    return (role: Role) => set((state) => ({ selectedRole: role }));
}

function hasPermissionForAction (reponse : any, msgPermission : string, redirect : boolean ){
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: 'AVISO',
                text2: msgPermission,
                text3: 'Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.',
                redirectHome : redirect,
                onClose: async () => {
                    return { show: false }
                  }
            }
        });
    }
}

function getRoles(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        set((state) => ({ roles: { loading: true, error: false, data: state.roles.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await roleService.getRolesByCompany(idCompany);

        hasPermissionForAction(res, "Listar roles", false);

        if (res?.data?.respuesta && Array.isArray(res.data.respuesta)) {
            set({ roles: { loading: false, error: false, data: res.data.respuesta.reverse() } });
        } else {
            console.log("Error fetching roles");
            set((state) => ({ roles: { loading: false, error: true, data: state.roles.data } }));
            //clean error
            set((state) => ({ roles: { ...state.roles, error: false } }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}


function getRolesByUser(set: NamedSet<State>, get: GetState<State>) {
    return async (idUser, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await userRoleService.getUserRols(idUser, idCompany);

        hasPermissionForAction(res,"Obtener roles",false);

        if (res?.data?.respuesta) {
                set({ userRoles: { loading: false, error: false, data:res.data.respuesta } });
                    set({userRolInfo : { loading: false, error: false, data: res.data.respuesta } })
        } else {
            console.log("Error fetching roles");
            set((state) => ({ userRoles: { loading: false, error: true, data: state.roles.data } }));
            //clean error
            set((state) => ({ userRoles: { ...state.roles, error: false } }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function updateRolesByUser(set: NamedSet<State>, get: GetState<State>) {
    return async (userRolId, roleInfo, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await userRoleService.updateUserRols(userRolId,roleInfo);

        hasPermissionForAction(res,"Actualizar roles",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.respuesta) {
            return true;
        } else {
            console.log("Error update roles");
            return false;
        }
    };
}


function createRole(set: NamedSet<State>, get: GetState<State>) {
    return async (role, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await roleService.createRole({
            ...role,
            idCompany,
        });

        hasPermissionForAction(res,"Crear roles",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getRoles(false);
            return true;
        } else {
            console.log("Error creating role");
            return false;
        }
    };
}

function updateRole(set: NamedSet<State>, get: GetState<State>) {
    return async (role, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await roleService.updateRole(role);

        hasPermissionForAction(res,"Actualizar roles",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getRoles(false);
            return true;
        } else {
            console.log("Error updating role");
            return false;
        }
    };
}

function deleteRole(set: NamedSet<State>, get: GetState<State>) {
    return async (roleId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await roleService.deleteRoleById(roleId);
        hasPermissionForAction(res,"Eliminar roles",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getRoles(false);
            return true;
        } else {
            console.log("Error deleting role");
            return false;
        }
    };
}
