import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

const biddingTypeAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/biddingType`,
    timeout: 20000,
});

const getBiddingTypes = async () => {
    try {
        return await biddingTypeAxiosRequest.get(`/all`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const getBiddingTypeById = async (biddingId: string) => {
    try {
        return await biddingTypeAxiosRequest.get(`/byId/${biddingId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const getBiddingTypesByCompany = async (companyOwnerId: string) => {
    try {
        return await biddingTypeAxiosRequest.get(`/${companyOwnerId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getBiddingType = async (biddingTypeId: string) => {
    try {
        return await biddingTypeAxiosRequest.get(`/${biddingTypeId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createBiddingType = async (body: any) => {
    try {
        return await biddingTypeAxiosRequest.put(``, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateBiddingType = async (body: any) => {
    try {
        return await biddingTypeAxiosRequest.patch(``, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteBiddingType = async (biddingTypeId: string) => {
    try {
        return await biddingTypeAxiosRequest.delete(`/${biddingTypeId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const allDataGroupByTemplateIdArray = async (body: any) => {
    try {
        return await biddingTypeAxiosRequest.post(`/allDataGroupByTemplateIdArray`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


export default { 
    createBiddingType, 
    updateBiddingType, 
    deleteBiddingType, 
    getBiddingTypes, 
    getBiddingTypesByCompany, 
    getBiddingType,
    allDataGroupByTemplateIdArray,
    getBiddingTypeById
 };

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
