import SearchInput from "./SearchInput";

type Props = {
    text: string;
    withSearch?: boolean;
    beforeSearchElement?: React.ReactNode;
    onSearch?: (value: string) => void;
};

const DottedSubtitle = ({ text, withSearch, beforeSearchElement, onSearch }: Props) => {
    return (
        <div className="flex w-full justify-between items-center gap-4 mt-2">
            <h4 className="italic text-xl text-gray-600">{text}</h4>
            <div className="grow h-1 mt-1 border-t-[3px] border-dotted border-gray-600/80" />
            {beforeSearchElement}
            {withSearch && <SearchInput onChange={onSearch} />}
        </div>
    );
};

export default DottedSubtitle;
