import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react";
import { useState } from "react";
import { BsX } from "react-icons/bs";
import { MdNextPlan } from "react-icons/md";
import { TiChevronLeft, TiChevronRight } from "react-icons/ti";
import useCustomToast from "../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const SignaturesTopbar = ({ onCloseDrawer, onPrev, onNext, currentPage, totalPages, setCurrentPage, documentName }) => {
    const toast = useCustomToast();
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const [pageInputValue, setPageInputValue] = useState(1);

    const handleGoToPageByInput = () => {
        if (pageInputValue < 1 || pageInputValue > totalPages) {
            toast.error(t("invalidPage"), t("warning"));
        } else {
            setCurrentPage(pageInputValue);
        }
    };

    return (
        <header className="w-full bg-brand-600 px-4 py-5 flex items-center justify-between">
            <div className="flex gap-4 items-center">
                <button onClick={onCloseDrawer}>
                    <BsX color="white" size={34} />
                </button>
                <h2 className="text-white text-xl">{documentName}</h2>
            </div>

            <div className="flex items-center gap-8">
                <div className="flex items-center text-white gap-1">
                    <button className="disabled:text-gray-500/40 disabled:cursor-not-allowed" onClick={onPrev} disabled={false}>
                        <TiChevronLeft size={20} />
                    </button>
                    <span>
                        {t("page")} {currentPage}/{totalPages}
                    </span>
                    <button className="disabled:text-gray-500/40 disabled:cursor-not-allowed" onClick={onNext} disabled={false}>
                        <TiChevronRight size={20} />
                    </button>
                </div>

                <div className="flex items-center gap-1">
                    <NumberInput
                        size={"xs"}
                        bg="white"
                        w="80px"
                        variant={"filled"}
                        value={pageInputValue}
                        onChange={(_, n) => setPageInputValue(n)}
                        max={totalPages}
                        min={1}
                    >
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <button onClick={handleGoToPageByInput}>
                        <MdNextPlan color="white" size={26} />
                    </button>
                </div>
            </div>
        </header>
    );
};

export default SignaturesTopbar;
