import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import CommonModal from "../CommonModal";
import SearchInput from "../SearchInput";
import { useEffect, useMemo, useState } from "react";
import LoadingBar from "../LoadingBar";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react";
import AccordionItemBrand from "../AccordionItemBrand";

const firstColumn = [
    "¿Cómo crear una plantilla?",
    "¿Cómo crear grupos de datos?",
    "¿Cómo añadir una variable nueva a grupos de datos?",
    "¿Cómo editar una variable de grupo de datos?",
    "¿Cómo agregar el tipo de contrato?",
    "¿Cómo agregar encabezado y pie de página?",
    "¿Cómo activar una plantilla?",
    "¿Cómo agregar las variables al documento en plantilla?",
    "¿Cómo crear un contrato?",
    "¿Cómo crear contratos de forma masiva?",
    "¿Cómo mandar a revisar y/o editar un contrato?",
    "¿Cómo revisar un contrato?",
    "¿Cómo revisar y editar un contrato?",
    "¿Cómo duplicar un contrato?",
];

const secondColumn = [
    "¿Dónde encontrar mi archivo ya tokenizado?",
    "¿Cómo firmar con FEA un contrato?",
    "¿Cómo tokenizar un documento?",
    "¿Cómo agregar documentos externos a la bóveda Blockchain?",
    "¿Cómo dar de alta un usuario?",
    "¿Cómo cerrar sesión?",
    "¿Cómo descargar un archivo?",
    "¿Cómo facturar?",
    "¿Cómo contactar al equipo axeleratum?",
    "¿Cómo solicitar un modulo nuevo o integración con otros sistemas?",
    "¿Cómo reportar fallas?",
    "¿Cómo solicitar soporte técnico?",
    "¿Cómo solicitar ayuda a dar seguimiento a un incumplimiento de contrato...",
    "¿Cómo cambiar una política de negocio?",
];

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const FAQModal = ({ isOpen, onClose }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <CommonModal size="6xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col h-[80vh] overflow-auto">
                <div className="w-full flex items-end gap-6">
                    <div>
                        <h2 className="title-3">Preguntas frecuentes</h2>
                        <h3 className="text-dark">Encuentra la respuesta a tus dudas</h3>
                    </div>
                    <SearchInput onChange={(query) => setSearchQuery(query)} />
                </div>

                <div className="px-2 mt-4">
                    {/* <LoadingBar isLoading={false} /> */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
                        <div className="">
                            <Accordion>
                                {firstColumn.map((item, index) => (
                                    <AccordionItemBrand key={index} title={item} order="between">
                                        <div className="text-center">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea commodo consequat.
                                        </div>
                                    </AccordionItemBrand>
                                ))}
                            </Accordion>
                        </div>
                        <div className="">
                            <Accordion allowMultiple>
                                {secondColumn.map((item, index) => (
                                    <AccordionItemBrand key={index} title={item} order="between">
                                       <div className="text-center">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                                            laboris nisi ut aliquip ex ea commodo consequat.
                                        </div>
                                    </AccordionItemBrand>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
};

export default FAQModal;
