import { Button } from "@chakra-ui/react";
import { useState, useRef } from "react";
import { FiDownload, FiUpload } from "react-icons/fi";
import useCustomToast from "../../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { IMAGE_MIME_TYPES } from "../../../../lib/constants";
import { TemplateHeaderFooterModalData } from "../../../../models/Modals.models";
import CommonModal from "../../CommonModal";
import FileDropzone from "../../FileDropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import LinkButton from "../../buttons/LinkButton";
import { IoClose } from "react-icons/io5";
import useTemplateState from "../../../../store/templatesState";
import useVaultState from "src/store/vaultState";
import { Template } from "src/models/Template.models";
import { useNavigate } from "react-router-dom";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: TemplateHeaderFooterModalData;
};

const TemplateHeaderFooterModal = ({ isOpen, onClose, data }: Props) => {
    const toast = useCustomToast();
    const navigate = useNavigate();
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const { updateTemplate, getTemplate , saveTemplate } = useTemplateState();
    const [imgSrc, setImgSrc] = useState("");
    const [cropper, setCropper] = useState<any>();
    const [imageWidth, setImageWidth] = useState(null); 
    const [imageHeight, setImageHeight] = useState(null);  

    const uploadDocumentToSession = useVaultState((s) => s.addDocumentToSession);
    //temp
    const item = data.template[data.type]
    const hasItem = Boolean(item);

    async function handleUpload(file: File) {
        console.log("file", file);
        if (!IMAGE_MIME_TYPES.includes(file.type)) {
            return toast.error(t("invalidFileType"));
        }
    
        const reader = new FileReader();
    
        reader.addEventListener("load", () => {
            const img = new Image();
    
            img.onload = function() {
                const width = img.width;
                const height = img.height;

                setImageWidth(width);
                setImageHeight(height);

                setImgSrc(reader.result.toString() || "");
            };
    
            img.src = reader.result as string;
        });
    
        reader.readAsDataURL(file);
    }
    
    async function handleSaveImage() {
        if (typeof cropper !== "undefined") {
            const base64Image = cropper.getCroppedCanvas().toDataURL();
    
            const blob: Blob = await new Promise((resolve) => {
                cropper.getCroppedCanvas().toBlob((blob) => {
                    resolve(blob);
                });
            });
    
            const formData = new FormData();
            formData.append("file", blob,);

            const blobBody = {
                file: formData,
            };
    
            try {
                const res = await uploadDocumentToSession(blobBody);
    
                if (res) {

                    //Evitar request URI  too large , quitar footer on header sea el caso
                    const elementType = data.type === "footer" ? "header" : "footer";

                    // Crear un nuevo objeto sin el elemento contrario
              
                  /**  const updatedTemplate: Template = {
                        ...data.template,
                        name: data.templateName,
                        status: "borrador",
                        [data.type]: res.response
                    };

                    console.log("...data.template",data.template)
                    
                    delete (updatedTemplate as any)[elementType]; */

                    if(!data.template.id){

                        
                        const updatedTemplate: Template = {
                            ...data.template,
                            name: data.templateName,
                            status: "borrador",
                            [data.type]: res.response
                        };

                        
                        delete (updatedTemplate as any)[elementType];

                        const savedTemplate = await saveTemplate({
                            ...updatedTemplate,
                            } as Template);

                        if (savedTemplate) {
                                getTemplate(data.template.id, false);
                                toast.success(t("templateCreatedSuccessfully"));
                                navigate("/templates");
                            }else{
                                toast.error(t("templateSavedFailed"));
                            }
    
                    }else{
                        
                    const updatedTemplateCurrent: Template = {
                        ...data.template,
                        [data.type]: res.response
                    };

                    
                    delete (updatedTemplateCurrent as any)[elementType];

                        const updated = await updateTemplate(updatedTemplateCurrent);
                        if (updated) {
                            getTemplate(data.template.id, false);
                        }else{
                            toast.error(t("templateSavedFailed"));
                        }
                    }
                    //const updated = await updateTemplate({updatedTemplate, [data.type]: res.response });
                }
            } catch (error) {
                console.error("Error al cargar la imagen:", error);
            }
    
            onClose();
        }
    }
    
    
    const [previewImageUrl, setPreviewImageUrl] = useState('');

    // Lógica para cargar y mostrar la vista previa de la imagen
    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      console.log("fiel s",file)
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result as string;
          setPreviewImageUrl(imageUrl);
        };
        reader.readAsDataURL(file);
      }
    };


    function buildImgElement(base64String) {
        // Construir la cadena con la estructura del elemento img
        if (!base64String) {
            console.error("La cadena Base64 proporcionada está vacía o nula.");
            return ""; // O cualquier otro valor de retorno que desees
        }
        const base64Format = base64String.replace(/"/g, '');
        const imgUrl = `data:image/png;base64,${base64Format}`;
        return imgUrl;
    }
    return (
        <CommonModal size="3xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">{data?.type === "header" ? "Encabezado" : "Pie de página"}  de la plantilla</h2>

                <div className="flex flex-col justify-center">
                    <h3 className="text-base text-dark font-semibold text-center w-10/12 mx-auto">Encabezado actual:</h3>
                    <div className="text-center mt-3 w-11/12 lg:w-[600px] flex justify-center items-center mx-auto">
                        {hasItem ? (
                            <img className="w-full" src={buildImgElement(item)} />
                        ) : (
                            <p className="w-full text-center text-xl">No definido</p>
                        )}
                    </div>

                    <div className="flex flex-col justify-center mt-8">
                        <h3 className="text-base text-dark font-semibold text-center w-10/12 mx-auto">Subir nuevo encabezado:</h3>
                        
                        {/*
                        <input type="file" accept="image/*" onChange={handleImageUpload} />
                        previewImageUrl && <PreviewImage imageUrl={previewImageUrl} />*/}
                        <div className="flex flex-col items-center">
                            {!Boolean(imgSrc) && (
                                <div className="w-full mt-3">
                                    <FileDropzone onDropFile={handleUpload} />
                                </div>
                            )}
                            {Boolean(imgSrc) && (
                                <div className="w-full pt-6 relative">
                                    <div className="absolute top-0 right-0">
                                        <LinkButton fontSize="xs" leftIcon={<IoClose size={14} />} onClick={() => setImgSrc("")}>
                                            Cancelar
                                        </LinkButton>
                                    </div>

                                    <Cropper
                                        style={{ height: "auto", width: "600px" }}
                                        zoomTo={0.0}
                                        aspectRatio={imageWidth/imageHeight} // 1088 / 96 = 12
                                    //    aspectRatio={imageWidth / imageHeight} // Usar la proporción de aspecto basada en las dimensiones de la imagen
                                    
                                        src={imgSrc}
                                        viewMode={2} // Puedes probar diferentes valores aquí
                                        minCropBoxHeight={100}
                                        minCropBoxWidth={100}
                                        background={false}
                                        responsive={true}

                                        autoCropArea={1}
                                        checkOrientation={false}
                                        center={true}
                                        guides={false}
                                        movable={true} // Permitir que el área de recorte sea movible
                                        crop={(event) => console.log(event.detail)}
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                    />
                                    <img className="max-w-full img-preview" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {Boolean(imgSrc) && (
                    <div className="w-full flex justify-center">
                        <Button rounded="full" size="md" fontWeight="medium" onClick={handleSaveImage}>
                            {t("save")}
                        </Button>
                    </div>
                )}
            </div>
        </CommonModal>
    );
};

const PreviewImage = ({ imageUrl }: { imageUrl: string }) => {
    return (
      <div>
        <h2>Previsualización de la imagen:</h2>
        <img src={imageUrl} alt="Vista previa de la imagen" />
      </div>
    );
  };

export default TemplateHeaderFooterModal;
