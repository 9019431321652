import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

type Props = {
    onSign: (arg: any) => any;
    isSaving: boolean;
};

const InVaultActions = ({ onSign, isSaving }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="flex justify-center gap-2 pt-4 pb-2 sticky bottom-0 bg-white border-t">
            <Button rounded="full" size="md" fontWeight="medium" variant="solid" px={12} onClick={onSign} isLoading={isSaving}>
                {t("sign")}
            </Button>
        </div>
    );
};

export default InVaultActions;
