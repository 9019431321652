import { Icon } from "@chakra-ui/react";

const FolderVaultIcon = (props) => {
    return (
        <Icon viewBox="0 0 42 45" {...props}>
            <path
                fill="#2E54B8"
                d="M38.476 20.9v10.13c0 5.355-4.197 9.712-9.357 9.712H12.854c-5.159 0-9.356-4.357-9.356-9.712V17.94H38.02c.262.817.402 1.652.437 2.542.018.127.018.29.018.418Z"
            />
            <path
                fill="#2E54B8"
                d="M38.021 17.94H3.498v-5.482c0-4.43 3.463-8.024 7.73-8.024h4.075c2.85 0 3.743.962 4.88 2.541l2.448 3.377c.542.744.612.853 1.626.853h4.88c4.145-.018 7.677 2.814 8.884 6.735Z"
                opacity={0.4}
            />
        </Icon>
    );
};

export default FolderVaultIcon;
