import { useIntervalStore } from "src/store/interval.store"
import CustomAlertDialog from "./CustomAlertDialog"
import useAuthState from "src/store/authState"
import { useTimerStore } from "src/store/timer.store"
import Timer from "./Timer"
import authService from "src/services/auth.service"

const SessionExpireNotifier: React.FC = () => {
    /** */
    const intervals = useIntervalStore( s => s.intervals )
    const clearInterval = useIntervalStore( s => s.clearInterval )
    const onLogin = useAuthState( s => s.onLogin )
    const logout = useAuthState( s => s.logout )
    const checkSessionExpires = useAuthState( s => s.checkSessionExpires )
    const { stopTimer, removeTimer } = useTimerStore()
    const refreshToken = useAuthState( s => s.refreshToken )

    const handleSignOut = () => {
        stopTimer( 'SessionExpiresTimer' )
        removeTimer( 'SessionExpiresTimer' )
        clearInterval( 'SessionExpiresTimerInterval' )
        clearInterval( 'sessionInterval' )
        logout()
    }

    const handleUpdateSession = async () => {
        const response = await authService.updateSession( refreshToken )

        if ( response?.data?.access_token ) {
            stopTimer( 'SessionExpiresTimer' )
            removeTimer( 'SessionExpiresTimer' )
            clearInterval( 'SessionExpiresTimerInterval' )
            clearInterval( 'sessionInterval' )

            onLogin({
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token,
                expires_in: response.data.expires_in,
                refresh_expires_in: response.data.refresh_expires_in
            })

        } else {
            console.log( 'refrsh token error' )
            handleSignOut()
        }
    }

    return (
        <CustomAlertDialog
            id="SessionExpireNotifier"
            title="Expiración de sesión"
            cancelText="Cerrar sesión"
            acceptText="Seguir conectado"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            onCancel={handleSignOut}
            onAccept={handleUpdateSession}
        >
            {() => (
                <p>
                    Tu sesión expirará en 
                        <Timer id={"SessionExpiresTimer"}
                            from={60}
                            autoStart={true}
                            onEndTimer={handleSignOut}
                        />
                     ¿Qué quieres hacer?
                </p>
            )}
        </CustomAlertDialog>
    )
}

export default SessionExpireNotifier