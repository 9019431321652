import { IoClose, IoTrashOutline } from "react-icons/io5";
import CommonTh from "../common/table/CommonTh";
import useModalState from "../../store/modalsState";
import DatePickerTh from "../common/table/DatePickerTh";
import SelectTh from "../common/table/SelectTh";
import { useNavigate } from "react-router-dom";
import { Template } from "../../models/Template.models";
import { formatDate, getTemplateStatusColor } from "../../lib/utils";
import usePagination from "../../hooks/usePagination";
import useTemplateState from "../../store/templatesState";
import useCustomToast from "../../hooks/useCustomToast";
import { MouseEvent } from "react";
import { Badge, Tooltip } from "@chakra-ui/react";
import useTemplatesFilter from "../../hooks/table-filters/useTemplatesFilter";
import LinkButton from "../common/buttons/LinkButton";
import { TEMPLATE_STATUSES } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { GrClone } from "react-icons/gr";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";

const TableRow = ({
    template,
    onDelete,
    onClone,
}: {
    template: Template;
    onDelete: (event, template) => void;
    onClone: (event, template) => void;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const navigate = useNavigate();

    return (
        <tr
            className="border-b hover:bg-slate-100 transition-colors duration-200 cursor-pointer"
            onClick={() => navigate(`${template.id}`)}
        >
            {/* <td className="w-4 px-2 py-3" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center">
                    <Checkbox />
                </div>
            </td> */}
            <td className="px-2 py-3">{template.name}</td>
            <td className="px-2 py-3 capitalize">{template.userName}</td>
            <td className="px-2 py-3">{formatDate(new Date(template?.createdDate || "01-01-2022"))}</td>
            <td className="px-2 py-3 capitalize">{template.type}</td>
            <td className="px-2 py-3 capitalize">
                <Badge colorScheme={getTemplateStatusColor(template.status)}>{t(template.status)}</Badge>
            </td>
            <td className="px-2 py-3">{formatDate(new Date(template?.lastModifiedDate || "01-01-2022"))}</td>
            <td className="px-2 py-3">
                <div className="w-full flex xl:w-8/12 justify-center gap-6">
                    <Tooltip label="Clonar" fontSize="12px">
                        <button onClick={(e) => onClone(e, template)}>
                            <GrClone size={22} />
                        </button>
                    </Tooltip>
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={(e) => onDelete(e, template)}>
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const TemplatesTable = ({ templates }: { templates: Template[] }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const setDeleteTemplateModal = useModalState((s) => s.setDeleteTemplateModal);
    const { deleteTemplate, cloneTemplate, getTemplates } = useTemplateState();

    const { filteredTemplates, handleDateFilter, isFilterActive, clearFilters, handleStatusFilter, handleSearchFilter } =
        useTemplatesFilter(templates);
    const {
        currentResutls: paginatedTemplates,
        currentPage,
        maxPages,
        prev,
        next,
        setPage,
    } = usePagination<Template>({ data: filteredTemplates, itemsPerPage: 6 });

    async function onDeleteTemplate(template: Template) {
        const templateDeleted = await deleteTemplate(template.id);
        if (templateDeleted) {
            toast.success(t("deletedTemplateSuccess"));
            getTemplates(false);
        } else {
            toast.error(t("deletedTemplateError"));
        }
    }

    function handleDelete(e: MouseEvent, template: Template) {
        e.stopPropagation();
        setDeleteTemplateModal({ show: true, data: { template, onDelete: () => onDeleteTemplate(template) } });
    }

    async function handleClone(e: MouseEvent, template: Template) {
        e.stopPropagation();
        const cloned = await cloneTemplate(template);
        if (cloned) {
            toast.success(t("clonedTemplateSuccess"));
            getTemplates();
        } else {
            toast.error(t("clonedTemplateError"));
        }
    }

    return (
        <>
            <div className="w-full flex justify-between items-center">
                <DottedSubtitle
                    text={t("myTemplates")}
                    withSearch
                    onSearch={handleSearchFilter}
                    beforeSearchElement={
                        isFilterActive && (
                            <LinkButton onClick={clearFilters} color="red.400">
                                <IoClose size={20} /> {t("cleanFilters")}
                            </LinkButton>
                        )
                    }
                />
            </div>
            <div>
                <table className="w-full text-sm text-left text-dark-2">
                    <thead className="text-base text-brand-500 font-medium border-b">
                        <tr>
                            {/* <th scope="col" className="px-2">
                                <div className="flex items-center">
                                    <Checkbox />
                                </div>
                            </th> */}
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("name")} onClick={() => {}} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("author")} onClick={() => {}} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <DatePickerTh text={t("creation")} onChange={(args: any) => handleDateFilter(args, "createdDate")} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("type")} onClick={() => {}} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <SelectTh
                                    text={t("status")}
                                    options={TEMPLATE_STATUSES.map((s) => ({ label: t(s), value: s }))}
                                    onSelect={handleStatusFilter}
                                />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <DatePickerTh
                                    text={t("lastVersion")}
                                    onChange={(args: any) => handleDateFilter(args, "lastModifiedDate")}
                                />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                {t("actions")}
                            </th>
                        </tr>
                        <tr></tr>
                    </thead>
                    <tbody>
                        {paginatedTemplates?.map((item) => (
                            <TableRow key={item.id} template={item} onDelete={handleDelete} onClone={handleClone} />
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-between items-center">
                    {paginatedTemplates?.length ? (
                        <div className="w-full py-6">
                            <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                        </div>
                    ) : (
                        <div className="w-full text-center p-2 text-dark">{t("notElementsFound")}</div>
                    )}
                </div>
            </div>
        </>
    );
};
export default TemplatesTable;
