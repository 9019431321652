import BoxButton from "../../components/common/BoxButton";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect } from "react";
import useCustomToast from "../../hooks/useCustomToast";
import AddNewOutlineIcon from "../../components/icons/AddNewOutlineIcon";
import AddNewFilledIcon from "../../components/icons/AddNewFilledIcon";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useModalState from "src/store/modalsState";
import useBiddingTypesState from "src/store/biddings/biddingTypesState";
import BiddingTypesTable from "src/components/bidding/BiddingTypesTable";
import GoBackOutlineIcon from "src/components/icons/GoBackOutlineIcon";
import GoBackFilledIcon from "src/components/icons/GoBackFilledIcon";
import { useNavigate } from "react-router-dom";
import GoBackIcon from "src/components/icons/iconsV2/GoBackIcon";
import NewProcessTypeIcon from "src/components/icons/iconsV2/NewProcessTypeIcon";
import DottedSubtitle from "src/components/common/DottedSubtitle";

const BiddingTypesPage = () => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const setBiddingTypeModal = useModalState((s) => s.setBiddingTypeModal);

    const navigate = useNavigate();
    const toast = useCustomToast();
    const {
        biddingTypesByCompany: { data },
        getBiddingTypesByCompany,
    } = useBiddingTypesState();

    useEffect(() => {
        getBiddingTypesByCompany().catch((err) => {
            toast.error(t("listingBiddingsTypesError"));
        });
    }, []);

    return (
        <MainLayout>
            <main className="w-full p-6">
                <h2 className="title-3">{t("processes")}</h2>
                <DottedSubtitle text={t("createNewProcessType")} />

                <div className="flex space-x-6 mt-4">
                    <BoxButton
                        icon={<GoBackIcon boxSize={45} />}
                        //hoverIcon={<GoBackFilledIcon boxSize={70} />}
                        text={t("backToProccess")}
                        onClick={() => navigate("../")}
                    />
                    <BoxButton
                        icon={<NewProcessTypeIcon boxSize={45} />}
                        //hoverIcon={<AddNewFilledIcon boxSize={70} />}
                        text={t("generateProccessType")}
                        onClick={() => setBiddingTypeModal({ show: true, data: { typeModal: "New" } })}
                    />
                </div>

                <div className="mt-4">
                    <BiddingTypesTable biddingTypes={data} />
                </div>
            </main>
        </MainLayout>
    );
};

export default BiddingTypesPage;
