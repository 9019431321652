import { Tooltip } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import useCustomToast from "../../hooks/useCustomToast";
import usePagination from "../../hooks/usePagination";
import { AssociatedCompany } from "../../models/Aministration.models";
import useAdministrationState from "../../store/administrationState";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { BiChevronRight, BiUserPlus } from "react-icons/bi";
import useModalState, { confirmAlert } from "../../store/modalsState";
import SearchInput from "../common/SearchInput";
import CommonTh from "../common/table/CommonTh";
import TablePaginator from "../common/table/TablePaginator";
import LinkButton from "../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { AiOutlineMinusCircle } from "react-icons/ai";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";

const TableRow = ({ company, onDelete }: { company: AssociatedCompany; onDelete: (company: AssociatedCompany) => any }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 py-3">{`${company.foreignCompanyName}`}</td>
            <td className="p-2 py-3">{company.foreignCompanyRfc}</td>

            <td className="px-2 py-3">
                <div className="w-full xl:w-7/12 flex justify-center gap-6">
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={() => onDelete(company)} className="text-gray-700">
                            <AiOutlineMinusCircle size={25} color="inherit" />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const CompaniesAdminTable = ({
    companies,
    onDelete,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
}: {
    companies: AssociatedCompany[];
    onDelete: (companyId: string) => any;
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            {/* <div className="w-full flex justify-between items-center sticky top-0 z-10 py-3 bg-white border-b">
                <SearchInput onChange={onSearch} />
            </div> */}
            <table className="w-full text-sm text-left text-dark-2">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={"RFC"} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("actions")} />
                        </th>
                    </tr>
                    <tr></tr>
                </thead>
                <tbody>
                    {companies?.map((item) => (
                        <TableRow key={item.id} company={item} onDelete={() => onDelete(item.id)} />
                    ))}
                </tbody>
            </table>
            {companies?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

const CompaniesAdmin = () => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [searchQuery, setSearchQuery] = useState("");
    const { setNewEditUserModal, setDeleteCommonModal } = useModalState();
    const [companyType, setCompanyType] = useState<"internal" | "external">("internal");

    const {
        allCompanies,
        associatedCompanies: { data: associatedCompanies },
        getAssociatedCompanies,
        deleteCompanyAssociation,
    } = useAdministrationState();

    const { setAddNewCompanyModal } = useModalState();

    const filteredResults = useMemo(() => {
        return associatedCompanies?.filter(
            (comp) =>
                comp.foreignCompanyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                comp.foreignCompanyRfc.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [searchQuery, associatedCompanies]);

    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({ data: filteredResults, itemsPerPage: 6 });

    function handleAddCompany() {
        setAddNewCompanyModal({ show: true });
    }

    async function onDelete(compId: string) {
        const deleted = await deleteCompanyAssociation(compId);
        if (deleted) {
            toast.success("Compañia eliminada");
            getAssociatedCompanies(false);
        } else {
            toast.error("Error al eliminar la compañia");
        }
    }

    function handleDelete(compId: string) {
        confirmAlert.show({
            title: "Eliminar compañia",
            msg: "¿Estas seguro de eliminar esta compañia?",
            severity: "warning",
            onConfirm: () => onDelete(compId),
        });
    }

    // function handleEdit(user: User) {
    //     setNewEditUserModal({ show: true, data: { type: "edit", userType: userType, user: user } });
    // }

    useEffect(() => {
        getAssociatedCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-4 mb-4">
                <DottedSubtitle
                    text={t("companies")}
                    withSearch
                    onSearch={setSearchQuery}
                    beforeSearchElement={
                        <LinkButton leftIcon={<BiUserPlus size={20} />} onClick={() => handleAddCompany()}>
                            Agregar nueva empresa
                        </LinkButton>
                    }
                />
            </div>

            <CompaniesAdminTable
                companies={currentResutls}
                onDelete={handleDelete}
                currentPage={currentPage}
                maxPages={maxPages}
                prev={prev}
                next={next}
                setPage={setPage}
            />
        </>
    );
};

export default CompaniesAdmin;
