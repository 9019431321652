import { Accordion, Box, IconButton, Tooltip, useBoolean } from "@chakra-ui/react";
import { DragEvent, useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import LinkButton from "../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../../i18n/en";
import { AiOutlineLine } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { confirmAlert } from "src/store/modalsState";
import AddNewTypeForm from "./AddNewStageGroup";
import RequirementModal from "./RequirimentModal";
import NewRequirementCard from "./NewRequirementCard";
import { buildNestedStructure, sortGroupsAndSubgroups } from "src/lib/utils";
import CollapseItem from "../common/CollapseItem";

interface RequerimentsProps {
    alwaysNew: boolean
    biddingValidationTypeId: string
    documentTypeId: string[]
    fileLabel: string
    maxUploadable: number
    minUploadable: number
    name: string
    requerimentIdList: string[]
    reviewStatus: string
    signStatus: string
    templateId: string
}

interface GroupsProps {
    name: string;
    requirements?: RequerimentsProps[]
}

interface Item {
    name: string;
    originalName: string;
    requirements: RequerimentsProps[];
    children: Record<string, Item>;
}

const BiddingTypeGroupsPanel = ({
    setStage,
    stage,
    editGroup,
    editMasiveGroup,
    editGroupSubGroup,
    createSubGroup,
    deleteGroup,
    addRequirement,
    editRequirement,
    deleteRequirement,
    onClick,
}: {
    setStage: (any) => any,
    stage: any;
    editGroup: (nameGroup, oldNameGroup, stageName) => any;
    editMasiveGroup: (nameGroup, oldNameGroup, stageName) => any;
    editGroupSubGroup: (nameGroup, oldNameGroup, stageNam, subGroupName) => any;
    createSubGroup: (nameSubGroup) => any;
    deleteGroup: (any) => any;
    addRequirement: (any) => any;
    editRequirement: (any) => any;
    deleteRequirement: (any) => any;
    onClick: () => void;
}) => {
    const groups: GroupsProps[] = stage?.groups;
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [showEditGroup, setShowEditGroup] = useBoolean(false);
    const [showModalRequirement, setShowModalRequirement] = useBoolean();
    const [tempRequirement, setTempRequirement] = useState({});
    const [typeSave, setTypeSave] = useState("");
    const [tempGroupName, setTempGroupName] = useState("");
    const [tempSubGroupName, setTempSubGroupName] = useState("");
    const [subGroupName, setSubGroupName] = useState('');
    const [nameGroup, setNameGroup] = useState("");
    const [showAddSubGroup, setShowAddSubGroup] = useBoolean(false);
    const [draggedItemIndex, setDraggedItemIndex] = useState<number | null>(null);
    const [draggedReqIndex, setDraggedReqIndex] = useState<number | null>(null);
    const [draggedItemName, setDraggedItemName] = useState({});
    const [nestedStructure, setNestedStructure] = useState({});
    const [openGroups, setOpenGroups] = useState<string | null>(null);
    const [openSubGroups, setOpenSubGroups] = useState<Record<string, boolean>>({});

    const MAX_ITEMS_VISIBLE = 6; // Número máximo de elementos visibles sin expandir

    const [showAllItems, setShowAllItems] = useState(false);

    function openModaEditGroup(name) {
        const arrayNameGroup = name.split('!');
        var nameGroup = '';
        setSubGroupName(name)
        if (arrayNameGroup.length > 1) {
            nameGroup = arrayNameGroup[arrayNameGroup.length - 1]
        } else {
            nameGroup = name
        }
        setTempGroupName(nameGroup);
        setShowEditGroup.on();
    }

    function openModaAddSubGroup(name) {
        setNameGroup(name);
        setShowAddSubGroup.on();
    }

    function handleEditGroup(typeName, oldName) {
        editGroupSubGroup(typeName, oldName, stage.name, subGroupName)
        setShowEditGroup.off();
        //onClick();
    }

    function handleAddSubGroup(typeName) {
        createSubGroup(`${nameGroup}!${typeName}`);
        setShowAddSubGroup.off();
        //onClick();
    }

    function handleAddOrEditRequirement(requerimientData) {
        requerimientData.stage = stage.name;
        if (typeSave === "edit") {
            editRequirement(requerimientData);
            setShowModalRequirement.off();
            setTempRequirement({});
            setTypeSave("");
            //onClick();
        } else {
            addRequirement(requerimientData);
            setShowModalRequirement.off();
            setTempRequirement({});
            setTypeSave("");
            //onClick();
        }
    }

    function handleDeleteGroup(nameGroup) {
        deleteGroup(nameGroup);
    }

    function handleDeleteRequirement(requerimientName, nameGroup) {
        let req = { nameReq: requerimientName, group: nameGroup, stage: stage.name };
        deleteRequirement(req);
    }
    function handleOpen(name) {
        setTempGroupName(name);
        setShowModalRequirement.on();
    }

    function handleOpenEdit(req, name) {
        setTempGroupName(name);
        setTempRequirement(req);
        setTypeSave("edit");
        setShowModalRequirement.on();
    }

    function closeModal() {
        setTempRequirement({});
        setTypeSave("");
        setShowModalRequirement.off();
    }

    function handleDeleteReq(requirement, group) {
        confirmAlert.show({
            title: t("areYouSure"),
            msg: `El requerimiento ${requirement.name} será eliminado.`,
            severity: "warning",
            onConfirm: () => handleDeleteRequirement(requirement.name, group.name),
        });
    }

    const handleDragStartGroup = (index, draggedName, e: DragEvent<HTMLDivElement>, item) => {
        setDraggedItemIndex(index);
        setDraggedItemName(draggedName)
    }

    // Maneja el evento drop
    const handleDropGroups = (e: React.DragEvent<HTMLDivElement>, dropIndex: number, nameGroup: string) => {
        e.preventDefault();
        if (draggedItemIndex !== null) {
            const newItems = [...groups];
            var sortedData = [];
            if (nameGroup.includes('!')) {
                e.stopPropagation();
                const selectedGroupIndex = newItems.findIndex(e => e.name === draggedItemName)
                const targetItemIndex = newItems.findIndex(e => e.name === nameGroup)
                const [removedItem] = newItems.splice(selectedGroupIndex, 1);
                newItems.splice(targetItemIndex, 0, removedItem);
            } else {
                const groupsTotal = newItems?.filter(e => !e.name.includes('!'))
                const selectedGroupName = groupsTotal[draggedItemIndex].name
                const selectedGroupIndex = newItems.findIndex(e => e.name === selectedGroupName)
                const targetItemIndex = newItems.findIndex(e => e.name === nameGroup)
                const [removedItem] = newItems.splice(selectedGroupIndex, 1);
                newItems.splice(targetItemIndex, 0, removedItem);
                sortedData = sortGroupsAndSubgroups(newItems);
            }
            const newData = {
                ...stage,
                groups: nameGroup.includes('!') ? newItems : sortedData
            }
            setStage(newData);
            setDraggedItemIndex(null);
        }
    };

    // Maneja el evento dragOver
    const handleDragOverGroups = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const handleDragStartSubGroup = (index, draggedName, e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setDraggedItemIndex(index);
        setDraggedItemName(draggedName)
    }

    // Maneja el evento dragOver
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragStart = (index, e: DragEvent<HTMLDivElement>) => {
        setDraggedReqIndex(index);
    }

    // Maneja el evento drop
    const handleDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number, nameGroup: string) => {
        e.stopPropagation();
        e.preventDefault();
        if (draggedReqIndex !== null) {
            var groupSelected = groups.findIndex(e => e.name === nameGroup)
            const newItems = [...groups[groupSelected].requirements];
            const [removedItem] = newItems.splice(draggedReqIndex, 1);
            newItems.splice(dropIndex, 0, removedItem);
            const groupSelect = {
                ...groups[groupSelected],
                requirements: newItems
            }
            const newData = {
                ...stage,
                groups: stage.groups.map((e, idx) => idx === groupSelected ? groupSelect : e)
            }
            setStage(newData);
            setDraggedReqIndex(null);
        }
    };

    const handleToggleGroup = (id: string) => {
        setOpenGroups((prevOpenGroups) => (prevOpenGroups === id ? null : id));
        setOpenSubGroups({}); // Close all subgroups when changing the main group
    };

    const handleToggleSubGroup = (id: string) => {
        setOpenSubGroups((prevOpenSubGroups) => ({
            ...prevOpenSubGroups,
            [id]: !prevOpenSubGroups[id],
        }));
    };

    useEffect(() => {
        setNestedStructure(buildNestedStructure(groups));
    }, [stage, groups])

    const actionsButtons = (name: string) => {
        return (
            <div className="flex items-center gap-2">
                <LinkButton leftIcon={<BiPlus size={15} />} onClick={() => openModaAddSubGroup(name)}>
                    {/* {t("addGroup")} */}
                    Añadir nuevo subgrupo
                </LinkButton>
                <Tooltip label={t("editTypeBiddingGroup")} fontSize="15px">
                    <IconButton
                        variant="outline"
                        rounded="full"
                        colorScheme="blue"
                        aria-label="edit"
                        size={"xs"}
                        onClick={() => openModaEditGroup(name)}
                        icon={<FiEdit3 size={15} />}
                    />
                </Tooltip>
                <Tooltip label={t("deleteTypeBiddingGroup")} fontSize="15px" className="space-x-2">
                    <IconButton
                        variant="outline"
                        rounded="full"
                        colorScheme="red"
                        aria-label="cancel"
                        size={"xs"}
                        icon={<AiOutlineLine size={15} />}
                        onClick={() => confirmAlert.show({
                            title: t("areYouSure"),
                            msg: t("onceSavedCannotBeChanged"),
                            severity: "info",
                            onConfirm: () => handleDeleteGroup(name),
                        })
                        }
                    />
                </Tooltip>
            </div>
        );
    };

    const NestedComponent = (data: Record<string, Item>, parentId?: string) => (
        <Box>
            {Object.values(data).map((item, index) => {
                const isOpen = parentId ? openSubGroups[item.name] : openGroups === item.name;
                const nameArray = item.originalName.split('/');
                const title = `${nameArray.length > 1 ? '/' : ''} ${nameArray[nameArray.length - 1]}`
                return <CollapseItem
                key={item.name}
                id={item.name}
                title={title}
                actions={actionsButtons(item.name)}
                isOpen={isOpen}
                onToggle={() => (parentId ? handleToggleSubGroup(item.name) : handleToggleGroup(item.name))}
                onDragStart={(e) => (item.name.includes('!') ? handleDragStartSubGroup : handleDragStartGroup)(index!, item.name, e, item)}
                onDragOver={handleDragOverGroups}
                onDrop={(e) => handleDropGroups(e, index, item.name)}
            >
                <>
                    {showEditGroup && (
                        <AddNewTypeForm
                            onCancel={setShowEditGroup.off}
                            onSave={handleEditGroup}
                            name={tempGroupName}
                            label={"Editar Grupo"}
                            isOpen={true}
                        />
                    )}
                    {showAddSubGroup && (
                        <AddNewTypeForm
                            onCancel={setShowAddSubGroup.off}
                            onSave={handleAddSubGroup}
                            name={tempSubGroupName}
                            label={"Agregar Subgrupo"}
                            isOpen={true}
                        />
                    )}
                    <div className="mb-2">
                        <div className="text-right mt-2 mb-2">
                            <LinkButton leftIcon={<BiPlus size={15} />} onClick={() => handleOpen(item.name)}>
                                {t("addNewTypeBiddingRequirement")}
                            </LinkButton>
                        </div>
                        <div className="w-full max-h-[500px] overflow-y-auto">
                            <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mt-3">
                                {item?.requirements?.map((requirement, idx) => (
                                    <div
                                        key={idx}
                                        draggable
                                        onDragStart={(e) => handleDragStart(idx, e)}
                                        onDragOver={handleDragOver}
                                        onDrop={(e) => handleDrop(e, idx, item.name)}
                                    >
                                        <NewRequirementCard
                                            requirement={requirement}
                                            onEdit={() => handleOpenEdit(requirement, item.name)}
                                            onDelete={() => handleDeleteReq(requirement, item)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {showModalRequirement && (
                            <RequirementModal
                                isOpen={true}
                                onClose={closeModal}
                                onSave={handleAddOrEditRequirement}
                                groupName={tempGroupName}
                                stageName={stage.name}
                                data={tempRequirement}
                                currentRequerimients={item.requirements}
                            />
                        )}
                    </div>
                    {Object.keys(item.children).length > 0 && NestedComponent(item.children, item.name)}
                </>
            </CollapseItem>
            })}
        </Box>
    )

    return <Box>{NestedComponent(nestedStructure)}</Box>;
};

export default BiddingTypeGroupsPanel;
