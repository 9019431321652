import { useEffect, useRef } from "react"

const useMountedEffect = ( effect: () => void ) => {
    /** */
    const hasMounted = useRef( false )

    useEffect( () => {
        if ( !hasMounted.current ) {

            hasMounted.current = true

            effect()
        }
    }, [ effect ] )

}

export default useMountedEffect