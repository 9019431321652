import { Link } from "react-router-dom"
import UserButtonMenu from "../UserButtonMenu"
const logo = require(`../../assets/images/${process.env.REACT_APP_LOGO}.png`)

interface NavbarShortProps {
    sidebarWidth: number
    elRef: any
}

const NavbarShort: React.FC<NavbarShortProps> = ({ sidebarWidth, elRef }) => {
    /** */

    return (
        <header ref={elRef}
            style={{ width: `calc(100% - ${sidebarWidth}rem)` }}
            className="flex items-center justify-between pb-2 pt-3 px-2"
        >
            <Link to="/" className="pr-6 border-r-2 border-gray-300 min-w-[100px] py-1">
                <img className="max-w-[130px] w-full" src={logo} alt="logo" />
            </Link>
            <div className="flex items-center gap-4">
                <UserButtonMenu />
            </div>
        </header>
    )
}

export default NavbarShort