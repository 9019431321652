import { useCallback, useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { PageViewport, PDFDocumentLoadingTask, PDFDocumentProxy } from "pdfjs-dist";
import usePageZoom from "./usePageZoom";

const MIN_SCALE = 0.75;
const MAX_SCALE = 1.26;

const usePdfCanvasRender = (url) => {
    // eslint-disable-next-line no-undef
    const canvasRef = useRef<HTMLCanvasElement>(null);
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const { zoom } = usePageZoom();
    const responsiveScale = Math.min(Math.max((100 - (zoom - 100)) / 100, MIN_SCALE), MAX_SCALE);

    const [pdfRef, setPdfRef] = useState<PDFDocumentProxy>();
    const [canvasHeight, setCanvasHeight] = useState<number>();
    const [canvasWidth, setCanvasWidth] = useState<number>();
    const [viewport, setViewport] = useState<PageViewport>();
    const [totalPages, setTotalPages] = useState<number>(0);

    const setCanvasSize = async (pageNum, pdf = pdfRef) => {
        if (pdf) {
            const page = await pdf.getPage(pageNum);
            let viewport = page.getViewport({ scale: responsiveScale });
            const canvas = canvasRef.current;

            setCanvasWidth(canvas.width);
            setCanvasHeight(canvas.height);
            setViewport(viewport);
        }
    };

    const renderPage = useCallback(
        async (pageNum: number, pdf = pdfRef) => {
            if (pdf) {
                const page = await pdf.getPage(pageNum);
                const viewport = page.getViewport({ scale: responsiveScale });

                const canvas = canvasRef.current;
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {
                    canvasContext: canvas.getContext("2d"),
                    viewport: viewport,
                };

                const pageRendering = page.render(renderContext);

                pageRendering.promise.then(
                    function () {},
                    function (error) {
                        console.error(error);
                    }
                );
            }
        },
        [pdfRef]
    );

    useEffect(() => {
        const loadingTask: PDFDocumentLoadingTask = pdfjsLib.getDocument(url);
        loadingTask.promise.then(
            (loadedPdf) => {
                setPdfRef(loadedPdf);
                setTotalPages(loadedPdf.numPages);
            },
            function (reason) {
                console.error(reason);
            }
        );
    }, [url]);

    return { canvasRef, renderPage, pdfRef, canvasHeight, canvasWidth, setCanvasSize, viewport, scale: responsiveScale, totalPages };
};

export default usePdfCanvasRender;
