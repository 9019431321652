import { Checkbox, Radio, RadioGroup } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import useCustomToast from "../../hooks/useCustomToast";
import usePagination from "../../hooks/usePagination";
import { UserGroup } from "../../models/Aministration.models";
import useAdministrationState from "../../store/administrationState";
import { BiUserPlus } from "react-icons/bi";
import useModalState from "../../store/modalsState";
import CommonTh from "../common/table/CommonTh";
import LinkButton from "../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";

const TableRow = ({
    userGroup,
    selectedUserGroup,
    onSelectUserGroup,
}: {
    userGroup: UserGroup;
    selectedUserGroup: any;
    onSelectUserGroup: any;
}) => {
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 w-18 pl-6">
                <RadioGroup onChange={() => onSelectUserGroup(userGroup.id)} value={selectedUserGroup}>
                    <Radio value={userGroup.id} />
                </RadioGroup>
            </td>
            <td className="p-2 py-3">{`${userGroup.name}`}</td>
            <td className="p-2 py-3">{userGroup.description}</td>
        </tr>
    );
};

const GenerateUsersTable = ({
    userGroup,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
    selectedUserGroup,
    onSelectUserGroup,
}: {
    userGroup: UserGroup[];
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
    onSelectUserGroup: any;
    selectedUserGroup: any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            <table className="w-full text-sm text-left text-dark-2 cursor-pointer">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2"></th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("description")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {userGroup?.map((item) => (
                        <TableRow
                            key={item.id}
                            userGroup={item}
                            selectedUserGroup={selectedUserGroup}
                            onSelectUserGroup={onSelectUserGroup}
                        />
                    ))}
                </tbody>
            </table>
            {userGroup?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

const UsersGroupAdmin = ({ selectedUserGroup, handleSelectUserGroup }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [searchQuery, setSearchQuery] = useState("");
    const { setNewEditUsersGroupModal } = useModalState();

    const {
        companyUsersGroups: { data: usersGroups },
        getCompanyUserGroups,
    } = useAdministrationState();

    const filteredResults = useMemo(() => {
        return usersGroups?.filter((userGroup) => userGroup.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [searchQuery, usersGroups]);

    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({ data: filteredResults, itemsPerPage: 6 });

    useEffect(() => {
        getCompanyUserGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <GenerateUsersTable
                userGroup={currentResutls}
                currentPage={currentPage}
                maxPages={maxPages}
                prev={prev}
                next={next}
                setPage={setPage}
                selectedUserGroup={selectedUserGroup}
                onSelectUserGroup={handleSelectUserGroup}
            />
        </>
    );
};

export default UsersGroupAdmin;
