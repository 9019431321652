import { Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../components/layouts/MainLayout";
import useCustomToast from "../../hooks/useCustomToast";
import { DataType } from "../../models/DataType.models";
import useDataGroupsState from "../../store/dataGroupsState";
import useDataTypesState from "../../store/dataTypesState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useAdministrationState from "src/store/administrationState";
import UserGroupsTable from "src/components/common/table/administration/UsersGroupsTable";
import UsersAdminTable from "src/components/common/table/administration/UsersAdminTable";
import BackIcon from "../../assets/bidding-icons/BackIcon.png";

const AssignUserGroupsPage = () => {
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedUserGroups, setSelectedUserGroups] = useState([]);

    const { setCurrentNewDataGroup, currentNewDataGroup, createDataGroup } = useDataGroupsState();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const toast = useCustomToast();
    const { getDataTypes } = useDataTypesState();

    const {
        createCompanyUserGroupAnnexe,
        getCompanyUserGroupAnnexe,
        updateCompanyUserGroupAnnexe,
        annexeCompanyUserGroup: { data: annexeCompanyUserGroup },
    } = useAdministrationState();

    const handleSelectUser = (user: any) => {
        setSelectedUser(user);
    };

    const handleSelectUserGroup = (groupId: any) => {
        const isSelected = selectedUserGroups.includes(groupId);

        if (isSelected) {
            setSelectedUserGroups(selectedUserGroups.filter((id) => id !== groupId));
        } else {
            setSelectedUserGroups([...selectedUserGroups, groupId]);
        }
    };

    const handleSaveUserGroups = () => {
        if (annexeCompanyUserGroup && annexeCompanyUserGroup.length === 0) {
            createCompanyUserGroupAnnexe({ selectedUser, selectedUserGroups });
        } else {
            updateCompanyUserGroupAnnexe({ annexeId: annexeCompanyUserGroup[0]?.id, selectedUserGroups });
        }
        toast.success("Usuarios guardados correctamente");
    };

    useEffect(() => {
        getDataTypes();
        return () => setCurrentNewDataGroup(null);
    }, []);

    useEffect(() => {
        if (selectedUser) {
            getCompanyUserGroupAnnexe(selectedUser);
        }
    }, [selectedUser]);

    useEffect(() => {
        if (annexeCompanyUserGroup && annexeCompanyUserGroup.length > 0 && selectedUser) {
            setSelectedUserGroups(annexeCompanyUserGroup[0]?.userGroupIdList);
        } else {
            setSelectedUserGroups([]);
        }
    }, [annexeCompanyUserGroup]);

    return (
        <MainLayout>
            <main className="w-full p-6 h-full">
                <div className="flex flex-col lg:flex-row gap-2 pt-2 pb-2 sticky bottom-0 bg-white border-b border-dashed mb-4">
                    <button onClick={() => navigate("/administration")}>
                        <img src={BackIcon} alt="back icon" className="w-8 pb-2"></img>
                    </button>
                    <section className="mb-4 pl-4">
                        <h2 className="title-3">{t("createUserGroup")}</h2>
                        <p className="mt-2 text-xs text-justify">{t("assignYourUserGroup")}</p>
                    </section>
                </div>

                <section className="flex">
                    {/* left panel */}
                    <section className="w-5/12 pr-4 h-full max-h-full overflow-y-auto border-r">
                        <UsersAdminTable selectedUser={selectedUser} handleSelectUser={handleSelectUser} />
                    </section>
                    {/* right panel */}
                    <section className="w-7/12 pl-4 h-full max-h-full overflow-y-auto">
                        <UserGroupsTable selectedUserGroups={selectedUserGroups} handleSelectUserGroup={handleSelectUserGroup} />
                        <div className="pb-2 w-full text-center">
                            <Button
                                type="submit"
                                rounded="full"
                                size="md"
                                onClick={handleSaveUserGroups}
                                fontWeight="medium"
                                px={"2rem"}
                                isLoading={false}
                            >
                                {t("save")}
                            </Button>
                        </div>
                    </section>
                </section>
            </main>
        </MainLayout>
    );
};

export default AssignUserGroupsPage;
