import { format, parseISO } from "date-fns";
import jwt from "jwt-decode";
import TimeAgo from "javascript-time-ago";
import es from "javascript-time-ago/locale/es.json";
import en from "javascript-time-ago/locale/en.json";
import { ContractStatus } from "../models/Contract.models";
import { TemplateStatus } from "../models/Template.models";
import { DataTypesTypes } from "../models/DataType.models";
import { BiddingStatus } from "src/models/biddings/Bidding.models";

TimeAgo.addLocale(es);
TimeAgo.addLocale(en);
export const timeAgo = new TimeAgo("es");

export const isExpired = (token: string) => {
    const exp = decodeToken(token).exp;
    let date_r = +new Date(exp * 1000);

    if (Date.now() > date_r) {
        return true;
    }

    return false;
};

export const isAuth = (token: string) => !isExpired(token);

export function decodeToken(token: string): any {
    const tokenDecoded = jwt(token);
    return tokenDecoded;
}

export function getAuthHeader() {
    let token = JSON.parse(sessionStorage.authUser ?? "{}").state?.accessToken;
    if (token) {
        return { Authorization: "Bearer " + token };
    } else {
        return {};
    }
}

export function formatDate(date: any, whitHourAndMinute = false) {
    if (!date) return "---";
    try {
        const parsedDate = new Date(date);
        return format(parsedDate, `dd/MM/yyyy${whitHourAndMinute ? " - hh:mm" : ""}`);
    } catch (error) {
        return "---";
    }
}

// parse to ISO
export function formatStringToDate(date: string): Date {
    return parseISO(date);
}

export function formatDateToString(date: Date | string): string {
    if (typeof date === 'string') {
        const parsedDate = new Date(date);
        return format(parsedDate, `yyyy-MM-dd`)
    }
    return format(date, `yyyy-MM-dd`)
}

export function bytesToMegabytes(bytes: number) {
    if (!bytes) return "---";
    return (bytes / 1024 / 1024).toFixed(2);
}

export function chunksArray(arr: any[], chunkSize: number) {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        chunks.push(chunk);
    }

    return chunks;
}

export function getTimeAgo(date: string) {
    try {
        //return moment(date).fromNow(true).replace("a","1")
        return timeAgo.format(new Date(date), "round");
    } catch (error) {
        return "";
    }
}

export function blobToBase64(blob: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsDataURL(blob);
    });
}

export function b64toBlob(b64Data: any, contentType = "", sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

//get mimetype from a given file extension
export function getMimeType(extension: string) {
    switch (extension?.toLowerCase()) {
        case "pdf":
            return "application/pdf";
        case "doc":
            return "application/msword";
        case "docx":
            return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        case "xls":
            return "application/vnd.ms-excel";
        case "xlsx":
            return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        case "ppt":
            return "application/vnd.ms-powerpoint";
        case "pptx":
            return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
        case "jpg":
            return "image/jpeg";
        case "jpeg":
            return "image/jpeg";
        case "png":
            return "image/png";
        case "gif":
            return "image/gif";
        case "xml":
            return "text/xml";
        case "html":
            return "text/html";
        case "txt":
            return "text/plain";
        case "json":
            return "application/json";
        case "zip":
            return "application/zip";
        case "mp3":
            return "audio/mpeg";
        case "mp4":
            return "video/mp4";
        case "avi":
            return "video/x-msvideo";
        case "wmv":
            return "video/x-ms-wmv";
        case "mov":
            return "video/quicktime";
        case "flv":
            return "video/x-flv";
        case "ogg":
            return "video/ogg";
        case "webm":
            return "video/webm";
        case "csv":
            return "text/csv";
        default:
            return "application/octet-stream";
    }
}

export function isObjectId(id: string): boolean {
    return `${id}`.length === 24;
}

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, "gm"), replace);
}

export function getColorFromStr(str: string) {
    const color = "#" + intToRGB(hashCode(str));
    return color;
}

export function getTitleByStatus(status: ContractStatus): string {
    switch (status?.toLowerCase()) {
        case "borrador":
            return "1. Crear contrato";
        case "revision":
        case "rechazado":
        case "aprobado":
            return "2. Revisiones";
        case "en boveda":
            return "3. En boveda";
        case "pendiente de firmas":
            return "4. Pendiente de firmas";
        case "pendiente de firmas":
            return "4. Firmado";
        case "tokenizado":
            return "6. Tokenizado";
        default:
            return "Contrato";
    }
}

export function getContractStepByStatus(status: ContractStatus): number {
    switch (status?.toLowerCase()) {
        default:
        case "borrador":
            return 1;
        case "revision":
        case "rechazado":
        case "aprobado":
            return 2;
        case "en boveda":
            return 3;
        case "pendiente de firmas":
        case "firmado":
            return 4;
        case "tokenizado":
            return 5;
    }
}

export function getTemplateStepByStatus(status: TemplateStatus): number {
    switch (status?.toLowerCase()) {
        case "borrador":
            return 2;
        case "activo":
            return 3;
        default:
            return 1;
    }
}

export function getTemplateStatusColor(status: TemplateStatus) {
    switch (status?.toLowerCase()) {
        case "activo":
            return "green";
        case "borrador":
            return "orange";
        default:
            return "";
    }
}

export function getContractStatusColor(status: ContractStatus) {
    switch (status?.toLowerCase()) {
        case "tokenizado":
            return "green";
        case "borrador":
            return "orange";
        case "rechazado":
            return "red";
        case "revision":
            return "blue";
        case "aprobado":
            return "teal";
        case "en boveda":
            return "purple";
        case "pendiente de firmas":
            return "gray";
        case "firmado":
            return "pink";
        default:
            return "";
    }
}

export function getBiddingStatusColor(status: BiddingStatus) {
    switch ((status ?? "-")?.toLowerCase()) {
        case "aceptado":
            return "green";
        case "bajo revision":
            return "orange";
        case "rechazado":
            return "red";
        default:
            return "gray";
    }
}

export function getStepNameByStatus(status: ContractStatus): string {
    switch (status?.toLowerCase()) {
        case "borrador":
            return "Creación";
        case "revision":
        case "rechazado":
        case "aprobado":
            return "Revision";
        case "en boveda":
            return "En boveda";
        case "pendiente de firmas":
            return "Firmas";
        case "firmado":
            return "Firmado";
        case "tokenizado":
            return "Tokenizado";
        default:
            return "N/A";
    }
}

export function getHomeContractStatusColor(status: ContractStatus): string {
    switch (status?.toLowerCase()) {
        case "borrador":
        case "revision":
        case "en boveda":
        case "firmado":
            return "#56BDF8";
        case "aprobado":
        case "tokenizado":
            return "#61DFB2";
        case "rechazado":
            return "#EC5A5A";
        case "pendiente de firmas":
            return "#F3D464";
        default:
            return "#56BDF8";
    }
}

export function parseDataTypes(type: DataTypesTypes) {
    switch (type) {
        case "string":
            return "text";
        case "number":
            return "numeric";
        case "double":
            return "numeric";
        case "date":
            return "date";
        case "selectable":
            return "selectable";
        default:
            return "N/A";
    }
}

export function sliceText(text: string, maxChars: number) {
    return text.length > maxChars ? text.slice(0, maxChars) + "..." : text;
}

export function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function loadScript(scriptId: string, scriptSrc: string, location: "head" | "body" = "head") {
    return new Promise((resolve, reject) => {
        const existingScript = document.getElementById(scriptId);
        if (!existingScript) {
            const script = document.createElement("script");
            script.src = scriptSrc;
            script.id = scriptId;
            document[location].appendChild(script);
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                reject(false);
            };
        }
        if (existingScript) resolve(true);
    });
}

export async function loadExternalCss(cssId: string, cssUrl: string) {
    return new Promise((resolve, reject) => {
        const existingCss = document.getElementById(cssId);
        if (!existingCss) {
            const css = document.createElement("link");
            css.href = cssUrl;
            css.id = cssId;
            css.rel = "stylesheet";
            document.head.appendChild(css);
            css.onload = () => {
                resolve(true);
            };
            css.onerror = () => {
                reject(false);
            };
        }
        if (existingCss) resolve(true);
    });
}

export async function htmlToBase64(htmlString: string): Promise<string> {
    const blob = new Blob([htmlString], { type: "text/html" });
    const reader = new FileReader();

    return new Promise<string>((resolve, reject) => {
        reader.onload = () => {
            if (typeof reader.result === "string") {
                let base64String = reader.result;
                base64String = base64String.replace(/^data:text\/html;base64,/, "");
                resolve(base64String);
            } else {
                reject("Error reading HTML as Base64");
            }
        };

        reader.onerror = () => {
            reject("Error reading HTML as Base64");
        };

        reader.readAsDataURL(blob);
    });
}

export function getDifferenceInMilliseconds(beforeDate: Date, afterDate: Date): number {
    /** */
    return Math.abs(beforeDate.getTime() - afterDate.getTime())
}

export function buildNestedStructure(data) {
    const root = {};

    data.forEach((item) => {
        const originalName = item.name.replace(/!/g, '/');
        const levels = item.name.split('!');
        let current = root;

        levels.forEach((level, index) => {
            if (!current[level]) {
                current[level] = {
                    name: item.name,
                    originalName: originalName,
                    requirements: [],
                    children: {}
                };
            }
            if (index === levels.length - 1) {
                current[level].requirements = item.requirements;
            }
            current = current[level].children;
        });
    });

    return root;
};

export function fixEncoding(str) {
    // Primero, convierte los caracteres mal formados usando escape
    const escapedStr = escape(str);
    // Luego, decodifica el URI para obtener la cadena correcta
    const fixedStr = decodeURIComponent(escapedStr);
    return fixedStr;
}

export function replaceByIndex(lookupArray, dataArray, replaceArray) {
    const dataMap = dataArray.reduce((acc, item) => {
        acc[item.name] = item;
        return acc;
    }, {});

    const replacedData = lookupArray.map((name, index) => {
        const data = dataMap[name];
        if (data) {
            return {
                ...data,
                name: replaceArray[index]
            };
        }
        return null;
    }).filter(item => item !== null);

    const remainingData = dataArray.filter(item => !lookupArray.includes(item.name));

    return [...replacedData, ...remainingData];
};

export function sortGroupsAndSubgroups(data) {
    const rootGroups = [];
    const subGroups = [];

    data.forEach(item => {
        if (item.name.includes('!')) {
            subGroups.push(item);
        } else {
            rootGroups.push(item);
        }
    });

    //rootGroups.sort((a, b) => (a.name > b.name ? 1 : -1));

    const result = [];
    rootGroups.forEach(rootGroup => {
        result.push(rootGroup);
        subGroups.forEach(subGroup => {
            if (subGroup.name.startsWith(rootGroup.name + '!')) {
                result.push(subGroup);
            }
        });
    });

    return result;
}


//aux functions
function hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

function intToRGB(i) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
}
