import { BiddingDocumentType } from "src/models/biddings/BiddingDocumentType.models";
import { BiddingProfile } from "src/models/biddings/BiddingProfile.models";
import biddingDocumentTypeService from "src/services/biddings/biddingDocumentType.service";
import biddingProfileService from "src/services/biddings/biddingProfile.service";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import useAuthState from "../authState";
import useModalState from "../modalsState";
import useCustomToast from "src/hooks/useCustomToast";

interface State {
    modalTemplateId: string;
    biddingProfile: { loading: boolean; error: boolean; data: any[] };
    biddingsProfileCompany: { loading: boolean; error: boolean; data: any[] }; //falta model Profile by company
    profilesBidding: { loading: boolean; error: boolean; data: any[] };
    selectedBiddingProfile: BiddingProfile;
    setSelectedBiddingProfile: (biddingDocumentType: BiddingProfile) => void;
    //getBiddingProfileCompany: (withLoading?: boolean) => any;
    getBiddingProfileCompany: (biddingId: string, withLoading?: boolean) => Promise<any | null>;
    getProfilesBiddingById: (biddingId: string, withLoading?: boolean) => Promise<any | null>;
    createBiddingProfile: (biddingDocumentType: any, withLoading?: boolean) => Promise<boolean>;
    deleteBiddingProfile: (profile: any, withLoading?: boolean) => Promise<boolean>;
    updateBiddingProfile: (profile: any, withLoading?: boolean) => Promise<boolean>;
    updateProfileBiddingList: (body: any, withLoading?: boolean) => Promise<boolean>;
    getProfile: (companyProfileId: string, withLoading?: boolean) => Promise<any | null>;
    setModalTemplateId: (templateId: string) => void;
    reset: () => void;
}

const initialState = {
    modalTemplateId: '',
    biddingProfile: { loading: false, error: false, data: [] },
    biddingsProfileCompany: { loading: false, error: false, data: [] },
    profilesBidding: { loading: false, error: false, data: [] },
    selectedBiddingProfile: null,
};

const useBiddingProfileState = create<State>((set, get) => ({
    ...initialState,
    setModalTemplateId: (templateId: string) =>  set({ modalTemplateId: templateId }),
    setSelectedBiddingProfile: (biddingProfile: any) => set({ selectedBiddingProfile: biddingProfile }),
    createBiddingProfile: createBiddingProfile(set, get),
    getBiddingProfileCompany: getBiddingProfiles(set, get),
    getProfilesBiddingById: getProfilesBiddingById(set, get),
    deleteBiddingProfile: deleteBiddingProfile(set, get),
    updateBiddingProfile: updateBiddingProfile(set, get),
    updateProfileBiddingList: updateProfileBiddingList(set, get),
    getProfile: getProfile(set, get),
    reset: () => set((state) => ({ ...state, ...initialState })),
}));

export default useBiddingProfileState;

function hasPermissionForAction(reponse: any, msgPermission: string, redirect: boolean) {
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text2: msgPermission,
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                redirectHome: redirect,
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    }
}

function createBiddingProfile(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingProfile: BiddingProfile, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingProfileService.createProfileBidding(biddingProfile);

        hasPermissionForAction(res, "Crear perfil", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating profile bidding");
            return false;
        }
    };
}
function getBiddingProfileCompany(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        let biddingId = "1";
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingProfileService.getProfileBiddingByCompany(biddingId, idCompany);

        hasPermissionForAction(res, "Obtener perfiles", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ biddingsProfileCompany: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching profile biddings");
            set((state) => ({
                biddingsProfileCompany: { loading: false, error: true, data: state.biddingsProfileCompany.data },
            }));
        }
    };
}

function getBiddingProfiles(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingProfileService.getProfileBiddingByCompany(biddingId, idCompany);

        hasPermissionForAction(res, "Obtener perfiles", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ selectedBiddingProfile: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            set({ selectedBiddingProfile: null });
            return null;
        }
    };
}

function getProfilesBiddingById(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingProfileService.getProfilesByBiddingId(biddingId);

        hasPermissionForAction(res, "Obtener perfiles del proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            set({ profilesBidding: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            set({ profilesBidding: null });
            return null;
        }
    };
}

function deleteBiddingProfile(set: NamedSet<State>, get: GetState<State>) {
    return async (profile: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingProfileService.deleteProfileBidding(profile);

        hasPermissionForAction(res, "Eliminar perfil", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            // set({ profilesBidding: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            // set({ profilesBidding: null });
            return null;
        }
    };
}

function updateBiddingProfile(set: NamedSet<State>, get: GetState<State>) {
    return async (profile: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingProfileService.updateProfileBidding(profile);
        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            // set({ profilesBidding: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            // set({ profilesBidding: null });
            return null;
        }
    };
}

function updateProfileBiddingList(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, withLoading = true) => {
        const { usersList, profile } = body;
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingProfileService.updateProfileBiddingList(usersList, profile);
        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            // set({ profilesBidding: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            // set({ profilesBidding: null });
            return null;
        }
    };
}

function getProfile(set: NamedSet<State>, get: GetState<State>) {
    return async (companyProfileId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingProfileService.getProfile(companyProfileId);
        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            set({ selectedBiddingProfile: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            set({ selectedBiddingProfile: null });
            return null;
        }
    };
}
