import { FormControl, Input, InputGroup, Select } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import useContractState from "../../store/contractState";

import useModalState from "../../store/modalsState";
import debounce from "lodash/debounce";
import { Contract, CreateContractBody } from "../../models/Contract.models";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useGlobalState from "src/store/globalState";

type Props = {
    action: "create" | "edit";
    invalidName?: boolean;
    invalidTemplate?: boolean;
    canSelectTemplate?: boolean;
    handleCurrentStepLife?: any;
    nameAssigned?: string;
    requerimentName?: string;
};

const ContractGeneralInfo = ({
    action,
    invalidName = false,
    invalidTemplate = false,
    canSelectTemplate,
    handleCurrentStepLife,
    nameAssigned,
    requerimentName,
}: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    //selecciono state dinamicamente segun el action
    const currentContract = useContractState((s) => (action === "create" ? s.currentNewContract : s.selectedContract));
    const setCurrentContract = useContractState((s) => (action === "create" ? s.setCurrentNewContract : s.setSelectedContract));
    const setTemplateSelectModal = useModalState((s) => s.setTemplateSelectModal);
    const { generateUniqueSerializedName } = useContractState();
    const [contractName, setContractName] = useState(() => {
        // Inicializa contractName con el valor de nameAssigned si está presente
        return nameAssigned || currentContract?.name || "";
    });
    const isReadOnly = action !== "create" || !canSelectTemplate;
    const additionalCompanyInfo = useGlobalState((s) => s.additionalCompanyInfo);

    const debouncedNameChange = useCallback(
        debounce((e) => setContractName(e.target.value), 500),
        []
    );

    useEffect(() => {
        setCurrentContract({ ...currentContract, name: contractName } as Partial<CreateContractBody> & Contract);
    }, [contractName]);

    useEffect(() => {
        const fetchData = async () => {
            if (requerimentName != undefined) {
                generateUniqueSerializedName(requerimentName).then((nameAssigned) => {
                    setContractName(nameAssigned);
                    const inputElement = document.getElementById("contract_name") as HTMLInputElement | null;
                    if (inputElement) {
                        // Actualizamos el valor del input
                        inputElement.value = nameAssigned;
                        //debouncedNameChange(inputElement.value);
                    }
                });
            }
        };

        fetchData();
    }, []);

    return (
        <div className="w-full mt-4 px-2">
            <h3 className="text-xl font-medium text-dark leading-none w-full">{t("generalInfo")}</h3>
            {/**<h3 className="text-xl font-medium text-dark leading-none w-full">{t("general")}</h3> */}
            
            <div className="w-full grid grid-cols-3 gap-4 mt-2">
                <FormControl isInvalid={!currentContract?.name && invalidName} isReadOnly={false}>
                    {additionalCompanyInfo.isAmericaMovil === true ?
                        <label htmlFor="contract_name" className="text-sm leading-8">
                            {t("nameId")}
                        </label> :
                        <label htmlFor="contract_name" className="text-sm leading-8">
                            {t("name")}
                        </label>
                    }
                    <InputGroup borderColor="gray.400">
                        <Input
                            autoFocus
                            onChange={debouncedNameChange}
                            id="contract_name"
                            type="text"
                            rounded="none"
                            defaultValue={currentContract?.name ?? ""}
                        />
                    </InputGroup>
                </FormControl>

                <FormControl isInvalid={!currentContract?.template?.name && invalidTemplate}>
                    <label htmlFor="template" className="text-sm leading-8">
                        {t("template")}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            id="template"
                            type="text"
                            rounded="none"
                            cursor={isReadOnly ? "not-allowed" : "pointer"}
                            readOnly={true}
                            onClick={() => {
                                if (!isReadOnly) {
                                    setTemplateSelectModal({ show: true });
                                    // handleCurrentStepLife(currentContract?.template?.maxLifeStage);
                                }
                            }}
                            value={currentContract?.template?.name ?? t("select")}
                        />
                    </InputGroup>
                </FormControl>

                {/* <FormControl isReadOnly={isReadOnly}>
                    <label htmlFor="contract_client" className="text-sm leading-8">
                        Cliente
                    </label>
                    <Select disabled id="contract_client" size="md" rounded="none">
                        <option value="">No disponible</option>
                        <option value="">Carlos Mendez</option>
                    </Select>
                </FormControl> */}
            </div>
        </div>
    );
};

export default ContractGeneralInfo;
