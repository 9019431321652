import { Avatar, Divider, Grid, GridItem, useDimensions } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BrandBox from "src/components/common/BrandBox";
import NavbarShort from "src/components/layouts/NavbarShort"
import { CompanyGroup } from "src/models/Company.models";
import { CompanyService } from "src/services/company.service";
import useGlobalState from "src/store/globalState";
import welcomeImg from '../assets/images/welcome.svg'
import useAuthState from "src/store/authState";

const GroupSelection: React.FC = () => {
    /** Services */
    const companyService = new CompanyService()
    /** */
    const navbarRef = useRef()
    const dimensions = useDimensions( navbarRef )
    const noScroll = false
    const navbarHeight = dimensions?.borderBox.height || 68
    const parentCompanyData = useGlobalState( s => s.parentCompanyData )
    const isSelectedChildCompany = useGlobalState( s => s.isSelectedChildCompany )
    const { getChildCompanyInfo } = useGlobalState()
    const [ companyChildren, setCompanyChildren ] = useState<CompanyGroup[]>( [] )
    const { changeCompanyId } = useAuthState()
    const navigate = useNavigate()

    if ( isSelectedChildCompany ) {
        navigate( '/' )
    }

    const handleSelectChild = ( companyId: string ) => {
        changeCompanyId( companyId )

        getChildCompanyInfo( companyId, true ).then(
            response => {
                if ( response ) {
                    navigate( "/" )
                }
            }
        )
    } 

    useEffect( () => {
        companyService.getGroupsCompany( parentCompanyData.id ).then(
            response => {
                setCompanyChildren( response )
            }
        )
    }, [parentCompanyData] )

    return (
        <div className="w-full h-[100vh] bg-gray-light px-8 pb-8 relative">
            <NavbarShort  elRef={navbarRef} sidebarWidth={0} />
            <div
                className={`bg-white rounded-2xl mt-4 ${noScroll ? "" : "overflow-y-auto"}`}
                style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: `calc(100% - ${/*sidebarWidth*/ 0}rem)`,
                    maxHeight: `calc(100% - ${navbarHeight}px)`,
                }}
            >
                <div className="flex flex-col p-4 gap-8">
                    <Grid templateColumns="repeat(10, 1fr)" gap={4}>
                        <GridItem colSpan={{ base: 10, md: 3 }}>
                            <BrandBox className="!shadow-md bg-gray-100 flex items-center gap-6">
                            <Avatar size="xl" name={parentCompanyData?.name || 'Default Name'} />
                            <h1 className="text-3xl text-brand-500">
                            <Avatar size="xl" name={parentCompanyData?.name || 'Default Name'} />
                                </h1>
                            </BrandBox>
                        </GridItem>
                        <GridItem colSpan={{ base: 10, md: 7 }}>
                            <BrandBox className="!shadow-md bg-gray-100 flex items-center gap-6 h-[128px]">
                                <img src={welcomeImg} className="h-24" alt="Imagen de bienvenida" />
                                <div className="flex flex-col justify-center">
                                    <h1 className="text-3xl text-brand-500">
                                        Te damos la bienvenida
                                    </h1>
                                    <p className="text-lg text-brand-500">
                                        A nuestra plataforma de orquestación de procesos empresariales
                                    </p>
                                </div>
                            </BrandBox>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns="repeat(12, 1fr)">
                        <GridItem colSpan={{ base: 12, md: 12 }}>
                            <strong className="text-xl">
                                Mis empresas
                            </strong>
                        </GridItem>
                    </Grid>
                    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                        {companyChildren.map(( child, index ) => (
                            <GridItem key={`${index}-${child.id}`} colSpan={{ base: 12, md: 4 }}
                                onClick={() => handleSelectChild( child.id )}
                            >
                                <BrandBox className="!shadow-md bg-gray-100 flex flex-col gap-6 cursor-pointer hover:bg-white">
                                    <div className="flex justify-center">
                                        <Avatar size="lg" name={child.name} />
                                    </div>
                                    <h1 className="text-2xl">
                                        {child.name}
                                    </h1>
                                    <Divider variant="dashed" />
                                    <div className="flex flex-col">
                                        <small>RFC: <strong>{child.rfc.toUpperCase()}</strong></small>
                                        <small>Email: <strong>{child.email}</strong></small>
                                        <small>Lista negra del SAT: <strong>{child.blackList ? 'PERTENECE' : 'NO PERTENECE'}</strong></small>
                                    </div>
                                </BrandBox>
                            </GridItem>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default GroupSelection