import { useEffect, useState } from "react";
import { Button, FormControl, Input, InputGroup, useBoolean } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import CommonModal from "../common/CommonModal";

const AddNewStageGroup = ({
    onCancel,
    onSave,
    name,
    label,
    isOpen,
}: {
    onCancel: () => any;
    onSave: (name: string, oldName: string) => any;
    isOpen: boolean;
    name?: string;
    label: string;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [invalidName, setInvalidName] = useBoolean(false);
    const [typeName, setTypeName] = useState("");
    const [oldName, setOldName] = useState(name);

    useEffect(() => {
        setTypeName(name);
        setOldName(name);
    }, [name]);

    function handleSaveType() {
        if (!typeName?.length) {
            return setInvalidName.on();
        } else {
            setInvalidName.off();
        }

        onSave(typeName, oldName);
        setTypeName("");
        setOldName("");
    }

    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onCancel}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">{label}</h2>
                <FormControl flex={1} isInvalid={invalidName}>
                    <label htmlFor="type_name" className="text-sm leading-8">
                        {t("name")}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            id="type_name"
                            type="text"
                            value={typeName}
                            rounded="none"
                            placeholder={t("name")}
                            onChange={(e) => setTypeName(e.target.value)}
                        />
                    </InputGroup>
                </FormControl>
                <div className="mt-8 pb-2 w-full text-center">
                    <Button
                        type="submit"
                        rounded="full"
                        size="md"
                        onClick={handleSaveType}
                        fontWeight="medium"
                        px={"2rem"}
                        isLoading={false}
                    >
                        {t("save")}
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default AddNewStageGroup;
