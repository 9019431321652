import create, { StateCreator } from "zustand"

/** Contrato del Store */
interface IntervalState {
    intervals: Record<string, NodeJS.Timeout>
    addInterval: ( id: string, interval: NodeJS.Timeout ) => void
    clearInterval: ( id: string ) => void
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    intervals: { }
}

/** Definición del Store */
const Store: StateCreator<IntervalState> = ( set, get ) => ({

    ...initialState,

    addInterval: ( id, interval ) => set( state => ({
        intervals: { ...state.intervals, [id]: interval }
    })),

    clearInterval: ( id => {
        const interval = get().intervals[id]

        if ( interval ) {
            clearInterval( interval )

            set( state => {
                const newIntervals = { ...state.intervals }
                delete newIntervals[id]
                return { intervals: newIntervals }
            })
        }
    }),

    reset: () => {
        const { intervals } = get()

        Object.values( intervals ).forEach( clearInterval )

        set( initialState )
    }

})

/** Exportación del Store */
export const useIntervalStore = create<IntervalState>( Store )