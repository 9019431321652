import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { CommonResponse } from "../models/Common.models";
import {
    Contract,
    ContractRevisionCreateBody,
    ContractRevisionStatus,
    ContractRevisionUserConfirmed,
    ContractRevisionUserSelect,
    UserRevisor,
} from "../models/Contract.models";
import contractRevisionService from "../services/contractRevision.service";
import useAuthState from "./authState";
import useModalState from "./modalsState";

interface State {
    revisionUsers: { loading: boolean; error: boolean; data: ContractRevisionUserSelect[] };
    currentRevisionStatus: ContractRevisionStatus;
    currentReviewerEditionChanges: string;
    setCurrentRevisionStatus: (revision: ContractRevisionStatus) => any;
    setCurrentReviewerEditionChanges: (newContractText: string) => any;
    getRevisionUsers: (withLoading?: boolean) => Promise<ContractRevisionUserSelect[]>;
    createRevision: (contractId: string, users: ContractRevisionUserConfirmed[], withLoading?: boolean,expirationHoursValue?: number, contractName?: string) => Promise<boolean>;
    getRevision: (contractId: string, withLoading?: boolean) => Promise<boolean>;
    cancelRevision: (contractId: string, withLoading?: boolean) => Promise<boolean>;
    getContractForRevision: (
        userId: string,
        contractId: string,
        withLoading?: boolean
    ) => Promise<CommonResponse<{ contractCollection: Contract; userReviewer: UserRevisor } | any> | null>;
    reset: () => any;
}

const initialState = {
    revisionUsers: { loading: false, error: false, data: [] },
    currentRevisionStatus: null,
    currentReviewerEditionChanges: "",
};

const useContractRevisionState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            setCurrentRevisionStatus: setCurrentRevisionStatus(set, get),
            setCurrentReviewerEditionChanges: setCurrentReviewerEditionChanges(set, get),
            getRevisionUsers: getRevisionUsers(set, get),
            createRevision: createRevision(set, get),
            getRevision: getRevision(set, get),
            cancelRevision: cancelRevision(set, get),
            getContractForRevision: getContractForRevision(set, get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "ContractRevisionState" }
    )
);

export default useContractRevisionState;

const msgPermission = 'No cuenta con el permiso para esta acción.';
function hasPermissionForAction (reponse : any, msgPermission : string, redirect : boolean ){
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: 'AVISO',
                text2: msgPermission,
                text3: 'Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.',
                redirectHome : redirect,
                onClose: async () => {
                    return { show: false }
                  }
            }
        });
    }
}


//FUNCTIONS
function setCurrentRevisionStatus(set: NamedSet<State>, get: GetState<State>) {
    return async (revision) => {
        set({ currentRevisionStatus: revision });
    };
}

function setCurrentReviewerEditionChanges(set: NamedSet<State>, get: GetState<State>) {
    return async (newContractText: string) => {
        set({ currentReviewerEditionChanges: newContractText });
    };
}

function getRevisionUsers(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        set((state) => ({ revisionUsers: { loading: true, error: false, data: state.revisionUsers.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await contractRevisionService.listUsers(idCompany);

        hasPermissionForAction(res,"Obtener revisores",false);

        if (res?.data?.respuesta) {
            set({ revisionUsers: { loading: false, error: false, data: res.data.respuesta } });
            return res.data.respuesta;
        } else {
            console.log("Error fetching revision users");
            set((state) => ({ revisionUsers: { loading: false, error: true, data: state.revisionUsers.data } }));
            //clean error
            set((state) => ({ revisionUsers: { ...state.revisionUsers, error: false } }));
            return null;
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function createRevision(set: NamedSet<State>, get: GetState<State>) {
    return async (contractId, users, withLoading = true , expirationHoursValue , contractNameValue) => {
        const { companyId, userEmail, userName, userId } = useAuthState.getState();

        const body: ContractRevisionCreateBody = {
            idCompany: companyId,
            idUserRoot: userId,
            userEmailRoot: userEmail,
            userNameRoot: userName,
            idContract: contractId,
            userReviewerList: users,
            expirationHours : expirationHoursValue,
            contractName: contractNameValue
        };

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await contractRevisionService.createRevision(body);
        
        hasPermissionForAction(res,"Genegar revisión ",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating revision");
            return false;
        }
    };
}

function getRevision(set: NamedSet<State>, get: GetState<State>) {
    return async (contractId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await contractRevisionService.getRevisionByContract(contractId);

        hasPermissionForAction(res,"Obtener revisión ",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ currentRevisionStatus: res.data.respuesta[0] });
            return true;
        } else {
            console.log("Error fetching revision status");
            set({ currentRevisionStatus: null });
            return false;
        }
    };
}

function cancelRevision(set: NamedSet<State>, get: GetState<State>) {
    return async (contractId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await contractRevisionService.cancelRevision(contractId);
        hasPermissionForAction(res,"Cancelar revisión ",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error canceling revision");
            return false;
        }
    };
}

function getContractForRevision(set: NamedSet<State>, get: GetState<State>) {
    return async (userId, contractId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await contractRevisionService.getContractForRevision(userId, contractId);
        hasPermissionForAction(res,"Obtener estatus revisión del contrato",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return { ok: true, data: res.data.respuesta };
        } else {
            console.log("Error fetching revision status");
            return { ok: false, data: res.response?.data };
        }
    };
}
