import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useContractState from "../../../store/contractState";
type Props = {
    onCancelReview: (arg: any) => any;
    isLoading: boolean;
};

const PendingRevisionActions = ({ onCancelReview, isLoading }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const uiOptions = useContractState((s) => s.uiOptions);

    return (
        <div className="flex justify-center gap-2 pt-4 pb-2 sticky bottom-0 bg-white border-t">
            <Button rounded="full" size="md" fontWeight="medium" variant="solid" onClick={onCancelReview} isLoading={isLoading}>
                {t("cancelRevision")}
            </Button>
        </div>
    );
};

export default PendingRevisionActions;
