import { Drawer, DrawerOverlay, DrawerContent, Button } from "@chakra-ui/react";
import { createRef, useEffect, useState } from "react";
import SignPdfViewerCanvas from "./SignPdfViewerCanvas";
import usePdfCanvasRender from "../../../hooks/usePdfCanvasRender";
import { PDFDocumentProxy } from "pdfjs-dist";
import { SignerCertificate, SignerStickerInfo, ContractSigner } from "../../../models/Signatures.models";
import useCustomToast from "../../../hooks/useCustomToast";
import { confirmAlert } from "../../../store/modalsState";
import SignerCard from "./SignerCard";
import SignaturesTopbar from "./SignaturesTopbar";
import useContractSignaturesState from "../../../store/contractSignatureState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

export interface Props {
    url: string;
    documentName?: string;
    onSaveSigns: (stickersInfo: SignerStickerInfo[]) => void;
    onClose: () => any;
}

const SignaturesView = ({ url, documentName, onSaveSigns, onClose }: Props) => {
    const originalSigners = useContractSignaturesState((s) => s.selectedSigners);
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const { canvasRef, setCanvasSize, pdfRef, canvasWidth, canvasHeight, viewport, scale } = usePdfCanvasRender(url);
    const [signers, setSigners] = useState(originalSigners);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isPositioningSticker, setIsPositioningSticker] = useState(false);
    const [itemSticker] = useState<SignerStickerInfo[]>([]);
    const [texts, setTexts] = useState([]);
    const [sizeOriginalSigners, setSizeOriginalSigners] = useState([]);
    const [startX, setStartX] = useState<number>();
    const [startY, setStartY] = useState<number>();
    const [selectedText, setSelectedText] = useState(-1);
    const [tempNames, setTempNames] = useState([]);
    const [currentSizeTempItems, setCurrentSizeTempItems] = useState(0);

    const pdfCanvasSizes = useContractSignaturesState((s) => s.pdfCanvasSizes);

    /* -------------------------------------------------------------------------- */
    /*                                   ON INIT                                  */
    /* -------------------------------------------------------------------------- */
    const renderStickerPage = (pdfRef: PDFDocumentProxy) => {
        if (pdfRef) {
            setTotalPages(pdfRef.numPages);
        }
    };

    async function effect() {
        if (!isPositioningSticker) await setCanvasSize(currentPage, pdfRef);
        renderStickerPage(pdfRef);
    }
    /* ----------------------------------- -- ----------------------------------- */

    /* -------------------------------------------------------------------------- */
    /*                                   ON PAGE CHANGE                           */
    /* -------------------------------------------------------------------------- */
    function handleNextPage() {
        if (currentPage >= pdfRef.numPages) return;
        setCurrentPage(currentPage + 1);
    }
    function handlePrevPage() {
        if (currentPage <= 1) return;
        setCurrentPage(currentPage - 1);
    }
    /* ----------------------------------- -- ----------------------------------- */

    //DIBUJA TODO LO QUE ESTE EN "texts"
    function draw() {
        const canvasEl = canvasRef.current;
        const ctxs = canvasEl.getContext("2d");
        ctxs.clearRect(0, 0, canvasEl.width, canvasEl.height);

        for (let i = 0; i < texts.length; i++) {
            const text = texts[i];
            ctxs.fillText(text.text, text.x, text.y);
        }
    }

    //agrega nueva etiqueta a "texts" y llama a "draw()"
    function handleGenerateTag(name: string, userId: string, certificate: SignerCertificate, idx: number) {
        // add user name , save another array
        setIsPositioningSticker(true);
        const existsUserTag = Boolean(
            itemSticker.find((a) => {
                return a.name === name && a.cardIdx === idx;
            })
        );

        if (existsUserTag) {
            return toast.error("Este usuario ya tiene una etiqueta generada");
        }

        const ctxs = canvasRef?.current?.getContext("2d");
        const y = texts.length * 20 + 20;

        const text: SignerStickerInfo | any = {
            name: name,
            page: currentPage,
            userId: userId,
            userCertificate: certificate,
            text: "[" + name.split(" ").slice(0, 2).join(" ") + "]",
            x: 20,
            y: y,
            // PdfScaledHeigth: canvasHeight,
            // PdfScaledWidth: canvasWidth,
            // convertedCoords: [0, 0],
            // scaleFactor: scale,
            // width: 0,
            // height: 0,
            cardIdx: idx,
        };

        ctxs.font = "16px verdana";
        ctxs.fillStyle = "#2E54B8";
        text.width = ctxs.measureText(text.text).width;
        text.height = 20;

        texts.push(text);
        itemSticker.push(text);

        draw();
    }

    //indica si el cursor esta sobre la etiqueta
    function textHittest(x: number, y: number, textIndex: number) {
        const text = texts[textIndex];
        const isBetweenX = x >= text.x && x <= text.x + text.width;
        const isBetweenY = y - text.height >= text.y - text.height - 20 && y - text.height <= text.y;
        return isBetweenX && isBetweenY;
    }

    /**
     * Function that calculates cursor position in the sticker canvas. If the cursor hovers any
     * sign sticker in the canvas, the cursor type. will change to pointer type, if it is not over any
     * sign sticker, the cursor type will be 'grab'
     * @param {number} cursorX - Current cursor position in x axis.
     * @param {number} cursorY - Current cursor position in y axis.
     */
    const updateCursorPosition = (cursorX: number, cursorY: number) => {
        // Gets canvas element
        const canvasD = canvasRef.current;
        const canvasSizes = canvasD?.getBoundingClientRect();

        // sets cursor's coordinates in the canvas.
        const tempStartX = Math.round(cursorX - canvasSizes?.left);
        const tempStartY = Math.round(cursorY - canvasSizes?.top);

        setStartX(tempStartX);
        setStartY(tempStartY);

        // Checks if the cursor is over one of the sign stickers.
        const isCursorInSticker = texts?.find((_, i) => textHittest(startX, startY, i));
        if (isCursorInSticker) {
            canvasD.style.cursor = "grab";
        } else {
            canvasD.style.cursor = "pointer";
        }
    };

    function handleMouseDown(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        e.preventDefault();

        // Gets canvas element
        const canvasD = canvasRef.current;
        const canvasSizes = canvasD?.getBoundingClientRect();

        const tempStartX = Math.round(e.clientX - canvasSizes.left);
        const tempStartY = Math.round(e.clientY - canvasSizes.top);

        setStartX(tempStartX);
        setStartY(tempStartY);

        for (let i = 0; i < texts.length; i++) {
            if (textHittest(startX, startY, i)) {
                setSelectedText(i);
            }
        }
    }

    function handleMouseUp(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        e.preventDefault();
        setSelectedText(-1);
    }

    function handleMouseOut(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        e.preventDefault();
        setSelectedText(-1);
    }

    function handleMouseMove(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
        // Every time the cursor moves inside the canvas,
        updateCursorPosition(e.clientX, e.clientY);

        if (selectedText < 0) {
            return;
        }

        e.preventDefault();
        // Gets canvas element
        const canvasD = canvasRef.current;
        const canvasSizes = canvasD?.getBoundingClientRect();

        const mouseX = Math.round(e.clientX - canvasSizes.left);
        const mouseY = Math.round(e.clientY - canvasSizes.top);

        const dx = mouseX - startX;
        const dy = mouseY - startY;

        const text = texts[selectedText];
        text.x += dx;
        text.y += dy;

        draw();
    }

    /**
     * Paints a text in the canvas, where text will be itemStciekr[`index`]
     * @param canvasD - Canvas DOM element where the sticker will be painted.
     * @param {number} index - Index that identifies the text that will bee painted
     */
    const drawFinalSticker = (canvasD: { getContext: (arg0: string) => any }, index: number) => {
        // Gets canvas DOM element
        const ctxs = canvasD.getContext("2d");
        // Sets text style.
        ctxs.font = "16px verdana";
        ctxs.fillStyle = "#30b539";
        // Gets the sticker.
        const text = itemSticker[index];
        // Paint the sticker in the canvas element.
        ctxs.fillText(text.text, text.x, text.y);
    };

    const handleSavePosition = (name: string, idx: number, idCard: string) => {
        setIsPositioningSticker(false);

        const hasMatchItem = Boolean(
            itemSticker.find((a) => {
                return a.name === name && a.cardIdx === idx;
            })
        );

        if (hasMatchItem === false) {
            toast.error(t("userHasNotGeneratedTag"), t("warning"));
        } else {
            confirmAlert.show({
                title: t("areYouSure"),
                msg: t("onceSavedCannotBeChanged"),
                severity: "info",
                onConfirm: () => saveStickerPosition(name, idx, idCard),
            });
        }
    };

    const saveStickerPosition = (name: string, idx: number, idCard: string) => {
        setTexts(texts.filter((item) => item.name !== name));
        itemSticker[idx].page = currentPage;

        // convert coord to pdf
        const coordX = itemSticker[idx].x;
        const coordY = itemSticker[idx].y;
        //const coordsConvert = viewport?.convertToPdfPoint(coordX * scale, coordY * scale);
        //const coordsConvert = [parseXValue(coordX), parseYValue(coordY)];
        const coordsConvert = [coordX, coordY];

        itemSticker[idx].convertedCoords = coordsConvert as any;

        const data = {
            name: name,
            page: currentPage,
            id: idx,
            idCard: idCard,
            canvasRef: createRef(),
            create: true,
        };

        setTempNames((oldTempNames) => [...oldTempNames, data]);
        setCurrentSizeTempItems((prevSize) => prevSize + 1);

        toast.success(t("signaturePositionGenerated"));
    };

    const handleAddNewSigner = (name: string, userId: string, certificate: SignerCertificate) => {
        confirmAlert.show({
            title: t("newSignatureQuestion"),
            msg: `${t("addSignatureFor")} "${name}"`,
            severity: "info",
            onConfirm: () => {
                const id = signers.length + 1;
                const newId = id.toString();
                const newSigner = {
                    certificate: certificate,
                    id: userId,
                    name: name,
                    isOriginal: true,
                    cardIdx: newId,
                };

                setSigners((prevSigners) => [...prevSigners, newSigner]);
                toast.success(t("newSignatureGenerated"));
            },
        });
    };

    const onConfirm = () => {
        const canvasSizes = canvasRef.current?.getBoundingClientRect();

        const parsedStickers = itemSticker.map((item) => {
            const newItem = { ...item };
            newItem.convertedCoords = [parseXValue(item.x, canvasSizes?.width), parseYValue(item.y, canvasSizes?.height)];

            return newItem;
        });
        onSaveSigns(parsedStickers);
        //onClose();
    };

    const handleFinishSignatures = () => {
        if (signers.length === currentSizeTempItems) {
            confirmAlert.show({
                title: t("areYouSure"),
                msg: t("finishTagsPositioning"),
                severity: "info",
                onConfirm,
            });
        } else {
            toast.error(t("thereAreStillLabelsToPosition"), t("warning"));
        }
    };

    // After the user saves sticker position, this effect copies the sticker and draws it in a new canvas,
    // dedicated only for this sticker.
    useEffect(() => {
        tempNames.forEach((item, i) => {
            // Gets the canvas DOM element that will contain the sticker
            const itemEl = item.canvasRef?.current;
            const canvasSizes = itemEl?.getBoundingClientRect();
            //
            if (itemEl && itemEl.height !== canvasHeight && itemEl.width !== canvasSizes?.width) {
                itemEl.height = canvasSizes?.height;
                itemEl.width = canvasSizes?.width;
                draw();
                drawFinalSticker(itemEl, i);
            }
        });
    }, [tempNames, currentPage]);

    useEffect(() => {
        effect();
        setSizeOriginalSigners(originalSigners);
    }, [pdfRef]);

    return (
        <>
            <Drawer placement="bottom" size="full" onClose={onClose} isOpen={true}>
                <DrawerOverlay />
                <DrawerContent>
                    <SignaturesTopbar
                        onCloseDrawer={onClose}
                        onPrev={handlePrevPage}
                        onNext={handleNextPage}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                        documentName={documentName}
                    />
                    <main className="w-full flex">
                        <div className="w-[350px] bg-white flex flex-col" style={{ height: "calc(100vh - 74px)" }}>
                            <div className="w-full p-4 flex flex-col gap-6 overflow-y-auto h-auto max-h-[70vh]">
                                {signers?.map((item, idx) => {
                                    return (
                                        <SignerCard
                                            userName={item.name}
                                            onGenerateTag={handleGenerateTag}
                                            userCertificate={item.certificate}
                                            userId={item.id}
                                            idx={idx}
                                            tempNames={tempNames}
                                            onSavePosition={handleSavePosition}
                                            sizeSigners={sizeOriginalSigners.length}
                                            sizeTempSigners={tempNames.length}
                                            isOriginal={item.isOriginal}
                                            onAddNewSigner={handleAddNewSigner}
                                            tagsCount={tempNames.filter((el) => el.idCard === item.id).length}
                                            isAddedTag={Boolean(item.cardIdx)}
                                            isAvailable={pdfCanvasSizes?.width > 0 && pdfCanvasSizes?.height > 0}
                                        />
                                    );
                                })}
                            </div>
                            <div className="grow"></div>
                            <div className="px-4 py-4 flex justify-center">
                                <Button variant="solid" size="md" rounded="full" px={8} onClick={handleFinishSignatures}>
                                    {t("finish")}
                                </Button>
                            </div>
                        </div>
                        <div className="w-full grow relative bg-slate-100">
                            {tempNames?.map((item, i) => {
                                return (
                                    !!item.canvasRef &&
                                    item.page === currentPage && (
                                        <canvas
                                            key={`${i}-${pdfCanvasSizes?.width}${pdfCanvasSizes?.height}`}
                                            className="fixed z-10 mx-auto"
                                            style={{
                                                width: pdfCanvasSizes?.width,
                                                height: pdfCanvasSizes?.height,
                                                left: pdfCanvasSizes?.left,
                                                top: pdfCanvasSizes?.top,
                                            }}
                                            ref={item.canvasRef}
                                        />
                                    )
                                );
                            })}
                            <canvas //donde esta siendo posicionado el sticker
                                key={`sticker-position-${pdfCanvasSizes?.width}${pdfCanvasSizes?.height}`}
                                id="canvasDraw"
                                className="fixed z-10 mx-auto"
                                style={{ left: pdfCanvasSizes?.left, top: pdfCanvasSizes?.top }}
                                width={pdfCanvasSizes?.width || 0}
                                height={pdfCanvasSizes?.height || 0}
                                ref={canvasRef}
                                onMouseDown={handleMouseDown}
                                onMouseMove={(e) => handleMouseMove(e)}
                                onMouseUp={(e) => handleMouseUp(e)}
                                onMouseOut={(e) => handleMouseOut(e)}
                            />
                            <SignPdfViewerCanvas currentPage={currentPage} url={url} />
                        </div>
                    </main>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default SignaturesView;

const FIRMAMEX_WIDTH = 595;
const parseXValue = (x: number, width: number) => {
    const percentage = (x * 100) / width;
    const firmamexX = FIRMAMEX_WIDTH * (percentage / 100);
    return firmamexX;
};

const FIRMAMEX_HEIGHT = 841 * 0.996;
const parseYValue = (y: number, height: number) => {
    y = height - y; //inversing y value
    const percentage = (y * 100) / height;
    const firmamexY = FIRMAMEX_HEIGHT * (percentage / 100);
    return firmamexY;
};
