import { Button } from "@chakra-ui/button";
import { CircularProgress } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import CommonModal from "../../CommonModal";
import { HandleBiddingSCMModalData } from "../../../../models/Modals.models";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { formatDate } from "src/lib/utils";
import CommonTh from "../../table/CommonTh";
import useContractState from "src/store/contractState";
import { Contract } from "src/models/Contract.models";
import { useNavigate } from "react-router-dom";
import useBiddingState from "src/store/biddings/biddingState";
import useCustomToast from "src/hooks/useCustomToast";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: HandleBiddingSCMModalData;
};

const HandleBiddingSCMModal = ({ isOpen, onClose, data }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const navigate = useNavigate();
    const [selectedContract, setSelectedContract] = useState<Contract>(null);
    const {
        contracts: { data: contracts, loading },
        getContractList,
    } = useContractState();
    const { addSmartContractToBiddingReq } = useBiddingState();

    const contractType = data?.requirement?.template?.type;
    const filteredContracts = useMemo(() => contracts.filter((contract) => contract.type === contractType), [contracts, contractType]);

    function handleNewContract() {
        //add validation TODO

        const assignamentState = {
            biddingId: data?.biddingId,
            biddinName: data?.requirement?.name,
            reqId: data?.requirement?.uuid,
            month: data?.month,
            year: data?.year,
            expedienteUnico: data.expedienteUnico
        };
        
        onClose();
        navigate(`/contracts/create?template=${data?.requirement?.template?.id}`, { state: { assignToBidding: true, assignamentState } });
    }
    async function handleAssignContract() {
        const body = {
            contractId: selectedContract?.id,
            month: data?.month,
            year: data?.year,
            name: selectedContract?.name,
        };

        const assigned = await addSmartContractToBiddingReq(body, data?.biddingId, data?.requirement?.uuid);

        if (assigned===true) {
            toast.success("Contrato asignado correctamente");
            data.afterUpload();
            onClose();
        } else {
            toast.error(assigned);
        }
    }

    useEffect(() => {
        getContractList(false);
    }, []);
    
    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div>
                    <h2 className="title-3 text-center">{data?.requirement?.name}</h2>
                </div>
                <div className="w-full flex flex-col items-center">
                    <div>
                        <h4 className="text-center text-lg text-gray-600 font-semibold mb-2">Nuevo contrato</h4>
                        <Button variant={"outline"} onClick={handleNewContract}>
                            Crear contrato
                        </Button>
                    </div>
                    <div className="w-full mt-8">
                        <h4 className="text-center text-lg text-gray-600 font-semibold mb-2">Asignar contrato existente</h4>

                        <div className="border p-4 rounded-lg max-h-[400px] overflow-y-auto">
                            <table className="w-full text-sm text-left text-dark-2">
                                <thead className="text-base text-brand-500 font-medium border-b">
                                    <tr>
                                        <th scope="col" className="p-2">
                                            <CommonTh text={t("name")} />
                                        </th>
                                        <th scope="col" className="p-2">
                                            <CommonTh text={t("author")} />
                                        </th>
                                        <th scope="col" className="p-2">
                                            <CommonTh text={t("lastVersion")} />
                                        </th>
                                    </tr>
                                    <tr></tr>
                                </thead>
                                <tbody className="m-h-[434px] overflow-y-auto">
                                    {!loading &&
                                        filteredContracts?.map((item) => (
                                            <TableRow
                                                key={item.id}
                                                contract={item}
                                                selected={selectedContract?.id === item.id}
                                                onSelect={() => setSelectedContract(item)}
                                            />
                                        ))}
                                    {loading && (
                                        <tr>
                                            <td colSpan={3} className="px-2 pt-2 text-center">
                                                <CircularProgress isIndeterminate color="brand.500" size="40px" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-4 text-center">
                            <Button onClick={handleAssignContract} disabled={!selectedContract} rounded="full">
                                Asignar contrato
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
};

export default HandleBiddingSCMModal;

const TableRow = ({ contract, selected, onSelect }: { contract: Contract; selected: boolean; onSelect: (contractId: string) => any }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    return (
        <tr
            onClick={() => onSelect(contract.id)}
            className={`${"border-b  transition-colors duration-200 cursor-pointer"} ${
                selected ? " bg-teal-600 text-white" : " hover:bg-slate-100"
            }`}
        >
            <td className="p-2 py-3">{contract.name}</td>
            <td className="p-2 py-3">{contract.userName}</td>
            <td className="p-2 py-3">{formatDate(contract?.lastModifiedDate)}</td>
        </tr>
    );
};
