import { useCallback, useEffect, useRef, useState } from "react";
import Editor  from "../common/editor/Editor";
//@ts-ignore
import HtmlDiff from "htmldiff-js";
import debounce from "lodash/debounce";
import { replaceAll } from "src/lib/utils";


const ContractEditorPanel = ({
    text,
    enableEdition = true,
    onInput,
    currentTextDiff,
}: {
    text: string;
    enableEdition?: boolean;
    onInput?: (text: string) => any;
    currentTextDiff?: string;
}) => {
    const [textDiff, setTextDiff] = useState(null);
    const [initialContent] = useState(text);
    const instance = useRef<any>();

    const onSetEditorRef = useCallback((editorInstance) => {
        if (instance.current) return;
        instance.current = editorInstance;
    }, []);

    const handleEditorChange = useCallback(
        debounce((content: string) => {
            Boolean(onInput) && onInput(content);
        }, 400),
        []
    );

    // useEffect(() => {
    //     if (currentTextDiff) {
    //         setTimeout(() => {
    //             instance.current.editor.track_changes.toggleTracking();
    //             instance.current.editor.track_changes.showChanges();
    //             instance.current.editor.html.set("");
    //             instance.current.editor.html.set(currentTextDiff);
    //         }, 100);
    //     } else {
    //         setTextDiff(null);
    //     }
    //     //eslint-disable-next-line
    // }, [currentTextDiff]);

    useEffect(() => {
        if (currentTextDiff) {
            let diff: string = HtmlDiff.execute(text, currentTextDiff);
            setTimeout(() => {
                setTextDiff(editorContentWrap(diff));
            }, 100);
        } else {
            setTextDiff("");
        }
        //eslint-disable-next-line
    }, [currentTextDiff]);

    return (
        <div className="w-full min-h-[450px]">
            <Editor
                onSetRef={onSetEditorRef}
                onChange={handleEditorChange}
                height={`calc(88vh - 240px)`}
                defaultValue={textDiff || initialContent || ""}
                readOnly={!enableEdition}
            />
        </div>
    );
};

export default ContractEditorPanel;

function editorContentWrap(html: string) {
    html = replaceAll(html, '<ins class="', '<span class="__se__ins ');
    html = replaceAll(html, "</ins>", "</span>");
    html = replaceAll(html, '<del class="', '<span class="__se__del ');
    html = replaceAll(html, "</del>", "</span>");
    return html;
}
