import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { DataType, DataTypesTypes } from "../models/DataType.models";
import dataTypesService from "../services/dataTypes.service";
import useAuthState from "./authState";
import useModalState from "./modalsState";

interface State {
    dataTypes: { loading: boolean; error: boolean; data: DataType[] };
    formatPatterns: any;
    getDataTypes: (withLoading?: boolean) => any;
    createDataType: (name: string, type: DataTypesTypes, patternValue?: string, valueList?: string[], withLoading?: boolean) => any;
    deleteDataType: (dataTypeId: string, withLoading?: boolean) => any;
    getNumberFormatPatterns: (withLoading?: boolean) => any;
    getDateFormatPatterns: (withLoading?: boolean) => any;
    reset: () => void;
}

const initialState = {
    dataTypes: { loading: false, error: false, data: [] },
    formatPatterns: null,
};

const useDataTypesState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            getDataTypes: getDataTypes(set, get),
            createDataType: createDataType(set, get),
            deleteDataType: deleteDataType(set, get),
            getNumberFormatPatterns: getNumberFormatPatterns(set, get),
            getDateFormatPatterns: getDateFormatPatterns(set, get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "dataTypesState" }
    )
);

function hasPermissionForAction(reponse: any, msgPermission: string, redirect: boolean) {
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text2: msgPermission,
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                redirectHome: redirect,
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    }
}
export default useDataTypesState;

//SETTER FUNCTIONS
function getDataTypes(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        set((state) => ({ dataTypes: { loading: true, error: false, data: state.dataTypes.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await dataTypesService.listDataTypes(idCompany);

        hasPermissionForAction(res, "Listar tipo de datos", false);

        if (res?.data?.respuesta) {
            set({ dataTypes: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching dataTypes");
            set((state) => ({ dataTypes: { loading: false, error: true, data: state.dataTypes.data } }));
            //clean error
            set((state) => ({ dataTypes: { ...state.dataTypes, error: false } }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function createDataType(set: NamedSet<State>, get: GetState<State>) {
    return async (name, type, patternValue, valueList, withLoading = true) => {
        const { companyId, userId } = useAuthState.getState();

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await dataTypesService.createDataType({
            idCompany: companyId,
            idUser: userId,
            name,
            type,
            dataAtribute: {
                formatPattern: patternValue,
            },
            valueList,
        });

        hasPermissionForAction(res, "Crear tipo de dato", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getDataTypes(false);
            return true;
        } else {
            console.log("Error creating dataTypes");
            return false;
        }
    };
}

function deleteDataType(set: NamedSet<State>, get: GetState<State>) {
    return async (dataTypeId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await dataTypesService.deleteDataType(dataTypeId);

        hasPermissionForAction(res, "Eliminar tipo de dato", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getDataTypes(false);
            return true;
        } else {
            console.log("Error deleting dataTypes");
            return false;
        }
    };
}

function getNumberFormatPatterns(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await dataTypesService.getNumberFormatPatterns(idCompany);
        console.log(res);
        hasPermissionForAction(res, "Eliminar tipo de dato", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ formatPatterns: res.data.respuesta });
        } else {
            console.log("Error deleting dataTypes");
            return false;
        }
    };
}

function getDateFormatPatterns(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await dataTypesService.getDateFormatPatterns(idCompany);
        console.log(res);
        hasPermissionForAction(res, "Eliminar tipo de dato", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ formatPatterns: res.data.respuesta });
        } else {
            console.log("Error deleting dataTypes");
            return false;
        }
    };
}
