import { Accordion, Button, FormControl, Input, InputGroup, Select, useBoolean } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { BiChevronLeft, BiPlus } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import AccordionItemBrand from "../../components/common/AccordionItemBrand";
import DataTypeButton from "../../components/common/buttons/DataTypeButton";
import LinkButton from "../../components/common/buttons/LinkButton";
import DataTypesTable from "../../components/data-groups/DataTypesTable";
import MainLayout from "../../components/layouts/MainLayout";
import useCustomToast from "../../hooks/useCustomToast";
import { DataType } from "../../models/DataType.models";
import useContractState from "../../store/contractState";
import useDataGroupsState from "../../store/dataGroupsState";
import useDataTypesState from "../../store/dataTypesState";
import useModalState from "../../store/modalsState";
import { isObjectId } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const ShowEditDataGroupsPage = () => {
    const toast = useCustomToast();
    const { setNewDatatTypeModal, setContractTypesModal } = useModalState();
    const navigate = useNavigate();
    const { id } = useParams();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const { getDataGroup, selectedDataGroup, setSelectedDataGroup, updateDataGroup } = useDataGroupsState();
    const { contractTypes, getContractTypes } = useContractState();
    const {
        dataTypes: { data: dataTypes, error },
        getDataTypes,
    } = useDataTypesState();

    const [invalidName, setInvalidName] = useBoolean(false);
    const [invalidType, setInvalidType] = useBoolean(false);
    const [dataGroupName, setDataGroupName] = useState(null);

    const selectedDataTypeNames = useMemo(() => selectedDataGroup?.types?.map((dt) => dt.idName?.split(".")[1]), [selectedDataGroup]);

    async function onInit() {
        if (isObjectId(id)) {
            getDataTypes(false);
            const validDataGroup = await getDataGroup(id);
            if (validDataGroup) {
                getContractTypes(false);
                return;
            }
        }

        toast.error("invalid ID");
        navigate("../");
    }

    function handleDataTypeSelect(dt: DataType, selected: boolean) {
        const tempDataTypes = selectedDataGroup?.types ?? [];
        if (selected) {
            const alreadyExist = tempDataTypes.find((dataType) => dataType.idName === dt.idName);
            if (!alreadyExist) {
                tempDataTypes.push(dt);
            }
            setSelectedDataGroup({ ...selectedDataGroup, types: tempDataTypes });
        } else {
            setSelectedDataGroup({ ...selectedDataGroup, types: tempDataTypes.filter((dataType) => dataType.name !== dt.name) });
        }
    }

    function handleGroupTypeChange(e: any) {
        const type = e.target.value;
        setSelectedDataGroup({ ...selectedDataGroup, status: type });
    }

    async function handleSave() {
        if (!selectedDataGroup?.name) {
            setInvalidName.on();
            toast.error(t("nameRequired"), t("warning"));
            return false;
        } else {
            setInvalidName.off();
        }

        if (selectedDataGroup?.status === null || selectedDataGroup?.status === undefined) {
            setInvalidType.on();
            toast.error(t("typeFieldRequired"), t("warning"));
            return false;
        } else {
            setInvalidType.off();
        }

        if (!selectedDataGroup.types?.length) {
            toast.error(t("leastOneDataTypeRequired"), t("warning"));
            return false;
        }

        const dgSaved = await updateDataGroup({ ...selectedDataGroup, name: dataGroupName ?? selectedDataGroup?.name });
        if (dgSaved) {
            toast.success(t("updatedDataGroup"));
            navigate("../");
        } else {
            toast.error(t("updatedDataGroupError"), t("warning"));
        }
    }

    useEffect(() => {
        onInit();

        return () => setSelectedDataGroup(null);
    }, [id]);
    return (
        <MainLayout>
            <main className="w-full p-3 flex h-full">
                {/* left panel */}
                <section className="w-3/12 flex flex-col pr-4 pl-2 border-r h-full">
                    <h2 className="title-3">{t("editDataGroups")}</h2>
                    <p className="mt-2 text-xs text-justify">{t("editDataGroupsText")}</p>

                    <div className="mt-6 pb-12">
                        <Accordion defaultIndex={[0]} allowMultiple>
                            <AccordionItemBrand title={t("information")}>
                                <div>
                                    <FormControl isInvalid={invalidName}>
                                        <label htmlFor="group_name" className="text-sm leading-8">
                                            {t("name")}
                                        </label>
                                        <InputGroup borderColor="gray.400">
                                            <Input
                                                id="group_name"
                                                type="text"
                                                rounded="none"
                                                defaultValue={selectedDataGroup?.name}
                                                onChange={(e) => setDataGroupName(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormControl>

                                    <FormControl className="mt-4" isInvalid={invalidType}>
                                        <label htmlFor="datagroup_type" className="text-sm leading-8">
                                            {t("type")}
                                        </label>
                                        <Select
                                            key={selectedDataGroup?.id ?? "---"}
                                            defaultValue={selectedDataGroup?.status}
                                            id="datagroup_type"
                                            size="md"
                                            rounded="none"
                                            onChange={handleGroupTypeChange}
                                        >
                                            <option value={null}>{t("select")}</option>
                                            <option value={"generic"}>{t("generic")}</option>
                                            {contractTypes?.data?.map((type) => (
                                                <option key={type.id} value={type.description}>
                                                    {type.description}
                                                </option>
                                            ))}
                                        </Select>
                                        <LinkButton
                                            fontSize="xs"
                                            leftIcon={<BiPlus size={18} />}
                                            className="mt-2"
                                            onClick={() => setContractTypesModal({ show: true })}
                                        >
                                            {t("addNew")}
                                        </LinkButton>
                                    </FormControl>
                                </div>
                            </AccordionItemBrand>
                        </Accordion>
                    </div>
                    <div className="grow"></div>
                    <div className="flex justify-around pt-4 pb-2 sticky bottom-0 bg-white border-t">
                        <Button onClick={handleSave} rounded="full" size="md" fontWeight="medium" variant="outline" isLoading={false}>
                            {t("save")}
                        </Button>
                        {/* <Button rounded="full" size="md" fontWeight="medium" variant="solid" isLoading={false}>
                            Crear plantilla
                        </Button> */}
                    </div>
                </section>

                {/* right panel */}
                <section className="w-9/12 pr-2 pl-4 h-full max-h-full overflow-y-auto">
                    <div className="w-full flex items-center justify-between py-4 border-b">
                        <LinkButton leftIcon={<BiChevronLeft size={18} />} onClick={() => navigate(-1)}>
                            {t("back")}
                        </LinkButton>
                        <LinkButton leftIcon={<BiPlus size={18} />} onClick={() => setNewDatatTypeModal({ show: true })}>
                            {t("addNewDataType")}
                        </LinkButton>
                    </div>
                    <DataTypesTable
                        dataTypes={dataTypes}
                        onDataTypeSelect={handleDataTypeSelect}
                        selectedDataTypeNames={selectedDataTypeNames}
                    />
                </section>
            </main>
        </MainLayout>
    );
};

export default ShowEditDataGroupsPage;
