import { useEffect, useState } from "react";
import useCustomToast from "../../../hooks/useCustomToast";
import useContractRevisionState from "../../../store/contractRevisionState";
import { getColorFromStr, sliceText } from "../../../lib/utils";
import UserIcon from "../../icons/UserIcon";
import { UserRevisor } from "../../../models/Contract.models";
import { BsXCircleFill, BsCheckCircleFill, BsClockFill, BsFillCaretUpFill } from "react-icons/bs";
import { Badge, Button, Tooltip, useBoolean } from "@chakra-ui/react";
import LinkButton from "../../common/buttons/LinkButton";
import { BiChevronDown } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { CgSandClock } from "react-icons/cg";
import { FaCheckCircle, FaClock } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import useModalState from "../../../store/modalsState";
import useDocumentRevisionState from "src/store/documentRevisionState";

interface User {
    id: string;
    name: string;
    email: string;
}

type ItemProps = {
    user: UserRevisor;
    userInfo : User;
    onReminder: (user: UserRevisor) => void;
};
const Item = ({ user,userInfo, onReminder  }: ItemProps) => {
    const setCurrentReviewerEditionText = useContractRevisionState((s) => s.setCurrentReviewerEditionChanges);
    const [showingChanges, setShowingChanges] = useBoolean(false);
    const hasEdition = Boolean(user.newTextContract);
    const hasComment = Boolean(user.comment?.length);
    const [showComments, setShowComments] = useBoolean(hasComment);

    function handleShowChanges(show: boolean) {
        if (show) {
            setCurrentReviewerEditionText(user.newTextContract);
            setShowingChanges.on();
        } else {
            setCurrentReviewerEditionText("");
            setShowingChanges.off();
        }
    }

    const isPending = user.idAnswer === "pendiente";
    const isApproved = user.idAnswer === "aprobado";
console.log("user{{{{",user)
    return (
        <li className="flex flex-col text-gray-500 py-2 w-full">
            <div className="flex items-start w-full gap-2">
                <UserIcon boxSize={10} />
                <div>
                    <div className="flex gap-2 items-start pr-1">
                       {/* <h4 className="text-brand-500 text-lg font-medium leading-tight">{sliceText(user.userName, 20)}</h4>*/}
                        <h4 className="text-brand-500 text-lg font-medium leading-tight">{sliceText(userInfo.name, 20)}</h4>

                        {hasEdition && (
                            <>
                                {!showingChanges && (
                                    <LinkButton fontSize="xs" className="mt-1" onClick={() => handleShowChanges(true)}>
                                        Mostrar cambios
                                    </LinkButton>
                                )}
                                {showingChanges && (
                                    <LinkButton fontSize="xs" className="mt-1" onClick={() => handleShowChanges(false)}>
                                        Ocultar cambios
                                    </LinkButton>
                                )}
                            </>
                        )}
                    </div>
                    <div className="text-gray-500 text-xs leading-none font-light">{userInfo.email}</div>

                    {hasComment && !showComments && (
                        <LinkButton fontSize="xs" className="mt-1" onClick={() => setShowComments.on()}>
                            Ver comentarios{" "}
                        </LinkButton>
                    )}

                    {isPending && (
                        <div className="mt-1">
                            <Button
                                style={{ padding: "2px 8px", height: "auto", fontWeight: "normal", fontSize: "12px" }}
                                onClick={() => onReminder(user)}
                            >
                                Recordar
                            </Button>
                        </div>
                    )}
                    {/* {hasEdition && (
                        <div className="mt-1">
                            {!showingChanges && (
                                <button>
                                    <Badge variant="outline" colorScheme="teal" onClick={() => handleShowChanges(true)}>
                                        Ver cambios
                                    </Badge>
                                </button>
                            )}
                            {showingChanges && (
                                <button>
                                    <Badge variant="solid" colorScheme="teal" onClick={() => handleShowChanges(false)}>
                                        Ocultar cambios
                                    </Badge>
                                </button>
                            )}
                        </div>
                    )} */}
                </div>
                <div className="grow"></div>
                <div className="mt-2">
                    {isPending ? (
                        <Tooltip label="Pendiente">
                            <div className="text-yellow-500/80 pr-1">
                                <FaClock size={"29px"} color="inherit" />
                            </div>
                        </Tooltip>
                    ) : isApproved ? (
                        <Tooltip label="Aprobado">
                            <div className="text-green-500/80 pr-1">
                                <FaCheckCircle size={"29px"} color="inherit" />
                            </div>
                        </Tooltip>
                    ) : (
                        <Tooltip label="Rechazado">
                            <div className="text-red-500/80">
                                <IoMdCloseCircle size={"34px"} color="inherit" />
                            </div>
                        </Tooltip>
                    )}
                </div>
            </div>

            {hasComment && showComments && (
                <div className="p-2 rounded-lg bg-slate-200 text-gray-800 text-xs leading-tight relative mt-3 w-full shadow">
                    <BsFillCaretUpFill size={25} className="absolute top-[-16px] left-2 text-slate-200" />
                    <button className="absolute top-1 right-1" onClick={() => setShowComments.off()}>
                        <IoClose size={15} />
                    </button>

                    <p className="mt-1 max-w-[95%]">{user?.comment}</p>
                </div>
            )}
        </li>
    );
};

type Props = {
    contractId: string;
};

const RevisionDocumentStatusList = ({ contractId }: Props) => {
    const toast = useCustomToast();
    const { currentRevisionStatus, getRevision, setCurrentRevisionStatus } = useContractRevisionState();
    const setLoaderModal = useModalState((s) => s.setLoaderModal);
    const {getDocumentReviewStatus ,reviewersList} = useDocumentRevisionState();
    const [loading, setLoading] = useState(true);

    function handleReminder(user: UserRevisor) {
        //todo
        setLoaderModal(true);
        setTimeout(() => {
            setLoaderModal(false);
            toast.success("Recordatorio enviado");
        }, 1000);
    }
console.log("reviewersList",reviewersList)


    useEffect(() => {
        const getReview = async () => {
            try {
                const data = await getDocumentReviewStatus(contractId); 
            } catch (error) {
                toast.error("Error al obtener estatus de revision");
            } finally {
                setLoading(false);
            }
        };

        getReview();
    }, [contractId]);

    return (
      <ul>
            {reviewersList && reviewersList.length > 0 ? (
                reviewersList.map((reviewerContainer) =>
                    reviewerContainer.reviewerList &&
                    reviewerContainer.reviewerList.length > 0 ? (
                        reviewerContainer.reviewerList.map((reviewer, index) =>
                            reviewer && reviewer.user ? (
                                <Item
                                    key={index}
                                    user={reviewer}
                                    userInfo={reviewer.user}
                                    onReminder={(user) => console.log("Reminder for:", user)}
                                />
                            ) : null
                        )
                    ) : (
                        <li className="text-gray-500 py-2">No hay revisores disponibles.</li>
                    )
                )
            ) : (
                <li className="text-gray-500 py-2">No hay información disponible.</li>
            )}
        </ul>
    );
};

export default RevisionDocumentStatusList;
