import { useNavigate } from "react-router";
import BoxButton from "../../components/common/BoxButton";
import DataGroupsTable from "../../components/data-groups/DataGroupsTable";
import MainLayout from "../../components/layouts/MainLayout";
import useDataGroupsState from "../../store/dataGroupsState";
import { useEffect } from "react";
import useCustomToast from "../../hooks/useCustomToast";
import GoBackOutlineIcon from "../../components/icons/GoBackOutlineIcon";
import GoBackFilledIcon from "../../components/icons/GoBackFilledIcon";
import AddNewOutlineIcon from "../../components/icons/AddNewOutlineIcon";
import AddNewFilledIcon from "../../components/icons/AddNewFilledIcon";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import GoBackIcon from "../../components/icons/iconsV2/GoBackIcon";
import NewDataGroupIcon from "src/components/icons/iconsV2/NewDataGroupIcon";
import DottedSubtitle from "src/components/common/DottedSubtitle";

const DataGroupsPage = () => {
    const navigate = useNavigate();
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const {
        dataGroups: { data, error },
        getDataGroups,
    } = useDataGroupsState();

    useEffect(() => {
        getDataGroups();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(t("fetchingDataGroupsError"));
        }
    }, [error]);

    return (
        <MainLayout>
            <main className="w-full p-6">
                <h2 className="title-3">{t("dataGroups")}</h2>
                <DottedSubtitle text="Crear nuevo grupo de Usuarios" />

                <div className="flex space-x-6 mt-4">
                    <BoxButton
                        icon={<GoBackIcon boxSize={45} />}
                        //hoverIcon={<GoBackFilledIcon boxSize={70} />}
                        text={t("backToTemplates")}
                        onClick={() => navigate("/templates")}
                    />
                    <BoxButton
                        icon={<NewDataGroupIcon boxSize={45} />}
                        //hoverIcon={<AddNewFilledIcon boxSize={70} />}
                        text={t("generateDataGroups")}
                        onClick={() => navigate("create")}
                    />
                </div>

                <div className="mt-4">
                    <DataGroupsTable dataGroups={data} />
                </div>
            </main>
        </MainLayout>
    );
};

export default DataGroupsPage;
