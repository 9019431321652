import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { useMemo, useState } from "react";
import { DataGroup } from "../../models/DataGroup.models";

const useDataGroupsFilter = (dataGroups: DataGroup[]) => {
    const [filterDates, setFilterDates] = useState<{ from: Date; to: Date }>(null);
    const [filterType, setFilterType] = useState<string>(null);
    const [filterText, setFilterText] = useState<string>(null);
    const [dateFilterField, setDateFilterField] = useState<TimeStampField>(null);
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

    function handleDateFilter({ from, to }: { from: any; to: any }, dateField: TimeStampField) {
        setIsFilterActive(true);
        setFilterDates({ from: from ? new Date(from) : null, to: to ? new Date(to) : null });
        setDateFilterField(dateField);
    }

    function handleTypeFilter(type: string) {
        if (type) {
            setIsFilterActive(true);
            setFilterType(type);
        } else {
            setIsFilterActive(false);
            setFilterType(null);
        }
    }

    function handleSearchFilter(text: string) {
        if (text) {
            setFilterText(text);
        } else {
            setFilterText(null);
        }
    }

    function clearFilters() {
        setIsFilterActive(false);
        setFilterDates(null);
        setDateFilterField(null);
        setFilterType(null);
    }

    const filteredDataGroups = useMemo(() => {
        let filtered = dataGroups;
        if (filterDates?.from && filterDates?.to) {
            //is between
            filtered = filtered.filter(
                (dg) =>
                    isAfter(new Date(dg[dateFilterField]), startOfDay(filterDates.from)) &&
                    isBefore(new Date(dg[dateFilterField]), endOfDay(filterDates.to))
            );
        } else if (filterDates?.from) {
            //is after
            filtered = filtered.filter((dg) => isAfter(new Date(dg[dateFilterField]), startOfDay(filterDates.from)));
        } else if (filterDates?.to) {
            //is before
            filtered = filtered.filter((dg) => isBefore(new Date(dg[dateFilterField]), endOfDay(filterDates.to)));
        }

        if (filterType) {
            filtered = filtered.filter((dg) => dg.status === filterType);
        }

        if (filterText) {
            filtered = filtered.filter(
                (dg) =>
                    dg.name.toLowerCase().includes(filterText.toLowerCase()) ||
                    dg.userName.toLowerCase().includes(filterText.toLowerCase()) ||
                    dg.status.toLowerCase().includes(filterText.toLowerCase())
            );
        }

        return filtered;
    }, [dataGroups, filterDates, dateFilterField, filterType, filterText]);

    return { handleDateFilter, filteredDataGroups, isFilterActive, clearFilters, handleTypeFilter, handleSearchFilter };
};

export default useDataGroupsFilter;
