import { Button } from "@chakra-ui/button";
import { IoTrashOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { DeleteTemplateModalData } from "../../../../models/Modals.models";
import CommonModal from "../../CommonModal";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: DeleteTemplateModalData;
};

const DeleteTemplateModal = ({ isOpen, onClose, data }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];

    function onDelete() {
        data?.onDelete();
        onClose();
    }
    return (
        <CommonModal size="xs" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div className="text-brand-500">
                    <IoTrashOutline size={120} color="inherit" />
                </div>
                <div className="text-center leading-none">
                    <p>{t("templateWillBeDeleted")}</p>
                    <h3 className="title-3 mt-2">{data?.template.name}</h3>
                </div>

                <p className="text-lg text-dark">{t("doYouAgree")}</p>

                <div className="mt-10">
                    <Button rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false} onClick={onDelete}>
                        {t("accept")}
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default DeleteTemplateModal;
