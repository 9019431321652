import { BiddingValidationType } from "src/models/biddings/BiddingValidationType.models";
import biddingValidationTypeService from "src/services/biddings/biddingValidationType.service";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import useAuthState from "../authState";
import useModalState from "../modalsState";

interface State {
    biddingValidationTypes: { loading: boolean; error: boolean; data: BiddingValidationType[] };
    biddingValidationTypesCompany: { loading: boolean; error: boolean; data: BiddingValidationType[] };
    selectedBiddingValidationType: BiddingValidationType;
    setSelectedBiddingValidationType: (biddingValidationType: BiddingValidationType) => void;
    getBiddingValidationTypes: (withLoading?: boolean) => any;
    createBiddingValidationType: (biddingValidationType: BiddingValidationType, withLoading?: boolean) => Promise<boolean>;
    updateBiddingValidationType: (biddingValidationType: BiddingValidationType, withLoading?: boolean) => Promise<boolean>;
    deleteBiddingValidationType: (biddingValidationTypeId: string, withLoading?: boolean) => Promise<boolean>;
    reset: () => void;
}

const initialState = {
    biddingValidationTypes: { loading: false, error: false, data: [] },
    biddingValidationTypesCompany: { loading: false, error: false, data: [] },
    selectedBiddingValidationType: null,
};

const useBiddingValidationTypesState = create<State>((set, get) => ({
    ...initialState,
    setSelectedBiddingValidationType: (biddingValidationType: BiddingValidationType) =>
        set({ selectedBiddingValidationType: biddingValidationType }),
    getBiddingValidationTypes: getBiddingValidationTypes(set, get),
    getBidValidationTypesByCompany: getBiddingValidationTypesByCompany(set, get),
    createBiddingValidationType: createBiddingValidationType(set, get),
    updateBiddingValidationType: updateBiddingValidationType(set, get),
    deleteBiddingValidationType: deleteBiddingValidationType(set, get),
    reset: () => set((state) => ({ ...state, ...initialState })),
}));

export default useBiddingValidationTypesState;

const msgPermission = 'No cuenta con el permiso para esta acción.';
function hasPermissionForAction (reponse : any, msgPermission : string, redirect : boolean ){
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: 'AVISO',
                text2: msgPermission,
                text3: 'Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.',
                redirectHome : redirect,
                onClose: async () => {
                    return { show: false }
                  }
            }
        });
    }
}

//FUNCTIONS
function getBiddingValidationTypes(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        set((state) => ({ biddingValidationTypes: { loading: true, error: false, data: state.biddingValidationTypes.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingValidationTypeService.getBiddingValidationTypes();
         
        hasPermissionForAction(res,"Listar",false);

        if (res?.data?.codigo === 0) {
            set({ biddingValidationTypes: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching biddingValidationTypes");
            set((state) => ({ biddingValidationTypes: { loading: false, error: true, data: state.biddingValidationTypes.data } }));
            //clean error
            // set((state) => ({ biddingValidationTypes: { ...state.biddingValidationTypes, error: false } }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function getBiddingValidationTypesByCompany(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        set((state) => ({
            biddingValidationTypesCompany: { loading: true, error: false, data: state.biddingValidationTypesCompany.data },
        }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingValidationTypeService.getBiddingValidationTypesByCompany(idCompany);
        
        hasPermissionForAction(res,"Listar",false);

        if (res?.data?.codigo === 0) {
            set({ biddingValidationTypesCompany: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching biddingValidationTypes");
            set((state) => ({
                biddingValidationTypesCompany: { loading: false, error: true, data: state.biddingValidationTypesCompany.data },
            }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function createBiddingValidationType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingValidationType: BiddingValidationType, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingValidationTypeService.createBiddingValidationType(biddingValidationType);
        
        hasPermissionForAction(res,"Guardar",false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating biddingValidationType");
            return false;
        }
    };
}

function updateBiddingValidationType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingValidationType: BiddingValidationType, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
       
        const res = await biddingValidationTypeService.updateBiddingValidationType(biddingValidationType);
        
        hasPermissionForAction(res,"Actualizar",false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error updating biddingValidationType");
            return false;
        }
    };
}

function deleteBiddingValidationType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingValidationTypeId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        
        const res = await biddingValidationTypeService.deleteBiddingValidationType(biddingValidationTypeId);
        
        hasPermissionForAction(res,"Eliminar",false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error deleting biddingValidationType");
            return false;
        }
    };
}
