import logoOrkestadoc from "../../assets/images/logo-scm-alt.png";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import ResetPasswordForm from "src/components/auth/ResetPasswordForm";

const ResetPasswordPage = () => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="h-screen w-screen flex">
            <div className="w-full md:w-6/12 flex justify-center items-center flex-col">
                <ResetPasswordForm />
            </div>
            <div
                className="w-6/12 hidden md:flex flex-col justify-between items-center py-24"
                style={{
                    backgroundImage: "url('/images/auth-bg.png')",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                }}
            >
                <div className="h-12"></div>
                <div>
                    <img src={logoOrkestadoc} className="h-20" alt="axeleratum" />
                </div>
            </div>
        </div>
    );
};

export default ResetPasswordPage;
