import { Button } from "@chakra-ui/button";
import { IoTrashOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { InfoCommonModalData } from "../../../models/Modals.models";
import CommonModal from "../CommonModal";
import { BsExclamationCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import useModalState from "../../../store/modalsState";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: InfoCommonModalData;
};

const RestrictedActionModal = ({ isOpen, onClose, data }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const navigate = useNavigate();
    const { setLoaderModal} = useModalState();

    function handleRedirectHome() {
       if(data.redirectHome){
        navigate("/");
            onClose();
       }else{
            onClose();
       }
      
    }
    //onClick={() => navigate("/groups-users")}
    return (
        <CommonModal size="lg" isOpen={isOpen} onClose={handleRedirectHome}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div className="text-brand-500">
                    <BsExclamationCircleFill size={40} color="inherit" />
                </div>
                <div className="text-center leading-none">
                    <p className="title-3 mt-2 text-strong">{data?.text1}</p>
                    <h3 className=" mt-2 font-bold">{data?.text2}</h3>
                    <h3 className=" mt-2">{data?.text3}</h3>
                </div>
                <div className="mt-10">
                    <Button
                        rounded="full"
                        size="md"
                        fontWeight="medium"
                        px={"2rem"}
                        onClick={handleRedirectHome}
                    >
                        {t("accept")}
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default RestrictedActionModal;
