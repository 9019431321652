import { Button, Tooltip, useDisclosure } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import usePagination from "../../hooks/usePagination";
import { BiUserPlus } from "react-icons/bi";
import useModalState from "../../store/modalsState";
import CommonTh from "../common/table/CommonTh";
import LinkButton from "../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { AiOutlineEdit } from "react-icons/ai";
import useRolesState from "src/store/rolesState";
import usePermissionsState from "src/store/permissionState";
import { Role } from "src/models/Roles.models";
import { Permission } from "src/models/Permission.models";
import SlicedText from "../common/SlicedText";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";
import { FaLock } from "react-icons/fa";
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from "@chakra-ui/react";
import React from "react";

const DrawerInfo = (method) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    function getColorFromProp(method) {
        switch (method) {
            case "GET":
                return "#3182ce"; // Azul para GET
            case "POST":
                return "#4FD1C5"; // Verde azulado para POST
            case "PUT":
                return "#68D391"; // Verde para PUT
            case "DELETE":
                return "#F56565"; // Rojo para DELETE
            case "PATCH":
                return "#F6E05E"; // Amarillo para PATCH
            default:
                return "gray"; // Color predeterminado para otros casos
        }
    }
    return (
        <>
            <Button colorScheme="white" variant="outline" onClick={onOpen}>
                <FaLock style={{ color: getColorFromProp(method) }} />
            </Button>
            <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader borderBottomWidth="1px">Nivel de Permiso: {method}</DrawerHeader>
                    <DrawerBody>
                        <div className="flex items-center p-2">
                            <FaLock className="text-gray-500 mr-2" style={{ color: getColorFromProp("GET") }} />
                            <strong className="text-blue-500">GET-Permiso Bajo</strong>:
                            <span className="text-gray-700 ml-2">Se utiliza para recuperar información en el sistema.</span>
                        </div>
                        <div className="flex items-center p-2">
                            <FaLock className="text-gray-500 mr-2" style={{ color: getColorFromProp("POST") }} />
                            <strong className="text-blue-500">POST-Permiso Moderado</strong>:
                            <span className="text-gray-700 ml-2">Se utiliza para enviar nueva información en el sistema.</span>
                        </div>
                        <div className="flex items-center p-2">
                            <FaLock className="text-gray-500 mr-2" style={{ color: getColorFromProp("PUT") }} />
                            <strong className="text-blue-500">PUT-Permiso Moderado a Alto</strong>:
                            <span className="text-gray-700 ml-2">
                                Se utiliza para realizar actualizaciones importantes en la información existente en el sistema.
                            </span>
                        </div>
                        <div className="flex items-center p-2">
                            <FaLock className="text-gray-500 mr-2" style={{ color: getColorFromProp("PATCH") }} />
                            <strong className="text-blue-500">PATCH-Permiso Moderado a Alto</strong>:
                            <span className="text-gray-700 ml-2">
                                Se utiliza para para actualizaciones parciales información en el sistema.
                            </span>
                        </div>
                        <div className="flex items-center p-2">
                            <FaLock className="text-gray-500 mr-2" style={{ color: getColorFromProp("DELETE") }} />
                            <strong className="text-blue-500">DELETE-Permiso Alto Peligro</strong>:
                            <span className="text-gray-700 ml-2">Se utiliza para eliminación información en el sistema.</span>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

const RolesTableRow = ({ role, onDelete, onEdit }: { role: Role; onDelete: (role: Role) => any; onEdit: (role: Role) => any }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 py-5">{role.name}</td>

            <td className="p-2 py-3">{role.description}</td>
            <td className="px-2 py-3">
                <div className="w-full xl:w-7/12 flex justify-center gap-6">
                    <Tooltip label={t("edit")} fontSize="12px">
                        <button onClick={() => onEdit(role)}>
                            <AiOutlineEdit size={25} />
                        </button>
                    </Tooltip>
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={() => onDelete(role)}>
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};
const PermissionsTableRow = ({
    permission,
    onDelete,
    onEdit,
}: {
    permission: Permission;
    onDelete: (permission: Permission) => Permission;
    onEdit: (permission: Permission) => Permission;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

   // const description = permission.description.replace(/\s/g, "");
    const description = permission.description
  ? permission.description.replace(/\s/g, "") || "N/A"
  : "N/A";

    console.log(description);
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 py-3">
                <SlicedText text={permission.tag ? permission.tag.replace(/[\[\]]/g, "") : permission.tag} maxLength={42} />
            </td>
            <td className="p-2 py-3">{t(description ?? ("undefined" as any))}</td>
            <td className="p-12 py-3">{DrawerInfo(permission.method)}</td>

            <td className="py-3">
                <div className="w-full xl:w-5/12 flex justify-center gap-6">
                    {/* <Tooltip label={t("edit")} fontSize="12px">
                        <button onClick={() => onEdit(permission)}>
                            <AiOutlineEdit size={25} />
                        </button>
                    </Tooltip> */}
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={() => onDelete(permission)} disabled>
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const RolesAdminTable = ({
    roles,
    onDelete,
    onEdit,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
}: {
    roles: Role[];
    onDelete: (role: Role) => any;
    onEdit: (role: Role) => any;
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            {/* <div className="w-full flex justify-between items-center sticky top-0 z-10 py-3 bg-white border-b">
                <SearchInput onChange={onSearch} />
            </div> */}
            <table className="w-full text-sm text-left text-dark-2">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("description")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("actions")} />
                        </th>
                    </tr>
                    <tr></tr>
                </thead>
                <tbody>
                    {roles?.map((item) => (
                        <RolesTableRow key={item.id} role={item} onDelete={onDelete} onEdit={onEdit} />
                    ))}
                </tbody>
            </table>
            {roles?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

const PermissionsAdminTable = ({
    permissions,
    onDelete,
    onEdit,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
}: {
    permissions: Permission[];
    onDelete: (permission: Permission) => any;
    onEdit: (permission: Permission) => any;
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            {/* <div className="w-full flex justify-between items-center sticky top-0 z-10 py-3 bg-white border-b">
                <SearchInput onChange={onSearch} />
            </div> */}
            <table className="w-full text-sm text-left text-dark-2">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("description")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("permissionLevel")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("actions")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {permissions?.map((item) => (
                        <PermissionsTableRow key={item.id} permission={item} onDelete={onDelete} onEdit={onEdit} />
                    ))}
                </tbody>
            </table>
            {permissions?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

const RolesAdmin = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const { setNewEditRoleModal, setDeleteCommonModal } = useModalState();
    const [tab, setTab] = useState<"role" | "permission">("role");

    const {
        getRoles,
        roles: { data: roles },
        deleteRole,
    } = useRolesState();

    const {
        getPermissions,
        permissions: { data: permissions },
        deletePermission,
    } = usePermissionsState();

    const filteredRolesResults = useMemo(() => {
        if (tab === "role") {
            return roles?.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
        }
        return [];
    }, [searchQuery, roles, tab]);

    const filteredPermissionsResults = useMemo(() => {
        if (tab === "permission") {
            return permissions?.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
        }
        return [];
    }, [searchQuery, permissions, tab]);

    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({
        data: tab === "role" ? (filteredRolesResults as any) : (filteredPermissionsResults as any),
        itemsPerPage: 6,
    });

    function handleDeleteRole(role: Role) {
        setDeleteCommonModal({
            show: true,
            data: { text1: "Esta a punto de eliminar el role", text2: `${role?.name}`, onDelete: () => deleteRole(role.id) },
        });
    }

    function handleEditRole(role: Role) {
        setNewEditRoleModal({ show: true, data: { type: "edit", role: role } });
    }

    function handleDeletePermission(permission: Permission) {
        setDeleteCommonModal({
            show: true,
            data: {
                text1: "Esta a punto de eliminar el permiso",
                text2: `${permission?.name}`,
                onDelete: () => deletePermission(permission.id),
            },
        });
    }

    useEffect(() => {
        getRoles();
        getPermissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-4 mb-4">
                <DottedSubtitle
                    text={"Roles y Permisos"}
                    withSearch
                    onSearch={setSearchQuery}
                    beforeSearchElement={
                        <LinkButton
                            leftIcon={<BiUserPlus size={20} />}
                            onClick={() => setNewEditRoleModal({ show: true, data: { type: "new" } })}
                        >
                            Agregar rol
                        </LinkButton>
                    }
                />
            </div>
            <Tabs isFitted variant="enclosed-colored">
                <TabList>
                    <Tab isSelected={tab === "role"} onMouseDown={() => setTab("role")}>
                        Roles
                    </Tab>
                    <Tab isSelected={tab === "permission"} onMouseDown={() => setTab("permission")}>
                        Permisos
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <RolesAdminTable
                            roles={currentResutls as any}
                            onDelete={handleDeleteRole}
                            onEdit={handleEditRole}
                            currentPage={currentPage}
                            maxPages={maxPages}
                            prev={prev}
                            next={next}
                            setPage={setPage}
                        />
                    </TabPanel>
                    <TabPanel>
                        <PermissionsAdminTable
                            permissions={currentResutls as any}
                            onDelete={handleDeletePermission}
                            onEdit={null}
                            currentPage={currentPage}
                            maxPages={maxPages}
                            prev={prev}
                            next={next}
                            setPage={setPage}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default RolesAdmin;
