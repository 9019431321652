import { Radio, RadioGroup } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import usePagination from "../../../../hooks/usePagination";
import { User } from "../../../../models/Aministration.models";
import useAdministrationState from "../../../../store/administrationState";
import { BiUserPlus } from "react-icons/bi";
import useModalState from "../../../../store/modalsState";
import CommonTh from "../../../common/table/CommonTh";
import LinkButton from "../../../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import DottedSubtitle from "../../../common/DottedSubtitle";
import Paginator from "../../../common/Paginator";

const TableRow = ({ user, handleChange, selectedUser }: { user: User; handleChange: any; selectedUser: string }) => {
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 py-3">
                <RadioGroup onChange={() => handleChange(user.id)} value={selectedUser}>
                    <Radio value={user.id} />
                </RadioGroup>
            </td>
            <td className="p-2 py-3">{`${user.name} ${user.lastName}`}</td>
            <td className="p-2 py-3">{user.email}</td>
        </tr>
    );
};

const UsersAdminTable = ({
    users,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
    handleChange,
    selectedUser,
}: {
    users: User[];
    handleChange: any;
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
    selectedUser: string;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            <table className="w-full text-sm text-left text-dark-2 cursor-pointer">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2"></th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("email")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((item) => (
                        <TableRow key={item.id} user={item} handleChange={handleChange} selectedUser={selectedUser} />
                    ))}
                </tbody>
            </table>
            {users?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

const UsersAdmin = ({ selectedUser, handleSelectUser }: any) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [searchQuery, setSearchQuery] = useState("");
    const { setNewEditUserModal } = useModalState();
    const [userType, setUserType] = useState<"internal" | "external">("internal");

    const {
        companyUsers: { data: users },
        getCompanyUsers,
    } = useAdministrationState();

    const externalUsers = useMemo(() => {
        return users?.filter((user) => user.external);
    }, [users]);

    const internalUsers = useMemo(() => {
        return users?.filter((user) => !user.external);
    }, [users]);

    const currentUsers = userType === "internal" ? internalUsers : externalUsers;

    const filteredResults = useMemo(() => {
        return currentUsers?.filter((user) => user.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [searchQuery, currentUsers]);

    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({ data: filteredResults, itemsPerPage: 6 });

    useEffect(() => {
        getCompanyUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-4 mb-4">
                <DottedSubtitle
                    text={t("users")}
                    withSearch
                    onSearch={setSearchQuery}
                    beforeSearchElement={
                        <LinkButton
                            leftIcon={<BiUserPlus size={20} />}
                            onClick={() => setNewEditUserModal({ show: true, data: { type: "new", userType: userType } })}
                        >
                            {t("addNewUser")}
                        </LinkButton>
                    }
                />
            </div>

            <Tabs isFitted variant="enclosed-colored">
                <TabList>
                    <Tab isSelected={userType === "internal"} onClick={() => setUserType("internal")}>
                        Internos
                    </Tab>
                    <Tab isSelected={userType === "external"} onClick={() => setUserType("external")}>
                        Externos
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <UsersAdminTable
                            users={currentResutls}
                            currentPage={currentPage}
                            maxPages={maxPages}
                            prev={prev}
                            next={next}
                            setPage={setPage}
                            handleChange={handleSelectUser}
                            selectedUser={selectedUser}
                        />
                    </TabPanel>
                    <TabPanel>
                        <UsersAdminTable
                            users={currentResutls}
                            currentPage={currentPage}
                            maxPages={maxPages}
                            prev={prev}
                            next={next}
                            setPage={setPage}
                            handleChange={handleSelectUser}
                            selectedUser={selectedUser}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default UsersAdmin;
