import { Button, Checkbox, FormControl, FormLabel, Switch } from "@chakra-ui/react";
import { forEach } from "lodash";
import { useEffect, useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { PasswordPolicyList, PolicyData } from "src/models/Aministration.models";
import useAdministrationState from "src/store/administrationState";
import useModalState from "src/store/modalsState";
import LinkButton from "../common/buttons/LinkButton";
import { TranslationKeys } from "src/i18n/en";
import { useTranslation } from "react-i18next";
import useCustomToast from "src/hooks/useCustomToast";

const AuthenticationTabAdmin: () => JSX.Element = () => {
    /** */
    const t: ( key: TranslationKeys ) => string = useTranslation( "global" )[0];
    const { getPoliciesByCompanyList, updatePoliciesList } = useAdministrationState();
    const [ policies, setPolicies ] = useState<PasswordPolicyList[]>([]);
    const [ policiesOtp, setPoliciesOtp ] = useState<PasswordPolicyList[]>([]);
    const [ policyData, setPolicyData ] = useState<PolicyData>();
    const { setShowUsersOtpModal } = useModalState();
    const toast = useCustomToast();

    useEffect( () => {
        getPoliciesByCompanyList().then( ( result ) => {
            /** */
            setPolicyData( result.respuesta );
            makeAndSetPolicies( result.respuesta.passwordPolicyList );
        });
    }, [] );

    function makeAndSetPolicies( data: PasswordPolicyList[] ) {
        /** Reescribir valor del value para evitar errores */
        data.map( item => !item.value && ( item.value = '' ) );

        setPolicies( data.filter( item => !item.policyType.includes( 'OTP' ) ) );

        /** Políticas OTP */
        const filteredOtpPolicies = data.filter( item => item.policyType.includes( 'OTP' ) );
        const principalOtpPolicy = filteredOtpPolicies.find( item => item.policyType === 'OTP_REQUIRED');

        if ( principalOtpPolicy ) {
            !principalOtpPolicy.active && filteredOtpPolicies.map( item => item.active = false );
        }

        setPoliciesOtp( filteredOtpPolicies );
    }

    const handlePolicy = ( index: number, type: string, value?: string ) => {
        /** */
        const updatedPolicies = policies.map( ( policy, idx ) => {

            if ( type === 'status' && ( index === idx ) ) {

                const input = document.getElementById( `input-policy${index}` );

                policy.active = !policy.active;

                if ( policy.active && input ) {
                    input.removeAttribute( 'disabled' );
                    input.focus();
                }

                ( !policy.active && input ) && input.setAttribute( 'disabled', 'true' );
            }

            if ( type === 'value' ) {
                index === idx && ( policy.value = value );
            }

            return policy;
        });

        setPolicies( updatedPolicies );
    }

    const handlePolicyOtp = ( index: number, type: string, value?: string ) => {
        /** */
        const updatedPoliciesOtp = policiesOtp.map( ( policy, idx ) => {

            if ( type === 'status' && ( index === idx ) ) {

                const input = document.getElementById( `input-policy-otp${index}` );

                policy.active = !policy.active;

                if ( policy.active && input ) {
                    input.removeAttribute( 'disabled' );
                    input.focus();
                }

                if ( policy.active && policy.policyType === 'OTP_REQUIRED' ) {
                    handleUsersOtpModal();
                }

                ( !policy.active && input ) && input.setAttribute( 'disabled', 'true' );
            }

            if ( type === 'value' ) {
                index === idx && ( policy.value = value );
            }

            return policy;
        });

        setPoliciesOtp( updatedPoliciesOtp );
    }

    const handleSavePolicies = () => {
        /** */
        let policy: PolicyData = policyData;

        policy.passwordPolicyList = policies.concat( policiesOtp );

        setPolicyData( policy );

        updatePoliciesList( policyData ).then( result => {
            /** */
            result?.codigo === 0
                ? toast.success( result.mensaje )
                : toast.error( result.mensaje );
        });
    }

    const handleUsersOtpModal = () => {
        /** */
        setShowUsersOtpModal({ show: true });
    }

    return (
        <>
            <div className="authentication-tab w-full flex justify-between gap-4">
                <div className="w-1/2 border-2 rounded-lg p-4 border-slate-300">
                    <h1 className="rounded-lg text-brand-500 w-full text-center py-2 font-semibold">
                        {t("policies")}
                    </h1>
                    <div className="h-full max-h-72 overflow-x-auto">
                        {policies.map( ( policy, index ) => (
                            <div key={index} className="flex justify-between mt-2">
                                <div className="px-2 py-1 pr-0 flex justify-center items-center">
                                    <FormControl display='flex' alignItems='center'>
                                        <div className="mr-3">
                                            <Switch id={'sw-policy'+index}
                                                size="md"
                                                isChecked={policy.active}
                                                onChange={() => handlePolicy( index, 'status' )}
                                            />
                                        </div>
                                        <FormLabel className="cursor-pointer"
                                            htmlFor={'sw-policy'+index} mb='0'>
                                            {policy.description}
                                        </FormLabel>
                                    </FormControl>
                                </div>
                                <div className="px-2 py-1 pl-0 text-center flex justify-center align-center">
                                    { policy.valueType !== 'BOOLEAN' && (
                                        <>
                                            <input id={'input-policy'+index} type="number"
                                                className="px-2 text-center rounded-lg bg-slate-200 w-14 mr-2"
                                                value={policy.value}
                                                onChange={ e => handlePolicy( index, 'value', e.target.value ) }
                                                disabled={ policy.active ? null : true }
                                                min={policy.min}
                                                max={policy.max} />
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                        {policies.length === 0 && (
                            <div className="flex justify-center mt-2">
                                {t("noPoliciesData")}
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-1/2 border-2 rounded-lg p-4 border-slate-300">
                    <h1 className="rounded-lg text-brand-500 w-full text-center py-2 font-semibold">
                        {t("otpTitle")}
                    </h1>
                    <div className="h-full max-h-72 overflow-x-auto">
                        {policiesOtp.map( ( policy, index ) => (
                            <div key={index} className="flex justify-between mt-2">
                                <div className="px-2 py-1 pr-0 flex justify-center items-center cursor-pointer">
                                    <FormControl display='flex' alignItems='center'>
                                        <div className="mr-3">
                                            <Switch id={'sw-policy-otp'+index}
                                                size="md"
                                                isChecked={policy.active}
                                                onChange={() => handlePolicyOtp( index, 'status' )}
                                            />
                                        </div>
                                        <FormLabel className="cursor-pointer"
                                            htmlFor={'sw-policy-otp'+index} mb='0'>
                                            {policy.description}
                                        </FormLabel>
                                    </FormControl>
                                </div>
                                <div className="px-2 py-1 pl-0 text-center flex justify-center align-center">
                                    { policy.valueType !== 'BOOLEAN' && (
                                        <>
                                            <input id={'input-policy-otp'+index} type="number"
                                                className="px-2 text-center rounded-lg bg-slate-200 w-14 mr-2"
                                                value={policy.value}
                                                onChange={ e => handlePolicyOtp( index, 'value', e.target.value ) }
                                                disabled={ policy.active ? null : true }
                                                min={policy.min}
                                                max={policy.max} />
                                        </>
                                    )}
                                    { policy.policyType === 'OTP_REQUIRED' && (
                                        <LinkButton
                                            onClick={ () => handleUsersOtpModal() }>
                                            {t("addExceptions")}
                                        </LinkButton>
                                    )}
                                </div>
                            </div>
                        ))}
                        {policiesOtp.length === 0 && (
                            <div className="flex justify-center mt-2">
                                {t("noPoliciesData")}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center mt-4">
                <Button
                    type="submit"
                    rounded="full"
                    size="md"
                    onClick={handleSavePolicies}
                    fontWeight="medium"
                    px={"2rem"}
                    isLoading={false}>
                    {t("save")}
                </Button>
            </div>
        </>
    )
};

export default AuthenticationTabAdmin;