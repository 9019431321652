import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

const documentAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/documentType`,
    timeout: 20000,
});

const getDocumentTypes = async () => {
    try {
        return await documentAxiosRequest.get(`/all`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDocumentTypesByCompany = async (companyOwnerId: string) => {
    try {
        return await documentAxiosRequest.get(`/${companyOwnerId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// const getdocumentType = async (documentTypeId: string) => {
//     try {
//         return await documentAxiosRequest.get(`/${documentTypeId}`, {
//             headers: getHeaders(),
//         });
//     } catch (error) {
//         console.log(error);
//         return error;
//     }
// };

const createDocumentType = async (body: any) => {
    try {
        return await documentAxiosRequest.put(`/`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateDocumentType = async (body: any) => {
    try {
        return await documentAxiosRequest.patch(``, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteDocumentType = async (documentTypeId: string) => {
    try {
        return await documentAxiosRequest.delete(`/${documentTypeId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default { getDocumentTypes, getDocumentTypesByCompany, createDocumentType, updateDocumentType, deleteDocumentType };

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
