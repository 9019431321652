import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

export const userRolesScmRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/userRol`,
    timeout: 20000,
});

// api para rol-roles por usuario 

const createUserRols = async (body : any) => {
    try {
        return await userRolesScmRequest.put(``, body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
}

const getUserRols = async (idUser: string, idCompany : string) => {
    try {
        return await userRolesScmRequest.get(`/userId/${idUser}/companyId/${idCompany}`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateUserRolsByBody = async (body: any) => {
    try {
        return await userRolesScmRequest.patch(``, body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};
 
const updateUserRols = async (idUser: any , body: any) => {
    try {
        return await userRolesScmRequest.patch(`userRolId/${idUser}`, body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    createUserRols,
    getUserRols,
    updateUserRols,
    updateUserRolsByBody
};
