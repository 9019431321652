import axios from "axios";
import { DataGroup } from "../models/DataGroup.models";
import { DataType } from "../models/DataType.models";
import { getAuthHeader } from "../lib/utils";

interface CreateDataGroupBody {
    idCompany: string;
    idUser: string;
    name: string;
    status: string;
    types: DataType[];
    userName: string;
}

export const axiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/data_groups`,
    timeout: 10000,
});

const listDataGroups = async (idCompany: string) => {
    try {
        return await axiosRequest.post(
            "/returnDataGroupList",
            { idCompany, idUser: "" },
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createDataGroup = async (body: CreateDataGroupBody) => {
    try {
        return await axiosRequest.post("/saveDataGroup", body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateDataGroup = async (body: DataGroup) => {
    try {
        return await axiosRequest.post("/editDataGroup", body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteDataGroup = async (dataGoupId: string) => {
    try {
        return await axiosRequest.delete(`/deleteDataGroup/${dataGoupId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDataGroupById = async (dataGoupId: string) => {
    try {
        return await axiosRequest.get(`/returnDataGroup/${dataGoupId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    listDataGroups,
    createDataGroup,
    deleteDataGroup,
    getDataGroupById,
    updateDataGroup,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
