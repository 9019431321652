import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { FormErrorMessage } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCustomToast from "../../../../hooks/useCustomToast";
import CommonModal from "../../CommonModal";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import usePermissionsState from "src/store/permissionState";
import { useEffect, useMemo, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { ImCheckboxChecked, ImCheckboxUnchecked, ImMinus, ImPlus, ImStack } from "react-icons/im";
import { HiLockClosed } from "react-icons/hi";
import useRolesState from "src/store/rolesState";
import { Role } from "src/models/Roles.models";
import { NewEditRoleModalData } from "src/models/Modals.models";
import LinkButton from "../../buttons/LinkButton";
import { BiSelectMultiple } from "react-icons/bi";
import { RiCheckboxMultipleBlankLine } from "react-icons/ri";

interface TreeNode {
    value: string;
    label: string;
    children?: TreeNode[];
    className?: string;
    orderValue: string;
}

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: NewEditRoleModalData;
};

interface FormFields {
    name: string;
    description: string;
}

const NewEditRoleModal = ({ isOpen, onClose, data }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();

    const {
        permissions: { data: permissions },
        getPermissions,
    } = usePermissionsState();

    const { createRole, updateRole } = useRolesState();

    const [permissionsChecked, setPermissionsChecked] = useState<string[]>(data?.role?.idPermissionList || []);
    const [permissionsExpanded, setPermissionsExpanded] = useState<string[]>([]);

    const permissionTreeNodes = useMemo(() => {
        const nodes: TreeNode[] = [];

        permissions.forEach((originalNode) => {
            const tag = originalNode.tag;
            let parentNode = nodes.find((node) => node.value === tag);

            if (!parentNode) {
                parentNode = {
                    value: tag,
                    label: tag,
                    children: [],
                    className: "text-gray-700 text-sm font-semibold my-1",
                    orderValue: tag,
                };

                nodes.push(parentNode);
            }

            parentNode.children.push({
                value: originalNode.id,
                label: originalNode.description,
                className: "text-gray-700 text-sm font-light my-1 method-class-" + originalNode.method?.toLowerCase(),
                orderValue: originalNode.method,
            });
        });

        nodes.forEach((node) => {
            node.children.sort((a, b) => (a.orderValue > b.orderValue ? 1 : -1));
        });

        return nodes;
    }, [permissions]);

    const formik = useFormik<FormFields>({
        initialValues: {
            name: data?.role?.name || "",
            description: data?.role?.description || "",
        },
        onSubmit: data.type === "new" ? handleCreate : handleUpdate,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
            description: Yup.string().required(t("requiredField")),
        }),
    });

    async function handleCreate(values: FormFields) {
        const body: Partial<Role> = {
            name: values.name,
            description: values.description,
            idPermissionList: permissionsChecked,
        };
        const created = await createRole(body);
        if (created) {
            toast.success("Role creado correctamente");
            onClose();
        } else {
            toast.error("Error al crear el role");
        }
    }

    async function handleUpdate(values: FormFields) {
        const body: Partial<Role> = {
            name: values.name,
            description: values.description,
            idPermissionList: permissionsChecked,
        };
        const updated = await updateRole({ ...data.role, ...body });
        if (updated) {
            toast.success("Rol actualizado correctamente");
            onClose();
        } else {
            toast.error("Error al actualizar el rol");
        }
    }

    const handleSelectAll = () => {
        const allPermissions = permissionTreeNodes.reduce((acc, node) => {
            return [...acc, node.value, ...(node.children || []).map((child) => child.value)];
        }, []);
        setPermissionsChecked(allPermissions);
    };

    const handleDeselectAll = () => {
        setPermissionsChecked([]);
    };

    useEffect(() => {
        getPermissions();
    }, []);

    return (
        <CommonModal size="4xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div className="text-center">
                    <h2 className="title-3">{data.type === "new" ? "Nuevo Rol" : "Editar Rol"}</h2>
                </div>

                <form
                    className="w-full flex flex-wrap justify-between items-center gap-2 max-h-[75vh] overflow-y-auto px-2"
                    onSubmit={formik.handleSubmit}
                >
                    <FormControl isInvalid={!!formik.errors.name}>
                        <label htmlFor="name" className="text-sm leading-8">
                            {t("name")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.description}>
                        <label htmlFor="description" className="text-sm leading-8">
                            {t("description")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="description"
                                name="description"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                    </FormControl>

                    <div className="px-2 mt-4 w-full p-3">
                        <h2 className="text-xl text-gray-700 mb-4">Seleccionar Permisos</h2>
                        <div className="mb-2 ml-2">
                            <LinkButton leftIcon={<BiSelectMultiple size={20} />} onClick={handleSelectAll}>
                                Seleccionar todos
                            </LinkButton>
                            {permissionsChecked.length !== 0 && (
                                <LinkButton
                                    leftIcon={<RiCheckboxMultipleBlankLine size={20} />}
                                    onClick={handleDeselectAll}
                                    className="ml-4"
                                >
                                    Limpiar campos
                                </LinkButton>
                            )}
                        </div>

                        <div className="max-h-[300px] overflow-y-auto">
                            <CheckboxTree
                                nodes={permissionTreeNodes}
                                checked={permissionsChecked}
                                expanded={permissionsExpanded}
                                onCheck={(checked) => setPermissionsChecked(checked)}
                                onExpand={(expanded) => setPermissionsExpanded(expanded)}
                                icons={{
                                    check: <ImCheckboxChecked />,
                                    uncheck: <ImCheckboxUnchecked />,
                                    halfCheck: <ImCheckboxChecked style={{ opacity: 0.4 }} />,
                                    expandClose: <FaChevronDown />,
                                    expandOpen: <FaChevronUp />,
                                    expandAll: <ImPlus />,
                                    collapseAll: <ImMinus />,
                                    parentClose: <ImStack />,
                                    parentOpen: <ImStack />,
                                    leaf: (
                                        <span className="lock-icon">
                                            <HiLockClosed color="inherit" />
                                        </span>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div className="mt-8 pb-2 w-full text-center">
                        <Button type="submit" rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                            {data?.type === "new" ? t("add") : t("save")}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

export default NewEditRoleModal;
