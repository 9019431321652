import { Divider, Input, InputGroup, InputLeftElement, useBoolean, useOutsideClick } from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { ContractRevisionUserSelect } from "../../../models/Contract.models";
import { UserGroup } from "../../../models/Aministration.models";
import { getColorFromStr } from "../../../lib/utils";
import UserIcon from "../../icons/UserIcon";
import { MdSupervisedUserCircle } from "react-icons/md";

type Props = {
    items: ContractRevisionUserSelect[];
    onSelect: (user: ContractRevisionUserSelect) => any;
    companyUsersGroups: UserGroup[];
    onSelectUserGroup: (user: UserGroup) => any;
};

const ContractRevisionSearchMenu = ({ items, onSelect, companyUsersGroups, onSelectUserGroup }: Props) => {
    const [showList, setShowList] = useBoolean(false);
    const containerRef = useRef();
    useOutsideClick({
        ref: containerRef,
        handler: setShowList.off,
    });

    const [searchQuery, setSearchQuery] = useState("");

    const currentGroupItems = useMemo(() => {
        if (!searchQuery) return companyUsersGroups;

        const query = searchQuery.toLowerCase();
        return [companyUsersGroups.filter((group) => group.name?.toLowerCase()?.includes(query))];
    }, [searchQuery, companyUsersGroups]);

    const currentItems = useMemo(() => {
        if (!searchQuery) return items;

        const query = searchQuery.toLowerCase();
        return items.filter((item) => item.userName?.toLowerCase()?.includes(query));
    }, [searchQuery, items]);

    const [internalUsers, externalUsers] = useMemo(() => {
        return [currentItems.filter((item) => !item.external), currentItems.filter((item) => item.external)];
    }, [currentItems]);

    function handleOnSelect(item) {
        onSelect(item);
        setShowList.off();
    }

    function handleOnSelectUserGroup(item) {
        onSelectUserGroup(item);
        setShowList.off();
    }

    const UserGroupItem = ({ userGroup, onSelect }: { userGroup: UserGroup; onSelect: () => any }) => (
        <li onClick={onSelect} className="flex items-center text-gray-500 cursor-pointer hover:bg-gray-100 p-2">
            <MdSupervisedUserCircle size={40} color="#25469B" />
            <div className="ml-4">
                <div className="text-base text-gray-900">{userGroup.name}</div>
                <div className="text-xs">{userGroup.description}</div>
            </div>
        </li>
    );

    const Item = ({ user, onSelect }: { user: ContractRevisionUserSelect; onSelect: () => any }) => (
        <li onClick={onSelect} className="flex items-center text-gray-500 cursor-pointer hover:bg-gray-100 p-2">
            <UserIcon boxSize={8} />
            <div className="ml-4">
                <div className="text-base text-gray-900">{user.userName}</div>
                <div className="text-xs">{user.userEmail}</div>
            </div>
        </li>
    );

    return (
        <div className="relative" ref={containerRef}>
            <InputGroup className="border-b">
                <InputLeftElement h={9} color="gray.400" pointerEvents="none" children={<BiSearch color="inherit" size={20} />} />
                <Input
                    size={"md"}
                    variant="unstyled"
                    py={2}
                    placeholder="Buscar"
                    onFocus={setShowList.on}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </InputGroup>

            {showList && (
                <ul className="shadow-lg rounded-sm absolute top-[100%] left-0 right-0 bg-white z-30 max-h-[14rem] overflow-y-auto">
                    <div className="h-full pb-1">
                        <h3 className="w-full flex justify-between items-center text-base font-medium text-brand-600 border-y p-2 sticky top-0 bg-white">
                            Grupos de usuarios
                        </h3>
                        {currentGroupItems?.length ? (
                            currentGroupItems?.map((userGroup) => {
                                return (
                                    <UserGroupItem
                                        key={userGroup.id}
                                        userGroup={userGroup}
                                        onSelect={() => handleOnSelectUserGroup(userGroup)}
                                    />
                                );
                            })
                        ) : (
                            <div className="text-gray-500 text-sm p-2 text-center">No hay resultados</div>
                        )}
                    </div>
                    <Divider />
                    <div className="h-full pb-1">
                        <h3 className="w-full flex justify-between items-center text-base font-medium text-brand-600 border-y p-2 sticky top-0 bg-white">
                            Internos
                        </h3>
                        {internalUsers?.length ? (
                            internalUsers?.map((item) => <Item key={item.idUser} user={item} onSelect={() => handleOnSelect(item)} />)
                        ) : (
                            <div className="text-gray-500 text-sm p-2 text-center">No hay resultados</div>
                        )}
                    </div>
                    <Divider />
                    <div className="h-full pb-1">
                        <h3 className="w-full flex justify-between items-center text-base font-medium text-brand-600 border-y p-2 sticky top-0 bg-white">
                            Externos
                        </h3>
                        {externalUsers?.length ? (
                            externalUsers?.map((item) => <Item key={item.idUser} user={item} onSelect={() => handleOnSelect(item)} />)
                        ) : (
                            <div className="text-gray-500 text-sm p-2 text-center">No hay resultados</div>
                        )}
                    </div>
                </ul>
            )}
        </div>
    );
};

export default ContractRevisionSearchMenu;
