import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { CgListTree } from "react-icons/cg";
import { useHref, useParams } from "react-router-dom";
import BiddingGroupsPanel from "src/components/bidding/BiddingGroupsPanel";
import StageButton from "src/components/bidding/StageButton";
//import LinkButton from "src/components/common/buttons/LinkButton";
//import PowerBiIcon from "src/components/icons/iconsV2/PowerBiIcon";
import MainLayout from "src/components/layouts/MainLayout";
import { BiddingStage } from "src/models/biddings/Bidding.models";
import useBiddingState from "src/store/biddings/biddingState";
import useModalState from "src/store/modalsState";
import BoxButton from "../../components/common/BoxButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import DatosIcon from "../../assets/bidding-icons/DatosIcon";
import { GrStatusUnknown } from "react-icons/gr";
import TableroIcon from "src/assets/bidding-icons/TableroIcon";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/bidding-icons/BackIcon.png";
import useBiddingProfileState from "src/store/biddings/biddingProfileState";
import useAuthState from "../../store/authState";
import useAdministrationState from "src/store/administrationState";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosCube } from "react-icons/io";
import useComponetState from "src/store/components/componentState";
import Bitacora from "../../assets/traceability-icons/documento.png";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { IoMdNotificationsOutline } from "react-icons/io";
import useGlobalState from "src/store/globalState";
import { DataGroup } from "src/models/DataGroup.models";
import useCustomToast from "src/hooks/useCustomToast"
import RulesModal from "./ModalReglas";
import MovilData from "./MovilData";

const ShowBiddingPage = () => {
    const { id } = useParams();
    const setExpedientModal = useModalState((s) => s.setExpedientModal);
    const setPowerBiModal = useModalState((s) => s.setBiddingPowerBiModal);
    const setProfileModal = useModalState((s) => s.setBiddingProfileModal);
    const setNotificationsModal = useModalState((s) => s.setBiddingNotificationsModal);
    const setTraceabilityDrawer = useModalState((s) => s.setTraceabilityCommonDrawer);
    const setModalGeneradorState = useModalState((s) => s.setShowGeneradorStateModal);
    const { getBidding, selectedBidding, setSelectedBidding ,reset} = useBiddingState();
    const [selectedStage, setSelectedStage] = useState<BiddingStage>(null);
    const [selectedYear, setSelectedYear] = useState<number>(null);
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const idCompany = useAuthState.getState().companyId;
    const [matchingProfile, setMatchingProfile] = useState("");
    const [selectedProfile, setSelectedProfile] = useState<any>([]);
    const additionalCompanyInfo = useGlobalState((s) => s.additionalCompanyInfo);
    const shouldValidate = additionalCompanyInfo.isAmericaMovil;
    const toast = useCustomToast();
    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const [openMovil, setOpenMovil] = useState(false);

    const { movilData, movilDataImg, getMovilChekData ,getMovilImgData} = useBiddingState();

    const {getAllBtnGeneradores,uiBtnGeneradoresOptions} = useComponetState();

    const navigate = useNavigate();

    const { userId } = useAuthState();
    const {
        getCompanyUserGroupAnnexe,
        annexeCompanyUserGroup: { data: annexeCompanyUserGroup },
    } = useAdministrationState();

    const { getProfilesBiddingById, profilesBidding, getProfile, selectedBiddingProfile } = useBiddingProfileState();

    const matchingProfileFiltered = useMemo(() => {
        if (annexeCompanyUserGroup !== null && Array.isArray(profilesBidding)) {
            return annexeCompanyUserGroup[0]?.profileIdSet.find((id) => profilesBidding.some((profile) => profile.id === id)) || null;
        }
        return null;
    }, [annexeCompanyUserGroup, profilesBidding]);

    useEffect(() => {

        if (id) {
            getBidding(id).then(() => setLoading(true)); 
            getProfilesBiddingById(id);
        }
        getCompanyUserGroupAnnexe(userId);
        return () => {
            // cleanup
            //setSelectedBidding(null);
        };
    }, []);

    useEffect(() => {
        if (matchingProfileFiltered === null) {
            setSelectedProfile(null);
        } else if (matchingProfileFiltered !== matchingProfile && matchingProfileFiltered !== null) {
            getProfile(matchingProfileFiltered);
            setMatchingProfile(matchingProfileFiltered);
        }
    }, [matchingProfileFiltered, matchingProfile]);

    useEffect(() => {
        setSelectedProfile(selectedBiddingProfile);
        getAllBtnGeneradores();
    }, [selectedBiddingProfile]);

    useEffect(() => {
        //Hacer set del ultimo stage seleccionado , para no perder la posicion del usuario
        const lastSelectedStage = JSON.parse(localStorage.getItem("lastSelectedStage"));

        // Verifica si lastSelectedStage existe y si coincide con algún stage en selectedBidding
        if (lastSelectedStage && selectedBidding?.stages?.length > 0) {
            const matchingStage = selectedBidding.stages.find((stage) => stage.uuid === lastSelectedStage.uuid);

            if (matchingStage) {
                // Si hay un stage en selectedBidding que coincide con lastSelectedStage, actualizar lastSelectedStage
                setSelectedStage(matchingStage);
            } else {
                // Si no hay coincidencia, seleccionar el primer stage de selectedBidding
                setSelectedStage(selectedBidding.stages[0]);
                localStorage.setItem("lastSelectedStage", JSON.stringify(selectedBidding.stages[0]));
            }
        } else if (selectedBidding?.stages?.length > 0) {
            // Si lastSelectedStage no está en localStorage, seleccionar el primer stage de selectedBidding
            setSelectedStage(selectedBidding.stages[0]);
            localStorage.setItem("lastSelectedStage", JSON.stringify(selectedBidding.stages[0]));
        }
    }, [selectedBidding]);

    const yearsMonthsMapss = useMemo(() => {
        const map = {};
        selectedStage?.intervals?.forEach(({ year, month }) => {
            if (!map[year]) map[year] = new Set();
            map[year].add(month);
        });

        Object.keys(map).forEach((year) => {
            map[year] = Array.from(map[year]).sort((a: number, b: number) => a - b);
        });

        const parsedMap = Object.entries(map).sort((a, b) => Number(b[0]) - Number(a[0]));
        //select first year as default
        if (parsedMap.length > 0) setSelectedYear(Number(parsedMap[0][0]));

        return parsedMap;
    }, [selectedStage]);

    const yearsMonthsMap = useMemo(() => {
        const map = {};
        selectedStage?.intervals?.forEach(({ year, month }) => {
            if (!map[year]) map[year] = new Set();
            map[year].add(month);
        });

        Object.keys(map).forEach((year) => {
            map[year] = Array.from(map[year]).sort((a: number, b: number) => a - b);
        });

        const parsedMap = Object.entries(map).sort((a, b) => Number(a[0]) - Number(b[0])); // Cambiado de b[0] - a[0] a a[0] - b[0]

        // Seleccionar el primer año como predeterminado
        if (parsedMap.length > 0) setSelectedYear(Number(parsedMap[0][0]));

        return parsedMap;
    }, [selectedStage]);

    function handleExpedientModalOpen() {
        setExpedientModal({
            show: true,
            data: {
                biddingId: selectedBidding?.id,
                dataGroups: selectedBidding?.expedienteUnico ? selectedBidding?.expedienteUnico : [],
            },
        });
    }

    function handlePowerBiModal() {
        setPowerBiModal({
            show: true,
            data: {
                bidding: selectedBidding,
            },
        });
    }

    function handleProfileModal() {
        setProfileModal({
            show: true,
            data: {
                bidding: selectedBidding,
                stages: selectedBidding.stages,
            },
        });
    }

    function handleNotificationsModal() {
        setNotificationsModal({
            show: true,
            data: {
                bidding: selectedBidding,
                stages: selectedBidding.stages,
            },
        });
    }

    function handleTraceabilityDrawer() {
        setTraceabilityDrawer({
            show: true,
            data: {
                biddingId: "001",
            },
        });
    }

    function handleOpenGeneradorModal() {
        setModalGeneradorState({
            show: true,
            data: {
                biddingId: id,
            },
        });
    }
    
    
   


    function populateAction(stage) { 
        // por si en algun momento hay que cambiar la logica del btn , para efecto de demos
       handleSelectStage(stage);
    }

    const handleSelectStage = (stage) => {
        setSelectedStage(stage);
        // Guardar el stage seleccionado 
        localStorage.setItem("lastSelectedStage", JSON.stringify(stage));
    };

    const validateStages = (stage) => {
        const validStage =
            (!selectedProfile?.biddingType?.documentSet?.stages ||
                !stage.name ||
                (selectedProfile?.biddingType?.documentSet?.stages?.some &&
                    selectedProfile?.biddingType?.documentSet?.stages?.some((copyStage) => copyStage?.name === stage?.name))) === false;

        return validStage;
    };
    
    const isStageEnabled = (stages, index) => {
        if (index === 0) {
          return true; 
        }
        for (let i = 0; i < index; i++) {
          if (stages[i].percentage !== 100) {
            return false;
          }
        }
        return true;
      };

    function isDummy(texto) {
        const regex = /\bdemo-rfp\b/;

      //  const regex = /demo/i; // La "i" en la expresión regular hace que sea insensible a mayúsculas y minúsculas
        return regex.test(texto);
    }
 

    return (
        <MainLayout>
            <main className="w-full p-3 flex h-full">
                {/* left panel */}
                <section className="w-3/12 flex flex-col pr-4 pl-2 border-r h-full max-h-full overflow-y-auto relative border-dashed">
                    <div className="flex flex-col lg:flex-row gap-2 pt-2 pb-2 sticky bottom-0 bg-white border-b border-dashed">
                        <button onClick={() => navigate("../")}>
                            <img src={BackIcon} alt="back icon" className="w-8 pb-2"></img>
                        </button>
                        <h2 className="title-3">{selectedBidding?.name}</h2>
                    </div>
                    {/**<div className="flex flex-col lg:flex-row gap-2 pt-2 pb-2 sticky bottom-0 bg-white border-b border-dashed">
                    <Button onClick={handleTraceabilityDrawer} w={"full"}
                        rightIcon={<img src={Bitacora} 
                        alt="bitacora" 
                        className="w-8 pb-2"></img>} 
                        colorScheme='blue' 
                        variant='outline'>
                        {"Bitácora"}
                    </Button>
                    </div> */}
                    {/*<p className="mt-2 text-xs text-justify">
                            "Las financiamientos son el proceso de compra de bienes y servicios por parte de una empresa o entidad pública. En este proceso, las empresas interesadas en participar en la financiamiento deben presentar sus ofertas, que serán evaluadas por el comprador."
                    </p>*/}
                    <div className="flex flex-col items-center mt-6 gap-4 grow">
                        {selectedBidding?.stages?.map((stage, i) => (
                            <StageButton
                                selected={selectedStage?.uuid === stage.uuid}
                                text={stage.name}
                                onClick={() => populateAction(stage)}
                                icon={<CgListTree size={24} color="inherit" />}
                                percentage={stage.percentage}
                                key={stage.uuid}
                                //disabled={validateStages(stage)}
                                disabled={shouldValidate ? !isStageEnabled(selectedBidding.stages, i) : validateStages(stage)}

                            />
                        ))}
                    </div>

                    <div className="flex flex-col lg:flex-col justify-center gap-2 pt-2 pb-2 sticky bottom-0 bg-white border-t border-dashed">
                        {/* isTelcel(selectedBidding?.name) ? 
                       <BoxButton
                       icon={<TableroIcon boxSize={70} />}
                       //hoverIcon={<AddNewFilledIcon boxSize={70} />}
                       buttonW={true}
                       size={"small"}
                       text={"Operación Diaria"}
                       tooltip={"Operación Diaria"}
                       onClick={()=> window.open("https://www.figma.com/proto/NXVCm4DL0q25CxAdSbFEwX/SCM-nuevo?type=design&node-id=545-1532&t=mO6MR3VtDi30NgFc-1&scaling=scale-down-width&page-id=522%3A1114&starting-point-node-id=545%3A1532&show-proto-sidebar=1", "_blank")}
                       />: 
                       ""  /**|| "662283837489b25bd0371841" */

                    }
                        {selectedBidding?.urlPowerBi !== ""  && (
                            <BoxButton
                                icon={<TableroIcon boxSize={45} />}
                                buttonW={true}
                                size={"small"}
                                text={"Tablero Ejecutivo"}
                                tooltip={"Ver tablero de reporte"}
                                onClick={handlePowerBiModal}
                                alignLeft
                            />
                        )}
                        {/** {selectedBidding?.hasExpedienteUnico === false || selectedBidding?.expedienteUnico.length > 0 && (*/}
                        {selectedBidding?.hasExpedienteUnico === true && (
                            <BoxButton
                                icon={<DatosIcon boxSize={45} />}
                                size={"small"}
                                buttonW={true}
                                text={"Expediente de datos"}
                                tooltip={t("biddingPageTooltip")}
                                alignLeft
                                onClick={handleExpedientModalOpen}
                            />
                        )}



                        {!uiBtnGeneradoresOptions.showBtnModalGeneradorStatus ? ("") : (
                                        selectedBidding?.urlGeneradores === true && (
                                            <BoxButton
                                                icon={<IoIosCube size={30} color="#2e54b8" />}
                                                size={"small"}
                                                buttonW={true}
                                                text={"Estatus Generador"}
                                                tooltip={"Consulta o cambia el estatus del generador"}
                                                alignLeft
                                                onClick={handleOpenGeneradorModal}
                                            />
                                        )
                                    )}
                          


{/**selectedBidding?.id === "65eb4f43665aef58b869c55a" ||  "662283837489b25bd0371841"  && ( */}
                        {/*
                           <>
                           <BoxButton
                             icon={<DatosIcon boxSize={45} />}
                             size={"small"}
                             buttonW={true}
                             text={"Reglas de Negocio"}
                             tooltip={"biddingPageTooltip"}
                             alignLeft
                             onClick={() => setOpen(true)}
                           />
                           <RulesModal isOpen={open} onClose={() => setOpen(false)} /> 
                         </>
                    */}

                        {/*
                           <>
                           <BoxButton
                             icon={<DatosIcon boxSize={45} />}
                             size={"small"}
                             buttonW={true}
                             text={"Evidencias App"}
                             tooltip={"Evidencias"}
                             alignLeft
                             onClick={() => setOpenMovil(true)}
                           />
                           <MovilData isOpen={openMovil} onClose={() => setOpenMovil(false)} movilData={movilData} movilDataImg={movilDataImg}/> 
                         </>
                */ }

                        {idCompany === selectedBidding?.companyOwnerId && selectedBidding?.createdBy === userId && (
                            <>
                                <BoxButton
                                    icon={<IoIosAddCircleOutline size={30} color="#2e54b8" />}
                                    buttonW={true}
                                    size={"small"}
                                    text={"Crear Perfil"}
                                    tooltip={"Crear Perfil"}
                                    onClick={handleProfileModal}
                                    alignLeft
                                />
                           
                            </>
                        )}

                        {idCompany === selectedBidding?.companyOwnerId && selectedBidding?.createdBy === userId && (
                            <>
                                <BoxButton
                                    icon={<IoMdNotificationsOutline size={30} color="#2e54b8" />}
                                    buttonW={true}
                                    size={"small"}
                                    text={"Configurar Notificaciones"}
                                    tooltip={"Configurar Notificaciones"}
                                    onClick={handleNotificationsModal}
                                    alignLeft
                                />
                           
                            </>
                        )}

                    </div>
                </section>

                {/* right panel */}
                <section className="w-9/12 pr-2 pl-4 h-full max-h-full overflow-y-auto">
                    <div className="w-full flex items-center justify-between py-4 border-b border-dashed">
                        <h3 className="font-light text-brand-500 italic" color={"brand.500"}>
                            {selectedStage?.name}
                        </h3>
                        {/** <div className="flex items-center gap-4">
                            <LinkButton rightIcon={<PowerBiIcon boxSize={5} />} onClick={handlePowerBiModal}>
                                {" "}
                                Ver Power BI
                            </LinkButton>
                        </div>*/}
                    </div>
                    <div className="w-full pt-4">
                        {selectedStage && validateStages(selectedStage) ? (
                            <p className="font-bold text-[#3557DB]">No cuentas con acceso a esta etapa.</p>
                        ) : (
                            <Box>
                            <Tabs isFitted variant="enclosed-colored">
                                <TabList>
                                    {yearsMonthsMap
                                        .sort(([yearA], [yearB]) => Number(yearA) - Number(yearB)) // Ordenar por año de menor a mayor
                                        .map(([year]) => (
                                            <Tab
                                                key={year}
                                                isSelected={Number(year) === selectedYear}
                                                onClick={() => setSelectedYear(parseInt(year))}
                                            >
                                                {year}
                                            </Tab>
                                        ))}
                                </TabList>
                                <TabPanels>
                                    {yearsMonthsMap.map(([year, months]) => (
                                        <TabPanel>
                                            {Number(year) === selectedYear ? (
                                                <BiddingGroupsPanel
                                                    key={selectedStage?.uuid}
                                                    months={months as any}
                                                    stage={selectedStage}
                                                    stageCopy={selectedProfile?.biddingType?.documentSet?.stages}
                                                    selectedYear={selectedYear}
                                                    afterUploadRequirementDocument={() => {}}
                                                    selectedBiddingId={selectedBidding?.id}
                                                />
                                            ) : (
                                                <div>No seleccionado</div>
                                            )}
                                        </TabPanel>
                                    ))}
                                </TabPanels>
                            </Tabs>
                        </Box>
                        )}
                    </div>
                </section>
            </main>
        </MainLayout>
    );
};

export default ShowBiddingPage;
