import { IoClose, IoTrashOutline } from "react-icons/io5";
import CommonTh from "../common/table/CommonTh";
import useModalState from "../../store/modalsState";
import DatePickerTh from "../common/table/DatePickerTh";
import SelectTh from "../common/table/SelectTh";
import TablePaginator from "../common/table/TablePaginator";
import SearchInput from "../common/table/SearchInput";
import { Tooltip } from "@chakra-ui/tooltip";
import { Contract } from "../../models/Contract.models";
import { formatDate, getContractStatusColor } from "../../lib/utils";
import usePagination from "../../hooks/usePagination";
import useContractState from "../../store/contractState";
import useCustomToast from "../../hooks/useCustomToast";
import { useNavigate } from "react-router-dom";
import { Badge } from "@chakra-ui/react";
import useContractsFilter from "../../hooks/table-filters/useContractsFilter";
import LinkButton from "../common/buttons/LinkButton";
import { CONTRACT_STATUSES } from "../../lib/constants";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { GrClone } from "react-icons/gr";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";

const TableRow = ({
    contract,
    onClone,
    onDelete,
}: {
    contract: Contract;
    onDelete: (event, template) => void;
    onClone: (event, template) => void;
}) => {
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    console.log("oapa",contract)
    return (
        <tr
            className="border-b hover:bg-slate-100 transition-colors duration-200 cursor-pointer"
            onClick={() => navigate(`${contract.id}`)}
        >
            {/* <td className="w-4 px-2 py-3" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center">
                    <Checkbox />
                </div>
            </td> */}
            <td className="px-2 py-3">{contract.name}</td>
            <td className="px-2 py-3">{contract.userName}</td>
            <td className="px-2 py-3">{formatDate(new Date(contract?.createdDate))}</td>
            {/* <td className="px-2 py-3">{contract.companyName}</td> */}
            <td className="px-2 py-3">{contract.type}</td>
            <td className="px-2 py-2">{contract.template ? contract.template.name : "N/D"}</td>
            <td className="px-2 py-3">
                <Badge colorScheme={getContractStatusColor(contract.status)}>{t(contract.status)}</Badge>
            </td>
            <td className="px-2 py-3">{formatDate(new Date(contract?.lastModifiedDate || "01-01-2022"))}</td>
            <td className="px-2 py-3">
                <div className="w-full flex xl:w-8/12 justify-center gap-6">
                    <Tooltip label="Clonar" fontSize="12px">
                        <button onClick={(e) => onClone(e, contract)}>
                            <GrClone size={22} />
                        </button>
                    </Tooltip>
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={(e) => onDelete(e, contract)} disabled={!contract.deleteable}>
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const ContractsTable = ({ contracts }: { contracts: Contract[] }) => {
    const { filteredContracts, handleDateFilter, isFilterActive, clearFilters, handleStatusFilter, handleSearchFilter } =
        useContractsFilter(contracts);
    const {
        currentResutls: paginatedContracts,
        currentPage,
        maxPages,
        prev,
        next,
        setPage,
    } = usePagination<Contract>({ data: filteredContracts, itemsPerPage: 6 });
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const setDeleteContractModal = useModalState((s) => s.setDeleteCommonModal);
    const { deleteContract, cloneContract, getContractList } = useContractState();
    const toast = useCustomToast();

    async function onDeleteContract(contract: Contract) {
        const contractDeleted = await deleteContract(contract.id);
        if (contractDeleted) {
            toast.success("Contrato eliminado correctamente.");
            getContractList();
        } else {
            toast.error("No se puede eliminar el documento ya que esta enlazado a un proceso existente.");
        }
    }

    function handleDelete(e: MouseEvent, contract: Contract) {
        e.stopPropagation();
        setDeleteContractModal({
            show: true,
            data: { text1: "Se eliminará el contrato", text2: contract.name, onDelete: () => onDeleteContract(contract) },
        });
    }

    async function handleClone(e: MouseEvent, contract: Contract) {
        e.stopPropagation();
        const cloned = await cloneContract(contract);
        if (cloned) {
            toast.success(t("clonedContractSuccess"));
            getContractList();
        } else {
            toast.error(t("clonedContractError"));
        }
    }

    return (
        <>
            <div className="w-full flex justify-between items-center">
                <DottedSubtitle
                    text={t("myContracts")}
                    withSearch
                    onSearch={handleSearchFilter}
                    beforeSearchElement={
                        isFilterActive && (
                            <LinkButton onClick={clearFilters} color="red.400">
                                <IoClose size={20} /> {t("cleanFilters")}
                            </LinkButton>
                        )
                    }
                />
            </div>

            <div>
                <table className="w-full text-sm text-left text-dark-2">
                    <thead className="text-base text-brand-500 font-medium border-b">
                        <tr>
                            {/* <th scope="col" className="px-2">
                                <div className="flex items-center">
                                    <Checkbox />
                                </div>
                            </th> */}
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("name")} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("author")} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <DatePickerTh text={t("creation")} onChange={(args: any) => handleDateFilter(args, "createdDate")} />
                            </th>
                            {/* <th scope="col" className="px-2 py-4">
                                <SelectTh text="Cliente" />
                            </th> */}
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("type")} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("templateName")} onClick={() => {}} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <SelectTh
                                    text={t("status")}
                                    options={CONTRACT_STATUSES.map((s) => ({ label: t(s), value: s }))}
                                    onSelect={handleStatusFilter}
                                />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <DatePickerTh
                                    text={t("lastVersion")}
                                    onChange={(args: any) => handleDateFilter(args, "lastModifiedDate")}
                                />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                {t("actions")}
                            </th>
                        </tr>
                        <tr></tr>
                    </thead>
                    <tbody>
                        {paginatedContracts?.map((item) => (
                            <TableRow key={item.id} contract={item} onClone={handleClone} onDelete={handleDelete} />
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-between items-center">
                    {paginatedContracts?.length ? (
                        <div className="w-full py-6">
                            <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                        </div>
                    ) : (
                        <div className="w-full text-center p-2 text-dark">{t("notElementsFound")}</div>
                    )}
                </div>
            </div>
        </>
    );
};
export default ContractsTable;
