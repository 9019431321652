import { BiddingType } from "src/models/biddings/BiddingType.model";
import biddingTypeService from "src/services/biddings/biddingType.service";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import useAuthState from "../authState";
import useModalState from "../modalsState";
import useBiddingNotificationState from "./biddingNotificationsState";

interface State {
    biddingsTypes: { loading: boolean; error: boolean; data: BiddingType[] };
    biddingTypesByCompany: { loading: boolean; error: boolean; data: BiddingType[] };
    selectedBiddingType: BiddingType;
    setSelectedBiddingType: (biddingType: BiddingType) => void;
    getBiddingsTypes: (withLoading?: boolean) => any;
    getBiddingTypesByCompany: (withLoading?: boolean) => Promise<any>;
    dataGroupByTemplateIdArray: { loading: boolean; error: boolean; data: any };
    getAllDataGroupByTemplateIdArray: (data?: any[], withLoading?: boolean) => Promise<any>;
    getBiddingType: (biddingTypeId: string, withLoading?: boolean) => Promise<BiddingType | null>;
    createBiddingType: (biddingType: BiddingType, withLoading?: boolean) => Promise<boolean>;
    updateBiddingType: (biddingType: BiddingType, withLoading?: boolean) => Promise<boolean>;
    deleteBiddingType: (biddingTypeId: string, withLoading?: boolean) => Promise<boolean>;
    cloneBiddingType: (contract: BiddingType, withLoading?: boolean) => any;
    infoBiddingTypeById: { loading: boolean; error: boolean; data: any[] };
    templateIds: any;
    setArrayTemplatesId: (arrayTemplatesId: any) => void;
    reset: () => void;
}

const initialState = {
    biddingsTypes: { loading: false, error: false, data: [] },
    biddingTypesByCompany: { loading: false, error: false, data: [] },
    dataGroupByTemplateIdArray: { loading: false, error: false, data: [] },
    infoBiddingTypeById: { loading: false, error: false, data: [] },
    selectedBiddingType: null,
    setTemplatesId: null,
    templateIds: null,
};

const useBiddingTypesState = create<State>((set, get) => ({
    ...initialState,
    setSelectedBiddingType: (biddingType: BiddingType) => set({ selectedBiddingType: biddingType }),
    setArrayTemplatesId: (arrayTemplates: any) => set({ templateIds: arrayTemplates }),
    getAllDataGroupByTemplateIdArray: allDataGroupByTemplateIdArray(set, get),
    getBiddingsTypes: getBiddingTypes(set, get),
    getBiddingTypesByCompany: getBiddingTypesByCompany(set, get),
    getBiddingType: getBiddingType(set, get),
    createBiddingType: createBiddingType(set, get),
    updateBiddingType: updateBiddingType(set, get),
    deleteBiddingType: deleteBiddingType(set, get),
    cloneBiddingType: cloneBiddingType(set, get),
    reset: () => set((state) => ({ ...state, ...initialState })),
}));

export default useBiddingTypesState;

function hasPermissionForAction(reponse: any, msgPermission: string, redirect: boolean) {
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text2: msgPermission,
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                redirectHome: redirect,
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    }
}

//FUNCTIONS
function getBiddingTypes(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingTypeService.getBiddingTypes();

        hasPermissionForAction(res, "Listar tipos de procesos", true);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ biddingsTypes: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching biddings");
            set((state) => ({ biddingsTypes: { loading: false, error: true, data: state.biddingsTypes.data } }));
        }
    };
}

function getBiddingTypesByCompany(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingTypeService.getBiddingTypesByCompany(idCompany);

        hasPermissionForAction(res, "Listar tipos de procesos", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ biddingTypesByCompany: { loading: false, error: false, data: res.data.respuesta.reverse() } });
        } else {
            console.log("Error fetching biddings");
            set((state) => ({ biddingTypesByCompany: { loading: false, error: true, data: state.biddingTypesByCompany.data } }));
            throw new Error("Error fetching biddings");
        }
    };
}

function allDataGroupByTemplateIdArray(set: NamedSet<State>, get: GetState<State>) {
    return async (templatesArray: any, withLoading = true) => {
        // const templatesId = data;
        //set((state) => ({ dataGroupByTemplateIdArray: { loading: true, error: false, data: state.dataGroupByTemplateIdArray.data } }));

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingTypeService.allDataGroupByTemplateIdArray(templatesArray);

        hasPermissionForAction(res, "Obtener datos de expendiente", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res.data.codigo === 0) {
            set({ dataGroupByTemplateIdArray: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching biddings");
            set((state) => ({ dataGroupByTemplateIdArray: { loading: false, error: true, data: state.dataGroupByTemplateIdArray.data } }));
            throw new Error("Error fetching biddings");
        }
    };
}

function getBiddingType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingTypeId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingTypeService.getBiddingTypeById(biddingTypeId);

        hasPermissionForAction(res, "Obtener tipo de proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ infoBiddingTypeById: res.data.respuesta });

            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            set({ infoBiddingTypeById: null });
            return null;
        }
    };
}

function createBiddingType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingType: BiddingType, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingTypeService.createBiddingType(biddingType);

        hasPermissionForAction(res, "Crear tipo de proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating bidding");
            return false;
        }
    };
}

function updateBiddingType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingType: BiddingType, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const stages = biddingType.documentSet.stages.map((stage: any) => stage.name);
        const notificaciones = await useBiddingNotificationState.getState().getBiddingTypeNotificacionCompany(biddingType.id);

        const notificacionesMatch = notificaciones.filter((notificacion: any) => {
            // Filtra los topics dentro de topicList para eliminar aquellos cuyo componentId no coincida con stages
            notificacion.topicList = notificacion.topicList.filter((topic: any) => {
                const componentIds = topic.componentId.split('||');
                return componentIds.some((id: string) => stages.includes(id));
            });
        
            // Retorna true solo si topicList aún tiene elementos
            return notificacion.topicList.length > 0;
        });
        
        // Arreglo para notificaciones que no coinciden con stages
        const notificacionesNoMatch = notificaciones.filter((notificacion: any) => notificacion.topicList.length === 0);
        
        //Actualizar las que si hacen match
        notificacionesMatch.map(async (notificacionUpdate: any) => {
            await useBiddingNotificationState.getState().updateBiddingnotificacion(notificacionUpdate);
        });
        //Eliminar las que no hacen match
        notificacionesNoMatch.map(async (notificacionDelete: any) => {
            await useBiddingNotificationState.getState().deleteBiddingnotificacion(notificacionDelete.id);
        });

        const res = await biddingTypeService.updateBiddingType(biddingType);

        hasPermissionForAction(res, "Actualizar tipo de proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error updating bidding");
            return false;
        }
    };
}

function deleteBiddingType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingTypeId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingTypeService.deleteBiddingType(biddingTypeId);

        hasPermissionForAction(res, "Borrar tipo de proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error deleting bidding");
            return false;
        }
    };
}

function cloneBiddingType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingType: BiddingType, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const biddingTypeList = get().biddingTypesByCompany.data;

        let copyNumber = 1;
        while (biddingTypeList.find((c) => c.name === `${biddingType.name?.split("(copy")[0]?.trim()} (copy-${copyNumber})`)) {
            copyNumber++;
        }

        let biddingTypetName = `${biddingType.name?.split("(copy")[0]?.trim()} (copy-${copyNumber})`;

        biddingType.name = biddingTypetName;
        biddingType.id = ""; // quitar id

        const res = await biddingTypeService.createBiddingType(biddingType);

        hasPermissionForAction(res, "Clonar tipo de proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.mensaje?.includes("registro ya existe.")) {
            //TODO: revisar
            return { duplicated: true };
        } else if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error updating bidding");
            return false;
        }
    };
}
