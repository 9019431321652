import { useEffect, useState } from "react";
import { Traceability } from "../../../models/Contract.models";
import useContractState from "../../../store/contractState";
import { formatDate } from "../../../lib/utils";
import AccordionItemBrand from "../../common/AccordionItemBrand";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { Tooltip } from '@chakra-ui/react'

const TraceabilityAccordionItems = ({ contractId, isPDF }: { contractId: string ,isPDF}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const getTraceability = useContractState((s) => s.getTraceability);
    const getTraceabilityPDF = useContractState((s) => s.getTraceabilityPDF);

    const [traceability, setTraceability] = useState<Traceability[]>(null);

    useEffect(() => {
        if(isPDF){
            getTraceabilityPDF(contractId, false).then((data) => {
                if (data?.codigo === 0) {
                    setTraceability(data.respuesta);
                }
            });

        } else{

            getTraceability(contractId, false).then((data) => {
                if (data?.codigo === 0) {
                    setTraceability(data.respuesta);
                }
            });

        }
    }, []);

    return (
        <>
            <AccordionItemBrand title={t("traceability")}>
                <ul>
                    {traceability?.map((trace, idx) => (
                        <TraceItem key={trace.id} trace={trace} withLine={idx !== 0} t={t} />
                    ))}
                </ul>
            </AccordionItemBrand>
        </>
    );
};

export default TraceabilityAccordionItems;

const TraceItem = ({ trace, t, withLine = true }: { trace: Traceability; withLine?: boolean, t: any }) => {
    return (
        <li>
            {withLine && <div className="w-24 h-8 border-l-2 ml-6 my-1"></div>}
            <div className="flex items-center">
                <Tooltip label={trace.generalComment}>
                    <img alt="icon" src={getIcon(trace.nameState)} height={50} width={50} />
                </Tooltip>
                <div className="ml-2">
                    <p className="leading-5 text-sm">
                        <b>{trace.user}</b> {t("changedStateTo")}{" "}
                        <span className="font-semibold italic text-gray-600">{trace.nameState === "SI" ? " en boveda" : trace.nameState.split("ion ").join("ión ")}</span>
                    </p>
                    <p className="leading-none italic text-brand-500 font-light text-xs mt-1">{formatDate(new Date(trace.date), true)}</p>
                </div>
            </div>
        </li>
    );
};

function getIcon(nameState: string) {
    let icon;
    try {
        icon = require(`../../../assets/traceability-icons/${nameState?.toLowerCase().split(" ").join("_")}.svg`);
    } catch (error) {
        icon = require("../../../assets/traceability-icons/en_boveda.svg");
    }

    return icon;
}
