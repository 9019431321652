import { FormControl, Input, InputGroup, Select } from "@chakra-ui/react";
import { Formik } from "formik";
import { debounce, throttle } from 'lodash';
import { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { DataGroup, DataGroupType } from "../../models/DataGroup.models";
import { replaceAll } from "../../lib/utils";
import { Contract } from "../../models/Contract.models";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import escapeRegExp from "lodash/escapeRegExp";
import { parse, isValid, format } from 'date-fns';
import es from 'date-fns/locale/es';
import useContractState from "src/store/contractState";

type Props = {
    dataGroupIdx: number;
    dataGroup: Partial<DataGroup>;
    currentContract: Partial<Contract>;
    action: "create" | "edit";
    setPopulatedDataGroups: (prevState: any) => any;
    setPopulatedDataGroupsCopy: (prevState: any) => any;
    transformNumberWithPattern1?: any;
    transformDateWithPattern1?: any;
};

const DynamicForm = ({
    currentContract,
    dataGroupIdx,
    dataGroup,
    action,
    setPopulatedDataGroups,
    setPopulatedDataGroupsCopy,
    transformNumberWithPattern1,
    transformDateWithPattern1,
}: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [valuesCopy, setValuesCopy] = useState({});
    const {
    
        transformNumberWithPattern,
        transformDateWithPattern,
    } = useContractState();

    const lastValues = useRef({});

    //modifico temporalmente idNames para evitar conflicto con libreria formik
    const safedDataGroupTypes = useMemo(() => {
        const contractText = currentContract?.template?.text;
        return dataGroup.types.map((dt, rawIdx) => {
            const isUsed = contractText?.includes(`{{${dt.idName}}}`);
            return {
                ...dt,
                isUsed,
                rawIdx,
                idName: replaceAll(dt.idName, escapeRegExp("."), "___"),
                isPreloaded: Boolean(dt.value) && action === "create",
            };
        });
    }, [dataGroup.id]);

    const initialValues = useMemo(() => {
        let temp = {};
        safedDataGroupTypes?.forEach((dt) => {
            temp[dt.idName] = dt.value;
        });
        return temp;
    }, [dataGroup.id]);

    const valuesValidations: { idName: string; required: true; regexp: null }[] = useMemo(
        () => safedDataGroupTypes?.map((dt) => ({ idName: dt.idName, required: true, regexp: null })),
        [dataGroup.id]
    );



    const debouncedHandleDataInputCopy = useCallback(debounce(handleDataInputCopy, 500), []);
    function handleDataInputCopy(value: any, dataTypeIdx: number) {

        setPopulatedDataGroupsCopy((prevState) => {

            prevState[dataGroupIdx].types[dataTypeIdx].value = value;
            return [...prevState];
        });
    }


  

    const debouncedHandleDataInput23 = useCallback(debounce(handleDataInput, 500), []);
    function handleDataInput3(value: any, dataTypeIdx: number) {
        setPopulatedDataGroups((prevState) => {

            prevState[dataGroupIdx].types[dataTypeIdx].value = value;
          //  debouncedHandleDataInputCopy(value, dataTypeIdx);
            return [...prevState];
        });
    }

  /*  const useDebouncedCallback = (callback, delay) => {
        const debouncedFn = useRef(debounce(callback, delay)).current;
    
        // Cleanup on unmount
        useEffect(() => {
            return () => {
                debouncedFn.cancel();
            };
        }, [debouncedFn]);
    
        return debouncedFn;
    };
    const debouncedHandleDataInput = useDebouncedCallback((value, dataTypeIdx) => {
        setPopulatedDataGroups((prevState) => {

            prevState[dataGroupIdx].types[dataTypeIdx].value = value;
          //  debouncedHandleDataInputCopy(value, dataTypeIdx);
            return [...prevState];
        });
    }, 500);*/

    const debouncedHandleDataInput = useCallback(throttle(handleDataInput, 500), []);

    function handleDataInput(value, dataTypeIdx) {
        setPopulatedDataGroups((prevState) => {
            const updatedState = [...prevState];
            updatedState[dataGroupIdx].types[dataTypeIdx].value = value;
            return updatedState;
        });
    }
    
    
    function validateErrors(values) {
        const errors: any = {};

        valuesValidations.forEach((val) => {
            if (val.required && !values[val.idName]) {
                errors[val.idName] = "Required";
            }
            if (val.regexp && true /*test regexp*/) {
                errors[val.idName] = "Ivalid field"; //custom message
            }
        });

        return errors;
    }

    const usedDataGroupTypes = useMemo(() => {
        return safedDataGroupTypes?.filter((dt) => dt.isUsed);
    }, [dataGroup.id]);


    return (
        <Formik validateOnChange initialValues={initialValues} validate={validateErrors} onSubmit={() => {}}>
            {({ values, errors, touched, handleChange }) => {
                const handleBlur = async (dt) => {
                //    console.log("res handleBlur ",dt.value)
                //    console.log("values -", values[dt.idName])


                    const currentValue = values[dt.idName];
                    if (lastValues.current[dt.idName] === currentValue) { // evitar duplicidad de peticiones con el valor previo
                        return;
                    }
                    lastValues.current[dt.idName] = currentValue;

                    const valueFormat = await transformValue(values[dt.idName],dt.type); //quitar formatos 
                    if (dt.type === "number" && valueFormat !== null) {

                        const res = await transformNumberWithPattern(valueFormat, dt?.dataAtribute?.formatPattern);
                       
                        if (res !== undefined) {
                           // debouncedHandleDataInputCopy(res, dt.rawIdx); 
                            debouncedHandleDataInput(res, dt.rawIdx);
                        }
                        return;
                    }

                    if (dt.type === "date" && valueFormat !== null) {

                        const res = await transformDateWithPattern(valueFormat, dt?.dataAtribute?.formatPattern);

                        if (res !== undefined) {
                         //   debouncedHandleDataInputCopy(res, dt.rawIdx);
                            debouncedHandleDataInput(res, dt.rawIdx);
                        }
                        return;
                    }   
                };
            const handleChangeWithBlur = (event, dt) => {
                const value = event.target.value;
                if (value !== null) {
                    debouncedHandleDataInput(value, dt.rawIdx);
                   // debouncedHandleDataInputCopy(value, dt.rawIdx);
                }
            };
                return (
                    <form className="mt-2 space-y-2">
                        {usedDataGroupTypes?.length ? (
                            usedDataGroupTypes.map((dt, idx) => (
                                <FormControl key={dt.idName} isInvalid={!!errors[dt.idName] && !!touched[dt.idName]}>
                                    {getDynamicFormField(
                                        dt,
                                        values[dt.idName],
                                        handleChange,
                                       (e) => debouncedHandleDataInput(e.target.value, dt.rawIdx),
                                     //   (e) => handleChangeWithBlur(e, dt),
                                       (e) => handleBlur(dt),
                                        dt.isPreloaded ? "gray.200" : undefined
                                    )}
                                    {!!errors[dt.idName] && !!touched[dt.idName] && (
                                        <small className="text-red-500 text-xs">{t("requiredField")}</small>
                                    )}
                                </FormControl>
                            ))
                        ) : (
                            <div className="text-sm font-light italic">{t("noTypeToThisGroup")}</div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

export default DynamicForm;

const transformValue = (value, type) => {
  
    if (type === 'date') {
        if (value === null) {
            return value;
        }
        value = value.replace(/(?<=\b\w{3})\./g, '');

        const formats = [
            "yyyy-MM-dd",
            "dd/MMM/yyyy",
            "dd 'de' MMMM 'de' yyyy",
            "EEEE, d' de 'MMMM' de 'yyyy",
            "dd/MM/yyyy",
            "MM/dd/yyyy",
            "dd-MM-yyyy",
            "MMMM dd, yyyy",
            "dd/MM/yy",
            "dd-MM-YY",
            "dd-MM-yyyy",
            "dd/MM/yyyy",
            "dd/MM/yy",
        ];

        let date;
        for (let formatString of formats) {
            date = parse(value, formatString, new Date(), { locale: es });
            if (isValid(date)) {
                break;
            }
        }

        if (isValid(date)) {
            // Ensure the year is displayed correctly
            const year = date.getFullYear();
            if (year < 1000) {
                date.setFullYear(2000 + (year % 100)); // Handle two-digit years
            }
            return format(date, 'yyyy-MM-dd');
        }
        return ''; 

    } else if (type === 'number') {
        if (typeof value === 'number') {
            return value;
        }

        if (value === null) {
            return value;
        }

        const cleanedValue = value.replace(/[^0-9.-]+/g, '');
        const number = parseFloat(cleanedValue);
        return isNaN(number) ? '' : number;
    }
    return value;
};


function getDynamicFormField(
    dt: DataGroupType,
    value: any,
    onChange: (value: any) => any,
    onInput: (value: any) => any,
    onBlur: any,
    bg?: string
): JSX.Element {
    switch (dt.type) {
        case "string":
        default:
            return (
                <>
                    <label htmlFor={dt.idName} className="text-sm leading-8">
                        {dt.name}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            bg={bg || "white"}
                            onChange={onChange}
                            onInput={onInput}
                            onBlur={onBlur}
                            name={dt.idName}
                            id={dt.idName}
                            type="text"
                            rounded="none"
                            defaultValue={value}
                        />
                    </InputGroup>
                </>
            );
        case "number":
            return (
                <>
                    <label htmlFor={dt.idName} className="text-sm leading-8">
                        {dt.name}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                           onChange={onChange}
                            onInput={onInput}
                            onBlur={onBlur}
                            name={dt.idName}
                            id={dt.idName}
                            type="number"
                            rounded="none"
                           defaultValue={transformValue(value,"number")}
                         // defaultValue={value}
                        />
                    </InputGroup>
                </>
            );

        case "date":
            return (
                <>
                    <label htmlFor={dt.idName} className="text-sm leading-8">
                        {dt.name}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            onChange={onChange}
                            onInput={onInput}
                            onBlur={onBlur}
                            name={dt.idName}
                            id={dt.idName}
                            type="date"
                            rounded="none"
                           defaultValue={transformValue(value,"date")}
                          //defaultValue={value}

                        />
                    </InputGroup>
                </>
            );
        case "selectable":
            return (
                <>
                    <label htmlFor={dt.idName} className="text-sm leading-8">
                        {dt.name}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Select
                            defaultValue={value}
                            id={dt.idName}
                            name={dt.idName}
                            size="md"
                            rounded="none"
                            onChange={onChange}
                            onInput={onInput}
                        >
                            <option value={null}>seleccionar</option>
                            {dt?.valueList?.map((value, i) => (
                                <option key={i} value={value}>
                                    {value}
                                </option>
                            ))}
                        </Select>
                    </InputGroup>
                </>
            );
        // case "boolean":
        //     return (
        //         <>
        //             <label htmlFor={dt.idName} className="text-sm leading-8">
        //                 {dt.name}
        //             </label>
        //             <RadioGroup onChange={(value) => onChange(value)} defaultValue={"SI"}>
        //                 <div className="w-full flex gap-4 items-center py-2 px-4 border">
        //                     <Radio value="SI">Si</Radio>
        //                     <Radio value="NO">No</Radio>
        //                 </div>
        //             </RadioGroup>
        //         </>
        //     );
    }
}
