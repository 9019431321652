import { HolidayCalendar } from "src/models/administration/holiday-calendar.model";
import { CalendarSerivce } from "src/services/calendar.service";
import create, { StateCreator } from "zustand";

/** Services */
const calendarService = new CalendarSerivce()

/** Contrato del Store */
interface AdminCalendarState {
    holidaysDays: HolidayCalendar[]
    selectedHolidayDay?: HolidayCalendar
    selectHolidayDay: ( holidayDay: HolidayCalendar ) => void
    deselectHolidayDay: () => void
    retrieveHolidaysDays: ( companyId: string ) => Promise<boolean>
    retrieveHolidayDayByYear: ( companyId: string, year: number ) => Promise<HolidayCalendar>
    addHolidayDay: ( calendarId: string, dateValue: string ) => Promise<boolean>
    removeHolidayDay: ( calendarId: string, dateValue: string ) => Promise<boolean>
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    holidaysDays: [],
    selectedHolidayDay: undefined
}

/** Definición del Store */
const Store: StateCreator<AdminCalendarState> = ( set, get ) => ({

    ...initialState,

    selectHolidayDay: ( holidayDay: HolidayCalendar ) => set({ selectedHolidayDay: holidayDay }),

    deselectHolidayDay: () => set({ selectedHolidayDay: undefined }),

    retrieveHolidaysDays: ( companyId: string ) => {
        return new Promise(( resolve, reject ) => {
            calendarService.getAllYearsHolidaysDays( companyId ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const data: HolidayCalendar[] = response.respuesta

                        set({ holidaysDays: data })

                        resolve( true )
                    } else {
                        resolve( false )
                        get().reset()
                    }
                }
            ).catch( () => {
                get().reset()
                reject()
            })
        })
    },

    retrieveHolidayDayByYear: ( companyId: string, year: number ) => {
        return new Promise(( resolve, reject ) => {
            calendarService.getHolidaysDaysByYear( companyId, year ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const newHoliday: HolidayCalendar = response.respuesta
                        const updatedHolidaysDays = get().holidaysDays

                        updatedHolidaysDays.push( newHoliday )

                        set({ holidaysDays: updatedHolidaysDays })

                        resolve( newHoliday )
                    } else {
                        resolve( undefined )
                    }
                }
            ).catch( () => reject() )
        })
    },

    addHolidayDay: ( calendarId: string, dateValue: string ) => {
        return new Promise(( resolve, reject ) => {
            calendarService.addDateToCalendar( calendarId, dateValue ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const newHolidayDay: HolidayCalendar = response.respuesta

                        get().retrieveHolidaysDays( newHolidayDay.idCompany )

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    removeHolidayDay: ( calendarId: string, dateValue: string ) => {
        return new Promise(( resolve, reject ) => {
            calendarService.removeToCalendar( calendarId, dateValue ).then(
                response => {
                    if ( response.codigo === 0 ) {
                        const removedHolidayDay: HolidayCalendar = response.respuesta

                        get().retrieveHolidaysDays( removedHolidayDay.idCompany )

                        resolve( true )
                    } else {
                        resolve( false )
                    }
                }
            ).catch( () => reject() )
        })
    },

    reset: () => set( initialState )

})

/** Exportación del Store */
export const useAdminCalendarStore = create<AdminCalendarState>(
    Store
)