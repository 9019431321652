import { useCallback, useEffect, useRef, useState } from "react";
import Editor from "../../components/common/editor/Editor";
import { off, on } from "../../lib/customEvents";
import debounce from "lodash/debounce";

const TemplatesEditorPanel = ({
    text,
    onInput,
    insertEventName,
    enableEdition = true,
}: {
    text: string;
    onInput: (text: string) => any;
    insertEventName: string;
    enableEdition?: boolean;
}) => {
    const [initialContent] = useState(text);
    const instance = useRef<any>();

    const onSetEditorRef = useCallback((editorInstance) => {
        if (instance.current) return;
        instance.current = editorInstance;
        //set default content
        //instance.current.editor.html.set(text);
    }, []);

    const handleEditorChange = useCallback(
        debounce((content: string) => {
            Boolean(onInput) && onInput(content);
        }, 400),
        []
    );

    /**
     * inserta dato dinámico en el editor, es llamada por el evento "insertEventName" pasado como prop
     */
    function handleInsert({ detail: { name, idName } }: CustomEventInit) {
        const editor = instance.current?.editor || {};
        const inertHtml = `<span><span class="__se__comodin" contenteditable="false">{{${idName}}}</span></span>`;
        editor.html.insert(inertHtml);
    }

    useEffect(() => {
        on(insertEventName, handleInsert);
        return () => {
            off(insertEventName, handleInsert);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // function handleCustomInsert() {
    //     const html = prompt("Inserte el HTML a insertar");
    //     editor.current.core.focus();
    //     editor.current.insertHTML(html, true, false, true);
    // }

    return (
        <div className="w-full min-h-[450px]">
            <Editor
                onSetRef={onSetEditorRef}
                onChange={handleEditorChange}
                height={`calc(88vh - 240px)`}
                defaultValue={initialContent || ""}
                readOnly={!enableEdition}
            />
        </div>
    );
};

export default TemplatesEditorPanel;
