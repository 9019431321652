import { useState, useEffect } from "react";
import BpmnAdmin from "../components/administration/BpmnAdmin";
import UsersAdmin from "../components/administration/UsersAdmin";
import UserGroups from "../components/administration/UsersGroupsAdmin";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import MainLayout from "src/components/layouts/MainLayout";
import CompaniesAdmin from "src/components/administration/CompaniesAdmin";
import RolesAdmin from "src/components/administration/RolesAdmin";
import DottedSubtitle from "src/components/common/DottedSubtitle";
import BoxButton from "src/components/common/BoxButton";
import AdminUsersIcon from "src/components/icons/iconsV2/AdminUsersIcon";
import AdminBpmIcon from "src/components/icons/iconsV2/AdminBpmIcon";
import AdminCompaniesIcon from "src/components/icons/iconsV2/AdminCompaniesIcon";
import AdminRolesIcon from "src/components/icons/iconsV2/AdminRolesIcon";
import NewDataGroupIcon from "src/components/icons/iconsV2/NewDataGroupIcon";
//import { FaRegCalendarAlt } from "react-icons/fa";
import CalendarAdmin from "src/components/administration/CalendarAdmin";
import useComponetState from "src/store/components/componentState";
import ConfigAdmin from "../components/administration/ConfigAdmin";
import NewProcessIcon from "src/components/icons/iconsV2/NewProcessIcon";

type Tab = "users" | "bpmn" | "companies" | "roles" | "groupUsers" | "calendar" | "config";

const AdministrationPage = () => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [selectedTab, setSelectedTab] = useState<Tab>("users");
    const uiTabsOptions = useComponetState((state) => state.uiBtnAdminOptions);
    const getAllBtnAdmin = useComponetState((s) => s.getAllBtnAdmin);

    useEffect(() => {
        getAllBtnAdmin();
    }, []);

    return (
        <MainLayout>
            <main className="w-full p-6">
                <h2 className="title-3">{t("administration")}</h2>
                <DottedSubtitle text="Administra entidades del sistema" />

                <div className="flex space-x-6 mt-4">
                    {!uiTabsOptions.showBtnUsers ? (
                        ""
                    ) : (
                        <BoxButton
                            icon={<AdminUsersIcon boxSize={45} />}
                            text={t("users")}
                            onClick={() => setSelectedTab("users")}
                            selected={selectedTab === "users"}
                            size="medium"
                        />
                    )}

                    {!uiTabsOptions.showBtnGroupUsers ? (
                        ""
                    ) : (
                        <BoxButton
                            icon={<NewDataGroupIcon boxSize={45} />}
                            text={t("generateUserGroups")}
                            onClick={() => setSelectedTab("groupUsers")}
                            selected={selectedTab === "groupUsers"}
                            size="medium"
                        />
                    )}

                    {!uiTabsOptions.showBtnCompany ? (
                        ""
                    ) : (
                        <BoxButton
                            icon={<AdminCompaniesIcon boxSize={45} />}
                            text={t("companies")}
                            onClick={() => setSelectedTab("companies")}
                            selected={selectedTab === "companies"}
                            size="medium"
                        />
                    )}

                  {/**<BoxButton
                        icon={<FaRegCalendarAlt size={40} color="#2e54b8" />}
                        text="Calendario"
                        onClick={() => setSelectedTab("calendar")}
                        selected={selectedTab === "calendar"}
                        size="medium"
                    /> */}  

                    {!uiTabsOptions.showBtnRols ? (
                        ""
                    ) : (
                        <BoxButton
                            icon={<AdminRolesIcon boxSize={45} />}
                            text={t("rolesAndPermissions")}
                            onClick={() => setSelectedTab("roles")}
                            selected={selectedTab === "roles"}
                            size="medium"
                        />
                    )}

                    {!uiTabsOptions.showBtnBpmn ? (
                        ""
                    ) : (
                        <BoxButton
                            icon={<AdminBpmIcon boxSize={45} />}
                            text={"BPMN"}
                            onClick={() => setSelectedTab("bpmn")}
                            selected={selectedTab === "bpmn"}
                            size="medium"
                        />
                    )}
                    {/* Retirar linea cuando se resuelva el endpoint de acceso configuracion */}
                    {!uiTabsOptions.showBtnConfig ? (
                    // {!uiTabsOptions.showBtnConfig ? (
                        ""
                    ) : (
                        <BoxButton
                            // icon={<AdminBpmIcon boxSize={45} />}
                            icon={<NewProcessIcon boxSize={45} />}
                            text={t("configuration")}
                            onClick={() => setSelectedTab("config")}
                            selected={selectedTab === "config"}
                            size="medium"
                        />
                    )}
                </div>
                <>
                    {selectedTab === "users" && <UsersAdmin />}
                    {selectedTab === "groupUsers" && <UserGroups />}
                    {selectedTab === "companies" && <CompaniesAdmin />}
                    {selectedTab === "calendar" && <CalendarAdmin />}
                    {selectedTab === "roles" && <RolesAdmin />}
                    {selectedTab === "bpmn" && <BpmnAdmin />}
                    {selectedTab === "config" && <ConfigAdmin />}
                </>
            </main>
        </MainLayout>
    );
};

export default AdministrationPage;
