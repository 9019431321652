import React from 'react';
import { Box, Collapse, Button, VStack } from '@chakra-ui/react';

interface CollapseItemProps {
  id: string;
  title: string;
  actions?: React.ReactNode;
  isOpen: boolean;
  onToggle: (id: string) => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop?: (e: React.DragEvent<HTMLDivElement>, targetId: string) => void;
  children?: React.ReactNode;
  disabled?: boolean;
  draggable?: boolean;
}

const CollapseItem: React.FC<CollapseItemProps> = ({
  id,
  title,
  actions,
  isOpen,
  onToggle,
  onDragStart,
  onDragOver,
  onDrop,
  children,
  disabled,
  draggable = true
}) => {
  return (
    <Box
      borderWidth="1px"
      borderRadius="md"
      p="2"
      pl='4'
      mb="2"
      bg="gray.50"
      draggable={draggable}
      onDragStart={(e) => onDragStart && onDragStart(e, id)}
      onDragOver={onDragOver}
      onDrop={(e) => onDrop && onDrop(e, id)}
    >
      <Button
        onClick={() => onToggle(id)}
        width="100%"
        textAlign="left"
        variant="outline"
        display='flex'
        justifyContent='space-between'
        disabled={disabled}
      >
        {title}
        <div className="order-[3] justify-self-end">{actions}</div>
      </Button>
      <Collapse in={isOpen}>
        <Box mt="2">
          <VStack spacing="2" align="stretch" mt="4">
            {children}
          </VStack>
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapseItem;
