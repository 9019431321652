import React, { useState ,useEffect } from "react";
import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import {
    Checkbox,
    FormErrorMessage,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    useBoolean,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCustomToast from "../../hooks/useCustomToast";
import CommonModal from "../common/CommonModal";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useBiddingValidationState from "src/store/biddings/biddingValidationTypesState";
import useBiddingDocumentTypeState from "src/store/biddings/biddingDocumentTypesState";
import useTemplateState from "../../store/templatesState";
import RadioList from "./RadioList";
import AssociateRequirementsList from "./AssociateRequirementsList";
import DynamicFormModal from "./DynamicFormModal";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
    onSave: (...args: any) => any;
    groupName?: string;
    stageName?: string;
    currentRequerimients?: any;
};

interface FormFields {
    name: string;
    fileLabel: string;
    biddingValidationTypeId: string;
    documentTypeId: string;
    group: string;
    oldName: string;
    templateId: string;
}

const NewBiddingModal = ({ isOpen, onClose, groupName, onSave, data , currentRequerimients,stageName}: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const {
        getBiddingValidationTypes,
        biddingValidationTypes: { data: biddingValidationTypes },
    } = useBiddingValidationState();

    const {
        getBiddingDocumentTypes,
        biddingsDocumentTypes: { data: biddingsDocumentTypes },
    } = useBiddingDocumentTypeState();

    const {
        templates: { data: templates },
        getTemplates,
    } = useTemplateState();

    const [showModalDynamicForm, setShowModalDynamicForm] = useBoolean(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [isFormValid, setIsFormValid] = useState(true);
    const [isAlwaysNew, setIsAlwaysNew] = useState(data.alwaysNew);
    const [isRevisionFlow, setIsRevisionFlow] = useState(false);
    const [docsAllowed, setDocsAllowed] = useState({
        minDocs: 1,
        maxDocs: 1,
    });
    const [associateRequeriments, setAssociateRequeriments] =useState([]);

    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        console.log(" data.alwaysNew",data.alwaysNew)

        setDocsAllowed({
            minDocs: data.minUploadable || 1,
            maxDocs: data.maxUploadable || 1,
        });

       
    }, [data]);

    const handleChangeAssociateRequeriments = (value) => {
        const concatenatedData = [groupName, stageName, ...value].join("||");
        //const concatenatedData = [value].join("||");
        const finalArray = [concatenatedData];
        setAssociateRequeriments(finalArray);
        setTabIndex(0);
        toast.success("Requerimientos asociados");
    };
   // console.log("tabIndex",associateRequeriments)

    const handleChangeMinDocs = (value) => {
        const newMaxDocs = Math.max(value, docsAllowed.maxDocs);

        setDocsAllowed({
            minDocs: value,
            maxDocs: newMaxDocs,
        });
    };

    const handleChangeMaxDocs = (value) => {
        const newMinDocs = Math.min(value, docsAllowed.minDocs);

        setDocsAllowed({
            minDocs: newMinDocs,
            maxDocs: value,
        });
    };

    const handleChangeOptionalDocs = (e) => {
        setDocsAllowed({
            ...docsAllowed,
            minDocs: e.target.checked && -1,
        });
    };

    const handleChangeUnlimitedDocs = (e) => {
        setDocsAllowed({
            ...docsAllowed,
            maxDocs: e.target.checked && -1,
        });
    };

    const handleChangeisAlwaysNew = (e) => {
        setIsAlwaysNew(e.target.checked);
    };


    const handleChangeisRevisionFlow= (e) => {
        setIsRevisionFlow(e.target.checked);
    };

    const updateFormValidity = () => {
        setIsFormValid(selectedOptions?.length > 0);
    };

    const typeRequirementId = (arrayIds: string[]) => {
        setSelectedOptions(arrayIds);
        updateFormValidity();
    };

    const formik = useFormik<FormFields>({
        initialValues: {
            name: data.name,
            fileLabel: data.fileLabel,
            biddingValidationTypeId: data.biddingValidationTypeId,
            documentTypeId: data.documentTypeId,
            group: "",
            oldName: "",
            templateId: data.templateId,
        },
        onSubmit: handleCreate,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
            fileLabel: Yup.string().required(t("requiredField")),
            biddingValidationTypeId: Yup.string().required(t("requiredField")),
            documentTypeId: Yup.array()
                .of(Yup.string().required(t("requiredField")))
                .min(1, t("requiredField")),
            templateId: Yup.string().when([], {
                is: () => selectedOptions !== null && selectedOptions !== undefined && selectedOptions.includes("63c90b4aea18686b30cf3848"),
                then: Yup.string().required(t("requiredField")).nullable(),
                otherwise: Yup.string().notRequired().nullable(),
            }),
        }),
    });

    async function handleCreate(values: FormFields) {
        const newData: any = { ...values };
        newData.group = groupName;
        newData.documentTypeId = selectedOptions;
        newData.oldName = data.name;
        newData.maxUploadable = docsAllowed.maxDocs;
        newData.minUploadable = docsAllowed.minDocs;
        newData.alwaysNew = isAlwaysNew;
        newData.reviewStatus = isRevisionFlow;
        newData.requerimentIdList = associateRequeriments;
        onSave(newData);

    }



   
    useEffect(() => {
    {/**    getBiddingValidationTypes().catch((err) => {
            toast.error(t("error"));
        });

        getBiddingDocumentTypes().catch((err) => {
            toast.error(t("error"));
        });

        getTemplates().catch((err) => {
            toast.error(t("error"));
        }); */}
    }, []);

useEffect(() => {
    // Verificar el valor de reviewStatus y signStatus
    data.reviewStatus === "SI" ? setIsRevisionFlow(true) : setIsRevisionFlow(false); 
    data.signStatus === "SI" ? setIsAlwaysNew(true) : setIsAlwaysNew(false);
}, [data.reviewStatus, data.signStatus]);




    const handleOpenDynamicFormModal = () => {
        setShowModalDynamicForm.on();
    };

    const closeDynamicFormModal = () => {
        setShowModalDynamicForm.off();
    };

console.log("añwasy new",isAlwaysNew)
    return (
        <CommonModal size="3xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">{t("requirements")}</h2>
                <Tabs isFitted variant='enclosed' onChange={(index) => setTabIndex(index)} defaultIndex={tabIndex}>
                    <TabList mb='1em'>
                        {/**                         <Tab>Configurar requerimiento</Tab>
*/}
                        {/**                        <Tab>Asociar requerimientos</Tab>
 */}
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                        <form
                    className="w-full flex flex-wrap justify-between items-center gap-2 max-h-[75vh] overflow-y-auto px-2"
                    onSubmit={formik.handleSubmit}
                >
                    <FormControl isInvalid={!!formik.errors.name}>
                        <label htmlFor="name" className="text-sm leading-8">
                            {t("name")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.fileLabel}>
                        <label htmlFor="fileLabel" className="text-sm leading-8">
                            {t("documentName")}
                            <small className="italic">(nombre de base para el archivo a subir)</small>
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="fileLabel"
                                name="fileLabel"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.fileLabel}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.fileLabel}</FormErrorMessage>
                    </FormControl>

                    <RadioList
                        onSaveTypeRequirementId={typeRequirementId}
                        biddingDocumentTypes={biddingsDocumentTypes}
                        typeOptions={formik.values.documentTypeId}
                    />
                    {selectedOptions?.length === 0 && (
                        <p className="text-teal-500 text-sm leading-7">Debes seleccionar al menos un tipo de requerimiento.</p>
                    )}

                    <div className="flex w-full gap-4">
                        <FormControl isInvalid={!!formik.errors.name} className="!w-1/2">
                            <label htmlFor="name" className="text-sm leading-8">
                                {t("minDocumentsRequired")}
                            </label>
                            <InputGroup borderColor="gray.400">
                                <NumberInput
                                    id="minDocs"
                                    name="minDocs"
                                    defaultValue={1}
                                    min={1}
                                    max={100}
                                    size="sm"
                                    value={docsAllowed.minDocs}
                                    onChange={handleChangeMinDocs}
                                    className="w-full"
                                    isDisabled={docsAllowed?.minDocs === -1 || docsAllowed?.maxDocs === -1}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </InputGroup>
                            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!formik.errors.name} className="!w-1/2">
                            <label htmlFor="name" className="text-sm leading-8">
                                {t("maxDocumentsRequired")}
                            </label>
                            <InputGroup borderColor="gray.400">
                                <NumberInput
                                    id="maxDocs"
                                    name="maxDocs"
                                    defaultValue={1}
                                    min={docsAllowed.minDocs || 1}
                                    max={100}
                                    size="sm"
                                    value={docsAllowed.maxDocs}
                                    onChange={handleChangeMaxDocs}
                                    className="w-full"
                                    isDisabled={docsAllowed?.minDocs === -1 || docsAllowed?.maxDocs === -1}
                                >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                    </NumberInputStepper>
                                </NumberInput>
                            </InputGroup>
                            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                        </FormControl>
                    </div>

                    <div className="w-full">
                        <FormControl isInvalid={!!formik.errors.name} className="!w-full flex">
                            <label htmlFor="name" className="text-sm leading-8 w-3/4">
                                {t("optionalDocs")}
                            </label>
                            <InputGroup borderColor="gray.400">
                                <Checkbox
                                    rounded="10"
                                    id="optionalDocs"
                                    name="optionalDocs"
                                    isChecked={docsAllowed.minDocs === -1}
                                    onChange={handleChangeOptionalDocs}
                                    size="md"
                                />
                            </InputGroup>
                            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                        </FormControl>

                        <FormControl isInvalid={!!formik.errors.name} className="!w-full flex ">
                            <label htmlFor="name" className="text-sm leading-8 w-3/4">
                                {t("unlimitedDocs")}
                            </label>
                            <InputGroup borderColor="gray.400">
                                <Checkbox
                                    rounded="10"
                                    id="unlimitedDocs"
                                    name="unlimitedDocs"
                                    isChecked={docsAllowed.maxDocs === -1}
                                    onChange={handleChangeUnlimitedDocs}
                                    size="md"
                                />
                            </InputGroup>
                            <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                        </FormControl>  

                    {selectedOptions !== null && selectedOptions !== undefined && selectedOptions.includes("63c90b4aea18686b30cf3848") ?    
                        <FormControl className="!w-full flex ">
                            <label htmlFor="name" className="text-sm leading-8 w-3/4">
                                {t("alwaysNew")}
                            </label>
                            <InputGroup borderColor="gray.400">
                                <Checkbox
                                    rounded="10"
                                    id="alwaysNew"
                                    name="alwaysNew"
                                    isChecked={isAlwaysNew}
                                    onChange={handleChangeisAlwaysNew}
                                    size="md"
                                />
                            </InputGroup>
                        </FormControl> : ""
                        }
                   
                   {selectedOptions !== null && selectedOptions !== undefined && selectedOptions.includes("63c90b4aea18686b30cf3848") ? "" :
                        <FormControl className="!w-full flex ">
                            <label htmlFor="name" className="text-sm leading-8 w-3/4">
                                {"Habilitar flujo de revisión"}
                            </label>
                            <InputGroup borderColor="gray.400">
                                <Checkbox
                                    rounded="10"
                                    id="isRevisionFlow"
                                    name="isRevisionFlow"
                                    isChecked={isRevisionFlow}
                                    onChange={handleChangeisRevisionFlow}
                                    size="md"
                                />
                            </InputGroup>
                        </FormControl> 
                    }
                    </div>
                 
                    {/* {selectedOptions !== null && selectedOptions !== undefined && selectedOptions.includes("63c90b4aea18686b30cf3848") ? (
                        <div className="mt-2 w-full text-center">
                            <Button
                                type="button"
                                rounded="full"
                                size="md"
                                fontWeight="medium"
                                px={"2rem"}
                                isLoading={false}
                                onClick={handleOpenDynamicFormModal}
                            >
                                Generar Formulario
                            </Button>
                        </div>
                    ) : (
                        ""
                    )} */}

                    {/* {showModalDynamicForm && (
                        <DynamicFormModal
                            isOpen={true}
                            onClose={closeDynamicFormModal}
                            // onSave={handleAddOrEditRequirement}
                            // groupName={tempGroupName}
                            // data={tempRequirement}
                        />
                    )} */}

                    {selectedOptions !== null && selectedOptions !== undefined && selectedOptions.includes("63c90b4aea18686b30cf3848") ? (
                        <FormControl isInvalid={!!formik.errors.templateId}>
                            <label htmlFor="templateId" className="text-sm leading-8">
                                {t("templateType")}
                            </label>
                            <Select
                                id="templateId"
                                size="md"
                                rounded="none"
                                onChange={formik.handleChange}
                                defaultValue={formik.values.templateId}
                            >
                                <option value="">{t("select")}</option>
                                {templates.map((temp) => (
                                    <option key={temp.id} value={temp.id}>
                                        {temp.name}
                                    </option>
                                ))}
                            </Select>
                            <FormErrorMessage>{formik.errors.templateId}</FormErrorMessage>
                        </FormControl>
                    ) : (
                        ""
                    )}

                    <FormControl isInvalid={!!formik.errors.biddingValidationTypeId}>
                        <label htmlFor="biddingValidationTypeId" className="text-sm leading-8">
                            {t("validationType")}
                        </label>
                        <Select
                            id="biddingValidationTypeId"
                            size="md"
                            rounded="none"
                            onChange={formik.handleChange}
                            defaultValue={formik.values.biddingValidationTypeId}
                        >
                            <option value="">{t("select")}</option>
                            {biddingValidationTypes.map((biddingType) => (
                                <option key={biddingType.id} value={biddingType.id}>
                                    {biddingType.name}
                                </option>
                            ))}
                        </Select>
                        <FormErrorMessage>{formik.errors.biddingValidationTypeId}</FormErrorMessage>
                    </FormControl>
                    <div className="mt-8 pb-2 w-full text-center">
                        <Button
                            type="submit"
                            rounded="full"
                            size="md"
                            fontWeight="medium"
                            px={"2rem"}
                            isLoading={false}
                            isDisabled={!isFormValid}
                        >
                            {t("save")}
                        </Button>
                    </div>
                </form>                        
                </TabPanel>
                        <TabPanel>
                            <div className="w-full flex max-h-[75vh]">
                            <AssociateRequirementsList data={currentRequerimients} previouslySelected ={["Iniciativas de coordenadas nominales","Diseño final"]} handleChangeAssociateRequeriments={handleChangeAssociateRequeriments} />
                            </div>
                        </TabPanel>
                    </TabPanels>
                    </Tabs>
               
            </div>
        </CommonModal>
    );
};

export default NewBiddingModal;
