import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { AssociatedCompany, CompanyLite, CreateUserBody, PolicyData, User, UserGroup } from "../models/Aministration.models";
import administrationService from "../services/administration.service";
import useAuthState from "./authState";
import useModalState from "./modalsState";
import userRoleService from "src/services/roles-permissions/user-role.service";
import { id } from "date-fns/locale";

interface State {
    companyUsers: { loading: boolean; error: boolean; data: User[] };
    allCompanies: { loading: boolean; error: boolean; data: CompanyLite[] };
    associatedCompanies: { loading: boolean; error: boolean; data: AssociatedCompany[] };
    companyUsersGroups: { loading: boolean; error: boolean; data: UserGroup[] };
    annexeCompanyUserGroup: { loading: boolean; error: boolean; data: any };
    annexeCompanyUser: { loading: boolean; error: boolean; data: any };
    annexeCompanyList: { loading: boolean; error: boolean; data: any };
    getCompanyUsers: (withLoading?: boolean) => Promise<User[]>;
    createUser: (body: CreateUserBody, withLoading?: boolean) => Promise<User>;
    createExternalUser: (body: CreateUserBody, withLoading?: boolean) => Promise<User>;
    deleteUser: (userId: string, withLoading?: boolean) => Promise<boolean>;
    updateUser: (body: User, withLoading?: boolean) => Promise<User>;
    getAllCompanies: (withLoading?: boolean) => Promise<CompanyLite[]>;
    getAssociatedCompanies: (whitLoading?: boolean) => Promise<any>;
    associateCompany: (companyId: string, withLoading?: boolean) => Promise<any>;
    createCompany: (body: any, withLoading?: boolean) => Promise<any>;
    deleteCompanyAssociation: (companyAssociationId: string, withLoading?: boolean) => Promise<any>;
    getCompanyUserGroups: (withLoading?: boolean) => Promise<UserGroup[]>;
    createUserGroup: (body: UserGroup, withLoading?: boolean) => Promise<UserGroup[]>;
    updateUserGroup: (body: UserGroup, withLoading?: boolean) => Promise<UserGroup[]>;
    deleteUserGroup: (userGroupId: string, withLoading?: boolean) => Promise<UserGroup[]>;
    getCompanyUserGroupAnnexe: (userId: string, withLoading?: boolean) => Promise<any>;
    createCompanyUserGroupAnnexe: (body: any, withLoading?: boolean) => Promise<UserGroup[]>;
    updateCompanyUserGroupAnnexe: (body: any, withLoading?: boolean) => Promise<UserGroup[]>;
    getCompanyUsersGroupAnnexeByList: (usersList: any, withLoading?: boolean) => Promise<UserGroup[]>;
    getPoliciesByCompanyList: ( withLoading?: boolean ) => Promise<any>;
    getPoliciesByUserList: ( idUser: string, withLoading?: boolean ) => Promise<any>;
    updatePoliciesList: ( body: PolicyData, withLoading?: boolean ) => Promise<any>;
    getNotApplicableUsers: ( withLoading?: boolean ) => Promise<any>;
    saveNotApplicableUsers: ( body: string[], withLoading?: boolean ) => Promise<any>;
    validateOtpCode: ( id: string, code: string, withLoading?: boolean ) => Promise<any>;
    reset: () => void;
}

const initialState = {
    companyUsers: { loading: false, error: false, data: null },
    companyUsersGroups: { loading: false, error: false, data: null },
    allCompanies: { loading: false, error: false, data: null },
    associatedCompanies: { loading: false, error: false, data: null },
    annexeCompanyUserGroup: { loading: false, error: false, data: null },
    annexeCompanyUser: { loading: false, error: false, data: null },
    annexeCompanyList: { loading: false, error: false, data: null },
};

const useAdministrationState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            getCompanyUsers: getCompanyUsers(set, get),
            createUser: createUser(set, get),
            createExternalUser: createExternalUser(set, get),
            deleteUser: deleteUser(set, get),
            updateUser: updateUser(set, get),
            getAllCompanies: getAllCompanies(set, get),
            getAssociatedCompanies: getAssociatedCompanies(set, get),
            associateCompany: associateCompany(set, get),
            createCompany: createCompany(set, get),
            deleteCompanyAssociation: deleteCompanyAssociation(set, get),
            getCompanyUserGroups: getCompanyUserGroups(set, get),
            createUserGroup: createUserGroup(set, get),
            updateUserGroup: updateUserGroup(set, get),
            deleteUserGroup: deleteUserGroup(set, get),
            getCompanyUserGroupAnnexe: getCompanyUserGroupAnnexe(set, get),
            createCompanyUserGroupAnnexe: createCompanyUserGroupAnnexe(set, get),
            updateCompanyUserGroupAnnexe: updateCompanyUserGroupAnnexe(set, get),
            getCompanyUsersGroupAnnexeByList: getCompanyUsersGroupAnnexeByList(set, get),
            getPoliciesByCompanyList: getPoliciesByCompanyList( set, get ),
            getPoliciesByUserList: getPoliciesByUserList( set, get ),
            updatePoliciesList: updatePoliciesList( set, get ),
            getNotApplicableUsers: getNotApplicableUsers( set, get ),
            saveNotApplicableUsers: saveNotApplicableUsers( set, get ),
            validateOtpCode: validateOtpCode( set, get ),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "AdministrationState" }
    )
);
export default useAdministrationState;

//FUNCTIONS

const msgPermission = "No cuenta con el permiso para esta acción.";
function hasPermissionForAction(reponse: any, msgPermission: string, redirect: boolean) {
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text2: msgPermission,
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                redirectHome: redirect,
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    }
}

function getCompanyUsers(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        set((state) => ({ companyUsers: { loading: true, error: false, data: state.companyUsers.data } }));

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.getCompanyUsers(idCompany);

        hasPermissionForAction(res, "Listar compañias", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ companyUsers: { loading: false, error: false, data: res.data.respuesta } });
            return res.data;
        } else {
            console.log("Error fetching company users");
            set((state) => ({ companyUsers: { loading: false, error: true, data: state.companyUsers.data } }));
            //clean error
            set((state) => ({ companyUsers: { ...state.companyUsers, error: false } }));
            return null;
        }
    };
}

function createUser(set: NamedSet<State>, get: GetState<State>) {
    return async (body: CreateUserBody, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        body.companies = [idCompany];

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.createUser(body);

        hasPermissionForAction(res, "Crear usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.data?.id) {
            let idUser = res?.data?.data?.id;

            const userRol = {
                companyId: idCompany,
                rolList: body.roles,
                userId: idUser,
            };

            const rolUser = await userRoleService.createUserRols(userRol);

            if (rolUser.data) {
                return res.data.data;
            }
        } else {
            if (res?.response?.data?.message?.includes(`ya se encuentra asociado a otro usuario`)) {
                useModalState.getState().setSimpleAlertModal({
                    show: true,
                    data: {
                        text1: "emailAlreadyRegistered",
                        text2: "inputAnotherEmail",
                        whitTranslation: true,
                    },
                });
                return null;
            } else {
                console.log("Error creating user");
                return null;
            }
        }
    };
}

function createExternalUser(set: NamedSet<State>, get: GetState<State>) {
    return async (body: CreateUserBody, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        body.companies = [idCompany];

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.createExternalUser(body);

        hasPermissionForAction(res, "Crear usuario externo", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.data?.id) {
            return res.data.data;
        } else {
            if (res?.response?.data?.message?.includes(`ya se encuentra asociado a otro usuario`)) {
                useModalState.getState().setSimpleAlertModal({
                    show: true,
                    data: {
                        text1: "emailAlreadyRegistered",
                        text2: "inputAnotherEmail",
                        whitTranslation: true,
                    },
                });
                return null;
            } else {
                console.log("Error creating user");
                return null;
            }
        }
    };
}

function deleteUser(set: NamedSet<State>, get: GetState<State>) {
    return async (userId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.deleteUser(userId);

        hasPermissionForAction(res, "Eliminar usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.id) {
            return res.data;
        } else {
            console.log("Error deleting user");
            return null;
        }
    };
}

function updateUser(set: NamedSet<State>, get: GetState<State>) {
    return async (body: User, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.updateUser(body);

        hasPermissionForAction(res, "Actualizar usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.body?.status === "ok") {
            return res.data.body?.data;
        } else {
            console.log("Error updating user");
            return null;
        }
    };
}

function getAllCompanies(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        set((state) => ({ allCompanies: { loading: true, error: false, data: state.allCompanies.data } }));

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.getCompanies(0, 300);

        hasPermissionForAction(res, "Listar compañias", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data) {
            set({ allCompanies: { loading: false, error: false, data: res.data?.content } });
            return res.data;
        } else {
            console.log("Error fetching all companies");
            set((state) => ({ allCompanies: { loading: false, error: true, data: state.allCompanies.data } }));
            //clean error
            set((state) => ({ allCompanies: { ...state.allCompanies, error: false } }));
            return null;
        }
    };
}

function getAssociatedCompanies(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        set((state) => ({ associatedCompanies: { loading: true, error: false, data: state.associatedCompanies.data } }));

        const companyId = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.getAssociatedCompanies(companyId);

        hasPermissionForAction(res, "Listar compañias asociadas", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ associatedCompanies: { loading: false, error: false, data: res?.data?.respuesta } });
            return res?.data?.respuesta;
        } else {
            console.log("Error fetching associated companies");
            set((state) => ({ associatedCompanies: { loading: false, error: true, data: state.associatedCompanies.data } }));
            //clean error
            set((state) => ({ associatedCompanies: { ...state.associatedCompanies, error: false } }));
            return null;
        }
    };
}

function associateCompany(set: NamedSet<State>, get: GetState<State>) {
    return async (foreignCompanyId: string, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        const body = {
            associatedApp: "SCM",
            ownCompanyId: idCompany,
            foreignCompanyId,
        };

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.associateCompany(body);

        hasPermissionForAction(res, "Listar compañia asociada", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return res.data;
        } else {
            console.log("Error associating company");
            return null;
        }
    };
}

function createCompany(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.createAndAssociateCompany(body);

        hasPermissionForAction(res, "Crear compañia", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.id) {
            return true;
        } else {
            console.log("Error creating company");
            if (res.response?.data?.message?.includes("ya existe")) {
                useModalState.getState().setSimpleAlertModal({
                    show: true,
                    data: {
                        text1: "Error al crear la compañía",
                        text2: "Ya existe una compañía con el mismo RFC",
                        whitTranslation: true,
                    },
                });
            }
            return null;
        }
    };
}

function deleteCompanyAssociation(set: NamedSet<State>, get: GetState<State>) {
    return async (companyId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.deleteCompanyAssociation(companyId);

        hasPermissionForAction(res, "Eliminar compañia", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return res.data;
        } else {
            console.log("Error deleting company association");
            return null;
        }
    };
}

function getCompanyUserGroups(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        set((state) => ({ companyUsersGroups: { loading: true, error: false, data: state.companyUsersGroups.data } }));

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.getCompanyUserGroups(idCompany);

        hasPermissionForAction(res, "Listar grupo de usuarios", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.respuesta && Array.isArray(res.data.respuesta)) {
            set({ companyUsersGroups: { loading: false, error: false, data: res.data.respuesta.reverse() } });
            return res.data;
        } else {
            console.log("Error fetching company users");
            set((state) => ({ companyUsersGroups: { loading: false, error: true, data: state.companyUsersGroups.data } }));
            //clean error
            set((state) => ({ companyUsersGroups: { ...state.companyUsersGroups, error: false } }));
            return null;
        }
    };
}

function createUserGroup(set: NamedSet<State>, get: GetState<State>) {
    return async (body: UserGroup, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        const payload = {
            name: body.name,
            description: body.description,
            companyId: idCompany,
        };

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.createUserGroup(payload);

        hasPermissionForAction(res, "Crear grupo de usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return res.data;
        } else {
            console.log("Error associating company");
            return null;
        }
    };
}

function updateUserGroup(set: NamedSet<State>, get: GetState<State>) {
    return async (body: UserGroup, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        const payload = {
            name: body.name,
            description: body.description,
            companyId: idCompany,
            id: body.id,
        };

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.updateUserGroup(payload);

        hasPermissionForAction(res, "Actualizar grupo de usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return res.data;
        } else {
            console.log("Error associating company");
            return null;
        }
    };
}

function deleteUserGroup(set: NamedSet<State>, get: GetState<State>) {
    return async (userGroupId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.deleteUserGroup(userGroupId);

        hasPermissionForAction(res, "Eliminar grupo de usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return res.data;
        } else {
            console.log("Error associating company");
            return null;
        }
    };
}

function getCompanyUserGroupAnnexe(set: NamedSet<State>, get: GetState<State>) {
    return async (userId, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        set((state) => ({ annexeCompanyUserGroup: { loading: true, error: false, data: state.annexeCompanyUserGroup.data } }));

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.getCompanyUserGroupAnnexe(idCompany, userId);

        hasPermissionForAction(res, "Obtener grupo de usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ annexeCompanyUserGroup: { loading: false, error: false, data: res.data.respuesta } });
            set({ annexeCompanyUser: { loading: false, error: false, data: { userId } } });
            return res.data;
        } else {
            console.log("Error fetching company users");
            set((state) => ({ annexeCompanyUserGroup: { loading: false, error: true, data: state.annexeCompanyUserGroup.data } }));
            set((state) => ({ annexeCompanyUser: { loading: false, error: true, data: state.annexeCompanyUser.data } }));

            //clean error
            set((state) => ({ annexeCompanyUserGroup: { ...state.annexeCompanyUserGroup, error: false } }));
            set((state) => ({ annexeCompanyUser: { ...state.annexeCompanyUser, error: false } }));
            return null;
        }
    };
}

function createCompanyUserGroupAnnexe(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        const payload = {
            companyId: idCompany,
            userId: body.selectedUser,
            userGroupIdList: body.selectedUserGroups,
        };

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.createCompanyUserGroupAnnexe(payload);

        hasPermissionForAction(res, "Asociar grupo de usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return res.data;
        } else {
            console.log("Error associating company");
            return null;
        }
    };
}

function updateCompanyUserGroupAnnexe(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.updateCompanyUserGroupAnnexe(body.selectedUserGroups, body.annexeId);

        hasPermissionForAction(res, "Actualizar grupo de usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return res.data;
        } else {
            console.log("Error associating company");
            return null;
        }
    };
}

function getCompanyUsersGroupAnnexeByList(set: NamedSet<State>, get: GetState<State>) {
    return async (usersList: any, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        set((state) => ({ annexeCompanyList: { loading: true, error: false, data: state.annexeCompanyList.data } }));

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await administrationService.getCompanyUsersGroupAnnexeByList(usersList, idCompany);

        hasPermissionForAction(res, "Obtener grupo de usuario", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ annexeCompanyList: { loading: false, error: false, data: res.data.respuesta } });
            return res.data;
        } else {
            console.log("Error fetching company users");
            set((state) => ({ annexeCompanyList: { loading: false, error: true, data: state.annexeCompanyList.data } }));
            //clean error
            set((state) => ({ annexeCompanyList: { ...state.annexeCompanyList, error: false } }));
            return null;
        }
    };
}

function getPoliciesByCompanyList( set: NamedSet<State>, get: GetState<State> ) {
    /** */
    return async ( withLoading = true ) => {
        /** */
        const companyId: string = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal( true );

        const res = await administrationService.getPoliciesByCompanyList( companyId );

        hasPermissionForAction( res, "Recuperar políticas de contraseña", false );

        withLoading && useModalState.getState().setLoaderModal(false);

        if ( res?.data?.codigo === 0 ) {
            return res.data;
        } else {
            console.log( "Error associating company" );
            return null;
        }
    }
}

function getPoliciesByUserList( set: NamedSet<State>, get: GetState<State> ) {
    /** */
    return async ( userId, withLoading = true ) => {
        /** */

        withLoading && useModalState.getState().setLoaderModal( true );

        const res = await administrationService.getPoliciesByUserList( userId );

        hasPermissionForAction( res, "Recuperar políticas de contraseña", false );

        withLoading && useModalState.getState().setLoaderModal(false);

        if ( res?.data?.codigo === 0 ) {
            return res.data;
        } else {
            console.log( "Error associating company" );
            return null;
        }
    }
}

function updatePoliciesList( set: NamedSet<State>, get: GetState<State> ) {
    /** */
    return async ( body: PolicyData, withLoading = true ) => {
        /** */
        withLoading && useModalState.getState().setLoaderModal( true );

        const res = await administrationService.updatePoliciesList( body );

        hasPermissionForAction( res, "Actualizar políticas", false );

        withLoading && useModalState.getState().setLoaderModal( false );

        if ( res?.data?.codigo === 0 ) {
            return res.data;
        } else {
            console.log( "Error updating policies" );
            return null;
        }

    }
}

function getNotApplicableUsers( set: NamedSet<State>, get: GetState<State> ) {
    /** */
    return async ( withLoading = true ) => {
        /** */
        const companyId: string = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal( true );

        const res = await administrationService.getNotApplicableUsers( companyId );

        hasPermissionForAction( res, "Recuperar usuario sin otp", false );

        withLoading && useModalState.getState().setLoaderModal( false );

        if ( res?.data?.codigo === 0 ) {
            return res.data;
        } else {
            console.log( "Error associating company" );
            return null;
        }
    }
}

function saveNotApplicableUsers( set: NamedSet<State>, get: GetState<State> ) {
    /** */
    return async ( body: string[], withLoading = true ) => {
        /** */
        const companyId: string = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal( true );

        const res = await administrationService.saveNotApplicableUsers( body, companyId );

        hasPermissionForAction( res, "Guardar usuarios sin otp", false );

        withLoading && useModalState.getState().setLoaderModal( false );

        if ( res?.data?.codigo === 0 ) {
            return res.data;
        } else {
            console.log( "Error associating company" );
            return null;
        }
    }
}

function validateOtpCode( set: NamedSet<State>, get: GetState<State> ) {
    /** */
    return async ( id: string, code: string, withLoading = true ) => {
        /** */
        withLoading && useModalState.getState().setLoaderModal( true );

        const res = await administrationService.validateOtpCode( id, code );

        withLoading && useModalState.getState().setLoaderModal( false );

        if ( res?.data?.codigo === 0 ) {
            return res.data;
        } else {
            console.log( "Error otp code valitation" );
            return res.data;
        }
    }
}