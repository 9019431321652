import { ContractStatus } from "../models/Contract.models";
import { TemplateStatus } from "../models/Template.models";
import { BiddingStatus } from "../models/biddings/Bidding.models";


export const GENERIC_DATAGROUP_TYPE = "generic";

export const CONTRACT_STATUSES: ContractStatus[] = [
    "borrador",
    "revision",
    "aprobado",
    "rechazado",
    "en boveda",
    "pendiente de firmas",
    "firmado",
    "tokenizado",
];

export const TEMPLATE_STATUSES: TemplateStatus[] = ["borrador", "activo"];
export const BIDDING_STATUSES: BiddingStatus[] = ["aceptado", "rechazado", "bajo revision"];

export const VALID_FILE_EXTENSIONS: ValidExtension[] = [
    "pdf",
    // "doc",
    // "docx",
    // "xls",
    // "xlsx",
    // "ppt",
    // "pptx",
    // "jpg",
    // "jpeg",
    // "png",
    // "gif",
    // "xml",
    // "html",
];

export const PHONE_PREFIXES = {
    US: { primary: "+1" },
    MX: { primary: "+52" },
    CO: { primary: "+57" },
    PE: { primary: "+51" },
};

export const EXCEL_MIME_TYPES = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
export const IMAGE_MIME_TYPES = ["image/jpeg", "image/png", "image/bmp", "image/tiff", "image/webp"];
export const WORD_MIME_TYPES = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

export const MESES = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];



const emailReg = new RegExp(
    /^(([^<>()\[\]\.,;:\s@\']+(\.[^<>()\[\]\.,;:\s@\']+)*)|(\'.+\'))@(([^<>()[\]\.,;:\s@\']+\.)+[^<>()[\]\.,;:\s@\']{1,})$/i /* eslint-disable-line */
);
const curpReg = new RegExp(
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0\d|1[0-2])(?:[0-2]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/ /* eslint-disable-line */
);
const rfcReg = new RegExp(
    /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i
); /* eslint-disable-line */

export const REGEXPS = {
    emailReg,
    curpReg,
    rfcReg,
};
