import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

const validationTypeAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/biddingValidationType`,
    timeout: 20000,
});

const getBiddingValidationTypes = async () => {
    try {
        return await validationTypeAxiosRequest.get(`/all`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getBiddingValidationTypesByCompany = async (companyOwnerId: string) => {
    try {
        return await validationTypeAxiosRequest.get(`/${companyOwnerId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// const getBiddingValidationType = async (biddingValidationTypeId: string) => {
//     try {
//         return await validationTypeAxiosRequest.get(`/${biddingValidationTypeId}`, {
//             headers: getHeaders(),
//         });
//     } catch (error) {
//         console.log(error);
//         return error;
//     }
// };

const createBiddingValidationType = async (body: any) => {
    try {
        return await validationTypeAxiosRequest.put(`/`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateBiddingValidationType = async (body: any) => {
    try {
        return await validationTypeAxiosRequest.patch(``, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteBiddingValidationType = async (biddingValidationTypeId: string) => {
    try {
        return await validationTypeAxiosRequest.delete(`/${biddingValidationTypeId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default { createBiddingValidationType, updateBiddingValidationType, deleteBiddingValidationType, getBiddingValidationTypes, getBiddingValidationTypesByCompany };

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
