import { Link } from "react-router-dom";
import LoginForm from "../../components/auth/LoginForm";
import logoOrkestadoc from "../../assets/images/logo-scm-alt.png";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const LoginPage = () => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    
    return (
        <div className="h-screen w-screen flex">
            <div className="w-full md:w-6/12 flex justify-center items-center flex-col">
                <LoginForm />
                {/*<div className="w-full mt-24 text-center">
                    {t("notAccount")}
                    <Link to="/register" className="text-brand-500">
                        {" "}
                        {t("register")}
                    </Link>
                </div>*/}
            </div>
            <div
                className="w-6/12 hidden md:flex flex-col justify-between items-center py-24"
                style={{
                    backgroundImage: "url('/images/auth-bg.png')",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                }}
            >
                <div className="h-12"></div>
                <div>
                    <img src={logoOrkestadoc} className="h-20" alt="axeleratum" />
                </div>
                <div className="w-10/12 lg:w-8/12 text-gray-100 text-center">
                    {t("smartContractMaker")}
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
