import { useEffect } from "react";
import newContractImg from "../../assets/images/new-contract.png";
import newTemplateImg from "../../assets/images/new-template.png";
import newProcessImg from "../../assets/images/new-process.png";
import newUserImg from "../../assets/images/new-user.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useGlobalState from "../../store/globalState";
import DottedSubtitle from "../common/DottedSubtitle";
import useComponetState from "src/store/components/componentState";

const ShorcutsList = () => {
    //const setEditCompanyModal = useModalState((s) => s.setEditCompanyModal);
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const { getUserCompanyInfo, userInfo, userCompanyInfo } = useGlobalState();
    const showBtnUiTemplate = useComponetState((s) => s.uiBtnTemplateOptions);
    const showBtnUiTaps = useComponetState((s) => s.uiTabOptions);
    const showBtnProcessOptions = useComponetState((s) => s.uiBtnProcessOptions);

    useEffect(() => {
        if (userInfo.data) {
            getUserCompanyInfo();
        }
    }, [userInfo?.data]);

    return (
        <div>
            <div className="flex w-full items-center gap-4 mt-6">
                <DottedSubtitle text={t("shortcuts")} />
            </div>
            <div className="w-full items-center mt-4 grid grid-cols-4 gap-4">

                {!showBtnProcessOptions.showBtnCreateNewDocument ? ("") : (
                    <BoxItem onClick={() => navigate("/contracts/create")}>
                        <img className="w-[40%]" src={newContractImg} alt="" />
                        <p className="text-2xl text-brand-600 leading-8 text-center">
                            {t("generate")} <br /> {t("contract")}
                        </p>
                    </BoxItem>
                )}
                {!showBtnUiTemplate.showBtnNewTemplate ? ("") : (
                    <BoxItem onClick={() => navigate("/templates/create")}>
                        <img className="w-[40%]" src={newTemplateImg} alt="" />
                        <p className="text-2xl text-brand-600 leading-8 text-center">
                            {t("create")} <br /> {t("template")}
                        </p>
                    </BoxItem>
                )}

                {!showBtnUiTaps.showTabProcess ? ("") : (
                    <BoxItem onClick={() => navigate("/biddings")}>
                        <img className="w-[38%]" src={newProcessImg} alt="" />
                        <p className="text-2xl text-brand-600 leading-8 text-center">
                            {/*t("new")*/} <br /> {t("process")}
                        </p>
                    </BoxItem>
                )}

                {!showBtnUiTaps.showTabAdmin ? ("") : (
                    <BoxItem onClick={() => navigate("/administration")}>
                        <img className="w-[40%]" src={newUserImg} alt="" />
                        <p className="text-2xl text-brand-600 leading-8 text-center">
                            {/*t("add")*/} <br /> {t("administration")}
                        </p>
                    </BoxItem>
                )}

            </div>
        </div>
    );
};

export default ShorcutsList;

const BoxItem = ({ children, onClick }) => {
    return (
        <div
            onClick={onClick}
            className="w-full h-[10rem] bg-gray-light p-4 flex items-center justify-around gap-4 border border-transparent hover-border-brand rounded-lg"
        >
            {children}
        </div>
    );
};
