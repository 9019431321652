import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { DataGroup } from "../models/DataGroup.models";
import dataGroupService from "../services/dataGroup.service";
import useAuthState from "./authState";
import useModalState from "./modalsState";

interface State {
    dataGroups: { loading: boolean; error: boolean; data: DataGroup[] };
    currentNewDataGroup: Partial<DataGroup>;
    selectedDataGroup: DataGroup;
    setCurrentNewDataGroup: (dataGroup: Partial<DataGroup>) => void;
    setSelectedDataGroup: (dataGroup: Partial<DataGroup>) => void;
    getDataGroups: (withLoading?: boolean) => void;
    getDataGroup: (dataGroupId: string, withLoading?: boolean) => Promise<boolean>;
    createDataGroup: (dt: Partial<DataGroup>, withLoading?: boolean) => Promise<boolean>;
    updateDataGroup: (dt: Partial<DataGroup>, withLoading?: boolean) => Promise<boolean>;
    deleteDataGroup: (dataGroupId: string, withLoading?: boolean) => Promise<boolean>;
    reset: () => void;
}

const initialState = {
    dataGroups: { loading: false, error: false, data: [] },
    currentNewDataGroup: null,
    selectedDataGroup: null,
};

const useDataGroupsState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            setCurrentNewDataGroup: setCurrentNewDataGroup(set, get),
            getDataGroups: getDataGroups(set, get),
            createDataGroup: createDataGroup(set, get),
            updateDataGroup: updateDataGroup(set, get),
            deleteDataGroup: deleteDataGroup(set, get),
            getDataGroup: getDataGroup(set, get),
            setSelectedDataGroup: setSelectedDataGroup(set, get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "dataGroupsState" }
    )
);

export default useDataGroupsState;

//SETTER FUNCTIONS
function setCurrentNewDataGroup(set: NamedSet<State>, get: GetState<State>) {
    return (dt: Partial<DataGroup>) => set((state) => ({ currentNewDataGroup: dt }));
}

function setSelectedDataGroup(set: NamedSet<State>, get: GetState<State>) {
    return (dt: DataGroup) => set((state) => ({ selectedDataGroup: dt }));
}

function hasPermissionForAction (reponse : any, msgPermission : string, redirect : boolean ){
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: 'AVISO',
                text2: msgPermission,
                text3: 'Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.',
                redirectHome : redirect,
                onClose: async () => {
                    return { show: false }
                  }
            }
        });
    }
}

function getDataGroups(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        set((state) => ({ dataGroups: { loading: true, error: false, data: state.dataGroups.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await dataGroupService.listDataGroups(idCompany);
        
        hasPermissionForAction(res,"Obtener grupo de datos",true);

        if (res?.data?.respuesta) {
            set({ dataGroups: { loading: false, error: false, data: res.data.respuesta?.reverse() } });
        } else {
            console.log("Error fetching dataGroups");
            set((state) => ({ dataGroups: { loading: false, error: true, data: state.dataGroups.data } }));
            //clean error
            set((state) => ({ dataGroups: { ...state.dataGroups, error: false } }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function createDataGroup(set: NamedSet<State>, get: GetState<State>) {
    return async (dt, withLoading = true) => {
        const auth = useAuthState.getState();

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await dataGroupService.createDataGroup({
            ...dt,
            idCompany: auth.companyId,
            idUser: auth.userId,
            userName: auth.userName,
        });

        hasPermissionForAction(res,"Crear grupo de datos",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getDataGroups(false);
            return true;
        } else {
            console.log("Error creating dataGroup");
            return false;
        }
    };
}

function updateDataGroup(set: NamedSet<State>, get: GetState<State>) {
    return async (dt, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await dataGroupService.updateDataGroup(dt);

        hasPermissionForAction(res,"Actualizar grupo de datos",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getDataGroups(false);
            return true;
        } else {
            console.log("Error creating dataGroup");
            return false;
        }
    };
}

function deleteDataGroup(set: NamedSet<State>, get: GetState<State>) {
    return async (dtId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await dataGroupService.deleteDataGroup(dtId);

        hasPermissionForAction(res,"Eliminar grupo de datos",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getDataGroups(false);
            return true;
        } else {
            console.log("Error deleting dataGroup");
            return false;
        }
    };
}

function getDataGroup(set: NamedSet<State>, get: GetState<State>) {
    return async (dtId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await dataGroupService.getDataGroupById(dtId);

        hasPermissionForAction(res,"Obtener grupo de datos",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.respuesta[0]) {
            set({ selectedDataGroup: res.data.respuesta[0] });
            return true;
        } else {
            console.log("Error fetching dataGroup");
            set({ selectedDataGroup: null });
            return false;
        }
    };
}
