import { Button } from "@chakra-ui/button";
import { useBoolean } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router";
import LinkButton from "../../components/common/buttons/LinkButton";

import MainLayout from "../../components/layouts/MainLayout";
import ContractDataGroupsForms from "../../components/contracts/ContractDataGroupsForms";
import ContractGeneralInfo from "../../components/contracts/ContractGeneralInfo";
import ContractSteps from "../../components/contracts/ContractSteps";
import useCustomToast from "../../hooks/useCustomToast";
import usePdfPreview from "../../hooks/usePdfPreview";
import { CreateContractBody } from "../../models/Contract.models";
import useContractState from "../../store/contractState";
import useModalState from "../../store/modalsState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { useLocation } from "react-router-dom";
import { isObjectId } from "../../lib/utils";
import useTemplateState from "../../store/templatesState";
import useBiddingState from "src/store/biddings/biddingState";
import { DataGroup } from "src/models/DataGroup.models";
import useAuthState from "src/store/authState";

const CreateContractPage = () => {
    const navigate = useNavigate();

    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const location = useLocation();
    const locationState: any = location.state;
    const query = new URLSearchParams(location.search);
    const selectedTemplateId = query.get("template");
    //biddings options
    const assignToBidding = locationState?.assignToBidding;
    const assignamentState = locationState?.assignamentState;
    const checkSessionExpires = useAuthState( s => s.checkSessionExpires )

    const requerimentName = locationState?.assignamentState?.biddinName;
    const { addSmartContractToBiddingReq } = useBiddingState();
    const { selectedBidding } = useBiddingState();
    const { setPdfPreviewModal, setLoaderModal, setContractRevisionModal } = useModalState();
    const {
        setCurrentNewContract,
        initNewContract,
        currentNewContract,
        createContract,
        generateUniqueSerializedName,
        transformNumberWithPattern,
        transformDateWithPattern,
    } = useContractState();
    const { getTemplate } = useTemplateState();
    const { generatePdfPreviewUrl } = usePdfPreview();
    const [invalidName, setInvalidName] = useBoolean(false);
    const [invalidTemplate, setInvalidTemplate] = useBoolean(false);
    const [stepLife, setStepLife] = useState("");
    const [nameAssigned, setNameAssigned] = useState("");
    const dataFileAmericaMovil = useBiddingState((s) => s.dataFileAmericaMovil);
    const preLoadedDataGroups: any[] = assignamentState?.expedienteUnico;
    const tokenExpiresIn = useAuthState((s) => s.tokenExpiresIn);
    const timestampExp = useAuthState((s) => s.timestampExp);

    const { hasSavedTemplateContract, setHasSavedTemplateContract} = useContractState();


    function buildImgElement(base64String) {
        // Construir la cadena con la estructura del elemento img
        if (!base64String) {
            console.error("La cadena Base64 proporcionada está vacía o nula.");
            return ""; // O cualquier otro valor de retorno que desees
        }
        const base64Format = base64String.replace(/"/g, '');
        const imgUrl = `data:image/png;base64,${base64Format}`;
        return imgUrl;
    }

    async function handlePreview() {
        if (!currentNewContract?.text) {
            return toast.error(t("thereIsNoContentToPreview"));
        }
        setLoaderModal(true);
        try {
            // verificar si existe footer - header generar elemento img
            const headerImgUrl = currentNewContract?.template?.header ? buildImgElement(currentNewContract.template.header) : "";
            const footerImgUrl = currentNewContract?.template?.footer ? buildImgElement(currentNewContract.template.footer) : "";
    
            const pdfBlobUrl = await generatePdfPreviewUrl(
                currentNewContract.text,
              //  currentNewContract?.template?.header,
               // currentNewContract?.template?.footer
               headerImgUrl,
               footerImgUrl
           );
   
            setLoaderModal(false);
            setPdfPreviewModal({ show: true, data: { pdfBlobUrl } });
        } catch (error) {
            toast.error(t("errorWhileGeneratingPreview"));
            console.log("o sera en este");
        } finally {
            setLoaderModal(false);
        }
    }

    function validateContractData() {
        let isValid = true;
        if (!currentNewContract?.name) {
            setInvalidName.on();
            toast.error(t("nameRequired"));
            isValid = false;
        }
        if (!currentNewContract.template) {
            setInvalidTemplate.on();
            toast.error(t("templateRequired"));
            isValid = false;
        }

        return isValid;
    }

    async function saveContractAsDraft() {
        if (!validateContractData()) return;
        currentNewContract.template.dataGroup = currentNewContract.auxDataGroups as Partial<DataGroup>[];
        const res = await createContract(currentNewContract as CreateContractBody);
        console.log("res duplicated",res)
        if (res.saved === false) {
            toast.error(t("contractAlreadyExists"), t("warning"));
        } else if (res.saved === true) {
            toast.success(t("contractSavedSuccessfully"));
            if (assignToBidding) {
                const body = {
                    contractId: res?.contract?.id,
                    month: assignamentState?.month,
                    year: assignamentState?.year,
                    name: res?.contract?.name,
                };
                const assigned = await addSmartContractToBiddingReq(body, assignamentState?.biddingId, assignamentState?.reqId);
                if (assigned) {
                    toast.success("Documento asignado correctamente");
                } else {
                    toast.error("Error al asignar documento");
                    return;
                }
            }
            // eslint-disable-next-line no-restricted-globals
            return navigate("../");
        } else {
            toast.error(t("savingContractError"));
        }
    }

    async function onSendToRevision() {
        if (!validateContractData()) return;
        currentNewContract.template.dataGroup = currentNewContract.auxDataGroups as Partial<DataGroup>[];
        const res = await createContract(currentNewContract as CreateContractBody);
        console.log("res duplicated onSendToRevision",res)

        if (res.saved === false) { // duplicated cambio la respuesta de la apí, duplicated siempre devuelve false
            toast.error(t("contractAlreadyExists"), t("warning"));
        } else if (res.saved === true ) {
            // toast.success(t("revisionCreatedSuccessfully"));
            if (assignToBidding) {
                const body = {
                    contractId: res?.contract?.id,
                    month: assignamentState?.month,
                    year: assignamentState?.year,
                    name: res?.contract?.name,
                };
                const assigned = await addSmartContractToBiddingReq(body, assignamentState?.biddingId, assignamentState?.reqId);
                if (assigned) {
                    toast.success("Documento asignado correctamente");
                    setContractRevisionModal({
                        show: true,
                        data: {
                            contractId: res?.contract?.id,
                            afterSubmit: () => navigate(`/contracts/${res?.contract?.id}`),
                            nameContract: res?.contract?.name,
                        },
                    });
                } else {
                    toast.error("Error al asignar documento");
                    return;
                }
            }
            setContractRevisionModal({
                show: true,
                data: {
                    contractId: res?.contract?.id,
                    afterSubmit: () => navigate(`/contracts/${res?.contract?.id}`),
                    nameContract: res?.contract?.name,
                },
            });
            // eslint-disable-next-line no-restricted-globals
            toast.success(t("contractSavedSuccessfully"));
            // return navigate("../");
        } else {
            toast.error(t("savingContractError"));
        }
    }

    useEffect(() => {
        const newContract = initNewContract();
        if (selectedTemplateId && isObjectId(selectedTemplateId)) {
            //setTemplate
            getTemplate(selectedTemplateId).then((template) => {
                if (template) {
                    if (requerimentName != undefined) {
                        generateUniqueSerializedName(requerimentName).then((nameAssigned) => {
                            setStepLife(template?.maxLifeStage);
                            setCurrentNewContract({
                                ...newContract,
                                template,
                                idTemplate: template?.id,
                                maxLifeStage: template?.maxLifeStage,
                                name: nameAssigned,
                            })
                        });
                    } else {
                        setStepLife(template?.maxLifeStage);

                        setCurrentNewContract({
                            ...newContract,
                            template,
                            idTemplate: template?.id,
                            maxLifeStage: template?.maxLifeStage,
                            name: template?.name,
                        });
                    }
                }
            });
        }

        return () => {
            setCurrentNewContract(null);
            setStepLife("");
        };
    }, [selectedTemplateId]);

    useEffect(() => {
        const fetchData = async () => {
            if (requerimentName !== undefined) {
                const nameAssigned = await generateUniqueSerializedName(requerimentName);
                setNameAssigned(nameAssigned);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const tokenCurrentTime = tokenExpiresIn / 60;
        const x = checkSessionExpires();
       // console.log("x time timestampExp ",timestampExp /60)
       // console.log("time create contract exp token",tokenCurrentTime ,  hasSavedTemplateContract)
        if (tokenCurrentTime < 2.5 && !hasSavedTemplateContract) {
            saveContractAsDraft();
            setHasSavedTemplateContract(true);
            toast.info("El contrato se ha guardado automáticamente.","¡Atención!");
        }
    }, [timestampExp]);


    useEffect(() => {
     /*   const processExpediente = async () => {
            if (dataFileAmericaMovil != null && dataFileAmericaMovil.originCompany && dataFileAmericaMovil.destinationCompany) {
                await Promise.all(preLoadedDataGroups.map(async (dataGroup) => {
                    if (dataGroup.types) {
                        dataGroup.types = dataGroup.types.map((dataType) => {
                            const lowerCaseName = dataType.name.toLowerCase(); // Convertir el nombre a minúsculas
    
                            if (lowerCaseName.includes("destino")) {
                                return { ...dataType, value: dataFileAmericaMovil.destinationCompany };
                            } else if (lowerCaseName.includes("origen")) {
                                return { ...dataType, value: dataFileAmericaMovil.originCompany };
                            } else {
                                return dataType;
                            }
                        });
                    }
                }));
            } 
        };
    
        processExpediente();*/
    }, []);
    

    return (
        <MainLayout>
            <main className="w-full p-3 flex h-full">
                {/* left panel */}
                <section className="w-3/12 flex flex-col pr-4 pl-2 border-r h-full max-h-full overflow-y-auto relative">
                    <h2 className="title-3">1. {t("generateContract")}</h2>
                    <p className="mt-2 text-xs text-justify">{t("createContractText")}</p>
                    <div className="grow"></div>
                    <div className="flex justify-around pt-4 pb-2 sticky bottom-0 bg-white border-t">
                        <Button rounded="full" size="md" fontWeight="medium" variant="outline" onClick={saveContractAsDraft}>
                            {t("saveDraft")}
                        </Button>
                        <Button rounded="full" size="md" fontWeight="medium" variant="solid" onClick={onSendToRevision}>
                            {t("sendRevision")}
                        </Button>
                        {/* <Button disabled rounded="full" size="md" fontWeight="medium" variant="solid" isLoading={false}>
                            Revisión
                        </Button> */}
                    </div>
                </section>

                {/* right panel */}
                <section className="w-9/12 pr-2 pl-4 h-full max-h-full overflow-y-auto">
                    <div className="w-full pb-4 border-b">
                        <ContractSteps
                            currentItem={1}
                            stepTemplate={currentNewContract?.maxLifeStage}
                            key={currentNewContract?.idTemplate}
                        />
                    </div>

                    <div className="w-full flex items-center justify-between py-4 border-b">
                        <LinkButton leftIcon={<BiChevronLeft size={18} />} onClick={() => navigate(-1)}>
                            {t("back")}
                        </LinkButton>
                        <LinkButton leftIcon={<FaEye size={18} />} onClick={handlePreview}>
                            {t("preview")}
                        </LinkButton>
                    </div>

                    <div className="w-full mx-auto flex flex-col grow items-center">
                        <ContractGeneralInfo
                            action="create"
                            invalidName={invalidName}
                            invalidTemplate={invalidTemplate}
                            canSelectTemplate={!assignToBidding}
                            handleCurrentStepLife={setStepLife}
                            nameAssigned={selectedBidding?.name}
                            requerimentName={requerimentName}
                        />
                        {currentNewContract?.template && (
                            <ContractDataGroupsForms
                                action="create"
                                key={currentNewContract?.template?.name}
                                preLoadedDataGroups={preLoadedDataGroups}
                                transformNumberWithPattern={transformNumberWithPattern}
                                transformDateWithPattern={transformDateWithPattern}
                            />
                        )}
                        {/* mantener prop key para re-renderizar componente cuando cambia el template y evitar bugs*/}
                    </div>
                </section>
            </main>
        </MainLayout>
    );
};

export default CreateContractPage;
