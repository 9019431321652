import { Tooltip } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import useCustomToast from "../../hooks/useCustomToast";
import usePagination from "../../hooks/usePagination";
import { User } from "../../models/Aministration.models";
import useAdministrationState from "../../store/administrationState";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { BiChevronRight, BiUserPlus } from "react-icons/bi";
import useModalState from "../../store/modalsState";
import SearchInput from "../common/SearchInput";
import CommonTh from "../common/table/CommonTh";
import TablePaginator from "../common/table/TablePaginator";
import LinkButton from "../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { AiOutlineEdit } from "react-icons/ai";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";

const TableRow = ({ user, onDelete, onEdit }: { user: User; onDelete: (user: User) => any; onEdit: (user: User) => any }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 py-3">{`${user.name} ${user.lastName}`}</td>
            <td className="p-2 py-3">{user.email}</td>
            {/*<td className="p-2 py-3">{user.roles?.join(", ")}</td>*/}
            <td className="px-2 py-3">
                <div className="w-full xl:w-7/12 flex justify-center gap-6">
                    <Tooltip label={t("edit")} fontSize="12px">
                        <button onClick={() => onEdit(user)}>
                            <AiOutlineEdit size={25} />
                        </button>
                    </Tooltip>
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={() => onDelete(user)}>
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const UsersAdminTable = ({
    users,
    onDelete,
    onEdit,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
}: {
    users: User[];
    onDelete: (user: User) => any;
    onEdit: (user: User) => any;
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            {/* <div className="w-full flex justify-between items-center sticky top-0 z-10 py-3 bg-white border-b">
                <SearchInput onChange={onSearch} />
            </div> */}
            <table className="w-full text-sm text-left text-dark-2 cursor-pointer">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("email")} />
                        </th>
                        {/*<th scope="col" className="p-2">
                            <CommonTh text={t("roles")} />
                        </th>*/}
                        <th scope="col" className="p-2">
                            <CommonTh text={t("actions")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((item) => (
                        <TableRow key={item.id} user={item} onDelete={onDelete} onEdit={onEdit} />
                    ))}
                </tbody>
            </table>
            {users?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

const UsersAdmin = () => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [searchQuery, setSearchQuery] = useState("");
    const { setNewEditUserModal, setDeleteCommonModal } = useModalState();
    const [userType, setUserType] = useState<"internal" | "external">("internal");

    const {
        companyUsers: { data: users },
        getCompanyUsers,
        deleteUser,
    } = useAdministrationState();

    const externalUsers = useMemo(() => {
        return users?.filter((user) => user.external);
    }, [users]);

    const internalUsers = useMemo(() => {
        return users?.filter((user) => !user.external);
    }, [users]);

    const currentUsers = userType === "internal" ? internalUsers : externalUsers;

    const [filteredResults, setFilteredResults] = useState(currentUsers);

    useEffect(() => {
        const normalizedQuery = searchQuery.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        const results = currentUsers?.filter((user) =>
            user.name.trim().toLowerCase().includes(normalizedQuery) ||
            user.email.trim().toLowerCase().includes(normalizedQuery)
        );
        setFilteredResults(results);

        setPage(1);
    }, [searchQuery, currentUsers]);

    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({
        data: filteredResults,
        itemsPerPage: 6
    });

    async function onDelete(user: User) {
        const deleted = await deleteUser(user.id);
        if (deleted) {
            toast.success(t("userDeletedSuccessfully"));
            getCompanyUsers(); 
        } else {
            toast.error(t("deletingUserError"));
        }
    }

    function handleDelete(user: User) {
        setDeleteCommonModal({
            show: true,
            data: {
                text1: t("userWillBeDeleted"),
                text2: `${user?.name} ${user?.lastName}`,
                onDelete: () => onDelete(user),
            },
        });
    }

    function handleEdit(user: User) {
        setNewEditUserModal({
            show: true,
            data: { type: "edit", userType: userType, user: user }
        });
    }

    useEffect(() => {
        getCompanyUsers();
    }, []);

    return (
        <>
            <div className="mt-4 mb-4">
                <DottedSubtitle
                    text={t("users")}
                    withSearch
                    onSearch={setSearchQuery}
                    beforeSearchElement={
                        <LinkButton
                            leftIcon={<BiUserPlus size={20} />}
                            onClick={() =>
                                setNewEditUserModal({
                                    show: true,
                                    data: { type: "new", userType: userType }
                                })
                            }
                        >
                            {t("addNewUser")}
                        </LinkButton>
                    }
                />
            </div>
            <Tabs isFitted variant="enclosed-colored">
                <TabList>
                    <Tab isSelected={userType === "internal"} onClick={() => setUserType("internal")}>
                        Internos
                    </Tab>
                    <Tab isSelected={userType === "external"} onClick={() => setUserType("external")}>
                        Externos
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <UsersAdminTable
                            users={currentResutls}
                            onDelete={handleDelete}
                            onEdit={handleEdit}
                            currentPage={currentPage}
                            maxPages={maxPages}
                            prev={prev}
                            next={next}
                            setPage={setPage}
                        />
                    </TabPanel>
                    <TabPanel>
                        <UsersAdminTable
                            users={currentResutls}
                            onDelete={handleDelete}
                            onEdit={handleEdit}
                            currentPage={currentPage}
                            maxPages={maxPages}
                            prev={prev}
                            next={next}
                            setPage={setPage}
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    );
};

export default UsersAdmin;
