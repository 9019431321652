import React from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import { Box, Button, Flex, FormControl, FormLabel, Select, Switch, useBoolean } from "@chakra-ui/react";
import { BiPlus, BiSave } from "react-icons/bi";
import AddTaskRoadmapModal from "src/components/common/modals/AddTaskRoadmapModal";
import { useAxRoadmapStore } from "src/store/axRoadmap.store";

type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => {
  const [showAddStage, setShowAddStage] = useBoolean(false);
  const { tasksRoadmapSelected, onlyRead } = useAxRoadmapStore();

  return (
    <>
      <Box pt={4} pb={2} display={'flex'} justifyContent={tasksRoadmapSelected.length !== 0 ? 'space-between' : 'end'}>
        {
          tasksRoadmapSelected.length !== 0 &&
          <Flex>
            <Select
              placeholder="Agrupación de tiempo"
              onChange={(e) => { e.target.value !== '' && onViewModeChange(e.target.value as ViewMode) }}
              bg="white"
              borderRadius="md"
              mr={4}
              width={'auto'}
            >
              <option value={ViewMode.Hour}>Hora</option>
              <option value={ViewMode.QuarterDay}>Cuarto de día</option>
              <option value={ViewMode.HalfDay}>Medio día</option>
              <option value={ViewMode.Day}>Día</option>
              <option value={ViewMode.Week}>Semana</option>
              <option value={ViewMode.Month}>Mes</option>
              <option value={ViewMode.Year}>Año</option>
            </Select>
            <FormControl display="flex" flexWrap={'wrap'} alignItems="center" width={'auto'}>
              <FormLabel htmlFor="task-list-switch" mb="0">
                Mostrar lista de tareas
              </FormLabel>
              <Switch
                id="task-list-switch"
                isChecked={isChecked}
                onChange={() => onViewListChange(!isChecked)}
                colorScheme="teal"
              />
            </FormControl>
          </Flex>
        }
        <Flex gap={1}>
          {
            !onlyRead && <Button variant='outline' leftIcon={<BiPlus />} onClick={setShowAddStage.on}>Agregar actividad</Button>
          }

          {/* {tasksRoadmapSelected.length !== 0 &&
            <Button variant='outline' leftIcon={<BiSave />} onClick={() => openModal('SaveRoadMapAlert')}>Guardar cambios</Button>
          } */}
        </Flex>
      </Box >
      {showAddStage && (
        <AddTaskRoadmapModal onCancel={setShowAddStage.off} isOpen={true} />
      )}
    </>

  );
};
