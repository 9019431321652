import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import CommonModal from "../../CommonModal";

import { Accordion, Button } from "@chakra-ui/react";
import AccordionItemBrand from "../../AccordionItemBrand";
import { Template } from "src/models/Template.models";
import ExpedientDynamicForm from "../../../bidding/ExpedientDynamicForm";
import useBiddingState from "src/store/biddings/biddingState";
import { useEffect, useState } from "react";
import { DataGroup } from "src/models/DataGroup.models";
import { ExpedientModalData } from "src/models/Modals.models";
import useCustomToast from "src/hooks/useCustomToast";
type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: ExpedientModalData;
};

const ExpedientModal = ({ isOpen, onClose, data }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const { updateExpedient } = useBiddingState();
    const hasDataGroups = data?.dataGroups?.length > 0;
console.log("haha ",data)
    const [populatedDataGroups, setPopulatedDataGroups] = useState<Partial<DataGroup>[]>(data?.dataGroups ? [...data?.dataGroups] : null);

    async function handleSave() {
        const saved = updateExpedient(data?.biddingId, populatedDataGroups as DataGroup[]);
        if (saved) {
            toast.success("Se ha guardado el expediente correctamente");
            data.setCanSetExpedient(true);
        } else {
            toast.error("Ha ocurrido un error al guardar el expediente");
        }
    }

    return (
        <CommonModal size="6xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col h-[80vh] relative">
                <div className="w-full flex items-end justify-between gap-6 sticky top-0">
                    <div className="ml-4">
                        <h2 className="title-3">Expediente de datos</h2>
                        <h3 className="text-dark">Grupos de datos del expediente</h3>
                    </div>
                    <div className="mr-6">
                        <Button rounded={"full"} disabled={!hasDataGroups} onClick={handleSave}>
                            Guardar
                        </Button>
                    </div>
                </div>
                {hasDataGroups ? (
                    <div className="px-2 mt-4 overflow-auto">
                        <Accordion defaultIndex={[0]} allowMultiple>
                            {populatedDataGroups?.map((dg, idx) => (
                                <AccordionItemBrand key={idx} title={dg.name}>
                                    <ExpedientDynamicForm
                                        dataGroup={{ ...dg }}
                                        dataGroupIdx={idx}
                                        setPopulatedDataGroups={setPopulatedDataGroups}
                                    />
                                </AccordionItemBrand>
                            ))}
                        </Accordion>
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center h-full">
                        <h3 className="text-dark">No hay grupos de datos para mostrar</h3>
                    </div>
                )}
            </div>
        </CommonModal>
    );
};

export default ExpedientModal;
