import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { WORD_MIME_TYPES } from "../../../../lib/constants";
import { TemplateFromDocxModalData } from "../../../../models/Modals.models";
import useTemplateState from "../../../../store/templatesState";
import CommonModal from "../../CommonModal";
import FileDropzone from "../../FileDropzone";
import useBiddingState from "src/store/biddings/biddingState";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: TemplateFromDocxModalData;
};

const TemplateFromDocxModal = ({ isOpen, onClose, data }: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const navigate = useNavigate();
    const saveTemplateFromDocx = useTemplateState((s) => s.saveTemplateFromDocx);
    const uploadDocumentToSession = useBiddingState((s) => s.addDocumentToSession);

    async function handleUpload(file: File) {
        if (!WORD_MIME_TYPES.includes(file.type)) {
            return toast.error(t("invalidDocxFile"));
        }

        if (!data.newTemplateValidation()) return;

        const formData = new FormData();
        formData.append("file", file);

        const blobBody = {
            file: formData,
        };

        const res = await uploadDocumentToSession(blobBody);

        if (res.uploaded) {
            const docRes = await saveTemplateFromDocx(data.currentTemplate, res?.response);
            onClose();
            if (docRes) {
                toast.success(t("uploadedFileSuccessfully"));
            } else {
                toast.error(t("uploadingFileError"));
            }
            navigate("/templates");
        } else {
            toast.error(t("uploadingFileError"));
        }
    }

    return (
        <CommonModal size="md" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center py-4">
                <h2 className="title-3 text-center">{t("createTemplateFromDocx")}</h2>

                <div className="flex flex-col justify-center">
                    <div className="flex flex-col justify-center mt-8">
                        <h3 className="text-base text-dark font-semibold text-center w-10/12 mx-auto">{t("uploadDocxFile")}:</h3>
                        <div className="mt-3">
                            <FileDropzone onDropFile={handleUpload} />
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
};

export default TemplateFromDocxModal;
