import axios from "axios";
import { Contract, CreateContractBody } from "../models/Contract.models";
import { getAuthHeader } from "../lib/utils";

export const axiosScmRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1`,
    timeout: 20000,
});

export const axiosGeneralRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_GENERAL}/api/general`,
    timeout: 12000,
});

const listContracts = async (idCompany: string) => {
    try {
        return await axiosScmRequest.post(
            `/contract/returnContractList`,
            { idCompany, idUser: "" },
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getContract = async (contractId: string) => {
    try {
        return await axiosScmRequest.get(`/contract/returnContract/${contractId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createContract = async (body: CreateContractBody) => {
    try {
        return await axiosScmRequest.post(`/contract/saveContract`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateContract = async (body: Contract) => {
    try {
        return await axiosScmRequest.post(`/contract/editContract`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteContract = async (contractId: string) => {
    try {
        return await axiosScmRequest.delete(`contract/deleteContract/${contractId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const listContractTypes = async (idCompany: string) => {
    try {
        return await axiosScmRequest.get(`/types_collection/listTypes/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createContractType = async (body: { active: boolean; description: string; idCompany: string }) => {
    try {
        return await axiosScmRequest.post(`/types_collection/saveTypes`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteContractType = async (contractTypeId: string) => {
    try {
        return await axiosScmRequest.delete(`/types_collection/deleteTypes/${contractTypeId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getTraceability = async (contractId: string) => {
    try {
        return await axiosScmRequest.get(`/trace/returnTraceability/${contractId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getTraceabilityPDF = async (contractId: string) => {
    try {
        return await axiosScmRequest.get(`/documentTrace/returnTraceability/${contractId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

//setea vaultId en el contrato, cambia status a "en boveda" y crea trazabilidad
const setIdVault = async (body: any) => {
    try {
        return await axiosScmRequest.post(`/contract/setIdVault`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const activateContract = async (contractId: string) => {
    try {
        return await axiosScmRequest.get(`/contract/updateTokenizedContract/${contractId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const uploadBulkContractFile = async (body: any, templateId: string) => {
    try {
        return await axiosScmRequest.post(`/masive/upload/${templateId}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const downloadBulkContractFile = async (templateId: string) => {
    try {
        return await axiosScmRequest.get(`/masive/createFileB64/${templateId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const transformNumberWithPatten = async (body: any) => {
    try {
        return await axiosGeneralRequest.post(`/util/transformNumbertoStringWithPattern`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const transformDateWithPatten = async (body: any) => {
    try {
        return await axiosGeneralRequest.post(`/util/transformStringToStringWithPatternDateES`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    listContractTypes,
    getContract,
    createContract,
    updateContract,
    deleteContract,
    createContractType,
    deleteContractType,
    listContracts,
    getTraceability,
    getTraceabilityPDF,
    setIdVault,
    activateContract,
    uploadBulkContractFile,
    downloadBulkContractFile,
    transformNumberWithPatten,
    transformDateWithPatten,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
