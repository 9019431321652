import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { useBoolean } from "@chakra-ui/react";
import { useState } from "react";
import useCustomToast from "../../../../hooks/useCustomToast";
import CommonModal from "../../CommonModal";
import FileDropzone from "../../FileDropzone";
import { UploadBiddingReqDocModalData } from "../../../../models/Modals.models";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useBiddingState from "src/store/biddings/biddingState";
import { blobToBase64, getMimeType } from "src/lib/utils";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: UploadBiddingReqDocModalData;
};

const UploadBiddingDocModal = ({ isOpen, onClose, data }: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const uploadDocument = useBiddingState((s) => s.addBiddingRequirementDocument);
    const uploadDocumentToSession = useBiddingState((s) => s.addDocumentToSession);
    const uploadAnnex = useBiddingState((s) => s.addBiddingRequirementAnnex);
    const [description, setDescription] = useState("");
    const [invalidDesc, setInvalidDesc] = useBoolean(false);
    const [file, setFile] = useState<File | null>(null);
    const validDocExtensions = data.requirement?.documentType?.map((d) => d.extension);

    async function handleCreate() {
        if (description?.length > 120) {
            return setInvalidDesc.on();
        } else {
            setInvalidDesc.off();
        }

        if (!file) {
            return toast.error(t("mustUploadFile"));
        }
        const fileExtension = file.name.split(".").reverse()[0]?.toLowerCase() ?? "";
        const documentTypeId = data.requirement?.documentType?.find((d) => d.extension?.toLowerCase() === fileExtension.toLowerCase())?.id;

        if (data.type === "document" && !validDocExtensions.includes(fileExtension)) return toast.error(t("invalidFileType"));

        const body = {
            year: data.year,
            month: data.month,
            b64File: (await blobToBase64(file)).split("base64,")[1],
            description,
            documentTypeId,
        };

        const formData = new FormData();
        formData.append("file", file);

        const blobBody = {
            file: formData,
        };

        const res = await uploadDocumentToSession(blobBody);

        const bodyWithNoBase64 = {
            year: data.year,
            month: data.month,
            b64File: res?.response,
            description,
            documentTypeId,
        };

        if (data.type === "annex") body["name"] = file.name;
        if (data.type === "annex") bodyWithNoBase64["name"] = file.name;

        if (res.uploaded) {
            const docRes =
                data.type === "document"
                    ? await uploadDocument(bodyWithNoBase64, data?.biddingId, data?.requirement?.uuid)
                    : await uploadAnnex(bodyWithNoBase64, data?.biddingId, data?.requirement?.uuid);

            if (docRes.uploaded) {
                toast.success(t("uploadedFileSuccessfully"));
            } else {
                if (docRes.fileExists) {
                    return toast.error(t("fileAlreadyExists"));
                } else {
                    toast.error(t("uploadingFileError"));
                }
            }

            data.afterUpload();
            onClose();
        } else {
            if (res.fileExists) {
                return toast.error(t("fileAlreadyExists"));
            } else {
                toast.error(t("uploadingFileError"));
            }
        }
    }

    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div>
                    <h4 className="text-center text-gray-600 font-semibold">SUBIR {data.type === "annex" ? "ANEXO" : "DOCUMENTO"}</h4>
                    <h2 className="title-3 text-center">{data?.requirement?.name}</h2>
                </div>

                <form className="w-10/12 flex flex-col items-center gap-4" onSubmit={(e) => e.preventDefault()}>
                    <FormControl isInvalid={invalidDesc}>
                        <label htmlFor="file_description" className="text-sm leading-8">
                            {t("description")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="file_description"
                                type="text"
                                rounded="none"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </InputGroup>
                        {invalidDesc && <span className="text-xs italic text-red-400">{t("max120Chars")}</span>}
                    </FormControl>

                    <div className="mt-6 w-full">
                        <FileDropzone
                            onDropFile={(file) => setFile(file)}
                            accept={data.type === "annex" ? "*" : validDocExtensions?.map((e) => getMimeType(e)).join(",")}
                        />
                        {/* <span className="text-xs italic text-red-400">{t("onlyPdfFiles")}*</span> */}
                    </div>

                    <div className="mt-8">
                        <Button
                            type="button"
                            rounded="full"
                            size="md"
                            fontWeight="medium"
                            px={"2rem"}
                            isLoading={false}
                            onClick={handleCreate}
                        >
                            {t("upload")} {data.type === "annex" ? "anexo" : "documento"}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

export default UploadBiddingDocModal;
