import { useNavigate } from "react-router";
import BoxButton from "../../components/common/BoxButton";
import MainLayout from "../../components/layouts/MainLayout";
import ContractsTable from "../../components/contracts/ContractsTable";
import useContractState from "../../store/contractState";
import { useEffect } from "react";
import useCustomToast from "../../hooks/useCustomToast";
import AddNewOutlineIcon from "../../components/icons/AddNewOutlineIcon";
import AddNewFilledIcon from "../../components/icons/AddNewFilledIcon";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import NewContractIcon from "src/components/icons/iconsV2/NewContractIcon";
import DottedSubtitle from "src/components/common/DottedSubtitle";
import useComponetState from "src/store/components/componentState";

const TemplatesPage = () => {
    const navigate = useNavigate();
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const showBtnUi = useComponetState((state) => state.uiBtnProcessOptions);
    const getAllBtnProcess = useComponetState((s) => s.getAllBtnProcess);

    const {
        getContractList,
        contracts: { data, error },
    } = useContractState();

    useEffect(() => {
        getContractList();
        getAllBtnProcess();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(t("listingContractsError"));
        }
    }, [error]);

    return (
        <MainLayout>
            <main className="w-full p-6">
                <h2 className="title-3">{t("contracts")}</h2>
                {!showBtnUi.showBtnCreateNewDocument ? ("") : (
                    <>
                        <DottedSubtitle text={t("createNewContract")} />
                        <div className="flex space-x-6 mt-4">
                            <BoxButton
                                icon={<NewContractIcon boxSize={45} />}
                                //hoverIcon={<AddNewFilledIcon boxSize={70} />}
                                text={t("generateNewContract")}
                                onClick={() => navigate("create")}
                            />
                        </div>
                        </>
                    )}
                <div className="mt-4">
                    <ContractsTable contracts={data} />
                </div>
            </main>
        </MainLayout>
    );
};

export default TemplatesPage;
