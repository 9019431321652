import CommonModal from "../../CommonModal";
import SearchInput from "../../table/SearchInput";
import CommonTh from "../../table/CommonTh";
import { Button, Checkbox, Progress } from "@chakra-ui/react";
import useDataGroupsState from "../../../../store/dataGroupsState";
import { useEffect, useMemo, useState } from "react";
import usePagination from "../../../../hooks/usePagination";
import TablePaginator from "../../table/TablePaginator";
import { DataGroup } from "../../../../models/DataGroup.models";
import { formatDate } from "../../../../lib/utils";
import useTemplateState from "../../../../store/templatesState";
import { SelectDataGroupModalData } from "../../../../models/Modals.models";
import { Template, TemplateDataGroup } from "../../../../models/Template.models";
import LoadingBar from "../../LoadingBar";
import { GENERIC_DATAGROUP_TYPE } from "../../../../lib/constants";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const TableRow = ({
    selected,
    dataGroup,
    onSelect,
}: {
    selected: boolean;
    dataGroup: DataGroup;
    onSelect: (dg: DataGroup, selected: boolean) => any;
}) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    function handleChange(e: any) {
        onSelect(dataGroup, e.target.checked);
    }
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="w-4 p-2">
                <div className="flex items-center">
                    <Checkbox defaultChecked={selected} onChange={handleChange} />
                </div>
            </td>
            <td className="p-2 py-3">{dataGroup.name}</td>
            <td className="p-2 py-3">{dataGroup.userName}</td>
            <td className="p-2 py-3">{formatDate(new Date(dataGroup?.createdDate || "01-01-2022"))}</td>
            <td className="p-2 py-3 capitalize">{dataGroup.status === GENERIC_DATAGROUP_TYPE ? t("generic") : dataGroup.status}</td>
            <td className="p-2 py-3">{formatDate(new Date(dataGroup?.lastModifiedDate || "01-01-2022"))}</td>
        </tr>
    );
};

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: SelectDataGroupModalData;
};

const DataGroupSelectModal = ({ isOpen, onClose, data }: Props) => {
    const {
        getDataGroups,
        dataGroups: { data: dataGroups, loading },
    } = useDataGroupsState();

    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const [searchQuery, setSearchQuery] = useState("");
    const filteredDataGroups = useMemo(() => dataGroupFilter(data.type, dataGroups, searchQuery), [dataGroups, data, searchQuery]);

    const { currentPage, currentResutls, maxPages, next, prev } = usePagination({ data: filteredDataGroups, itemsPerPage: 6 });

    const currentTemplate = useTemplateState((s) => (data?.case === "new" ? s.currentNewTemplate : s.selectedTemplate));
    const setCurrentTemplate = useTemplateState((s) => (data?.case === "new" ? s.setCurrentNewTemplate : s.setSelectedTemplate));

    const [selectedDataGroups, setDataGroups] = useState<TemplateDataGroup[]>(currentTemplate?.dataGroup);
    const selectedDataGroupsNames = useMemo(() => selectedDataGroups.map((dg) => dg.idName), [selectedDataGroups]);

    function handleSelect(dataGroup: DataGroup, selected: boolean) {
        if (selected) {
            const dgNotExists = !selectedDataGroups.find((dg) => dg.idName === dataGroup.idName);
            if (dgNotExists) {
                setDataGroups((prevDgs) => [...prevDgs, dataGroup]);
            }
        } else {
            setDataGroups((prevDgs) => prevDgs.filter((dg) => dg.idName !== dataGroup.idName));
        }
    }

    function handleSubmit() {
        setCurrentTemplate({ ...currentTemplate, dataGroup: selectedDataGroups } as Template);
        onClose();
    }

    useEffect(() => {
        getDataGroups(false);
    }, []);

    return (
        <CommonModal size="4xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col min-h-[60vh]">
                <div className="w-full flex items-end">
                    <div>
                        <h2 className="title-3">{t("myDataGroups")}</h2>
                        <h3 className="text-dark">{t("selectOneOrManyDataGroups")}</h3>
                    </div>
                    <SearchInput onChange={(query) => setSearchQuery(query)} />
                </div>
                <div className="px-2 mt-4">
                    <LoadingBar isLoading={loading} />
                    <table className="w-full text-sm text-left text-dark-2">
                        <thead className="text-base text-brand-500 font-medium border-b">
                            <tr>
                                <th scope="col" className="p-2"></th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("name")} />
                                </th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("author")} />
                                </th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("date")} />
                                </th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("type")} />
                                </th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("lastVersion")} />
                                </th>
                            </tr>
                            <tr></tr>
                        </thead>
                        <tbody>
                            {currentResutls?.map((item, idx) => (
                                <TableRow
                                    key={item.id}
                                    dataGroup={item}
                                    selected={selectedDataGroupsNames.includes(item.idName)}
                                    onSelect={handleSelect}
                                />
                            ))}
                        </tbody>
                    </table>
                    {filteredDataGroups?.length ? (
                        <div className="w-full flex justify-end">
                            <TablePaginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} />
                        </div>
                    ) : (
                        <div className="w-full text-center p-2 text-dark">{t("notElementsFound")}</div>
                    )}
                </div>
                <div className="grow"></div>
                <div className="text-right mt-6">
                    <Button rounded="full" size="md" fontWeight="medium" onClick={handleSubmit}>
                        {t("saveChanges")}
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default DataGroupSelectModal;

//filtra datagroups por tipo seleccionado y tipos genéricos
function dataGroupFilter(type: string, dataGroups: DataGroup[], searchQuery: string) {
    let tempDgs = dataGroups;

    if (type) {
        tempDgs = dataGroups.filter((dg) => dg.status?.toLowerCase() === type?.toLocaleLowerCase() || dg.status === GENERIC_DATAGROUP_TYPE);
    }

    if (searchQuery) {
        const lowerQuery = searchQuery.toLocaleLowerCase();
        tempDgs = tempDgs.filter((dg) => dg.name?.toLocaleLowerCase().includes(lowerQuery));
    }

    return tempDgs;
}
