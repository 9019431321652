import CommonModal from "../CommonModal";
import { CircularProgress } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const LoaderModal = ({ isOpen, onClose }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <CommonModal size="xs" isOpen={isOpen} onClose={onClose} closeButton={false} closeOnOverlayClick={false}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <CircularProgress isIndeterminate size="4rem" color="brand.500" />
                <span className="text-xl text-dark">{t("loading")}</span>
            </div>
        </CommonModal>
    );
};

export default LoaderModal;
