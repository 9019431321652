import { Icon } from "@chakra-ui/react";

const NewTemplateIcon = (props) => {
    return (
        <Icon viewBox="0 0 93 110" {...props}>
            <path
                fill="#0D3BB1"
                d="M70.3 11.38H56.658A5.682 5.682 0 0 0 51.3 7.585h-3.99a9.517 9.517 0 0 0-2.584-4.817A9.518 9.518 0 0 0 38.019 0c-4.579 0-8.417 3.262-9.31 7.586h-3.99c-2.47 0-4.56 1.593-5.358 3.793H5.7c-3.135 0-5.7 2.56-5.7 5.69v87.241c0 3.13 2.565 5.69 5.7 5.69h64.6c3.135 0 5.7-2.56 5.7-5.69V17.069c0-3.13-2.565-5.69-5.7-5.69ZM19.342 22.758a5.682 5.682 0 0 0 5.358 3.793h26.6c2.47 0 4.56-1.593 5.358-3.793H62.7c1.045 0 1.9.853 1.9 1.896v72.07a1.904 1.904 0 0 1-1.9 1.896H13.3c-1.045 0-1.9-.854-1.9-1.897V24.655c0-1.043.855-1.896 1.9-1.896h6.042Zm5.358-11.38h5.7c1.045 0 1.9-.853 1.9-1.896 0-3.13 2.565-5.69 5.7-5.69 1.52 0 2.945.588 4.028 1.65a5.709 5.709 0 0 1 1.672 4.04c0 1.043.855 1.896 1.9 1.896h5.7c1.045 0 1.9.854 1.9 1.897v7.586a1.904 1.904 0 0 1-1.9 1.897H24.7c-1.045 0-1.9-.854-1.9-1.897v-7.586c0-1.043.855-1.897 1.9-1.897Zm47.5 92.931a1.904 1.904 0 0 1-1.9 1.897H5.7c-1.045 0-1.9-.854-1.9-1.897V17.069c0-1.043.855-1.897 1.9-1.897H19v3.793h-5.7c-3.135 0-5.7 2.56-5.7 5.69v72.07c0 3.128 2.565 5.689 5.7 5.689h49.4c3.135 0 5.7-2.56 5.7-5.69V24.655c0-3.13-2.565-5.69-5.7-5.69H57v-3.793h13.3c1.045 0 1.9.854 1.9 1.897v87.241Z"
            />
            <path
                fill="#0D3BB1"
                d="M22.61 41.724h30.78c.95 0 1.71-.854 1.71-1.897 0-1.043-.76-1.896-1.71-1.896H22.61c-.95 0-1.71.853-1.71 1.896s.76 1.897 1.71 1.897ZM22.61 56.27h30.78c.95 0 1.71-.853 1.71-1.896s-.76-1.897-1.71-1.897H22.61c-.95 0-1.71.854-1.71 1.897 0 1.043.76 1.897 1.71 1.897ZM22.61 70.798h30.78c.95 0 1.71-.853 1.71-1.897 0-1.043-.76-1.896-1.71-1.896H22.61c-.95 0-1.71.853-1.71 1.896 0 1.044.76 1.897 1.71 1.897ZM22.61 85.345h30.78c.95 0 1.71-.854 1.71-1.897 0-1.043-.76-1.896-1.71-1.896H22.61c-.95 0-1.71.853-1.71 1.896s.76 1.897 1.71 1.897ZM74 42c-10.484 0-19 8.516-19 19s8.516 19 19 19 19-8.516 19-19-8.516-19-19-19Zm0 34.546c-8.567 0-15.545-6.979-15.545-15.546S65.433 45.455 74 45.455 89.546 52.433 89.546 61 82.567 76.546 74 76.546Z"
            />
            <path
                fill="#F4F4F4"
                d="M74 45.454c-8.567 0-15.545 6.978-15.545 15.546 0 8.567 6.978 15.545 15.545 15.545S89.546 69.567 89.546 61c0-8.568-6.979-15.546-15.546-15.546Zm8.118 17.204h-6.46v6.46c0 .863-.743 1.554-1.658 1.554-.915 0-1.658-.69-1.658-1.554v-6.46h-6.46c-.864 0-1.555-.743-1.555-1.658 0-.916.691-1.659 1.555-1.659h6.46v-6.46c0-.863.743-1.554 1.658-1.554.915 0 1.658.69 1.658 1.554v6.46h6.46c.864 0 1.555.743 1.555 1.659 0 .915-.691 1.658-1.555 1.658Z"
            />
            <path
                fill="#0D3BB1"
                d="M83.673 61c0 .915-.691 1.658-1.555 1.658h-6.46v6.46c0 .864-.743 1.555-1.658 1.555-.915 0-1.658-.691-1.658-1.555v-6.46h-6.46c-.864 0-1.555-.743-1.555-1.658 0-.916.691-1.658 1.555-1.658h6.46v-6.46c0-.864.743-1.555 1.658-1.555.915 0 1.658.691 1.658 1.555v6.46h6.46c.864 0 1.555.742 1.555 1.658Z"
            />
        </Icon>
    );
};

export default NewTemplateIcon;
