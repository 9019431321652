import { Button, ButtonProps } from "@chakra-ui/react";

const LinkButton = ({ className, ...props }: ButtonProps) => {
    return (
        <Button
            fontSize={props.fontSize ?? "sm"}
            fontWeight="light"
            colorScheme={props.colorScheme ?? "brand"}
            variant="link"
            className={"italic outline-none focus:shadow-none " + className}
            {...props}
        >
            {props.children}
        </Button>
    );
};

export default LinkButton;
