import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { bytesToMegabytes, formatDate } from "../../lib/utils";
import useVaultState from "../../store/vaultState";
import AccordionItemBrand from "../common/AccordionItemBrand";

const VaultDetails = () => {
    const { selectedFile, selectedFileInfo } = useVaultState();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="max-w-[300px]">
            {/* get document info */}
            {selectedFile && selectedFile.fileType === "document" && (
                <div className="text-sm space-y-2 p-2">
                    <p>
                        <b>{t("date")}:</b> {formatDate(selectedFileInfo?.createdAt, true)}
                    </p>
                    <p>
                        <b>{t("name")}:</b> {selectedFile?.name}
                    </p>
                    <p>
                        <b>{t("extension")}:</b> {selectedFileInfo?.extension?.replace(".", "")?.toUpperCase() || "---"}
                    </p>

                    <p>
                        <b>IPFS hash:</b> {selectedFileInfo?.ipfsHash}
                    </p>
                    <p>
                        <b>{t("size")}:</b> {bytesToMegabytes(selectedFileInfo?.size)} MB
                    </p>

                    <p>
                        <b>{t("user")}:</b> {selectedFileInfo?.user?.username || "---"}
                    </p>
                </div>
            )}

            {selectedFile && selectedFile.fileType === "folder" && (
                <div className="text-sm space-y-2 p-2">
                    <p>
                        <b>{t("date")}:</b> {formatDate((selectedFileInfo as any)?.createdDate, true)}
                    </p>
                    <p>
                        <b>{t("name")}:</b> {selectedFileInfo?.name}
                    </p>
                    <p>
                        <b>{t("user")}:</b> {selectedFileInfo?.createdBy || "---"}
                    </p>
                </div>
            )}

            {!selectedFile && (
                <div className="text-sm space-y-1 p-2">
                    <p>{t("noElementSelected")}</p>
                </div>
            )}
        </div>
    );
};

export default VaultDetails;
