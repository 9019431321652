import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { FormErrorMessage, Select } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import useCustomToast from "../../../../hooks/useCustomToast";
import CommonModal from "../../CommonModal";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useBiddingTypesState from "src/store/biddings/biddingTypesState";
import useBiddingState from "src/store/biddings/biddingState";
import useAdministrationState from "src/store/administrationState";
import useGlobalState from "src/store/globalState";
import PowerBiImg from "../../../../assets/images/PowerBi.png";
type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

interface FormFields {
    name: string;
}

const BiddingTypeUrlPowerBI = ({ isOpen, onClose, data }: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const {
        getBiddingTypesByCompany,
        biddingTypesByCompany: { data: biddingTypes },
    } = useBiddingTypesState();

    const { createBidding, getBiddings } = useBiddingState();
    const { getAssociatedCompanies, associatedCompanies } = useAdministrationState();
    const userCompany = useGlobalState((s) => s.userCompanyInfo);
    const formik = useFormik<FormFields>({
        initialValues: {
            name: data.powerBiUrl,
        },
        onSubmit: handleCreate,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
        }),
    });

    async function handleCreate(values: FormFields) {
        data.addUrlPowerBi(values.name);
        onClose();
    }

    useEffect(() => {
        getBiddingTypesByCompany().catch((err) => {
            toast.error(t("listingBiddingTypesError"));
        });
        getAssociatedCompanies();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CommonModal size="4xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-right space-y-6 py-4">
                <h2 className="title-3">{"Caracteristicas Adicionales"}</h2> {/*t("newProcess") */}
                <p>{t("aditionalFeatures")}</p>
                <form
                    className="w-full flex flex-wrap justify-between items-center gap-2 max-h-[75vh] overflow-y-auto px-2"
                    onSubmit={formik.handleSubmit}
                >
                    <FormControl isInvalid={!!formik.errors.name}>
                        <label htmlFor="name" className="text-sm leading-8">
                            {"URL POWER BI"}
                        </label>

                        <InputGroup borderColor="gray.400">
                            <img src={PowerBiImg} style={{ width: "70px", height: "70px", paddingBottom: "1.6rem" }}></img>

                            <Input
                                id="name"
                                name="name"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>
                    <div className="mt-8 pb-2 w-full text-center">
                        <Button type="submit" rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                            {t("add")}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

export default BiddingTypeUrlPowerBI;
