import { useBoolean } from "@chakra-ui/react";

type Props = {
    label: string;
    onSelect?: (selected: boolean) => any;
    selected?: boolean;
    size?: "sm" | "md";
    toggable?: boolean;
};

const ToggableButton = ({ label, onSelect = () => {}, selected = false, size = "md", toggable = true }: Props) => {
    const [active, setActive] = useBoolean(selected);
    const handleClick = () => {
        if(toggable) setActive.toggle()
        onSelect(active);
    };
    return (
        <button
            onClick={handleClick}
            className={`${
                size === "md" ? "py-1 px-6 text-base" : "py-1 px-4 text-sm"
            }  rounded-full border transition-colors duration-200 ${
                active ? "bg-brand-100/40 text-brand-500  border-transparent" : "bg-white text-dark  border-gray-700"
            }`}
        >
            {label}
        </button>
    );
};

export default ToggableButton;
