import axios from "axios";
import { ContractRevisionCreateBody, SendReviewBody } from "../models/Contract.models";
import { getAuthHeader } from "../lib/utils";

export const axiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/revision`,
    timeout: 10000,
});

const axiosRequestToReview = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1`,
    timeout: 10000,
});

const listUsers = async (idCompany: string) => {
    try {
        return await axiosRequest.get(`/users/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createRevision = async (body: ContractRevisionCreateBody) => {
    try {
        return await axiosRequest.put(`/`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getRevisionByContract = async (idContract: string) => {
    try {
        return await axiosRequest.post(
            `/`,
            { idContract },
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

const cancelRevision = async (contractId: string) => {
    try {
        return await axiosRequest.get(`/cancel/${contractId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getContractForRevision = async (idUser: string, idContract: string) => {
    try {
        return await axiosRequest.get(`/contrato/${idUser}/${idContract}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getContractForRevisionById = async (reviewId: string) => {
    try {
        return await axiosRequestToReview.get(`/review/${reviewId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDocumentForRevisionById = async (reviewId: string) => {
    try {
        return await axiosRequestToReview.get(`/review/base64/reviewId/${reviewId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const sendReview = async (body: SendReviewBody) => {
    try {
        return await axiosRequest.patch(`/update/user/reviewer`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const patchNewReview = async (id: string, body: SendReviewBody) => {
    try {
        return await axiosRequestToReview.patch(`/review/byReviewer/${id}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const sendRevisionReminder = async (idUser: string, idVault: string, idContract: string) => {
    try {
        return await axiosRequest.get(`/recordatorio/${idUser}/${idVault}/${idContract}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
}

export default {
    listUsers,
    createRevision,
    getRevisionByContract,
    cancelRevision,
    getContractForRevision,
    sendReview,
    sendRevisionReminder,
    getContractForRevisionById,
    patchNewReview,
    getDocumentForRevisionById
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
