import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

export const rolesScmRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/rol`,
    timeout: 20000,
});

const createRole = async (body: any) => {
    try {
        return await rolesScmRequest.put(``, body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getRolesByCompany = async (idCompany: string) => {
    try {
        return await rolesScmRequest.get(`/idCompany/${idCompany}`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteRoleById = async (id: string) => {
    try {
        return await rolesScmRequest.delete(`/id/${id}`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateRole = async (body: any) => {
    try {
        return await rolesScmRequest.patch(``, body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    createRole,
    getRolesByCompany,
    deleteRoleById,
    updateRole,
};
