import {
    Accordion,
    Badge,
    Button,
    CircularProgress,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Input,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    useBoolean,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState, Suspense, lazy, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import GoBackLinkButton from "../../components/common/buttons/GoBackLinkButton";
import MainLayout from "../../components/layouts/MainLayout";
import InVaultActions from "../../components/contracts/actions-buttons-groups/InVaultActions";
import PendingSignActions from "../../components/contracts/actions-buttons-groups/PendingSignActions";
import TraceabilityAccordionItems from "../../components/contracts/contract-traceability/TraceabilityAccordionItems";
import WorkSheetsAccordionItems from "../../components/worksheets/WorkSheetsAccordionItems";
import SignaturesAccordionItems from "../../components/contracts/contract-signatures/SignaturesAccordionItems";
import SignaturesView from "../../components/contracts/contract-signatures/SignaturesView";
import useCustomToast from "../../hooks/useCustomToast";
import usePdfPreview from "../../hooks/usePdfPreview";
import { ContractSigner } from "../../models/Signatures.models";
import pdfSignatureService from "src/services/pdfSignature.service";
import useContractRevisionState from "../../store/contractRevisionState";
import useContractSignaturesState from "../../store/contractSignatureState";
import useContractState from "../../store/contractState";
import useDynmicTableState from "../../store/dinamicTables";
import useModalState from "../../store/modalsState";
import { formatDate, getContractStepByStatus, getTitleByStatus, isObjectId } from "../../lib/utils";
import SignedActions from "../../components/contracts/actions-buttons-groups/SignedActions";
import { AiOutlineFileAdd } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import vaultService from "src/services/vault.service";
import BiddingPdfViewerCustom from "../../components/bidding/BiddingPdfViewerCustom";
import usePdfSignaturesState from "src/store/pdfSignatureState";
import WorkSheet from "./WorkSheet";
import LinkButton from "src/components/common/buttons/LinkButton";
import { BiChevronLeft } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import React from "react";
const LazyPdfViewer = lazy(() => import("../../components/common/PdfViewer"));

//const FilterInput = ({ onChangeInput, disabled = false, valueInput, label,clearValue }: { onChangeInput?: any; disabled?: boolean, valueInput?: any, label?: string ,clearValue?:boolean}) => {

const ShowEditPdfPage = () => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const { id, biddingId, uuid } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    // console.log("dinamicID", uuid);
    const {
        setPdfPreviewModal,
        setLoaderModal,
        setContractRevisionModal,
        setTokenIlustrationModal,
        setContractSignersSelectModal,
        setUploadContractAnnexModal,
        setRestrictedActionModal,
    } = useModalState();
    const setSelectedSigners = useContractSignaturesState((s) => s.setSelectedSigners);
    const { selectedContract, getContract, setSelectedContract, updateContract, uiOptions, saveOnVault, createFolder, createToken } =
        useContractState();
    const { selectedBiddingProfile, selectedBiddingProfile2, getDynamicTable, getDynamicTableJSON } = useDynmicTableState();
    const { cancelRevision, currentReviewerEditionChanges, setCurrentReviewerEditionChanges } = useContractRevisionState();
    const { generatePdfPreviewUrl, pdfBlobUrl, generatePdfBlob, generatePdfVaultUrl } = usePdfPreview();
    const [isSaving, setIsSaving] = useBoolean(false);
    const [isSavingOnVault, setIsSavingOnVault] = useBoolean(false);
    const [showSignaturesView, setShowSignaturesView] = useBoolean(false);
    const [initialDataGroupsSnapshot, setInitialDataGroupsSnapshot] = useState<string>(null);

    // new state pdf contract type
    const [documentName, setDocumentName] = useState<string | undefined>(t("undefined"));
    const [pdfUrlBlob, setPdfUrlBlob] = useState<string>(null);
    const [loading, setLoading] = useBoolean(true);
    const { infoContract, statusContract, isSignature, setIsSignature } = usePdfSignaturesState();
    const [csvContent, setCsvContent] = useState<string[][]>([]);
    const [comment, setComment] = useState({
        row: "",
        priority: "high",
        text: "",
    });
    const [commentsArray, setCommentsArray] = useState([]);
    const [selectedComment, setSelectedComment] = useState({
        row: "",
        priority: "",
        text: "",
    });
    const [error, setError] = useState("");
    const [range, setRange] = useState<any>();
    const [tableJSON, setTableJSON] = useState({});
    // console.log(tableJSON);
    // console.log("selected", selectedBiddingProfile);
    // console.log("selected2", selectedBiddingProfile2);
    console.log("array", commentsArray);
    // console.log("selected", selectedComment);
    // console.log("rango", range);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target?.result) {
                    const content = e.target.result.toString();
                    const rows = content.split("\n").map((row) => row.split(","));
                    setCsvContent(rows);
                }
            };
            reader.readAsText(file);
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setComment({ ...comment, [id]: value });
    };

    const handleAddComment = () => {
        if (comment?.row !== "" && comment.text !== "") {
            setCommentsArray([...commentsArray, comment]);
            setComment({
                row: "",
                priority: "high",
                text: "",
            });
            setError("");
        } else {
            setError("Por favor llene todos los campos.");
        }
    };

    async function getVaultDoc(docFileId: string) {
        setLoading.on();
        try {
            const res = await vaultService.getDocumentFromVault(docFileId);
            const b64String = res.data.file;
            const extension = res.data.name.split(".").reverse()[0];
            if (extension.toLowerCase() === "pdf") {
                setPdfUrlBlob("data:application/pdf;base64," + b64String);
                setDocumentName(res.data.name);
            }
        } catch {
            toast.error("Ocurrió un error al descargar el documento");
        } finally {
            setLoading.off();
        }
    }
    function handleRevision() {
        const hasChanges = JSON.stringify(selectedContract?.template?.dataGroup) !== initialDataGroupsSnapshot;
        if (hasChanges) {
            toast.error(t("saveChangesBeforeRevision"), t("warning"));
            return;
        }
        setContractRevisionModal({
            show: true,
            data: { contractId: id, afterSubmit: () => getContract(id), nameContract: selectedContract?.name },
        });
    }

    async function handleCancelRevision() {
        const canceled = await cancelRevision(id);
        if (canceled) {
            getContract(id);
            toast.success(t("revisionCanceled"));
        } else {
            toast.error(t("cancelingRevisionError"));
        }
    }

    async function handleSign() {
        setContractSignersSelectModal({
            show: true,
            data: {
                afterSubmit: (signers: ContractSigner[]) => {
                    setSelectedSigners(signers);
                    if (pdfBlobUrl) return setShowSignaturesView.on();
                    setLoaderModal(true);
                    generatePdfVaultUrl(id)
                        .then((url) => {
                            setShowSignaturesView.on();
                        })
                        .finally(() => setLoaderModal(false));
                },
            },
        });
    }

    async function handleOnSendSignatures(stickersValue: any) {
        const STICKER_W = 130;
        const STICKER_H = 30;
        const QR = [{ height: "80", pages: "all", width: "80", x: "0", y: "0" }];

        let stickers = stickersValue.map((sv: any) => {
            return {
                id: sv.userId,
                authority: "Vinculada a Correo Electronico por Liga",
                page: parseInt(sv.page || 1) - 1,
                rect: {
                    lx: Math.round(sv.convertedCoords[0]),
                    tx: Math.round(sv.convertedCoords[0]) + STICKER_W,
                    ty: Math.round(sv.convertedCoords[1]) + STICKER_H,
                    ly: Math.round(sv.convertedCoords[1]) - 5,
                },
            };
        });

        const body = {
            biddingId: biddingId,
            documentUuid: uuid,
            reqSignatureCreateDto: {
                documentId: id,
                qr: QR,
                signers: stickers,
            },
        };
        setLoaderModal(true);
        try {
            const res = await pdfSignatureService.sendSignatures(body);
            let codigo = res.data?.mensaje;
            let msg = res.data?.codigo;

            if (res?.data?.codigo === 3 && res?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
                setRestrictedActionModal({
                    show: true,
                    data: {
                        text1: "AVISO",
                        text2: t("createSignatureProcess"),
                        text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                        redirectHome: false,
                        onClose: async () => {
                            return { show: false };
                        },
                    },
                });
            } else {
                if (res.status === 200) {
                    //setSelectedContract({ ...selectedContract!, status: "pendiente de firmas" });
                    setIsSignature(true);
                    toast.success(t("signaturesSendedSuccessfully"));
                    setShowSignaturesView.off();
                } else {
                    throw new Error();
                }
            }
        } catch (error) {
            toast.error(t("sendingSignaturesError"));
        } finally {
            setLoaderModal(false);
        }
    }

    async function handleCancelSignatures() {
        setLoaderModal(true);
        try {
            const res: any = await pdfSignatureService.cancelSignatures(biddingId, uuid);
            if (res.status === 200) {
                setIsSignature(false);
                //setSelectedContract({ ...selectedContract!, status: "en boveda" });
                toast.success(t("canceledSignaturesSuccessfully"));
            } else {
                throw new Error();
            }
        } catch (error) {
            toast.error(t("cancelingSignaturesError"));
        } finally {
            setLoaderModal(false);
        }
    }

    useEffect(() => {
        if (id) {
            getVaultDoc(id);
        } else {
            toast.error("ID de documento no existe");
        }
    }, [id]);

    function cleanFileName(fileName) {
        const cleanedFileName = fileName.replace(/((\.pdf){1,}){1,}\.pdf$/, ".pdf");

        const truncatedFileName = cleanedFileName.substring(0, 20);

        return truncatedFileName;
    }

    {
        /** isExcel revisar temporal si se debe mostrar el excel */
    }

    const handleSetRowColor = (priority) => {
        if (priority === "high") {
            return "red";
        }
        if (priority === "medium") {
            return "orange";
        }
        if (priority === "low") {
            return "yellow";
        }
    };

    const handleSelectedComment = (comment) => {
        setSelectedComment(comment);
        onClose();
    };

    const commentStyles = (comment) => {
        if (
            selectedComment?.row === comment?.row &&
            selectedComment?.priority === comment?.priority &&
            selectedComment?.text === comment?.text
        ) {
            return "border rounded-md p-2 cursor-pointer mb-2 bg-[#0327e2] text-white border-[#0327e2]";
        }
        return "border rounded-md p-2 cursor-pointer mb-2";
    };

    const hanleResetColors = () => {
        onClose();
        setSelectedComment({
            row: "",
            priority: "",
            text: "",
        });
    };

    useEffect(() => {
        if (range && range.range && range.range.from && range.range.to) {
            const selectedRange = range.range;
            const separateRows = range.separateRows;

            const selectedRows = new Set();

            const fromRow = parseInt(selectedRange.from.row) + 1;
            const toRow = parseInt(selectedRange.to.row) + 1;
            for (let i = fromRow; i <= toRow; i++) {
                selectedRows.add(i);
            }

            separateRows.forEach((row) => {
                const start = row[0];
                const end = row[2];

                for (let i = start; i <= end; i++) {
                    selectedRows.add(i + 1);
                }
            });

            const sortedRows = Array.from(selectedRows).sort((a: any, b: any) => a - b);
            const result = sortedRows.join(",");

            setComment({ ...comment, row: result });
        }
    }, [range]);

    useEffect(() => {
        if (range && range.range && range.range.from) {
            const { from } = range.range;
            const selectedRow = parseInt(from.row) + 1;

            // Verificar si el from row se encuentra en algún elemento del array
            const foundItem = commentsArray.find((item) => parseInt(item.row) === selectedRow);
            console.log(foundItem);
            if (foundItem && from.col === -1) {
                onOpen();
                // setSelectedComment(foundItem);
            }
        }
    }, [range]);

    useEffect(() => {
        getDynamicTable(uuid);
    }, []);

    useEffect(() => {
        if (selectedBiddingProfile) {
            getDynamicTableJSON(selectedBiddingProfile?.originalResourceId);
        }
    }, [selectedBiddingProfile]);

    useEffect(() => {
        if (selectedBiddingProfile2) {
            setTableJSON(selectedBiddingProfile2);
        }
    }, [selectedBiddingProfile2]);

    return (
        <MainLayout>
            <main className="w-full p-3 flex h-full">
                <Drawer isOpen={isOpen} placement="right" onClose={onClose} finalFocusRef={btnRef}>
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader className="text-[#0327e2]">Comentarios agregados:</DrawerHeader>

                        <DrawerBody>
                            {/* <Input placeholder="Type here..." /> */}

                            {commentsArray?.map((item, index) => (
                                <Stat className={commentStyles(item)} key={index} onClick={() => handleSelectedComment(item)}>
                                    <StatNumber>Fila: {item.row}</StatNumber>
                                    <Badge colorScheme={handleSetRowColor(item.priority)}>{item.priority}</Badge>
                                    <StatHelpText className="!mb-0 mt-1"> {item.text}</StatHelpText>
                                </Stat>
                            ))}
                        </DrawerBody>

                        <DrawerFooter>
                            <Button mr={3} className="!w-full" onClick={hanleResetColors}>
                                Reestablecer
                            </Button>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
                {/* ------------------------------- left panel ------------------------------- */}
                <section className="w-3/12 flex flex-col pr-4 pl-2 border-r h-full max-h-full overflow-y-auto relative">
                    {
                        <>
                            <div className="pb-4 pt-6">
                                <GoBackLinkButton />
                            </div>
                            <h2 className="title-3">{"Estatus del documento"}</h2>
                            <div className="text-sm mt-2 space-y-1">
                                <p>
                                    <b>{t("contractName")}:</b> {cleanFileName(documentName)}
                                </p>
                                <p>
                                    <b>{t("date")}:</b> {formatDate(new Date(infoContract?.createdDate || "01-01-2022"))}{" "}
                                </p>
                            </div>
                        </>
                    }
                    <div className="mt-6 pb-12">
                        <Accordion defaultIndex={[0, 1]} allowMultiple>
                            {isSignature && (
                                <SignaturesAccordionItems // REVISAR ENDPOINT BACK NO HAY PROCESO
                                    key={isSignature + "-revision"}
                                    documentId={id}
                                />
                            )}
                            {<TraceabilityAccordionItems key={"trace" + isSignature} contractId={uuid} isPDF={true} />}
                        </Accordion>
                        <Accordion defaultIndex={[0, 1]} allowMultiple>
                            {
                                <WorkSheetsAccordionItems
                                    key={"trace" + isSignature}
                                    contractId={uuid}
                                    isPDF={true}
                                    handleInputChange={handleInputChange}
                                    handleAddComment={handleAddComment}
                                    comment={comment}
                                    commentsArray={commentsArray}
                                    ref={btnRef}
                                    onClick={onOpen}
                                    error={error}
                                    range={range}
                                />
                            }
                        </Accordion>
                        {/* <Accordion defaultIndex={[0, 1]} allowMultiple>
                                {isSignature && (
                                    <SignaturesAccordionItems // REVISAR ENDPOINT BACK NO HAY PROCESO
                                        key={isSignature + "-revision"}
                                        documentId={id}
                                    />
                                )}
                                {<TraceabilityAccordionItems key={"trace" + isSignature} contractId={uuid} isPDF={true} />}
                            </Accordion>
                            <Accordion defaultIndex={[0, 1]} allowMultiple>
                                {isSignature && (
                                    <SignaturesAccordionItems // REVISAR ENDPOINT BACK NO HAY PROCESO
                                        key={isSignature + "-revision"}
                                        documentId={id}
                                    />
                                )}
                                {<TraceabilityAccordionItems key={"trace" + isSignature} contractId={uuid} isPDF={true} />}
                            </Accordion> */}
                    </div>
                    {/*uiOptions.showRevisionAccordionItems && (
                                <RevisionAccordionItems key={selectedContract?.lastModifiedDate + "-revision"} contractId={id} />
                            )*/}
                    <div className="grow"></div>
                    <>
                        {/*uiOptions.showRevisionActions && (
                            <RevisionActions onCancelRevision={handleCancelRevision} onSaveOnVault={handleSaveOnVault} />
                        )*/}
                        {/*uiOptions.IsInVault && <InVaultActions onSign={handleSign} isSaving={false} />*/}
                        {/* 
                        {!isSignature && infoContract && infoContract.signStatus !== "firmado" && (
                            <InVaultActions onSign={handleSign} isSaving={false} />
                        )}

                        {isSignature && <PendingSignActions onCancelSignatures={handleCancelSignatures} isLoading={false} />} */}
                    </>
                </section>
                {/* ------------------------------- right panel ------------------------------ */}

                <section className="w-9/12 pr-2 pl-4 h-full max-h-full overflow-y-auto">
                    <div className="w-full flex items-center justify-between py-4 border-b mb-4">
                        <LinkButton leftIcon={<BiChevronLeft size={18} />} onClick={() => navigate(-1)}>
                            {t("back")}
                        </LinkButton>
                        {/**<LinkButton
                            leftIcon={<FaEye size={18} />}
                            // onClick={handlePreview}
                        >
                            {t("preview")}
                        </LinkButton>
                        {/* <input type="file" accept=".csv" onChange={handleFileChange} /> */}
                    </div>
                    {/* {<BiddingPdfViewerCustom docVaultId={id} onDownload={setShowSignaturesView.off} onClose={setShowSignaturesView.off} />} */}
                    {selectedBiddingProfile2 && (
                        <WorkSheet
                            csvContent={selectedBiddingProfile2}
                            commentsArray={commentsArray}
                            selectedComment={selectedComment}
                            setRange={setRange}
                        />
                    )}

                    {/* <WorkSheet
                            csvContent={csvContent}
                            commentsArray={commentsArray}
                            selectedComment={selectedComment}
                            setRange={setRange}
                        /> */}
                </section>
                {/* {showSignaturesView && (
                    <SignaturesView
                        url={pdfUrlBlob}
                        documentName={"l"}
                        onSaveSigns={handleOnSendSignatures}
                        onClose={setShowSignaturesView.off}
                    />
                )} */}
            </main>
        </MainLayout>
    );
};

export default ShowEditPdfPage;
