import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import axios from 'axios';
import CommonModal from 'src/components/common/CommonModal';
import useGeneradorStatus from 'src/store/biddings/GeneradorStatus';
import useCustomToast from "src/hooks/useCustomToast";
import { confirmAlert } from 'src/store/modalsState';
import { title } from 'process';




    type Props = {
        isOpen: boolean;
        onClose: (...args: any) => any;
        data?: any;
    };
    
const GeneradorStateModal = ({ isOpen, onClose, data }: Props) => {
 // const { isOpen, onOpen, onClose } = useDisclosure();
 // const [data, setData] = useState(null);
 const {generadorStatus , getGeneradorBiddingStatus, createGeneradorBiddingStatus,reset} = useGeneradorStatus();
  const [comments, setComments] = useState('');
const toast = useCustomToast();
console.log("generadorStatus",generadorStatus)
  function handleClick() {
   // data?.onDelete()
    onClose()
}

  useEffect(() => {
    reset();
    if (isOpen) {
        getGeneradorBiddingStatus(data.biddingId)
    }
  }, [isOpen]);

  const handleApprove = () => {
    // Lógica para aprobar
    console.log('Approved with comments:', comments);
    onClose();
  };

  const handleReject = () => {
    // Lógica para rechazar
    console.log('Rejected with comments:', comments);
 
    onClose();
  };

  const handleEnableRequirement = (status) => {
    const enabled = status === "APPROVED" ? "Aprobar" : "Rechazar";
    
    // Validar si los comentarios están vacíos
    if (!comments.trim()) {
        toast.infoTop(""," Por favor ingrese un comentario antes de proceder.")
      return;
    }

    confirmAlert.show({
      title: `${enabled}`,
      msg: `¿Estás seguro de ${enabled.toLowerCase()} el generador?`,
      severity: "warning",
      onConfirm: () => onSubmitRequirement(status),
    });
  };

  const onSubmitRequirement = async (status) => {
    console.log(`El requerimiento ha sido ${status === "APPROVED" ? "aprobado" : "rechazado"} con el siguiente comentario: ${comments}`);
    // Aquí podrías realizar la llamada a la API o cualquier otra acción necesaria
    const body = {
        comment: comments,
        idBidding: data.biddingId,
        status: status
      };

    const created = await createGeneradorBiddingStatus(body);
    if (created) {
        toast.success("Estatus del generador actualizado");
        getGeneradorBiddingStatus(data.biddingId);
    } else {
        toast.error("Error al actualizar estatus del generador");
    }
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return new Intl.DateTimeFormat('es-ES', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  console.log("generadorStatus",generadorStatus)
  return (
    <>
      <CommonModal size="3xl" isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col items-center space-y-6 py-4">
      <div className="text-center">
        <h2 className="title-3">Generador</h2>
      </div>
      {generadorStatus ? (
        <Box>
          <Box border="1px solid" borderColor="gray.200" p={4} borderRadius="md">
            <Text fontWeight="bold">Información del Generador:</Text>
            <Text>{"Comentario: "}{generadorStatus.comment}</Text>
            <Text>{"Estatus: "}{generadorStatus.status === "APPROVED" ? "Aprobado" : "Rechazado"}</Text>
            <Text>
            {"Fecha de actualización: "} {formatDate(generadorStatus.lastModifiedDate)}
            </Text>          
        </Box>
        </Box>
      ) : (
        <Box>
          <Text>Podemos cambiar el estado del generador, pero es bajo su responsabilidad verificar que la información es correcta.</Text>
          <Textarea
            placeholder="Agrega comentarios aquí..."
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            mt={4}
            isRequired
          />
        </Box>
      )}
      <Box className="flex justify-center space-x-4 mt-4">
      {generadorStatus ? (
                 ""
      ) : (
        <Box>
         <Button colorScheme="green" onClick={() => handleEnableRequirement("APPROVED")}>
      Aprobar
        </Button>
        <Button colorScheme="red" onClick={() => handleEnableRequirement("REJECTED")}>
          Rechazar
        </Button>
        </Box>
      )}
    
      </Box>
    </div>
        </CommonModal>
     
    </>
  );
};

export default GeneradorStateModal;
