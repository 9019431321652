import axios from "axios";
import { Template } from "../models/Template.models";
import { blobToBase64, getAuthHeader } from "../lib/utils";

export const axiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/templates`,
    timeout: 10000,
});

export const axiosGeneralRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_GENERAL}/api/general`,
    timeout: 20000,
});

const listTemplates = async (idCompany: string) => {
    try {
        return await axiosRequest.post(
            "/returnTemplateList",
            { idCompany, idUser: "" },
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

const saveTemplate = async (body: Template) => {
    try {
        return await axiosRequest.post("/saveTemplate", body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateTemplate = async (body: Template) => {
    try {
        return await axiosRequest.post("/editTemplate", body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteTemplate = async (templateId: string) => {
    try {
        return await axiosRequest.delete(`/deleteTemplate/${templateId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getTemplateById = async (templateId: string) => {
    try {
        return await axiosRequest.get(`/returnTemplate/${templateId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const convertDocxToHtml = async (file: File) => {
    try {
        const blob = new Blob([file], { type: file.type });
        const base64: string = await blobToBase64(blob);
        return await axiosGeneralRequest.post("/util/docxToHTML", base64.split("base64,")[1], {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const saveTemplateFromDocx = async ({ template, file }: { template: Template; file: string }) => {
    try {
        return await axiosRequest.post(
            "/saveTemplateFromDocx",
            { ...template, text: file },
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    listTemplates,
    saveTemplate,
    updateTemplate,
    deleteTemplate,
    getTemplateById,
    convertDocxToHtml,
    saveTemplateFromDocx,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
