import { Accordion, Center, CircularProgress, useBoolean } from "@chakra-ui/react";
import { useEffect, useState, Suspense, lazy } from "react";
import { useNavigate, useParams } from "react-router";
import GoBackLinkButton from "../../components/common/buttons/GoBackLinkButton";
import MainLayout from "../../components/layouts/MainLayout";
import InVaultActions from "../../components/contracts/actions-buttons-groups/InVaultActions";
import PendingSignActions from "../../components/contracts/actions-buttons-groups/PendingSignActions";
import PendingRevisionActions from "src/components/contracts/actions-buttons-groups/PendingRevisionActions";
import TraceabilityAccordionItems from "../../components/contracts/contract-traceability/TraceabilityAccordionItems";
import SignaturesAccordionItems from "../../components/contracts/contract-signatures/SignaturesAccordionItems";
import SignaturesView from "../../components/contracts/contract-signatures/SignaturesView";
import useCustomToast from "../../hooks/useCustomToast";
import usePdfPreview from "../../hooks/usePdfPreview";
import { ContractSigner } from "../../models/Signatures.models";
import pdfSignatureService from "src/services/pdfSignature.service";
import useContractRevisionState from "../../store/contractRevisionState";
import useContractSignaturesState from "../../store/contractSignatureState";
import useContractState from "../../store/contractState";
import useModalState from "../../store/modalsState";
import { formatDate, getContractStepByStatus, getTitleByStatus, isObjectId } from "../../lib/utils";
import SignedActions from "../../components/contracts/actions-buttons-groups/SignedActions";
import { AiOutlineFileAdd } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import vaultService from "src/services/vault.service";
import BiddingPdfViewerCustom from "../../components/bidding/BiddingPdfViewerCustom";
import usePdfSignaturesState from "src/store/pdfSignatureState";
import useDocumentRevisionState from "src/store/documentRevisionState";
import useBiddingState from "src/store/biddings/biddingState";
import RevisionActions from "src/components/contracts/actions-buttons-groups/RevisionActions";
import RevisionDocumentActions from "src/components/contracts/actions-buttons-groups/RevisionDocumentActions";
import { Box, Button, Image, Text, Spinner, useToast } from '@chakra-ui/react';
import RevisionAccordionItems from "src/components/contracts/contract-revision/RevisionAccordionItems";

const LazyPdfViewer = lazy(() => import("../../components/common/PdfViewer"));

//const FilterInput = ({ onChangeInput, disabled = false, valueInput, label,clearValue }: { onChangeInput?: any; disabled?: boolean, valueInput?: any, label?: string ,clearValue?:boolean}) => {

const ShowEditPdfPage = () => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const { id, biddingId, uuid } = useParams();

    const {
        setPdfPreviewModal,
        setLoaderModal,
        setContractRevisionModal,
        setTokenIlustrationModal,
        setContractSignersSelectModal,
        setUploadContractAnnexModal,
        setRestrictedActionModal,
    } = useModalState();
    const setSelectedSigners = useContractSignaturesState((s) => s.setSelectedSigners);
    const { selectedContract, getContract, setSelectedContract, updateContract, uiOptions, saveOnVault, createFolder, createToken } =
        useContractState();
    const { cancelRevision, currentReviewerEditionChanges, setCurrentReviewerEditionChanges } = useContractRevisionState();
    const { generatePdfPreviewUrl, pdfBlobUrl, generatePdfBlob, generatePdfVaultUrl } = usePdfPreview();
    const [isSaving, setIsSaving] = useBoolean(false);
    const [isSavingOnVault, setIsSavingOnVault] = useBoolean(false);
    const [showSignaturesView, setShowSignaturesView] = useBoolean(false);
    const [initialDataGroupsSnapshot, setInitialDataGroupsSnapshot] = useState<string>(null);

    // new state pdf contract type
    const [documentName, setDocumentName] = useState<string | undefined>(t("undefined"));
    const [pdfUrlBlob, setPdfUrlBlob] = useState<string>(null);
    const [loading, setLoading] = useBoolean(false);

    const { infoContract, statusContract, isSignature, setIsSignature,setInfoContract } = usePdfSignaturesState();

    const { statusDocument, getAllReviewAssociate, getDocumentReviewStatus, deleteReviewDocument } = useDocumentRevisionState();

    const { getBidding } = useBiddingState();

    const [isImage, setIsImage] = useState(false);
    const [isOtherFile, setIsOtherFile] = useState(false);

    const [otherFile, setOtherFile] = useState(null);

    console.log("infoContract", infoContract)

    /*async function getVaultDoc(docFileId: string) {
        setLoading.on();
        try {
            const res = await vaultService.getDocumentFromVault(docFileId);
            const b64String = res.data.file;
            const extension = res.data.name.split(".").reverse()[0];
            if (extension.toLowerCase() === "pdf") {
                setPdfUrlBlob("data:application/pdf;base64," + b64String);
                setDocumentName(res.data.name);
            }
        } catch {
            toast.error("Ocurrió un error al descargar el documento");
        } finally {
            setLoading.off();
        }
    }*/
    async function getVaultDoc(docFileId) {
        setLoading.on();
        try {
            const res = await vaultService.getDocumentFromVault(docFileId);
            const b64String = res.data.file;
            const extension = res.data.name.split(".").reverse()[0].toLowerCase();

            if (extension === "pdf") {
                setPdfUrlBlob("data:application/pdf;base64," + b64String);
                setDocumentName(res.data.name);
            } else if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
                setIsImage(true);
                setPdfUrlBlob("data:image/" + extension + ";base64," + b64String);
                setDocumentName(res.data.name);
            } else {
                setIsOtherFile(true);
                setPdfUrlBlob("data:application/octet-stream;base64," + b64String);
                setOtherFile("data:application/octet-stream;base64," + b64String);
                setDocumentName(res.data.name);
            }
        } catch {
            toast.error("Ocurrió un error al descargar el documento");
        } finally {
            setLoading.off();
        }
    }


    function getRefreshReviewData() {
        getBidding(biddingId);
        getAllReviewAssociate(uuid);
        infoContract.reviewStatus = "revision";

        setInfoContract(infoContract);
        generatePdfVaultUrl(id);

    }
    function handleRevision() {
        /* const hasChanges = JSON.stringify(selectedContract?.template?.dataGroup) !== initialDataGroupsSnapshot;
         if (hasChanges) {
             toast.error(t("saveChangesBeforeRevision"), t("warning"));
             return;
         }*/
        setContractRevisionModal({
            show: true,
            data: { contractId: uuid, afterSubmit: () => getRefreshReviewData(), nameContract: selectedContract?.name, reviewType: "Document" },
        });
    }

    async function handleCancelRevision() {
        const canceled = await deleteReviewDocument(statusDocument[0].id);
        if (canceled) {
            getBidding(biddingId);
            infoContract.reviewStatus = "SI"

            setInfoContract(infoContract);
            toast.success(t("revisionCanceled"));
        } else {
            toast.error(t("cancelingRevisionError"));
        }
    }

    async function handleSign() {
        setContractSignersSelectModal({
            show: true,
            data: {
                afterSubmit: (signers: ContractSigner[]) => {
                    setSelectedSigners(signers);
                    if (pdfBlobUrl) return setShowSignaturesView.on();
                    setLoaderModal(true);
                    generatePdfVaultUrl(id)
                        .then((url) => {
                            setShowSignaturesView.on();
                        })
                        .finally(() => setLoaderModal(false));
                },
            },
        });
    }

    async function handleOnSendSignatures(stickersValue: any) {
        const STICKER_W = 130;
        const STICKER_H = 30;
        const QR = [{ height: "80", pages: "all", width: "80", x: "0", y: "0" }];

        let stickers = stickersValue.map((sv: any) => {
            return {
                id: sv.userId,
                authority: "Vinculada a Correo Electronico por Liga",
                page: parseInt(sv.page || 1) - 1,
                rect: {
                    lx: Math.round(sv.convertedCoords[0]),
                    tx: Math.round(sv.convertedCoords[0]) + STICKER_W,
                    ty: Math.round(sv.convertedCoords[1]) + STICKER_H,
                    ly: Math.round(sv.convertedCoords[1]) - 5,
                },
            };
        });

        const body = {
            biddingId: biddingId,
            documentUuid: uuid,
            reqSignatureCreateDto: {
                documentId: id,
                qr: QR,
                signers: stickers,
            },
        };
        setLoaderModal(true);
        try {
            const res = await pdfSignatureService.sendSignatures(body);
            console.log("res firma", res);
            let codigo = res.data?.mensaje;
            let msg = res.data?.codigo;

            if (res?.data?.codigo === 3 && res?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
                setRestrictedActionModal({
                    show: true,
                    data: {
                        text1: "AVISO",
                        text2: t("createSignatureProcess"),
                        text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                        redirectHome: false,
                        onClose: async () => {
                            return { show: false };
                        },
                    },
                });
            } else {
                if (res.status === 200) {
                    //setSelectedContract({ ...selectedContract!, status: "pendiente de firmas" });
                    setIsSignature(true);
                    getBidding(biddingId);
                    infoContract.signStatus = "firmado";
                    setInfoContract(infoContract);
                    toast.success(t("signaturesSendedSuccessfully"));
                    setShowSignaturesView.off();
                } else {
                    throw new Error();
                }
            }
        } catch (error) {
            console.log(error);
            toast.error(t("sendingSignaturesError"));
        } finally {
            setLoaderModal(false);
        }
    }

    async function handleCancelSignatures() {
        setLoaderModal(true);
        try {
            const res: any = await pdfSignatureService.cancelSignatures(biddingId, uuid);
            if (res.status === 200) {
                setIsSignature(false);
                infoContract.signStatus = "SI"
                setInfoContract(infoContract);
                //setSelectedContract({ ...selectedContract!, status: "en boveda" });
                toast.success(t("canceledSignaturesSuccessfully"));
            } else {
                throw new Error();
            }
        } catch (error) {
            console.log(error);
            toast.error(t("cancelingSignaturesError"));
        } finally {
            setLoaderModal(false);
        }
    }

    useEffect(() => {
        if (id) {
            getVaultDoc(id);
            getDocumentReviewStatus(uuid);
        } else {
            toast.error("ID de documento no existe");
        }
    }, [id]);

    function cleanFileName(fileName) {
        const extensions = ["pdf", "png", "jpg", "jpeg", "gif", "doc", "docx", "xls", "xlsx", "xml", "mp4"];

        // const cleanedFileName = fileName.replace(/((\.pdf){1,}){1,}\.pdf$/, ".pdf");
        const extensionPattern = extensions.join("|");

        const cleanedFileName = fileName.replace(
            new RegExp(`((\\.(${extensionPattern})){1,})\\.(${extensionPattern})$`, "i"),
            ".$4"
        );

        const truncatedFileName = cleanedFileName.substring(0, 20);

        return truncatedFileName;
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = pdfUrlBlob;
        link.download = cleanFileName(documentName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    console.log("#isOther", isOtherFile);

    const renderSignatures = isSignature && (
        <SignaturesAccordionItems key={isSignature + "-revision"} documentId={id} />
    );

    const renderRevision = infoContract.reviewStatus === "revision" && (
        <RevisionAccordionItems
            key={selectedContract?.lastModifiedDate + "-revision"}
            contractId={infoContract.uuid}
            type={"document"}
        />
    );

    const renderTraceability = (
        <TraceabilityAccordionItems key={"trace" + isSignature} contractId={uuid} isPDF={true} />
    );

    return (
        <MainLayout>
            <main className="w-full p-3 flex h-full">
                {/* ------------------------------- left panel ------------------------------- */}
                <section className="w-3/12 flex flex-col pr-4 pl-2 border-r h-full max-h-full overflow-y-auto relative">
                    {
                        <>
                            <div className="pb-8 pt-6">
                                <GoBackLinkButton />
                            </div>
                            <h2 className="title-3">{"Estatus del documento"}</h2>
                            <div className="text-sm mt-2 space-y-1">
                                <p>
                                    <b>{t("contractName")}:</b> {cleanFileName(documentName)}
                                </p>
                                <p>
                                    <b>{t("date")}:</b> {formatDate(new Date(infoContract?.createdDate || "01-01-2022"))}{" "}
                                </p>
                            </div>
                        </>
                    }
                    <div className="mt-6 pb-12">
                        <Accordion defaultIndex={[0]} allowMultiple>
                            {renderSignatures}
                            {renderRevision}
                            {renderTraceability}
                        </Accordion>
                    </div>

                    <div className="grow"></div>
                    <>
                        {infoContract.reviewStatus === "SI" && (
                            <RevisionDocumentActions onSendReview={handleRevision} isSaving={false} />
                        )}
                        {/*uiOptions.IsInVault && <InVaultActions onSign={handleSign} isSaving={false} />*/}

                        {infoContract.signStatus === "SI" && (
                            <InVaultActions onSign={handleSign} isSaving={false} />
                        )}


                        {isSignature && <PendingSignActions onCancelSignatures={handleCancelSignatures} isLoading={false} />}
                        {infoContract.reviewStatus === "revision" && <PendingRevisionActions onCancelReview={handleCancelRevision} isLoading={false} />}

                    </>
                </section>
                {/* ------------------------------- right panel ------------------------------ */}
                <section className="w-9/12 pr-2 pl-4 h-full max-h-full overflow-y-auto">
                    {loading && (
                        <Center h="100%">
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                            />
                        </Center>
                    )}

                    {!loading && pdfUrlBlob && (
                        <>
                            {pdfUrlBlob && documentName.endsWith('.pdf') && (
                                <BiddingPdfViewerCustom docVaultId={id} onDownload={handleDownload} onClose={setShowSignaturesView.off} />
                            )}

                            <Center h="100%">
                                {isImage && (
                                    <Box textAlign="center" boxShadow="lg" p={4} borderRadius="md">
                                        <Image
                                            src={pdfUrlBlob}
                                            alt={documentName}
                                            fallbackSrc="https://via.placeholder.com/150"
                                            width="200%"
                                            height="700px"
                                            objectFit="contain"
                                            mx="auto"
                                        />
                                        <Button mt={4} onClick={handleDownload}>Descargar Imagen</Button>
                                    </Box>
                                )}
                                {isOtherFile && (
                                    <Box textAlign="center" boxShadow="lg" p={4} borderRadius="md">
                                        <Text fontSize="lg" fontWeight="bold" mb={2}>{cleanFileName(documentName)}</Text>
                                        <Button onClick={handleDownload}>Descargar Documento</Button>
                                    </Box>
                                )}
                            </Center>
                        </>
                    )}
                </section>
                {showSignaturesView && (
                    <SignaturesView
                        url={pdfUrlBlob}
                        documentName={"l"}
                        onSaveSigns={handleOnSendSignatures}
                        onClose={setShowSignaturesView.off}
                    />
                )}
            </main>
        </MainLayout>
    );
};

export default ShowEditPdfPage;
