import React from 'react'
import CommonModal from '../CommonModal'
import { useAxCalcStore } from 'src/store/axCalc.store';
import { Button, useBoolean } from '@chakra-ui/react';
import { useFormik } from 'formik';
import FileDropzone from '../FileDropzone';
import { useTranslation } from 'react-i18next';
import { TranslationKeys } from 'src/i18n/en';

type Props = {
  onCancel: () => any;
  isOpen: boolean;
};

function AddFileToCalcModal({ isOpen, onCancel }: Props) {
  const t: (key: TranslationKeys) => string = useTranslation("global")[0];
  const { setSelectedFile, openFile, selectedFile } = useAxCalcStore();
  const [hasErrorFile, setHasErrorFile] = useBoolean(false)

  const handleBudgetFile = () => {

    if (selectedFile === null) {
      setHasErrorFile.on()
      return
    }
    setHasErrorFile.off()
    openFile()
    onCancel()
  }

  const budgetCardForm = useFormik({
    onSubmit: handleBudgetFile,
    validateOnBlur: true,
    initialValues: {}
  })

  const handleFileUpload = (file: File) => {
    setSelectedFile(file);
  }
  return (
    <CommonModal size="xl" isOpen={isOpen} onClose={onCancel}>
      <div className="w-full flex flex-col items-center space-y-6 py-4">
        <h2 className="title-3">Cargar documento</h2>
        <form onSubmit={budgetCardForm.handleSubmit}
          className="flex flex-col gap-4">
          <FileDropzone accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onDropFile={handleFileUpload} />
          {hasErrorFile && (
            <span className="text-red-500">
              El archivo es requerido
            </span>
          )}
        </form>
        <div className="mt-8 pb-2 w-full text-center">
          <Button
            rounded="full"
            size="md"
            onClick={() => budgetCardForm.handleSubmit()}
            fontWeight="medium"
            px={"2rem"}
            isLoading={false}
          >
            {t("save")}
          </Button>
        </div>
      </div>
    </CommonModal>
  )
}

export default AddFileToCalcModal