import { Checkbox, Radio, RadioGroup } from "@chakra-ui/react";
import { User } from "../../models/Aministration.models";
import CommonTh from "../common/table/CommonTh";
import Paginator from "../common/Paginator";
import { TranslationKeys } from "src/i18n/en";
import { useTranslation } from "react-i18next";

const TableRow = ({ user, handleChange, selectedUsers }: { user: User; handleChange: any; selectedUsers: string[] }) => {
    const handleCheckboxChange = () => {
        handleChange(user.id);
    };
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 py-3">
                <Checkbox onChange={handleCheckboxChange} isChecked={selectedUsers.includes(user.id)} />
            </td>
            <td className="p-2 py-3">{`${user.name} ${user.lastName}`}</td>
            <td className="p-2 py-3">{user.email}</td>
        </tr>
    );
};

const UsersAdminTable = ({
    users,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
    handleChange,
    selectedUsers,
}: {
    users: User[];
    handleChange: any;
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
    selectedUsers: any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            <table className="w-full text-sm text-left text-dark-2 cursor-pointer">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2"></th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("email")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {users?.map((item) => (
                        <TableRow key={item.id} user={item} handleChange={handleChange} selectedUsers={selectedUsers} />
                    ))}
                </tbody>
            </table>
            {users?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

export default UsersAdminTable;
