import * as React from "react";

const TableroIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        style={{
            enableBackground: "new 0 0 2316 2500",
            fill: "#2E54B8",
        }}
        viewBox="0 0 66 54"
        {...props}
    >
        <path
            d="M54.5 51.667H6.333v-1.53L20.5 35.97l10.767 10.767a2.833 2.833 0 0 0 3.994 0l21.25-21.25a2.833 2.833 0 0 0 0-4.023 2.833 2.833 0 0 0-3.995 0L33.25 40.73 22.512 29.964a2.833 2.833 0 0 0-3.995 0L6.333 42.119V27.47L20.5 13.304l7.933 7.933a2.833 2.833 0 0 0 3.995 0L46 7.665l6.205 6.205A2.835 2.835 0 0 0 56.2 9.847l-8.245-8.188a2.833 2.833 0 0 0-3.995 0l-13.6 13.6-7.905-7.934a2.833 2.833 0 0 0-3.995 0L6.333 19.452V3.5a2.833 2.833 0 1 0-5.666 0v51A2.833 2.833 0 0 0 3.5 57.334h51a2.833 2.833 0 0 0 0-5.667Z"
            fill="#2E54B8"
        />
    </svg>
);

export default TableroIcon;
