import axios from "axios";
import { getAuthHeader } from "../lib/utils";

export const axiosBaseRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1`,
    timeout: 10000,
});


const setDocumentOnBlockchain = async (body: any) => {
    try {
        return await axiosBaseRequest.put(`/blockchain/`,body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    setDocumentOnBlockchain,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
