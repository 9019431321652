import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

export const axiosScmRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/component/`,
    timeout: 20000,
});

export const axiosScmRequestV2 = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/`,
    timeout: 20000,
});


// Visualizar button de configuración en administración.
const getPermissionBtnConfig = async () => {
    try {
        return await axiosScmRequest.get(`admin/button/configuracion`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar button de bpmn en administración.
const getPermissionBtnBpmn = async () => {
    try {
        return await axiosScmRequest.get(`admin/button/bpmn`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar button de compañias en administración.
const getPermissionBtnCompany = async () => {
    try {
        return await axiosScmRequest.get(`admin/button/empresas`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


// Visualizar button de grupo de usuarios en administración.
const getPermissionBtnGroupUser = async () => {
    try {
        return await axiosScmRequest.get(`admin/button/grupoDeUsuarios`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar button de role en administración.
const getPermissionBtnRols = async () => {
    try {
        return await axiosScmRequest.get(`admin/button/roles`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar button de usuarios en administración.
const getPermissionBtnUsers = async () => {
    try {
        return await axiosScmRequest.get(`admin/button/usuarios`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

/**  Apis Modulos */

// Visualizar button de nuevo documento dinamico Modulo Documentos Dinamicos.
const getPermissionBtnCreateDynamicDocument = async () => {
    try {
        return await axiosScmRequest.get(`process/button/crearDocumentoDinamico`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar button de crear nuevo proceso.
const getPermissionBtnCreateNewProcces = async () => {
    try {
        return await axiosScmRequest.get(`process/button/nuevaOperacion`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar button de crear nuevo proceso.
const getPermissionBtnCreateNewTypeProcces = async () => {
    try {
        return await axiosScmRequest.get(`process/button/tiposDeProceso`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


/**
 * Components Tabs- Pestañas Menú
 */

// Visualizar Menu Administración.
const getPermissionTabAdmin = async () => {
    try {
        return await axiosScmRequest.get(`tap/admin`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


// Visualizar Menú Contrato.
const getPermissionTabContract = async () => {
    try {
        return await axiosScmRequest.get(`tap/contract`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar Menú Procesos.
const getPermissionTabProcess = async () => {
    try {
        return await axiosScmRequest.get(`tap/process`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar Menú Plantillas.
const getPermissionTabTemplates = async () => {
    try {
        return await axiosScmRequest.get(`tap/templates`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};
/*
    Btn Templates
*/

// Visualizar btn modulo plantillas grupo de datos.
const getPermissionBtnTemplateGroupData = async () => {
    try {
        return await axiosScmRequest.get(`template/button/gruposDeDatos`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar btn modulo plantillas nueva plantila.
const getPermissionBtnNewTemplate = async () => {
    try {
        return await axiosScmRequest.get(`template/button/nuevaPlantilla`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// Visualizar btn modulo plantillas tipos de documentos dinamicos.
const getPermissionBtnTypeDinamicDocuments = async () => {
    try {
        return await axiosScmRequest.get(`template/button/tiposDeDocumentosDinamicos`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


// Visualizar btn modulo plantillas tipos de documentos dinamicos.
const getTest = async () => {
    try {
        return await axiosScmRequestV2.get(`movilCheckInOut/checkInOut`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};
// * Generadores option //
const getPermissionBtnModalStatusGenerador = async () => {
    try {
        return await axiosScmRequest.get(`biddings/urlGeneradores`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    getPermissionBtnConfig,
    getPermissionBtnBpmn,
    getPermissionBtnCompany,
    getPermissionBtnGroupUser,
    getPermissionBtnRols,
    getPermissionBtnUsers,
    getPermissionBtnCreateDynamicDocument,
    getPermissionBtnCreateNewProcces,
    getPermissionBtnCreateNewTypeProcces,
    getPermissionTabAdmin,
    getPermissionTabContract,
    getPermissionTabProcess,
    getPermissionTabTemplates,
    getPermissionBtnTemplateGroupData,
    getPermissionBtnNewTemplate,
    getPermissionBtnTypeDinamicDocuments,
    getTest,
    getPermissionBtnModalStatusGenerador
};
