import { Box, Button, Progress } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MESES } from "src/lib/constants";
import { BiddingRequirement, BiddingStage } from "src/models/biddings/Bidding.models";
import RequirementCard from "./RequirementCard";
import useBiddingState from "src/store/biddings/biddingState";
import { useParams } from "react-router-dom";
import { buildNestedStructure } from "src/lib/utils";
import CollapseItem from "../common/CollapseItem";

interface RequerimentsProps {
    alwaysNew: boolean;
    biddingValidationTypeId: string;
    documentTypeId: string[];
    fileLabel: string;
    maxUploadable: number;
    minUploadable: number;
    name: string;
    requerimentIdList: string[];
    reviewStatus: string;
    signStatus: string;
    templateId: string;
}

interface Item {
    name: string;
    originalName: string;
    requirements: RequerimentsProps[];
    children: Record<string, Item>;
}

const BiddingGroupsPanel = ({
    months,
    stage,
    selectedYear,
    afterUploadRequirementDocument,
    stageCopy,
    selectedBiddingId,
}: {
    months: number[];
    stage: BiddingStage;
    stageCopy: any;
    selectedYear: number;
    afterUploadRequirementDocument: any;
    selectedBiddingId?: any;
}) => {
    const allMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const [selectedMonth, setSelectedMonth] = useState<number>(months[0] || allMonths[0]);
    const groups = stage?.groups;
    const selectedInterval = stage?.intervals?.find((interval) => interval.month === selectedMonth && interval.year === selectedYear);
    const roundedPercentage = Math.min(Math.round(selectedInterval?.percentage || 0), 100);
    const [openGroups, setOpenGroups] = useState<string | null>(null);
    const [openSubGroups, setOpenSubGroups] = useState<Record<string, boolean>>({});
    const { getBidding } = useBiddingState();
    const { id } = useParams();
    const nestedStructure = buildNestedStructure(groups);

    const handleToggleGroup = (id: string) => {
        setOpenGroups((prevOpenGroups) => (prevOpenGroups === id ? null : id));
        setOpenSubGroups({}); // Close all subgroups when changing the main group
    };

    const handleToggleSubGroup = (id: string) => {
        setOpenSubGroups((prevOpenSubGroups) => ({
            ...prevOpenSubGroups,
            [id]: !prevOpenSubGroups[id],
        }));
    };

    useEffect(() => {
        if (groups.length > 0) {
            handleToggleGroup(groups[0].name);
        }
    }, []);

    const NestedComponent = (data: Record<string, Item>, parentId?: string) => (
        <Box>
            {Object.values(data).map((item) => {
                const isOpen = parentId ? openSubGroups[item.name] : openGroups === item.name;
                const nameArray = item.originalName.split('/');
                const title = `${nameArray.length > 1 ? '/' : ''} ${nameArray[nameArray.length - 1]}`
                return (
                    <CollapseItem
                        key={item.name}
                        id={item.name}
                        title={title}
                        isOpen={isOpen}
                        onToggle={() => (parentId ? handleToggleSubGroup(item.name) : handleToggleGroup(item.name))}
                        draggable={false}
                        disabled={
                            (!stageCopy ||
                                !item.name ||
                                (stageCopy.some &&
                                    stageCopy.some((copyGroups) =>
                                        copyGroups.groups?.some((copyGroup) => copyGroup.name === item.name)
                                    ))) === false
                        }
                    >
                        <>
                            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 mt-4 mb-2">
                                {item.requirements?.map((requirement, reqIdx) => (
                                    <RequirementCard
                                        key={reqIdx}
                                        disabled={
                                            (!stageCopy ||
                                                !requirement.name ||
                                                (stageCopy.some &&
                                                    stageCopy.some((groups) =>
                                                        groups.groups?.some((group) =>
                                                            group.requirements?.some(
                                                                (requirementCopy) => requirementCopy.name === requirement.name
                                                            )
                                                        )
                                                    ))) === false
                                        }
                                        requirement={requirement as BiddingRequirement}
                                        selectedMonth={selectedMonth}
                                        selectedYear={selectedYear}
                                        afterUploadDocument={() => {
                                            afterUploadRequirementDocument(requirement);
                                        }}
                                        selectedBiddingId={selectedBiddingId}
                                    />
                                ))}
                            </div>
                            {Object.keys(item.children).length > 0 && NestedComponent(item.children, item.name)}
                        </>
                    </CollapseItem>
                );
            })}
        </Box>
    );

    return (
        <div>
            <div className="flex flex-nowrap gap-4 overflow-auto p-1 justify-between">
                {allMonths.map((month) => (
                    <Button
                        key={month}
                        colorScheme="brand"
                        variant={selectedMonth === month ? "solid" : "outline"}
                        fontSize="sm"
                        onClick={() => setSelectedMonth(month)}
                        isDisabled={!months.includes(month)}
                    >
                        {MESES[month]}
                    </Button>
                ))}
            </div>
            <div className="mt-4 flex items-center justify-between">
                <Progress hasStripe value={roundedPercentage} colorScheme="teal" w={"calc(100% - 60px)"} />
                <span className="text-gray-600 font-semibold text-lg">{roundedPercentage}%</span>
            </div>
            <div className="mt-4">
                <Box>{NestedComponent(nestedStructure)}</Box>
            </div>
        </div>
    );
};

export default BiddingGroupsPanel;
