import { Tooltip, useBoolean } from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";

type Props = {
    icon: JSX.Element;
    hoverIcon?: JSX.Element;
    text: string;
    tooltip?: string;
    size?: "xsmall" | "small" | "medium" | "large";
    buttonW?: boolean;
    selected?: boolean;
    alignLeft?: boolean;
    onClick: () => void;
};

const variants = {
    xsmall: {
        height: "h-16",
        fontSize: "text-base",
    },
    small: {
        height: "h-20",
        fontSize: "text-xl",
    },
    medium: {
        height: "h-24",
        fontSize: "text-xl",
    },
    large: {
        height: "h-32",
        fontSize: "text-lg",
    },
};

const BoxButton = ({ icon, hoverIcon = null, text, tooltip, size = "medium", selected = false, onClick, buttonW, alignLeft }: Props) => {
    const [isHover, setIsHover] = useBoolean(false);

    return (
        <div
            className={`${buttonW ? "w-100" : "w-80"}  ${variants[size]?.height} ${selected ? "bg-[#DBF4FC]" : "bg-gray-light"} ${
                alignLeft ? "justify-start gap-4" : "justify-center gap-6"
            } rounded-lg border-2 border-transparent flex items-center  px-4 gap-6 hover-border-brand relative `}
            onClick={onClick}
            onMouseEnter={setIsHover.on}
            onMouseLeave={setIsHover.off}
        >
            {!hoverIcon && icon}
            {!!hoverIcon &&
                (isHover ? (
                    <div key={"hovIcon"} className="fade">
                        {hoverIcon}
                    </div>
                ) : (
                    <div key={"normalIcon"} className="fade">
                        {icon}
                    </div>
                ))}

            <p className={`text-left text-brand-500 break-normal ${variants[size]?.fontSize}`}>{text}</p>
            {Boolean(tooltip) && (
                <div className="absolute top-0.5 right-2">
                    <Tooltip label={tooltip} fontSize="12px">
                        <button>
                            <AiOutlineInfoCircle size={16} />
                        </button>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};

export default BoxButton;
