import { TiChevronLeft, TiChevronRight } from "react-icons/ti";

type Props = {
    onNext: (arg?: any) => any;
    onPrev: (arg?: any) => any;
    currentPage: number;
    maxPages: number;
};

const TablePaginator = ({ currentPage, maxPages, onNext, onPrev }: Props) => {
    return (
        <div className="flex items-center text-brand-500">
            <button
                className="disabled:text-brand-500/40 disabled:cursor-not-allowed"
                onClick={onPrev}
                disabled={currentPage === 1}
            >
                <TiChevronLeft />
            </button>
            {currentPage ?? 1} - {maxPages ?? 1}
            <button
                className="disabled:text-brand-500/40 disabled:cursor-not-allowed"
                onClick={onNext}
                disabled={currentPage === maxPages}
            >
                <TiChevronRight />
            </button>
        </div>
    );
};

export default TablePaginator;
