import axios from "axios";
import { getAuthHeader } from "../lib/utils";

export const axiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1`,
    timeout: 20000,
});

export const axiosGeneralRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_GENERAL}/api/general`,
    timeout: 20000,
});

const listUsers = async (idCompany: string) => {
    try {
        return await axiosRequest.get(`/revision/users/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const sendSignatures = async (body: any) => {
    try {
        return await axiosRequest.put(`/firmaPDF`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const cancelSignatures = async (biddingId: string, documentUuid: string) => {
    try {
        return await axiosRequest.delete(`/firmaPDF/biddingId/${biddingId}/documentUuid/${documentUuid}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getSignaturesByDocument = async (docId: string) => {
    try {
        return await axiosGeneralRequest.get(`/signature/${docId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDynamicTable = async (jsonId: string) => {
    try {
        return await axiosRequest.get(`/DinamicTable/${jsonId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDynamicTableJSON = async (jsonId: string) => {
    try {
        return await axiosRequest.get(`/DinamicTable/fileJsonDecode/fileJsonId/${jsonId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    listUsers,
    sendSignatures,
    getSignaturesByDocument,
    cancelSignatures,
    getDynamicTable,
    getDynamicTableJSON,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
