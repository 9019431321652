import axios from "axios";
import { DataTypesTypes } from "../models/DataType.models";
import { getAuthHeader } from "../lib/utils";

export const axiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/data_types`,
    timeout: 10000,
});

export const patternAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1`,
    timeout: 10000,
});

const listDataTypes = async (idCompany: string) => {
    try {
        return await axiosRequest.post(
            "/returnDataTypes",
            { idCompany, idUser: "" },
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createDataType = async (body: {
    name: string;
    type: DataTypesTypes;
    idCompany: string;
    idUser: string;
    valueList?: string[];
    dataAtribute?: any;
}) => {
    try {
        return await axiosRequest.post(`/saveDataTypes`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteDataType = async (dataTypeId: string) => {
    try {
        return await axiosRequest.delete(`/deleteDataTypes/${dataTypeId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getNumberFormatPatterns = async (idCompany: string) => {
    try {
        return await patternAxiosRequest.get(`/numericPattern/idCompany/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDateFormatPatterns = async (idCompany: string) => {
    try {
        return await patternAxiosRequest.get(`/datePattern/idCompany/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    listDataTypes,
    createDataType,
    deleteDataType,
    getNumberFormatPatterns,
    getDateFormatPatterns,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
