import { useEffect, useMemo, useState } from "react";
import usePagination from "../../hooks/usePagination";
import useAdministrationState from "../../store/administrationState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import UsersTable from "./UsersTable";
import GroupsTable from "./GroupsTable";
import useUsersOnUserGroup from "../../hooks/useUsersOnUserGroup";

const UsersAdmin = ({ selectedUser, handleSelectUser, usersIdsListInSelectedGroup, selectedUserGroup, handleSelectUserGroup }: any) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [userType, setUserType] = useState<"group" | "single">("group");
    const [orderedUsers, setOrderedUsers] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);

    const {
        companyUsers: { data: users },
        getCompanyUsers,
        companyUsersGroups: { data: companyUsersGroups },
    } = useAdministrationState();

    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({ data: orderedUsers, itemsPerPage: 6 });

    useEffect(() => {
        getCompanyUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (usersIdsListInSelectedGroup) {
            const orderGroups = () => {
                const selectedSet = new Set(usersIdsListInSelectedGroup);

                // Filter users and handle cases where users or users elements are undefined
                const selectedUsers = users?.filter((group) => selectedSet.has(group.id)) || [];
                const remainingUsers = users?.filter((group) => !selectedSet.has(group.id)) || [];

                setOrderedUsers([...selectedUsers, ...remainingUsers]);
            };
            orderGroups();
        }
    }, [usersIdsListInSelectedGroup, users]);

    useEffect(() => {
        if (selectedUserGroup) {
            setTabIndex(0);
        }
    }, [selectedUserGroup]);

    return (
        <div>
            <Tabs isFitted variant="enclosed-colored" index={tabIndex}>
                <TabList>
                    <Tab isSelected={userType === "single"} onClick={() => setTabIndex(0)}>
                        Usuarios
                    </Tab>
                    <Tab isSelected={userType === "group"} onClick={() => setTabIndex(1)}>
                        Grupos de usuarios
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <UsersTable
                            users={currentResutls}
                            currentPage={currentPage}
                            maxPages={maxPages}
                            prev={prev}
                            next={next}
                            setPage={setPage}
                            handleChange={handleSelectUser}
                            selectedUsers={usersIdsListInSelectedGroup}
                        />
                    </TabPanel>
                    <TabPanel>
                        <GroupsTable selectedUserGroup={selectedUserGroup} handleSelectUserGroup={handleSelectUserGroup} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default UsersAdmin;
