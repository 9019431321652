import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import useModalState from "./modalsState";
import dashboardService from "src/services/dashboard.service";
import useAuthState from "./authState";

interface State {
    urlDashboardPowerBi: string;
    getUrlDashboardPowerBI : (withLoading?: boolean) => Promise<any>;
    refreshDashboardPowerBI : (withLoading?: boolean) => Promise<any>;
    downloadPoweBIToMail : (withLoading?: boolean) => Promise<any>;
    reset: () => void;
}

const initialState = {
    urlDashboardPowerBi: null
};

const useDashboardState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            getUrlDashboardPowerBI : getUrlDashboardPowerBI(set , get),
            refreshDashboardPowerBI : refreshDashboardPowerBI(set , get),
            downloadPoweBIToMail: downloadPoweBIToMail(set, get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "dashboardState" }
    )
);

export default useDashboardState;

/* -------------------------------------------------------------------------- */
/*                              SETTERS FUNCTIONS                             */
/* -------------------------------------------------------------------------- */

function getUrlDashboardPowerBI(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await dashboardService.getUrlPowerBiDashboard(idCompany);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0 || res?.data?.respuesta === "ok") {
            let response = res.data.respuesta;
            set({ urlDashboardPowerBi: response.powerBiDashboardUrl });
            return true ;
        } else {
            console.log("Error fetching url PowerBi");
            return false;
        }
    };
}

function refreshDashboardPowerBI(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await dashboardService.refreshDashboardPowerBI(idCompany);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0 || res?.data?.respuesta === "ok") {
            let response = res.data.respuesta;
            set({ urlDashboardPowerBi: response.powerBiDashboardUrl });
            return true ;
        } else {
            console.log("Error fetching url PowerBi");
            return false;
        }
    };
}

function downloadPoweBIToMail(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await dashboardService.downloadPoweBIToMail();

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0 || res?.data?.respuesta === "ok") {
            let response = res.data.respuesta;
            set({ urlDashboardPowerBi: response.powerBiDashboardUrl });
            return true ;
        } else {
            console.log("Error fetching url PowerBi");
            return false;
        }
    };
}
