import CommonModal from "../../CommonModal";
import SearchInput from "../../table/SearchInput";
import CommonTh from "../../table/CommonTh";
import { Badge, Button, Checkbox } from "@chakra-ui/react";
import useTemplateState from "../../../../store/templatesState";
import { useEffect, useMemo, useState } from "react";
import LoadingBar from "../../LoadingBar";
import TablePaginator from "../../table/TablePaginator";
import { Template } from "../../../../models/Template.models";
import usePagination from "../../../../hooks/usePagination";
import { formatDate, getTemplateStatusColor } from "../../../../lib/utils";
import useContractState from "../../../../store/contractState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { useNavigate } from "react-router-dom";

const TableRow = ({ template, selected, onSelect }: { template: Template; selected: boolean; onSelect: (tempId: string) => any }) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];

    return (
        <tr
            onClick={() => onSelect(template.id)}
            className={`${"border-b  transition-colors duration-200 cursor-pointer"} ${
                selected ? " bg-brand-500 text-white" : " hover:bg-slate-100"
            }`}
        >
            <td className="p-2 py-3">{template.name}</td>
            <td className="p-2 py-3">{template.userName}</td>
            <td className="p-2 py-3">{formatDate(new Date(template?.createdDate || "01-01-2022"))}</td>
            <td className="p-2 py-3">{template.type}</td>
            <td className="p-2 py-3">
            <Badge colorScheme={getTemplateStatusColor(template.status)}>{t(template.status)}</Badge>
            </td>
            <td className="p-2 py-3">{formatDate(new Date(template?.lastModifiedDate || "01-01-2022"))}</td>
        </tr>
    );
};

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const TemplateSelectModal = ({ isOpen, onClose }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const navigate = useNavigate();
    const {
        getTemplates,
        templates: { data: templates, loading },
        getTemplate,
    } = useTemplateState();

    const { currentNewContract, setCurrentNewContract } = useContractState();

    const [searchQuery, setSearchQuery] = useState("");
    const [selectedTemplate, setSelectedTemplate] = useState<Template>(currentNewContract?.template);

    const filteredTemplates = useMemo(() => filterTemplates(templates, searchQuery), [searchQuery, templates]);

    const { currentPage, currentResutls, maxPages, next, prev } = usePagination({ data: filteredTemplates, itemsPerPage: 6 });

    function handleSelect(tempId: string) {
        setSelectedTemplate(templates.find((tp) => tp.id === tempId));
    }

    function handleSubmit() {
        onClose();
        getTemplate(selectedTemplate?.id).then((template) => {
            console.log("template modal",template)
            if (template) {
                setCurrentNewContract({ ...currentNewContract, template, idTemplate: template?.id,maxLifeStage: template.maxLifeStage });
            }
        });
    }

    useEffect(() => {
        getTemplates(false);
    }, []);

    return (
        <CommonModal size="6xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col min-h-[60vh]">
                <div className="w-full flex items-end">
                    <div>
                        <h2 className="title-3">{t("myTemplates")}</h2>
                        <h3 className="text-dark">{t("selectAnActiveTemplate")}</h3>
                    </div>
                    <SearchInput onChange={(query) => setSearchQuery(query)} />
                </div>

                <div className="px-2 mt-4">
                    <LoadingBar isLoading={loading} />
                    <table className="w-full text-sm text-left text-dark-2">
                        <thead className="text-base text-brand-500 font-medium border-b">
                            <tr>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("name")} />
                                </th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("author")} />
                                </th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("creation")} />
                                </th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("type")} />
                                </th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("status")} />
                                </th>
                                <th scope="col" className="p-2">
                                    <CommonTh text={t("lastVersion")} />
                                </th>
                            </tr>
                            <tr></tr>
                        </thead>
                        <tbody>
                            {currentResutls?.map((item) => (
                                <TableRow
                                    key={item.id}
                                    template={item}
                                    selected={selectedTemplate?.id === item.id}
                                    onSelect={handleSelect}
                                />
                            ))}
                        </tbody>
                    </table>
                    {currentResutls?.length ? (
                        <div className="w-full flex justify-end">
                            <TablePaginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} />
                        </div>
                    ) : (
                        <div className="w-full text-center p-2 text-dark">{t("notElementsFound")}</div>
                    )}
                </div>
                <div className="grow"></div>
                <div className="text-right mt-6">
                    <Button disabled={!selectedTemplate} rounded="full" size="md" fontWeight="medium" onClick={handleSubmit}>
                        {t("select")}
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default TemplateSelectModal;

function filterTemplates(templates: Template[], searchQuery: string) {
    //devuelve los templates que coincidan con la query y sean activos
    const activeTemplates = templates.filter((tp) => tp.status === "activo");
    const lowerQuery = searchQuery.toLocaleLowerCase();
    return activeTemplates.filter((tp) => tp.name?.toLocaleLowerCase().includes(lowerQuery));
}
