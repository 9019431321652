import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { FormErrorMessage } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCustomToast from "../../../../hooks/useCustomToast";
import CommonModal from "../../CommonModal";
import useAdministrationState from "../../../../store/administrationState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { NewEditUserGroupModalData } from "src/models/Modals.models";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: NewEditUserGroupModalData;
};

interface FormFields {
    name: string;
    description: string;
}

const NewEditUserModal = ({ isOpen, onClose, data }: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const { createUserGroup, getCompanyUserGroups, updateUserGroup } = useAdministrationState();
    const formik = useFormik<FormFields>({
        initialValues: {
            name: data?.userGroup?.name || "",
            description: data?.userGroup?.description || "",
        },
        onSubmit: data.type === "new" ? handleCreate : handleUpdate,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
            description: Yup.string().required(t("requiredField")),
        }),
    });

    async function handleCreate(values: FormFields) {
        //valido nombre
        if (!values?.name) {
            toast.error(t("leastOneNameRequired"));
            return;
        }

        //valido descripción
        if (!values?.description) {
            toast.error(t("leastOneDescriptionRequired"));
            return;
        }

        const userBody = {
            ...values,
        };

        const savedUser = await createUserGroup(userBody as any);

        if (savedUser) {
            toast.success(t("userGroupCreatedSuccessfully"));
            getCompanyUserGroups(false);
            onClose();
        } else {
            toast.error(t("userGroupCreatingError"));
        }
    }

    async function handleUpdate(values: FormFields) {
        //valido nombre
        if (!values?.name) {
            toast.error(t("leastOneNameRequired"));
            return;
        }

        //valido descripción
        if (!values?.description) {
            toast.error(t("leastOneDescriptionRequired"));
            return;
        }

        const userBody = {
            ...values,
            id: data?.userGroup?.id,
        };

        const savedUser = await updateUserGroup(userBody as any);

        if (savedUser) {
            toast.success(t("userUpdatedSuccessfully"));
            getCompanyUserGroups(false);
            onClose();
        } else {
            toast.error(t("userUpdatingError"));
        }
    }

    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div className="text-center">
                    <h2 className="title-3">{data?.type === "edit" ? t("editUserGroup") : t("newUserGroup")}</h2>
                </div>

                <form
                    className="w-full flex flex-wrap justify-between items-center gap-2 max-h-[75vh] overflow-y-auto px-2"
                    onSubmit={formik.handleSubmit}
                >
                    <FormControl isInvalid={!!formik.errors.name}>
                        <label htmlFor="name" className="text-sm leading-8">
                            {t("name")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.description}>
                        <label htmlFor="description" className="text-sm leading-8">
                            {t("description")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="description"
                                name="description"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.description}</FormErrorMessage>
                    </FormControl>

                    <div className="mt-8 pb-2 w-full text-center">
                        <Button type="submit" rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                            {data?.type === "new" ? t("add") : t("save")}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

export default NewEditUserModal;
