import Icon from "@chakra-ui/icon";

const UserIcon = (props) => {
    return (
        <Icon viewBox="0 0 62 55" fill="none" {...props}>
            <g clipPath="url(#userIcon)">
                <path
                    d="M31 0a31.43 31.43 0 0 0-18.232 5.819C7.47 9.599 3.532 14.925 1.516 21.034a29.799 29.799 0 0 0 .034 18.79C3.588 45.926 7.546 51.24 12.857 55v-1.071c0-4.728 1.916-9.263 5.328-12.606 3.412-3.343 8.039-5.221 12.864-5.221 4.825 0 9.452 1.878 12.864 5.221 3.411 3.343 5.328 7.878 5.328 12.606V55c5.318-3.765 9.279-9.087 11.314-15.199a29.798 29.798 0 0 0 .011-18.813c-2.027-6.114-5.982-11.44-11.295-15.211A31.432 31.432 0 0 0 31 0Zm0 31.257a8.493 8.493 0 0 1-4.65-1.382 8.24 8.24 0 0 1-3.083-3.681 8.049 8.049 0 0 1-.476-4.739 8.152 8.152 0 0 1 2.29-4.2 8.424 8.424 0 0 1 4.286-2.244 8.53 8.53 0 0 1 4.836.466 8.336 8.336 0 0 1 3.756 3.021 8.085 8.085 0 0 1 1.41 4.557 8.12 8.12 0 0 1-2.45 5.8 8.458 8.458 0 0 1-5.92 2.402Z"
                    fill={props.color ?? "#25469B"}
                />
            </g>
        </Icon>
    );
};

export default UserIcon;
