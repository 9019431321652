import { BiddingDocumentType } from "src/models/biddings/BiddingDocumentType.models";
import biddingDocumentTypeService from "src/services/biddings/biddingDocumentType.service";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import useAuthState from "../authState";
import useModalState from "../modalsState";

interface State {
    biddingsDocumentTypes: { loading: boolean; error: boolean; data: BiddingDocumentType[] };
    biddingsDocumentTypesCompany: { loading: boolean; error: boolean; data: BiddingDocumentType[] };
    selectedBiddingDocumentType: BiddingDocumentType;
    setSelectedBiddingDocumentType: (biddingDocumentType: BiddingDocumentType) => void;
    getBiddingDocumentTypes: (withLoading?: boolean) => any;
    getBiddingDocumentTypesCompany: (withLoading?: boolean) => any;
    createBiddingDocumentType: (biddingDocumentType: BiddingDocumentType, withLoading?: boolean) => Promise<boolean>;
    updateBiddingDocumentType: (biddingDocumentType: BiddingDocumentType, withLoading?: boolean) => Promise<boolean>;
    deleteBiddingDocumentType: (biddingDocumentTypeId: string, withLoading?: boolean) => Promise<boolean>;
    reset: () => void;
}

const initialState = {
    biddingsDocumentTypes: { loading: false, error: false, data: [] },
    biddingsDocumentTypesCompany: { loading: false, error: false, data: [] },
    selectedBiddingDocumentType: null,
};

const useBiddingDocumentTypesState = create<State>((set, get) => ({
    ...initialState,
    setSelectedBiddingDocumentType: (biddingDocumentType: BiddingDocumentType) => set({ selectedBiddingDocumentType: biddingDocumentType }),
    getBiddingDocumentTypes: getBiddingDocumentTypes(set, get),
    getBiddingDocumentTypesCompany: getBiddingDocumentTypesCompany(set, get),
    createBiddingDocumentType: createBiddingDocumentType(set, get),
    updateBiddingDocumentType: updateBiddingDocumentType(set, get),
    deleteBiddingDocumentType: deleteBiddingDocumentType(set, get),
    reset: () => set((state) => ({ ...state, ...initialState })),
}));

export default useBiddingDocumentTypesState;

function hasPermissionForAction (reponse : any, msgPermission : string, redirect : boolean ){
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: 'AVISO',
                text2: msgPermission,
                text3: 'Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.',
                redirectHome : redirect,
                onClose: async () => {
                    return { show: false }
                  }
            }
        });
    }
}

function getBiddingDocumentTypes(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        
        const res = await biddingDocumentTypeService.getDocumentTypes();

        hasPermissionForAction(res,"Listar tipos de proceso",false);

        withLoading && useModalState.getState().setLoaderModal(false);
        const msg = res.data.respuesta;

        if (res?.data?.codigo === 0) {
            set({ biddingsDocumentTypes: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching biddings");
            set((state) => ({ biddingsDocumentTypes: { loading: false, error: true, data: state.biddingsDocumentTypes.data } }));
        }
    };
}

function getBiddingDocumentTypesCompany(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await biddingDocumentTypeService.getDocumentTypesByCompany(idCompany);

        hasPermissionForAction(res,"Listar tipos de procesos del usuario",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ biddingsDocumentTypesCompany: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching biddings");
            set((state) => ({
                biddingsDocumentTypesCompany: { loading: false, error: true, data: state.biddingsDocumentTypesCompany.data },
            }));
        }
    };
}

function createBiddingDocumentType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingDocumentType: BiddingDocumentType, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        
        const res = await biddingDocumentTypeService.createDocumentType(biddingDocumentType);
        
        hasPermissionForAction(res,"Crear tipo de proceso",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating bidding");
            return false;
        }
    };
}

function updateBiddingDocumentType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingDocumentType: BiddingDocumentType, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        
        const res = await biddingDocumentTypeService.updateDocumentType(biddingDocumentType);
       
        hasPermissionForAction(res,"Actualizar tipo de proceso",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating bidding");
            return false;
        }
    };
}

function deleteBiddingDocumentType(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingDocumentTypeId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
       
        const res = await biddingDocumentTypeService.deleteDocumentType(biddingDocumentTypeId);
        
        hasPermissionForAction(res,"Borrar tipo de proceso",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating bidding");
            return false;
        }
    };
}
