import { Badge, Button, Select } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import useCustomToast from "../../../../hooks/useCustomToast";
import { ContractSignersSelect } from "../../../../models/Contract.models";
import { ContractSignersSelectModalData } from "../../../../models/Modals.models";
import { ContractSigner } from "../../../../models/Signatures.models";
import useContractSignaturesState from "../../../../store/contractSignatureState";
import SignersSearchMenu from "../../../contracts/contract-signatures/SignersSearchMenu";
import AddRevisorIcon from "../../../icons/AddRevisorIcon";
import UserIcon from "../../../icons/UserIcon";
import CommonModal from "../../CommonModal";
import LoadingBar from "../../LoadingBar";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { IoClose } from "react-icons/io5";
import SlicedText from "../../SlicedText";

type ItemProps = {
    user: ContractSigner;
    isExternal: boolean;
    onChangeType: (...args: any) => any;
    onDelete: (...args: any) => any;
};

const SignerItem = ({ user, isExternal, onChangeType, onDelete }: ItemProps) => (
    <li className="flex items-center text-lg text-gray-600 relative">
        <UserIcon boxSize={12} />
        <div className="ml-4 pt-2">
            <div className="text-base text-gray-900 relative inline-block">
                <SlicedText text={user.name} maxLength={16} />
                {isExternal && (
                    <div className="text-xs font-light absolute top-[2px] left-full ml-2">
                        <Badge fontSize={"9px"} colorScheme="gray">
                            Externo
                        </Badge>
                    </div>
                )}
            </div>
            <div className="text-xs">{user.email || "---"}</div>
        </div>
        <div className="grow"></div>
        <Select variant={"unstyled"} maxW="6rem" className="font-bold">
            <option value="">EMAIL</option>
            {/* <option value="">RFC</option> */}
        </Select>
        <button className="text-red-500 ml-2" onClick={onDelete}>
            <IoClose size={15} color="inherit" />
        </button>
    </li>
);

type ModalProps = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: ContractSignersSelectModalData;
};

const ContractSignaturesModal = ({ isOpen, onClose, data }: ModalProps) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const {
        getSigners, //los firmantes son los mismos usuarios de revision
        signers: { data: signers, error, loading },
    } = useContractSignaturesState();

    const [selectedSigners, setSigners] = useState<ContractSigner[]>([]);

    async function onSubmit() {
        if (!selectedSigners.length) {
            return toast.error(t("atLeastOneSignerRequired"));
        }
        //action
        data?.afterSubmit(selectedSigners);
        onClose();
    }

    function handleSelect(user: ContractSignersSelect) {
        setSigners((prevState) => {
            const userAlreadySelected = prevState.find((u) => u.id === user.idUser);
            if (userAlreadySelected) return prevState;
            return [
                ...prevState,
                {
                    id: user.idUser,
                    isOriginal: false,
                    name: user.userName,
                    email: user.userEmail,
                    certificate: "EMAIL",
                    isExternal: user.external,
                },
            ];
        });
    }

    function handleDeleteSigner(id: string) {
        setSigners((prevState) => prevState.filter((u) => u.id !== id));
    }

    const filteredSigners = useMemo(() => {
        return signers?.filter((u) => !selectedSigners?.find((su) => su.id === u.idUser));
    }, [selectedSigners, signers]);

    useEffect(() => {
        getSigners(false);
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(t("fetchingRevisionUsersError"));
        }
    }, [error]);
    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onClose} closeButton closeOnOverlayClick>
            <div className="flex flex-col px-8">
                <div className="flex items-center space-x-4">
                    <AddRevisorIcon boxSize={10} />
                    <h2 className="title-3">{t("selectSigners")}</h2>
                </div>

                <p className="text-sm m-4 mb-2">{t("newSignerText")}</p>
                <LoadingBar isLoading={loading} />
                <SignersSearchMenu items={filteredSigners} onSelect={handleSelect} />

                <ul className="mt-4 space-y-4">
                    {selectedSigners?.length ? (
                        selectedSigners?.map((user, idx) => (
                            <SignerItem
                                isExternal={user.isExternal}
                                key={user.id}
                                user={user}
                                onChangeType={() => {}}
                                onDelete={() => handleDeleteSigner(user.id)}
                            />
                        ))
                    ) : (
                        <div className="py-6 text-gray-600 text-center">{t("noSignersSelectedYet")}</div>
                    )}
                </ul>

                <div className="flex justify-end mt-4">
                    <Button rounded="full" size="md" fontWeight="medium" variant="solid" onClick={onSubmit}>
                        {t("positionSignatures")}
                    </Button>
                </div>

                {/* <Divider my={4} />

                <div className="w-full flex justify-between items-center">
                    <div className="w-6/12">
                        <div className="flex items-center space-x-2">
                            <LinkIcon boxSize={6} />
                            <h3 className="text-brand-600 text-xl font-bold">Obtener enlace</h3>
                        </div>
                        <p className="text-sm">Cualquier usuario de internet con este enlace pude ver y comentar.</p>
                    </div>
                    <Button fontWeight="light" fontSize="sm" colorScheme="brand" variant="link">
                        Copiar enlace
                    </Button>
                </div> */}
            </div>
        </CommonModal>
    );
};

export default ContractSignaturesModal;
