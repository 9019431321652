import { TranslationKeys } from "src/i18n/en";
import logoOrkestadoc from "../../assets/images/logo-scm-alt.png";
import otpLogo from "../../assets/images/otp-logo.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import OtpInput from 'react-otp-input';
import { Button, useBoolean } from "@chakra-ui/react";
import LinkButton from "src/components/common/buttons/LinkButton";
import useAuthState from 'src/store/authState';
import { useNavigate } from "react-router-dom";
import useAdministrationState from "src/store/administrationState";
import useCustomToast from "src/hooks/useCustomToast";
import useGlobalState from "src/store/globalState";

const OtpPage: () => JSX.Element = () => {
    /** */
    const t: ( key: TranslationKeys ) => string = useTranslation("global")[0];
    const navigate = useNavigate();
    const [ otp, setOtp ] = useState('');
    const [ otpLength, setOtpLength ] = useState<number>( 5 );
    const [ otpExpiration, setOtpExpiration ] = useState<number>( 0 );
    const [ isLoading, toggleLoading ] = useBoolean( false );
    const { authPolicyData } = useAuthState( s => s );
    const setAuthPolicyData = useAuthState( s => s.setAuthPolicyData );
    const { validateOtpCode } = useAdministrationState();
    const onLogin = useAuthState( s => s.onLogin );
    const { getUserInfo, getUserCompanyInfo, setIsSelectedChildCompany } = useGlobalState()

    const toast = useCustomToast();

    useEffect( () => {
        /** */
        if ( !authPolicyData?.userId ) {
            setAuthPolicyData( JSON.parse(
                sessionStorage.getItem( 'authPolicyData' )
            ));

            !authPolicyData && (
                navigate( 'onboarding', { replace: true } )
            );
        }
    }, []);

    useEffect( () => {
        /** */
        if ( authPolicyData ) {
            for ( const policy of authPolicyData.passwordPolicyList ) {
                const { policyType, active, value } = policy;

                if ( policyType === 'OTP_EXPIRATION' && active ) {
                    setTimeout( () => setOtpExpiration( Number( value ) + 1 ), 0 );
                }

                if ( policyType === 'OTP_LENGHT' ) setOtpLength( Number( value ) );
            }
        }
    }, [authPolicyData]);

    const handleBackToLogin = () => {
        /** */
        navigate( 'login', { replace: true } );
    };

    // const handleReSendCode = () => {
    //     /** */
    //     console.log('resend');
    // }

    const handleSendCode = () => {
        /** */
        validateOtpCode( authPolicyData.id, otp ).then( result => {
            if ( result.codigo === 0 ) {
                  onLogin( result.respuesta ).then(
                    async response => {
                        if ( response ) {
                            getUserInfo( response.userId )

                            const companyData = await getUserCompanyInfo()

                            if ( companyData && companyData.group ) {
                                navigate( "/group-selection" )
                            } else {
                                setIsSelectedChildCompany( true )
                                navigate( "/onboarding" )
                            }
                        }
                    }
                )
            } else {
                toast.error( result.mensaje );
            }
        });
    }

    return (
        <>
            <div className="h-screen w-screen flex">
                <div className="w-full md:w-6/12 flex justify-center items-center flex-col gap-8">
                    <div className="w-full flex w-3/5">
                        <LinkButton
                            onClick={ () => handleBackToLogin() }>
                            Regresar
                        </LinkButton>
                    </div>
                    <div className="flex flex-col items-center gap-4">
                        <img src={otpLogo} className="w-4/12" />
                        <h1 className="text-xl font-bold">
                            Autenticación a 2 pasos
                        </h1>
                    </div>
                    <div className="flex flex-col items-center gap-6">
                        <p className="text-center w-3/5">
                            Ingrese el código de verificación que hemos enviado al correo 
                            <span className="font-semibold"> {authPolicyData?.email}</span>
                            {otpExpiration !== 0 && ` que tiene una validez de ${otpExpiration} ${otpExpiration === 1 ? 'minuto' : 'minutos'}`}
                        </p>
                        <div className="otp-inputs">
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={otpLength}
                                // renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col items-center">
                        {/* <LinkButton
                            onClick={ () => handleReSendCode() }>
                            Reenviar código
                        </LinkButton> */}
                        <Button type="button" className="mt-6"
                            rounded="full" size="lg" fontWeight="medium" isLoading={isLoading}
                            onClick={ () => handleSendCode() }>
                            Verificar
                        </Button>
                    </div>
                </div>
                <div className="w-6/12 hidden md:flex flex-col justify-between items-center py-24"
                    style={{
                        backgroundImage: "url('/images/auth-bg.png')",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                    }}>
                    <div className="h-12"></div>
                    <div>
                        <img src={logoOrkestadoc} className="h-20" alt="axeleratum" />
                    </div>
                    <div className="w-10/12 lg:w-8/12 text-gray-100 text-center">
                        {t("smartContractMaker")}
                    </div>
                </div>
            </div>
        </>
    );
}

export default OtpPage;