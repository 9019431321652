import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { FaUserCircle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import CommonModal from "../../CommonModal";
import LinkButton from "../../buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import {
    Checkbox,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    tempComponent?: any;
    isReq?: boolean;
    onSave: (...args: any) => any;
    editTopic: any;
    isSignable: boolean;
};

const EditUserModal = ({ isOpen, onClose, isReq = false, onSave, tempComponent, editTopic, isSignable }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [topic, setTopic] = useState({
        active: true,
        componentId: tempComponent?.uuid,
        notificationTypeEnum: "platform",
        subTopicTypeEnum: null,
        topPercentage: 100,
        topicTypeEnum: "",
        delete: false,
    });
    const [error, setError] = useState("");

    console.log(topic);

    const handleChangeSelect = (e) => {
        setTopic({
            ...topic,
            [e.target.name]: e.target.value,
        });
    };

    const handleChangePercentage = (value) => {
        setTopic({
            ...topic,
            topPercentage: Number(value),
        });
    };

    const handleChangeCheckboxes = (e) => {
        setTopic({
            ...topic,
            [e.target.name]: e.target.checked,
        });
    };

    const handleSave = () => {
        if (!topic.topicTypeEnum) {
            setError("Debe seleccionar una acción.");
            return;
        }

        if (topic.topicTypeEnum === "update" && !topic.subTopicTypeEnum) {
            setError("Debe seleccionar una etapa.");
            return;
        }

        onSave(topic);
    };

    useEffect(() => {
        if (editTopic?.componentId === tempComponent?.uuid) {
            setTopic(editTopic);
        }
    }, [editTopic, tempComponent]);

    return (
        <CommonModal size="sm" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">Generación de notificaciones</h2>

                {isReq ? (
                    <form className="flex flex-col items-center">
                        esto es para los docs
                        <FormControl className="mt-6">
                            <InputGroup>
                                <Select
                                    id="templateId"
                                    size="md"
                                    rounded="none"
                                    onChange={handleChangeSelect}
                                    name="topicTypeEnum"
                                    // defaultValue={formik.values.}
                                >
                                    <option value="">{t("select")}</option>
                                    {["complete", "upload", "delete"].map((topic) => (
                                        <option
                                            // key={temp.id}
                                            value={topic}
                                        >
                                            {topic}
                                        </option>
                                    ))}
                                </Select>
                            </InputGroup>
                        </FormControl>
                        {topic?.topicTypeEnum === "complete" && (
                            <FormControl className="mt-6">
                                <label htmlFor="name" className="text-sm leading-8">
                                    Seleccione el porcentaje en el que será notificado
                                </label>
                                <InputGroup borderColor="gray.400">
                                    <NumberInput
                                        id="maxDocs"
                                        name="maxDocs"
                                        defaultValue={10}
                                        min={10}
                                        max={100}
                                        size="sm"
                                        step={10}
                                        // onChange={handleChangeMaxDocs}
                                        className="w-full"
                                        // isDisabled={docsAllowed?.minDocs === -1 || docsAllowed?.maxDocs === -1}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </InputGroup>
                            </FormControl>
                        )}
                        {topic?.topicTypeEnum === "upload" && (
                            <FormControl className="flex items-center mt-6">
                                <label htmlFor="name" className="text-sm leading-8">
                                    Notificar al borrar documentos
                                </label>
                                <InputGroup borderColor="gray.400" className="ml-2 !w-fit">
                                    <Checkbox
                                        rounded="10"
                                        id="unlimitedDocs"
                                        name="unlimitedDocs"
                                        // checked={formik.values.tAndC}
                                        // onChange={}
                                        size="md"
                                    />
                                </InputGroup>
                            </FormControl>
                        )}
                        {topic?.topicTypeEnum === "delete" && (
                            <FormControl className="mt-6">
                                <InputGroup>
                                    <Select
                                        id="templateId"
                                        size="md"
                                        rounded="none"
                                        // onChange={formik.handleChange}
                                        // defaultValue={formik.values.}
                                    >
                                        <option value="">{t("select")}</option>
                                        <option value="complete">Al completar</option>
                                        <option value="upload">Al subir</option>
                                        <option value="update">Al actualizar</option>
                                    </Select>
                                </InputGroup>
                            </FormControl>
                        )}
                        <label htmlFor="name" className="text-sm leading-8 mt-6 font-bold text-[#0327e2]">
                            Seleccione los medios por donde será notificado:
                        </label>
                        <div className="flex w-full justify-between">
                            <FormControl className="flex items-center w-fit">
                                <label htmlFor="name" className="text-sm leading-8">
                                    Plataforma
                                </label>
                                <InputGroup borderColor="gray.400" className="ml-2">
                                    <Checkbox
                                        rounded="10"
                                        id="unlimitedDocs"
                                        name="unlimitedDocs"
                                        // checked={formik.values.tAndC}
                                        // onChange={}
                                        size="md"
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl className="flex items-center w-fit">
                                <label htmlFor="name" className="text-sm leading-8">
                                    Email
                                </label>
                                <InputGroup borderColor="gray.400" className="ml-2">
                                    <Checkbox
                                        rounded="10"
                                        id="unlimitedDocs"
                                        name="unlimitedDocs"
                                        // checked={formik.values.tAndC}
                                        // onChange={}
                                        size="md"
                                    />
                                </InputGroup>
                            </FormControl>
                        </div>
                        <div className="mt-10">
                            <Button rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                                {t("save")}
                            </Button>
                        </div>
                    </form>
                ) : (
                    <form className="flex flex-col items-center">
                        <label htmlFor="name" className="text-sm leading-8 mt-6 font-bold text-[#0327e2] self-start">
                            Elija la acción mediante la cual será notificado:
                        </label>
                        <FormControl className="mt-2">
                            <InputGroup>
                                <Select
                                    id="templateId"
                                    size="md"
                                    rounded="none"
                                    name="topicTypeEnum"
                                    onChange={handleChangeSelect}
                                    defaultValue={topic?.topicTypeEnum}
                                >
                                    <option value="">{t("select")}</option>
                                    <option value="complete">Al completar</option>
                                    <option value="upload">Al subir</option>
                                    <option value="update">Al actualizar</option>
                                </Select>
                            </InputGroup>
                        </FormControl>

                        {topic?.topicTypeEnum === "complete" && (
                            <FormControl className="mt-6">
                                <label htmlFor="name" className="text-sm leading-8 mt-6 font-bold text-[#0327e2] self-start">
                                    Seleccione el porcentaje en el que será notificado
                                </label>
                                <InputGroup borderColor="gray.400" className="mt-2">
                                    <NumberInput
                                        id="topPercentage"
                                        name="topPercentage"
                                        min={10}
                                        max={100}
                                        size="sm"
                                        step={5}
                                        onChange={handleChangePercentage}
                                        className="w-full"
                                        defaultValue={topic?.topPercentage}
                                    >
                                        <NumberInputField />
                                        <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                        </NumberInputStepper>
                                    </NumberInput>
                                </InputGroup>
                            </FormControl>
                        )}

                        {topic?.topicTypeEnum === "upload" && (
                            <FormControl className="flex items-center mt-6">
                                <label htmlFor="name" className="text-sm leading-8 font-bold text-[#0327e2]">
                                    Notificar al borrar documentos
                                </label>
                                <InputGroup borderColor="gray.400" className="ml-2 !w-fit">
                                    <Checkbox
                                        rounded="10"
                                        id="delete"
                                        name="delete"
                                        isChecked={topic?.delete}
                                        onChange={handleChangeCheckboxes}
                                        size="md"
                                    />
                                </InputGroup>
                            </FormControl>
                        )}

                        {topic?.topicTypeEnum === "update" && (
                            <FormControl className="mt-6">
                                <label htmlFor="name" className="text-sm leading-8 mt-6 font-bold text-[#0327e2] self-start">
                                    Seleccione la etapa en la que será notificado
                                </label>
                                <InputGroup className="mt-2">
                                    <Select
                                        id="templateId"
                                        size="md"
                                        rounded="none"
                                        name="subTopicTypeEnum"
                                        onChange={handleChangeSelect}
                                        defaultValue={topic?.subTopicTypeEnum}
                                    >
                                        <option value="">{t("select")}</option>
                                        <option value="reviewUpdate">Estatus de revisión</option>
                                        {isSignable && <option value="signUpdate">Estatus de firma</option>}
                                        <option value="versionUpdate">Cambio de versión</option>
                                    </Select>
                                </InputGroup>
                            </FormControl>
                        )}

                        <label htmlFor="name" className="text-sm leading-8 mt-6 font-bold text-[#0327e2]">
                            Seleccione los medios por donde será notificado:
                        </label>
                        <FormControl className="mt-2">
                            <InputGroup>
                                <Select
                                    id="notificationTypeEnum"
                                    size="md"
                                    rounded="none"
                                    name="notificationTypeEnum"
                                    onChange={handleChangeSelect}
                                    defaultValue={topic?.notificationTypeEnum}
                                >
                                    <option value="platform">Plataforma</option>
                                    <option value="mail">Email</option>
                                    <option value="both">Plataforma e Email</option>
                                </Select>
                            </InputGroup>
                        </FormControl>
                        {error && <p className="text-red-500 mt-4">{error}</p>}
                        <div className="mt-8">
                            <Button onClick={handleSave} rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                                {t("save")}
                            </Button>
                        </div>
                    </form>
                )}
            </div>
        </CommonModal>
    );
};

export default EditUserModal;
