import React from "react";
import { Button, Tooltip } from "@chakra-ui/react";
import { AiOutlineUpload } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const TruncatedButton = ({ disabled, text, extensionsAllowed, onClick }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <Button
            disabled={disabled}
            size="xs"
            fontSize="sm"
            fontWeight="medium"
            colorScheme="teal"
            rightIcon={<AiOutlineUpload size={16} />}
            className="w-[200px]"
            onClick={onClick}
        >
            {extensionsAllowed.length > 2 ? (
                <Tooltip label={text} placement="top" hasArrow>
                    <span className="truncate">{text}</span>
                </Tooltip>
            ) : (
                <span className="truncate">{text}</span>
            )}
        </Button>
    );
};

export default TruncatedButton;
