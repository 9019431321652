import useAdministrationState from "./administrationState";
import useAuthState from "./authState";
import useContractRevisionState from "./contractRevisionState";
import useContractState from "./contractState";
import useDataGroupsState from "./dataGroupsState";
import useDataTypesState from "./dataTypesState";
import useGlobalState from "./globalState";
import useNotificationsState from "./notificationsState";
import useTemplateState from "./templatesState";
import useVaultState from "./vaultState";
import useBiddingState from "./biddings/biddingState";
import useBiddingTypesState from "./biddings/biddingTypesState";
import useComponetState from "./components/componentState";
import useDashboardState from "./dashboardState";
import useModalState from "./modalsState";
import { useModalStore } from "./modal.store";
import { useIntervalStore } from "./interval.store";
import usePdfSignaturesState from "./pdfSignatureState";
import useGeneradorStatus from "./biddings/GeneradorStatus";

export function resetStore() {
    useAuthState.getState().reset();
    useContractState.getState().reset();
    useContractRevisionState.getState().reset();
    useDataGroupsState.getState().reset();
    useDataTypesState.getState().reset();
    useTemplateState.getState().reset();
    useNotificationsState.getState().reset();
    useVaultState.getState().reset();
    useAdministrationState.getState().reset()
    useGlobalState.getState().reset();
    useBiddingState.getState().reset();
    useBiddingTypesState.getState().reset();
    useComponetState.getState().reset();
    useDashboardState.getState().reset();
    useModalState.getState().reset();
    useModalStore.getState().reset();
    useIntervalStore.getState().reset();
    usePdfSignaturesState.getState().reset();
    useGeneradorStatus.getState().reset();
}
