import { IoClose, IoTrashOutline, IoCreateOutline } from "react-icons/io5";
import CommonTh from "../common/table/CommonTh";
import useModalState from "../../store/modalsState";
import DatePickerTh from "../common/table/DatePickerTh";
import TablePaginator from "../common/table/TablePaginator";
import SearchInput from "../common/table/SearchInput";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../lib/utils";
import usePagination from "../../hooks/usePagination";
import useCustomToast from "../../hooks/useCustomToast";
import { MouseEvent } from "react";
import { Tooltip } from "@chakra-ui/react";
import LinkButton from "../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { BiddingType } from "src/models/biddings/BiddingType.model";
import useBiddingTypesFilter from "src/hooks/table-filters/useBiddingTypesFilter";
import useBiddingTypesState from "src/store/biddings/biddingTypesState";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";
import { GrClone } from "react-icons/gr";

const TableRow = ({
    biddingType,
    onDelete,
    onEdit,
    onClone,
}: {
    biddingType: BiddingType;
    onDelete: (event, biddingType) => void;
    onEdit: (event, biddingType) => void;
    onClone: (event, biddingType) => void;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const navigate = useNavigate();

    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200 cursor-pointer" onClick={(e) => onEdit(e, biddingType)}>
            {/* <td className="w-4 px-2 py-3" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center">
                    <Checkbox />
                </div>
            </td> */}
            <td className="px-2 py-3">{biddingType.name}</td>
            <td className="px-2 py-3">{formatDate(biddingType?.createdAt)}</td>
            <td className="px-2 py-3">{formatDate(biddingType?.updatedAt)}</td>
            <td className="px-2 py-3">{biddingType.documentSet?.name}</td>
            <td className="px-1 py-3">
                <div className="w-full flex xl:w-8/12 justify-center gap-6">
                    <Tooltip label="Clonar" fontSize="12px">
                        <button onClick={(e) => onClone(e, biddingType)}>
                            <GrClone size={22} />
                        </button>
                    </Tooltip>
                    <Tooltip label={t("edit")} fontSize="12px">
                        <button onClick={(e) => onEdit(e, biddingType)}>
                            <IoCreateOutline size={25} />
                        </button>
                    </Tooltip>
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={(e) => onDelete(e, biddingType)}>
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const BiddingTypesTable = ({ biddingTypes }: { biddingTypes: BiddingType[] }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const setDeleteModal = useModalState((s) => s.setDeleteCommonModal);
    const deleteBiddingType = useBiddingTypesState((s) => s.deleteBiddingType);
    const getBiddingTypes = useBiddingTypesState((s) => s.getBiddingTypesByCompany);
    const setBiddingTypeModal = useModalState((s) => s.setBiddingTypeModal);
    const cloneBiddingType = useBiddingTypesState((S) => S.cloneBiddingType);

    const { filteredBiddings, handleDateFilter, isFilterActive, clearFilters, handleSearchFilter } = useBiddingTypesFilter(biddingTypes);
    const {
        currentResutls: paginatedBiddingTypes,
        currentPage,
        maxPages,
        prev,
        next,
        setPage,
    } = usePagination<BiddingType>({ data: filteredBiddings, itemsPerPage: 6 });

    async function onDeleteBiddingType(biddingType: BiddingType) {
        const deleted = await deleteBiddingType(biddingType.id);
        if (deleted) {
            toast.success(t("deletedProccessTypeSuccess"));
            getBiddingTypes(false);
        } else {
            toast.error(t("deletedProccessTypeError"));
        }
    }

    function handleDelete(e: MouseEvent, biddingType: BiddingType) {
        e.stopPropagation();
        setDeleteModal({
            show: true,
            data: { text1: t("proccessTypeWillBeDeleted"), text2: biddingType.name, onDelete: () => onDeleteBiddingType(biddingType) },
        });
    }

    function handleEdit(e: MouseEvent, biddingType: BiddingType) {
        e.stopPropagation();
        setBiddingTypeModal({
            show: true,
            data: {
                biddingTypeId: biddingType.id,
                typeModal: "Edit",
                biddingTypeStages: biddingType.documentSet,
                defaultUrlPowerBi: biddingType.defaultUrlPowerBi,
                biddingTypePattern: biddingType.formatNewProccessName,
                biddingTypeCategory: biddingType.superCategority,
                biddingTypeFields: {
                     hasEndDate: biddingType.hasEndDate,
                     hasUrlPowerBi: biddingType.hasUrlPowerBi, 
                     hasExpedienteUnico : biddingType.hasExpedienteUnico, 
                     hasUrlGeneradores : biddingType.hasUrlGeneradores
                    }
            },
        });
    }

    async function handleClone(e: MouseEvent, biddingType: BiddingType) {
        e.stopPropagation();
        const cloned = await cloneBiddingType(biddingType);
        if (cloned) {
            toast.success(t("clonedBiddingTypeSuccess"));
            getBiddingTypes(false);
        } else {
            toast.error(t("clonedBiddingTypeSError"));
        }
    }

    return (
        <>
            <div className="w-full flex justify-between items-center">
                <DottedSubtitle
                    text={t("myProccessTypes")}
                    withSearch
                    onSearch={handleSearchFilter}
                    beforeSearchElement={
                        isFilterActive && (
                            <LinkButton onClick={clearFilters} color="red.400">
                                <IoClose size={20} /> {t("cleanFilters")}
                            </LinkButton>
                        )
                    }
                />
            </div>
            <div>
                <table className="w-full text-sm text-left text-dark-2">
                    <thead className="text-base text-brand-500 font-medium border-b">
                        <tr>
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("name")} onClick={() => {}} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <DatePickerTh text={t("creation")} onChange={(args: any) => handleDateFilter(args, "createdAt")} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <DatePickerTh text={t("lastVersion")} onChange={(args: any) => handleDateFilter(args, "updatedAt")} />
                            </th>
                            <th scope="col" className="px-2 py-4">
                                <CommonTh text={t("documentSet")} onClick={() => {}} />
                            </th>
                            <th scope="col" className="px-1 py-1">
                                {t("actions")}
                            </th>
                        </tr>
                        <tr></tr>
                    </thead>
                    <tbody>
                        {paginatedBiddingTypes?.map((item) => (
                            <TableRow
                                key={item.id}
                                biddingType={item}
                                onDelete={(e) => handleDelete(e, item)}
                                onEdit={(e) => handleEdit(e, item)}
                                onClone={(e) => handleClone(e, item)}
                            />
                        ))}
                    </tbody>
                </table>
                <div className="flex justify-between items-center">
                    {paginatedBiddingTypes?.length ? (
                        <div className="w-full py-6">
                            <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                        </div>
                    ) : (
                        <div className="w-full text-center p-2 text-dark">{t("notElementsFound")}</div>
                    )}
                </div>
            </div>
        </>
    );
};
export default BiddingTypesTable;
