import { boolean } from "yup";
import create, { GetState } from "zustand";
import { devtools, NamedSet, persist } from "zustand/middleware";

interface State {
    signers: any; // Cambiado a tipo 'any' para que puedas almacenar cualquier tipo de valor
    infoContract: any; // Variable infoContract
    statusContract: any; // Variable estatusContract
    isSignature: boolean;
    reviewStatusDocument: any;
    isReview: boolean;
    currentRequiriment: any;
    currentBidding: any;
    setInfoContract: (info: any) => void; // Función para actualizar infoContract
    setStatusContract: (estatus: any) => void; // Función para actualizar estatusContract
    setIsSignature: (info: any) => void; // Función para actualizar infoContract
    setReviewStatusDocument: (estatus: any) => void; // Función para actualizar estatusContract
    setIsReview: (status: any) => void; // Función para actualizar infoContract
    setCurrentRequiriment: (requirement: any) => void;
    setCurrentBidding: (bidding: any) => void;
    reset: () => void;
}

const initialState = {
    signers: null, // Cambiado a 'null' para indicar que aún no hay datos
  infoContract: {} as any, // Usa `any` para mantener flexibilidad en los tipos
    statusContract: null,
    isSignature: false,
    isReview: false,
    currentRequiriment: null,
    reviewStatusDocument: null,
    currentBidding: null
};

const usePdfSignaturesState = create<State>(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                setInfoContract: setInfoContract(set, get),
                setStatusContract: setStatusContract(set, get),
                setIsSignature: setIsSignature(set, get),
                setReviewStatusDocument: setReviewStatusDocument(set, get),
                setIsReview: setIsReview(set, get),
                setCurrentRequiriment: setCurrentRequiriment(set, get),
                setCurrentBidding: setCurrentBidding(set, get),
                reset: () => set((state) => ({ ...state, ...initialState })),
            }),
            { name: "documentFloww", getStorage: () => sessionStorage }
        ),
        { name: "PdfSignaturesState" }
    )
);


export default usePdfSignaturesState;

//FUNCTIONS

function setInfoContract(set: NamedSet<State>, get: GetState<State>) {
    return (info: any) => {
        set({ infoContract: info });
    };
}

function setStatusContract(set: NamedSet<State>, get: GetState<State>) {
    return (status: any) => {
        if (status === "pendiente de firmas") {
            set({ isSignature: true });
        }
        set({ statusContract: status });
    };
}


function setIsSignature(set: NamedSet<State>, get: GetState<State>) {
    return (status: any) => {
        set({ isSignature: status });
    };
}

function setReviewStatusDocument(set: NamedSet<State>, get: GetState<State>) {
    return (status: any) => {
        /*   if (status === "pendiente de firmas"){
               set({ isSignature: true });
           }*/
        set({ reviewStatusDocument: status });
    };
}

function setIsReview(set: NamedSet<State>, get: GetState<State>) {
    return (status: any) => {
        set({ setIsReview: status });
    };
}

function setCurrentRequiriment(set: NamedSet<State>, get: GetState<State>) {
    return (requirement: any) => {
        set({ currentRequiriment: requirement });
    };
}

function setCurrentBidding(set: NamedSet<State>, get: GetState<State>) {
    return (bidding: any) => {
        set({ currentBidding: bidding });
    };
}
