import { useIntervalStore } from "src/store/interval.store"
import useAuthState from "src/store/authState"
import { useTimerStore } from "src/store/timer.store"
import authService from "src/services/auth.service"
import CustomAlertDialog from "src/components/CustomAlertDialog"

const DownloadPowerBIAlert: React.FC = () => {
    /** */
    return (
        <CustomAlertDialog
            id="DownloadPowerBIAlert"
            title="Descarga de reporte por correo"
            acceptText="Aceptar"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCancelButton={false}
        >
            {() => (
                <p>
                  En unos minutos recibirá un correo con la información del reporte actual
                </p>
            )}
        </CustomAlertDialog>
    )
}

export default DownloadPowerBIAlert