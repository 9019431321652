import { Box, List, ListItem, Progress, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiCheck, BiX } from "react-icons/bi";
import { PasswordPolicyList } from "src/models/Aministration.models";
import useAdministrationState from "src/store/administrationState";

interface SafetyProps {
  errorPolicies: any
  policies: PasswordPolicyList[]
  setPolicies: (policies: PasswordPolicyList[]) => void
}


const ResetPassSafetyPolicies = ( {errorPolicies, policies, setPolicies}: SafetyProps ) => {
    /** */

    useEffect( () => {        
        if ( errorPolicies ) {
            const updatedPolicies = policies.map( policy => {
              policy.hasError = false;
                for ( const key in errorPolicies ) {
                    key === policy.policyType && (
                        policy.hasError = true
                    );
                }

                return policy;
            });

            setPolicies( updatedPolicies );
        }
    }, [ errorPolicies ]);

    return (
        <Box>
            <List>
                {policies.map(( policy, index ) => (
                    <ListItem key={index} className="flex items-center">
                        {policy.hasError
                            ? <BiX color="red" className="mr-4 mb-2" />
                            : <BiCheck color="green" className="mr-4 mb-2" />}
                        <Text fontSize={12} color={policy.hasError ? 'red.500' : 'green.500'}>
                            {policy.valueType === 'NUMERIC' ? `${policy.description} ${policy.value}` : policy.description}
                        </Text>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default ResetPassSafetyPolicies;