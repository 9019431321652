import { Accordion, useBoolean } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import GoBackLinkButton from "../../components/common/buttons/GoBackLinkButton";
import LinkButton from "../../components/common/buttons/LinkButton";
import PreviewLinkButton from "../../components/common/buttons/PreviewLinkButton";
import MainLayout from "../../components/layouts/MainLayout";
import PdfViewer from "../../components/common/PdfViewer";
import DraftActions from "../../components/contracts/actions-buttons-groups/DraftActions";
import InVaultActions from "../../components/contracts/actions-buttons-groups/InVaultActions";
import PendingSignActions from "../../components/contracts/actions-buttons-groups/PendingSignActions";
import RevisionActions from "../../components/contracts/actions-buttons-groups/RevisionActions";
import RevisionAccordionItems from "../../components/contracts/contract-revision/RevisionAccordionItems";
import TraceabilityAccordionItems from "../../components/contracts/contract-traceability/TraceabilityAccordionItems";
import ContractDataGroupsForms from "../../components/contracts/ContractDataGroupsForms";
import ContractEditorPanel from "../../components/contracts/ContractEditorPanel";
import ContractGeneralInfo from "../../components/contracts/ContractGeneralInfo";
import ContractSteps from "../../components/contracts/ContractSteps";
import SignaturesAccordionItems from "../../components/contracts/contract-signatures/SignaturesAccordionItems";
import SignaturesView from "../../components/contracts/contract-signatures/SignaturesView";
import useCustomToast from "../../hooks/useCustomToast";
import usePdfPreview from "../../hooks/usePdfPreview";
import { Contract } from "../../models/Contract.models";
import { ContractSigner } from "../../models/Signatures.models";
import contractSignaturesService from "../../services/contractSignatures.service";
import useContractRevisionState from "../../store/contractRevisionState";
import useContractSignaturesState from "../../store/contractSignatureState";
import useContractState from "../../store/contractState";
import useModalState from "../../store/modalsState";
import { formatDate, getContractStepByStatus, getTitleByStatus, isObjectId } from "../../lib/utils";
import SignedActions from "../../components/contracts/actions-buttons-groups/SignedActions";
import { AiOutlineFileAdd } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import Table from "./HandsonTable";
import TableNoEdit from "./TableNoEdit";
import TarifasCorp from "./TarifasCorp";
import TableRules from "./TableRules";
import TableAmort from "./TableAmort";
import useVaultState from "src/store/vaultState";
import useAuthState from "src/store/authState";

const ShowEditContractPage = () => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const { id } = useParams();

    const {
        setPdfPreviewModal,
        setLoaderModal,
        setContractRevisionModal,
        setTokenIlustrationModal,
        setContractSignersSelectModal,
        setUploadContractAnnexModal,
    } = useModalState();
    const setSelectedSigners = useContractSignaturesState((s) => s.setSelectedSigners);
    const uploadDocumentToSession = useVaultState((s) => s.addDocumentToSession);
    const { selectedContract, getContract, setSelectedContract, updateContract, uiOptions, saveOnVault, createFolder, createToken } =
        useContractState();
        const {hasSavedContract, setHasSavedContract}= useContractState();
    const { cancelRevision, currentReviewerEditionChanges, setCurrentReviewerEditionChanges } = useContractRevisionState();
    const { generatePdfPreviewUrl, pdfBlobUrl, generatePdfBlob, generatePdfVaultUrl } = usePdfPreview();
    const [isSaving, setIsSaving] = useBoolean(false);
    const [isSavingOnVault, setIsSavingOnVault] = useBoolean(false);
    const [showSignaturesView, setShowSignaturesView] = useBoolean(false);
    const [initialDataGroupsSnapshot, setInitialDataGroupsSnapshot] = useState<string>(null);
    const tokenExpiresIn = useAuthState((s) => s.tokenExpiresIn);


    function buildImgElement(base64String) {
        // Construir la cadena con la estructura del elemento img
        if (!base64String) {
            console.error("La cadena Base64 proporcionada está vacía o nula.");
            return ""; // O cualquier otro valor de retorno que desees
        }
        const base64Format = base64String.replace(/"/g, '');
        const imgUrl = `data:image/png;base64,${base64Format}`;
        return imgUrl;
    }

    async function handlePreview() {
        let pdfUrl = null;
        if (!selectedContract?.text) {
            return toast.error(t("thereIsNoContentToPreview"), t("warning"));
        }
        setLoaderModal(true);
        try {
            if (selectedContract?.idVault) {
                // Si está en la bóveda
                pdfUrl = await generatePdfVaultUrl(selectedContract?.idVault);
            } else {

                     // verificar si existe footer - header generar elemento img
            const headerImgUrl = selectedContract?.template?.header ? buildImgElement(selectedContract.template.header) : "";
            const footerImgUrl = selectedContract?.template?.footer ? buildImgElement(selectedContract.template.footer) : "";
                pdfUrl = await generatePdfPreviewUrl(
                    currentReviewerEditionChanges || selectedContract.text,
                    headerImgUrl,
                    footerImgUrl
                    //selectedContract?.template?.header,
                    //selectedContract?.template?.footer
                );
            }
        } catch (error) {
            toast.error(t("errorWhileGeneratingPreview"));
        } finally {
            setLoaderModal(false);
        }

        return pdfUrl;
    }

    async function handlePreviewModal() {
        const pdfBlobUrl = await handlePreview();
        !!pdfBlobUrl && setPdfPreviewModal({ show: true, data: { pdfBlobUrl } });
    }

    async function onInit() {
        if (isObjectId(id)) {
            const validContract = await getContract(id);
            if (validContract) {
                setInitialDataGroupsSnapshot(JSON.stringify(validContract?.template?.dataGroup));
                return;
            }
        }

        toast.error("Invalid ID");
        navigate("../");
    }

    function saveContract(contract?: Contract) {
        setIsSaving.on();
        setTimeout(async () => {
            const saved = await updateContract(contract ?? selectedContract, false, true);
            setIsSaving.off();
            if (saved) {
                toast.success(t("updatedContract"));
                onInit();
                return navigate('../')
            } else {
                toast.error(t("savingContractError"));
            }
        }, 400);
    }

    function handleRevision() {
        const hasChanges = JSON.stringify(selectedContract?.template?.dataGroup) !== initialDataGroupsSnapshot;
        if (hasChanges) {
            toast.error(t("saveChangesBeforeRevision"), t("warning"));
            return;
        }
        setContractRevisionModal({
            show: true,
            data: { contractId: id, afterSubmit: () => getContract(id), nameContract: selectedContract?.name },
        });
    }

    async function handleCancelRevision() {
        const canceled = await cancelRevision(id);
        if (canceled) {
            getContract(id);
            toast.success(t("revisionCanceled"));
        } else {
            toast.error(t("cancelingRevisionError"));
        }
    }

    async function handleSaveOnVault() {
        setIsSavingOnVault.on();
        setLoaderModal(true);
        try {
            const folderRes = await createFolder(selectedContract?.name, false);
            if (!folderRes.data?.id) throw new Error();


            const pdfBlob = await generatePdfBlob(
                selectedContract?.text,
                buildImgElement(selectedContract?.template.header),
                buildImgElement(selectedContract?.template.footer)
            );

            const formData = new FormData();
            formData.append("file", pdfBlob);

            const blobBody = {
                file: formData,
            };

            const res = await uploadDocumentToSession(blobBody);
            if (res.uploaded) {
                await saveOnVault(pdfBlob, folderRes.data.id, res?.response, false);
            }
        } catch (error) {
            toast.error(t("creatingFolderError"));
        } finally {
            setLoaderModal(false);
            setIsSavingOnVault.off();
        }
    }

    async function handleSign() {
        setContractSignersSelectModal({
            show: true,
            data: {
                afterSubmit: (signers: ContractSigner[]) => {
                    setSelectedSigners(signers);
                    if (pdfBlobUrl) return setShowSignaturesView.on();
                    setLoaderModal(true);
                    generatePdfVaultUrl(selectedContract?.idVault)
                        .then((url) => {
                            setShowSignaturesView.on();
                        })
                        .finally(() => setLoaderModal(false));
                },
            },
        });
    }

    async function handleOnSendSignatures(stickersValue: any) {
        const STICKER_W = 130;
        const STICKER_H = 30;
        const QR = [{ height: "80", pages: "all", width: "80", x: "0", y: "0" }];

        let stickers = stickersValue.map((sv: any) => {
            return {
                id: sv.userId,
                authority: "Vinculada a Correo Electronico por Liga",
                page: parseInt(sv.page || 1) - 1,
                rect: {
                    lx: Math.round(sv.convertedCoords[0]),
                    tx: Math.round(sv.convertedCoords[0]) + STICKER_W,
                    ty: Math.round(sv.convertedCoords[1]) + STICKER_H,
                    ly: Math.round(sv.convertedCoords[1]) - 5,
                },
            };
        });

        const body = {
            idContract: id,
            reqSignatureCreateDto: {
                documentId: selectedContract?.idVault,
                qr: QR,
                signers: stickers,
            },
        };
        setLoaderModal(true);
        try {
            const res = await contractSignaturesService.sendSignatures(body);
            if (res.status === 200) {
                setSelectedContract({ ...selectedContract!, status: "pendiente de firmas" });
                toast.success(t("signaturesSendedSuccessfully"));
                setShowSignaturesView.off();
            } else {
                throw new Error();
            }
        } catch (error) {
            console.log(error);
            toast.error(t("sendingSignaturesError"));
        } finally {
            setLoaderModal(false);
        }
    }

    async function handleCancelSignatures() {
        setLoaderModal(true);
        try {
            const res: any = await contractSignaturesService.cancelSignatures(id);
            if (res.status === 200) {
                setSelectedContract({ ...selectedContract!, status: "en boveda" });
                toast.success(t("canceledSignaturesSuccessfully"));
            } else {
                throw new Error();
            }
        } catch (error) {
            console.log(error);
            toast.error(t("cancelingSignaturesError"));
        } finally {
            setLoaderModal(false);
        }
    }

    async function handleTokenization() {
        const tokenized = await createToken(selectedContract?.idVault, false);
        if (tokenized) {
            setTokenIlustrationModal({ show: true, data: { contract: selectedContract } });
            getContract(id, false);
            toast.success(t("tokenizedContractSuccessfully"));
        } else {
            toast.error(t("tokenizingContractFailed"));
        }
    }

    async function handleTokenizationFake() {
        setTokenIlustrationModal({ show: true, data: { contract: selectedContract } });
    }

    useEffect(() => {
        onInit();
        return () => {
            //clean up on unmount
            setSelectedContract(null);
            setCurrentReviewerEditionChanges(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (uiOptions.showPdfViewer && !isSavingOnVault) {
            handlePreview();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContract]);

    const shouldShowtActions = () => {
        return selectedContract?.status === selectedContract?.template?.maxLifeStage;
    };


    useEffect(() => {
        const tokenCurrentTime = tokenExpiresIn / 60;
        console.log("time show edit contract exp token",tokenCurrentTime)

        if (tokenCurrentTime < 2.5 && !hasSavedContract) {
            saveContract();
            setHasSavedContract(true);
            toast.info("El contrato se ha guardado automáticamente antes de que su sesión expirara.","¡Atención!");
        }
    }, [tokenExpiresIn]);

    
    return (
        <MainLayout>
            <main className="w-full p-3 flex h-full">
                {/* ------------------------------- left panel ------------------------------- */}
                <section className="w-3/12 flex flex-col pr-4 pl-2 border-r h-full max-h-full overflow-y-auto relative">
                    {!uiOptions.isActivated && selectedContract?.id && (
                        <>
                            <h2 className="title-3">{getTitleByStatus(selectedContract?.status)}</h2>
                            <div className="text-sm mt-2 space-y-1">
                                <p>
                                    <b>{t("contractName")}:</b> {selectedContract?.name}
                                </p>

                                {/* <p>
                                    <b>Cliente:</b> N/A
                                </p> */}
                                <p>
                                    <b>{t("date")}:</b> {formatDate(new Date(selectedContract?.createdDate || "01-01-2022"))}{" "}
                                </p>
                            </div>
                        </>
                    )}

                    {uiOptions.isActivated && (
                        <>
                            <h2 className="title-3 text-center">{selectedContract?.name}</h2>
                            <div className="text-center italic text-sm pb-4 border-b-2">
                                <p>
                                    {t("contractType")}: {selectedContract?.type}
                                </p>
                                {/* <p>Cliente: N/A</p> */}
                                <p>
                                    {t("date")}: {formatDate(new Date(selectedContract?.createdDate || "01-01-2022"))}
                                </p>
                            </div>

                            <div className="text-center mt-4">
                                <h3 className="text-lg">{t("contractToken")}:</h3>
                                <div>{selectedContract?.idVault}</div>
                                <LinkButton disabled fontSize={"xs"}>
                                    {t("showOnEtherScan")}
                                </LinkButton>
                            </div>
                        </>
                    )}

                    <div className="mt-6 pb-12">
                        <Accordion defaultIndex={[0, 1]} allowMultiple>
                            {/* la key refresca el componente cada vez se actualiza el status */}
                            {uiOptions.showRevisionAccordionItems && (
                                <RevisionAccordionItems key={selectedContract?.lastModifiedDate + "-revision"} contractId={id} />
                            )}
                            {uiOptions.showSignaturesAccordionItems && (
                                <SignaturesAccordionItems
                                    key={selectedContract?.lastModifiedDate + "-signature"}
                                    documentId={selectedContract?.idVault}
                                />
                            )}
                            {uiOptions.showTraceabilityAccordionItems && (
                                <TraceabilityAccordionItems
                                    key={"trace" + selectedContract?.status}
                                    contractId={selectedContract?.id}
                                    isPDF={false}
                                />
                            )}
                        </Accordion>
                    </div>

                    <div className="grow"></div>
                    <>
                        {uiOptions.isDraft && (
                            <DraftActions
                                onSave={() => saveContract()}
                                onSendToRevision={handleRevision}
                                isSaving={isSaving}
                                contractStatus={selectedContract?.status}
                                templateMaxLife={selectedContract?.template?.maxLifeStage}
                            />
                        )}

                        {/*uiOptions.showRevisionActions && (
                            <RevisionActions onCancelRevision={handleCancelRevision} onSaveOnVault={handleSaveOnVault} />
                        )*/}

                        {!shouldShowtActions() && uiOptions.showRevisionActions && (
                            <RevisionActions onCancelRevision={handleCancelRevision} onSaveOnVault={handleSaveOnVault} />
                        )}

                        {!shouldShowtActions() && uiOptions.IsInVault && <InVaultActions onSign={handleSign} isSaving={false} />}

                        {/*uiOptions.IsInVault && <InVaultActions onSign={handleSign} isSaving={false} />*/}
                        {!shouldShowtActions() && uiOptions.isPendingSignatures && (
                            <PendingSignActions onCancelSignatures={handleCancelSignatures} isLoading={false} />
                        )}

                        {/*uiOptions.isPendingSignatures && (
                            <PendingSignActions onCancelSignatures={handleCancelSignatures} isLoading={false} />
                        )*/}
                        {!shouldShowtActions() && uiOptions.isSigned && <SignedActions onHandleTokenization={handleTokenization} />}

                        {/*uiOptions.isSigned && <SignedActions onHandleTokenization={handleTokenization} />*/}
                    </>
                </section>

                {/* ------------------------------- right panel ------------------------------ */}
                <section className="w-9/12 pr-2 pl-4 h-full max-h-full overflow-y-auto">
                    <div className="w-full pb-4 border-b">
                        {
                            <ContractSteps
                                currentItem={getContractStepByStatus(selectedContract?.status)}
                                stepTemplate={selectedContract?.template?.maxLifeStage}
                            />
                        }
                    </div>

                    <div className="w-full flex items-center justify-between py-4 border-b">
                        <GoBackLinkButton />
                        {/*
                            !!selectedContract && !uiOptions.showPdfViewer ? (
                                ""
                            ) : (
                                <PreviewLinkButton onClick={handlePreviewModal} />
                            ) //cambiar despues
                            */}
                        <PreviewLinkButton onClick={handlePreviewModal} />

                        {/*!!selectedContract && uiOptions.showUploadAnnex && (
                            <div className="flex gap-4 items-center">
                                <LinkButton
                                    leftIcon={<IoEyeOutline size={18} />}
                                    onClick={() => navigate(`/vault?folderId=${selectedContract?.idFolder}`)}
                                >
                                    {t("showInVault")}
                                </LinkButton>

                                <LinkButton
                                    leftIcon={<AiOutlineFileAdd size={18} />}
                                    onClick={() => setUploadContractAnnexModal({ show: true, data: { contract: selectedContract } })}
                                >
                                    {t("uploadAnnex")}
                                </LinkButton>
                            </div>
                        )*/}
                    </div>

                    {uiOptions.isDraft && (
                        <div className="w-full mx-auto flex flex-col grow items-center">
                            {/**Bloquear */}
                            {/*isExcel() ? "" : <ContractGeneralInfo action="edit" />*/}
                            {<ContractGeneralInfo action="edit" />}
                            {/* isExcel() ? <Table/>  : "" */}
                            {/*typeTablePlan() ? <Table /> : ""*/}
                            {/*typeTableRev() ? <TableNoEdit /> : ""*/}
                            {/*typeTableRules() ? <TableRules /> : ""*/}
                            {/*typeTableTarifas() ? <TarifasCorp /> : ""*/}
                            {/*typeTableAmort() ? <TableAmort /> : ""*/}
                            <ContractDataGroupsForms action="edit" key={selectedContract?.template?.name} />

                            {/* mantener prop key para re-renderizar componente cuando cambia el template y evitar bugs*/}
                        </div>
                    )}

                    {uiOptions.showEditorPanel && (
                        <ContractEditorPanel
                            key={selectedContract?.lastModifiedDate + "-editor"}
                            text={selectedContract?.text}
                            enableEdition={false}
                            currentTextDiff={currentReviewerEditionChanges}
                        />
                    )}

                    {!uiOptions.showEditorPanel && uiOptions.showPdfViewer && (
                        <PdfViewer pdfBlobUrl={pdfBlobUrl} height={vh - 100 + "px"} />
                    )}
                </section>

                {showSignaturesView && (
                    <SignaturesView
                        url={pdfBlobUrl}
                        documentName={selectedContract?.name}
                        onSaveSigns={handleOnSendSignatures}
                        onClose={setShowSignaturesView.off}
                    />
                )}
            </main>
        </MainLayout>
    );
};

export default ShowEditContractPage;
