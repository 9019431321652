import create, { GetState } from "zustand";
import { devtools, NamedSet ,persist } from "zustand/middleware";
import { TranslationKeys } from "../i18n/en";
import { User } from "../models/Aministration.models";
import { Company } from "../models/Company.models";
import administrationService from "../services/administration.service";
import useModalState from "./modalsState";
import { CompanyService } from "src/services/company.service";

/** Services */
const companyService = new CompanyService()

interface State {
    userInfo: { loading: boolean; error: boolean; data: User };
    userCompanyInfo: Company;
    parentCompanyData: Company;
    additionalCompanyInfo : any;
    isSelectedChildCompany: boolean
    t: (key: TranslationKeys) => string; //translation function
    getUserInfo: (userId: string, withLoading?: boolean) => Promise<User>;
    getUserCompanyInfo: (withLoading?: boolean) => Promise<any>;
    getChildCompanyInfo: ( companyId: string, withLoading: boolean ) => Promise<any>
    setAdditionalCompanyInfo : (additionalCompanyInfo : any , withLoading?: boolean) => any;
    setT: (t: (key: TranslationKeys) => string) => void;
    updatedPass: boolean;
    setIsSelectedChildCompany: ( flag: boolean ) => void,
    reset: () => void;
}

const initialState = {
    userInfo: { loading: false, error: false, data: null },
    userCompanyInfo: null,
    parentCompanyData: undefined,
   // additionalCompanyInfo : { isAmericaMovil: false, isAdminUser: false, dashBoardType: ''},
    additionalCompanyInfo : { isAmericaMovil: false, dashBoardType: ''},
    updatedPass: false,
    isSelectedChildCompany: false
};

const useGlobalState = create<State>(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                t: (key: TranslationKeys) => key,
                setT: (t: (key: TranslationKeys) => string) => set({ t }),
                getUserInfo: getUserInfo(set, get),
                getUserCompanyInfo: getUserCompanyInfo(set, get),
                getChildCompanyInfo: getChildCompanyInfo( set, get ),
                setAdditionalCompanyInfo: setAdditionalCompanyInfo(set, get),
                setIsSelectedChildCompany: ( flag: boolean ) => set({ isSelectedChildCompany: flag }),
                reset: () => set((state) => ({ ...state, ...initialState })),
            }),
            {
                name: "GlobalState",
                getStorage: () => localStorage, 
            }
        )
    )
);

export default useGlobalState;

//FUNCTIONS

// function getUserInfo(set: NamedSet<State>, get: GetState<State>) {
//     return async (userId, withLoading = true) => {
//         withLoading && useModalState.getState().setLoaderModal(true);
//         const res = await administrationService.getUserInfo(userId);
//         withLoading && useModalState.getState().setLoaderModal(false);
//         if (res?.data) {
//             set({ userInfo: { loading: false, error: false, data: res.data } });
//             set({ updatedPass: res.data.updatedPassword });
//             return res.data;
//         } else {
//             console.log("Error fetching user info");
//             set((state) => ({ userInfo: { loading: false, error: true, data: state.userInfo.data } }));
//             //clean error
//             set((state) => ({ userInfo: { ...state.userInfo, error: false } }));
//             return null;
//         }
//     };
// }
function getUserInfo( set: NamedSet<State>, get: GetState<State> ) {
    return async ( userId: string, withLoading: boolean = true ) => {
        /** */
        if ( !get().userInfo.data ) {
            /** */
            withLoading && useModalState.getState().setLoaderModal( true )
            const res = await administrationService.getUserInfo( userId )
            withLoading && useModalState.getState().setLoaderModal( false )

            if ( res?.data ) {
                set({ userInfo: { loading: false, error: false, data: res.data } })
                set({ updatedPass: res.data.updatedPassword })

                return res.data
            } else {
                console.log("Error fetching user info")

                set((state) => ({ userInfo: { loading: false, error: true, data: state.userInfo.data } }))
                //clean error
                set((state) => ({ userInfo: { ...state.userInfo, error: false } }))

                return null
            }
        }

        return get().userInfo.data
    }
}

// function getUserCompanyInfo(set: NamedSet<State>, get: GetState<State>) {
//     return async (withLoading = false) => {
//         withLoading && useModalState.getState().setLoaderModal(true);
//         const res = await administrationService.getUserCompaniesInfo();
//         withLoading && useModalState.getState().setLoaderModal(false);

//         if (Array.isArray(res?.data?.companies) && res.data.companies.length > 0) {
//             const company = res.data.companies[0];
//             set({ userCompanyInfo: company });
//             return company;
//         } else {
//             console.log("Error fetching user company info");
//             return null;
//         }
//     };
// }
function getUserCompanyInfo( set: NamedSet<State>, get: GetState<State> ) {
    return async (withLoading = false) => {
        /** */
        if ( !get().userCompanyInfo ) {
            /** */
            withLoading && useModalState.getState().setLoaderModal( true )
            const res = await administrationService.getUserCompaniesInfo()
            withLoading && useModalState.getState().setLoaderModal( false )

            if (Array.isArray( res?.data?.companies ) && res.data.companies.length > 0 ) {
                const company = res.data.companies[0]
                set({ userCompanyInfo: company })

                if ( company.group ) {
                    set({ parentCompanyData: company })
                }

                return company
            } else {
                console.log( "Error fetching user company info" )
                return null
            }
        }

        return get().userCompanyInfo
    }
}

function getChildCompanyInfo( set: NamedSet<State>, get: GetState<State> ) {
    return async ( companyId: string, withLoading: boolean = false ) => {
        withLoading && useModalState.getState().setLoaderModal( true )
        const res = await companyService.getSelectedCompany( companyId )
        withLoading && useModalState.getState().setLoaderModal( false )

        if ( res ) {
            set({ userCompanyInfo: res })
            set({ isSelectedChildCompany: true })
            return res
        } else {
            console.log( "Error fetching selected company info" )
            return undefined
        }
    }
}

function setAdditionalCompanyInfo(set: NamedSet<State>, get: GetState<State>) {
    return async (companyInfo) => {
            set({ additionalCompanyInfo: companyInfo })
    };
}