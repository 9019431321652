import {
    Badge,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Input,
    useBoolean,
    InputGroup,
    InputRightElement,
    Tooltip,
    Button
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import useCustomToast from "../../../../hooks/useCustomToast";
import { ContractRevisionUserConfirmed, ContractRevisionUserSelect } from "../../../../models/Contract.models";
import { ContractRevisionModalData } from "../../../../models/Modals.models";
import useContractRevisionState from "../../../../store/contractRevisionState";
import ContractRevisionSearchMenu from "../../../contracts/contract-revision/ContractRevisionSearchMenu";
import AddRevisorIcon from "../../../icons/AddRevisorIcon";
import UserIcon from "../../../icons/UserIcon";
import CommonModal from "../../CommonModal";
import LoadingBar from "../../LoadingBar";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { IoClose } from "react-icons/io5";
import SlicedText from "../../SlicedText";
import { AiOutlineClockCircle } from "react-icons/ai";
import useAdministrationState from "src/store/administrationState";
import { UserGroup } from "src/models/Aministration.models";
import useDocumentRevisionState from "src/store/documentRevisionState";
import usePdfSignaturesState from "src/store/pdfSignatureState";
import useBiddingState from "src/store/biddings/biddingState";

type RevisorRole = "revisor" | "editor";

type ItemProps = {
    order: number;
    user: ContractRevisionUserConfirmed;
    isExternal: boolean;
    onChangeType: (arg: RevisorRole) => any;
    onDelete: (...args: any) => any;
};

const RevisionItem = ({ order, user, isExternal, onChangeType, onDelete }: ItemProps) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    return (
        <li className="flex items-center text-lg text-gray-600">
            <div className="mr-6 pt-2">{order}</div>
            <UserIcon boxSize={12} />
            <div className="ml-4 pt-2">
                <div className="text-base text-gray-900 relative inline-block">
                    <SlicedText text={user.userName} maxLength={16} />
                    {isExternal && (
                        <div className="text-xs font-light absolute top-[2px] left-full ml-2">
                            <Badge fontSize={"9px"} colorScheme="gray">
                                Externo
                            </Badge>
                        </div>
                    )}
                </div>
                <div className="text-xs">{user.userEmail}</div>
            </div>
            <div className="grow"></div>
            {/* <Select variant={"unstyled"} maxW="6rem" className="font-bold" onChange={(e) => onChangeType(e.target.value as RevisorRole)}>
                <option value="revisor">{t("revisor")}</option> */}
            {/* <option value="editor">{t("editor")}</option> cambiar */}
            {/* </Select> */}
            <button className="text-red-500 ml-2 pr-3" onClick={onDelete}>
                <IoClose size={15} color="inherit" />
            </button>
        </li>
    );
};

type ModalProps = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: ContractRevisionModalData;
};

const ContractRevisionModal = ({ isOpen, onClose, data }: ModalProps) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const {
        getRevisionUsers,
        revisionUsers: { data: revisionUsers, error, loading },
        createRevision,
    } = useContractRevisionState();

    const {
        getCompanyUserGroups,
        companyUsersGroups: { data: companyUsersGroups },
    } = useAdministrationState();

    const { sendDocumentToReview } = useDocumentRevisionState();

    const { selectedBidding } = useBiddingState();

    const { currentRequiriment } = usePdfSignaturesState();


    console.log("data currentRequiriment g", currentRequiriment);
    const [selectedRevisorUsers, setRevisorUsers] = useState<ContractRevisionUserConfirmed[]>([]);
    // const [selectedRevisorUserGroup, setRevisorUserGroup] = useState<any[]>([]);
    const [isSubmmiting, setSubmmiting] = useBoolean(false);
    const [expirationHoursValue, setExpirationHoursValue] = useState(48);
    const [invalidName, setInvalidName] = useBoolean(false);

    async function onSubmit() {
        if (!selectedRevisorUsers.length) {
            toast.error(t("atLeastOneRevisorRequired"));
            return;
        }
        //asigno campo "secuence" segun index dentro del array
        const parsedUsers = selectedRevisorUsers.map((u, idx) => ({ ...u, secuence: String(idx + 1) }));
        setSubmmiting.on();

        if (data.reviewType === "Document") {
            createDocumentRevision(parsedUsers);
        } else {
            createContractRevision(parsedUsers);
        }
        // createContractRevision(parsedUsers);

    }

    async function createContractRevision(parsedUsers) {
        const created = await createRevision(data.contractId, parsedUsers, false, expirationHoursValue, data.nameContract);
        setSubmmiting.off();
        if (created) {
            toast.success(t("revisionCreatedSuccessfully"));
            data.afterSubmit();
            onClose();
        } else {
            toast.error(t("revisionCreationError"));
        }

    }

    async function createDocumentRevision(parsedUsers) {
        console.log("entro en documento flow review")

        const expirationHours = expirationHoursValue;

        const parsedUsersTemp = parsedUsers.map((user) => ({
            expirationHours: expirationHours,
            reviewerId: user.idUser,
            secuence: user.secuence,
            user: {
                email: user.userEmail,
                id: user.idUser,
                name: user.userName,
            },
        }));



        const created = await sendDocumentToReview(data.contractId, selectedBidding.id, parsedUsersTemp, expirationHours);
        setSubmmiting.off();
        if (created) {
            toast.success(t("revisionCreatedSuccessfully"));
            data.afterSubmit();
            onClose();
        } else {
            toast.error(t("revisionCreationError"));
        }

    }

    function handleSelect(user: ContractRevisionUserSelect) {

        setRevisorUsers((prevState) => {
            const userAlreadySelected = prevState.find((u) => u.idUser === user.idUser);
            if (userAlreadySelected) return prevState;
            return [
                ...prevState,
                {
                    answer: "",
                    answered: false,
                    comment: "",
                    editor: false,
                    idAnswer: null,
                    idUser: user.idUser,
                    userEmail: user.userEmail,
                    userName: user.userName,
                    secuence: "",
                    isExternal: user.external,
                    user: {
                        email: user.userEmail,
                        id: user.idUser,
                        name: user.userName
                    }
                },
            ];
        });
    }

    function handleSelectUserGroup(userGroup: UserGroup) {
        const userGroupList = userGroup.userList.map((user) => user);

        const filteredUsersInUserGroupList = filteredRevisorUsers.filter((user) => userGroupList.includes(user.idUser));

        setRevisorUsers((prevState) => {
            const usersAlreadySelected = prevState.filter((u) => userGroupList.includes(u.idUser));
            const newIdsArray = userGroupList.filter((id) => !usersAlreadySelected.some((user) => user.idUser === id));

            const updatedRevisorUsers = [
                ...prevState,
                ...filteredUsersInUserGroupList
                    .filter((filteredUser) => newIdsArray.includes(filteredUser.idUser))
                    .map((filteredUser) => ({
                        answer: "",
                        answered: false,
                        comment: "",
                        editor: false,
                        idAnswer: null,
                        idUser: filteredUser.idUser,
                        userEmail: filteredUser.userEmail,
                        userName: filteredUser.userName,
                        secuence: "",
                        isExternal: filteredUser.external,
                        user: {
                            email: filteredUser.userEmail,
                            id: filteredUser.idUser,
                            name: filteredUser.userName
                        }
                    })),
            ];

            return updatedRevisorUsers;
        });
    }

    function handleRevisorTypeChange(user: ContractRevisionUserConfirmed, type: RevisorRole) {
        setRevisorUsers((prevState) => {
            return prevState.map((u) => {
                if (u.idUser === user.idUser) {
                    return { ...u, editor: type === "editor" };
                }
                return u;
            });
        });
    }

    function handleDelete(user: ContractRevisionUserConfirmed) {
        setRevisorUsers((prevState) => {
            return prevState.filter((u) => u.idUser !== user.idUser);
        });
    }

    function convertirHorasADias(horas) {
        const dias = Math.floor(horas / 24);
        return dias;
    }

    const filteredRevisorUsers = useMemo(() => {
        return revisionUsers?.filter((u) => !selectedRevisorUsers?.find((su) => su.idUser === u.idUser));
    }, [selectedRevisorUsers, revisionUsers]);

    const handleChangeNumber = (value) => {
        const newValue = parseInt(value, 10);
        if (!isNaN(newValue) && newValue > 0 && newValue <= 720) {
            setExpirationHoursValue(newValue);
        }
    };

    const ExteneralUserInput = ({ handleSelect }) => {
        const [email, setEmail] = useState('');
        const toast = useCustomToast();

        const validateEmail = (email) => {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(String(email).toLowerCase());
        };
    
        const handleAddExternalUser = () => {
            if (!validateEmail(email)) {
                toast.warningTop("Por favor, ingresa un correo electrónico válido.")
                return;
            }
    
            const user = {
                idUser: email,
                userEmail: email,
                name: email,
                external: true,
            };
    
            handleSelect(user);
    
            setEmail('');
        };
    
        return (
            <InputGroup size='md'>
                <Tooltip 
                    hasArrow
                    label="Añadir usuario externo para la revisión"
                    bg='blue.600'
                >
                    <Input
                        pr='4.5rem'
                        size="md"
                        type='text'
                        placeholder='Correo del revisor'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Tooltip>
                <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={handleAddExternalUser}>
                        Agregar
                    </Button>
                </InputRightElement>
            </InputGroup>
        );
    }

    useEffect(() => {
        getRevisionUsers(false);
        getCompanyUserGroups();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(t("fetchingRevisionUsersError"));
        }
    }, [error]);

  //  console.log("selectedRevisorUsers", selectedRevisorUsers)
  //  console.log("currentBidding", selectedBidding)
    return (
        <CommonModal size="3xl" isOpen={isOpen} onClose={onClose} closeButton closeOnOverlayClick>
            <div className="flex flex-col px-8">
                <div className="flex items-center space-x-4">
                    <AddRevisorIcon boxSize={10} />
                    <h2 className="title-3 pl-2 pr-12">{t("newRevision")}</h2>

                    <AiOutlineClockCircle size={25} color="blue" />
                    <span className="text-sm">{"Expira en"}</span>
                    <div className="w-20">
                        <NumberInput defaultValue={expirationHoursValue} min={1} max={720} size="sm" onChange={handleChangeNumber}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </div>

                    <span className="text-sm pr-5">{expirationHoursValue === 1 ? "hora" : "horas"}</span>
                    <span>
                        {"("}
                        {convertirHorasADias(expirationHoursValue)}
                        {" días )"}
                    </span>
                </div>

                <p className="text-sm m-4 mb-2">{t("newRevisionText")}</p>
                <LoadingBar isLoading={loading} />
                <ContractRevisionSearchMenu
                    items={filteredRevisorUsers}
                    onSelect={handleSelect}
                    companyUsersGroups={companyUsersGroups}
                    onSelectUserGroup={handleSelectUserGroup}
                />
                {/**   <Tooltip 
                    hasArrow
                    label={t('tooltipExternalUserText')}
                    bg='blue.600'
                    >
                    <p className="text-sm m-4 mb-2">{t("reviewExternalUserText")}</p>
                </Tooltip>

                <ExteneralUserInput  handleSelect={handleSelect} /> */
                }
             

                <ul className="mt-6 space-y-4 max-h-56 overflow-y-auto">
                    {selectedRevisorUsers?.length ? (
                        selectedRevisorUsers?.map((user, idx) => (
                            <RevisionItem
                                key={user.idUser}
                                order={idx + 1}
                                user={user}
                                isExternal={user.isExternal}
                                onChangeType={(type) => handleRevisorTypeChange(user, type)}
                                onDelete={() => handleDelete(user)}
                            />
                        ))
                    ) : (
                        <div className="py-6 text-gray-600 text-center">{t("noReviewerSelectedYet")}</div>
                    )}
                </ul>

                <div className="flex justify-end mt-4">
                    <Button rounded="full" size="md" fontWeight="medium" variant="solid" isLoading={isSubmmiting} onClick={onSubmit}>
                        {t("sendRevision")}
                    </Button>
                </div>

                {/* <Divider my={4} /> */}

                {/* <div className="w-full flex justify-between items-center">
                    <div className="w-6/12">
                        <div className="flex items-center space-x-2">
                            <LinkIcon boxSize={6} />
                            <h3 className="text-brand-600 text-xl font-bold">Obtener enlace</h3>
                        </div>
                        <p className="text-sm">Cualquier usuario de internet con este enlace pude ver y comentar.</p>
                    </div>
                    <Button fontWeight="light" fontSize="sm" colorScheme="brand" variant="link">
                        Copiar enlace
                    </Button>
                </div> */}
            </div>
        </CommonModal>
    );
};

export default ContractRevisionModal;
