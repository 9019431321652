import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

type Props = {
    onHandleTokenization: (arg: any) => any;
};

const SignedActions = ({ onHandleTokenization }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="flex justify-center gap-2 pt-4 pb-2 sticky bottom-0 bg-white border-t">
            <Button rounded="full" size="md" fontWeight="medium" variant="solid" px={12} onClick={onHandleTokenization} isLoading={false}>
                {t("tokenize")}
            </Button>
        </div>
    );
};

export default SignedActions;
