import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchInput from "../common/SearchInputV2";
import FilterInput from "../common/FilterInput";
import CommonTh from "../common/table/CommonTh";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { Badge, Tooltip } from "@chakra-ui/react";
import { formatDate, getBiddingStatusColor } from "../../lib/utils";
import { IoClose, IoTrashOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import useModalState from "../../store/modalsState";
import useBiddingState from "src/store/biddings/biddingState";
import useCustomToast from "../../hooks/useCustomToast";
import TablePaginator from "../common/TablePaginatorV2";
import LinkButton from "../common/buttons/LinkButton";
import useGlobalState from "src/store/globalState";
import _ from "lodash";
import { useParams } from 'react-router-dom';

const CustomBiddingTable = ({ data }) => {
    const t = useTranslation("global")[0];
    const navigate = useNavigate();
    const toast = useCustomToast();

    const setDeleteModal = useModalState((s) => s.setDeleteCommonModal);
    const deleteBidding = useBiddingState((s) => s.deleteBidding);
    const getBiddings = useBiddingState((s) => s.getBiddings);

    const [shouldReload, setShouldReload] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const additionalCompanyInfo = useGlobalState((s) => s.additionalCompanyInfo);
    let { filter } = useParams();

    const initialFilters = {
        name: "",
        createdByName: "",
        createdAt: "",
        biddingTypeName: filter ? filter : "" ,
        superCategority: "",
        status: "",
        updatedAt: "",
    };

    const [filters, setFilters] = useState(initialFilters);

    const handleResetFilters = () => {
        setFilters(initialFilters);
        setIsFilterActive(false);
        setSearchTerm("");
        setShouldReload(true); // limpiar valores inputs filtro busqueda por columna
    };

    const handleSort = (key) => {
        if (key === sortKey) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortKey(key);
            setSortOrder("asc");
        }
    };
    const handleInputChange = (e, filterKey) => {
        setShouldReload(false); // evitar que los value en los inputs filtro busqueda por columna se limpien
        setFilters({ ...filters, [filterKey]: e.target.value });
        setIsFilterActive(true); // bandera para indicar filtros por columna
    };

    const formattedateData = data.map((item) => ({
        ...item,
        createdAtFormatted: formatDate(item.createdAt),
        updatedAtFormatted: formatDate(item.updatedAt),
    }));

    const filteredData = formattedateData.filter((item) => {
        const escapedName = _.escapeRegExp(filters.name);
        const namePattern = new RegExp(escapedName, "i");

        const escapedType = _.escapeRegExp(filters.biddingTypeName);
        const typePattern = new RegExp(escapedType, "i");

        return (
            namePattern.test(item.name) &&
            item.createdByName?.toLowerCase().includes(filters.createdByName?.toLowerCase()) &&
            //item.createdAt?.toString().includes(filters.createdAt) &&
            item.createdAtFormatted?.includes(filters.createdAt) &&
            typePattern.test(item.biddingTypeName) &&
            //item.biddingTypeName?.toLowerCase().includes(filters.biddingTypeName?.toLowerCase()) &&
            (item.superCategority !== null
                ? item.superCategority.toLowerCase().includes(filters.superCategority.toLowerCase())
                : //namePattern.test(item.superCategority) :
                  true) &&
            item.status.toLowerCase().includes(filters.status.toLowerCase()) &&
            item.updatedAtFormatted?.includes(filters.updatedAt) &&
            //item.updatedAt?.toString().includes(filters.updatedAt) &&
            (searchTerm === "" || Object.values(item).some((field) => field?.toString().toLowerCase().includes(searchTerm.toLowerCase())))
        );
    });

    const sortedData = [...filteredData].sort((a, b) => {
        if (sortKey) {
            if (a[sortKey] < b[sortKey]) {
                return sortOrder === "asc" ? -1 : 1;
            }
            if (a[sortKey] > b[sortKey]) {
                return sortOrder === "asc" ? 1 : -1;
            }
        }
        return 0;
    });

    const renderSortArrow = (column) => {
        if (sortKey === column) {
            return sortOrder === "asc" ? <GoTriangleDown size={14} /> : <GoTriangleUp size={14} />;
        }
        return "";
    };

    const totalPages = Math.ceil(sortedData.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
   // const currentItems = sortedData.slice(startIndex, endIndex);
    const currentItems = sortedData
    .filter(item => item.enabled !== false)
    .slice(startIndex, endIndex); 

    const onGetCurrentPage = (page) => {
        setCurrentPage(page);
    };

    async function onDeleteBidding(bidding) {
        const deleted = await deleteBidding(bidding.id);
        if (deleted) {
            toast.success(t("deletedProccessTypeSuccess"));
            getBiddings(false);
        } else {
            toast.error(t("deletedProccessTypeError"));
        }
    }

    function handleDelete(e, bidding) {
        e.stopPropagation();
        setDeleteModal({
            show: true,
            data: { text1: t("proccessTypeWillBeDeleted"), text2: bidding.name, onDelete: () => onDeleteBidding(bidding) },
        });
    }

    useEffect(() => {
     //   console.log("filet data",data)
    
    }, []);

    return (
        <div>
            <div className="w-full flex justify-between items-center">
                <div className="flex w-full justify-between items-center gap-4 mt-2">
                    <h4 className="italic text-xl text-gray-600">{t("myProccess")}</h4>
                    <div className="grow h-1 mt-1 border-t-[3px] border-dotted border-gray-600/80" />
                    {isFilterActive ? (
                        <LinkButton onClick={() => handleResetFilters()} color="red.400">
                            <IoClose size={20} /> {t("cleanFilters")}
                        </LinkButton>
                    ) : (
                        <SearchInput value={searchTerm} onChangeInput={(e) => setSearchTerm(e.target.value)} />
                    )}
                </div>
            </div>
            <table className="w-full text-sm text-left text-dark-2">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="px-2 py-4">
                            <span className="flex flex-col items-center">
                                <div className="mb-2 flex items-center">
                                {additionalCompanyInfo.isAmericaMovil === true ?
                                     <CommonTh text={t("nameId")} onClick={() => handleSort("name")} />
                                                                       :               
                                    <CommonTh text={t("name")} onClick={() => handleSort("name")} />
                                }
                                    <div className="ml-2">{renderSortArrow("name")}</div>
                                </div>
                                <FilterInput
                                    value={filters.name}
                                    clearValue={shouldReload}
                                    label={"Filtrar por nombre"}
                                    onChangeInput={(e) => handleInputChange(e, "name")}
                                />
                            </span>
                        </th>
                        <th scope="col" className="px-2 py-4">
                            <span className="flex flex-col items-center">
                                <div className="mb-2 flex items-center">
                                    <CommonTh text={t("author")} onClick={() => handleSort("createdByName")} />
                                    <div className="ml-2">{renderSortArrow("createdByName")}</div>
                                </div>
                                <FilterInput
                                    value={filters.createdByName}
                                    clearValue={shouldReload}
                                    label={`Filtrar por ${t("author")}`}
                                    onChangeInput={(e) => handleInputChange(e, "createdByName")}
                                />
                            </span>
                        </th>
                        <th scope="col" className="px-2 py-4">
                            <span className="flex flex-col items-center">
                                <div className="mb-2 flex items-center">
                                    <CommonTh text={t("creation")} onClick={() => handleSort("createdAt")} />
                                    <div className="ml-2">{renderSortArrow("createdAt")}</div>
                                </div>
                                <FilterInput
                                    value={filters.createdAt}
                                    clearValue={shouldReload}
                                    label={`Filtrar por ${t("creation")}`}
                                    onChangeInput={(e) => handleInputChange(e, "createdAt")}
                                />
                            </span>
                        </th>
                        <th scope="col" className="px-2 py-4">
                            <span className="flex flex-col items-center">
                                <div className="mb-2 flex items-center">
                                    <CommonTh text={t("type")} onClick={() => handleSort("biddingTypeName")} />
                                    <div className="ml-2">{renderSortArrow("biddingTypeName")}</div>
                                </div>
                                <FilterInput
                                    value={filters.biddingTypeName}
                                    clearValue={shouldReload}
                                    label={`Filtrar por ${t("type")}`}
                                    onChangeInput={(e) => handleInputChange(e, "biddingTypeName")}
                                />
                            </span>
                        </th>
                        <th scope="col" className="px-2 py-4">
                            <span className="flex flex-col items-center">
                                <div className="mb-2 flex items-center">
                                    <CommonTh text={t("category")} onClick={() => handleSort("superCategority")} />
                                    <div className="ml-2">{renderSortArrow("superCategority")}</div>
                                </div>
                                <FilterInput
                                    value={filters.superCategority}
                                    clearValue={shouldReload}
                                    label={`Filtrar por ${t("category")}`}
                                    onChangeInput={(e) => handleInputChange(e, "superCategority")}
                                />
                            </span>
                        </th>
                        <th scope="col" className="px-2 py-4">
                            <span className="flex flex-col items-center">
                                <div className="mb-2 flex items-center">
                                    <CommonTh text={t("status")} onClick={() => handleSort("status")} />
                                    <div className="ml-2">{renderSortArrow("status")}</div>
                                </div>
                                <FilterInput
                                    value={filters.status}
                                    clearValue={shouldReload}
                                    label={`Filtrar por ${t("status")}`}
                                    onChangeInput={(e) => handleInputChange(e, "status")}
                                />
                            </span>
                        </th>
                        <th scope="col" className="px-2 py-4">
                            <span className="flex flex-col items-center">
                                <div className="mb-2 flex items-center maxW-[110px] truncate">
                                    <CommonTh text={t("lastVersion")} onClick={() => handleSort("updatedAt")} />
                                    <div className="ml-2">{renderSortArrow("updatedAt")}</div>
                                </div>
                                <FilterInput
                                    value={filters.updatedAt}
                                    clearValue={shouldReload}
                                    label={`Filtrar por ${t("lastVersion")}`}
                                    onChangeInput={(e) => handleInputChange(e, "updatedAt")}
                                />
                            </span>
                        </th>
                        <th scope="col" className="px-2 py-4">
                            <span className="flex flex-col items-center">
                                <div className="mb-10 flex items-center">
                                    <CommonTh text={t("actions")} onClick={() => {}} />
                                    <div className="ml-2">{/*renderSortArrow("birthYear")*/}</div>
                                </div>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item) => (
                        <tr
                            className="items-center border-b hover:bg-slate-100 transition-colors duration-300 cursor-pointer"
                            key={item.id}
                            onClick={() => navigate(`${item.id}`)}
                        >
                            {" "}
                            {/**onClick={() => navigate(`${bidding.id}`)} */}
                            <td className="px-2 py-3  capitalize">{item.name}</td>
                            <td className="px-2 py-3  items-center justify-center">
                                <div className="w-full flex xl:w-11/12 justify-center gap-6">{item.createdByName}</div>
                            </td>
                            <td className="px-2 py-3  items-center justify-center">
                                <div className="w-full flex xl:w-11/12 justify-center gap-6">{formatDate(item.createdAt)}</div>
                            </td>
                            <td className="px-2 py-3  items-center justify-center">
                                <div className="w-full flex xl:w-12/12 justify-center gap-6 text-center text-ellipsis">
                                    {item.biddingTypeName}
                                </div>
                            </td>
                            <td className="px-2 py-3  items-center justify-center">
                                <div className="w-full flex xl:w-11/12 justify-center gap-6">{item.superCategority}</div>
                            </td>
                            <td className="px-2 py-3  items-center justify-center">
                                <div className="w-full flex xl:w-11/12 justify-center gap-6">
                                    <Badge colorScheme={getBiddingStatusColor(item.status)}>
                                        {/*console.log(item)*/}
                                        {t((item.status ?? "undefined")?.toLowerCase())}
                                    </Badge>
                                </div>
                            </td>
                            <td className="px-2 py-3  items-center justify-center">
                                <div className="w-full flex xl:w-11/12 justify-center gap-6">{formatDate(item.updatedAt)}</div>
                            </td>
                            <td className="px-2 py-3">
                                <div className="w-full flex xl:w-8/12 justify-center gap-6">
                                    <Tooltip label={t("delete")} fontSize="12px">
                                        <button onClick={(e) => handleDelete(e, item)}>
                                            {/**onClick={(e) => onDelete(e, bidding)} */}
                                            <IoTrashOutline size={25} />
                                        </button>
                                    </Tooltip>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex justify-between items-center">
                {currentItems?.length ? (
                    <div className="w-full py-6">
                        <TablePaginator
                            onPrev={() => setCurrentPage(currentPage - 1)}
                            onNext={() => setCurrentPage(currentPage + 1)}
                            currentPage={currentPage}
                            maxPages={totalPages}
                            onSetPage={onGetCurrentPage}
                        />
                    </div>
                ) : (
                    <div className="w-full text-center p-2 text-dark">{t("notElementsFound")}</div>
                )}
            </div>
            {/*<div>
                <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
                <span>{currentPage} de {totalPages}</span>
                <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>Next</button>
            </div>*/}
        </div>
    );
};

export default CustomBiddingTable;
