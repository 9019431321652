import * as React from "react";

const DatosIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        style={{
            enableBackground: "new 0 0 2316 2500",
            fill: "#2E54B8",
        }}
        viewBox="0 0 66 54"
        {...props}
    >
        <path d="M3.9 3.9 1 6.8v40.4l2.9 2.9L6.8 53H31c29.5 0 27.3 1.2 31.3-16.9C65.4 22.3 65.1 21 59.4 21c-3.1 0-3.4-.3-3.4-3 0-1.9-.9-4-2.5-5.5-2.3-2.4-3.1-2.5-13.4-2.5-11 0-11.1 0-11.7-2.5C27.2 2.7 23.6 1 14.8 1c-7.5 0-8.1.2-10.9 2.9zm17.7 2.9c.6.4 1.5 1.8 1.9 3.2 1.4 4.7 2.1 5 13.9 5C49 15 51 15.6 51 19.2c0 1.7-1.2 1.8-15.5 1.8-18.1 0-16.1-1.5-19.6 14.5-2.3 10.9-3.5 13.1-6.8 12C7.1 46.9 7 46.3 7 27c0-14.9.3-20 1.3-20.3 1.9-.8 12.1-.8 13.3.1zm37.1 21.5c-.2.7-1.2 4.8-2.2 9.2-.9 4.4-2.4 8.6-3.3 9.3-1.1.8-6.4 1.2-18 1.2H18.8l.5-2.3c.3-1.2 1.3-5.9 2.3-10.5l1.7-8.2h18c14.1 0 17.8.3 17.4 1.3z" />
    </svg>
);

export default DatosIcon;
