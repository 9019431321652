import axios from "axios";
import { getAuthHeader } from "../lib/utils";
import { PolicyData } from "src/models/Aministration.models";

export const generalAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_GENERAL}/api/general`,
    timeout: 10000,
});

export const companyAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_COMPANY}/api/v1`,
    timeout: 10000,
});

export const userGroupRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/userGroup`,
    timeout: 20000,
});

export const userAnnexeRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/userAnnexe`,
    timeout: 20000,
});

const getCompanyUsers = async (companyId: string) => {
    try {
        return await generalAxiosRequest.get(`/user/byCompany/${companyId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getUserInfo = async (userId: string) => {
    try {
        return await generalAxiosRequest.get(`/user/${userId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createUser = async (body: any) => {
    try {
        return await generalAxiosRequest.put(`/user/`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createExternalUser = async (body: any) => {
    try {
        return await generalAxiosRequest.put(`/user/external`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateUser = async (body: any) => {
    try {
        return await generalAxiosRequest.patch(`/user/`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteUser = async (userId: string) => {
    try {
        return await generalAxiosRequest.delete(`/user/${userId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getUserCompaniesInfo = async () => {
    try {
        return await companyAxiosRequest.get(`/companies/owner`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const getCompanies = async (page: number, size: number) => {
    try {
        return await companyAxiosRequest.get(`/companies?page=${page}&size=${size}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const getAssociatedCompanies = async (ownCompany: string) => {
    try {
        return await generalAxiosRequest.get(`/company/associate/ownCompanyId/${ownCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const associateCompany = async (body: any) => {
    try {
        return await generalAxiosRequest.put(`/company/associate`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const createCompany = async (body: any) => {
    try {
        return await generalAxiosRequest.put(`/company/`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const createAndAssociateCompany = async (body: any) => {
    try {
        return await generalAxiosRequest.put(`/company/createAndAssociateCompany`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteCompanyAssociation = async (companyAssociationId: string) => {
    try {
        return await generalAxiosRequest.delete(`/company/associate/${companyAssociationId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const getCompanyUserGroups = async (companyId: string) => {
    try {
        return await userGroupRequest.get(`/companyId/${companyId}`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getCompanyUserGroupDetail = async (userGroupId: string) => {
    try {
        return await userGroupRequest.get(`/${userGroupId}`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createUserGroup = async (body: any) => {
    try {
        return await userGroupRequest.put(``, body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


const updateUserGroup = async (body: any) => {
    try {
        return await userGroupRequest.patch(``,body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteUserGroup = async (userGroupId: any) => {
    try {
        return await userGroupRequest.delete(`/${userGroupId}`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getCompanyUserGroupAnnexe = async (companyId: string, userId: string) => {
    try {
        return await userAnnexeRequest.get(`/companyId/${companyId}/userId/${userId}`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createCompanyUserGroupAnnexe = async (body: any) => {
    try {
        return await userAnnexeRequest.put(``,body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateCompanyUserGroupAnnexe = async (body: any, userAnnexeId: string) => {
    try {
        return await userAnnexeRequest.patch(`/userGroupIdList/${userAnnexeId}`,body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getCompanyUsersGroupAnnexeByList = async (body: any, companyId: string) => {
    try {
        return await userAnnexeRequest.post(`/companyId/${companyId}`,body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getPoliciesByCompanyList = async( companyId: string ) => {
    /** */
    try {
        return await generalAxiosRequest.get( `/companyPasswordPolicy/companyId/${companyId}`, {
            headers: getHeaders()
        });
    } catch ( error ) {
        console.log( error );
        return error;
    }
};

const getPoliciesByUserList = async( userId: string ) => {
    /** */
    try {
        return await generalAxiosRequest.get( `/companyPasswordPolicy/userId/${userId}`, {
            headers: getHeaders()
        });
    } catch ( error ) {
        console.log( error );
        return error;
    }
};

const updatePoliciesList = async ( body: PolicyData ) => {
    /** */
    try {
        return await generalAxiosRequest.patch( `/companyPasswordPolicy`, body, {
            headers: getAuthHeader()
        });
    } catch ( error ) {
        console.log( error );
        return error;
    }
}

const getNotApplicableUsers = async( companyId: string ) => {
    /** */
    try {
        return await generalAxiosRequest.get( `/otpConfig/notApplicableUsers/companyId/${companyId}`, {
            headers: getHeaders()
        });
    } catch ( error ) {
        console.log( error );
        return error;
    }
};

const saveNotApplicableUsers = async ( body: any, companyId: string ) => {
    try {
        return await generalAxiosRequest.post( `/otpConfig/companyId/${companyId}`, body, {
            headers: getAuthHeader()
        });
    } catch ( error ) {
        console.log( error );
        return error;
    }
};

const validateOtpCode = async ( id: string, code: string ) => {
    /** */
    try {
        /** */
        const validateOtpPath = `/otpConfig/validOtp/otpNodeId/${id}/otpValue/${code}`;

        return await generalAxiosRequest.post( validateOtpPath, null )
        .catch( error => {
            return error.response;
        });
    } catch ( error ) {
        /** */
        console.log( error );
        return error;
    }
}

export default {
    getCompanyUsers,
    createUser,
    createExternalUser,
    deleteUser,
    getUserInfo,
    getUserCompaniesInfo,
    updateUser,
    getCompanies,
    getAssociatedCompanies,
    associateCompany,
    createCompany,
    deleteCompanyAssociation,
    createAndAssociateCompany,
    getCompanyUserGroups,
    createUserGroup,
    updateUserGroup,
    deleteUserGroup,
    getCompanyUserGroupAnnexe,
    createCompanyUserGroupAnnexe,
    updateCompanyUserGroupAnnexe,
    getCompanyUsersGroupAnnexeByList,
    getPoliciesByCompanyList,
    getPoliciesByUserList,
    updatePoliciesList,
    getNotApplicableUsers,
    saveNotApplicableUsers,
    validateOtpCode
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
