import CommonModal from "../CommonModal";
import { PdfPreviewModalData } from "../../../models/Modals.models";
import PdfViewer from "../PdfViewer";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: PdfPreviewModalData;
};

const PdfPreviewModal = ({ isOpen, onClose, data }: Props) => {
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    return (
        <CommonModal size="6xl" isOpen={isOpen} onClose={onClose} closeButton closeOnOverlayClick>
            <PdfViewer pdfBlobUrl={data?.pdfBlobUrl} height={vh - 120 + "px"} />
        </CommonModal>
    );
};

export default PdfPreviewModal;
