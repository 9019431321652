import { BiPlus } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import useCustomToast from "../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { Template } from "../../models/Template.models";
import useModalState from "../../store/modalsState";
import useTemplateState from "../../store/templatesState";
import AccordionItemBrand from "../common/AccordionItemBrand";
import LinkButton from "../common/buttons/LinkButton";

const TemplateHeaderFooterAccordionItem = ({ template, readOnly, templateName }: { template: Template; readOnly?: boolean,templateName?: string }) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const { setTemplateHeaderFooterModal } = useModalState();
    const { updateTemplate, getTemplate } = useTemplateState();
    const hasHeader = Boolean(template.header);
    const hasFooter = Boolean(template.footer);

    async function handleDelete(type: "header" | "footer") {

        const elementType = type === "footer" ? "header" : "footer";

        // Crear un nuevo objeto sin el elemento contrario

         const updatedTemplate: Template = {
            ...template,
             [type]: "" // o null
            }
                    
     delete (updatedTemplate as any)[elementType];

        const updated = await updateTemplate(updatedTemplate);
        //localStorage.removeItem(type + "-" + template.id); //temp
        if (updated) {
            getTemplate(template.id, false);
        } else {
            toast.error(t("error"));
        }
    }

    function buildImgElement(base64String) {
        // Construir la cadena con la estructura del elemento img
        if (!base64String) {
            console.error("La cadena Base64 proporcionada está vacía o nula.");
            return ""; // O cualquier otro valor de retorno que desees
        }
        const base64Format = base64String.replace(/"/g, '');
        const imgUrl = `data:image/png;base64,${base64Format}`;
        return imgUrl;
    }

    return (
        <AccordionItemBrand title={t("headerAndFooter")}>
            <div>
                <div>
                    <h4 className="text-base font-medium text-dark p-1">- Encabezado</h4>
                    {!readOnly && (
                        <div className="flex gap-4 items-center mb-2">
                            <LinkButton
                                fontSize="xs"
                                leftIcon={<BiPlus size={16} />}
                                onClick={() =>
                                    setTemplateHeaderFooterModal({
                                        show: true,
                                        data: { template: template, case: hasHeader ? "edit" : "new", type: "header", templateName:templateName },
                                    })
                                }
                            >
                                {hasHeader ? "Cambiar" : "Agregar"}
                            </LinkButton>
                            {hasHeader && (
                                <LinkButton
                                    fontSize="xs"
                                    colorScheme="red"
                                    leftIcon={<IoClose size={16} />}
                                    onClick={() => handleDelete("header")}
                                >
                                    Eliminar
                                </LinkButton>
                            )}
                        </div>
                    )}
                    {hasHeader ? (
                        <img src={buildImgElement(template.header)} alt="header" />
                    ) : (
                        <p className="text-sm text-dark text-center py-2">No definido</p>
                    )}
                </div>

                <div className="mt-2">
                    <h4 className="text-base font-medium text-dark p-1">- Pie de página</h4>
                    {!readOnly && (
                        <div className="flex gap-4 items-center mb-2">
                            <LinkButton
                                fontSize="xs"
                                leftIcon={<BiPlus size={16} />}
                                onClick={() =>
                                    setTemplateHeaderFooterModal({
                                        show: true,
                                        data: { template: template, case: hasFooter ? "edit" : "new", type: "footer" },
                                    })
                                }
                            >
                                {hasFooter ? "Cambiar" : "Agregar"}
                            </LinkButton>
                            {hasFooter && (
                                <LinkButton
                                    fontSize="xs"
                                    colorScheme="red"
                                    leftIcon={<IoClose size={16} />}
                                    onClick={() => handleDelete("footer")}
                                >
                                    Eliminar
                                </LinkButton>
                            )}
                        </div>
                    )}
                    {hasFooter ? (
                        <img src={buildImgElement(template.footer)} alt="footer" />
                    ) : (
                        <p className="text-sm text-dark text-center py-2">No definido</p>
                    )}
                </div>
            </div>
        </AccordionItemBrand>
    );
};

export default TemplateHeaderFooterAccordionItem;
