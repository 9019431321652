import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { ContractSignersConfirmed, ContractSignersSelect } from "../models/Contract.models";
import { ContractSigner } from "../models/Signatures.models";
import contractSignaturesService from "../services/contractSignatures.service";
import useAuthState from "./authState";
import useModalState from "./modalsState";

interface State {
    signers: { loading: boolean; error: boolean; data: ContractSignersSelect[] };
    selectedSigners: ContractSigner[];
    currentSignaturesStatus: ContractSignersConfirmed;
    pdfCanvasSizes: { width: number; height: number; top: number; left: number };
    setSelectedSigners: (signers: ContractSigner[]) => void;
    setCurrentSignaturesStatus: (signatures: ContractSignersConfirmed) => void;
    getSigners: (withLoading?: boolean) => Promise<ContractSignersSelect[]>;
    getSignatures: (contractId: string, withLoading?: boolean) => Promise<boolean>;
    setPdfCanvasSizes: (sizes: { width: number; height: number; top: number; left: number }) => void;
    reset: () => void;
}

const initialState = {
    signers: { loading: false, error: false, data: [] },
    selectedSigners: null,
    currentSignaturesStatus: null,
    pdfCanvasSizes: { width: null, height: null, top: null, left: null },
};

const useContractSignaturesState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            setSelectedSigners: setSelectedSigners(set, get),
            setCurrentSignaturesStatus: setCurrentSignaturesStatus(set, get),
            getSigners: getSigners(set, get),
            getSignatures: getSignatures(set, get),
            setPdfCanvasSizes: (sizes) => set({ pdfCanvasSizes: sizes }),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "ContractSignaturesState" }
    )
);

export default useContractSignaturesState;

//FUNCTIONS
function setSelectedSigners(set: NamedSet<State>, get: GetState<State>) {
    return (signers) => {
        set({ selectedSigners: signers });
    };
}

function setCurrentSignaturesStatus(set: NamedSet<State>, get: GetState<State>) {
    return (signatures) => {
        set({ currentSignaturesStatus: signatures });
    };
}

function hasPermissionForAction (reponse : any, msgPermission : string, redirect : boolean ){
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: 'AVISO',
                text2: msgPermission,
                text3: 'Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.',
                redirectHome : redirect,
                onClose: async () => {
                    return { show: false }
                  }
            }
        });
    }
}


function getSigners(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        set((state) => ({ signers: { loading: true, error: false, data: state.signers.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await contractSignaturesService.listUsers(idCompany);

        hasPermissionForAction(res,"Obtener firmantes",false);

        if (res?.data?.respuesta) {
            set({ signers: { loading: false, error: false, data: res.data.respuesta } });
            return res.data.respuesta;
        } else {
            console.log("Error fetching signers");
            set((state) => ({ signers: { loading: false, error: true, data: state.signers.data } }));
            //clean error
            set((state) => ({ signers: { ...state.signers, error: false } }));
            return null;
        }
    };
}

function getSignatures(set: NamedSet<State>, get: GetState<State>) {
    return async (documentId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await contractSignaturesService.getSignaturesByDocument(documentId);
        
        hasPermissionForAction(res,"Obtener firma",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.ok) {
            set({ currentSignaturesStatus: res.data.data });
            return true;
        } else {
            console.log("Error fetching signatures status");
            set({ currentSignaturesStatus: null });
            return false;
        }
    };
}
