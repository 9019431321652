import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { Template } from "../models/Template.models";
import templateService from "../services/template.service";
import useAuthState from "./authState";
import useDataGroupsState from "./dataGroupsState";
import useModalState from "./modalsState";
import { htmlToBase64 } from "src/lib/utils";

interface State {
    templates: { loading: boolean; error: boolean; data: Template[] };
    selectedTemplate: Template;
    currentNewTemplate: Partial<Template>;
    hasSavedTemplateDraft: boolean,
    hasSavedTemplate: boolean,
    setHasSavedTemplateDraft:  (value) => any;
    setHasSavedTemplate:  (value) => any; 
    setTemplates: (templates: Template[]) => any;
    setSelectedTemplate: (template: Template) => any;
    initNewTemplate: () => any;
    setCurrentNewTemplate: (template: Partial<Template>) => any;
    getTemplates: (withLoading?: boolean) => any;
    saveTemplate: (template: Template, withLoading?: boolean) => Promise<any>;
    saveTemplateFromDocx: (template: Template, file: string, withLoading?: boolean) => Promise<boolean>;
    cloneTemplate: (template: Template, withLoading?: boolean) => Promise<boolean>;
    updateTemplate: (template: Template, withLoading?: boolean) => Promise<boolean>;
    deleteTemplate: (templateId: string, withLoading?: boolean) => Promise<boolean>;
    getTemplate: (templateId: string, withLoading?: boolean, onlyGet?: boolean) => Promise<Template | null>;
    reset: () => void;
}

const initialState = {
    templates: { loading: false, error: false, data: [] },
    selectedTemplate: null,
    currentNewTemplate: null,
    hasSavedTemplateDraft: false,
    hasSavedTemplate: false,
};

const useTemplateState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            setTemplates: setTemplates(set, get),
            setSelectedTemplate: setSelectedTemplate(set, get),
            initNewTemplate: initNewTemplate(set, get),
            setCurrentNewTemplate: setCurrentNewTemplate(set, get),
            getTemplates: getTemplates(set, get),
            saveTemplate: saveTemplate(set, get),
            saveTemplateFromDocx: saveTemplateFromDocx(set, get),
            cloneTemplate: cloneTemplate(set, get),
            updateTemplate: updateTemplate(set, get),
            deleteTemplate: deleteTemplate(set, get),
            getTemplate: getTemplate(set, get),
            setHasSavedTemplateDraft: setHasSavedTemplateDraft(set, get),
            setHasSavedTemplate:  setHasSavedTemplate(set, get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "TemplateState" }
    )
);

export default useTemplateState;

//SETTERS FUNCTIONS
function setTemplates(set: NamedSet<State>, get: GetState<State>) {
    return (templates: Template[]) => {
        set((state) => ({ templates: { loading: false, error: false, data: templates } }));
    };
}

function initNewTemplate(set: NamedSet<State>, get: GetState<State>) {
    return () => {
        const auth = useAuthState.getState();
        return set({ currentNewTemplate: getEmptyNewTemplate(auth.companyId, auth.userId, auth.userName) });
    };
}

function setCurrentNewTemplate(set: NamedSet<State>, get: GetState<State>) {
    return (template: Template) => set((state) => ({ currentNewTemplate: template }));
}

function setSelectedTemplate(set: NamedSet<State>, get: GetState<State>) {
    return (template: Template) => set((state) => ({ selectedTemplate: template }));
}

function setHasSavedTemplateDraft(set: NamedSet<State>, get: GetState<State>) {
    return (value) => {
        set({ hasSavedTemplateDraft: value })
    };
}

function setHasSavedTemplate(set: NamedSet<State>, get: GetState<State>) {
    return (value) => {
        set({ hasSavedTemplate: value })
    };
}

const msgPermission = "No cuenta con el permiso para esta acción.";
function hasPermissionForAction(reponse: any, msgPermission: string, redirect: boolean) {
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text2: msgPermission,
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                redirectHome: redirect,
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    }
}

function getTemplates(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        set((state) => ({ templates: { loading: true, error: false, data: state.templates.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await templateService.listTemplates(idCompany);

        hasPermissionForAction(res, "Listar Plantillas", true);

        if (res?.data?.respuesta !== msgPermission) {
            set({ templates: { loading: false, error: false, data: res.data.respuesta?.reverse() } });
        } else {
            console.log("Error fetching templates");
            set((state) => ({ templates: { loading: false, error: true, data: state.templates.data } }));
            //clean error
            // set((state) => ({ templates: { ...state.templates, error: false } }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function saveTemplate(set: NamedSet<State>, get: GetState<State>) {
    return async (template: Template, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        template.text = await htmlToBase64(template.text);

        const res = await templateService.saveTemplate(template);
        hasPermissionForAction(res, "Guardar Plantilla", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return res?.data?.respuesta;
        } else {
            console.log("Error saving template");
            return false;
        }
    };
}

function saveTemplateFromDocx(set: NamedSet<State>, get: GetState<State>) {
    return async (template: Template, file: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await templateService.saveTemplateFromDocx({ template, file });

        hasPermissionForAction(res, "Guardar Plantilla", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error saving template from docx");
            return false;
        }
    };
}

function cloneTemplate(set: NamedSet<State>, get: GetState<State>) {
    return async (template: Template, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const fullTemplate = await get().getTemplate(template.id, false, true);
        if (!fullTemplate) return false;
        template = fullTemplate;

        if (template?.dataGroup?.length > 0) {
            await useDataGroupsState.getState().getDataGroups(false);
            const dataGroups = useDataGroupsState.getState().dataGroups?.data;
            template.dataGroup = template.dataGroup
                ?.map((dg) => {
                    const dataGroup = dataGroups.find((d) => {
                        return d.idName === dg.idName;
                    });

                    return dataGroup;
                })
                .filter((dg) => Boolean(dg));
        }

        const templateList = get().templates.data;
        let copyNumber = 1;
        while (templateList.find((c) => c.name === `${template.name?.split("(copy")[0]?.trim()} (copy-${copyNumber})`)) {
            copyNumber++;
        }

        let templateName = `${template.name?.split("(copy")[0]?.trim()} (copy-${copyNumber})`;

        const newTemplate: Partial<Template> = {
            idCompany: template.idCompany,
            idName: "",
            idUser: template.idUser,
            name: templateName,
            status: "borrador",
            text: await htmlToBase64(template.text),
            type: template.type,
            userName: template.userName,
            dataGroup: template.dataGroup,
            maxLifeStage: template.maxLifeStage
        };

        const res = await templateService.saveTemplate(newTemplate as Template);

        hasPermissionForAction(res, "Clonar Plantilla", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error cloning template");
            return false;
        }
    };
}

function updateTemplate(set: NamedSet<State>, get: GetState<State>) {
    return async (template: Template, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        template.text = await htmlToBase64(template.text);
    
        const res = await templateService.updateTemplate(template);

        hasPermissionForAction(res, "Actualizar Plantilla", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error updating template");
            return false;
        }
    };
}

function deleteTemplate(set: NamedSet<State>, get: GetState<State>) {
    return async (templateId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await templateService.deleteTemplate(templateId);

        hasPermissionForAction(res, "Borrar Plantilla", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            set((state) => ({
                templates: { ...state.templates, data: state.templates.data.filter((temp) => temp.id !== templateId) },
            }));
            return true;
        } else {
            console.log("Error deleting template");
            return false;
        }
    };
}

function getTemplate(set: NamedSet<State>, get: GetState<State>) {
    return async (templateId, withLoading = true, onlyGet = false) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await templateService.getTemplateById(templateId);

        hasPermissionForAction(res, "Obtener información de la Plantilla", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.respuesta[0]) {
            !onlyGet && set({ selectedTemplate: res.data.respuesta[0] });
            return res.data.respuesta[0];
        } else {
            console.log("Error fetching template");
            !onlyGet && set({ selectedTemplate: null });
            return null;
        }
    };
}

//AUX
function getEmptyNewTemplate(idCompany: string, idUser: string, userName: string): Partial<Template> {
    return {
        idCompany,
        idUser,
        userName,
        idName: "",
        name: "",
        text: "",
        type: "",
        status: "borrador",
        dataGroup: [],
    };
}
