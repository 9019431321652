import { Icon } from "@chakra-ui/react";

const NewContractIcon = (props) => {
    return (
        <Icon viewBox="0 0 100 105" {...props}>
            <path
                fill="#2E54B8"
                d="m97.907 22.678-21.04-21.04A5.57 5.57 0 0 0 72.924 0H24.558A5.555 5.555 0 0 0 19 5.558v93.884A5.555 5.555 0 0 0 24.558 105h69.407a5.555 5.555 0 0 0 5.559-5.558V26.619a5.57 5.57 0 0 0-1.638-3.941h.02ZM77.655 8.752l12.793 12.915c.344.344.102.95-.383.95H77.27a.543.543 0 0 1-.546-.546V9.156a.55.55 0 0 1 .95-.384l-.02-.02Zm16.775 19.04v71.993a.543.543 0 0 1-.546.546H24.68a.543.543 0 0 1-.546-.546V5.215c0-.304.242-.546.546-.546h46.89c.304 0 .547.242.547.546v16.452a5.555 5.555 0 0 0 5.558 5.558h16.19c.303 0 .545.243.545.546l.02.02Z"
            />
            <path
                fill="#2E54B8"
                d="M81.171 38.22H37.352a2.284 2.284 0 0 0 0 4.569h43.82a2.284 2.284 0 0 0 0-4.568ZM81.171 53.763H37.352a2.284 2.284 0 1 0 0 4.568h43.82a2.284 2.284 0 1 0 0-4.568ZM81.171 69.306H37.352a2.284 2.284 0 1 0 0 4.568h43.82a2.284 2.284 0 1 0 0-4.568ZM81.171 84.85H37.352a2.284 2.284 0 0 0 0 4.568h43.82a2.284 2.284 0 0 0 0-4.568Z"
            />
            <path
                fill="#2E54B8"
                d="M21 33C9.412 33 0 42.412 0 54s9.412 21 21 21 21-9.412 21-21-9.412-21-21-21Zm0 38.182c-9.47 0-17.182-7.713-17.182-17.182 0-9.47 7.713-17.182 17.182-17.182 9.47 0 17.182 7.713 17.182 17.182 0 9.47-7.713 17.182-17.182 17.182Z"
            />
            <path
                fill="#F4F4F4"
                d="M21 36.817C11.531 36.817 3.818 44.53 3.818 54c0 9.47 7.713 17.182 17.182 17.182 9.47 0 17.182-7.713 17.182-17.182 0-9.469-7.713-17.182-17.182-17.182Zm8.973 19.015h-7.14v7.14c0 .954-.821 1.718-1.833 1.718s-1.833-.764-1.833-1.718v-7.14h-7.14c-.954 0-1.718-.821-1.718-1.833s.764-1.833 1.718-1.833h7.14v-7.14c0-.954.821-1.718 1.833-1.718s1.833.764 1.833 1.718v7.14h7.14c.954 0 1.718.821 1.718 1.833s-.764 1.833-1.718 1.833Z"
            />
            <path
                fill="#2E54B8"
                d="M31.69 54c0 1.011-.763 1.832-1.717 1.832h-7.14v7.14c0 .955-.821 1.718-1.833 1.718s-1.833-.763-1.833-1.718v-7.14h-7.14c-.954 0-1.718-.82-1.718-1.833 0-1.011.764-1.832 1.718-1.832h7.14v-7.14c0-.955.821-1.718 1.833-1.718s1.833.763 1.833 1.718v7.14h7.14c.954 0 1.718.82 1.718 1.832Z"
            />
        </Icon>
    );
};

export default NewContractIcon;
