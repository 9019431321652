import { Button } from "@chakra-ui/react";
import { FiDownload, FiUpload } from "react-icons/fi";
import useCustomToast from "../../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { EXCEL_MIME_TYPES } from "../../../../lib/constants";
import { ContractBulkLoadModalData } from "../../../../models/Modals.models";
import useContractState from "../../../../store/contractState";
import CommonModal from "../../CommonModal";
import FileDropzone from "../../FileDropzone";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: ContractBulkLoadModalData;
};

const ContractBulkLoad = ({ isOpen, onClose, data }: Props) => {
    const toast = useCustomToast();
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const { uploadBulkContractFile, downloadBulkContractFile } = useContractState();

    function handleDownload() {
        downloadBulkContractFile(data?.template?.id, data.template?.name);
    }

    async function handleUpload(file: File) {
        if (!EXCEL_MIME_TYPES.includes(file.type)) {
            return toast.error(t("invalidFileType"));
        }
        const uploaded = await uploadBulkContractFile(file, data?.template?.id);
        if (uploaded) {
            onClose();
            toast.success(t("uploadedFileSuccessfully"));
        } else {
            toast.error(t("uploadingFileError"));
        }
    }

    return (
        <CommonModal size="md" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">{t("contractsBulkLoad")}</h2>

                <div className="flex flex-col justify-center">
                    <h3 className="text-base text-dark font-semibold text-center w-10/12 mx-auto">{t("downloadExcelFile")}:</h3>
                    <div className="text-center">
                        <Button
                            colorScheme="brand"
                            variant="solid"
                            size={"sm"}
                            className="mt-2"
                            rightIcon={<FiDownload size={20} />}
                            onClick={handleDownload}
                        >
                            {t("download")}
                        </Button>
                    </div>

                    <div className="flex flex-col justify-center mt-8">
                        <h3 className="text-base text-dark font-semibold text-center w-10/12 mx-auto">{t("uploadExcelFile")}:</h3>
                        <div className="mt-3">
                            <FileDropzone onDropFile={handleUpload} />
                        </div>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
};

export default ContractBulkLoad;
