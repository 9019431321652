import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import CommonModal from "../../CommonModal";
import { Accordion, Button, AccordionButton, AccordionIcon, Badge } from "@chakra-ui/react";
import AccordionItemBrand from "../../AccordionItemBrand";
import useBiddingState from "src/store/biddings/biddingState";
import useBiddingProfileState from "src/store/biddings/biddingProfileState";
import { useEffect, useState } from "react";
import { DataGroup } from "src/models/DataGroup.models";
import { ExpedientModalData } from "src/models/Modals.models";
import useCustomToast from "src/hooks/useCustomToast";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { MdDisabledVisible } from "react-icons/md";
import { BiAddToQueue } from "react-icons/bi";
import { FormControl, Input, InputGroup, Tooltip, useBoolean, Alert, AlertIcon } from "@chakra-ui/react";
import { GrRadialSelected, GrRadial } from "react-icons/gr";
import useAuthState from "../../../../store/authState";
import useAdministrationState from "../../../../store/administrationState";
import CommonTh from "../../table/CommonTh";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import CreateProfileBidding from "./CreateProfileBidding";
import React from "react";
import { UserGroup } from "src/models/Aministration.models";
import { IoClose } from "react-icons/io5";
import UserIcon from "src/components/icons/UserIcon";
import SlicedText from "../../SlicedText";
import NotificationsBidding from "src/components/notifications/NotificationsBiddingTable";
import useBiddingTypesState from "src/store/biddings/biddingTypesState";
import UsersAndGroupsBiddingTable from "src/components/profiles-bidding/UsersAndGroupsBiddingTable";
import useBiddingNotificationState from "src/store/biddings/biddingNotificationsState";
import useModalState, { confirmAlert } from "src/store/modalsState";
import CreateNotificationsBidding from "src/components/notifications/CreateNotificationsBidding";
import { MdOutlineNotificationAdd } from "react-icons/md";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const CompaniesAdminTable = ({ companies, setCompany }) => {
    const [updatedCompany, setUpdatedCompany] = useState(companies);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleButtonClick = (item) => {
        if (selectedItem === item) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
            setCompany(item);
        }
    };

    const handleUpdateCompany = () => {
        const updatedDataCopy = [...updatedCompany];
        const updatedDataWithSelected = updatedDataCopy?.map((item) => {
            item.isSelected = false;
            return item;
        });
        setUpdatedCompany(updatedDataWithSelected);
    };

    useEffect(() => {
        handleUpdateCompany();
    }, []);

    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            <table className="w-full text-sm text-left text-dark-2">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={"RFC"} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {updatedCompany?.map((item, idx) => (
                        <tr id={idx} className="border-b hover:bg-slate-100 transition-colors duration-200">
                            <td className="p-2 py-3">{`${item.foreignCompanyName}`}</td>
                            <td className="p-2 py-3">{item.foreignCompanyRfc}</td>
                            <td>
                                <div id={idx} className="w-full xl:w-7/12 flex">
                                    <Tooltip id={idx} label={"Seleccionar empresa"} fontSize="12px">
                                        {
                                            <button
                                                key={idx}
                                                onClick={() => handleButtonClick(item)}
                                                disabled={selectedItem && selectedItem !== item}
                                            >
                                                {item.isSelected ? (
                                                    <GrRadial size={25} style={{ color: "blue" }} />
                                                ) : (
                                                    <GrRadialSelected size={25} style={{ color: "blue" }} />
                                                )}
                                            </button>
                                        }
                                    </Tooltip>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

type ItemProps = {
    order: number;
    user: any;
    onDelete: (...args: any) => any;
};

const RevisionItem = ({ order, user, onDelete }: ItemProps) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    return (
        <li className="flex items-center text-lg text-gray-600 my-">
            <div className="mr-6 pt-2">{order}</div>
            <UserIcon boxSize={12} />
            <div className="ml-4 pt-2">
                <div className="text-base text-gray-900 relative inline-block">
                    <SlicedText text={user.name} maxLength={16} />
                </div>
                <div className="text-xs">{user.email}</div>
            </div>
            <div className="grow"></div>

            <button className="text-red-500 ml-2 pr-3" onClick={onDelete}>
                <IoClose size={15} color="inherit" />
            </button>
        </li>
    );
};

const UserGroupsTable = ({ companies, setCompany, users }) => {
    const [updatedCompany, setUpdatedCompany] = useState(companies);
    const [selectedItem, setSelectedItem] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [usersInUserGroup, setUsersInUserGroup] = useState([]);
    const handleButtonClick = (item) => {
        if (selectedItem === item) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
            setCompany(item);
            setShowDetails(true);
        }
    };

    function handleSelectUserGroup() {
        const userGroupList = selectedItem.userList?.map((user) => user);
        const filteredUsersInUserGroupList = users.filter((user) => userGroupList.includes(user.id));

        setUsersInUserGroup((prevState) => {
            const updatedRevisorUsers = [...prevState, ...filteredUsersInUserGroupList];
            return updatedRevisorUsers;
        });
    }

    const handleUpdateCompany = () => {
        const updatedDataCopy = [...updatedCompany];
        const updatedDataWithSelected = updatedDataCopy?.map((item) => {
            item.isSelected = false;
            return item;
        });
        setUpdatedCompany(updatedDataWithSelected);
    };

    function handleDelete(user) {
        setUsersInUserGroup((prevState) => {
            return prevState.filter((u) => u.id !== user.id);
        });
    }

    useEffect(() => {
        handleUpdateCompany();
    }, []);

    useEffect(() => {
        // Esconder detalles cuando se deselecciona el item
        if (!selectedItem) {
            setShowDetails(false);
            setUsersInUserGroup([]);
        }
        if (selectedItem) {
            handleSelectUserGroup();
        }
    }, [selectedItem]);

    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            <table className="w-full text-sm text-left text-dark-2">
                {/* ... Tu encabezado aquí ... */}
                <tbody>
                    {updatedCompany?.map((item, idx) => (
                        <React.Fragment key={idx}>
                            <tr
                                id={idx}
                                className={`border-b hover:bg-slate-100 transition-colors duration-200 ${
                                    item.isSelected ? "bg-blue-100" : ""
                                }`}
                                onClick={() => handleButtonClick(item)}
                            >
                                <td className="p-2 py-3">{item.name}</td>
                                <td className="p-2 py-3">{item.description}</td>
                                <td>
                                    <div id={idx} className="w-full xl:w-10/12 flex">
                                        <Tooltip id={idx} label={"Seleccionar empresa"} fontSize="12px">
                                            <button
                                            //  disabled={selectedItem && selectedItem !== item}
                                            >
                                                {selectedItem && selectedItem !== item ? (
                                                    <GrRadial size={25} style={{ color: "blue" }} />
                                                ) : (
                                                    <GrRadialSelected size={25} style={{ color: "blue" }} />
                                                )}
                                            </button>
                                        </Tooltip>
                                    </div>
                                </td>
                            </tr>
                            {showDetails && selectedItem && selectedItem === item && (
                                <ul className="mt-4 space-y-4 max-h-40 overflow-y-auto">
                                    {usersInUserGroup?.map((user, i) => (
                                        <RevisionItem key={user.id} order={i + 1} user={user} onDelete={() => handleDelete(user)} />
                                    ))}
                                </ul>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const PopulateGroupsBidding = ({ stage, group, updatedData, setUpdatedData }) => {
    const handleToggleValidity = (e, itemName) => {
        e.preventDefault();
        const updatedDataCopy = [...updatedData];
        const updatedDataWithFalse = updateisValidGroup(itemName, updatedDataCopy);
        setUpdatedData(updatedDataWithFalse);
    };

    const updateisValidGroup = (groupName, updatedDataCopy) => {
        const updatedDataWithFalse = updatedDataCopy?.map((item) => {
            if (item.name === stage) {
                item.groups.forEach((group) => {
                    if (group.name === groupName) {
                        group.isValid = !group.isValid;
                    }
                    return group;
                });
            }
            return item;
        });
        return updatedDataWithFalse;
    };

    const handleToggleValidityRequiriment = (itemName, groupName) => {
        const updatedDataCopy = [...updatedData];
        const updatedDataWithFalse = updatedDataCopy?.map((item) => {
            if (item.name === stage) {
                item.groups.forEach((group) => {
                    if (group.name === groupName) {
                        group.requirements?.map((req) => {
                            if (req.name === itemName) {
                                req.isValid = !req.isValid;
                            }
                            if (group.requirements.length === 1 && req.isValid === false) {
                                //agregar validacion cuando no exista ningun req marcado
                                updateisValidGroup(groupName, updatedDataCopy);
                                setUpdatedData(updatedDataWithFalse);
                            }
                            return req;
                        });
                    }
                    return group;
                });
            }
            return item;
        });
        setUpdatedData(updatedDataWithFalse);
    };

    return (
        <div>
            {group?.map((group, idx) => {
                return (
                    <div>
                        <Tabs id={idx}>
                            <TabList>
                                <Tooltip label="Configurar notificaciones de grupo" fontSize="15px">
                                    <Tab onClick={(e) => handleToggleValidity(e, group.name)}>
                                        <div id={idx} className="flex flex-row lg:flex-row  grid-cols-2 gap-4 ">
                                            <div>
                                                <button className="text-gray-700">
                                                    <MdOutlineNotificationAdd style={{ color: "green" }} />
                                                </button>
                                            </div>

                                            <div id={idx}>{`Grupo : ${group.name}`}</div>
                                        </div>
                                    </Tab>
                                </Tooltip>
                            </TabList>
                            <TabPanels id={idx}>
                                <TabPanel id={idx}>
                                    <ul key={idx} className="mb-4">
                                        {group.isValid
                                            ? ""
                                            : group.requirements?.map((op, i) => {
                                                  return (
                                                      <li key={i} className="mb-2">
                                                          <label className="flex items-center">
                                                              <input
                                                                  type="checkbox"
                                                                  id={i}
                                                                  name="options"
                                                                  value={op.name}
                                                                  checked={op.isValid}
                                                                  onChange={() => handleToggleValidityRequiriment(op.name, group.name)}
                                                                  className="mr-2"
                                                              />
                                                              {`${op.name}`}
                                                          </label>
                                                      </li>
                                                  );
                                              })}
                                    </ul>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                );
            })}
        </div>
    );
};

const BiddingNotificationsModal = ({ isOpen, onClose, data }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const setProfileModal = useModalState((s) => s.setBiddingProfileModal);
    const { createBiddingProfile, getProfilesBiddingById, profilesBidding, updateProfileBiddingList } = useBiddingProfileState();
    // NEW STATE NOTIF
    const { getBiddingNotificacionCompany, biddingnotificacion, createBiddingnotificacion, updatenotificacionBiddingList } =
        useBiddingNotificationState(); // here
    console.log("biddingTypeId desde bidding", data?.bidding?.biddingTypeId);

    console.log("operacion ", biddingnotificacion);
    const { getBiddingType, infoBiddingTypeById } = useBiddingTypesState();
    const { getBidding, selectedBidding } = useBiddingState();
    const hasDataGroups = data?.stages;
    const [stagesProfile] = useState([data?.bidding?.stages]);
    const [biddingTypeId] = useState(data?.bidding?.biddingTypeId);
    const [biddingId] = useState(data?.bidding?.id);

    const [onCreateorEditProfile, setOnCreateorEditProfile] = useState(false);
    const [isNewProfile, setIsNewProfile] = useState(false);
    const [tempProfileData, setTempProfileData] = useState();

    const [typeName, setTypeName] = useState("");
    const [description, setDescription] = useState("");
    const [company, setCompany] = useState(null);
    const [bidding] = useState(data.bidding);
    const [copyBidding, setCopyBidding] = useState([]);
    const [invalidName, setInvalidName] = useBoolean(false);
    const [updatedData, setUpdatedData] = useState(data?.stages);
    const [filteredStages, setFilteredStages] = useState([]);
    const idCompany = useAuthState.getState().companyId;

    const [etiquetaSeleccionada, setEtiquetaSeleccionada] = useState(null); // Etiqueta seleccionada

    const [usersIdsListInSelectedGroup, setUsersIdsListInSelectedGroup] = useState([]);
    const [selectedUserGroup, setSelectedUserGroup] = useState("");
    const [selectedProfile, setSelectedProfile] = useState("");

    // new state const notifications

    const [tempNotificationData, setTempNotificationData] = useState();
    const {
        associatedCompanies: { data: associatedCompanies },
        getAssociatedCompanies,
        getCompanyUserGroups,
        companyUsersGroups: { data: companyUsersGroups },
        getCompanyUsers,
        companyUsers: { data: companyUsers },
        getCompanyUsersGroupAnnexeByList,
        annexeCompanyList: { data: annexeCompanyList },
    } = useAdministrationState();

    const handleUpdateData = () => {
        const updatedDataCopy = [...updatedData];
        const updatedDataWithFalse = updatedDataCopy?.map((item) => {
            item.isValid = false;
            item.groups.forEach((group) => {
                group.isValid = false;
                group.requirements.forEach((requirement) => {
                    requirement.isValid = true;
                    return requirement;
                });
                return group;
            });
            return item;
        });
        setUpdatedData(updatedDataWithFalse);
    };

    const createProfile = (isBack?: any) => {
        setOnCreateorEditProfile(false);
        setTempProfileData(null);
        setIsNewProfile(false);
        getProfilesBiddingById(bidding.id);
        getBiddingNotificacionCompany(biddingId);
        if (!isBack) {
            setProfileModal({
                show: false,
            });
        }
    };

    const editProfile = (profileData, onEdit) => {
        // getProfilesBiddingById(bidding.id)
        if (onEdit) {
            setTempProfileData(profileData);
            setIsNewProfile(true);
            setOnCreateorEditProfile(true);
        } else {
            setOnCreateorEditProfile(true);
        }
    };

    const handleSelectUser = (userId) => {
        const isSelected = usersIdsListInSelectedGroup.includes(userId);

        if (isSelected) {
            setUsersIdsListInSelectedGroup(usersIdsListInSelectedGroup.filter((id) => id !== userId));
        } else {
            setUsersIdsListInSelectedGroup([...usersIdsListInSelectedGroup, userId]);
        }
    };

    const filterUsersListInUserGroup = () => {
        const usersList = companyUsersGroups.filter((userGroup) => userGroup.id === selectedUserGroup);
        setUsersIdsListInSelectedGroup(usersList.length > 0 ? usersList[0].userList : null);
    };

    const handleSelectUserGroup = (userGroup: string) => {
        setSelectedUserGroup(userGroup);
    };

    const handleSelectProfile = (profile: string, users: string[]) => {
        setSelectedProfile(profile);
        setUsersIdsListInSelectedGroup(users);
        setSelectedUserGroup("");
    };

    const handleSaveUserGroups = () => {
        if (annexeCompanyList.some((user) => usersIdsListInSelectedGroup.includes(user.userId)) && selectedProfile.length > 0) {
            if (annexeCompanyList.filter((user) => user.userId.length > 0)) {
                confirmAlert.show({
                    title: "¿Estas seguro de modificar este perfil?",
                    msg: "Uno o más usuarios pertenecen a otro perfil",
                    severity: "info",
                    onConfirm: () => handleUpdateProfile(),
                });
            }
        } else if (selectedProfile.length > 0) {
            handleUpdateProfile();
        } else {
            toast.error("Faltan campos por llenar");
        }
    };

    const handleUpdateProfile = () => {
        const saved = updateProfileBiddingList({ usersList: usersIdsListInSelectedGroup, profile: selectedProfile });

        if (saved) {
            toast.success("Perfil asignado con éxito");
        } else {
            toast.error("Error al asignar el perfil");
        }
    };

    // Función de comparación para sort
    const compareUsers = (a, b, selectedUsers) => {
        const isSelectedA = selectedUsers.includes(a.id);
        const isSelectedB = selectedUsers.includes(b.id);

        // Colocar usuarios seleccionados primero
        if (isSelectedA && !isSelectedB) {
            return -1;
        } else if (!isSelectedA && isSelectedB) {
            return 1;
        } else {
            // Mantener el orden original para usuarios no seleccionados
            return 0;
        }
    };

    // Función para ordenar la lista de usuarios
    const sortUsers = (users, selectedUsers) => {
        // Utilizar la función de comparación para sort
        return [...users].sort((a, b) => compareUsers(a, b, selectedUsers));
    };

    const assignUsersToSubscriptions = async () => {
        const saved = await updatenotificacionBiddingList({ biddingId: selectedProfile, usersList: usersIdsListInSelectedGroup });
        if (saved) {
            toast.success("Notificaciones asignadas correctamente");
            getBiddingNotificacionCompany(biddingId);
            onClose();
        } else {
            toast.error("Ha ocurrido un error al asignar tus notificaciones");
            onClose();
        }
    };

    useEffect(() => {
        // getProfilesBiddingById(bidding.id);
        getCompanyUsers();
        getCompanyUserGroups();
    }, []);

    useEffect(() => {
        getBidding(biddingId);
        getAssociatedCompanies();
        getBiddingNotificacionCompany(biddingId);
        setUpdatedData(copyBidding);
        //getBiddingType(biddingTypeId);
        handleUpdateData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copyBidding]);

    useEffect(() => {
        if (selectedUserGroup) {
            filterUsersListInUserGroup();
        }
    }, [selectedUserGroup]);

    useEffect(() => {
        if (usersIdsListInSelectedGroup.length > 0) {
            getCompanyUsersGroupAnnexeByList(usersIdsListInSelectedGroup);
        }
    }, [usersIdsListInSelectedGroup]);

    return (
        <CommonModal size="6xl" isOpen={isOpen} onClose={onClose}>
            <main className="w-full flex flex-col h-[60vh] relative pb-2 pt-2 overflow-hidden">
                <div className="w-full flex items-end justify-between gap-6 sticky top-0">
                    <div>
                        <h2 className="title-2">{t("processNotificationConfig")}</h2>
                    </div>
                    {/*<div className="mr-6">
                <Button rounded={"full"} disabled={!hasDataGroups} onClick={filterIsValidaStage}>
                    Guardar 
                </Button></div>*/}
                </div>

                {onCreateorEditProfile ? (
                    <CreateNotificationsBidding
                        isOpen={false}
                        onClose={(isBack) => createProfile(isBack)}
                        isNewProfile={isNewProfile}
                        tempData={tempProfileData}
                        data={selectedBidding?.stages}
                        biddingId={biddingId}
                        usersIdsListInSelectedGroup={usersIdsListInSelectedGroup}
                    />
                ) : (
                    <>
                        <div className="flex pt-4 mb-2">
                            {/* left panel */}
                            <div className="w-6/12">
                                <h2 className="title-3">{t("processNotifications")} proceso</h2>
                                <p className="mt-2 text-xs text-justify">{t("seeNotificationsInProcess")}</p>
                            </div>
                            {/* right panel */}
                            <div className="w-6/12 border-l pl-2">
                                <h2 className="title-3">Asigna tus usuarios a notificaciones</h2>
                                <p className="mt-2 text-xs text-justify">
                                    Selecciona tus usuarios o grupos para asignarlos a una notificación
                                </p>
                            </div>
                        </div>

                        <div id="divider" className="border-t border-dotted w-12/12 mb-6"></div>

                        <section className="flex flex-grow overflow-hidden">
                            {/* left panel */}
                            <section className="w-6/12 pr-4 h-full overflow-y-auto border-r">
                                <NotificationsBidding
                                    profilesData={biddingnotificacion}
                                    onEditProfile={editProfile}
                                    biddingId={biddingId}
                                    handleSelectProfile={handleSelectProfile}
                                    selectedProfile={selectedProfile}
                                />
                            </section>
                            {/* right panel */}
                            <section className="w-6/12 pl-1 h-full overflow-y-auto">
                                <UsersAndGroupsBiddingTable
                                    usersIdsListInSelectedGroup={usersIdsListInSelectedGroup}
                                    selectedUserGroup={selectedUserGroup}
                                    handleSelectUserGroup={handleSelectUserGroup}
                                    handleSelectUser={handleSelectUser}
                                />
                                <div className="w-full text-center">
                                    <Button
                                        type="submit"
                                        rounded="full"
                                        size="md"
                                        onClick={assignUsersToSubscriptions}
                                        fontWeight="medium"
                                        px={"2rem"}
                                        isLoading={false}
                                    >
                                        {t("assignUsersToNotifications")}
                                    </Button>
                                </div>
                            </section>
                        </section>
                    </>
                )}
            </main>
        </CommonModal>
    );
};

export default BiddingNotificationsModal;
