import { Accordion, Button, FormControl, Input, InputGroup, Select, useBoolean } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiChevronLeft, BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import AccordionItemBrand from "../../components/common/AccordionItemBrand";
import LinkButton from "../../components/common/buttons/LinkButton";
import DataTypesTable from "../../components/data-groups/DataTypesTable";
import MainLayout from "../../components/layouts/MainLayout";
import { GENERIC_DATAGROUP_TYPE } from "../../lib/constants";
import useCustomToast from "../../hooks/useCustomToast";
import { DataType } from "../../models/DataType.models";
import useContractState from "../../store/contractState";
import useDataGroupsState from "../../store/dataGroupsState";
import useDataTypesState from "../../store/dataTypesState";
import useModalState from "../../store/modalsState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import capitalize from "lodash/capitalize";

const CreateDataGroupsPage = () => {
    const showNewDataTypeModal = useModalState((s) => s.setNewDatatTypeModal);
    const setContractTypesModal = useModalState((s) => s.setContractTypesModal);
    const { setCurrentNewDataGroup, currentNewDataGroup, createDataGroup } = useDataGroupsState();
    const { contractTypes, getContractTypes } = useContractState();
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const toast = useCustomToast();
    const {
        dataTypes: { data: dataTypes, error },
        getDataTypes,
    } = useDataTypesState();

    const [invalidName, setInvalidName] = useBoolean(false);
    const [invalidType, setInvalidType] = useBoolean(false);
    const [dataGroupName, setDataGroupName] = useState("");

    function handleGroupTypeChange(e: any) {
        const type = e.target.value;
        setCurrentNewDataGroup({ ...currentNewDataGroup, status: type });
    }

    function handleDataTypeSelect(dt: DataType, selected: boolean) {
        const tempDataTypes = currentNewDataGroup?.types ?? [];
        if (selected) {
            const alreadyExist = tempDataTypes.find((dataType) => dataType.idName === dt.idName);
            if (!alreadyExist) {
                tempDataTypes.push(dt);
            }
            setCurrentNewDataGroup({ ...currentNewDataGroup, types: tempDataTypes });
        } else {
            setCurrentNewDataGroup({ ...currentNewDataGroup, types: tempDataTypes.filter((dataType) => dataType.idName !== dt.id) });
        }
    }

    async function handleSave() {
        if (!dataGroupName) {
            setInvalidName.on();
            toast.error(t("nameRequired"), t("warning"));
            return false;
        } else {
            setInvalidName.off();
        }

        if (currentNewDataGroup?.status === null || currentNewDataGroup?.status === undefined) {
            setInvalidType.on();
            toast.error(t("typeFieldRequired"), t("warning"));
            return false;
        } else {
            setInvalidType.off();
        }

        if (!currentNewDataGroup.types?.length) {
            toast.error(t("leastOneDataTypeRequired"), t("warning"));
            return false;
        }

        const dgSaved = await createDataGroup({ ...currentNewDataGroup, name: dataGroupName });
        if (dgSaved) {
            toast.success(t("dataGroupCreated"));
            navigate("../");
        } else {
            toast.error(t("creatingDataGroupError"));
        }
    }

    useEffect(() => {
        getDataTypes();
        getContractTypes(false);

        return () => setCurrentNewDataGroup(null);
    }, []);

    useEffect(() => {
        if (error) {
            toast.error(t("fetchingDataTypesError"));
        }
    }, [error]);

    return (
        <MainLayout>
            <main className="w-full p-3 flex h-full">
                {/* left panel */}
                <section className="w-3/12 flex flex-col pr-4 pl-2 border-r h-full max-h-full overflow-y-auto relative">
                    <h2 className="title-3">{t("generateDataGroups")}</h2>
                    <p className="mt-2 text-xs text-justify">{t("createDataGroupText")}</p>

                    <div className="mt-6 pb-12">
                        <Accordion defaultIndex={[0]} allowMultiple>
                            <AccordionItemBrand title={t("information")}>
                                <div>
                                    <FormControl isInvalid={invalidName}>
                                        <label htmlFor="group_name" className="text-sm leading-8">
                                            {t("name")}
                                        </label>
                                        <InputGroup borderColor="gray.400">
                                            <Input
                                                id="group_name"
                                                type="text"
                                                rounded="none"
                                                value={dataGroupName}
                                                onChange={(e) => setDataGroupName(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormControl>

                                    <FormControl className="mt-4" isInvalid={invalidType}>
                                        <label htmlFor="datagroup_type" className="text-sm leading-8">
                                            {t("type")}
                                        </label>
                                        <Select
                                            defaultValue={null}
                                            id="datagroup_type"
                                            size="md"
                                            rounded="none"
                                            onChange={handleGroupTypeChange}
                                        >
                                            <option value={null} disabled>
                                                {t("select")}
                                            </option>
                                            <option value={GENERIC_DATAGROUP_TYPE}>{t("generic")}</option>
                                            {contractTypes?.data?.map((type) => (
                                                <option key={type.id} value={type.description}>
                                                    {capitalize(type.description)}
                                                </option>
                                            ))}
                                        </Select>
                                        <LinkButton
                                            fontSize="xs"
                                            leftIcon={<BiPlus size={18} />}
                                            className="mt-2"
                                            onClick={() => setContractTypesModal({ show: true })}
                                        >
                                            {t("addNew")}
                                        </LinkButton>
                                    </FormControl>
                                </div>
                            </AccordionItemBrand>
                        </Accordion>
                    </div>
                    <div className="grow"></div>
                    <div className="flex flex-col lg:flex-row justify-center gap-2 pt-4 pb-2 sticky bottom-0 bg-white border-t">
                        <Button rounded="full" size="md" fontSize="sm" fontWeight="medium" variant="outline" onClick={handleSave}>
                            {t("save")}
                        </Button>
                        {/* <Button rounded="full" size="md" fontSize="sm" fontWeight="medium" variant="solid" isLoading={false}>
                            Crear plantilla
                        </Button> */}
                    </div>
                </section>

                {/* right panel */}
                <section className="w-9/12 pr-2 pl-4 h-full max-h-full overflow-y-auto">
                    <div className="w-full flex items-center justify-between py-4 border-b">
                        <LinkButton leftIcon={<BiChevronLeft size={18} />} onClick={() => navigate(-1)}>
                            {t("back")}
                        </LinkButton>
                        <LinkButton leftIcon={<BiPlus size={18} />} onClick={() => showNewDataTypeModal({ show: true })}>
                            {t("addNewDataType")}
                        </LinkButton>
                    </div>
                    <DataTypesTable
                        dataTypes={dataTypes}
                        onDataTypeSelect={handleDataTypeSelect}
                        selectedDataTypeNames={currentNewDataGroup?.types?.map((dt) => dt.idName)}
                    />
                </section>
            </main>
        </MainLayout>
    );
};

export default CreateDataGroupsPage;
