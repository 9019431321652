import { IconButton, Tooltip } from "@chakra-ui/react"
import { IconType } from "react-icons"

interface ActionIconProps {
    label: string
    icon: IconType
    disabled?: boolean
    size?: string
    variant?: string
    color?: string
    onHandle: () => void
}

const ActionIcon: React.FC<ActionIconProps> = ({
    label,
    icon: Icon,
    disabled = false,
    size = 'sm',
    variant = 'solid',
    color = 'gray',
    onHandle
}) => {
    /** */
    return (
        <Tooltip label={label}>
            <IconButton aria-label={""}
                icon={<Icon size={20} />}
                variant={variant}
                size={size}
                colorScheme={color}
                isDisabled={disabled}
                onClick={onHandle}
            />
        </Tooltip>
    )
}

export default ActionIcon