import { memo } from "react";

// Require Editor JS files.
import "froala-editor/js/froala_editor.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/special_characters.min.js";
import "froala-editor/js/plugins/word_paste.min.js";
import "froala-editor/js/third_party/spell_checker.min.js";
//import "froala-editor/js/plugins/track_changes.min.js";
//import "froala-editor/js/languages/es.js";

// Require Editor CSS files.
//import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins/colors.min.css";
import "froala-editor/css/plugins/table.min.css";
import "froala-editor/css/plugins/image.min.css";
import "froala-editor/css/plugins/special_characters.min.css";
import "froala-editor/css/third_party/spell_checker.min.css";
import "./editor-styles/custom.css";

import FroalaEditor from "react-froala-wysiwyg";
import config from "src/lib/config";

type Props = {
    onSetRef: (editorInstance: any) => any;
    onChange: (content: string) => any;
    height: string;
    defaultValue: string;
    readOnly: boolean;
};

const Editor = memo(function ({ defaultValue, height, onChange, onSetRef, readOnly }: Props) {

    return (
        <>
            <div className="relative" style={{ height }}>
                <FroalaEditor
                    ref={onSetRef}
                    model={defaultValue}
                    config={{
                        key: config.froalaApiKey,
                        events: {
                            contentChanged: function () {
                                onChange(this.html.get(true));
                            },
                            initialized: function () {
                                if (readOnly) {
                                    this.edit.off();
                                }
                                //@ts-ignore
                                window.WEBSPELLCHECKER.init({
                                    container: this.$iframe ? this.$iframe[0] : this.el,
                                });
                            },
                            //upload images as base64
                            "image.beforeUpload": function (files) {
                                const editor = this;
                                if (files.length) {
                                    var reader = new FileReader();
                                    reader.onload = function (e) {
                                        var result = e.target.result;
                                        editor.image.insert(result, null, null, editor.image.get());
                                    };
                                    reader.readAsDataURL(files[0]);
                                }
                                return false;
                            },
                        },
                        language: "es",
                        documentReady: true,
                        //enableTrackChanges: true,
                        readOnly,
                        pastePlain: true,
                        spellcheck: true,
                        toolbarSticky:false, //done by css
                        toolbarButtons: [
                            "undo",
                            "redo",
                            "|",
                            "paragraphFormat",
                            "|",
                            "fontFamily",
                            "fontSize",
                            "|",
                            "bold",
                            "italic",
                            "underline",
                            "strikeThrough",
                            "textColor",
                            "backgroundColor",
                            "|",
                            "align",
                            "outdent",
                            "indent",
                            "|",
                            "formatOL",
                            "formatUL",
                            "|",
                            "insertLink",
                            "insertTable",
                            "insertImage",
                            "insertHR",
                            "|",
                            "specialCharacters",
                            "clearFormatting",
                            "spellChecker",
                            //"trackChanges"
                        ],
                        tableCellMultipleStyles: true,
                        tableStyles: {
                            noBorder: "No Border",
                            dashed: "Dashed Border",
                            alternateRows: "Alternate Rows",
                        },
                        tableCellStyles: {
                            highlight: "Highlight",
                            noBorder: "No Border",
                        },
                    }}
                />
            </div>
        </>
    );
});

export default Editor;
