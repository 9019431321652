import { Icon } from "@chakra-ui/react";

const AdminCompaniesIcon = (props) => {
    return (
        <Icon viewBox="0 0 105 100" {...props}>
            <path
                fill="#2E54B8"
                d="M91.317 16.917H74.766v-9.55C74.766 3.32 71.465 0 67.389 0H37.611c-4.053 0-7.377 3.297-7.377 7.367v9.55H13.683C6.124 16.917 0 23.056 0 30.582v55.753C0 93.884 6.147 100 13.683 100h77.634C98.876 100 105 93.86 105 86.335V30.582c0-7.549-6.147-13.665-13.683-13.665Zm-56.051-9.55a2.364 2.364 0 0 1 2.367-2.365h29.78a2.364 2.364 0 0 1 2.367 2.365v9.55H35.288v-9.55h-.022Zm-21.56 14.552H91.34c4.781 0 8.674 3.888 8.674 8.663V52.66H66.98c-.092-3.592-3.051-6.503-6.671-6.503H44.737c-3.62 0-6.58 2.91-6.671 6.503H5.009V30.582c0-4.775 3.893-8.663 8.674-8.663h.023ZM61.97 52.865v4.616a1.68 1.68 0 0 1-1.685 1.682H44.714a1.68 1.68 0 0 1-1.685-1.682v-4.616a1.68 1.68 0 0 1 1.685-1.683h15.572c.934 0 1.685.75 1.685 1.683ZM91.317 95.02H13.683c-4.781 0-8.674-3.888-8.674-8.663V57.685h33.034c.114 3.593 3.05 6.48 6.67 6.48h15.573c3.62 0 6.557-2.887 6.67-6.48h33.035v28.672c0 4.775-3.893 8.663-8.674 8.663Z"
            />
        </Icon>
    );
};

export default AdminCompaniesIcon;
