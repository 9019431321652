import CommonModal from "../../CommonModal";
import LinkButton from "../../buttons/LinkButton";
import { BiPlus } from "react-icons/bi";
import SearchInput from "../../table/SearchInput";
import ContractTypeButton from "../../buttons/ContractTypeButton";
import { useEffect, useState } from "react";
import { FormControl, IconButton, Input, InputGroup, Tooltip, useBoolean } from "@chakra-ui/react";
import { IoCheckmarkSharp, IoCloseOutline } from "react-icons/io5";
import useContractState from "../../../../store/contractState";
import { ContractType } from "../../../../models/Contract.models";
import useCustomToast from "../../../../hooks/useCustomToast";
import LoadingBar from "../../LoadingBar";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import capitalize from "lodash/capitalize";

const AddNewTypeForm = ({ onCancel, onSave }: { onCancel: () => any; onSave: (name: string) => any }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [invalidName, setInvalidName] = useBoolean(false);
    const [typeName, setTypeName] = useState("");

    function handleSaveType() {
        if (!typeName?.length) {
            return setInvalidName.on();
        } else {
            setInvalidName.off();
        }
        onSave(typeName);
    }
    return (
        <div className="w-10/12 flex justify-between items-end gap-4 mt-6">
            <FormControl flex={1} isInvalid={invalidName}>
                <label htmlFor="type_name" className="text-sm leading-8">
                    {t("addType")}
                </label>
                <InputGroup borderColor="gray.400">
                    <Input id="type_name" type="text" rounded="none" placeholder="Nombre" onChange={(e) => setTypeName(e.target.value)} />
                </InputGroup>
            </FormControl>
            <Tooltip label="Cancelar" fontSize="10px">
                <IconButton
                    variant="outline"
                    rounded="full"
                    colorScheme="red"
                    aria-label="cancel"
                    icon={<IoCloseOutline size={20} />}
                    onClick={onCancel}
                />
            </Tooltip>
            <Tooltip label="Guardar" fontSize="10px">
                <IconButton
                    variant="solid"
                    rounded="full"
                    colorScheme="brand"
                    aria-label="add"
                    icon={<IoCheckmarkSharp size={20} />}
                    onClick={handleSaveType}
                />
            </Tooltip>
        </div>
    );
};

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const ContractTypesModal = ({ isOpen, onClose }: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const {
        getContractTypes,
        contractTypes: { data: contractTypes, loading },
        createContractType,
    } = useContractState();

    const [showAddNew, setShowAddNew] = useBoolean(false);
    const [savingType, setSavingType] = useBoolean(false);

    const [filteredTypes, setFilteredTypes] = useState<ContractType[]>(null);

    function handleSearch(query: string) {
        const lowerQuery = query?.toLocaleLowerCase();
        if (query.length) {
            setFilteredTypes(contractTypes.filter((ct) => ct.description?.toLocaleLowerCase().includes(lowerQuery)));
        } else {
            setFilteredTypes(null);
        }
    }

    async function onSaveNewType(name: string) {
        if (contractTypes.find((ct) => ct.description === name)) {
            return toast.error(t("typeAlreadyExists"));
        }

        setSavingType.on();
        const typeSaved = await createContractType(name, false);
        setSavingType.off();

        if (typeSaved) {
            setShowAddNew.off();
            toast.success(t("contractTypeCreated"));
        } else {
            toast.error(t("creatingContractTypeError"));
        }
    }

    useEffect(() => {
        getContractTypes(false);
    }, []);

    return (
        <CommonModal size="4xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col min-h-[60vh]">
                <div className="w-full mb-4">
                    <h2 className="title-3">{t("contractTypes")}</h2>
                    <h3 className="text-dark">{t("manageContractTypes")}</h3>
                </div>
                <LoadingBar isLoading={loading || savingType} />

                <div className="w-full flex items-center justify-between py-3 border-b">
                    <SearchInput disabled={showAddNew} onChange={handleSearch} />
                    <LinkButton leftIcon={<BiPlus size={18} />} onClick={setShowAddNew.on}>
                        {t("addNewType")}
                    </LinkButton>
                </div>

                <div className="w-full flex flex-col items-center">
                    {showAddNew ? (
                        <AddNewTypeForm onCancel={setShowAddNew.off} onSave={onSaveNewType} />
                    ) : (
                        //types list
                        <div className="w-10/12 flex flex-wrap justify-center gap-6 mt-8 py-16 max-h-[450px] overflow-y-scroll overflow-x-hidden">
                            {(filteredTypes ?? contractTypes)?.map((ct) => (
                                <ContractTypeButton
                                    key={ct.id}
                                    contractType={ct}
                                    selected={false}
                                    onSelect={(_, label) => {}}
                                    label={capitalize(ct.description)}
                                />
                            ))}
                        </div>
                    )}
                </div>
                <div className="grow"></div>
            </div>
        </CommonModal>
    );
};

export default ContractTypesModal;
