import { Button } from "@chakra-ui/button";
import { BsFillCheckCircleFill, BsExclamationCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { ConfirmAlertModalData } from "../../../models/Modals.models";
import CommonModal from "../CommonModal";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data: ConfirmAlertModalData;
};

const ConfirmAlertModal = ({ isOpen, onClose, data }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];

    function onConfirm() {
        data?.onConfirm();
        onClose();
    }

    function getIconBySeverity() {
        switch (data?.severity) {
            case "success":
                return (
                    <div className="flex items-center text-green-400">
                        <BsFillCheckCircleFill size={28} color="inherit" />
                    </div>
                );
            case "warning":
                return (
                    <div className="flex items-center text-red-400">
                        <BsExclamationCircleFill size={28} color="inherit" />
                    </div>
                );
            case "info":
                return (
                    <div className="flex items-center text-brand-500">
                        <BsExclamationCircleFill size={28} color="inherit" />
                    </div>
                );
            default:
                return (
                    <div className="flex items-center text-gray-300" style={{ height: "max-content" }}>
                        <BsExclamationCircleFill size={28} color="inherit" />
                    </div>
                );
        }
    }

    return (
        <CommonModal size="lg" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col ">
                <p className="text-2xl font-bold text-dark-2 flex items-center gap-2">
                    {getIconBySeverity()} <span>{data?.title}</span>
                </p>
                <p className="text-lg text-gray-600 mt-4 pl-9">{data?.msg}</p>

                <div className="mt-8 flex justify-end gap-4">
                    <Button
                        rounded="full"
                        size="md"
                        fontWeight="medium"
                        variant="outline"
                        px={"2rem"}
                        isLoading={false}
                        onClick={onClose}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        rounded="full"
                        size="md"
                        fontWeight="medium"
                        px={"2rem"}
                        isLoading={false}
                        onClick={onConfirm}
                    >
                        {t("accept")}
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default ConfirmAlertModal;
