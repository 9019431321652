import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import debounce from "lodash/debounce";
import { useCallback } from "react";
import { BiSearch } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
//BiFilter
const SearchInput = ({ onChangeInput, disabled = false, valueInput }: { onChangeInput?: any ; disabled?: boolean , valueInput?: string}) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];

    return (
        <div>
            <InputGroup>
                <InputLeftElement h={8} color="gray.400" pointerEvents="none" children={<BiSearch color="inherit" size={20} />} />
                <Input type="text" disabled={disabled} value={valueInput} size={"sm"} rounded="full" placeholder={t("search")} onChange={onChangeInput} bg="white" />
            </InputGroup>
        </div>
    );
};

export default SearchInput;
