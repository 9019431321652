import { Button } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import DottedSubtitle from "../common/DottedSubtitle";

const BPMN_URL = "https://bpmscmqa.axeleratum.com/app/welcome/default/#/welcome";
let redirectTimeout = null;

const BpmnAdmin = () => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    function handleRedirect() {
        window.open(BPMN_URL, "_blank");
    }

    useEffect(() => {
        redirectTimeout = setTimeout(() => {
            handleRedirect();
        }, 3000);

        return () => {
            clearTimeout(redirectTimeout);
        };
    }, []);

    return (
        <>
            <div className="mt-4 mb-4">
                <DottedSubtitle text={"BPMN"} />
            </div>

            <div className="w-full">
                <div className="flex justify-center items-center mt-24">
                    <p className="text-lg text-center max-w-[400px]">
                        {t("youWillBeRedirectedBPM")}
                        <Button variant="link" colorScheme={"brand"} onClick={handleRedirect}>
                            {t("here")}
                        </Button>
                    </p>
                </div>
            </div>
        </>
    );
};

export default BpmnAdmin;
