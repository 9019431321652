import { useDimensions } from "@chakra-ui/react";
import { useRef } from "react";
import Topbar from "./Topbar";

type Props = {
    children?: JSX.Element;
    noScroll?: boolean;
};

const MainLayout = ({ children, noScroll }: Props) => {
    const topbarRef = useRef();
    const dimensions = useDimensions(topbarRef);
    const topbarHeight = dimensions?.borderBox.height || 68; //current topbar height

    return (
        <div className="w-full h-[99.9vh] bg-gray-light px-8 pb-8 relative">
            <Topbar elRef={topbarRef} sidebarWidth={0} />
            {/* <Sidebar width={sidebarWidth} /> */}
            <div
                className={`bg-white rounded-2xl mt-4 ${noScroll ? "" : "overflow-y-auto"}`}
                style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: `calc(100% - ${/*sidebarWidth*/ 0}rem)`,
                    maxHeight: `calc(100% - ${topbarHeight}px)`,
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default MainLayout;
