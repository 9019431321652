import { Badge, Menu, MenuButton, MenuItem, MenuList, Portal } from "@chakra-ui/react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaEye } from "react-icons/fa";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { formatDate, getContractStatusColor } from "src/lib/utils";
import { BiddingRequirement, BiddingRequirementDocument } from "src/models/biddings/Bidding.models";

import LinkButton from "../common/buttons/LinkButton";
import SlicedText from "../common/SlicedText";
import { useNavigate, useParams } from "react-router";
import usePdfSignaturesState from "src/store/pdfSignatureState";
import { useEffect } from "react";
import WorkSheet from "src/pages/worksheets/WorkSheet";

const BiddingDocumentCard = ({
    doc,
    onOpenDoc,
    onDeleteDoc,
    t,
    isSignaturePdf,
    isReviewFlowType,
    requirement
}: {
    doc: BiddingRequirementDocument;
    onOpenDoc: (doc: BiddingRequirementDocument) => void;
    onDeleteDoc: (doc: BiddingRequirementDocument, isContract: boolean) => void;
    t?: any;
    isSignaturePdf: boolean;
    isReviewFlowType: boolean;
    requirement: BiddingRequirement;
}) => {
    const navigate = useNavigate();
    const isContract = Boolean(doc.contract);
    const isPDF = Boolean(isSignaturePdf);
    const isWorkSheet = Boolean(doc?.documentTypeId === "65e5f03e9d2608971ee4a48a");
    const isRevieFlow = Boolean(isReviewFlowType);
    const { id, biddingId } = useParams();
    const { setInfoContract, setStatusContract, setIsSignature, setReviewStatusDocument, setCurrentRequiriment } = usePdfSignaturesState();

    console.log("reeq", requirement);

    function handleShowDoc() {
        setInfoContract(doc);
        console.log("doc", doc);
        setCurrentRequiriment(requirement);

        setStatusContract(doc.signStatus);

        if (isRevieFlow) {
            //   setReviewStatusDocument(doc.reviewStatus);

        }

        console.log("docSign", doc.signStatus);
        console.log("isRevieFlow", isRevieFlow);

        switch (true) {

            case isContract:
                navigate(`/contracts/${doc.contract.id}`);
                break;
            case isWorkSheet:
                navigate(`/worksheets/${doc?.fileId}/bidding/${id}/uuid/${doc.dinamicTableId}`);
                break;
            case isRevieFlow:
                navigate(`/documents/${doc?.fileId}/bidding/${id}/uuid/${doc.uuid}`);
                break;
            case isPDF:
                navigate(`/pdfs/${doc?.fileId}/bidding/${id}/uuid/${doc.uuid}`);
                break;
            default:
                onOpenDoc(doc);
        }
    }
    
    useEffect(() => {
        setInfoContract(null);
        setStatusContract(null);
        //    setReviewStatusDocument(null);
        setCurrentRequiriment(null);
        setIsSignature(false);
    }, []);

    function handleDeleteDoc() {
        onDeleteDoc(doc, isContract);
    }
    return (
        <div className="w-full border-2 border-dashed border-gray-400 px-4 pt-2 pb-4 flex items-center justify-between relative">
            <div className="space-y-1 text-gray-600 w:9/12 lg:w-10/12">
                <h5 className="text-sm font-semibold leading-tight">
                    <SlicedText text={doc.name} maxLength={36} />
                </h5>
                <p className="text-xs">
                    Descripción: <b>{<SlicedText text={doc.description} maxLength={22} />}</b>
                </p>
                <p className="text-xs">
                    Agregado el: <b>{formatDate(doc.createdAt)}</b>
                </p>
            </div>
            <div className="absolute bottom-1 right-2">
                {isContract && (
                    <div>
                        <Badge colorScheme={getContractStatusColor(doc.contract.status)} fontSize="10px">
                            {t(doc.contract.status)}
                        </Badge>
                    </div>
                )}
            </div>
            <div className="absolute right-[4px] top-0">
                <ButtonMenu
                    isContract={isContract}
                    onShow={handleShowDoc}
                    onDelete={handleDeleteDoc}
                    isPDF={isSignaturePdf}
                    isWorkSheet={isWorkSheet}
                    isReviewFlowType={isReviewFlowType}
                />
            </div>
        </div>
    );
};

export default BiddingDocumentCard;

const ButtonMenu = ({
    isContract,
    onShow,
    onDelete,
    isPDF,
    isWorkSheet,
    isReviewFlowType
}: {
    isContract: boolean;
    onShow: () => void;
    onDelete: () => void;
    isPDF: boolean;
    isWorkSheet: boolean;
    isReviewFlowType: boolean;
}) => {
    return (
        <Menu closeOnSelect={true} placement="bottom-start">
            <MenuButton style={{ all: "unset", display: "block", cursor: "pointer" }}>
                <button className="text-gray-600">
                    <BiDotsHorizontalRounded size={28} />
                </button>
            </MenuButton>
            <Portal>
                <MenuList shadow={"md"} zIndex="modal" style={{ minWidth: "unset" }}>
                    <div className="w-[150px] px-2 max-h-[300px] overflow-y-auto relative">
                        {/*isContract ? (
                            <MenuItem style={{ width: "100%", padding: "5px", fontSize: "14px", height: "unset" }} onClick={onShow}>
                                {"Ver contrato"}
                            </MenuItem>
                        ) : (
                            ""
                        )*/}
                        {isWorkSheet ? (
                            <MenuItem style={{ width: "100%", padding: "5px", fontSize: "14px", height: "unset" }} onClick={onShow}>
                                {"Ver tabla dinámica"}
                            </MenuItem>
                        ) : (
                            ""
                        )}
                        {isReviewFlowType && isPDF ? (
                            <MenuItem style={{ width: "100%", padding: "5px", fontSize: "14px", height: "unset" }} onClick={onShow}>
                                {"Ver flujo de Revisión y Firma"}
                            </MenuItem>
                        ) : (
                            <>
                                {!isPDF && !isWorkSheet && !isReviewFlowType && (
                                    <MenuItem style={{ width: "100%", padding: "5px", fontSize: "14px", height: "unset" }} onClick={onShow}>
                                        {"Ver documento"}
                                    </MenuItem>
                                )}
                                {isPDF && (
                                    <MenuItem style={{ width: "100%", padding: "5px", fontSize: "14px", height: "unset" }} onClick={onShow}>
                                        {"Ver flujo de firma"}
                                    </MenuItem>
                                )}
                                {isReviewFlowType && (
                                    <MenuItem style={{ width: "100%", padding: "5px", fontSize: "14px", height: "unset" }} onClick={onShow}>
                                        {"Ver flujo de revisión"}
                                    </MenuItem>
                                )}

                            </>
                        )}

                        <MenuItem
                            style={{ width: "100%", padding: "5px", fontSize: "14px", height: "unset", color: "red" }}
                            onClick={onDelete}
                        >
                            {isContract ? "Desasociar" : "Eliminar"}
                        </MenuItem>
                    </div>
                </MenuList>
            </Portal>
        </Menu>
    );
};
