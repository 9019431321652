import axios from "axios";
import { getAuthHeader } from "../lib/utils";

export const meetAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/video/`,
    timeout: 10000,
});

const createSession = async () => {
    try {
        return await meetAxiosRequest.post(
            `session/default`,
            {},
            {
                headers: getHeaders(),
            }
        );
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    createSession,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
