import { Icon } from "@chakra-ui/react";

const NewProcessIcon = (props) => {
    return (
        <Icon viewBox="0 0 105 97" {...props}>
            <path
                fill="#2E54B8"
                d="M24.806 15.254h9.974a1.67 1.67 0 0 0 1.662-1.67 1.67 1.67 0 0 0-1.662-1.668h-9.974a1.67 1.67 0 0 0-1.663 1.669 1.67 1.67 0 0 0 1.663 1.669ZM24.806 27.185h16.623a1.67 1.67 0 0 0 1.663-1.668 1.67 1.67 0 0 0-1.663-1.67H24.806a1.67 1.67 0 0 0-1.663 1.67 1.67 1.67 0 0 0 1.663 1.668ZM41.43 47.68H24.805a1.67 1.67 0 0 0-1.663 1.669 1.67 1.67 0 0 0 1.663 1.668h16.623a1.67 1.67 0 0 0 1.663-1.668 1.67 1.67 0 0 0-1.663-1.67ZM41.43 59.596H24.805a1.67 1.67 0 0 0-1.663 1.669 1.67 1.67 0 0 0 1.663 1.668h16.623a1.67 1.67 0 0 0 1.663-1.668 1.67 1.67 0 0 0-1.663-1.67ZM41.43 71.527H24.805a1.67 1.67 0 0 0-1.663 1.67 1.67 1.67 0 0 0 1.663 1.668h16.623a1.67 1.67 0 0 0 1.663-1.669 1.67 1.67 0 0 0-1.663-1.669ZM24.806 39.101h29.923a1.67 1.67 0 0 0 1.662-1.669 1.67 1.67 0 0 0-1.662-1.668H24.806a1.67 1.67 0 0 0-1.663 1.668 1.67 1.67 0 0 0 1.663 1.67Z"
            />
            <path
                fill="#2E54B8"
                d="m103.187 61.765-7.896-1.219 4.721-6.475a1.68 1.68 0 0 0-.166-2.17l-7.049-7.075a1.663 1.663 0 0 0-2.161-.167l-6.45 4.74-1.214-7.928a1.678 1.678 0 0 0-1.645-1.418h-3.325V18.358c0-.218-.05-.434-.133-.635-.083-.217-.2-.4-.35-.55L60.897.484a1.551 1.551 0 0 0-.549-.35A1.65 1.65 0 0 0 59.716 0H19.818c-2.742 0-4.987 2.253-4.987 5.007v76.767c0 2.754 2.245 5.007 4.987 5.007h34.844l5.22 5.24c.582.584 1.496.65 2.161.167l6.45-4.74 1.214 7.928a1.678 1.678 0 0 0 1.645 1.418h9.975c.814 0 1.512-.6 1.645-1.418l1.214-7.928 6.45 4.74c.665.484 1.58.417 2.161-.167l7.049-7.076a1.68 1.68 0 0 0 .166-2.17L95.291 76.3l7.896-1.218a1.68 1.68 0 0 0 1.413-1.652V63.417a1.68 1.68 0 0 0-1.413-1.652ZM61.378 5.69l10.955 10.998H61.378V5.69Zm-8.71 77.085c-.15.2-.234.434-.283.667H19.818a1.67 1.67 0 0 1-1.662-1.669V5.007a1.67 1.67 0 0 1 1.662-1.67h38.235v15.02a1.67 1.67 0 0 0 1.663 1.67h14.961v20.026h-3.325c-.814 0-1.513.6-1.645 1.418l-1.214 7.927-6.45-4.74a1.663 1.663 0 0 0-2.161.168l-7.049 7.076a1.679 1.679 0 0 0-.166 2.17l4.721 6.474-7.896 1.219a1.68 1.68 0 0 0-1.413 1.652V73.43a1.68 1.68 0 0 0 1.413 1.652l7.896 1.218-4.72 6.476Zm48.607-10.781-9.21 1.418c-.565.1-1.063.484-1.28 1.018a1.665 1.665 0 0 0 .2 1.62l5.502 7.542-5.036 5.057-7.514-5.524a1.648 1.648 0 0 0-1.613-.2 1.71 1.71 0 0 0-1.014 1.285l-1.413 9.245h-7.115l-1.014-6.675-.4-2.57a1.621 1.621 0 0 0-.348-.768 1.57 1.57 0 0 0-.665-.517 1.648 1.648 0 0 0-1.613.2l-.432.317-4.538 3.338-2.544 1.869-1.861-1.87-3.176-3.187.117-.15 5.386-7.393c.349-.467.415-1.085.2-1.619a1.705 1.705 0 0 0-1.28-1.018l-9.21-1.418v-7.143l9.21-1.419a1.706 1.706 0 0 0 1.28-1.017 1.665 1.665 0 0 0-.2-1.62l-5.503-7.543 5.037-5.056 7.514 5.524c.466.35 1.08.417 1.613.2a1.71 1.71 0 0 0 1.014-1.285l1.413-9.246h7.115l1.413 9.246a1.71 1.71 0 0 0 1.014 1.285c.532.217 1.147.15 1.612-.2l7.514-5.524 5.037 5.056-5.502 7.544a1.665 1.665 0 0 0-.2 1.619c.217.534.715.917 1.28 1.018l9.21 1.418v7.143Z"
            />
            <path
                fill="#2E54B8"
                d="M78.002 58.56a9.054 9.054 0 0 0-1.662-.15c-.566 0-1.13.05-1.663.15-4.721.785-8.312 4.907-8.312 9.863 0 4.957 3.591 9.079 8.312 9.863.532.1 1.097.15 1.662.15.566 0 1.13-.05 1.663-.15 4.721-.784 8.312-4.906 8.312-9.863 0-4.956-3.59-9.078-8.312-9.863Zm0 16.322c-.532.15-1.08.217-1.662.217-.582 0-1.13-.067-1.663-.217a6.657 6.657 0 0 1-4.987-6.459 6.657 6.657 0 0 1 4.987-6.458 6.058 6.058 0 0 1 1.662-.217c.582 0 1.13.067 1.663.217a6.657 6.657 0 0 1 4.987 6.458 6.657 6.657 0 0 1-4.987 6.459ZM16.45 28.102C7.371 28.102 0 35.474 0 44.55 0 53.628 7.372 61 16.45 61c9.076 0 16.449-7.373 16.449-16.45 0-9.077-7.373-16.45-16.45-16.45Zm0 29.907c-7.418 0-13.46-6.041-13.46-13.458s6.042-13.459 13.46-13.459c7.416 0 13.458 6.042 13.458 13.459s-6.042 13.458-13.459 13.458Z"
            />
            <path
                fill="#F4F4F4"
                d="M16.45 31.092c-7.418 0-13.459 6.041-13.459 13.458 0 7.418 6.041 13.459 13.459 13.459 7.417 0 13.458-6.041 13.458-13.459 0-7.417-6.041-13.458-13.459-13.458Zm7.028 14.894h-5.593v5.593c0 .747-.643 1.346-1.436 1.346-.792 0-1.435-.599-1.435-1.346v-5.593H9.42c-.748 0-1.346-.643-1.346-1.436 0-.792.598-1.435 1.346-1.435h5.593v-5.593c0-.748.643-1.346 1.436-1.346.792 0 1.435.598 1.435 1.346v5.593h5.593c.747 0 1.346.643 1.346 1.435 0 .793-.599 1.436-1.346 1.436Z"
            />
            <path
                fill="#2E54B8"
                d="M24.823 44.55c0 .793-.598 1.436-1.346 1.436h-5.592v5.592c0 .748-.643 1.346-1.436 1.346-.792 0-1.435-.598-1.435-1.346v-5.593H9.42c-.748 0-1.346-.642-1.346-1.435s.598-1.436 1.346-1.436h5.593v-5.592c0-.748.643-1.346 1.435-1.346.793 0 1.436.598 1.436 1.346v5.592h5.592c.748 0 1.346.643 1.346 1.436Z"
            />
        </Icon>
    );
};

export default NewProcessIcon;
