import "handsontable/dist/handsontable.full.min.css";
import "./WorkSheet.css";
import Handsontable from "handsontable/base";
import { registerAllModules } from "handsontable/registry";
import { HotTable } from "@handsontable/react";
import { registerRenderer, textRenderer } from "handsontable/renderers";
import { useEffect, useState, useRef } from "react";
registerAllModules();

const WorkSheet = ({ csvContent, commentsArray, selectedComment, setRange }) => {
    const hotRef = useRef(null);
    const [output, setOutput] = useState([]);
    const [selectedRow, setSelectedRow] = useState<any>();

    useEffect(() => {
        const hot = hotRef.current.hotInstance;

        const handleSelection = (event) => {
            const selectedRange = hot.getSelectedRangeLast();

            if (selectedRange) {
                const selectedRows = hot.getSelected();

                const separateRows = selectedRows.filter((row) => {
                    return (
                        row[0] < selectedRange.from.row ||
                        row[0] > selectedRange.to.row ||
                        row[0] < selectedRange.from.row ||
                        row[0] > selectedRange.to.row
                    );
                });

                const selections = {
                    range: selectedRange,
                    separateRows: separateRows,
                };

                setRange(selections);
            }
        };

        hot.addHook("afterSelectionEnd", handleSelection);

        return () => {
            hot.removeHook("afterSelectionEnd", handleSelection);
        };
    }, []);

    const handleSetRowColor = (priority) => {
        if (priority === "high") {
            return "#FF4D4D";
        }
        if (priority === "medium") {
            return "#fd8c00";
        }
        if (priority === "low") {
            return "#fdc500";
        }
    };

    const jsonObject = JSON.parse(csvContent);
    // console.log("obj", jsonObject);
    const cellRangeList = jsonObject?.workbookAx?.sheetAxList[0]?.cellRangeList;
    const matrizCellAxList = jsonObject?.workbookAx?.sheetAxList;
    const matrizStyleList = jsonObject?.workbookAx?.styleList;

    const mergedCells = cellRangeList?.map((range) => {
        const [rowStart, colStart, rowEnd, colEnd] = range.split("|")?.map(Number);
        const rowspan = rowEnd - rowStart + 1;
        const colspan = colEnd - colStart + 1;
        return { row: rowStart, col: colStart, rowspan, colspan };
    });

    const matriz = matrizCellAxList?.flatMap((sheet) =>
        sheet.rowAxList?.map((row) => {
            const cellList = row.cellAxList.slice();
            console.log("cellList",cellList)
            while (cellList.length < 12) {
                cellList.push("");
            }
            return cellList;
        })
    );

    const applyCellStyle = (TD, row, col) => {
        const rowStyles = matrizCellAxList[0]?.rowAxList[row]?.cssList;

        let styleIndex = -1;

        for (let i = 0; i < rowStyles?.length; i++) {
            if (rowStyles[i]?.fi > col) {
                break;
            }
            styleIndex = rowStyles[i]?.si;
        }

        const styleString = matrizStyleList[styleIndex] || "";
        const styleParts = styleString ? styleString?.split("|") : "";

        if (styleParts && styleParts.length >= 4) {
            const fontSize = parseInt(styleParts[0]);
            const bold = styleParts[1] && styleParts[1][0] === "1" ? "bold" : "normal";
            const verticalAlignment = ["top", "center", "bottom", "justify"][parseInt(styleParts[1] && styleParts[1][2]) || 0];
            const alignment = ["general", "left", "center", "right", "fill", "justify", "selection"][
                parseInt(styleParts[1] && styleParts[1][1]) || 0
            ];
            const backgroundColor = "#" + (styleParts[2] || "FFFFFF");
            const foregroundColor = "#" + (styleParts[3] || "000000");

            TD.style.fontSize = fontSize + "px";
            TD.style.fontWeight = bold;
            TD.style.verticalAlign = verticalAlignment;
            TD.style.textAlign = alignment;
            TD.style.color = foregroundColor;
            TD.style.backgroundColor = backgroundColor;
        }
    };

 console.log(matriz);

    return (
        <HotTable
            ref={hotRef}
            data={matriz}
            width="100%"
            height="92%"
            colWidths={100}
            rowHeights={23}
            rowHeaders={true}
            colHeaders={true}
            outsideClickDeselects={false}
            selectionMode="multiple"
            autoWrapRow={true}
            autoWrapCol={true}
        
            licenseKey="non-commercial-and-evaluation"
            // fixedColumnsStart={3}
            mergeCells={mergedCells}
            afterRenderer={(TD, row, col, prop, value, cellProperties) => {
                applyCellStyle(TD, row, col);

                const applySelectedCommentStyle = (comment) => {
                    const rowArray = comment?.row.split(",");
                    rowArray?.forEach((commentRow) => {
                        const rowNumber = parseInt(commentRow.trim(), 10); // Convertir a número y eliminar espacios
                        if (rowNumber === row + 1) {
                            TD.style.background = handleSetRowColor(comment?.priority);
                        }
                    });
                };
                if (selectedComment.row !== "") {
                    applySelectedCommentStyle(selectedComment);
                }

                commentsArray.forEach((comment) => {
                    applySelectedCommentStyle(comment);
                });
            }}
        />
    );
};

export default WorkSheet;
