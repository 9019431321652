import { Accordion, Button, FormControl, Input, InputGroup, Select, useBoolean } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { BiChevronLeft, BiPlus } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import AccordionItemBrand from "../../components/common/AccordionItemBrand";
import LinkButton from "../../components/common/buttons/LinkButton";
import MainLayout from "../../components/layouts/MainLayout";
import Steps from "../../components/common/Steps";
import DataGroupList from "../../components/templates/DataGroupList";
import TemplatesEditorPanel from "../../components/templates/TemplatesEditorPanel";
import useCustomToast from "../../hooks/useCustomToast";
import { trigger } from "../../lib/customEvents";
import { Template } from "../../models/Template.models";
import useContractState from "../../store/contractState";
import useModalState from "../../store/modalsState";
import useTemplateState from "../../store/templatesState";
import { BsCheckCircleFill } from "react-icons/bs";
import { formatDate, getTemplateStepByStatus, isObjectId } from "../../lib/utils";
import usePdfPreview from "../../hooks/usePdfPreview";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import TemplateHeaderFooterAccordionItem from "../../components/templates/TemplateHeaderFooterAccordionItem";
import useAuthState from "src/store/authState";

const INSERT_EVENT_NAME = "insertDataItemOnSelectedTemplate";

const ShowEditTemplatePage = () => {
    const navigate = useNavigate();
    const toast = useCustomToast();
    const { id } = useParams();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const { setContractTypesModal, setDataGroupSelectModal, setLoaderModal, setPdfPreviewModal, setContractBulkLoadModal } =
        useModalState();
    //const { getContractTypes, contractTypes } = useContractState();
    const getContractTypes = useContractState((state) => state.getContractTypes);
    const contractTypes = useContractState((state) => state.contractTypes);

    const { selectedTemplate, setSelectedTemplate, updateTemplate, getTemplate } = useTemplateState();
    const { generatePdfPreviewUrl } = usePdfPreview();
    const {setHasSavedTemplateDraft,hasSavedTemplateDraft} = useTemplateState();

    const [currentText, setCurrentText] = useState({ changed: false, text: selectedTemplate?.text });
    const [invalidName, setInvalidName] = useBoolean(false);
    const [invalidType, setInvalidType] = useBoolean(false);
    const [templateName, setTemplateName] = useState(selectedTemplate?.name);
    const [currentStep, setCurrentStep] = useState(selectedTemplate?.maxLifeStage);
    const tokenExpiresIn = useAuthState((s) => s.tokenExpiresIn);

    const isDraft = useMemo(() => selectedTemplate?.status === "borrador", [selectedTemplate?.status]);
    const isActivated = useMemo(() => selectedTemplate?.status === "activo", [selectedTemplate?.status]);
    const [stepsLifes]= useState(
        [{
            "name": "Borrador",
            "value": "borrador"
        },
        {
            "name": "Aprobado",
            "value": "aprobado"
        },{
            "name": "Boveda",
            "value": "en_boveda"
        },{
            "name": "Firmado",
            "value": "firmado"
        },
        {
            "name": "Tokenizado ",
            "value": "tokenizado "
        }]
        );

    function handleNameChange(e: any) {
        invalidName && setInvalidName.off();
        setTemplateName(e.target.value);
    }

    function handleTypeChange(e: any) {
        const type = e.target.value;
        setSelectedTemplate({ ...selectedTemplate, type });
    }

    function setText(text: string) {
        setCurrentText({ changed: true, text });
    }

    function handleStepChange(e: any) {
        const step = e.target.value;
        setCurrentStep(step);
    }

    function validateTemplate() {
        if (!templateName && !selectedTemplate?.name) {
            setInvalidName.on();
            toast.error(t("nameRequired"), t("warning"));
            return false;
        } else {
            setInvalidName.off();
        }

        if (!selectedTemplate.type) {
            setInvalidType.on();
            toast.error(t("contractTypeRequired"), t("warning"));
            return false;
        } else {
            setInvalidType.off();
        }

        return true;
    }
    async function saveTemplateByStatus(status: "activo" | "borrador") {
        if (!validateTemplate()) return;
        const newName = templateName ?? selectedTemplate?.name;
        const newStepLife = currentStep ?? selectedTemplate?.maxLifeStage;

        const elementTypeF = "footer";
        const elementTypeH = "header"

               
        const updatedTemplateObject: Template = {
            ...selectedTemplate,
            name: newName,
            status,
            maxLifeStage: newStepLife
        };
        delete (updatedTemplateObject as any)[elementTypeF];
        delete (updatedTemplateObject as any)[elementTypeH];

        const updated = await updateTemplate({
            ...updatedTemplateObject
          } as Template);

          // Eliminar valores del body evitar problemas en la request too Large


          
        if (updated) {
            toast.success(t("templateUpdated"));
            navigate("../");
        } else {
            toast.error(t("templateSavedFailed"));
        }
    }

    async function onInit() {
        if (isObjectId(id)) {
            const validTemplate = await getTemplate(id);
            if (validTemplate) return;
        }

        toast.error("invalid ID");
        navigate("../");
    }


    function buildImgElement(base64String) {
        // Construir la cadena con la estructura del elemento img para renderizar el pdf
        if (!base64String) {
            console.error("La cadena Base64 proporcionada está vacía o nula.");
            return ""; // O cualquier otro valor de retorno que desees
        }
        console.log("base64String",base64String)
        const base64Format = base64String.replace(/"/g, '');
        const imgUrl = `data:image/png;base64,${base64Format}`;
        return imgUrl;
    }

    async function handlePreview() {
        setLoaderModal(true);
        const pdfBlobUrl = await generatePdfPreviewUrl(selectedTemplate?.text, buildImgElement(selectedTemplate.header), buildImgElement(selectedTemplate.footer));
        setLoaderModal(false);
        setPdfPreviewModal({ show: true, data: { pdfBlobUrl } });
    }

    function handleNewContract() {
        navigate("/contracts/create" + (selectedTemplate?.id ? `?template=${selectedTemplate.id}` : ""));
    }

    useEffect(() => {
        onInit();
        return () => setSelectedTemplate(null);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isDraft) {
            getContractTypes(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDraft]);

    useEffect(() => {
        if (contractTypes.error) {
            toast.error(t("fetchingContractTypesError"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractTypes.error]);

    //uso side effect para actualizar texto para evitar comportamiento no deseado
    useEffect(() => {
        if (currentText.changed) {
            setSelectedTemplate({ ...selectedTemplate, text: currentText.text });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentText]);

    useEffect(() => {
        const tokenCurrentTime = tokenExpiresIn / 60;
        console.log("time show edit template exp token",tokenCurrentTime)

        if (tokenCurrentTime < 1.5 && !hasSavedTemplateDraft) {

            saveTemplateByStatus("borrador");
            setHasSavedTemplateDraft(true);
            toast.info("La plantilla se ha guardado automáticamente antes de que su sesión expirara.","¡Atención!");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenExpiresIn]);

    return (
        <MainLayout>
            <main className="w-full p-3 flex h-full">
                {/* left panel */}
                <section className="w-3/12 flex flex-col pr-4 pl-2 border-r h-full max-h-full overflow-y-auto relative">
                    {isDraft && <h2 className="title-3">{t("editTemplate")}</h2>}
                    {isActivated && (
                        <div className="flex items-center gap-2">
                            <BsCheckCircleFill size={28} color="#82C43C" />
                            <h2 className="title-3 leading-none">{t("activatedTemplate")}</h2>
                        </div>
                    )}
                    {selectedTemplate?.status === "activo" ? (
                        <p className="mt-2 text-xs text-justify">{t("activatedTemplateText")}</p>
                    ) : (
                        <p className="mt-2 text-xs text-justify">{t("editTemplateText")}</p>
                    )}

                    {isDraft && (
                        <div className="mt-6 pb-12">
                            <Accordion defaultIndex={[0]} allowMultiple>
                                {/* information */}
                                <AccordionItemBrand title={t("information")}>
                                    <div className="pb-2">
                                        <FormControl isInvalid={invalidName}>
                                            <label htmlFor="template_name" className="text-sm leading-8">
                                                {t("name")}
                                            </label>
                                            <InputGroup borderColor="gray.400">
                                                <Input
                                                    id="template_name"
                                                    type="text"
                                                    rounded="none"
                                                    onChange={handleNameChange}
                                                    defaultValue={selectedTemplate?.name}
                                                    readOnly={isActivated}
                                                />
                                            </InputGroup>
                                        </FormControl>

                                        <FormControl className="mt-4" isInvalid={invalidType}>
                                            <label htmlFor="contract_type" className="text-sm leading-8">
                                                {t("contractType")}
                                            </label>
                                            <Select
                                                defaultValue={selectedTemplate?.type ?? ""}
                                                id="contract_type"
                                                size="md"
                                                rounded="none"
                                                onChange={handleTypeChange}
                                                disabled={isActivated}
                                                _disabled={{
                                                    color: "gray.800",
                                                    opacity: 1,
                                                }}
                                            >
                                                <option value={""}>{t("select")}</option>
                                                {contractTypes?.data?.map((type) => (
                                                    <option key={type.id} value={type.description}>
                                                        {type.description}
                                                    </option>
                                                ))}
                                            </Select>
                                            {isDraft && (
                                                <LinkButton
                                                    fontSize="xs"
                                                    leftIcon={<BiPlus size={18} />}
                                                    className="mt-2"
                                                    onClick={() => setContractTypesModal({ show: true })}
                                                >
                                                    {t("addNew")}
                                                </LinkButton>
                                            )}
                                            
                                        </FormControl>
                                        <FormControl className="mt-4" isInvalid={invalidType}>
                                        <label htmlFor="contract_type" className="text-sm leading-8">
                                            {"Etapa del documento"}
                                        </label>
                                        <Select                                                 
                                            defaultValue={selectedTemplate?.maxLifeStage ?? ""}
                                             id="contract_step" size="md" rounded="none" onChange={handleStepChange}>
                                            <option value={currentStep}>{t("select")}</option>
                                            {stepsLifes?.map((step) => (
                                                <option key={step.value} value={step.value}>
                                                    {step.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    </div>
                                </AccordionItemBrand>
                                {/* data groups */}
                                <AccordionItemBrand title={t("dataGroups")}>
                                    <div>
                                        {isDraft && (
                                            <div>
                                                <LinkButton
                                                    leftIcon={<BiPlus size={18} />}
                                                    onClick={() =>
                                                        setDataGroupSelectModal({
                                                            show: true,
                                                            data: { case: "edit", type: selectedTemplate?.type },
                                                        })
                                                    }
                                                >
                                                    {t("selectDataGroup")}
                                                </LinkButton>
                                            </div>
                                        )}

                                        <div>
                                            {!selectedTemplate?.dataGroup?.length && (
                                                <div className="text-sm px-1 mt-4">{t("noDataGroupSelected")}</div>
                                            )}
                                            {selectedTemplate?.dataGroup?.map((dg) => (
                                                <DataGroupList
                                                    key={dg.id}
                                                    dg={dg}
                                                    onClick={(name, idName) =>
                                                        trigger<{ name: string; idName: string }>(INSERT_EVENT_NAME, { name, idName })
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </AccordionItemBrand>
                          
                                 {/**header & footer */}
                                {  <TemplateHeaderFooterAccordionItem template={selectedTemplate} /> }
                            </Accordion>
                        </div>
                    )}

                    {isActivated && (
                        <div className="mt-6 pb-12">
                            <Accordion defaultIndex={[0, 1]} allowMultiple>
                                {/* information */}
                                <AccordionItemBrand title={t("information")}>
                                    <div className="py-4">
                                        <ul className="space-y-2">
                                            <li className="text-gray-600">
                                                {t("name")}: <span className="text-dark-2 font-medium">{selectedTemplate?.name}</span>
                                            </li>
                                            <li className="text-gray-600">
                                                {t("type")}: <span className="text-dark-2 font-medium">{selectedTemplate?.type}</span>
                                            </li>
                                            <li className="text-gray-600">
                                                {t("date")}:{" "}
                                                <span className="text-dark-2 font-medium">
                                                    {formatDate(new Date(selectedTemplate?.createdDate || "01-01-2022"))}
                                                </span>
                                            </li>
                                            <li className="text-gray-600">
                                                {t("activationDate")}:{" "}
                                                <span className="text-dark-2 font-medium">
                                                    {formatDate(new Date(selectedTemplate?.lastModifiedDate || "01-01-2022"))}
                                                </span>
                                            </li>
                                            <li className="text-gray-600">
                                                {t("author")}: <span className="text-dark-2 font-medium">{selectedTemplate?.userName}</span>
                                            </li>
                                            <li className="text-gray-600">
                                                {t("maxLifeStage")}: <span className="text-dark-2 font-medium">{selectedTemplate?.maxLifeStage}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </AccordionItemBrand>
                                {/* header & footer */}
                                {<TemplateHeaderFooterAccordionItem template={selectedTemplate} readOnly />}
                            </Accordion>
                        </div>
                    )}

                    <div className="grow"></div>
                    {isDraft ? (
                        <div className="flex flex-col lg:flex-row justify-between gap-2 pt-4 pb-2 sticky bottom-0 bg-white border-t">
                            <Button
                                rounded="full"
                                size="md"
                                fontSize="sm"
                                fontWeight="medium"
                                variant="outline"
                                onClick={() => saveTemplateByStatus("borrador")}
                            >
                                {t("saveDraft")}
                            </Button>
                            <Button
                                rounded="full"
                                size="md"
                                fontWeight="medium"
                                variant="solid"
                                fontSize="sm"
                                onClick={() => saveTemplateByStatus("activo")}
                            >
                                {t("activateTemplate")}
                            </Button>
                        </div>
                    ) : (
                        <div className="flex flex-col lg:flex-row justify-between gap-2 pt-4 pb-2 sticky bottom-0 bg-white border-t">
                            <Button
                                rounded="full"
                                size="md"
                                fontSize="sm"
                                fontWeight="medium"
                                variant="outline"
                                onClick={() => setContractBulkLoadModal({ show: true, data: { template: selectedTemplate } })}
                            >
                                {t("contractsBulkLoad")}
                            </Button>
                            <Button rounded="full" size="md" fontWeight="medium" variant="solid" fontSize="sm" onClick={handleNewContract}>
                                {t("generateNewContract")}
                            </Button>
                        </div>
                    )}
                </section>

                {/* right panel */}
                <section className="w-9/12 pr-2 pl-4 h-full max-h-full overflow-y-auto">
                    <div className="w-full pb-4 border-b">
                        <Steps
                            labels={[t("loadData"), t("draft"), t("activo")]}
                            currentItem={getTemplateStepByStatus(selectedTemplate?.status)}
                            className="w-6/12 mx-auto"
                        />{" "}
                    </div>

                    <div className="w-full flex items-center justify-between py-4 border-b">
                        <LinkButton leftIcon={<BiChevronLeft size={18} />} onClick={() => navigate("../")}>
                            {t("back")}
                        </LinkButton>
                        <LinkButton leftIcon={<FaEye size={18} />} onClick={handlePreview}>
                            {t("preview")}
                        </LinkButton>
                    </div>

                    <div className="w-full flex grow justify-center mt-2">
                        <TemplatesEditorPanel
                            key={"template-editor" + selectedTemplate?.id} //re-render cuando cambia selectedTemplate
                            enableEdition={isDraft}
                            text={selectedTemplate?.text}
                            onInput={setText}
                            insertEventName={INSERT_EVENT_NAME}
                        />
                    </div>
                </section>
            </main>
        </MainLayout>
    );
};

export default ShowEditTemplatePage;
