import create, { StateCreator } from "zustand"
import { Task } from "gantt-task-react";

/** Contrato del Store */
interface AxRoadmapState {
    
    tasksRoadmapSelected: Task[]
    onlyRead: boolean;
    setTasksRoadMapselect: (tasks: Task[]) => void
    setOnlyRead: (value: boolean) => void
    reset: () => void
}

/** Estado inicial del Store */
const initialState = {
    tasksRoadmapSelected: [],
    onlyRead: false
}

/** Definición del Store */
const Store: StateCreator<AxRoadmapState> = (set, get) => ({

    ...initialState,
    /* setRoadMapSelect: (roadmap: string) => set({ roadMapSelected: roadmap }), */

    setTasksRoadMapselect: (tasks: Task[]) => set({ tasksRoadmapSelected: tasks }),

    setOnlyRead: (value: boolean) => set({ onlyRead: value }),

    reset: () => set(initialState)

})

/** Exportación del Store */
export const useAxRoadmapStore = create<AxRoadmapState>(Store)