import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import LinkButton from "./LinkButton";

const GoBackLinkButton = ({ onClick }: { onClick?: (e?: any) => any }) => {
    const navigate = useNavigate();
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];

    function goBack() {
        navigate(-1)    
    }
    return (
        <LinkButton leftIcon={<BiChevronLeft size={18} />} onClick={onClick ? onClick : goBack}>
            {t("back")}
        </LinkButton>
    );
};

export default GoBackLinkButton;
