import { Button } from "@chakra-ui/button";
import { IoTrashOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { DeleteCommonModalData } from "../../../models/Modals.models";
import CommonModal from "../CommonModal";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: DeleteCommonModalData;
};

const DeleteCommonModal = ({ isOpen, onClose, data }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    function handleClick(){
        data?.onDelete()
        onClose()
    }

    return (
        <CommonModal size="xs" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div className="text-brand-500">
                    <IoTrashOutline size={120} color="inherit" />
                </div>
                <div className="text-center leading-none">
                    <p>{data?.text1}</p>
                    <h3 className="title-3 mt-2">{data?.text2}</h3>
                </div>

                <p className="text-lg text-dark">{t("doYouAgree")}</p>

                <div className="mt-10">
                    <Button rounded="full" size="md" fontWeight="medium" px={"2rem"} onClick={handleClick}>
                        {t("accept")}
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default DeleteCommonModal;
