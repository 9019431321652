import { IconButton, Tooltip, useBoolean } from "@chakra-ui/react";
import { AiOutlineLine } from "react-icons/ai";
import { FiEdit3 } from "react-icons/fi";
import { confirmAlert } from "src/store/modalsState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../../i18n/en";
import AddNewTypeForm from "./AddNewStageGroup";

const StageButton = ({
    icon,
    text,
    onClick,
    selected,
    editStage,
    deleteStage,
}: {
    icon: JSX.Element;
    text: string;
    onClick: () => void;
    selected: boolean;
    editStage: (any) => any;
    deleteStage: (any) => any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [showEditStage, setShowEditStage] = useBoolean(false);

    function handleEditStage(typeName, oldName) {
        let stage = { oldName, newName: typeName };
        editStage(stage);
        setShowEditStage.off();
        onClick();
    }

    function handleDeleteStage(name) {
        deleteStage(name);
    }

    return (
        <>
            <button
                onClick={onClick}
                className={`w-full border px-2 flex rounded-xl items-center shadow-lg justify-between hover-border-brand relative py-3 ${
                    selected ? "bg-sky-100" : "border-gray-400 bg-[#F4F4F4]"
                }`}
            >
                <div className={`flex items-center gap-4 ${selected ? "text-brand-500" : "text-gray-400"}`}>
                    {/*icon*/}
                    <p className="font-medium text-sm">{text}</p>
                </div>
                <div className="flex items-center gap-2">
                    <Tooltip label={t("editTypeBiddingStage")} fontSize="15px">
                        <IconButton
                            variant="outline"
                            rounded="full"
                            colorScheme="blue"
                            aria-label="cancel"
                            size={"xs"}
                            onClick={setShowEditStage.on}
                            icon={<FiEdit3 size={15} />}
                        />
                    </Tooltip>

                    <Tooltip label={t("deleteTypeBiddingStage")} fontSize="15px">
                        <IconButton
                            variant="outline"
                            rounded="full"
                            colorScheme="red"
                            aria-label="cancel"
                            size={"xs"}
                            icon={<AiOutlineLine size={15} />}
                            onClick={() =>
                                confirmAlert.show({
                                    title: t("areYouSure"),
                                    msg: t("onceSavedCannotBeChanged"),
                                    severity: "info",
                                    onConfirm: () => handleDeleteStage(text),
                                })
                            }
                        />
                    </Tooltip>
                </div>
            </button>

            {showEditStage && (
                <AddNewTypeForm onCancel={setShowEditStage.off} onSave={handleEditStage} name={text} label={"Editar Etapa"} isOpen={true} />
            )}
        </>
    );
};

export default StageButton;
