import { Button } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { DndProvider, DropTargetMonitor } from "react-dnd";
import { useDrop } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";
import useCustomToast from "../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import * as XLSX from "xlsx";

interface FileDropzoneProps {
    onDropFile: (file: File) => any;
    accept?: string;
}

const FileDropzoneComp = ({ onDropFile, accept }: FileDropzoneProps) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [{ canDrop, isOver }, drop] = useDrop(useDropOptions, []);

    const [updatedFile, setUpdatedFile] = useState<File | null>(null);

    function useDropOptions() {
        return {
            accept: [NativeTypes.FILE],
            drop(item: { files: any[] }) {
                if (onDrop) {
                    onDrop(item.files);
                }
            },
            canDrop(item: any) {
                return true;
            },
            hover(item: any) {},
            collect: (monitor: DropTargetMonitor) => {
                return {
                    isOver: monitor.isOver(),
                    canDrop: monitor.canDrop(),
                };
            },
        };
    }

    function isExcelValid(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = e.target.result;

                try {
                    const workbook = XLSX.read(data, { type: "binary" });

                    // Verificar si el archivo tiene al menos una hoja de cálculo
                    if (workbook.SheetNames.length === 0) {
                        alert("El archivo Excel no contiene hojas de cálculo.");
                    }
                    if (!workbook.SheetNames || !Array.isArray(workbook.SheetNames) || workbook.SheetNames.length === 0) {
                        alert("El archivo Excel no contiene hojas de cálculo válidas.");
                        return false;
                    }

                    if (!workbook.Sheets || typeof workbook.Sheets !== "object") {
                        alert('El objeto "Sheets" no es válido en el archivo Excel.');
                        return false;
                    }

                    // Puedes realizar más validaciones según tus expectativas

                    resolve(true);
                } catch (error) {
                    console.error("Error al analizar el archivo Excel:", error.message);

                    let errorMessage = "El archivo Excel está corrupto o no es válido.";

                    // Proporcionar mensajes de error más específicos según el tipo de problema
                    if (error.message.includes("hojas de cálculo")) {
                        errorMessage = "El archivo Excel debe contener al menos una hoja de cálculo.";
                    }

                    alert(errorMessage);
                    reject(false);
                }
            };

            // Lee el contenido del archivo como binario
            reader.readAsBinaryString(file);
        });
    }

    function onDrop(files) {
        const file = files[0];

        if (files?.length > 1) {
            toast.error(t("onlyOneFilePerTimeCanBeUploaded"));
            return;
        }
        if (files[0].size === 0) {
            toast.error(t("fileCorrupt"));
            console.error("El archivo está vacío (0 KB).");
            return;
        }

        // const allowedMimeTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
        // if (!allowedMimeTypes.includes(file.type)) {
        //   alert('El tipo de archivo no es compatible. Por favor, elija un archivo de Excel válido.');
        //   return Promise.resolve(false);
        // }

        {
            /*   if (file.name.toLowerCase().endsWith('.xlsx') || file.name.toLowerCase().endsWith('.xls')) {
            
            isExcelValid(file).then((isValid) => {
                if (isValid) {
                  // El archivo es válido, realiza acciones adicionales si es necesario
                } else {
                    toast.error(t("fileCorrupt"))
                  return;
                }
              });
        }*/
        }
        //" application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        // application/vnd.ms-excel

        setUpdatedFile(files[0]);
        onDropFile(files[0]);
    }

    const inputRef = useRef<any>();
    const isActive = canDrop && isOver;

    return (
        <div
            ref={drop}
            className={`relative w-full min-w-[350px] h-36 flex flex-col justify-center items-center gap-3  border-2 border-dashed border-brand-500 rounded-2xl ${
                isActive ? " bg-brand-50" : "bg-slate-50"
            }`}
        >
            {!updatedFile ? (
                <>
                    <div className="text-xl text-brand-600 font-medium">{t("dragFileHereToUpload")}</div>
                    <div className="text-base text-brand-600 font-medium">O</div>
                </>
            ) : (
                <div className="text-xl text-brand-600 font-medium text-center">{updatedFile.name}</div>
            )}
            <label htmlFor="vault-file-upload">
                <input
                    accept={accept || "*"}
                    onInput={(e: any) => onDrop(e.target.files)}
                    ref={inputRef}
                    id="vault-file-upload"
                    name="vault-file-upload"
                    type="file"
                    className="absolute bottom-0 w-1 h-1 invisible"
                ></input>
                <Button variant="outline" size="sm" onClick={() => inputRef.current?.click()}>
                    {t("selectFile")}
                </Button>
            </label>
        </div>
    );
};

const FileDropzone = (props: FileDropzoneProps) => {
    return (
        <DndProvider backend={HTML5Backend}>
            <FileDropzoneComp {...props} />
        </DndProvider>
    );
};

export default FileDropzone;
