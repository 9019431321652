import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import DottedSubtitle from "../common/DottedSubtitle";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const CalendarAdmin = () => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const [value, onChange] = useState<Value>(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedRange, setSelectedRange] = useState<Value>([null, null]);

    const handleSelectDate = (date: Date | Date[]) => {
        if (Array.isArray(date)) {
            if (date.length === 2) {
                const range: [Date, Date] = [date[0], date[1]];
                setSelectedRange(range);
            } else {
                console.error("El rango de fechas debe contener exactamente dos fechas.");
            }
        } else {
            setSelectedDate(date);
        }
    };

    useEffect(() => {
        console.log("Fecha seleccionada:", selectedDate);
    }, [selectedDate]);

    useEffect(() => {
        console.log("Rango de fechas seleccionado:", selectedRange);
    }, [selectedRange]);

    return (
        <>
            <div className="mt-4 mb-4">
                <DottedSubtitle text="Calendario" />
            </div>

            <div className="w-full">
                <div className="flex justify-center items-center mt-24">
                    {/* Calendario con fecha de state (back) */}
                    <Calendar onChange={onChange} value={value} />

                    {/* Calendario con fecha seleccionada */}
                    <Calendar onChange={handleSelectDate} value={selectedDate} />

                    {/* Calendario con rango de fechas */}
                    <Calendar onChange={handleSelectDate} value={selectedRange} selectRange={true} />
                </div>
            </div>
        </>
    );
};

export default CalendarAdmin;
