import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

const documentAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/profile`,
    timeout: 20000,
});

const createProfileBidding = async (body: any) => {
    try {
        return await documentAxiosRequest.put(``, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getProfileBiddingByCompany = async (biddingId: string, companyProfileId: string) => {
    try {
        return await documentAxiosRequest.get(`/byBidding/bidding/${biddingId}/companyProfile/${companyProfileId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getProfilesByBiddingId = async (biddingId: string) => {
    try {
        return await documentAxiosRequest.get(`/bidding/${biddingId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteProfileBidding = async (profileId: string) => {
    try {
        return await documentAxiosRequest.delete(`/${profileId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateProfileBidding = async (body: any) => {
    try {
        return await documentAxiosRequest.patch(`/exceptUserList`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateProfileBiddingList = async (body: any, profileId: string) => {
    try {
        return await documentAxiosRequest.patch(`/userList/${profileId}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getProfile = async (companyProfileId: string) => {
    try {
        return await documentAxiosRequest.get(`/${companyProfileId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    createProfileBidding,
    getProfileBiddingByCompany,
    getProfilesByBiddingId,
    deleteProfileBidding,
    updateProfileBidding,
    updateProfileBiddingList,
    getProfile,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
