import { useEffect } from "react"
import { useIntervalStore } from "src/store/interval.store"
import { useTimerStore } from "src/store/timer.store"

interface TimerProps {
    id: string
    from: number
    ellipsis?: string
    autoStart?: boolean
    onEndTimer: () => void
}

const Timer: React.FC<TimerProps> = ({ id, from,  ellipsis = 'segundos', autoStart = false, onEndTimer }) => {
    /** */
    const { timers, addTimer, decrementTime, startTimer, stopTimer, removeTimer } = useTimerStore()
    const { addInterval, clearInterval } = useIntervalStore()

    useEffect( () => {
        /** Inicializar el temporizador */
        if ( !timers[id] ) {
            addTimer( id, from )
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id, timers ])

    useEffect( () => {
        /** Manejo del intervalo para el decremento */
        if ( autoStart ) {
            startTimer( id )
        }

        if ( timers[id]?.isRunning ) {
            const interval = setInterval( () => {
                decrementTime( id )
            }, 1000 )

            clearInterval( `${id}Interval` )
            addInterval( `${id}Interval`, interval )
        } else {
            clearInterval( `${id}Interval` )
        }

        return () => {
            clearInterval( `${id}Interval` )
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ id, timers[id]?.isRunning ])

    useEffect( () => {
        if ( timers[id]?.time <= 0 ) {
            onEndTimer()
            clearInterval( `${id}Interval` )
            stopTimer( id )
            removeTimer( id )
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timers[id]?.time, onEndTimer, clearInterval, stopTimer, removeTimer] )

    if ( !timers[id] ) return null

    return (
        <strong>{`${timers[id].time} ${ellipsis}`}</strong>
    )
}

export default Timer