import { Button } from "@chakra-ui/button";
import { IoTrashOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { DeleteCommonModalData } from "../../../models/Modals.models";
import CommonModal from "../CommonModal";
import React, { useEffect, useRef, useState } from "react";
import TraceabilityAccordionItems from "src/components/contracts/contract-traceability/TraceabilityAccordionItems";
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Input, Tooltip, useDisclosure } from "@chakra-ui/react";
import useContractState from "../../../store/contractState";
import { Traceability } from "src/models/Contract.models";
import AccordionItemBrand from "../AccordionItemBrand";
import { formatDate } from "../../../lib/utils";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const TraceabilityCommonDrawer = ({ isOpen, onClose, data }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [placement, setPlacement] = React.useState('left')
    //const { isOpen1, onOpen, onClose1 } = useDisclosure()
    const getTraceabilityPDF = useContractState((s) => s.getTraceabilityPDF);
    const [traceability, setTraceability] = useState<Traceability[]>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const containerRef = useRef(null);

    function handleClick() {
        data?.onDelete()
        onClose()
    }

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

        if (scrollTop + clientHeight >= scrollHeight - 20) {
            //  función para cargar más elementos
            // loadMoreData();
        }

        setScrollPosition(scrollTop);
    };

    useEffect(() => {
        getTraceabilityPDF("ba6ab2f5-eebe-41c5-bf97-650de4e20981", false).then((data) => {
            if (data?.codigo === 0) {
                setTraceability(data.respuesta);
            }
        });
    }, []);

    return (
        <>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
                size="md"
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth='2px' >Bitácora</DrawerHeader>
                    <DrawerBody onScroll={handleScroll} ref={containerRef} className="overflow-y-auto max-h-[800px] pt-2hv">
                        <ul>
                            {traceability?.map((trace, idx) => (
                                <TraceItem key={trace.id} trace={trace} withLine={idx !== 0} t={t} />
                            ))}
                        </ul>
                    </DrawerBody>
                    <DrawerFooter>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    );
};

const TraceItem = ({ trace, t, withLine = true }: { trace: Traceability; withLine?: boolean, t: any }) => {
    return (
        <li>
            {withLine && <div className="w-24 h-8 border-l-2 ml-6 my-1"></div>}
            <div className="flex items-center">
                <Tooltip label={trace.generalComment}>
                    <img alt="icon" src={getIcon(trace.nameState)} height={50} width={50} />
                </Tooltip>
                <div className="ml-2">
                    <p className="leading-5 text-sm">
                        <b>{trace.user}</b> {t("changedStateTo")}{" "}
                        <span className="font-semibold italic text-gray-600">{trace.nameState === "SI" ? " en boveda" : trace.nameState.split("ion ").join("ión ")}</span>
                    </p>
                    <p className="leading-none italic text-brand-500 font-light text-xs mt-1">{formatDate(new Date(trace.date), true)}</p>
                </div>
            </div>
        </li>
    );
};

function getIcon(nameState: string) {
    let icon;
    try {
        icon = require(`../../../assets/traceability-icons/${nameState?.toLowerCase().split(" ").join("_")}.svg`);
    } catch (error) {
        icon = require("../../../assets/traceability-icons/en_boveda.svg");
    }

    return icon;
}

export default TraceabilityCommonDrawer;
