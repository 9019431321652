import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { FormErrorMessage, Select } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import useCustomToast from "../../../../hooks/useCustomToast";
import CommonModal from "../../CommonModal";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useBiddingTypesState from "src/store/biddings/biddingTypesState";
import useBiddingState from "src/store/biddings/biddingState";
import useAdministrationState from "src/store/administrationState";
import useGlobalState from "src/store/globalState";
import { DataGroup } from "src/models/DataGroup.models";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

interface FormFields {
    name: string;
    beginDateAt: string;
    endDateAt: string;
    biddingTypeId: string;
    companyOwnerId: string;
    companyPartnerId: string;
    urlPowerBi?: string;
    origen?: string;
    destino?: string;
    concepto?: string;
    importe?: string;
    moneda?: string;
}

const NewBiddingModal = ({ isOpen, onClose }: Props) => {
    const [selectedBiddingType, setSelectedBiddingType] = useState<any>({});
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const {
        getBiddingTypesByCompany,
        biddingTypesByCompany: { data: biddingTypes },
    } = useBiddingTypesState();

    const { createBidding, getBiddings, transformStringPattern,reset } = useBiddingState();
    const { getAssociatedCompanies, associatedCompanies } = useAdministrationState();
    const userCompany = useGlobalState((s) => s.userCompanyInfo);
    const userInfo = useGlobalState((s) => s.getUserCompanyInfo);
    const additionalCompanyInfo = useGlobalState((s) => s.additionalCompanyInfo);

   // console.log("USER COMPANY", additionalCompanyInfo);
    const formik = useFormik<FormFields>({
        initialValues: {
            name: "",
            beginDateAt: "",
            endDateAt: "",
            biddingTypeId: "",
            companyOwnerId: "",
            companyPartnerId: "",
            urlPowerBi: "",
            origen: "",
            destino: ""
        },
        onSubmit: handleCreate,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
            beginDateAt: Yup.string().required(t("requiredField")),
            endDateAt: Yup.string().when("hasEndDate", {
                is: true,
                then: Yup.string().required(t("requiredField")),
            }),
            biddingTypeId: Yup.string().required(t("requiredField")),
            // companyOwnerId: Yup.string().required(t("requiredField")),
            // companyPartnerId: Yup.string().required(t("requiredField")),
            urlPowerBi: Yup.string()
                .url(t("invalidField"))
                .when("hasUrlPowerBi", {
                    is: true,
                    then: Yup.string().required(t("requiredField")),
                }),
        }),
    });

    async function handleCreate(values: FormFields) {
        values.beginDateAt = new Date(values.beginDateAt).toISOString();
        values.endDateAt = new Date(values.endDateAt).toISOString();
        values.companyOwnerId = userCompany.id;
        values.companyPartnerId = userCompany.id;
        //validate dates
        if (new Date(values.beginDateAt) > new Date(values.endDateAt)) {
            toast.error(t("beginDateGreaterThanEndDate"));
            return;
        }

        const created = await createBidding(values);
        if (created) {
            toast.success(t("proccessCreatedSuccessfully"));
            getBiddings();
            onClose();
        } else {
            toast.error(t("creatingProccessError"));
        }
    }

    function onBiddingTypeChange(e: any) {
        formik.handleChange(e);
        const biddingType = biddingTypes.find((biddingType) => biddingType.id === e.target.value);
        setSelectedBiddingType(biddingType);
        if (biddingType?.defaultUrlPowerBi) {
            formik.setFieldValue("urlPowerBi", biddingType.defaultUrlPowerBi);
        }
        if (biddingType?.formatNewProccessName) {
            onGetFormat(biddingType.formatNewProccessName);
        } else {
            formik.setFieldValue("name", formik.values.name);
        }
    }

    async function onGetFormat(pattern) {
        let body = {
            specificDate: "",
            stringWithPattern: pattern,
        };

        const getTypePattern = await transformStringPattern(body, false);

        if (getTypePattern) {
            const namePattern = `${getTypePattern}`;
            formik.setFieldValue("name", namePattern);
        } else {
            toast.error("Error al generar formato");
        }
    }

    useEffect(() => {
        userInfo();
        getBiddingTypesByCompany().catch((err) => {
            toast.error(t("listingBiddingTypesError"));
        });
        getAssociatedCompanies();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!selectedBiddingType?.hasEndDate) {
            formik.setFieldValue("endDateAt", formik.values.beginDateAt);
        }
    }, [selectedBiddingType?.hasEndDate, formik.values.beginDateAt]);

    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">{t("newProcess")}</h2>
                {
                    additionalCompanyInfo?.isAmericaMovil === true ?
                        <AmericaMovilForm onClose={onClose} isOpen={false} /> :
                        <form
                            className="w-full flex flex-wrap justify-between items-center gap-2 max-h-[75vh] overflow-y-auto px-2"
                            onSubmit={formik.handleSubmit}>
                            <FormControl isInvalid={!!formik.errors.name}>
                                <label htmlFor="name" className="text-sm leading-8">
                                    {t("name")}
                                </label>
                                <InputGroup borderColor="gray.400">
                                    <Input
                                        id="name"
                                        name="name"
                                        type="text"
                                        rounded="none"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        disabled={false}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!formik.errors.biddingTypeId}>
                                <label htmlFor="biddingTypeId" className="text-sm leading-8">
                                    {t("processType")}
                                </label>
                                <Select id="biddingTypeId" size="md" rounded="none" onChange={onBiddingTypeChange}>
                                    <option value="">{t("select")}</option>
                                    {biddingTypes.map((biddingType) => (
                                        <option key={biddingType.id} value={biddingType.id}>
                                            {biddingType.name}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage>{formik.errors.biddingTypeId}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!formik.errors.companyOwnerId}>
                                <label htmlFor="companyOwnerId" className="text-sm leading-8">
                                    {t("ownershipCompany")}
                                </label>
                                <Select id="companyOwnerId" size="md" rounded="none" onChange={formik.handleChange}>
                                    <option value="">{t("select")}</option>
                                    {userCompany && <option value={userCompany.id}>{userCompany.name}</option>}
                                </Select>
                                <FormErrorMessage>{formik.errors.companyOwnerId}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!formik.errors.companyPartnerId}>
                                <label htmlFor="companyPartnerId" className="text-sm leading-8">
                                    {t("affiliatedCompany")}
                                </label>
                                <Select id="companyPartnerId" size="md" rounded="none" onChange={formik.handleChange}>
                                    <option value="">{t("select")}</option>
                                    {associatedCompanies?.data?.map((comp) => (
                                        <option key={comp.id} value={comp.foreignCompanyId}>
                                            {comp.foreignCompanyName}
                                        </option>
                                    ))}
                                </Select>
                                <FormErrorMessage>{formik.errors.companyPartnerId}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!formik.errors.beginDateAt} w={selectedBiddingType?.hasEndDate ? "48%" : "100%"}>
                                <label htmlFor="beginDateAt" className="text-sm leading-8">
                                    {t("startDate")}
                                </label>
                                <InputGroup borderColor="gray.400">
                                    <Input
                                        id="beginDateAt"
                                        name="beginDateAt"
                                        type="date"
                                        rounded="none"
                                        onChange={formik.handleChange}
                                        value={formik.values.beginDateAt}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.beginDateAt}</FormErrorMessage>
                            </FormControl>
                            {selectedBiddingType?.hasEndDate && (
                                <FormControl isInvalid={!!formik.errors.endDateAt} w="48%">
                                    <label htmlFor="endDateAt" className="text-sm leading-8">
                                        {t("endDate")}
                                    </label>
                                    <InputGroup borderColor="gray.400">
                                        <Input
                                            id="endDateAt"
                                            name="endDateAt"
                                            type="date"
                                            rounded="none"
                                            onChange={formik.handleChange}
                                            value={formik.values.endDateAt}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>{formik.errors.endDateAt}</FormErrorMessage>
                                </FormControl>
                            )}
                            {selectedBiddingType?.hasUrlPowerBi && (
                                <FormControl isInvalid={!!formik.errors.urlPowerBi}>
                                    <label htmlFor="urlPowerBi" className="text-sm leading-8">
                                        URL Power BI
                                    </label>
                                    <InputGroup borderColor="gray.400">
                                        <Input
                                            id="urlPowerBi"
                                            name="urlPowerBi"
                                            type="text"
                                            rounded="none"
                                            onChange={formik.handleChange}
                                            value={formik.values.urlPowerBi}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>{formik.errors.urlPowerBi}</FormErrorMessage>
                                </FormControl>
                            )}
                            <div className="mt-8 pb-2 w-full text-center">
                                <Button type="submit" rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                                    {t("add")}
                                </Button>
                            </div>
                        </form>
                }
            </div>
        </CommonModal>
    );
};

export default NewBiddingModal;


const AmericaMovilForm = ({ onClose }: Props) => {
    const [selectedBiddingType, setSelectedBiddingType] = useState<any>({});
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const {
        getBiddingTypesByCompany,
        biddingTypesByCompany: { data: biddingTypes },
    } = useBiddingTypesState();

    const { createBidding, getBiddings, transformStringPattern, createBiddingReturnDataResponse,currentBiddingCreated } = useBiddingState();
    const { getAssociatedCompanies, associatedCompanies } = useAdministrationState();
    const userCompany = useGlobalState((s) => s.userCompanyInfo);
    const userInfo = useGlobalState((s) => s.getUserCompanyInfo);
    const setAdditionalCompanyInfo = useGlobalState((s) => s.additionalCompanyInfo);
    const setDataFileAmericaMovil= useBiddingState((s) => s.setDataFileAmericaMovil);
    const { updateExpedient } = useBiddingState();

    const formik = useFormik<FormFields>({
        initialValues: {
            name: "",
          //  beginDateAt: "",
            beginDateAt: new Date().toISOString().split('T')[0],
            endDateAt: "",
            biddingTypeId: "",
            companyOwnerId: "",
            companyPartnerId: "",
            urlPowerBi: "",
            origen: "",
            destino: "",
            concepto: "",
            importe: "",
            moneda: ""
        },
        onSubmit: handleCreate,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
            beginDateAt: Yup.string().required(t("requiredField")),
            endDateAt: Yup.string().when("hasEndDate", {
                is: true,
                then: Yup.string().required(t("requiredField")),
            }),
            biddingTypeId: Yup.string().required(t("requiredField")),
            origen: Yup.string().required(t("requiredField")),
            destino: Yup.string().required(t("requiredField")),
            // companyOwnerId: Yup.string().required(t("requiredField")),
            // companyPartnerId: Yup.string().required(t("requiredField")),
            urlPowerBi: Yup.string()
                .url(t("invalidField"))
                .when("hasUrlPowerBi", {
                    is: true,
                    then: Yup.string().required(t("requiredField")),
                }),
        }),
    });

    async function handleCreate(values: FormFields) {
        values.beginDateAt = new Date(values.beginDateAt).toISOString();
        values.endDateAt = new Date(values.endDateAt).toISOString();
        values.companyOwnerId = userCompany.id;
        values.companyPartnerId = userCompany.id;
        //validate dates
        if (new Date(values.beginDateAt) > new Date(values.endDateAt)) {
            toast.error(t("beginDateGreaterThanEndDate"));
            return;
        }

        let dataFile ={
            originCompany: values.origen,
            destinationCompany: values.destino,
            concepto: values.concepto ,
            importe: values.importe  ,
            monedaType: values.moneda
        }
        console.log("USER COMPANY", dataFile);

     //   setDataFileAmericaMovil(dataFile);

        const created = await createBiddingReturnDataResponse(values);
        console.log("sirvio  state",created.respuesta.expedienteUnico);
        console.log("sirvio  state",created);


        if (created) {
            toast.success(t("proccessCreatedSuccessfully"));
            setDataFileAmericaMovil(dataFile);

            processExpediente(created.respuesta,dataFile);
         //   getBiddings();
           // onClose();
        } else {
            toast.error(t("creatingProccessError"));
        }
    }

    
    const processExpediente = async (createdBidding,dataFile) => {

    let idBidding =  createdBidding.id;
    let expUnico = createdBidding.expedienteUnico;
        let populatedDataGroups: DataGroup[] = expUnico;

                await Promise.all(
                    populatedDataGroups.map(async (dataGroup) => {
                        if (dataGroup.types) {
                            dataGroup.types = dataGroup.types.map((dataType) => {                                    
                                const lowerCaseOName = dataType.name.toLowerCase(); // Convertir el nombre a minúsculas
                                console.log("data file",lowerCaseOName)
                                if (lowerCaseOName.includes("origen")) {
                                    return { ...dataType, value: dataFile.originCompany };
                                }                                
                                if (lowerCaseOName.includes("destino")) {
                                    return { ...dataType, value: dataFile.destinationCompany };
                                }
                                if (lowerCaseOName.includes("accionista 1 + % de participación")) {
                                    return { ...dataType, value: dataFile.destinationCompany };
                                }  
                                if (lowerCaseOName.includes("concepto")) {
                                    return { ...dataType, value: dataFile.concepto };
                                } 
                                if (lowerCaseOName.includes("importe")) {
                                    return { ...dataType, value: dataFile.importe };
                                } 
                                else if (lowerCaseOName.includes("moneda")) {
                                    // Utilizar includes para comprobar si el nombre contiene "origen"
                                    return { ...dataType, value: dataFile.monedaType };
                                } else {
                                    return dataType;
                                }
                            });
                        }
                    })
                );

              //  const saved = await updateExpedient(id, expedienteDatos);
               const saved = updateExpedient( idBidding, populatedDataGroups as DataGroup[]);

                if (saved) {
                    toast.success("Se ha cargado la operación correctamente");
                    setDataFileAmericaMovil(null);
                    getBiddings();
                    onClose();
                } else {
                    toast.error("Ha ocurrido un error al cargar el expediente");
                    console.log("Error al guardar expediente de datos");
                }
    }
       

    function onBiddingTypeChange(e: any) {
        formik.handleChange(e);
        const biddingType = biddingTypes.find((biddingType) => biddingType.id === e.target.value);
        setSelectedBiddingType(biddingType);
        if (biddingType?.defaultUrlPowerBi) {
            formik.setFieldValue("urlPowerBi", biddingType.defaultUrlPowerBi);
        }
        if (biddingType?.formatNewProccessName) {
            onGetFormat(biddingType.formatNewProccessName);
        } else {
            formik.setFieldValue("name", formik.values.name);
        }
    }

    async function onGetFormat(pattern) {
        let body = {
            specificDate: "",
            stringWithPattern: pattern,
        };

        const getTypePattern = await transformStringPattern(body, false);

        if (getTypePattern) {
            const namePattern = `${getTypePattern}`;
            formik.setFieldValue("name", namePattern);
        } else {
            toast.error("Error al generar formato");
        }
    }

    useEffect(() => {
        userInfo();
        getBiddingTypesByCompany().catch((err) => {
            toast.error(t("listingBiddingTypesError"));
        });
        getAssociatedCompanies();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!selectedBiddingType?.hasEndDate) {
            formik.setFieldValue("endDateAt", formik.values.beginDateAt);
        }
    }, [selectedBiddingType?.hasEndDate, formik.values.beginDateAt]);

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            beginDateAt: new Date().toISOString().split('T')[0]
        });
    }, []);

    //console.log("bengi biddingType",biddingTypes)
    return (
        <form
            className="w-full flex flex-wrap justify-between items-center gap-2 max-h-[75vh] overflow-y-auto px-2"
            onSubmit={formik.handleSubmit}
        >
            <FormControl isInvalid={!!formik.errors.name}>
                <label htmlFor="name" className="text-sm leading-8">
                    {/*t("nameId")
                            */}
                    {t("nameId")}
                </label>
                <InputGroup borderColor="gray.400">
                    <Input
                        id="name"
                        name="name"
                        type="text"
                        rounded="none"
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        disabled={true}
                    />
                </InputGroup>
                <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formik.errors.biddingTypeId}>
                <label htmlFor="biddingTypeId" className="text-sm leading-8">
                    {t("processType")}
                </label>
                <Select id="biddingTypeId" size="md" rounded="none" onChange={onBiddingTypeChange}>
                    <option value="">{t("select")}</option>
                    {biddingTypes.map((biddingType) => (
                        <option key={biddingType.id} value={biddingType.id}>
                            {biddingType.name}
                        </option>
                    ))}
                </Select>
                <FormErrorMessage>{formik.errors.biddingTypeId}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formik.errors.origen}>
                <label htmlFor="origen" className="text-sm leading-8">
                    {"Origen/Decretada"}
                </label>
                <InputGroup borderColor="gray.400">
                    <Input
                        id="origen"
                        name="origen"
                        type="text"
                        rounded="none"
                        onChange={formik.handleChange}
                        value={formik.values.origen}
                    />
                </InputGroup>
                <FormErrorMessage>{formik.errors.origen}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formik.errors.destino}>
                <label htmlFor="destino" className="text-sm leading-8">
                    {"Destino"}
                </label>
                <InputGroup borderColor="gray.400">
                    <Input
                        id="destino"
                        name="destino"
                        type="text"
                        rounded="none"
                        onChange={formik.handleChange}
                        value={formik.values.destino}
                    />
                </InputGroup>
                <FormErrorMessage>{formik.errors.destino}</FormErrorMessage>
            </FormControl>

            {biddingTypes.map((biddingType) => {
                // Verificar si el nombre del tipo de licitación es "Especial"
                if (biddingType.name === "Especial"  && biddingType.id === formik.values.biddingTypeId) {
                    // Si es "Especial", mostrar 3 inputs
                    return (
                        <>

                            <FormControl isInvalid={!!formik.errors.concepto}>
                                <label htmlFor="concepto" className="text-sm leading-8">
                                    {"Concepto"}
                                </label>
                                <InputGroup borderColor="gray.400">
                                    <Input
                                        id="concepto"
                                        name="concepto"
                                        type="text"
                                        rounded="none"
                                        onChange={formik.handleChange}
                                        value={formik.values.concepto}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.concepto}</FormErrorMessage>
                            </FormControl>  

                            <FormControl isInvalid={!!formik.errors.importe}>
                                <label htmlFor="importe" className="text-sm leading-8">
                                    {"Importe"}
                                </label>
                                <InputGroup borderColor="gray.400">
                                    <Input
                                        id="importe"
                                        name="importe"
                                        type="text"
                                        rounded="none"
                                        onChange={formik.handleChange}
                                        value={formik.values.importe}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.importe}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!formik.errors.moneda}>
                                <label htmlFor="moneda" className="text-sm leading-8">
                                    {"Moneda"}
                                </label>
                                <InputGroup borderColor="gray.400">
                                    <Input
                                        id="moneda"
                                        name="moneda"
                                        type="text"
                                        rounded="none"
                                        onChange={formik.handleChange}
                                        value={formik.values.moneda}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.moneda}</FormErrorMessage>
                            </FormControl>                       
                        </>
                    );
                } else {
                    return null;
                }
            })}

            {biddingTypes.map((biddingType) => {
                if ( biddingType.name === "Terceros" && biddingType.id === formik.values.biddingTypeId) {
                    // Si es "Terceros", mostrar 3 inputs
                    return (
                        <>

                            <FormControl isInvalid={!!formik.errors.concepto}>
                                <label htmlFor="concepto" className="text-sm leading-8">
                                    {"Concepto"}
                                </label>
                                <InputGroup borderColor="gray.400">
                                    <Input
                                        id="concepto"
                                        name="concepto"
                                        type="text"
                                        rounded="none"
                                        onChange={formik.handleChange}
                                        value={formik.values.concepto}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.concepto}</FormErrorMessage>
                            </FormControl>  

                            <FormControl isInvalid={!!formik.errors.importe}>
                                <label htmlFor="importe" className="text-sm leading-8">
                                    {"Importe"}
                                </label>
                                <InputGroup borderColor="gray.400">
                                    <Input
                                        id="importe"
                                        name="importe"
                                        type="text"
                                        rounded="none"
                                        onChange={formik.handleChange}
                                        value={formik.values.importe}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.importe}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={!!formik.errors.moneda}>
                                <label htmlFor="moneda" className="text-sm leading-8">
                                    {"Moneda"}
                                </label>
                                <InputGroup borderColor="gray.400">
                                    <Input
                                        id="moneda"
                                        name="moneda"
                                        type="text"
                                        rounded="none"
                                        onChange={formik.handleChange}
                                        value={formik.values.moneda}
                                    />
                                </InputGroup>
                                <FormErrorMessage>{formik.errors.moneda}</FormErrorMessage>
                            </FormControl>                       
                        </>
                    );
                } else {
                    return null;
                }
            })}
            {/** <FormControl isInvalid={!!formik.errors.beginDateAt} w={selectedBiddingType?.hasEndDate ? "48%" : "100%"}>
                <label htmlFor="beginDateAt" className="text-sm leading-8">
                    {t("date")}
                </label>
                <InputGroup borderColor="gray.400">
                    <Input
                        id="beginDateAt"
                        name="beginDateAt"
                        type="date"
                        rounded="none"
                        onChange={formik.handleChange}
                        value={formik.values.beginDateAt}
                    />
                </InputGroup>
                <FormErrorMessage>{formik.errors.beginDateAt}</FormErrorMessage>
            </FormControl>*/}
            

            {selectedBiddingType?.hasEndDate && (
                <FormControl isInvalid={!!formik.errors.endDateAt} w="48%">
                    <label htmlFor="endDateAt" className="text-sm leading-8">
                        {t("endDate")}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            id="endDateAt"
                            name="endDateAt"
                            type="date"
                            rounded="none"
                            onChange={formik.handleChange}
                            value={formik.values.endDateAt}
                        />
                    </InputGroup>
                    <FormErrorMessage>{formik.errors.endDateAt}</FormErrorMessage>
                </FormControl>
            )}

            {selectedBiddingType?.hasUrlPowerBi && (
                <FormControl isInvalid={!!formik.errors.urlPowerBi}>
                    <label htmlFor="urlPowerBi" className="text-sm leading-8">
                        URL Power BI
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            id="urlPowerBi"
                            name="urlPowerBi"
                            type="text"
                            rounded="none"
                            onChange={formik.handleChange}
                            value={formik.values.urlPowerBi}
                        />
                    </InputGroup>
                    <FormErrorMessage>{formik.errors.urlPowerBi}</FormErrorMessage>
                </FormControl>
            )}

            <div className="mt-8 pb-2 w-full text-center">
                <Button type="submit" rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                    {t("add")}
                </Button>
            </div>
        </form>
    );
};
