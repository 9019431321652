import { Permission } from "src/models/Permission.models";
import permissionService from "src/services/roles-permissions/permission.service";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import useModalState from "./modalsState";

interface State {
    permissions: { loading: boolean; error: boolean; data: Permission[] };
    selectedPermission: Permission;
    setSelectedPermission: (permission: Permission) => void;
    getPermissions: (withLoading?: boolean) => void;
    createPermission: (permission: Permission, withLoading?: boolean) => Promise<boolean>;
    deletePermission: (permissionId: string, withLoading?: boolean) => Promise<boolean>;
    updatePermission: (permission: Permission, withLoading?: boolean) => Promise<boolean>;
    reset: () => void;
}

const initialState = {
    permissions: { loading: false, error: false, data: [] },
    selectedPermission: null,
};

const usePermissionsState = create<State>((set, get) => ({
    ...initialState,
    setSelectedPermission: setSelectedPermission(set, get),
    getPermissions: getPermissions(set, get),
    createPermission: createPermission(set, get),
    deletePermission: deletePermission(set, get),
    updatePermission: updatePermission(set, get),
    reset: () => set((state) => ({ ...state, ...initialState })),
}));

export default usePermissionsState;

//SETTER FUNCTIONS
function setSelectedPermission(set: NamedSet<State>, get: GetState<State>) {
    return (permission: Permission) => set((state) => ({ selectedPermission: permission }));
}

function hasPermissionForAction (reponse : any, msgPermission : string, redirect : boolean ){
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: 'AVISO',
                text2: msgPermission,
                text3: 'Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.',
                redirectHome : redirect,
                onClose: async () => {
                    return { show: false }
                  }
            }
        });
    }
}

function getPermissions(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        set((state) => ({ permissions: { loading: true, error: false, data: state.permissions.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await permissionService.getPermissions();
        hasPermissionForAction(res,"Obtener permisos",false);

        if (res?.data?.respuesta && Array.isArray(res.data.respuesta)) {
            set({ permissions: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching permissions");
            set((state) => ({ permissions: { loading: false, error: true, data: state.permissions.data } }));
            //clean error
            set((state) => ({ permissions: { ...state.permissions, error: false } }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function createPermission(set: NamedSet<State>, get: GetState<State>) {
    return async (permission, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await permissionService.createPermission(permission);

        hasPermissionForAction(res,"Crear permisos",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getPermissions(false);
            return true;
        } else {
            console.log("Error creating permission");
            return false;
        }
    };
}

function updatePermission(set: NamedSet<State>, get: GetState<State>) {
    return async (permission, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await permissionService.updatePermission(permission);

        hasPermissionForAction(res,"Actualizar permisos",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getPermissions(false);
            return true;
        } else {
            console.log("Error updating permission");
            return false;
        }
    };
}

function deletePermission(set: NamedSet<State>, get: GetState<State>) {
    return async (permissionId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await permissionService.deletePermissionById(permissionId);
       
        hasPermissionForAction(res,"Eliminar permisos",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getPermissions(false);
            return true;
        } else {
            console.log("Error deleting permission");
            return false;
        }
    };
}
