import useAuthState from "../../store/authState";
import companyIcon from "../../assets/images/company-icon.png";
import useGlobalState from "../../store/globalState";
import { b64toBlob } from "../../lib/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
 import { GoPerson } from 'react-icons/go';
import americamovil from '../../assets/images/americamovil-logo.png'
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const ProfileBox = () => {
    const { userName } = useAuthState((s) => s);
    const {getUserCompanyInfo, userInfo, userCompanyInfo, parentCompanyData, setIsSelectedChildCompany} = useGlobalState()
    const [profileImage, setProfileImage] = useState(null);
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const navigate = useNavigate()

    const handleChangeCompany = () => {
        setIsSelectedChildCompany( false )
        navigate( "/group-selection" )
    }

    useEffect(() => {
        if (userInfo?.data?.image64 && userInfo?.data?.image) {
            try {
                const blob = b64toBlob(userInfo.data.image64.split("base64,")[1], userInfo?.data?.image);
                const url = window.URL.createObjectURL(blob);
                setProfileImage(url);
            } catch (error) {
                console.log(error);
            }
        }
    }, [userInfo]);

    return (    
        <div className="w-full flex items-center justify-between">
            <div className="flex items-center space-x-3 w-7/12">
                <div className="pl-2">
                    <h1 className="text-3xl text-gray-400 ">{t("welcome")}</h1>
                    <h2 className="text-brand-600 text-5xl capitalize">{userName ?? "N/A"}</h2>
                </div>
            </div>
            <div className="grow p-3 flex items-center justify-between border border-dashed border-gray-500 rounded-lg">
                <div className="flex items-center space-x-4">
                    <img className="block w-10" src={companyIcon} alt="company logo" />
                    <h3 className="text-2xl text-brand-500">{userCompanyInfo?.name}</h3>
                </div>
                {parentCompanyData && (
                    <Button variant="outline"
                        onClick={handleChangeCompany}
                    >
                        Cambiar empresa
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ProfileBox;

/**
 *   <div className="w-full flex items-center justify-between">
            <div className="flex items-center space-x-3 w-7/12">
                <div className="pl-2">
                    <h1 className="text-3xl text-gray-400 ">{t("welcome")}</h1>
                    <h2 className="text-brand-600 text-5xl capitalize">{userName ?? "N/A"}</h2>
                </div>
            </div>

            <div className="grow p-3 flex items-center space-x-4 border border-dashed border-gray-500 rounded-lg">
                <img className="block w-10" src={companyIcon} alt="company logo" />
                <h3 className="text-2xl text-brand-500">{userCompanyInfo?.name}</h3>
            </div>
        </div>
 */


        /** NEW COMPONENET
         * 
         *  <div className="w-full flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md">
            <div className="flex items-center space-x-4 w-7/12">
                <GoPerson className="w-16 h-16 text-blue-500 rounded-full bg-white p-2 shadow-md" />
                <div>
                    <h1 className="text-2xl text-gray-500 ">{t("welcome")}</h1>
                    <h2 className="text-brand-600 text-4xl capitalize">{userName ?? "N/A"}</h2>
                </div>
            </div>
            <div className="grow p-3 flex flex-col items-center space-y-3 bg-white rounded-lg border border-blue-300 shadow-md transition duration-300 ease-in-out transform hover:scale-105">
                <img className="block w-16 mb-2" src={companyIcon} alt="company logo" />

                <h3 className="text-2xl text-brand-500">{userCompanyInfo?.name}</h3>
            </div>
        </div>
         */