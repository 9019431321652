import Icon from "@chakra-ui/icon";

const AddRevisorIcon = (props) => {
    return (
        <Icon viewBox="0 0 38 46" fill="none" {...props}>
            <path
                d="M14.994 13.584c3.187 0 5.77-2.593 5.77-5.792S18.182 2 14.995 2c-3.187 0-5.77 2.593-5.77 5.792s2.583 5.792 5.77 5.792ZM2 39.223v-5.792c0-3.459 1.369-6.776 3.806-9.222a12.97 12.97 0 0 1 9.188-3.82v0a12.97 12.97 0 0 1 9.18 3.825 13.067 13.067 0 0 1 3.8 9.217v5.792"
                stroke="#25469B"
                strokeWidth={3}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M27.652 45.312c5.705 0 10.33-4.642 10.33-10.369 0-5.726-4.625-10.369-10.33-10.369-5.706 0-10.33 4.643-10.33 10.37 0 5.726 4.625 10.368 10.33 10.368Z"
                fill="#25469B"
            />
            <path
                d="M32.104 33.39h-3.08v-3.024c0-.358-.142-.701-.394-.954a1.342 1.342 0 0 0-2.296.954v3.025h-2.946c-.357 0-.7.142-.952.395a1.353 1.353 0 0 0 .952 2.305h2.945v3.024c0 .358.142.702.394.955a1.343 1.343 0 0 0 2.297-.955v-3.024h3.08c.357 0 .699-.142.951-.395a1.353 1.353 0 0 0-.95-2.305Z"
                fill="#fff"
            />
        </Icon>
    );
};

export default AddRevisorIcon;
