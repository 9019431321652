import BoxButton from "../../components/common/BoxButton";
import MainLayout from "../../components/layouts/MainLayout";
import { useEffect } from "react";
import useCustomToast from "../../hooks/useCustomToast";
//import AddNewOutlineIcon from "../../components/icons/AddNewOutlineIcon";
//import AddNewFilledIcon from "../../components/icons/AddNewFilledIcon";
//import ContractTypesOutlineIcon from "../../components/icons/ContractTypesOutlineIcon";
//import ContractTypesFilledIcon from "../../components/icons/ContractTypesFilledIcon";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
//import BiddingsTable from "src/components/bidding/BiddingsTable";
import useBiddingState from "src/store/biddings/biddingState";
import useModalState from "src/store/modalsState";
import { useNavigate } from "react-router-dom";
import NewProcessIcon from "src/components/icons/iconsV2/NewProcessIcon";
import ProcessTypesIcon from "src/components/icons/iconsV2/ProcessTypesIcon";
import DottedSubtitle from "src/components/common/DottedSubtitle";
//import NewBiddingTable from "../../components/bidding/NewBiddingTable";
//import TestMaterialTable from "../../components/bidding/TestMaterialTable";
import CustomBiddingsTable from "../../components/bidding/CustomBiddingsTable";
import useComponetState from "src/store/components/componentState";

const BiddingsPage = () => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const setNewBiddingModal = useModalState((s) => s.setNewBiddingModal);
    const uiBtnProcessOptions = useComponetState((s) => s.uiBtnProcessOptions);
    const getAllBtnProcess = useComponetState((s) => s.getAllBtnProcess);

    const navigate = useNavigate();
    const toast = useCustomToast();
    const {
        biddings: { data },
        getBiddings,
    } = useBiddingState();

    useEffect(() => {
        getAllBtnProcess();
        getBiddings().catch((err) => {
            toast.error(t("listingBiddingsError"));
        });
    }, []);

    return (
        <MainLayout>
            <main className="w-full p-6">
                <h2 className="title-3">{t("processes")}</h2>
                {!uiBtnProcessOptions.showBtTypeProcces && !uiBtnProcessOptions.showBtnCreateNewProcess ? ("") : (
                <DottedSubtitle text={t("createNewProcess")} />
                )
                }
                <div className="flex space-x-6 mt-4">
                    {!uiBtnProcessOptions.showBtTypeProcces ? ("") : (
                        <BoxButton
                            icon={<ProcessTypesIcon boxSize={45} />}
                            //hoverIcon={<ContractTypesFilledIcon boxSize={70} />}
                            text={t("typeProcess")}
                            tooltip={t("createNewProcessType")}
                            onClick={() => navigate("types")}
                            size="medium"
                        />
                    )}
                    {!uiBtnProcessOptions.showBtnCreateNewProcess ? ("") : (
                        <BoxButton
                            icon={<NewProcessIcon boxSize={45} />}
                            //hoverIcon={<AddNewFilledIcon boxSize={70} />}
                            text={t("newProcess")}
                            tooltip={t("generateNewProcess")}
                            onClick={() => setNewBiddingModal({ show: true })}
                            size="medium"
                        />
                    )}
                </div>
                <div className="mt-4">
                    <CustomBiddingsTable data={data} />
                    {/*<BiddingsTable biddings={data} />*/}
                </div>
            </main>
        </MainLayout>
    );
};

export default BiddingsPage;
