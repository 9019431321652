import CommonModal from "../../CommonModal";
import BlockchainGif from "../../../../assets/images/blockchain_mov.gif";
import { BsCheckCircleFill, BsFillClockFill } from "react-icons/bs";
import { Button, CircularProgress } from "@chakra-ui/react";
import { TokenIlustrationModalData } from "../../../../models/Modals.models";
import { useEffect, useState } from "react";
import { getRandomNumber } from "../../../../lib/utils";
type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data: TokenIlustrationModalData;
};

const proccessList = [
    "Recolectando información del documento",
    "Generando proceso",
    "Estableciendo transacciones con bóveda",
    "Comprobación de unicidad",
    "Comprobación de trazabilidad",
    "Transacciones completadas exitosamente",
    "Minando token",
    "Solicitando nodos disponibles para minado",
    "Validando integridad del token.",
    "Validando procesos alternos concurrente",
    "Generación de Token exitosa",
    "Persistiendo Token",
    "Proceso completado con éxito",
];

const TokenIlustrationModal = ({ isOpen, onClose, data }: Props) => {
    const [steps, setSteps] = useState<{ text: string; status: number }[]>(proccessList.map((text) => ({ text, status: 1 })));
    const [currentStep, setCurrentStep] = useState(0);

    function incrementSteps(cs) {
        setSteps((prevSteps) =>
            prevSteps.map((step, index) => {
                if (index <= cs && step.status < 3) {
                    return { ...step, status: step.status + 1 };
                }
                return step;
            })
        );
        setCurrentStep((prevStep) => prevStep + 1);
    }

    useEffect(() => {
        const timeout = getRandomNumber(300, 600);
        if (currentStep <= steps.length) {
            setTimeout(() => {
                incrementSteps(currentStep);
            }, timeout);
        }
    }, [currentStep]);

    return (
        <CommonModal bg="#EDF3F7" size="6xl" isOpen={isOpen} onClose={onClose} closeButton closeOnOverlayClick>
            <div className="w-full flex flex-wrap items-center">
                <div className="w-6/12 py-8 pl-4">
                    <ul style={{ fontFamily: "fantasy" }} className="space-y-1 text-dark">
                        {steps.map((step, index) => (
                            <ProccessListItem idx={index + 1} status={step.status as any} text={step.text} />
                        ))}
                    </ul>
                </div>
                <div className="w-6/12">
                    <img className="mx-auto max-h-[420px] object-cover" src={BlockchainGif} alt="blockchain" />
                </div>
                {/* <div className="w-full text-center text-brand-600 text-xl font-bold">Token:</div> */}
                <div className="w-full flex justify-center">
                    <Button rounded="full" size="md" fontWeight="medium" variant="solid" onClick={onClose}>
                        Cerrar
                    </Button>
                </div>
            </div>
        </CommonModal>
    );
};

export default TokenIlustrationModal;

const ProccessListItem = ({ text, status, idx }: { text: string; status: 1 | 2 | 3; idx: number }) => {
    return (
        <li className="text-lg flex justify-between items-center w-full">
            <span style={{ fontFamily: "monospace" }} className="whitespace-nowrap">
                {text}
            </span>
            <div
                className="w-full h-[1em] bg-repeat-x mx-2 mb-[-.6em]"
                style={{
                    backgroundSize: "10px 1em",
                    backgroundImage: "radial-gradient(1px 1px at center, #000 1px, transparent 1px)",
                }}
            />

            <div>
                {status === 1 ? (
                    <BsFillClockFill color="grey" />
                ) : status === 2 ? (
                    <CircularProgress isIndeterminate size={5} />
                ) : (
                    <BsCheckCircleFill color="#56B06F" />
                )}
            </div>
        </li>
    );
};
