import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const useCustomToast = () => {
    const toast = useToast();
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];

    function error(msg: string, title?: string) {
        toast({
            title: title ?? t("error"),
            description: msg,
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top-right",
            variant: "top-accent",
        });
    }

    function success(msg: string, title?: string) {
        toast({
            title: title ?? t("success"),
            description: msg,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top-right",
            variant: "top-accent",
        });
    }

    function info(msg: string , title?: string){
        toast({
            title: title ?? t("success"),
            description: msg,
            status: "info",
            duration: 15000,
            isClosable: true,
            position: 'top-left',
        });
    }

    function infoTop(msg: string , title?: string){
        toast({
            title: title ?? t("success"),
            description: msg,
            status: "info",
            duration: 15000,
            isClosable: true,
            position: 'top',
        });
    }

    function warningTop(msg: string , title?: string){
        toast({
            title: title ?? t("warning"),
            description: msg,
            status: "warning",
            duration: 15000,
            isClosable: true,
            position: 'top',
        });
    }

    return { success, error, info, infoTop,warningTop };
};

export default useCustomToast;
