import axios from "axios";
import { getAuthHeader } from "../lib/utils";

const vaultGeneralAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_GENERAL}/api/general/vault`,
    timeout: 30000,
});

const vaultOriginalAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_VAULT}/api/v1`,
    timeout: 30000,
});

const utilAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_GENERAL}/api/general/util`,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 10000,
});

const addDocumentToSession = async (body: any) => {
    try {
        return await utilAxiosRequest.post(`/uploadToSession`, body, {
            headers: getMultipartHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

//genera el pdf y lo guarda en el vault
const createDocument = async (body: any) => {
    try {
        return await vaultGeneralAxiosRequest.put(`/document`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createFolder = async (body: any) => {
    try {
        return await vaultGeneralAxiosRequest.put(`/folder`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDocumentFromVault = async (docId: string) => {
    try {
        return await vaultGeneralAxiosRequest.get(`/documentData/${docId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getDocumentInfo = async (docId: string) => {
    try {
        return await vaultGeneralAxiosRequest.get(`/documentInfo/${docId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getFolderData = async (folderId: string, companyId: string) => {
    try {
        return await vaultGeneralAxiosRequest.get(`/folderData/${folderId}/companyId/${companyId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getCompanyRootFolder = async (companyId: string) => {
    try {
        return await vaultGeneralAxiosRequest.get(`/folder/${companyId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getCompanySubFolder = async (companyId: string, subfolderId: string) => {
    try {
        return await vaultGeneralAxiosRequest.get(`/subfolder/${companyId}/${subfolderId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteFolder = async (folderId: string) => {
    try {
        return await vaultGeneralAxiosRequest.delete(`/folder/${folderId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteDocument = async (docId: string) => {
    try {
        return await vaultGeneralAxiosRequest.delete(`/document/${docId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const downloadDocument = async (docId: string) => {
    try {
        return await vaultOriginalAxiosRequest.get(`/documents/download/${docId}`, {
            responseType: "arraybuffer",
            headers: {
                Accept: "*/*",
                ...getAuthHeader(),
            },
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    addDocumentToSession,
    createDocument,
    createFolder,
    getDocumentFromVault,
    getDocumentInfo,
    getFolderData,
    getCompanyRootFolder,
    getCompanySubFolder,
    deleteFolder,
    deleteDocument,
    downloadDocument,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}

function getMultipartHeaders() {
    return {
        "Content-Type": "multipart/form-data",
        ...getAuthHeader(),
    };
}
