import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
type Props = {
    onSave: (arg: any) => any;
    onSendToRevision: (arg: any) => any;
    isSaving: boolean;
    contractStatus? : string;
    templateMaxLife?: string;
};

const DraftActions = ({ onSave, onSendToRevision, isSaving,contractStatus,templateMaxLife }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];

    const shouldShowtActions = () => {

        return contractStatus != templateMaxLife;
    };

    return (
        <div className="flex flex-col xl:flex-row justify-between gap-2 pt-4 pb-2 sticky bottom-0 bg-white border-t">
            <Button rounded="full" size="md" fontWeight="medium" variant="outline" disabled={isSaving} onClick={onSave}>
                {t("saveChanges")}
            </Button>
            {shouldShowtActions && 
             <Button rounded="full" size="md" fontWeight="medium" variant="solid" onClick={onSendToRevision}>
             {t("sendRevision")}
         </Button>}
           
        </div>
    );
};

export default DraftActions;
