import GC from '@mescius/spread-sheets';
import '@mescius/spread-sheets-print';
import '@mescius/spread-sheets-io';
import '@mescius/spread-sheets-shapes';
import '@mescius/spread-sheets-charts';
import '@mescius/spread-sheets-slicers';
import '@mescius/spread-sheets-pivot-addon';
import '@mescius/spread-sheets-reportsheet-addon';
import "@mescius/spread-sheets-tablesheet";
import "@mescius/spread-sheets-ganttsheet";
import { SpreadSheets, Worksheet } from '@mescius/spread-sheets-react';
import '@mescius/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import { Box, Button, Flex, useBoolean } from '@chakra-ui/react';
import { useAxCalcStore } from 'src/store/axCalc.store';
import { BiDownload, BiSave } from 'react-icons/bi';
import AddFileToCalcModal from '../common/modals/AddFileToCalcModal';

GC.Spread.Common.CultureManager.culture('ja-jp');

// @ts-ignore
window.GC = GC;

GC.Spread.Sheets.LicenseKey = "axeleratum,E436373432998989#B1Tedl7MLNXTa3ET8JDdihGcyETcDVXb5MDNFdDR6QjZNx6Ys5WWFxGMshzVUl6KDJVY7g5SZF4RV5mRq34VvhFUENDcZlHMvUTRPNkMIlUTzUDUBZzLvcXWhRGNKhWcEhFO69EM6oEVWZXTrdXO6JFbykkSaV4LvUFWMlmaFJ7QuRjRlxWZORGR9cDW4sSUp9Wc6oVSFljU7VFMR5mSYRGRaNnQvFTeuNGeNxmMOdlZ8ZUe6F4MapUMhRGRDdDUwdETOJEbwEmdtxGbSVmcCF5Yh3ScJ5Uc6QGa5YzZipnTLRmdhRmW5N4T5pndwx6NWJUWPxmM9Y4YjxmTOpWWuJGSHRTcSFlI0IyUiwiI4I4Q4cjRGRjI0ICSiwSNxczNzkDNyQTM0IicfJye35XX3JSVBtUUiojIDJCLicTMuYHITpEIkFWZyB7UiojIOJyebpjIkJHUiwiI8AjN5MDMgEDM8ADNyAjMiojI4J7QiwiIxMDOwQjMwIjI0ICc8VkIsISbvNmLtVHdhJXZsVGehJiOiEmTDJCLlVnc4pjIsZXRiwiI9gTO8kTOyMDNzczM6MDNiojIklkIs4XXiQXZlh6U4J7bwVmUiwiIlxmYhRFdvZXaQJCLiQXZlh6U4RnbhdkIbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdGJUQk9mURN6b6VHeoxGR65WOVJmV6VmTUBlWr8WVllUYVxGe7ZFcTBXZiJXMzsER8YUVERUSK5GMMx6QhV7NC56ZC3CaIJmc7wUa5csZ4F";        // Enter a valid license key.      

const BiddingAxCalc = () => {

  const [showAddStage, setShowAddStage] = useBoolean(false);
  const { setSpread, save } = useAxCalcStore();

  let hostStyle = {
    width: '100%',
    height: '600px',
    border: '1px solid darkgray'
  }


  function initSpread(spread: GC.Spread.Sheets.Workbook) {
    setSpread(spread);
    // Init Status Bar
    const statusBar = new GC.Spread.Sheets.StatusBar.StatusBar(document.getElementById('statusBar')!);
    statusBar.bind(spread);
  }

  return (
    <Box mt={2}>
      <Flex justifyContent={'flex-end'} gap={3} mb={3}>
        <Button
          rounded={"full"}
          size={"md"}
          fontWeight={"medium"}
          px={"2rem"}
          onClick={() => save()} leftIcon={<BiDownload />}>
          Descargar
        </Button>
        <Button
          rounded={"full"}
          size={"md"}
          fontWeight={"medium"}
          px={"2rem"}
          onClick={setShowAddStage.on} leftIcon={<BiSave />} >
          Cargar Documento
        </Button>
      </Flex>
      <Box>
        <SpreadSheets workbookInitialized={spread => initSpread(spread)} hostStyle={hostStyle}>
          <Worksheet />
        </SpreadSheets>
      </Box>
      <div id="statusBar"></div>
      {showAddStage && (
        <AddFileToCalcModal onCancel={setShowAddStage.off} isOpen={true} />
      )}
    </Box>
  );
}

export default BiddingAxCalc;