import { useEffect, useState } from "react";
import { Traceability } from "../../models/Contract.models";
import useContractState from "../../store/contractState";
import { formatDate } from "../../lib/utils";
import AccordionItemBrand from "../common/AccordionItemBrand";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { Button, Input, Select, Tooltip } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import { id } from "date-fns/locale";

const TraceabilityAccordionItems = ({
    contractId,
    isPDF,
    handleInputChange,
    handleAddComment,
    comment,
    commentsArray,
    ref,
    onClick,
    error,
    range,
}: {
    contractId: string;
    isPDF;
    handleInputChange: any;
    handleAddComment: any;
    comment: any;
    commentsArray: any;
    ref: any;
    onClick: any;
    error: string;
    range: any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const getTraceability = useContractState((s) => s.getTraceability);
    const getTraceabilityPDF = useContractState((s) => s.getTraceabilityPDF);

    const [traceability, setTraceability] = useState<Traceability[]>(null);

    useEffect(() => {
        if (isPDF) {
            getTraceabilityPDF(contractId, false).then((data) => {
                if (data?.codigo === 0) {
                    setTraceability(data.respuesta);
                }
            });
        } else {
            getTraceability(contractId, false).then((data) => {
                if (data?.codigo === 0) {
                    setTraceability(data.respuesta);
                }
            });
        }
    }, []);

    return (
        <>
            <AccordionItemBrand title={"Bitacora"}>
                <>
                    <div className="p-2">
                        <p className="text-lg font-bold text-[#0327e2]">Comentarios</p>
                        <div className="mt-2">
                            <label htmlFor="row">Fila:</label>
                            <Input
                                type="text"
                                id="row"
                                value={comment?.row}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const sanitizedValue = inputValue.replace(/[^0-9,]/g, "");
                                    e.target.value = sanitizedValue; // Actualizar el valor del evento
                                    handleInputChange(e); // Pasar el evento completo
                                }}
                                placeholder="Escriba la fila"
                            />
                        </div>
                        <div className="mt-4">
                            <label htmlFor="priority">Prioridad:</label>
                            <Select id="priority" value={comment?.priority} onChange={handleInputChange}>
                                <option value="high">Alta</option>
                                <option value="medium">Media</option>
                                <option value="low">Baja</option>
                            </Select>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="comment">Comentario:</label>
                            <Textarea
                                id="text"
                                value={comment?.text}
                                onChange={handleInputChange}
                                placeholder="Escribe el comentario"
                            ></Textarea>
                        </div>

                        {error && <p className=" text-red-600 mt-2">{error}</p>}
                        <div className="w-full mt-4 flex flex-col-reverse gap-4">
                            <Button className="!w-full" ref={ref} onClick={onClick}>
                                Ver todos
                            </Button>
                            <Button className="!bg-[#2da12d] !w-full" onClick={handleAddComment}>
                                Agregar
                            </Button>
                        </div>

                        {/* <div className="mt-4">
                            <p>Comentarios Agregados:</p>
                            <ul>
                                {commentsArray?.map((item, index) => (
                                    <li key={index}>
                                        Fila: {item.row}, Prioridad: {item.priority}, Comentario: {item.text}
                                    </li>
                                ))}
                            </ul>
                        </div> */}
                    </div>
                </>
            </AccordionItemBrand>
        </>
    );
};

export default TraceabilityAccordionItems;

const TraceItem = ({ trace, t, withLine = true }: { trace: Traceability; withLine?: boolean; t: any }) => {
    return (
        <li>
            {withLine && <div className="w-24 h-8 border-l-2 ml-6 my-1"></div>}
            <div className="flex items-center">
                <Tooltip label={trace.generalComment}>
                    <img alt="icon" src={getIcon(trace.nameState)} height={50} width={50} />
                </Tooltip>
                <div className="ml-2">
                    <p className="leading-5 text-sm">
                        <b>{trace.user}</b> {t("changedStateTo")}{" "}
                        <span className="font-semibold italic text-gray-600">
                            {trace.nameState === "SI" ? " en boveda" : trace.nameState.split("ion ").join("ión ")}
                        </span>
                    </p>
                    <p className="leading-none italic text-brand-500 font-light text-xs mt-1">{formatDate(new Date(trace.date), true)}</p>
                </div>
            </div>
        </li>
    );
};

function getIcon(nameState: string) {
    let icon;
    try {
        icon = require(`../../assets/traceability-icons/${nameState?.toLowerCase().split(" ").join("_")}.svg`);
    } catch (error) {
        icon = require("../../assets/traceability-icons/en_boveda.svg");
    }

    return icon;
}
