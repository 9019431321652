import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { Contract, ContractType, CreateContractBody } from "../models/Contract.models";
import { Template } from "../models/Template.models";
import { VaultDocumentInfo } from "../models/Vault.models";
import blockchainService from "../services/blockchain.service";
import contractService from "../services/contract.service";
import vaultService from "../services/vault.service";
import useAuthState from "./authState";
import useModalState from "./modalsState";
import useTemplateState from "./templatesState";
import { htmlToBase64 } from "src/lib/utils";

interface State {
    contracts: { loading: boolean; error: boolean; data: Contract[] };
    contractTypes: { loading: boolean; error: boolean; data: ContractType[] };
    currentNewContract: Partial<CreateContractBody>;
    selectedContract: Contract;
    uiOptions: {
        IsInVault: boolean;
        isDraft: boolean;
        isAproved: boolean;
        isRejected: boolean;
        isOnRevision: boolean;
        isPendingSignatures: boolean;
        isActivated: boolean;
        isSigned: boolean;
        showRevisionActions: boolean;
        showRevisionAccordionItems: boolean;
        showTraceabilityAccordionItems: boolean;
        showSignaturesAccordionItems: boolean;
        showPdfViewer: boolean;
        showEditorPanel: boolean;
        showUploadAnnex: boolean;
    };
    hasSavedContract: boolean,
    hasSavedTemplateContract: boolean,
    setHasSavedContract:  (value) => any;
    setHasSavedTemplateContract:  (value) => any;
    setUiOptions: () => any;
    setCurrentNewContract: (contract: Partial<CreateContractBody>) => any;
    setSelectedContract: (contract: Contract) => any;
    initNewContract: () => Partial<CreateContractBody>;
    getContractList: (withLoading?: boolean) => any;
    getContract: (contractId: string, withLoading?: boolean) => Promise<Contract>;
    createContract: (
        body: CreateContractBody,
        withLoading?: boolean
    ) => Promise<{ saved: boolean; contract?: Contract; duplicated: boolean }>;
    cloneContract: (contract: Contract, withLoading?: boolean) => any;
    generateUniqueSerializedName: (contractName: string, withLoading?: boolean) => Promise<any>;
    updateContract: (body: Contract, updateList?: boolean, withLoading?: boolean) => Promise<boolean | { duplicated: boolean }>;
    deleteContract: (contractId: string, withLoading?: boolean) => any;
    getContractTypes: (withLoading?: boolean) => any;
    createContractType: (name: string, withLoading?: boolean) => any;
    deleteContractType: (contractTypeId: string, withLoading?: boolean) => any;
    getTraceability: (contractId: string, withLoading?: boolean) => Promise<any>;
    getTraceabilityPDF: (contractId: string, withLoading?: boolean) => Promise<any>;
    saveOnVault: (pdfBlob: Blob, folderId: string, uuid: string, withLoading?: boolean) => any;
    setIdVault: (createdDocument: any, folderId: string, withLoading?: boolean) => any;
    createFolder: (name: string, withLoading?: boolean) => any;
    createToken: (docId: string, withLoading?: boolean) => any;
    uploadBulkContractFile: (file: File, templateId: string, withLoading?: boolean) => any;
    downloadBulkContractFile: (templateId: string, templateName: string, withLoading?: boolean) => any;
    transformNumberWithPattern: (number: number, pattern: string, withLoading?: boolean) => any;
    transformDateWithPattern: (date: string, pattern: string, withLoading?: boolean) => any;
    reset: () => void;
}

const initialState = {
    contracts: { loading: false, error: false, data: [] },
    contractTypes: { loading: false, error: false, data: [] },
    currentNewContract: null,
    selectedContract: null,
    uiOptions: {
        IsInVault: false,
        isDraft: false,
        isAproved: false,
        isRejected: false,
        isOnRevision: false,
        isPendingSignatures: false,
        isActivated: false,
        isSigned: false,
        showRevisionActions: false,
        showRevisionAccordionItems: false,
        showTraceabilityAccordionItems: false,
        showSignaturesAccordionItems: false,
        showPdfViewer: false,
        showEditorPanel: false,
        showUploadAnnex: false,
    },
    hasSavedContract: false,
    hasSavedTemplateContract: false
};

const useContractState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            setUiOptions: setUiOptions(set, get),
            setCurrentNewContract: setCurrentNewContract(set, get),
            setSelectedContract: setSelectedContract(set, get),
            initNewContract: initNewContract(set, get),
            getContractList: getContractList(set, get),
            getContract: getContract(set, get),
            createContract: createContract(set, get),
            cloneContract: cloneContract(set, get),
            generateUniqueSerializedName: generateUniqueSerializedName(set, get),
            updateContract: updateContract(set, get),
            deleteContract: deleteContract(set, get),
            getContractTypes: getContractTypes(set, get),
            createContractType: createContractType(set, get),
            deleteContractType: deleteContractType(set, get),
            getTraceability: getTraceability(set, get),
            getTraceabilityPDF: getTraceabilityPDF(set, get),
            saveOnVault: saveOnVault(set, get),
            setIdVault: setIdVault(set, get),
            createFolder: createFolder(set, get),
            createToken: createToken(set, get),
            uploadBulkContractFile: uploadBulkContractFile(set, get),
            downloadBulkContractFile: downloadBulkContractFile(set, get),
            transformNumberWithPattern: transformNumberWithPattern(set, get),
            transformDateWithPattern: transformDateWithPattern(set, get),
            setHasSavedContract: setHasSavedContract(set , get),
            setHasSavedTemplateContract: setHasSavedTemplateContract( set, get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "contractState" }
    )
);

export default useContractState;

/* -------------------------------------------------------------------------- */
/*                              SETTERS FUNCTIONS                             */
/* -------------------------------------------------------------------------- */

function setUiOptions(set: NamedSet<State>, get: GetState<State>) {
    return () => {
        set(({ selectedContract }) => ({
            uiOptions: getUiOptions(selectedContract),
        }));
    };
}

function setCurrentNewContract(set: NamedSet<State>, get: GetState<State>) {
    return (contract: CreateContractBody) => {
        set((state) => ({ currentNewContract: contract }));
    };
}

function setSelectedContract(set: NamedSet<State>, get: GetState<State>) {
    return (contract: Contract) => {
        set((state) => ({ selectedContract: contract, uiOptions: getUiOptions(contract) }));
    };
}

function initNewContract(set: NamedSet<State>, get: GetState<State>) {
    return () => {
        const auth = useAuthState.getState();
        const newContract = getEmptyNewContract(auth.companyId, auth.userId, auth.userName);
        set({ currentNewContract: newContract });
        return newContract;
    };
}

function setHasSavedContract(set: NamedSet<State>, get: GetState<State>) {
    return (value) => {
        set({ hasSavedContract: value })
    };
}

function setHasSavedTemplateContract(set: NamedSet<State>, get: GetState<State>) {
    return (value) => {
        set({ hasSavedTemplateContract: value })
    };
}

/* -------------------------------------------------------------------------- */
/*                           PERMISSION MODAL FUNCTION                             */
/* -------------------------------------------------------------------------- */
const msgPermission = "No cuenta con el permiso para esta acción.";
function hasPermissionForAction(reponse: any, msgPermission: string, redirect: boolean) {
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text2: msgPermission,
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                redirectHome: redirect,
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    }
}

/* -------------------------------------------------------------------------- */
/*                             SERVICES FUNCTIONS                             */
/* -------------------------------------------------------------------------- */
function getContractList(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        set((state) => ({ contracts: { loading: true, error: false, data: state.contracts.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await contractService.listContracts(idCompany);

        hasPermissionForAction(res, "Listar contratos", true);

        withLoading && useModalState.getState().setLoaderModal(false);
        const contracts = res.data.respuesta;
        if (contracts !== msgPermission) {
            set({
                contracts: {
                    loading: false,
                    error: false,
                    data: contracts
                        .map((c: any) => {
                            if (c.status === "activado") {
                                c.status = "tokenizado";
                            }
                            return c;
                        })
                        .reverse(),
                },
            });
        } else {
            console.log("Error fetching contracts");
            set((state) => ({ contracts: { loading: false, error: true, data: state.contracts.data } }));
            //clean error
            set((state) => ({ contracts: { ...state.contracts, error: false } }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function getContract(set: NamedSet<State>, get: GetState<State>) {
    return async (contractId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await contractService.getContract(contractId);

        hasPermissionForAction(res, "Obtener contrato", true);

        withLoading && useModalState.getState().setLoaderModal(false);
        //        if (res?.data?.respuesta[0] && res?.data?.mensaje !== msgPermission) {

        if (res?.data?.respuesta[0]) {
            const contract = res?.data?.respuesta[0];
            if (contract.status === "activado") {
                contract.status = "tokenizado";
            }
            get().setSelectedContract(contract);
            return contract;
        } else {
            console.log("Error fetching contract");
            get().setSelectedContract(null);
            return null;
        }
    };
}

function createContract(set: NamedSet<State>, get: GetState<State>) {
    return async (body, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        body.text = await htmlToBase64(body.text);
        body.template.text = await htmlToBase64(body.template.text);

        const res = await contractService.createContract(body);

        hasPermissionForAction(res, "Crear contrato", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.mensaje?.includes("registro ya existe.")) {
            //TODO: revisar
            return { saved: false, duplicated: true };
        } else if (res?.data?.codigo === 0) {
            get().getContractList(false);
            return { saved: true, contract: res?.data?.respuesta[0], duplicated: false };
        } else {
            console.log("Error creating contract");
            return { saved: false, duplicated: false };
        }
    };
}

function cloneContract(set: NamedSet<State>, get: GetState<State>) {
    return async (contract: Contract, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const contractList = get().contracts.data;
        let copyNumber = 1;
        while (contractList.find((c) => c.name === `${contract.name?.split("(copy")[0]?.trim()} (copy-${copyNumber})`)) {
            copyNumber++;
        }

        let contractName = `${contract.name?.split("(copy")[0]?.trim()} (copy-${copyNumber})`;
        const template = await useTemplateState.getState().getTemplate(contract.idTemplate, false, true);
        if (!template) return false;

        const body: CreateContractBody = {
            idCompany: contract.idCompany,
            idTemplate: contract.idTemplate,
            idUser: contract.idUser,
            name: contractName,
            status: "borrador",
            template: template as Template,
            text: contract.text,
            userName: contract.userName,
        };

        body.template.text = await htmlToBase64(body.template.text);

        const res = await contractService.createContract(body);

        hasPermissionForAction(res, "Clonar contrato", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.mensaje?.includes("registro ya existe.")) {
            //TODO: revisar
            return { duplicated: true };
        } else if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating contract");
            return false;
        }
    };
}

function generateUniqueSerializedName(set: NamedSet<State>, get: GetState<State>) {
    return async (contractName: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const contractList = get().contracts.data;
        let copyNumber = 1;
        while (contractList.find((c) => c.name === `${contractName.split("(")[0]?.trim()} (${copyNumber})`)) {
            copyNumber++;
        }

        let contractSerializedName = `${contractName?.split("(")[0]?.trim()} (${copyNumber})`;
        let serializedName = contractSerializedName;
        withLoading && useModalState.getState().setLoaderModal(false);

        if (contractSerializedName) {
            return serializedName;
        } else {
            return contractName;
        }
    };
}

function updateContract(set: NamedSet<State>, get: GetState<State>) {
    return async (body: Contract, updateList = true, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        body.text = await htmlToBase64(body.text);
        body.template.text = await htmlToBase64(body.template.text);

        const res = await contractService.updateContract(body);

        hasPermissionForAction(res, "Actualizar contrato", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.mensaje?.includes("registro ya existe.")) {
            //TODO: revisar
            return { duplicated: true };
        } else if (res?.data?.codigo === 0) {
            updateList && get().getContractList(false);
            return true;
        } else {
            console.log("Error updating contract");
            return false;
        }
    };
}

function deleteContract(set: NamedSet<State>, get: GetState<State>) {
    return async (id, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await contractService.deleteContract(id);
        hasPermissionForAction(res, "Borrar contrato", false);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            //set({ contracts: { ...currentContracts, data: currentContracts.data?.filter((c) => c.id !== id) } });
            return true;
        } else {
            console.log("Error deleting contract");
            return false;
        }
    };
}

function getContractTypes(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        set((state) => ({ contractTypes: { loading: true, error: false, data: state.contractTypes.data } }));
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await contractService.listContractTypes(idCompany);

        hasPermissionForAction(res, "Listar tipos de documentos", false);

        if (res?.data?.respuesta) {
            set({
                contractTypes: {
                    loading: false,
                    error: false,
                    data: res.data.respuesta?.sort((a, b) => (a.description?.toLowerCase() < b.description?.toLowerCase() ? -1 : 1)),
                },
            });
        } else {
            console.log("Error fetching contractTypes");
            set((state) => ({
                contractTypes: {
                    loading: false,
                    error: true,
                    data: state.contractTypes.data?.sort((a, b) => (a.description?.toLowerCase() < b.description?.toLowerCase() ? -1 : 1)),
                },
            }));
            //clean error
            set((state) => ({ contractTypes: { ...state.contractTypes, error: false } }));
        }
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function createContractType(set: NamedSet<State>, get: GetState<State>) {
    return async (name, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await contractService.createContractType({ active: true, description: name, idCompany });

        hasPermissionForAction(res, "Crear tipo de contrato", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getContractTypes(false);
            return true;
        } else {
            console.log("Error creating contractType");
            return false;
        }
    };
}

function deleteContractType(set: NamedSet<State>, get: GetState<State>) {
    return async (id, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await contractService.deleteContractType(id);

        hasPermissionForAction(res, "Eliminar tipo de contrato", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            get().getContractTypes(false);
            return true;
        } else {
            console.log("Error deleting contractType");
            return false;
        }
    };
}

function getTraceability(set: NamedSet<State>, get: GetState<State>) {
    return async (contractId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res: any = await contractService.getTraceability(contractId);

        hasPermissionForAction(res, "Obtener trazabilidad del documento", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        return res.data;
    };
}

function getTraceabilityPDF(set: NamedSet<State>, get: GetState<State>) {
    return async (contractId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res: any = await contractService.getTraceabilityPDF(contractId);

        hasPermissionForAction(res, "Obtener trazabilidad de documento PDF", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        return res.data;
    };
}

function saveOnVault(set: NamedSet<State>, get: GetState<State>) {
    return async (pdfBlob, folderId, uuid, withLoading = true) => {
        const userId = useAuthState.getState().userId;
        const selectedContract = get().selectedContract;
        withLoading && useModalState.getState().setLoaderModal(true);

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(pdfBlob);
            reader.onloadend = async function () {
                const base64data = reader.result;
                const documentTemplate: any = {
                    companyId: selectedContract?.idCompany,
                    createdBy: userId,
                    description: "contract document",
                    extension: "pdf",
                    name: selectedContract?.name,
                    fileBase64: uuid,
                    folderId,
                };

                try {
                    const createdDocument = await vaultService.createDocument(documentTemplate);
                    hasPermissionForAction(createdDocument, "Guardar documento en bóveda", false);

                    if (createdDocument.status === 200) {
                        await get().setIdVault(createdDocument, folderId, false);

                        get().setSelectedContract({
                            ...selectedContract!,
                            idVault: createdDocument.data.id,
                            idFolder: folderId,
                            status: "en boveda",
                        });
                        await get().getContract(selectedContract?.id, false);
                        resolve({});
                    }
                } catch (error) {
                    reject(error);
                }

                withLoading && useModalState.getState().setLoaderModal(false);
            };
        });
    };
}

function setIdVault(set: NamedSet<State>, get: GetState<State>) {
    return async (createdDocument, folderId, withLoading = true) => {
        const selectedContract = get().selectedContract;
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await contractService.setIdVault({
            idContract: selectedContract?.id,
            idVault: createdDocument.data.id,
            idFolder: folderId,
        });

        hasPermissionForAction(res, "Guardar documento en bóveda", false);

        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function createToken(set: NamedSet<State>, get: GetState<State>) {
    return async (docId, withLoading = true) => {
        const selectedContract = get().selectedContract;
        withLoading && useModalState.getState().setLoaderModal(true);

        const docInfo: VaultDocumentInfo = (await vaultService.getDocumentInfo(docId)).data;

        if (docInfo?.ipfsHash) {
            const blockchainBody = {
                _author: docInfo.userBy,
                _hashIpfs: docInfo.ipfsHash,
                _hashSum256: docInfo.sha256sum,
                _nameDocument: docInfo.name,
                _status: true,
                _tokenId: docInfo.id,
                _typeContract: selectedContract?.type,
            };

            const res = await blockchainService.setDocumentOnBlockchain(blockchainBody);

            hasPermissionForAction(res, "Generar token documento", false);

            if (res?.data?.codigo === 0) {
                const activateRes = await contractService.activateContract(selectedContract?.id);
                withLoading && useModalState.getState().setLoaderModal(false);
                if (activateRes?.data?.codigo !== 0) {
                    console.log("Error on token activation");
                    return null;
                }
                return res.data.respuesta;
            } else {
                console.log("Error creating token");
                withLoading && useModalState.getState().setLoaderModal(false);
                return null;
            }
        } else {
            console.log("Error getting document info");
            withLoading && useModalState.getState().setLoaderModal(false);
            return null;
        }
    };
}

function createFolder(set: NamedSet<State>, get: GetState<State>) {
    return async (name, withLoading = true) => {
        const companyId = useAuthState.getState().companyId;
        withLoading && useModalState.getState().setLoaderModal(true);
        const res: any = await vaultService.createFolder({
            isActive: true,
            name,
            companyId,
        });
        withLoading && useModalState.getState().setLoaderModal(false);
        return res;
    };
}

function uploadBulkContractFile(set: NamedSet<State>, get: GetState<State>) {
    return async (file, templateId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const body = new FormData();
        body.append("file", file);
        const res = await contractService.uploadBulkContractFile(body, templateId);
        withLoading && useModalState.getState().setLoaderModal(false);
        if (res.data?.codigo === 0) {
            return true;
        } else {
            return false;
        }
    };
}

function downloadBulkContractFile(set: NamedSet<State>, get: GetState<State>) {
    return async (templateId, templateName, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await contractService.downloadBulkContractFile(templateId);
        withLoading && useModalState.getState().setLoaderModal(false);
        if (res.data) {
            const link = document.createElement("a");
            link.href = "data:application/vnd.ms-excel;base64," + res.data;
            link.setAttribute("download", templateName + ".xls");
            document.body.appendChild(link);
            link.click();
            return true;
        } else {
            return false;
        }
    };
}

function transformNumberWithPattern(set: NamedSet<State>, get: GetState<State>) {
    return async (number, pattern, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const payload = {
            number,
            pattern,
        };
        const res = await contractService.transformNumberWithPatten(payload);
        withLoading && useModalState.getState().setLoaderModal(false);
        if (res.data) {
            return res.data.respuesta;
        }
    };
}

function transformDateWithPattern(set: NamedSet<State>, get: GetState<State>) {
    return async (date, pattern, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const payload = {
            specificDate: date,
            stringWithPattern: pattern,
        };
        const res = await contractService.transformDateWithPatten(payload);
        withLoading && useModalState.getState().setLoaderModal(false);
        if (res.data) {
            return res.data.respuesta;
        }
    };
}

/* -------------------------------------------------------------------------- */
/*                             AUXILIAR FUNCTIONS                             */
/* -------------------------------------------------------------------------- */
function getEmptyNewContract(idCompany: string, idUser: string, userName: string): Partial<CreateContractBody> {
    return {
        name: "",
        idCompany,
        idUser,
        userName,
        idTemplate: "",
        status: "borrador",
        text: "",
        template: null,
    };
}

function getUiOptions(selectedContract: Contract) {
    return {
        IsInVault: selectedContract?.status === "en boveda",
        isDraft: selectedContract?.status === "borrador",
        isAproved: selectedContract?.status === "aprobado",
        isRejected: selectedContract?.status === "rechazado",
        isOnRevision: selectedContract?.status === "revision",
        isPendingSignatures: selectedContract?.status === "pendiente de firmas",
        isActivated: selectedContract?.status === "tokenizado",
        isSigned: selectedContract?.status === "firmado",
        showRevisionActions:
            selectedContract?.status === "revision" || selectedContract?.status === "rechazado" || selectedContract?.status === "aprobado",
        showRevisionAccordionItems:
            selectedContract?.status === "revision" || selectedContract?.status === "rechazado" || selectedContract?.status === "aprobado",
        showTraceabilityAccordionItems: !!selectedContract?.id,
        showSignaturesAccordionItems: selectedContract?.status === "pendiente de firmas",
        showEditorPanel: selectedContract?.status === "rechazado" || selectedContract?.status === "revision",
        showPdfViewer:
            selectedContract?.status === "en boveda" ||
            selectedContract?.status === "aprobado" ||
            selectedContract?.status === "pendiente de firmas" ||
            selectedContract?.status === "firmado" ||
            selectedContract?.status === "tokenizado",
        showUploadAnnex:
            selectedContract?.status === "en boveda" ||
            selectedContract?.status === "pendiente de firmas" ||
            selectedContract?.status === "firmado" ||
            selectedContract?.status === "tokenizado",
    };
}
