import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { useMemo, useState } from "react";
import { Template, TemplateStatus } from "../../models/Template.models";

const useTemplatesFilter = (templates: Template[]) => {
    const [filterDates, setFilterDates] = useState<{ from: Date; to: Date }>(null);
    const [filterStatus, setFilterStatus] = useState<TemplateStatus>(null);
    const [filterText, setFilterText] = useState<string>(null);
    const [dateFilterField, setDateFilterField] = useState<TimeStampField>(null);
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

    function handleDateFilter({ from, to }: { from: any; to: any }, dateField: TimeStampField) {
        setIsFilterActive(true);
        setFilterDates({ from: from ? new Date(from) : null, to: to ? new Date(to) : null });
        setDateFilterField(dateField);
    }

    function handleStatusFilter(status: TemplateStatus) {
        if (status) {
            setIsFilterActive(true);
            setFilterStatus(status);
        } else {
            setIsFilterActive(false);
            setFilterStatus(null);
        }
    }

    function handleSearchFilter(text: string) {
        if (text) {
            setFilterText(text);
        } else {
            setFilterText(null);
        }
    }

    function clearFilters() {
        setIsFilterActive(false);
        setFilterDates(null);
        setDateFilterField(null);
        setFilterStatus(null);
    }

    const filteredTemplates = useMemo(() => {
        let filtered = templates;
        if (filterDates?.from && filterDates?.to) {
            //is between
            filtered = filtered.filter(
                (t) =>
                    isAfter(new Date(t[dateFilterField]), startOfDay(filterDates.from)) &&
                    isBefore(new Date(t[dateFilterField]), endOfDay(filterDates.to))
            );
        } else if (filterDates?.from) {
            //is after
            filtered = filtered.filter((t) => isAfter(new Date(t[dateFilterField]), startOfDay(filterDates.from)));
        } else if (filterDates?.to) {
            //is before
            filtered = filtered.filter((t) => isBefore(new Date(t[dateFilterField]), endOfDay(filterDates.to)));
        }

        if (filterStatus) {
            filtered = filtered.filter((t) => t.status === filterStatus);
        }

        if (filterText) {
            filtered = filtered.filter(
                (t) =>
                    t.name.toLowerCase().includes(filterText.toLowerCase()) ||
                    t.userName.toLowerCase().includes(filterText.toLowerCase()) ||
                    t.type.toLowerCase().includes(filterText.toLowerCase())
            );
        }

        return filtered;
    }, [templates, filterDates, dateFilterField, filterStatus, filterText]);

    return { handleDateFilter, filteredTemplates, isFilterActive, clearFilters, handleStatusFilter, handleSearchFilter };
};

export default useTemplatesFilter;
