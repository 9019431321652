import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Stack,
  IconButton,
  FormControl,
  FormLabel,
  Text,
  Box,
} from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";

const ModalReq = ({ isOpen, onClose }) => {
  const [requerimientos, setRequerimientos] = useState([]);
  const [nombre, setNombre] = useState("");
  const [nombreRequerimiento, setNombreRequerimiento] = useState("");

  const agregarRequerimiento = () => {
    if (nombreRequerimiento) {
      setRequerimientos([...requerimientos, { nombre, nombreRequerimiento, dependencias: [] }]);
      setNombre("");
      setNombreRequerimiento("");
    } else {
      alert("Por favor, ingrese un nombre y un nombre de requerimiento.");
    }
  };

  const quitarRequerimiento = (index) => {
    const nuevaLista = [...requerimientos];
    nuevaLista.splice(index, 1);
    setRequerimientos(nuevaLista);
  };

  const agregarDependencia = (index) => {
    const dependenciaNombre = prompt("Ingrese el nombre de la dependencia:");
    if (dependenciaNombre) {
      const nuevaLista = [...requerimientos];
      nuevaLista[index].dependencias.push(dependenciaNombre);
      setRequerimientos(nuevaLista);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Asociar Requerimientos</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl>
                {/** 
                 *               <FormLabel>Nombre:</FormLabel>
                <Input
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
              />*/}
             
            </FormControl>
            <FormControl>
              <FormLabel>Nombre del Requerimiento:</FormLabel>
              <Input
                value={nombreRequerimiento}
                onChange={(e) => setNombreRequerimiento(e.target.value)}
              />
            </FormControl>
            <Button
              colorScheme="blue"
              onClick={agregarRequerimiento}
              leftIcon={<MdAdd />}
            >
              Agregar Requerimiento
            </Button>
            {requerimientos.map((requerimiento, index) => (
  <Box
    key={index}
    p={4}
    borderWidth="1px"
    borderRadius="lg"
    borderColor="gray.200"
    bg="blue.50"
    textAlign="center"
    mb={4}
  >
 <div style={{ display: 'flex', alignItems: 'center' }}>
  <Text fontSize="xl" fontWeight="bold" mb={1} ml={16}>
    {requerimiento.nombreRequerimiento}
  </Text>
  <IconButton
    colorScheme="red"
    aria-label="Eliminar"
    onClick={() => quitarRequerimiento(index)}
    icon={<MdOutlineClose />}
    variant="outline"
    size="sm"
    ml={6}  />
</div>

    <Button
      onClick={() => agregarDependencia(index)}
      colorScheme="blue"
      size="sm"
      mt={2}
    >
      Agregar Dependencia
    </Button>
    <Text fontSize="lg" mt={4} mb={2}>
      Documentos asociados:
    </Text>
    <ul>
      {requerimiento.dependencias.map((documento, i) => (
        <li key={i}>
          <Text fontSize="md">{documento}</Text>
        </li>
      ))}
    </ul>
  </Box>
))}

          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cerrar
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Guardar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalReq;
