import React, { useState, useEffect } from 'react';

function YourComponent({ data, previouslySelected,handleChangeAssociateRequeriments }) {
  const [selectedNames, setSelectedNames] = useState([]);

  useEffect(() => {
  //  setSelectedNames(previouslySelected);
  }, [previouslySelected]);

  const handleCheckboxChange = (name) => {
    if (selectedNames.includes(name)) {
      setSelectedNames(selectedNames.filter(item => item !== name));
    } else {
      setSelectedNames([...selectedNames, name]);
    }
  };
console.log("selectedNames",selectedNames)
  return (
<div className="p-6 border rounded-lg shadow-lg w-full max-w-2xl overflow-y-auto bg-white">
  <h2 className="text-2xl font-semibold mb-4">Lista de Requerimientos:</h2>
  <div className="max-h-64 w-full overflow-y-auto">
    {data.map((item, index) => (
      <div key={index} className="flex items-center mb-4">
        <input
          type="checkbox"
          id={`checkbox-${index}`}
          checked={selectedNames.includes(item.name)}
          onChange={() => handleCheckboxChange(item.name)}
          className="mr-4 h-5 w-5 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
        />
        <label htmlFor={`checkbox-${index}`} className="text-lg">{item.name}</label>
      </div>
    ))}
    {data.length > 0 ? (
        data.map((item, index) => (
            <div key={index} className="flex items-center mb-4">
            <input
                type="checkbox"
                id={`checkbox-${index}`}
                checked={selectedNames.includes(item.name)}
                onChange={() => handleCheckboxChange(item.name)}
                className="mr-4 h-5 w-5 text-blue-500 focus:ring-blue-400 border-gray-300 rounded"
            />
            <label htmlFor={`checkbox-${index}`} className="text-lg">{item.name}</label>
            </div>
        ))
    ) : (
      <p className="text-gray-500">No hay requerimientos.</p>
    )}
  </div>
  <h2 className="text-2xl font-semibold mt-8 mb-4">Documentos Dependientes:</h2>
  <div className="border rounded-lg p-4 max-h-40 overflow-y-auto bg-gray-100">
    {selectedNames.length > 0 ? (
      <ul className="list-disc list-inside">
        {selectedNames.map((name, index) => (
          <li key={index} className="text-lg">{name}</li>
        ))}
      </ul>
    ) : (
      <p className="text-gray-500">No hay iniciativas seleccionadas.</p>
    )}
  </div>
  <button 
    className={`mt-8  w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded ${selectedNames.length === 0 ? "opacity-50 cursor-not-allowed" : ""}`}
    onClick={() => handleChangeAssociateRequeriments(selectedNames)} // Agrega la función handleAssociate para asociar las iniciativas seleccionadas
    disabled={selectedNames.length === 0} // Deshabilita el botón si no hay iniciativas seleccionadas
  >
    Asociar Requerimiento
  </button>
</div>



  
  );
}

export default YourComponent;
