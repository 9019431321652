import { Routes, Route, Navigate } from "react-router-dom";
import ContractsPage from "../pages/contracts/ContractsPage";
import DataGroupsPage from "../pages/data-groups/DataGroupsPage";
import HomePage from "../pages/HomePage";
import OnboardingPage from "../pages/OnboardingPage";
import LoginPage from "../pages/auth/LoginPage";
import TemplatesPage from "../pages/templates/TemplatesPage";
import PrivateRoute from "./PrivateRoute";
import CreateDataGroupsPage from "../pages/data-groups/CreateDataGroupPage";
import CreateTemplatePage from "../pages/templates/CreateTemplatePage";
import CreateContractPage from "../pages/contracts/CreateContractPage";
import ShowEditDataGroupsPage from "../pages/data-groups/ShowEditDataGroupPage";
import ShowEditTemplatePage from "../pages/templates/ShowEditTemplatePage";
import ShowEditContractPage from "../pages/contracts/ShowEditContractPage";
import VaultPage from "../pages/vault/VaultPage";
import React from "react";
import AdministrationPage from "src/pages/AdministrationPage";
import BiddingPage from "src/pages/bidding/BiddingsPage";
import ShowBiddingPage from "src/pages/bidding/ShowBiddingPage";
import BiddingTypesPage from "src/pages/bidding/BiddingTypesPage";
import ShowEditPdfPage from "src/pages/contracts/ShowEditPdfPage";
import ShowRevisionDocumentPage from "src/pages/contracts/ShowRevisionDocumentPage";
import AssignUserGroupsPage from "src/pages/user-groups/AssignUserGroupsPage";
import ShowEditWorkSheetPage from "src/pages/worksheets/ShowEditWorkSheetPage";
import OtpPage from "src/pages/auth/OtpPage";
import ResetPasswordPage from "src/pages/auth/ResetPasswordPage";
import GroupSelection from "src/pages/GroupSelectionPage";

const MeetPage = React.lazy(() => import("../pages/videocall/MeetPage"));
const ContractRevisionPage = React.lazy(() => import("../pages/contract-revision/ContractRevisionPage"));
const DocumentRevisionPage = React.lazy(() =>import ("../pages/contract-revision/DocumentRevisionPage"));
const RegisterPage = React.lazy(() => import("../pages/auth/RegisterPage"));
const ForgotPasswordPage = React.lazy(() => import("../pages/auth/ForgotPasswordPage"));
const NewPasswordPage = React.lazy(() => import("../pages/auth/NewPasswordPage"));

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/">
                {/* auth */}
                <Route path="login" element={<LoginPage />} />
                <Route path="otp" element={<OtpPage />} />
                <Route
                    path="register"
                    element={
                        <React.Suspense fallback={<FallBack />}>
                            <RegisterPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="forgot-password"
                    element={
                        <React.Suspense fallback={<FallBack />}>
                            <ForgotPasswordPage />
                        </React.Suspense>
                    }
                />
                <Route
                    path="new-password"
                    element={
                        <React.Suspense fallback={<FallBack />}>
                            <NewPasswordPage />
                        </React.Suspense>
                    }
                />

                <Route
                    path="resetPassword"
                    element={
                        <React.Suspense fallback={<FallBack />}>
                            <ResetPasswordPage />
                        </React.Suspense>
                    }
                />

                {/* main */}
                <Route index element={makePrivate(<HomePage />)} />

                <Route path="group-selection">
                    <Route index element={makePrivate(<GroupSelection />)} />
                </Route>

                <Route path="onboarding">
                    <Route index element={makePrivate(<OnboardingPage />)} />
                </Route>

                <Route path="templates">
                    <Route index element={makePrivate(<TemplatesPage />)} />
                    <Route path="create" element={makePrivate(<CreateTemplatePage />)} />
                    <Route path=":id" element={makePrivate(<ShowEditTemplatePage />)} />
                </Route>

                <Route path="data-groups">
                    <Route index element={makePrivate(<DataGroupsPage />)} />
                    <Route path="create" element={makePrivate(<CreateDataGroupsPage />)} />
                    <Route path=":id" element={makePrivate(<ShowEditDataGroupsPage />)} />
                </Route>

                <Route path="contracts">
                    <Route index element={makePrivate(<ContractsPage />)} />
                    <Route path="create" element={makePrivate(<CreateContractPage />)} />
                    <Route path=":id" element={makePrivate(<ShowEditContractPage />)} />
                </Route>

                <Route path="vault">
                    <Route index element={makePrivate(<VaultPage />)} />
                </Route>

                <Route path="administration">
                    <Route index element={makePrivate(<AdministrationPage />)} />
                </Route>

                <Route path="biddings">
                    <Route index element={makePrivate(<BiddingPage />)} />
                    <Route path="types" element={makePrivate(<BiddingTypesPage />)} />
                    <Route path=":id" element={makePrivate(<ShowBiddingPage />)} />
                </Route>

                <Route path="pdfs">
                    {/*<Route index element={makePrivate(<BiddingPage />)} />*/}
                    {/** <Route path="types" element={makePrivate(<BiddingTypesPage />)} />*/}
                    <Route path=":id/bidding/:biddingId/uuid/:uuid" element={makePrivate(<ShowEditPdfPage />)} />
                </Route>

                <Route path="documents">
                    {/*<Route index element={makePrivate(<BiddingPage />)} />*/}
                    {/** <Route path="types" element={makePrivate(<BiddingTypesPage />)} /> ShowRevisionDocumentPage*/}
                    <Route path=":id/bidding/:biddingId/uuid/:uuid" element={makePrivate(<ShowEditPdfPage />)} />
                </Route>

                <Route path="worksheets">
                    {/*<Route index element={makePrivate(<BiddingPage />)} />*/}
                    {/** <Route path="types" element={makePrivate(<BiddingTypesPage />)} />*/}
                    <Route path=":id/bidding/:biddingId/uuid/:uuid" element={makePrivate(<ShowEditWorkSheetPage />)} />
                </Route>

                <Route path="groups-users">
                    {/* <Route index element={makePrivate(<UserGroupsPage />)} /> */}
                    <Route index element={makePrivate(<AssignUserGroupsPage />)} />
                    {/*<Route index element={makePrivate(<BiddingPage />)} />*/}
                    {/** <Route path="types" element={makePrivate(<BiddingTypesPage />)} />*/}
                    {/* <Route path=":id" element={makePrivate(<UserGroupsPage />)} /> */}
                </Route>

                {/* others */}
                
                <Route
                    path="contract-revision/:contractId"
                    element={makePrivate(
                        <React.Suspense fallback={<FallBack />}>
                            <ContractRevisionPage />
                        </React.Suspense>
                    )}
                />

                <Route
                    path="document-revision/:contractId"
                    element={makePrivate(
                        <React.Suspense fallback={<FallBack />}>
                            <DocumentRevisionPage />
                        </React.Suspense>
                    )}
                />
                {/* externals */}
                <Route
                    path="external/contract-revision/:data"
                    element={
                        <React.Suspense fallback={<FallBack />}>
                            <ContractRevisionPage isExternal />
                        </React.Suspense>
                    }
                />
                 <Route
                    path="external/document-revision/:data"
                    element={
                        <React.Suspense fallback={<FallBack />}>
                            <DocumentRevisionPage isExternal />
                        </React.Suspense>
                    }
                />
                <Route
                    path="external/meet/:sessionId"
                    element={
                        <React.Suspense fallback={<FallBack />}>
                            <MeetPage />
                        </React.Suspense>
                    }
                />
            </Route>

            {/* 404 route redirect to home */}
            <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
    );
};

export default AppRouter;

function makePrivate(children) {
    return <PrivateRoute>{children}</PrivateRoute>;
}

function FallBack() {
    return <>...</>;
}
