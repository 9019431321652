import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

const documentAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1`,
    timeout: 20000,
});

const getSubscriptionBidding = async (biddingId: string) => {
    try {
        return await documentAxiosRequest.get(`/subscription/componentId/${biddingId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getSubscriptionBiddingType = async (biddingId: string) => {
    try {
        return await documentAxiosRequest.get(`/subscription/componentTemplateId/${biddingId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getSubscriptionbyId = async (biddingId: string) => {
    try {
        return await documentAxiosRequest.get(`/subscription/id/${biddingId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createSubscriptionBidding = async (body: any) => {
    try {
        return await documentAxiosRequest.put(`/subscription`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateSubscriptionBidding = async (body: any) => {
    try {
        return await documentAxiosRequest.patch(`/subscription`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateSubscriptionBiddingUserList = async (biddingId: string, body: any) => {
    try {
        return await documentAxiosRequest.patch(`/subscription/userIdList/id/${biddingId}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deleteSubscriptionBidding = async (id: string) => {
    try {
        return await documentAxiosRequest.delete(`/subscription/deactivate/${id}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    getSubscriptionBidding,
    getSubscriptionBiddingType,
    getSubscriptionbyId,
    createSubscriptionBidding,
    updateSubscriptionBidding,
    deleteSubscriptionBidding,
    updateSubscriptionBiddingUserList,
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
