import {Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react";

type Props = {
    title: string;
    children: JSX.Element;
    subTitle?: JSX.Element;
    order?: "start" | "between";
    actions?: JSX.Element;
    isValid?: boolean;
    disabled?: boolean;
};
const AccordionItemBrand = ({ title, children, actions, subTitle, order = "start", isValid, disabled }: Props) => {
    return (
        <AccordionItem isDisabled={isValid}>
            <AccordionButton
                justifyContent={order === "between" ? "space-between" : "unset"}
                _focus={{ outline: "none" }}
                _expanded={{ bg: "gray.100" }}
                _hover={{ bg: "gray.100" }}
                //style={{backgroundColor:"whiteSmoke"}}
            >
                <span style={{ order: order === "start" ? 3 : 1 }} className="pl-2 text-base font-bold text-brand-500 text-left grow">
                    {title}
                </span>

                <AccordionIcon order={order === "start" ? 1 : 3} color={"brand.500"} />
                <div style={{ paddingRight: "10rem" }} className="order-[3] justify-self-end">
                    {subTitle}
                </div>
                <div className="order-[3] justify-self-end">{actions}</div>
            </AccordionButton>
            <AccordionPanel px={0} pb={4}>
                {disabled ? <p className="pl-12 pt-2 text-[#2227e2] font-bold">No cuentas con acceso a este grupo</p> : children}
            </AccordionPanel>
        </AccordionItem>
    );
};

export default AccordionItemBrand;
