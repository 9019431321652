import { Tooltip } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import useCustomToast from "../../hooks/useCustomToast";
import usePagination from "../../hooks/usePagination";
import { UserGroup } from "../../models/Aministration.models";
import useAdministrationState from "../../store/administrationState";
import { BiUserPlus } from "react-icons/bi";
import useModalState from "../../store/modalsState";
import CommonTh from "../common/table/CommonTh";
import LinkButton from "../common/buttons/LinkButton";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { AiOutlineEdit } from "react-icons/ai";
import DottedSubtitle from "../common/DottedSubtitle";
import Paginator from "../common/Paginator";
import { useNavigate } from "react-router-dom";

const TableRow = ({
    userGroup,
    onDelete,
    onEdit,
}: {
    userGroup: UserGroup;
    onDelete: (userGroup: UserGroup) => any;
    onEdit: (userGroup: UserGroup) => any;
}) => {
    const navigate = useNavigate();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 py-3" onClick={() => navigate("/groups-users")}>{`${userGroup.name}`}</td>
            <td className="p-2 py-3" onClick={() => navigate("/groups-users")}>
                {userGroup.description}
            </td>
            <td className="px-2 py-3">
                <div className="w-full xl:w-7/12 flex gap-6">
                    <Tooltip label={t("edit")} fontSize="12px">
                        <button onClick={() => onEdit(userGroup)}>
                            <AiOutlineEdit size={25} />
                        </button>
                    </Tooltip>
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={() => onDelete(userGroup)}>
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const GenerateUsersTable = ({
    userGroup,
    onDelete,
    onEdit,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
}: {
    userGroup: UserGroup[];
    onDelete: (userGroup: UserGroup) => any;
    onEdit: (userGroup: UserGroup) => any;
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <div className="w-full">
            <table className="w-full text-sm text-left text-dark-2 cursor-pointer">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("description")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("actions")} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {userGroup?.map((item) => (
                        <TableRow key={item.id} userGroup={item} onDelete={onDelete} onEdit={onEdit} />
                    ))}
                </tbody>
            </table>
            {userGroup?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

const UsersGroupAdmin = () => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [searchQuery, setSearchQuery] = useState("");
    const { setNewEditUsersGroupModal, setDeleteCommonModal } = useModalState();

    const {
        companyUsersGroups: { data: usersGroups },
        getCompanyUserGroups,
        deleteUserGroup,
    } = useAdministrationState();

    const filteredResults = useMemo(() => {
        const filteredGroups = usersGroups?.filter((userGroup) => userGroup.name.toLowerCase().includes(searchQuery.toLowerCase()));
        return filteredGroups?.slice();
    }, [searchQuery, usersGroups]);

    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({ data: filteredResults, itemsPerPage: 6 });

    async function onDelete(userGroup: UserGroup) {
        //interface
        const deleted = await deleteUserGroup(userGroup.id);
        if (deleted) {
            toast.success(t("userDeletedSuccessfully"));
            getCompanyUserGroups();
        } else {
            toast.error(t("deletingUserError"));
        }
    }

    function handleDelete(userGroup: UserGroup) {
        setDeleteCommonModal({
            show: true,
            data: { text1: t("userGroupWillBeDeleted"), text2: `${userGroup?.name}`, onDelete: () => onDelete(userGroup) },
        });
    }

    function handleEdit(userGroup: UserGroup) {
        setNewEditUsersGroupModal({ show: true, data: { type: "edit", userGroup: userGroup } });
    }

    useEffect(() => {
        getCompanyUserGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-4 mb-4">
                <DottedSubtitle
                    text={t("usersGroup")}
                    withSearch
                    onSearch={setSearchQuery}
                    beforeSearchElement={
                        <LinkButton
                            leftIcon={<BiUserPlus size={20} />}
                            onClick={() => setNewEditUsersGroupModal({ show: true, data: { type: "new" } })}
                        >
                            {t("addNewUserGroup")}
                        </LinkButton>
                    }
                />
            </div>

            <GenerateUsersTable
                userGroup={currentResutls}
                onDelete={handleDelete}
                onEdit={handleEdit}
                currentPage={currentPage}
                maxPages={maxPages}
                prev={prev}
                next={next}
                setPage={setPage}
            />
        </>
    );
};

export default UsersGroupAdmin;
