import { Button } from "@chakra-ui/react";
import useCustomToast from "../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useContractRevisionState from "../../../store/contractRevisionState";
import useContractState from "../../../store/contractState";
type Props = {
    onCancelRevision: (arg: any) => any;
    onSaveOnVault: (arg: any) => any;
};

const RevisionActions = ({ onCancelRevision, onSaveOnVault }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const uiOptions = useContractState((s) => s.uiOptions);
    const { currentReviewerEditionChanges, cancelRevision, createRevision, currentRevisionStatus, setCurrentReviewerEditionChanges } =
        useContractRevisionState();
    const { updateContract, selectedContract, getContract } = useContractState();
    const toast = useCustomToast();

    const showingChanges = Boolean(currentReviewerEditionChanges);

    async function handleApplyChanges() {
        //update contract
        const saved = await updateContract({ ...selectedContract, text: currentReviewerEditionChanges });
        if (!saved) {
            return toast.error("Error al aplicar cambios");
        }
        //cancel revision
        const canceled = await cancelRevision(selectedContract.id);
        if (!canceled) {
            return toast.error("Error al cancelar revisión");
        }
        //send revision
        const created = await createRevision(
            selectedContract.id,
            currentRevisionStatus?.userReviewerList?.map((user) => ({
                answer: "",
                answered: false,
                comment: "",
                editor: user.editor,
                idAnswer: null,
                idUser: user.idUser,
                userEmail: user.userEmail,
                userName: user.userName,
                secuence: user.secuence,
                isExternal: false, //TODO: revisar
            }))
        );
        if (!created) {
            return toast.error("Error al enviar revisión");
        }
        setCurrentReviewerEditionChanges("");
        getContract(selectedContract.id);
        toast.success("Cambios aplicados correctamente");
    }
console.log("hsahs")
    return (
        <div className={`flex ${showingChanges ? "justify-between" : "justify-center"} gap-2 pt-4 pb-2 sticky bottom-0 bg-white border-t`}>
            {uiOptions.isRejected ? (
                <Button rounded="full" size="md" fontWeight="medium" variant="solid" onClick={onCancelRevision}>
                    {t("cancelRevision")}
                </Button>
            ) : uiOptions.isAproved ? (
                <Button rounded="full" size="md" fontWeight="medium" variant="solid" onClick={onSaveOnVault}>
                    {t("saveInVault")}
                </Button>
            ) : (
                <Button rounded="full" size="md" fontWeight="medium" variant="solid" onClick={onCancelRevision}>
                    {t("cancelRevision")}
                </Button>
            )}
            {showingChanges && (
                <Button rounded="full" size="md" fontWeight="medium" variant="outline" onClick={handleApplyChanges}>
                    {t("applyChanges")}
                </Button>
            )}
        </div>
    );
};

export default RevisionActions;
