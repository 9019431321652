import { FaEye } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import LinkButton from "./LinkButton";

const PreviewLinkButton = ({ onClick }) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <LinkButton leftIcon={<FaEye size={18} />} onClick={onClick}>
            {t("preview")}
        </LinkButton>
    );
};

export default PreviewLinkButton;
