import { Icon } from "@chakra-ui/react";

const GoBackIcon = (props) => {
    return (
        <Icon viewBox="0 0 120 113" {...props}>
            <path
                fill="#2E54B8"
                d="M110.686 99.3h-3.108l.074-47.32c3.731 2.363 8.879 1.269 11.242-2.56 2.114-3.482 1.119-8.256-2.313-10.867 0 0-3.681-2.784-9.003-6.837V9.262c0-2.387-1.965-4.351-4.353-4.351h-9.948c-2.388 0-4.353 1.964-4.353 4.351v8.305C77.832 9.138 67.634 1.38 67.634 1.38a6.903 6.903 0 0 0-8.281 0L10.406 38.578C7 41.164 5.98 45.963 8.118 49.444c2.313 3.755 7.561 4.874 11.242 2.562l-.075 47.318h-3.108a4.357 4.357 0 0 0-4.353 4.352v4.973c0 2.387 1.965 4.351 4.353 4.351h94.509c2.388 0 4.353-1.964 4.353-4.351v-4.973a4.357 4.357 0 0 0-4.353-4.352V99.3ZM92.655 9.261c0-.348.273-.621.622-.621h9.948c.348 0 .622.273.622.621v19.644c-3.457-2.611-7.312-5.545-11.192-8.504V9.262ZM17.644 48.7c-2.04 1.541-5.024.97-6.342-1.219-1.144-1.865-.547-4.476 1.368-5.918L61.616 4.39a3.097 3.097 0 0 1 3.755 0l48.947 37.173c1.94 1.442 2.536 4.053 1.392 5.918-1.343 2.188-4.327 2.76-6.367 1.219-.074-.05-44.718-33.966-44.718-33.966a1.903 1.903 0 0 0-2.263 0s-44.668 33.94-44.718 33.966Zm63.197.97h-34.82c-1.019 0-1.865.845-1.865 1.864v47.791h-21.14l.075-50.054c9.998-7.608 40.415-30.708 40.415-30.708l40.416 30.684-.075 50.078h-21.14v-47.79c0-1.02-.846-1.866-1.866-1.866Zm-1.865 3.73v45.925H47.887V53.398h31.089Zm32.332 55.25a.63.63 0 0 1-.622.621h-94.51a.631.631 0 0 1-.621-.621v-4.973c0-.349.273-.622.622-.622h94.509c.349 0 .622.273.622.622v4.973Z"
            />
            <path
                fill="#2E54B8"
                d="M19 25C8.515 25 0 33.516 0 44s8.515 19 19 19 19-8.516 19-19-8.515-19-19-19Zm0 34.545c-8.567 0-15.545-6.978-15.545-15.545S10.433 28.454 19 28.454 34.545 35.434 34.545 44 27.567 59.545 19 59.545Z"
            />
            <path
                fill="#F4F4F4"
                d="M19 28.453c-8.567 0-15.545 6.978-15.545 15.546 0 8.567 6.978 15.545 15.545 15.545s15.545-6.978 15.545-15.545c0-8.568-6.978-15.546-15.545-15.546Zm8.118 17.204H14.941l2.867 2.867c.605.605.57 1.624-.069 2.263-.639.639-1.658.69-2.28.069l-5.63-5.407a2.039 2.039 0 0 1 .016-2.919l5.631-5.389c.605-.621 1.624-.587 2.28.07.657.656.674 1.658.07 2.262l-2.868 2.867h12.16c.864 0 1.555.743 1.555 1.659 0 .915-.691 1.658-1.555 1.658Z"
            />
            <path
                fill="#2E54B8"
                d="M28.673 44c0 .916-.691 1.658-1.555 1.658H14.941l2.867 2.868c.605.604.57 1.623-.069 2.262-.639.64-1.658.691-2.28.07L9.83 45.45a2.039 2.039 0 0 1 .016-2.92l5.631-5.388c.605-.622 1.624-.587 2.28.069.657.656.674 1.658.07 2.263l-2.868 2.867h12.16c.864 0 1.555.743 1.555 1.658Z"
            />
        </Icon>
    );
};

export default GoBackIcon;
