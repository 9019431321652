import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { useMemo, useState } from "react";
import { Contract, ContractStatus } from "../../models/Contract.models";

const useContractsFilter = (contracts: Contract[]) => {
    const [filterDates, setFilterDates] = useState<{ from: Date; to: Date }>(null);
    const [filterStatus, setFilterStatus] = useState<ContractStatus>(null);
    const [filterText, setFilterText] = useState<string>(null);
    const [dateFilterField, setDateFilterField] = useState<TimeStampField>(null);
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

    function handleDateFilter({ from, to }: { from: any; to: any }, dateField: TimeStampField) {
        setIsFilterActive(true);
        setFilterDates({ from: from ? new Date(from) : null, to: to ? new Date(to) : null });
        setDateFilterField(dateField);
    }

    function handleStatusFilter(status: ContractStatus) {
        if (status) {
            setIsFilterActive(true);
            setFilterStatus(status);
        } else {
            setIsFilterActive(false);
            setFilterStatus(null);
        }
    }

    function handleSearchFilter(text: string) {
        if (text) {
            setFilterText(text);
        } else {
            setFilterText(null);
        }
    }

    function clearFilters() {
        setIsFilterActive(false);
        setFilterDates(null);
        setDateFilterField(null);
        setFilterStatus(null);
    }

    const filteredContracts = useMemo(() => {
        let filtered = contracts;
        if (filterDates?.from && filterDates?.to) {
            //is between
            filtered = filtered.filter(
                (c) =>
                    isAfter(new Date(c[dateFilterField]), startOfDay(filterDates.from)) &&
                    isBefore(new Date(c[dateFilterField]), endOfDay(filterDates.to))
            );
        } else if (filterDates?.from) {
            //is after
            filtered = filtered.filter((c) => isAfter(new Date(c[dateFilterField]), startOfDay(filterDates.from)));
        } else if (filterDates?.to) {
            //is before
            filtered = filtered.filter((c) => isBefore(new Date(c[dateFilterField]), endOfDay(filterDates.to)));
        }

        if (filterStatus) {
            filtered = filtered.filter((c) => c.status === filterStatus);
        }

        if (filterText) {
            filtered = filtered.filter(
                (c) =>
                    c.name.toLowerCase().includes(filterText.toLowerCase()) ||
                    c.userName.toLowerCase().includes(filterText.toLowerCase()) ||
                    c.type.toLowerCase().includes(filterText.toLowerCase())
            );
        }

        return filtered;
    }, [contracts, filterDates, dateFilterField, filterStatus, filterText]);

    return { handleDateFilter, filteredContracts, isFilterActive, clearFilters, handleStatusFilter, handleSearchFilter };
};

export default useContractsFilter;
