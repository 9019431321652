import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { FaUserCircle } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import CommonModal from "../CommonModal";
import LinkButton from '../buttons/LinkButton'
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const EditUserModal = ({ isOpen, onClose }: Props) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    return (
        <CommonModal size="sm" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">{t("editProfile")}</h2>
                <div className="text-brand-500 text-center">
                    <FaUserCircle size={100} color="inherit" className="mx-auto" />
                    <LinkButton fontSize="xs" rightIcon={<FiEdit />} colorScheme="gray">
                        {t("changeImage")}
                    </LinkButton>
                </div>

                <form className="flex flex-col items-center">
                    <FormControl>
                        <InputGroup>
                            <Input id="first_name" placeholder={t("name")} textAlign="center" type="text" rounded="none" variant="flushed" />
                        </InputGroup>
                    </FormControl>

                    <FormControl className="mt-6">
                        <InputGroup>
                            <Input id="last_name" placeholder={t("lastName")} textAlign="center" type="text" rounded="none" variant="flushed" />
                        </InputGroup>
                    </FormControl>

                    <div className="mt-10">
                        <Button rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                            {t("accept")}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

export default EditUserModal;
