import { BiddingDocumentType } from "src/models/biddings/BiddingDocumentType.models";
import { BiddingProfile } from "src/models/biddings/BiddingProfile.models";
import biddingDocumentTypeService from "src/services/biddings/biddingDocumentType.service";
import biddingProfileService from "src/services/biddings/biddingProfile.service";
import biddingNotificationsService from "src/services/biddings/biddingNotifications.service";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import useAuthState from "../authState";
import useModalState from "../modalsState";
import useCustomToast from "src/hooks/useCustomToast";
import useBiddingProfileState from "./biddingProfileState";

interface State {
    biddingnotificacion: { loading: boolean; error: boolean; data: any[] };
    biddingsnotificacionCompany: { loading: boolean; error: boolean; data: any[] }; //falta model notificacion by company
    notificacionsBidding: { loading: boolean; error: boolean; data: any[] };
    selectedBiddingnotificacion: any;
    setSelectedBiddingnotificacion: (biddingDocumentType: any) => void;
    //getBiddingNotificacionCompany: (withLoading?: boolean) => any;
    getBiddingNotificacionCompany: (biddingId: string, withLoading?: boolean) => Promise<any | null>;
    getBiddingTypeNotificacionCompany: (biddingId: string, withLoading?: boolean) => Promise<any | null>;
    getnotificacionsBiddingById: (biddingId: string, withLoading?: boolean) => Promise<any | null>;
    createBiddingnotificacion: (biddingDocumentType: any, withLoading?: boolean) => Promise<boolean>;
    deleteBiddingnotificacion: (notificacion: any, withLoading?: boolean) => Promise<boolean>;
    updateBiddingnotificacion: (notificacion: any, withLoading?: boolean) => Promise<boolean>;
    updatenotificacionBiddingList: (body: any, withLoading?: boolean) => Promise<boolean>;
    getnotificacion: (companynotificacionId: string, withLoading?: boolean) => Promise<any | null>;
    // notifications
    biddingNotifications: { loading: boolean; error: boolean; data: any[] };
    stages: [],
    setStages: (newStages) => void

    reset: () => void;
}

const initialState = {
    biddingnotificacion: { loading: false, error: false, data: [] },
    biddingsnotificacionCompany: { loading: false, error: false, data: [] },
    notificacionsBidding: { loading: false, error: false, data: [] },
    selectedBiddingnotificacion: null,
    // notifications
    biddingNotifications: { loading: false, error: false, data: [] },
};

const useBiddingNotificationState = create<State>((set, get) => ({
    ...initialState,
    stages: [],
    setSelectedBiddingnotificacion: (biddingnotificacion: any) => set({ selectedBiddingnotificacion: biddingnotificacion }),
    createBiddingnotificacion: createBiddingnotificacion(set, get),
    getBiddingNotificacionCompany: getBiddingnotificacions(set, get),
    getBiddingTypeNotificacionCompany: getBiddingTypenotificacions(set, get),
    getnotificacionsBiddingById: getnotificacionsBiddingById(set, get),
    deleteBiddingnotificacion: deleteBiddingnotificacion(set, get),
    updateBiddingnotificacion: updateBiddingnotificacion(set, get),
    updatenotificacionBiddingList: updatenotificacionBiddingList(set, get),
    getnotificacion: getnotificacion(set, get),
    setStages: (newStages) => set((state) => ({...state, stages: newStages})),
    reset: () => set((state) => ({ ...state, ...initialState })),
}));

export default useBiddingNotificationState;

function hasPermissionForAction(reponse: any, msgPermission: string, redirect: boolean) {
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text2: msgPermission,
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                redirectHome: redirect,
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    }
}

function createBiddingnotificacion(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingnotificacion: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingNotificationsService.createSubscriptionBidding(biddingnotificacion);

        hasPermissionForAction(res, "Crear notificacion", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating notificacion bidding");
            return false;
        }
    };
}

function getBiddingNotificacionCompany(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        let biddingId = "1";
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingNotificationsService.getSubscriptionBidding(biddingId);

        hasPermissionForAction(res, "Obtener notificaciones", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ biddingsnotificacionCompany: { loading: false, error: false, data: res.data.respuesta } });
        } else {
            console.log("Error fetching notificacion biddings");
            set((state) => ({
                biddingsnotificacionCompany: { loading: false, error: true, data: state.biddingsnotificacionCompany.data },
            }));
        }
    };
}

function getBiddingnotificacions(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingNotificationsService.getSubscriptionBidding(biddingId);

        hasPermissionForAction(res, "Obtener notificaciones", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ biddingnotificacion: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            set({ biddingnotificacion: null });
            return null;
        }
    };
}

function getBiddingTypenotificacions(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        withLoading && useModalState.getState().setLoaderModal(true);
        const templateId = useBiddingProfileState.getState().modalTemplateId;
        const res = await biddingNotificationsService.getSubscriptionBiddingType(biddingId || templateId);

        hasPermissionForAction(res, "Obtener notificaciones", false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ biddingnotificacion: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            set({ biddingnotificacion: null });
            return null;
        }
    };
}

function getnotificacionsBiddingById(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingNotificationsService.getSubscriptionBidding(biddingId);

        hasPermissionForAction(res, "Obtener notificaciones del proceso", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            set({ notificacionsBidding: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            set({ notificacionsBidding: null });
            return null;
        }
    };
}

function deleteBiddingnotificacion(set: NamedSet<State>, get: GetState<State>) {
    return async (notificacion: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingNotificationsService.deleteSubscriptionBidding(notificacion);

        hasPermissionForAction(res, "Eliminar notificacion", false);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            // set({ notificacionsBidding: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            // set({ notificacionsBidding: null });
            return null;
        }
    };
}

function updateBiddingnotificacion(set: NamedSet<State>, get: GetState<State>) {
    return async (notificacion: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingNotificationsService.updateSubscriptionBidding(notificacion);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            return null;
        }
    };
}

function updatenotificacionBiddingList(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, withLoading = true) => {
        const { usersList, biddingId } = body;
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingNotificationsService.updateSubscriptionBiddingUserList(biddingId, usersList);
        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            // set({ notificacionsBidding: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            // set({ notificacionsBidding: null });
            return null;
        }
    };
}

function getnotificacion(set: NamedSet<State>, get: GetState<State>) {
    return async (companynotificacionId: string, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await biddingNotificationsService.getSubscriptionBidding(companynotificacionId);
        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            set({ selectedBiddingnotificacion: res.data.respuesta });
            return res.data.respuesta;
        } else {
            console.log("Error fetching bidding");
            set({ selectedBiddingnotificacion: null });
            return null;
        }
    };
}
