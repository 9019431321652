import { Button } from "@chakra-ui/button";
import { FormControl, Input, InputGroup, useBoolean } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useCustomToast from "../../../../hooks/useCustomToast";
import CommonModal from "../../CommonModal";
import { UploadBiddingReqAxCalcModalData } from "../../../../models/Modals.models";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useBiddingState from "src/store/biddings/biddingState";
import { blobToBase64, getMimeType } from "src/lib/utils";
import BiddingAxCalc from "src/components/bidding/BiddingAxCalc";
import AddFileToCalcModal from "../AddFileToCalcModal";
import BiddingAxRoadmap from "src/components/bidding/AxRoadmap/BiddingAxRoadmap";
import { useAxCalcStore } from "src/store/axCalc.store";
import { useAxRoadmapStore } from "src/store/axRoadmap.store";

type Props = {
  isOpen: boolean;
  onClose: () => void
};

const RoadMapViewModal = ({ isOpen, onClose }: Props) => {
  const resetRoadmap = () => {
    useAxRoadmapStore.getState().reset()
    onClose()
  }

  useEffect(() =>{

  }, [isOpen])
  return (
    <CommonModal size="6xl" isOpen={isOpen} onClose={resetRoadmap}>
      <div className="w-full  space-y-6 py-4">
        <div>
          <h4 className="text-center text-gray-600 font-semibold">Ax RoadMap</h4>
          <BiddingAxRoadmap />
        </div>
      </div>
    </CommonModal>
  );
};

export default RoadMapViewModal;
