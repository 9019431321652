import React, { useEffect, useState } from 'react'
import CommonModal from '../CommonModal'
import { TranslationKeys } from 'src/i18n/en';
import { useTranslation } from 'react-i18next';
import * as Yup from "yup"
import { Task } from 'gantt-task-react';
import { TaskType } from 'gantt-task-react/dist/types/public-types';
import { useFormik } from 'formik';
import { formatDateToString, formatStringToDate } from 'src/lib/utils';
import { Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import { BiPen } from 'react-icons/bi';
import CalendarIcon from 'src/components/icons/CalendarIcon';
import { BsPercent } from 'react-icons/bs';
import { useAxRoadmapStore } from 'src/store/axRoadmap.store';

type Props = {
  onCancel: () => any;
  isOpen: boolean;
  taskSelected?: Task;
};

function AddTaskRoadmapModal({ isOpen, onCancel, taskSelected }: Props) {
  const t: (key: TranslationKeys) => string = useTranslation("global")[0];
  const { setTasksRoadMapselect, tasksRoadmapSelected } = useAxRoadmapStore();
  const [selectedOption, setSelectedOption] = useState('');
  const [taskProject, setTaskProject] = useState<Task[]>();
  const [projectSelected, setProjectSelect] = useState<string>('');

  const handleSelectChange = (e: any) => {
    taskForm.values.activityType = e.target.value;
    setSelectedOption(e.target.value);
    const projectList = tasksRoadmapSelected.filter(e => e.type === 'project');
    setTaskProject(projectList);
  }

  const getDisplayOrder = () => {
    const projectList = tasksRoadmapSelected.filter(e => e.type === 'project');
    if (taskForm.values.activityType === 'project') {
      return projectList.length === 0 ? 1 : ((projectList[projectList.length - 1].displayOrder ?? 0) + 1000)
    } else {
      const projectData = tasksRoadmapSelected.filter((e) => e.id === projectSelected)[0]
      const tasksProject = tasksRoadmapSelected.filter((e) => e.project === projectSelected);
      return tasksProject.length === 0 ? (projectData.displayOrder ?? 0) + 1 : (projectData.displayOrder ?? 0) + tasksProject.length + 1
    }
  }

  const clearForm = () => {
    taskForm.resetForm()
    setSelectedOption('');
    setProjectSelect('');
  }

  const createTask = () => {
    const task: Task = {
      ...{
        start: formatStringToDate(taskForm.values.initialDate),
        end: formatStringToDate(taskForm.values.endDate),
        name: taskForm.values.name,
        id: taskForm.values.activityType !== 'project' ? `${projectSelected}${taskForm.values.name}id` : `${taskForm.values.name}id`,
        progress: taskForm.values.progress,
        type: taskForm.values.activityType as TaskType,
        styles: { backgroundColor: taskForm.values.activityType !== 'project' ? 'rgba(3, 68, 220, 1)' : 'rgba(49, 151, 149, 1)', backgroundSelectedColor: taskForm.values.activityType !== 'project' ? 'rgba(3, 68, 220, .8)' : 'rgba(49, 151, 149, .8)' },
      },
      ...(taskForm.values.activityType !== 'project' ? { project: `${projectSelected}`, displayOrder: getDisplayOrder() ?? 0 } : { hideChildren: false, displayOrder: getDisplayOrder() ?? 0 })
    }

    const body = [
      ...tasksRoadmapSelected
    ].concat(task)

    setTasksRoadMapselect(body);

    clearForm();
    onCancel()
  }

  const editTask = () => {

    const task: Task = {
      ...taskSelected!,
      ...{
        start: formatStringToDate(taskForm.values.initialDate),
        end: formatStringToDate(taskForm.values.endDate),
        name: taskForm.values.name,
        progress: taskForm.values.progress,
      }
    }
    const updatedArray = tasksRoadmapSelected.map(e => e.id === task.id ? task : e);
    setTasksRoadMapselect(updatedArray)
    onCancel()
  }
  const validationSchema = Yup.object({
    name: Yup.string().required('El nombre de la actividad es requerido'),
    initialDate: Yup.date().required('La fecha inicial de la tarea es requerida'),
    endDate: Yup.date().required('La fecha final de la tarea es requerida'),
    activityType: Yup.string().required('Seleccione un tipo de actividad'),
  });
  const taskForm = useFormik({
    onSubmit: taskSelected ? editTask : createTask,
    validateOnBlur: true,
    initialValues: { name: '', initialDate: '2024-01-01', endDate: '2024-01-01', activityType: '', progress: 0 },
    validationSchema
  })

  useEffect(() => {
    if (taskSelected) {
      taskForm.setValues(({
        name: taskSelected.name,
        initialDate: formatDateToString(taskSelected.start),
        endDate: formatDateToString(taskSelected.end),
        progress: taskSelected.progress,
        activityType: taskSelected.type
      }))
    }
  }, [taskSelected])

  return (
    <CommonModal size="xl" isOpen={isOpen} onClose={onCancel}>
      <div className="w-full space-y-6 py-4">
        <h2 className="title-3 text-center">{taskSelected ? 'Editar' : 'Agregar'} actividad</h2>
        <form onSubmit={taskForm.handleSubmit}>
          {
            !taskSelected && <FormControl mb={2} isInvalid={!!taskForm.errors.activityType}>
              <FormLabel mb={0}>Tipo de actividad</FormLabel>
              <Select
                placeholder="Seleccionar un tipo"
                value={selectedOption}
                onChange={handleSelectChange}
                bg="white"
                borderRadius="md"
                w={'auto'}
              >
                <option value="project">Proyecto</option>
                <option value="task">Tarea</option>
              </Select>
              <FormErrorMessage>{taskForm.errors.activityType}</FormErrorMessage>
            </FormControl>
          }

          {selectedOption === 'task' &&
            <FormControl mb={2}>
              <FormLabel mb={0}>Proyecto relacionado</FormLabel>
              <Select
                placeholder="Seleccione proyecto"
                value={projectSelected}
                onChange={(e) => setProjectSelect(e.target.value)}
                bg="white"
                borderRadius="md"
                w={'auto'}
              >
                {taskProject?.map(e => (
                  <option value={e.id}>{e.name}</option>
                ))}
              </Select>
            </FormControl>
          }
          <FormControl mb={2} isInvalid={!!taskForm.errors.name}>
            <FormLabel mb={0}>Nombre de {taskForm.values.activityType === 'project' ? 'proyecto' : 'tarea'}</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <BiPen color="gray.300" />
              </InputLeftElement>
              <Input id="name" type="text" placeholder={`Nombre de ${taskForm.values.activityType === 'project' ? 'proyecto' : 'tarea'}`}
                onChange={taskForm.handleChange}
                value={taskForm.values.name}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.name}</FormErrorMessage>
          </FormControl>
          <FormControl mb={2} isInvalid={!!taskForm.errors.initialDate}>
            <FormLabel mb={0}>Fecha inicial</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <CalendarIcon color="gray.300" />
              </InputLeftElement>
              <Input id="initialDate" type="date"
                onChange={taskForm.handleChange}
                value={taskForm.values.initialDate}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.initialDate}</FormErrorMessage>
          </FormControl>
          <FormControl mb={2} isInvalid={!!taskForm.errors.endDate}>
            <FormLabel mb={0}>Fecha final</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <CalendarIcon color="gray.300" />
              </InputLeftElement>
              <Input id="endDate" type="date"
                onChange={taskForm.handleChange}
                value={taskForm.values.endDate}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.endDate}</FormErrorMessage>
          </FormControl>
          <FormControl mb={2} isInvalid={!!taskForm.errors.progress}>
            <FormLabel mb={0}>Progreso de actividad</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <BsPercent color="gray.300" />
              </InputLeftElement>
              <Input id="progress" type="number" placeholder="0" min={0} max={100}
                onChange={taskForm.handleChange}
                value={taskForm.values.progress}
              />
            </InputGroup>
            <FormErrorMessage>{taskForm.errors.progress}</FormErrorMessage>
          </FormControl>
          <div className="mt-8 text-center">
            <Button
              rounded={"full"}
              size={"md"}
              fontWeight={"medium"}
              px={"2rem"}
              isLoading={false}
              onClick={() => taskForm.handleSubmit()}
            >
              {t("save")}
            </Button>
          </div>
        </form>
      </div>
    </CommonModal>
  )
}

export default AddTaskRoadmapModal