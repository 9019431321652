import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import PowerIframe from "./PowerBIIFrame";
import NotifcationList from "./NotifcationList";
import useDashboardState from "src/store/dashboardState";
import './homeAmericaMovil.css'
import { Button } from "@chakra-ui/react";
import { BiRefresh } from "react-icons/bi";
import { TiDownload } from "react-icons/ti";
import DownloadPowerBIAlert from "src/components/common/modals/DownloadPowerBIAlert";
import { useModalStore } from "src/store/modal.store";


const BoxDashBoard = () => {
    const { urlDashboardPowerBi, getUrlDashboardPowerBI, refreshDashboardPowerBI, downloadPoweBIToMail } = useDashboardState();
    const [loading, setLoading] = useState<boolean>(false);
    const openModal = useModalStore(e => e.openModal);
    //timeout 45 segundos
    const handleRefreshPBI = async () => {
        setLoading(true)
        refreshDashboardPowerBI().then(() => {
            setTimeout(() => {
                getUrlDashboardPowerBI();
                setLoading(false)
            }, 45000);
        })
        setLoading(false)
    }

    const handleDownloadToMail = async () => {
        setLoading(true)
        downloadPoweBIToMail().then(() => {
            openModal('DownloadPowerBIAlert')
            getUrlDashboardPowerBI();
            setLoading(false)
        })

        setLoading(false)
    }

    useEffect(() => {
        getUrlDashboardPowerBI();
    }, []);
    return (
        <div className="box-dashboard w-full flex items-start justify-between p-2 mb-2">
            <div className="h-full bg-gray-100 rounded-xl shadow-xl mr-4">
                <NotifcationList />
            </div>
            <div className="flex-1">
                <div className="bg-gray-100 rounded-xl shadow-xl">
                    <PowerIframe urlPower={urlDashboardPowerBi} loading={loading} />
                </div>
                <div className="text-right mt-2 flex justify-between">
                    <Button colorScheme="gray" variant='solid' leftIcon={<TiDownload className="w-5 h-5" />} onClick={handleDownloadToMail}>Descargar reporte a correo</Button>
                    <Button colorScheme="gray" variant='solid' leftIcon={<BiRefresh className="w-5 h-5" />} onClick={handleRefreshPBI}>Actualizar tablero</Button>
                </div>
            </div>
            <DownloadPowerBIAlert />
        </div>
    );
};



export default BoxDashBoard;

