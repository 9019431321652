import { CircularProgress, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import LoaderModal from 'src/components/common/modals/LoaderModal';


const PowerBIIFrame = ({urlPower, loading}) => {

    return (
        <div className="flex justify-center items-center"
            style={{ height: "calc(100vh - 42vh)" }}>
            {loading ?
                <Flex direction={'column'} alignItems={'center'}>
                    <CircularProgress isIndeterminate size="4rem" color="brand.500" />
                    <Text fontSize='lg' w={'100%'} mt={5}>
                        Espere un momento, esto podría demorarse un par de minutos...
                    </Text>
                </Flex>
                :
                <iframe
                    title={" Power BI"}
                    src={urlPower}
                    //src="https://app.powerbi.com/view?r=eyJrIjoiODE0MjgyZGUtNDBiYS00ODcwLWI0OGQtYTljYmIyYTE2MTYzIiwidCI6ImQyMmM0MWI2LTY2ODAtNDhiYS1iZGQzLWEzNDNiODJjNTdiOCJ9"
                    allowFullScreen={true}
                    className="w-full h-full border-0"
                    style={{ zoom: '0.8' }}
                />
            }
        </div>
    );
};

export default PowerBIIFrame;
