import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from "@chakra-ui/react";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    size: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "full";
    children: JSX.Element;
    closeButton?: boolean;
    closeOnOverlayClick?: boolean;
    bg?: string;
    className?: string;
};

const CommonModal = ({ isOpen, onClose, size, children, closeButton = true, closeOnOverlayClick = true, bg, className }: Props) => {
    return (
        <Modal
            isOpen={isOpen}
            isCentered
            size={size}
            onClose={onClose}
            autoFocus={false}
            closeOnOverlayClick={closeOnOverlayClick}
        >
            <ModalOverlay />
            <ModalContent bgColor="transparent">
                <div className={"w-full rounded-3xl flex p-6 relative " + className} style={{ backgroundColor: bg ?? "#fff" }}>
                    {children}
                    {closeButton && (
                        <ModalCloseButton
                            _hover={{ bg: "transparent" }}
                            _active={{ bg: "transparent" }}
                            _focus={{ outline: "none" }}
                            color="gray.600"
                            size="md"
                        />
                    )}
                </div>
            </ModalContent>
        </Modal>
    );
};

export default CommonModal;
