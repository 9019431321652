import { Drawer, DrawerOverlay, DrawerContent, useBoolean, CircularProgress } from "@chakra-ui/react";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomToast from "src/hooks/useCustomToast";
import usePdfCanvasRender from "src/hooks/usePdfCanvasRenderV2";
import { TranslationKeys } from "src/i18n/en";
import vaultService from "src/services/vault.service";
import BiddingPdfViewerTopbar from "./BiddingPdfViewerTopbar";

export interface Props {
    docVaultId?: string;
    onDownload: () => any;
    onClose: () => any;
}

const PdfViewerFull = ({ docVaultId, onClose, onDownload }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const canvasId = "pdfCanvas-" + nanoid(4);
    const toast = useCustomToast();

    const [loading, setLoading] = useBoolean(true);
    const [pdfUrl, setPdfUrl] = useState<string>(null);
    const [documentName, setDocumentName] = useState<string | undefined>(t("undefined"));

    const {
        screenRef,
        containerRef,
        canvasRef,
        totalPages,
        handleNextPage,
        handlePrevPage,
        currentPage,
        setCurrentPage,
        handleZoom,
        isLoaded,
    } = usePdfCanvasRender(pdfUrl);

    async function getVaultDoc(docFileId: string) {
        setLoading.on();
        try {
            const res = await vaultService.getDocumentFromVault(docFileId);
            const b64String = res.data.file;
            const extension = res.data.name.split(".").reverse()[0];
            if (extension.toLowerCase() === "pdf") {
                setPdfUrl("data:application/pdf;base64," + b64String);
                setDocumentName(res.data.name);
            }
        } catch {
            toast.error("Ocurrió un error al descargar el documento");
        } finally {
            setLoading.off();
        }
    }

    useEffect(() => {
        if (docVaultId) {
            getVaultDoc(docVaultId);
        } else {
            toast.error("ID de documento no existe");
        }
    }, []);

    return (
        <>
            <Drawer placement="bottom" size="full" onClose={onClose} isOpen={true}>
                <DrawerOverlay />
                <DrawerContent>
                    <BiddingPdfViewerTopbar
                        showCloseDrawer={true}
                        onCloseDrawer={onClose}
                        onPrev={handlePrevPage}
                        onNext={handleNextPage}
                        onZoomIn={() => handleZoom("in")}
                        onZoomOut={() => handleZoom("out")}
                        onDownload={onDownload}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        setCurrentPage={setCurrentPage}
                        documentName={documentName}
                    />
                    <main className="w-full flex flex-col">
                        {/* <div className="w-[350px] bg-white flex flex-col" style={{ height: "calc(100vh - 74px)" }}>
                            <div className="grow"></div>
                            <div className="px-4 py-4 flex justify-center">
                                <Button variant="solid" size="md" rounded="full" px={8} onClick={onDownload}>
                                    Descargar
                                </Button>
                            </div>
                        </div> */}
                        <div
                            ref={screenRef}
                            className="w-full grow relative bg-slate-100 flex justify-center items-center"
                            style={{ height: "calc(100vh - 74px)" }}
                        >
                            {Boolean(pdfUrl) && (
                                <div ref={containerRef} className="overflow-auto">
                                    <canvas
                                        id={canvasId}
                                        className={`absolute bottom-0 left-0 right-0 top-0 ${isLoaded && "shadow-xl border"}}`}
                                        ref={canvasRef}
                                    />
                                </div>
                            )}
                            {loading && (
                                <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center flex-col gap-2">
                                    <CircularProgress isIndeterminate color="brand.500" size="80px" thickness="6px" />
                                    <p>{t("loadingDocument")}</p>
                                </div>
                            )}
                        </div>
                    </main>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default PdfViewerFull;
