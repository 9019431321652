import { Checkbox, Select, Tooltip } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { IoTrashOutline } from "react-icons/io5";
import useCustomToast from "../../hooks/useCustomToast";
import usePagination from "../../hooks/usePagination";
import { DataType, DataTypesTypes } from "../../models/DataType.models";
import useDataTypesState from "../../store/dataTypesState";
import useModalState from "../../store/modalsState";
import { parseDataTypes } from "../../lib/utils";
import SearchInput from "../common/SearchInput";
import CommonTh from "../common/table/CommonTh";
import TablePaginator from "../common/table/TablePaginator";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const TableRow = ({
    selected,
    dataType,
    onSelect,
    onDelete,
}: {
    selected: boolean;
    dataType: DataType;
    onSelect: (dt: DataType, selected: boolean) => any;
    onDelete: (dt: DataType) => any;
}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    function handleChange(e: any) {
        onSelect(dataType, e.target.checked);
    }

    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200 cursor-pointer">
            <td className="w-4 p-2">
                <div className="flex items-center">
                    <Checkbox key={String(selected)} size={"lg"} defaultChecked={selected} onChange={handleChange} />
                </div>
            </td>
            <td className="p-2 py-3">{dataType.name}</td>
            <td className="p-2 py-3">{t(parseDataTypes(dataType.type) as any)}</td>
            <td className="px-2 py-3">
                <div className="w-full xl:w-7/12 flex justify-center gap-6">
                    <Tooltip label={t("delete")} fontSize="12px">
                        <button onClick={() => onDelete(dataType)}>
                            <IoTrashOutline size={25} />
                        </button>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const DataTypesTable = ({
    dataTypes,
    onDataTypeSelect,
    selectedDataTypeNames = [],
}: {
    dataTypes: DataType[];
    onDataTypeSelect: (dataType: DataType, selected: boolean) => any;
    selectedDataTypeNames: string[];
}) => {
    const setDeleteModal = useModalState((s) => s.setDeleteCommonModal);
    const deleteDataType = useDataTypesState((s) => s.deleteDataType);
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const [searchQuery, setSearchQuery] = useState("");
    const [filter, setFilter] = useState<DataTypesTypes | "">("");

    const filteredResults = useMemo(() => {
        let temp = dataTypes.sort((a, b) => (a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1));
        if (filter) {
            temp = temp?.filter((dt) => dt.type === filter);
        }
        if (searchQuery) {
            temp = temp?.filter((dt) => dt.name.toLowerCase().includes(searchQuery.toLowerCase()));
        }
        return temp;
    }, [searchQuery, filter, dataTypes]);

    const { currentPage, currentResutls, maxPages, next, prev } = usePagination({ data: filteredResults, itemsPerPage: 6 });

    async function onDelete(dataType: DataType) {
        //quito dt del grupo de datos en caso de exsitir
        onDataTypeSelect(dataType, false);
        //elimino
        const deleted = await deleteDataType(dataType.id);
        if (deleted) {
            toast.success(t("deletedDataTypeSuccess"));
        } else {
            toast.error(t("deletedDataTypeError"));
        }
    }

    function handleDelete(dataType: DataType) {
        setDeleteModal({
            show: true,
            data: { text1: t("typeWillBeDeleted"), text2: dataType.name, onDelete: () => onDelete(dataType) },
        });
    }

    return (
        <div className="w-full">
            <div className="w-full flex justify-between items-center sticky top-0 z-10 py-3 bg-white border-b">
                <SearchInput onChange={setSearchQuery} />
                <Select
                    width="max-content"
                    color="brand.500"
                    fontSize="sm"
                    variant="unstyled"
                    placeholder={t("all")}
                    colorScheme="brand"
                    onChange={(e) => setFilter(e.target.value as typeof filter)}
                >
                    <option value="string">{t("text")}</option>
                    <option value="number">{t("numeric")}</option>
                    <option value="date">{t("date")}</option>
                    <option value="selectable">{t("selectable")}</option>
                </Select>
            </div>
            <table className="w-full text-sm text-left text-dark-2">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2 w-2/12">
                            {t("add")}
                        </th>
                        <th scope="col" className="p-2 w-6/12">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2 w-2/12">
                            <CommonTh text={t("type")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("actions")} />
                        </th>
                    </tr>
                    <tr></tr>
                </thead>
                <tbody>
                    {currentResutls?.map((item) => (
                        <TableRow
                            key={item.id}
                            dataType={item}
                            selected={Boolean(selectedDataTypeNames.includes(item.idName))}
                            onSelect={onDataTypeSelect}
                            onDelete={handleDelete}
                        />
                    ))}
                </tbody>
            </table>
            {currentResutls?.length ? (
                <div className="w-full flex justify-end">
                    <TablePaginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

export default DataTypesTable;
