import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input";
import { Button, useBoolean } from "@chakra-ui/react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import authService from "../../services/auth.service";
import useAuthState from "../../store/authState";
import { useNavigate } from "react-router";
import useCustomToast from "../../hooks/useCustomToast";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useGlobalState from "src/store/globalState";

interface FormFields {
    email: string;
    password: string;
}

const LoginForm = () => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const [showPass, setShowPass] = useBoolean(false);
    const [isLoading, toggleLoading] = useBoolean(false);

    const onLogin = useAuthState((s) => s.onLogin);
    const setAuthPolicyData = useAuthState( s => s.setAuthPolicyData );
    const navigate = useNavigate();
    const toast = useCustomToast();
    const { getUserInfo, getUserCompanyInfo, setIsSelectedChildCompany } = useGlobalState()

    const onSubmit = async (data: FormFields) => {
        /** */
        toggleLoading.on();

        const res = await authService.login(data);

        toggleLoading.off();

        if ( res?.data ) {
            /** */
            if ( res.data?.passwordPolicyList ) {
                setAuthPolicyData( res.data );
                navigate("/otp");
            } else {
                onLogin( res.data ).then(
                    async response => {
                        if ( response ) {
                            /** Recuperar información del usuario */
                            getUserInfo( response.userId )

                            const companyData = await getUserCompanyInfo()

                            if ( companyData && companyData.group ) {
                                navigate( "/group-selection" )
                            } else {
                                setIsSelectedChildCompany( true )
                                navigate( "/onboarding" )
                            }
                        }
                    }
                )
            }
        } else {
            toast.error("Email y/o contraseña invalidos", "Login fallido");
        }
    };

    const microsoftLoginUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?scope=User.read&state=8-nWaosiRJJhLx3Byx02nFAcqNDjIxD9FSaTDxgPBYo.BYZKfNhssZw.cxD11xV7RISw_G_Jdj60ZQ&response_type=code&client_id=39ca7544-33f8-46a5-a640-67233d7bb204&redirect_uri=https%3A%2F%2Fkivit.axeleratum.com';

    const handleMicrosoftLogin = () => {
        window.location.href = microsoftLoginUrl;
      };

    const formik = useFormik<FormFields>({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: onSubmit,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            email: Yup.string().required("Campo requerido").email("Email inválido"),
            password: Yup.string().required("Campo requerido"),
        }),
    });

    return (
        <div className="w-full max-w-[25rem] p-2">
            <h1 className="text-brand-500 text-3xl font-medium">{t("login")}</h1>

            <form className="mt-8 flex flex-col items-center" onSubmit={formik.handleSubmit}>
                <FormControl isInvalid={!!formik.errors.email}>
                    <label htmlFor="email" className="text-sm leading-8">
                        {t("email")}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input id="email" type="text" rounded="none" onChange={formik.handleChange} value={formik.values.email} />
                    </InputGroup>
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>

                <FormControl className="mt-6" isInvalid={!!formik.errors.password}>
                    <label htmlFor="password" className="text-sm leading-8">
                        {t("password")}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            id="password"
                            type={showPass ? "text" : "password"}
                            rounded="none"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        <InputRightElement
                            children={
                                <button type="button" onClick={setShowPass.toggle}>
                                    {showPass ? (
                                        <IoEyeOffOutline size={22} color="inherit" />
                                    ) : (
                                        <IoEyeOutline size={22} color="inherit" />
                                    )}
                                </button>
                            }
                        />
                    </InputGroup>
                    <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                </FormControl>
                <div className="w-full text-right text-brand-500 font-light text-sm mt-4">
                    <Link to="/forgot-password">{t("forgetPassword")}</Link>
                </div>

                <div className="mt-10">
                    <Button type="submit" rounded="full" size="lg" fontWeight="medium" isLoading={isLoading}>
                        {t("login")}
                    </Button>
                </div>
             
            </form>
           {/**<div className="mt-10">

                    <Button  rounded="full" size="lg" fontWeight="medium" onClick={handleMicrosoftLogin} isLoading={isLoading}>
                        {"LOGIN MICROSOFT"}
                    </Button>
                </div>
                 */} 
        </div>
    );
};

export default LoginForm;
