import { Accordion, Button, FormControl, Input, InputGroup, Select, useBoolean } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiChevronLeft, BiPlus } from "react-icons/bi";
import { FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AccordionItemBrand from "../../components/common/AccordionItemBrand";
import LinkButton from "../../components/common/buttons/LinkButton";
import MainLayout from "../../components/layouts/MainLayout";
import Steps from "../../components/common/Steps";
import DataGroupList from "../../components/templates/DataGroupList";
import TemplatesEditorPanel from "../../components/templates/TemplatesEditorPanel";
import useCustomToast from "../../hooks/useCustomToast";
import usePdfPreview from "../../hooks/usePdfPreview";
import { trigger } from "../../lib/customEvents";
import { Template } from "../../models/Template.models";
import useContractState from "../../store/contractState";
import useModalState from "../../store/modalsState";
import useTemplateState from "../../store/templatesState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useAuthState from "../../store/authState";
import AddNewFilledIcon from "../../components/icons/AddNewFilledIcon";
import TemplateHeaderFooterAccordionItem from "src/components/templates/TemplateHeaderFooterAccordionItem";
import { useLocation } from "react-router-dom";
import {
    Tag,
    TagLabel,
    TagLeftIcon,
    TagRightIcon,
    TagCloseButton,
} from '@chakra-ui/react'
import { IoIosSettings } from "react-icons/io";
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'

const INSERT_EVENT_NAME = "insertDataItemOnNewTemplate";

const CreateTemplatePage = () => {
    const navigate = useNavigate();
    const toast = useCustomToast();

    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const { setContractTypesModal, setDataGroupSelectModal, setLoaderModal, setPdfPreviewModal, setNewTemplateFromDocxModal } =
        useModalState();

    const { getContractTypes, contractTypes } = useContractState();
    const {hasSavedTemplate, setHasSavedTemplate} = useTemplateState();

    const { generatePdfPreviewUrl } = usePdfPreview();
    const sessionWillBeExpired = useAuthState((s) => s.sessionWillBeExpired);
    const tokenExpiresIn = useAuthState((s) => s.tokenExpiresIn);

    const { currentNewTemplate, setCurrentNewTemplate, initNewTemplate, saveTemplate } = useTemplateState();
    const [currentText, setCurrentText] = useState({ changed: false, text: currentNewTemplate?.text });
    const [currentStep, setCurrentStep] = useState("");

    const [invalidName, setInvalidName] = useBoolean(false);
    const [invalidType, setInvalidType] = useBoolean(false);
    const [templateName, setTemplateName] = useState("");
    const [stepsLifes] = useState([
        {
            name: "Borrador",
            value: "borrador",
        },
        {
            name: "Aprobado",
            value: "aprobado",
        },
        {
            name: "Boveda",
            value: "en_boveda",
        },
        {
            name: "Firmado",
            value: "firmado",
        },
        {
            name: "Tokenizado ",
            value: "tokenizado ",
        },
    ]);

    function handleNameChange(e: any) {
        invalidName && setInvalidName.off();
        setTemplateName(e.target.value);
        //TODOCM: setear nombre en template
    }

    function handleTypeChange(e: any) {
        const type = e.target.value;
        setCurrentNewTemplate({ ...currentNewTemplate, type });
    }

    function handleStepChange(e: any) {
        const step = e.target.value;
        setCurrentStep(step);
    }

    function setText(text: string) {
        setCurrentText({ changed: true, text });
    }

    function validateTemplate(): boolean {
        if (!templateName) {
            setInvalidName.on();
            toast.error(t("nameRequired"), t("warning"));
            return false;
        } else {
            setInvalidName.off();
        }

        if (!currentNewTemplate.type) {
            setInvalidType.on();
            toast.error(t("contractTypeRequired"), t("warning"));
            return false;
        } else {
            setInvalidType.off();
        }

        if (!currentStep) {
            setInvalidType.on();
            toast.error(t("contractTypeRequired"), t("warning"));
            return false;
        } else {
            setInvalidType.off();
        }

        return true;
    }

    async function saveTemplateAsDraft() {
        if (!validateTemplate()) return;

        const savedTemplate = await saveTemplate({
            ...currentNewTemplate,
            name: templateName,
            status: "borrador",
            maxLifeStage: currentStep,
        } as Template);
        if (savedTemplate) {
            toast.success(t("templateCreatedSuccessfully"));
            navigate("../");
        } else {
            toast.error(t("templateCreationFailed"));
        }
    }

    async function publishTemplate() {
        if (!validateTemplate()) return;

        const updated = await saveTemplate({
            ...currentNewTemplate,
            name: templateName,
            status: "activo",
            maxLifeStage: currentStep,
        } as Template);

        if (updated) {
            toast.success(t("templateActivatedSuccessfully"));
            //navigate("../");
            navigate(`/templates/${updated.id}`);
        } else {
            toast.error(t("templateActivatedFailed"));
        }
    }

    function buildImgElement(base64String) {
        // Construir la cadena con la estructura del elemento img
        if (!base64String) {
            console.error("La cadena Base64 proporcionada está vacía o nula.");
            return ""; // O cualquier otro valor de retorno que desees
        }
        const base64Format = base64String.replace(/"/g, '');
        const imgUrl = `data:image/png;base64,${base64Format}`;
        return imgUrl;
    }

    async function handlePreview() {
        if (!currentNewTemplate?.text?.length) {
            return toast.error(t("thereIsNoContentToPreview"), t("warning"));
        }
        setLoaderModal(true);
        const pdfBlobUrl = await generatePdfPreviewUrl(currentNewTemplate?.text, buildImgElement(currentNewTemplate?.header), buildImgElement(currentNewTemplate?.footer));
        setLoaderModal(false);
        setPdfPreviewModal({ show: true, data: { pdfBlobUrl } });
    }

    function handleDocxUpload() {
        setNewTemplateFromDocxModal({
            show: true,
            data: {
                currentTemplate: { ...currentNewTemplate, name: templateName, status: "borrador" } as Template,
                newTemplateValidation: validateTemplate,
            },
        });
    }

    useEffect(() => {
        getContractTypes(false);
        //setea template vacio
        initNewTemplate();

        return () => setCurrentNewTemplate(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (contractTypes.error) {
            toast.error(t("fetchingContractTypesError"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractTypes.error]);

    //uso side effect para actualizar texto para evitar comportamiento no deseado
    useEffect(() => {
        if (currentText.changed) {
            setCurrentNewTemplate({ ...currentNewTemplate, text: currentText.text });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentText]);

    //guarda el template como borrador en caso que expire la sesion
    useEffect(() => {/*
        if (sessionWillBeExpired) {
            if (!validateTemplate()) return;
            saveTemplate({ ...currentNewTemplate, name: templateName, status: "borrador" } as Template, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    */}, [sessionWillBeExpired]);

    useEffect(() => {
        const tokenCurrentTime = tokenExpiresIn / 60;
        console.log("time show edit template exp token",tokenCurrentTime)

        if (tokenCurrentTime < 1.5 && !hasSavedTemplate) {

            publishTemplate();
            setHasSavedTemplate(true);
            toast.info("La plantilla se ha guardado automáticamente antes de que su sesión expirara.","¡Atención!");
           // navigate("../");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenExpiresIn]);

    return (
        <MainLayout>
            <main className="w-full p-3 flex h-full">
                {/* left panel */}
                <section className="w-3/12 flex flex-col pr-4 pl-2 border-r h-full max-h-full overflow-y-auto relative">
                    <h2 className="title-3">{t("generateTemplate")}</h2>
                    <p className="mt-2 text-xs text-justify">{t("newTemplateText")}</p>
                    <div className="mt-6 pb-12">
                        <Accordion defaultIndex={[0]} allowMultiple>
                            {/* information */}
                            <AccordionItemBrand title={t("information")}>
                                <div className="pb-2">
                                    <FormControl isInvalid={invalidName}>
                                        <label htmlFor="template_name" className="text-sm leading-8">
                                            {t("templateName")}
                                        </label>
                                        <InputGroup borderColor="gray.400">
                                            <Input
                                                id="template_name"
                                                type="text"
                                                rounded="none"
                                                onChange={handleNameChange}
                                                value={templateName}
                                            />
                                        </InputGroup>
                                    </FormControl>

                                    <FormControl className="mt-4" isInvalid={invalidType}>
                                        <label htmlFor="contract_type" className="text-sm leading-8">
                                            {t("contractType")}
                                        </label>
                                        <Select defaultValue={"-"} id="contract_type" size="md" rounded="none" onChange={handleTypeChange}>
                                            <option value={""}>{t("select")}</option>
                                            {contractTypes?.data?.map((type) => (
                                                <option key={type.id} value={type.description}>
                                                    {type.description}
                                                </option>
                                            ))}
                                        </Select>
                                        <LinkButton
                                            fontSize="xs"
                                            leftIcon={<BiPlus size={18} />}
                                            className="mt-2"
                                            onClick={() => setContractTypesModal({ show: true })}
                                        >
                                            {t("addNew")}
                                        </LinkButton>
                                    </FormControl>
                                    <FormControl className="mt-4" isInvalid={invalidType}>
                                        <label htmlFor="contract_type" className="text-sm leading-8">
                                            {"Etapa del documento"}
                                        </label>
                                        <Select defaultValue={"-"} id="contract_step" size="md" rounded="none" onChange={handleStepChange}>
                                            <option value={""}>{t("select")}</option>
                                            {stepsLifes?.map((step) => (
                                                <option key={step.value} value={step.value}>
                                                    {step.name}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </AccordionItemBrand>
                            {/* data groups */}
                            <AccordionItemBrand title={t("dataGroups")}>
                                <div>
                                    <div>
                                        <LinkButton
                                            leftIcon={<BiPlus size={18} />}
                                            onClick={() =>
                                                setDataGroupSelectModal({
                                                    show: true,
                                                    data: { case: "new", type: currentNewTemplate.type },
                                                })
                                            }
                                        >
                                            {t("selectDataGroup")}
                                        </LinkButton>
                                    </div>

                                    <div>
                                        {!currentNewTemplate?.dataGroup?.length && (
                                            <div className="text-sm px-1 mt-4">{t("noDataGroupSelected")}</div>
                                        )}
                                        {currentNewTemplate?.dataGroup?.map((dg) => (
                                            <DataGroupList
                                                key={dg.id}
                                                dg={dg}
                                                onClick={(name, idName) =>
                                                    trigger<{ name: string; idName: string }>(INSERT_EVENT_NAME, { name, idName })
                                                }
                                            />
                                        ))}
                                    </div>
                                </div>
                            </AccordionItemBrand>
                            <AccordionItemBrand title={t("headerAndFooter")}>
                                <AlertInfoAction />
                            </AccordionItemBrand>
                            {/*Boolean(currentNewTemplate
                            ) && <TemplateHeaderFooterAccordionItem template={currentNewTemplate as Template} templateName={templateName}/>*/}
                        </Accordion>
                    </div>
                    <div className="grow"></div>
                    <div className="flex flex-col lg:flex-row justify-between gap-2 pt-4 pb-2 sticky bottom-0 bg-white border-t">
                        <Button rounded="full" size="md" fontSize="sm" fontWeight="medium" variant="outline" onClick={saveTemplateAsDraft}>
                            {t("saveDraft")}
                        </Button>
                        <Button rounded="full" size="md" fontWeight="medium" variant="solid" fontSize="sm" onClick={publishTemplate}>
                            {t("activateTemplate")}
                        </Button>
                    </div>
                </section>

                {/* right panel */}
                <section className="w-9/12 pr-2 pl-4 h-full max-h-full overflow-y-auto">
                    <div className="w-full pb-4 border-b">
                        <Steps labels={[t("loadData"), t("draft"), t("activo")]} currentItem={1} className="w-6/12 mx-auto" />
                    </div>

                    <div className="w-full flex items-center justify-between py-4 border-b">
                        <LinkButton leftIcon={<BiChevronLeft size={18} />} onClick={() => navigate(-1)}>
                            {t("back")}
                        </LinkButton>
                        <div className="flex items-center gap-2">
                            <LinkButton leftIcon={<AddNewFilledIcon size={18} />} onClick={handleDocxUpload}>
                                {t("uploadDocxFile")}
                            </LinkButton>
                            <LinkButton leftIcon={<FaEye size={18} />} onClick={handlePreview}>
                                {t("preview")}
                            </LinkButton>
                        </div>
                    </div>
                    <div className="w-full flex grow justify-center mt-2">
                        <TemplatesEditorPanel text={currentNewTemplate?.text} onInput={setText} insertEventName={INSERT_EVENT_NAME} />
                    </div>
                </section>
            </main>
        </MainLayout>
    );
};

const AlertInfoAction = () => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    return (
        <Alert
            status='info'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
        >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
                {t("saveTemplateAsDraft")}
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
                {t("msgTemplateAsDraft")}
            </AlertDescription>
        </Alert>
    );
}

export default CreateTemplatePage;
