import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useContractState from "../../../store/contractState";
import AccordionItemBrand from "../../common/AccordionItemBrand";
import RevisionRolesList from "./RevisionRolesList";
import RevisionStatusList from "./RevisionStatusList";
import RevisionDocumentStatusList from "./RevisionDocumentStatusList";
import { Accordion } from '@chakra-ui/react'
const RevisionAccordionItems = ({ contractId, type }: { contractId: string, type?: string }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const uiOptions = useContractState((s) => s.uiOptions);

    return (
        <>
        
            <AccordionItemBrand title={t("revisions")}>
                {type === "document" ? 
                    <RevisionDocumentStatusList contractId={contractId} />
                    :
                    <RevisionStatusList contractId={contractId} />
                }
            </AccordionItemBrand>

            {/*uiOptions.isOnRevision && (
                <AccordionItemBrand title={t("roles")}>
                    <RevisionRolesList />
                </AccordionItemBrand>
            )*/}
        </>
    );
};

export default RevisionAccordionItems;
