import { endOfDay, isAfter, isBefore, startOfDay } from "date-fns";
import { useMemo, useState } from "react";
import { BiddingType } from "src/models/biddings/BiddingType.model";

const useBiddingTypesFilter = (biddings: BiddingType[]) => {
    const [filterDates, setFilterDates] = useState<{ from: Date; to: Date }>(null);
    const [filterText, setFilterText] = useState<string>(null);
    const [dateFilterField, setDateFilterField] = useState<TimeStampField>(null);
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

    function handleDateFilter({ from, to }: { from: any; to: any }, dateField: TimeStampField) {
        setIsFilterActive(true);
        setFilterDates({ from: from ? new Date(from) : null, to: to ? new Date(to) : null });
        setDateFilterField(dateField);
    }

    function handleSearchFilter(text: string) {
        if (text) {
            setFilterText(text);
        } else {
            setFilterText(null);
        }
    }

    function clearFilters() {
        setIsFilterActive(false);
        setFilterDates(null);
        setDateFilterField(null);
    }

    const filteredBiddings = useMemo(() => {
        let filtered = biddings;
        if (filterDates?.from && filterDates?.to) {
            //is between
            filtered = filtered.filter(
                (t) =>
                    isAfter(new Date(t[dateFilterField]), startOfDay(filterDates.from)) &&
                    isBefore(new Date(t[dateFilterField]), endOfDay(filterDates.to))
            );
        } else if (filterDates?.from) {
            //is after
            filtered = filtered.filter((t) => isAfter(new Date(t[dateFilterField]), startOfDay(filterDates.from)));
        } else if (filterDates?.to) {
            //is before
            filtered = filtered.filter((t) => isBefore(new Date(t[dateFilterField]), endOfDay(filterDates.to)));
        }

        if (filterText) {
            filtered = filtered.filter(
                (t) =>
                    t.name.toLowerCase().includes(filterText.toLowerCase()) ||
                    t.documentSet?.name.toLowerCase().includes(filterText.toLowerCase())
            );
        }

        return filtered;
    }, [biddings, filterDates, dateFilterField, filterText]);

    return { handleDateFilter, filteredBiddings, isFilterActive, clearFilters, handleSearchFilter };
};

export default useBiddingTypesFilter;
