import { Button, FormControl, FormErrorMessage, Input, InputGroup } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiEdit } from "react-icons/bi";
import useCustomToast from "src/hooks/useCustomToast";
import { TranslationKeys } from "src/i18n/en";
import { BiddingPowerBiModalData } from "src/models/Modals.models";
import biddingService from "src/services/biddings/bidding.service";
import useBiddingState from "src/store/biddings/biddingState";
import CommonModal from "../../CommonModal";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: BiddingPowerBiModalData;
};

const ShowBiddingPowerBiModal = ({ isOpen, onClose, data }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();

    const getBidding = useBiddingState((s) => s.getBidding);
    const currentBidding = data?.bidding;

    const [newPowerBiUrl, setNewPowerBiUrl] = useState<string>(currentBidding?.urlPowerBi ?? "");
    const [newSavedUrl, setNewSavedUrl] = useState<string>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    async function updateUrlPowerBi() {
        if (!newPowerBiUrl || !validPowerBiUrl(newPowerBiUrl)) {
            toast.error("La URL ingresada no es válida");
            return;
        }
        const res = await biddingService.updateUrlPowerBi(currentBidding.id, newPowerBiUrl);

        if (res?.data?.codigo === 0) {
            toast.success("URL actualizada correctamente");
            getBidding(currentBidding.id);
            setNewSavedUrl(newPowerBiUrl);
            setIsEditing(false);
        } else {
            toast.error("Ocurrió un error al actualizar la URL");
        }
    }

    return (
        <CommonModal size="6xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full">
                <h3 className="title-3 text-center">{currentBidding?.name}</h3>
                {currentBidding?.urlPowerBi && !isEditing ? (
                    <div className="mt-4 relative">
                        <button
                            className="absolute top-0 left-0 text-gray-100 bg-brand-500/70 p-[2px] rounded-lg"
                            onClick={() => setIsEditing(true)}
                        >
                            <BiEdit size={32} color="inherit" />
                        </button>
                        <iframe
                            title={currentBidding?.name + " Power BI"}
                            width={"100%"}
                            height="600px"
                            src={newSavedUrl || currentBidding?.urlPowerBi}
                            allowFullScreen={true}
                        ></iframe>
                    </div>
                ) : (
                    <div className="py-12 min-h-[500px] flex flex-col justify-center items-center">
                        {isEditing ? (
                            <>
                                <h4 className="text-xl font-medium text-gray-700 text-center">Editar URL Power BI</h4>
                                <div className="w-7/12 mt-2">
                                    <FormControl isInvalid={false}>
                                        <label htmlFor="urlPowerBi" className="text-sm leading-8">
                                            URL Power BI
                                        </label>
                                        <InputGroup borderColor="gray.400">
                                            <Input
                                                id="urlPowerBi"
                                                name="urlPowerBi"
                                                type="text"
                                                rounded="none"
                                                onChange={(e) => setNewPowerBiUrl(e.target.value)}
                                                value={newPowerBiUrl}
                                            />
                                        </InputGroup>
                                        <FormErrorMessage>{""}</FormErrorMessage>
                                    </FormControl>
                                </div>
                            </>
                        ) : (
                            <h4 className="text-xl font-medium text-gray-700 text-center">{t("noPowerBIURLasociatedWithProcess")}</h4>
                        )}
                        <div className="flex justify-center mt-4">
                            {!isEditing ? (
                                <Button colorScheme="brand" size="sm" onClick={() => setIsEditing(true)}>
                                    Editar URL
                                </Button>
                            ) : (
                                <div>
                                    <Button colorScheme="gray" size="sm" onClick={() => setIsEditing(false)}>
                                        Cancelar
                                    </Button>
                                    <span className="mx-2"></span>

                                    <Button colorScheme="brand" size="sm" onClick={updateUrlPowerBi}>
                                        Actualizar URL
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </CommonModal>
    );
};

export default ShowBiddingPowerBiModal;

function validPowerBiUrl(url: string) {
    const regex = /https:\/\/app\.powerbi\.com\/view\?r=.*$/g;
    return regex.test(url);
}
