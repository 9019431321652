import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { Accordion, Button, AccordionButton, AccordionIcon, Badge } from "@chakra-ui/react";
import AccordionItemBrand from "../common/AccordionItemBrand";
import useBiddingState from "src/store/biddings/biddingState";
import useBiddingProfileState from "src/store/biddings/biddingProfileState";
import { useEffect, useState } from "react";
import { DataGroup } from "src/models/DataGroup.models";
import { ExpedientModalData } from "src/models/Modals.models";
import useCustomToast from "src/hooks/useCustomToast";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { MdDisabledVisible } from "react-icons/md";
import { BiAddToQueue } from "react-icons/bi";
import { FormControl, Input, InputGroup, Tooltip, useBoolean, Alert, AlertIcon } from "@chakra-ui/react";
import { GrRadialSelected, GrRadial } from "react-icons/gr";
import useAuthState from "../../store/authState";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import React from "react";
import { UserGroup } from "src/models/Aministration.models";
import { IoClose } from "react-icons/io5";
import UserIcon from "src/components/icons/UserIcon";
import useBiddingTypesState from "src/store/biddings/biddingTypesState";
import GoBackLinkButton from "../common/buttons/GoBackLinkButton";
import useModalState from "src/store/modalsState";
import BiddingNotificationsConfigModal from "../common/modals/biddings/BiddinTypeNotificationsConfigModal";
import { MdOutlineNotificationAdd } from "react-icons/md";
import useBiddingNotificationState from "src/store/biddings/biddingNotificationsState";
import { MdOutlineEditNotifications } from "react-icons/md";
import { IoTrashOutline } from "react-icons/io5";

const PopulateGroupsBidding = ({
    stage,
    group,
    updatedData,
    setUpdatedData,
    handleOpenConfigModal,
    topicList,
    handleDeleteTopic,
    messageGenerator,
    componentIdName,
}) => {
    const handleToggleValidity = (e, itemName) => {
        e.preventDefault();
        const updatedDataCopy = [...updatedData];
        const updatedDataWithFalse = updateisValidGroup(itemName, updatedDataCopy);
        setUpdatedData(updatedDataWithFalse);
    };

    const updateisValidGroup = (groupName, updatedDataCopy) => {
        const updatedDataWithFalse = updatedDataCopy.map((item) => {
            if (item.name === stage) {
                item.groups.forEach((group) => {
                    if (group.name === groupName) {
                        group.isValid = !group.isValid;
                    }
                    return group;
                });
            }
            return item;
        });
        return updatedDataWithFalse;
    };

    const handleToggleValidityRequiriment = (itemName, groupName) => {
        const updatedDataCopy = [...updatedData];
        const updatedDataWithFalse = updatedDataCopy.map((item) => {
            if (item.name === stage) {
                item.groups.forEach((group) => {
                    if (group.name === groupName) {
                        group.requirements.map((req) => {
                            if (req.name === itemName) {
                                req.isValid = !req.isValid;
                            }
                            if (group.requirements.length === 0) {
                                //agregar validacion cuando no exista ningun req marcado
                                group.isValid = false;
                                updateisValidGroup(groupName, updatedDataCopy);
                                setUpdatedData(updatedDataWithFalse);
                            }
                            return req;
                        });
                    }
                    return group;
                });
            }
            return item;
        });
        setUpdatedData(updatedDataWithFalse);
    };

    return (
        <div>
            {group.map((group, idx) => {
                return (
                    <div>
                        <Tabs id={idx}>
                            <TabList>
                                <Tooltip label="Configurar notificaciones de grupo" fontSize="15px">
                                    <Tab>
                                        <div id={idx} className="flex flex-row lg:flex-row  grid-cols-2 gap-4 ">
                                            <div id={idx}>{`Grupo : ${group.name.replaceAll('!', '/')}`}</div>
                                            <div className="flex items-center">
                                                {topicList.some((topic) => topic.componentId === componentIdName(group)) && (
                                                    <p className="mr-2 text-[#2e54b8] font-semibold">{messageGenerator(group)}</p>
                                                )}
                                                <button className="text-gray-700" onClick={(e) => handleOpenConfigModal(group)}>
                                                    {topicList.some((topic) => topic.componentId === componentIdName(group)) ? (
                                                        <MdOutlineEditNotifications
                                                            style={{
                                                                color: "#1cb81e",
                                                                width: "25px",
                                                                height: "25px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <MdOutlineNotificationAdd
                                                            style={{
                                                                color: "#2e54b8",
                                                                width: "25px",
                                                                height: "25px",
                                                            }}
                                                        />
                                                    )}
                                                </button>
                                                <button className="text-gray-700 ml-2" onClick={() => handleDeleteTopic(group)}>
                                                    {topicList.some((topic) => topic.componentId === componentIdName(group)) && (
                                                        <IoTrashOutline
                                                            style={{
                                                                color: "#d10404",
                                                                width: "25px",
                                                                height: "25px",
                                                            }}
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tooltip>
                            </TabList>
                            <TabPanels id={idx}>
                                <TabPanel>
                                    <ul key={idx} className="mb-4">
                                        {group.isValid
                                            ? ""
                                            : group.requirements.map((op, i) => {
                                                  return (
                                                      <li key={i} className="mb-2 flex">
                                                          <label className="flex items-center">
                                                              {`${op.name}`}
                                                              {topicList.some((topic) => topic.componentId === componentIdName(op)) && (
                                                                  <p className="mx-2 text-[#2e54b8] font-semibold">
                                                                      {messageGenerator(op)}
                                                                  </p>
                                                              )}
                                                              <button
                                                                  className="text-gray-700 ml-2 w-fit"
                                                                  onClick={(e) => handleOpenConfigModal(op)}
                                                              >
                                                                  {topicList.some((topic) => topic.componentId === componentIdName(op)) ? (
                                                                      <MdOutlineEditNotifications
                                                                          style={{
                                                                              color: "#1cb81e",
                                                                              width: "20px",
                                                                              height: "20px",
                                                                          }}
                                                                      />
                                                                  ) : (
                                                                      <MdOutlineNotificationAdd
                                                                          style={{
                                                                              color: "#2e54b8",
                                                                              width: "20px",
                                                                              height: "20px",
                                                                          }}
                                                                      />
                                                                  )}
                                                              </button>
                                                          </label>
                                                          <button className="text-gray-700 ml-2" onClick={() => handleDeleteTopic(op)}>
                                                              {topicList.some((topic) => topic.componentId === componentIdName(op)) && (
                                                                  <IoTrashOutline
                                                                      style={{
                                                                          color: "#d10404",
                                                                          width: "20px",
                                                                          height: "20px",
                                                                      }}
                                                                  />
                                                              )}
                                                          </button>
                                                      </li>
                                                  );
                                              })}
                                    </ul>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                );
            })}
        </div>
    );
};

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    isNewProfile?: boolean;
    tempData?: any;
    data?: any;
    biddingId?: string;
    templateId?: string;
};

const CreateProfileBidding = ({ isOpen, onClose, isNewProfile, tempData, data, biddingId, templateId }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const { createBiddingProfile, updateBiddingProfile } = useBiddingProfileState();
    const {
        getBiddingType,
        infoBiddingTypeById: { data: infoBiddingTypeById },
    } = useBiddingTypesState();
    // const hasDataGroups = data?.documentSet.stages;
    const [typeName, setTypeName] = useState(tempData && tempData.name !== undefined ? tempData.name : "");
    const [description, setDescription] = useState(tempData && tempData.description !== undefined ? tempData.description : "");
    const [company, setCompany] = useState(null);
    // const [biddingStages] = useState(data?.documentSet.stages);
    const [copyBidding, setCopyBidding] = useState([]);
    const [invalidName, setInvalidName] = useBoolean(false);
    const [updatedData, setUpdatedData] = useState(data);

    const [filteredStages, setFilteredStages] = useState<any[]>([]);
    const idCompany = useAuthState.getState().companyId;
    const [biddingTypeData] = useState(data);
    const [showBiddingNotificationsConfigModal, setShowBiddingNotificationsConfigModal] = useBoolean(false);
    const [isRequirementModal, setIsRequirementModal] = useState(false);
    const [topicList, setTopicList] = useState([]);
    const [tempComponent, setTempComponent] = useState();
    const { createBiddingnotificacion, updateBiddingnotificacion, getBiddingTypeNotificacionCompany } = useBiddingNotificationState();
    const [editTopic, setEditTopic] = useState();
    const [isSignable, setIsSignable] = useState(false);
    const [componentId, setcomponentId] = useState("");

    async function handleSave(stages) {
        biddingTypeData.documentSet.stages = stages;
        let body = {
            biddingId: biddingId,
            biddingType: biddingTypeData,
            biddingTypeId: biddingTypeData.id,
            companyOwnerId: idCompany,
            description: description,
            enabled: true,
            name: typeName,
            userProfileIdList: [],
        };

        const saved = createBiddingProfile(body);
        if (saved) {
            toast.success("Se ha guardado el perfil correctamente");
            // getBiddingNotificacionCompany(biddingId);
            onClose();
        } else {
            toast.error("Ha ocurrido un error al guardar el perfil");
            onClose();
        }
    }

    async function handleSaveTopic(stages) {
        if (templateId) {
            let body = {
                active: true,
                companyId: idCompany,
                componentId: biddingId,
                componentTemplateId: templateId,
                description: description,
                name: typeName,
                topicList: topicList,
                userIdList: [],
            };
            if (isNewProfile) {
                body["id"] = tempData?.id;
            }

            const saved = isNewProfile ? await updateBiddingnotificacion(body) : await createBiddingnotificacion(body);
            if (saved) {
                toast.success("Se ha generado notificación correctamente");
                getBiddingTypeNotificacionCompany(templateId);

                onClose();
            } else {
                toast.error("Ha ocurrido un error al generar notificación");
                onClose();
            }
        } else {
            onClose();
        }
    }

    async function handleUpdate(stages) {
        tempData.biddingType.documentSet.stages = stages;
        tempData.name = typeName;
        tempData.description = description;

        const saved = updateBiddingProfile(tempData);
        if (saved) {
            toast.success("Se ha actualizado el perfil correctamente");
            onClose();
        } else {
            toast.error("Ha ocurrido un error al actualizar el perfil");
            onClose();
        }
    }

    function isCreateOrEditSubscription(subscription) {
        if (isNewProfile) {
            handleUpdate(subscription);
        } else {
            handleSave(subscription);
        }
    }

    const handleToggleValidity = (e, itemName, isValid) => {
        if (isValid!) {
            e.preventDefault();
        }
        const updatedDataCopy = [...updatedData];
        const updatedDataWithToggledValidity = updatedDataCopy.map((item) => {
            if (item.name === itemName) {
                item.isValid = !item.isValid;
            }
            return item;
        });
        setUpdatedData(updatedDataWithToggledValidity);
    };

    const filterIsValidaStage = () => {
        // filtrar si es valido stage-group-requiriment
        const updatedDataCopy = [...updatedData];
        const updatedDataWithFalse = updatedDataCopy.map((item) => {
            item.groups = item.groups.filter((group) => !group.isValid);

            item.groups.forEach((group) => {
                group.requirements = group.requirements.filter((requirement) => requirement.isValid); // Filtrar los requisitos que tienen isValid como false
                return group;
            });
            return item;
        });

        const filteredData = updatedDataWithFalse.filter((item) => !item.isValid);

        // isCreateOrEditProfile(filteredData);
        setUpdatedData(filteredData);
    };

    const verifyProfileStages = () => {
        // comparar stages si profile para editar
        const updatedDataCopy = [...updatedData]; // stages del proceso original
        const profileStages = tempData?.biddingType?.documentSet.stages; // stages del profile a editar

        const updatedDataWithFalse = updatedDataCopy.map((item) => {
            const correspondingItem = profileStages?.find((otherItem) => otherItem.name === item.name);

            if (correspondingItem) {
                item.groups.forEach((group) => {
                    const correspondingGroup = correspondingItem.groups?.find((otherGroup) => otherGroup.name === group.name);

                    if (correspondingGroup) {
                        group.requirements.forEach((requirement) => {
                            const correspondingRequirement = correspondingGroup.requirements?.find(
                                (otherRequirement) => otherRequirement.name === requirement.name
                            );

                            if (correspondingRequirement) {
                                item.isValid = true;
                                group.isValid = true;
                                requirement.isValid = false;
                            }

                            return requirement;
                        });
                    }

                    return group;
                });
            }

            return item;
        });
        setUpdatedData(updatedDataWithFalse);
    };

    const handleUpdateData = () => {
        const updatedDataCopy = updatedData && [...updatedData];

        const updatedDataWithFalse =
            updatedDataCopy &&
            updatedDataCopy?.map((item) => {
                item.isValid = false;
                item.groups.forEach((group) => {
                    group.isValid = false;
                    group.requirements.forEach((requirement) => {
                        requirement.isValid = true;
                        return requirement;
                    });
                    return group;
                });
                return item;
            });
        setUpdatedData(updatedDataWithFalse);
    };

    const handleOpenConfigModalComponent = (dg) => {
        setTempComponent(dg);

        setcomponentId(componentIdName(dg));

        const topicToEdit = topicList?.find((topic) => topic.componentId === componentIdName(dg));

        if (topicToEdit) {
            setEditTopic(topicToEdit);
        }
        setShowBiddingNotificationsConfigModal.on();
    };

    const componentIdName = (dg) => {
        let nombreComponente = "";

        if (dg.groups) {
            nombreComponente = dg.name;
        } else if (dg.requirements) {
            const stage = data.find((item) => item.groups && item.groups.find((group) => group === dg));
            if (stage) {
                nombreComponente = `${stage.name}||${dg.name}`;
            }
        } else {
            const group = data.reduce((groupEncontrado, stage) => {
                if (groupEncontrado) {
                    return groupEncontrado;
                }
                const grupoEncontrado =
                    stage.groups && stage.groups.find((group) => group.requirements && group.requirements.find((req) => req === dg));
                if (grupoEncontrado) {
                    return grupoEncontrado;
                }
            }, null);

            if (group) {
                const stage = data.find((item) => item.groups && item.groups.find((grupo) => grupo === group));
                if (stage) {
                    nombreComponente = `${stage.name}||${group.name}||${dg.name}`;
                }
            }
        }

        return nombreComponente;
    };

    const formatName = (name) => {
        return name.replace(/\s/g, "");
    };

    const handleOpenConfigModal = (isRequirement = false) => {
        setShowBiddingNotificationsConfigModal.on();
        if (isRequirement) {
            setIsRequirementModal(true);
            return;
        }
        setIsRequirementModal(false);
    };

    useEffect(() => {
        setUpdatedData(copyBidding);
        handleUpdateData();
        if (isNewProfile) {
            verifyProfileStages();
        }
    }, []);

    const handleAddTopicList = (newTopic) => {
        const existingIndex = topicList?.findIndex((topic) => {
            return topic.componentId === newTopic.componentId;
        });

        if (existingIndex !== -1) {
            const updatedTopicList = [...topicList];
            updatedTopicList[existingIndex] = newTopic;
            setTopicList(updatedTopicList);
            setShowBiddingNotificationsConfigModal.off();
        } else {
            setTopicList((prevTopicList) => [...prevTopicList, newTopic]);
            setShowBiddingNotificationsConfigModal.off();
        }
    };

    const handleDeleteTopic = (topicToDelete) => {
        const updatedTopicList = topicList.filter((topic) => {
            return topic.componentId !== componentIdName(topicToDelete);
        });

        setTopicList(updatedTopicList);
        setEditTopic(null);
    };

    const messageGenerator = (individualTopic) => {
        const topic = topicList?.find((topic) => topic.componentId === componentIdName(individualTopic));

        const topicEnum = {
            complete: {
                name: "Al completar",
                value: topic?.topPercentage ? `${topic.topPercentage}%` : "",
            },
            upload: {
                name: "Al subir",
                value: topic?.delete ? "y al eliminar" : "",
            },
            update: {
                name: "Al actualizar",
                value: {
                    reviewUpdate: "estatus de revisión",
                    signUpdate: "estatus de firma",
                    versionUpdate: "cambio de versión",
                },
            },
        };

        const notifications = topic?.topicTypeEnum && topicEnum[topic.topicTypeEnum];

        if (notifications) {
            if (topic.topicTypeEnum === "update") {
                return `${notifications.name} ${notifications.value[topic.subTopicTypeEnum]}`;
            } else {
                return `${notifications.name} ${notifications.value}`;
            }
        } else {
            return "";
        }
    };

    const isDocumentTypePresent = (data) => {
        let isPresent = false;
        data.forEach((item) => {
            if (Array.isArray(item.groups)) {
                item.groups.forEach((group) => {
                    if (Array.isArray(group.requirements)) {
                        group.requirements.forEach((requirement) => {
                            if (
                                Array.isArray(requirement.documentTypeId) &&
                                (requirement.documentTypeId.includes("655ff58b1df4f015436bed44") ||
                                    requirement.documentTypeId.includes("63c90b4aea18686b30cf3848"))
                            ) {
                                isPresent = true;
                            }
                        });
                    }
                });
            }
        });

        setIsSignable(isPresent);
    };

    useEffect(() => {
        if (updatedData) {
            isDocumentTypePresent(updatedData);
        }
    }, [updatedData]);

    useEffect(() => {
        if (tempData?.topicList?.length > 0) {
            setTopicList(tempData?.topicList);
        }
    }, [tempData]);

    return (
        <div className="w-full flex flex-col h-full relative pb-2 pt-6">
            {
                <div className="w-full flex items-end justify-between gap-6 sticky top-0">
                    <div className="ml-4 text-md">
                        <GoBackLinkButton onClick={() => onClose(true)} />
                    </div>
                    <div className="mr-6">
                        <Button rounded="full" onClick={handleSaveTopic} disabled={!typeName || !description}>
                            Guardar
                        </Button>
                    </div>
                </div>
            }

            {updatedData ? (
                <div className="px-2 mt-2 overflow-auto">
                    <div className="title-2 w-full flex flex-wrap justify-between items-center gap-2 max-h-[15vh] overflow-y-auto px-2">
                        <FormControl flex={1} isInvalid={invalidName} w="38%">
                            <label htmlFor="type_name" className="text-sm leading-8 ">
                                {t("configName")}
                            </label>
                            <InputGroup borderColor="gray.400" className="rounded-lg border-2">
                                <Input
                                    id="type_name"
                                    type="text"
                                    rounded="none"
                                    value={typeName}
                                    placeholder={t("name")}
                                    onChange={(e) => setTypeName(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl flex={1} isInvalid={invalidName} w="48%">
                            <label htmlFor="type_name" className="text-sm leading-8 ">
                                {t("description")}
                            </label>
                            <InputGroup borderColor="gray.400" className="rounded-lg border-2">
                                <Input
                                    id="type_name"
                                    type="text"
                                    rounded="none"
                                    value={description}
                                    placeholder={t("description")}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                    </div>
                    <div className="ml-2 pt-2 pb-2">
                        <h2 className="title-3">{t("processNotificationConfig")}</h2>
                        <h3 className="text-dark">{t("configEvents")}.</h3>
                    </div>
                    <div className="w-full h-full overflow-y-auto">
                        <Accordion allowToggle className="mb-3 overflow-y-auto">
                            {updatedData.map((dg, idx) => {
                                return (
                                    <AccordionItemBrand
                                        key={idx}
                                        title={`Etapa: ${dg.name}`}
                                        actions={
                                            <div className="flex items-center">
                                                <p className="mr-2 text-[#2e54b8] font-semibold">{messageGenerator(dg)}</p>
                                                <Tooltip label={"Configurar notificaciones de la etapa"} fontSize="12px">
                                                    <button className="text-gray-700" onClick={() => handleOpenConfigModalComponent(dg)}>
                                                        {topicList.some((topic) => topic.componentId === dg.name) ? (
                                                            <MdOutlineEditNotifications
                                                                style={{
                                                                    color: "#1cb81e",
                                                                    width: "30px",
                                                                    height: "30px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <MdOutlineNotificationAdd
                                                                style={{
                                                                    color: "#2e54b8",
                                                                    width: "30px",
                                                                    height: "30px",
                                                                }}
                                                            />
                                                        )}
                                                    </button>
                                                </Tooltip>
                                                <Tooltip label={"Eliminar configuración"} fontSize="12px">
                                                    <button className="text-gray-700 ml-2" onClick={() => handleDeleteTopic(dg)}>
                                                        {topicList.some((topic) => topic.componentId === dg.name) && (
                                                            <IoTrashOutline
                                                                style={{
                                                                    color: "#d10404",
                                                                    width: "30px",
                                                                    height: "30px",
                                                                }}
                                                            />
                                                        )}
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        }
                                    >
                                        <PopulateGroupsBidding
                                            stage={dg.name}
                                            group={dg.groups}
                                            updatedData={updatedData}
                                            setUpdatedData={setUpdatedData}
                                            handleOpenConfigModal={handleOpenConfigModalComponent}
                                            topicList={topicList}
                                            handleDeleteTopic={handleDeleteTopic}
                                            messageGenerator={messageGenerator}
                                            componentIdName={componentIdName}
                                        />
                                    </AccordionItemBrand>
                                );
                            })}
                        </Accordion>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center h-full">
                    <h3 className="text-dark">No hay datos para mostrar</h3>
                </div>
            )}
            {showBiddingNotificationsConfigModal && (
                <BiddingNotificationsConfigModal
                    isOpen={true}
                    onClose={() => setShowBiddingNotificationsConfigModal.off()}
                    // isReq={isRequirementModal}
                    onSave={handleAddTopicList}
                    // groupName={tempGroupName}
                    tempComponent={tempComponent}
                    editTopic={editTopic}
                    isSignable={isSignable}
                    componentId={componentId}
                    componentIdName={componentIdName}
                />
            )}
        </div>
    );
};

export default CreateProfileBidding;
