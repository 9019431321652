import { TiChevronLeft, TiChevronRight } from "react-icons/ti";

type Props = {
    onNext: (arg?: any) => any;
    onPrev: (arg?: any) => any;
    onSetPage: (page: number) => any;
    currentPage: number;
    maxPages: number;
};

const Paginator = ({ currentPage, maxPages, onNext, onPrev, onSetPage }: Props) => {
    if (maxPages === 1) return null;

    return (
        <div className="flex items-center text-brand-500 justify-center bg-white gap-2">
            <button
                className="w-8 h-8 flex justify-center items-center disabled:text-brand-500/40 disabled:cursor-not-allowed border-2 border-brand-500 p-2 rounded-lg"
                onClick={onPrev}
                disabled={currentPage === 1}
            >
                <TiChevronLeft />
            </button>
            <PagesButtons currentPage={currentPage} maxPages={maxPages} onClick={onSetPage} />
            <button
                className="w-8 h-8 flex justify-center items-center disabled:text-brand-500/40 disabled:cursor-not-allowed border-2 border-brand-500 p-2 rounded-lg"
                onClick={onNext}
                disabled={currentPage === maxPages}
            >
                <TiChevronRight />
            </button>
        </div>
    );
};

export default Paginator;

const PagesButtons = ({ onClick, currentPage, maxPages }: any) => {
    const showedPagesCount = maxPages < 5 ? maxPages : 5;
    const threshold = 2;
    const end =
        currentPage + threshold < showedPagesCount
            ? showedPagesCount
            : currentPage + threshold < maxPages
            ? currentPage + threshold
            : maxPages;
    const start = end - showedPagesCount + 1;

    const showingPages = Array.from({ length: end - start + 1 }, (_, i) => i + start);
    return (
        <>
            {showingPages.map((page) => (
                <button
                    key={page}
                    className={`w-8 h-8 flex justify-center items-center disabled: text-white rounded-lg ${
                        currentPage == page ? "bg-brand-500" : "bg-brand-500/50"
                    }`}
                    onClick={() => onClick(page)}
                    //disabled={currentPage === pageNumber}
                >
                    {page}
                </button>
            ))}
        </>
    );
};
