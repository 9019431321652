import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useContractState from "../../../store/contractState";
import AccordionItemBrand from "../../common/AccordionItemBrand";
import SignaturesStatusList from "./SignaturesStatusList";

const SignaturesAccordionItems = ({ documentId }: { documentId: string }) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const uiOptions = useContractState((s) => s.uiOptions);
    return (
        <>
            <AccordionItemBrand title={t("signatures")}>
                <SignaturesStatusList documentId={documentId} />
            </AccordionItemBrand>
            {/* {uiOptions.isOnRevision && (
                <AccordionItemBrand title="Roles">
                    <RevisionRolesList />
                </AccordionItemBrand>
            )} */}
        </>
    );
};

export default SignaturesAccordionItems;
