import { TranslationKeys } from "src/i18n/en";
import CommonModal from "../../CommonModal";
import CommonTh from "../../table/CommonTh";
import { useTranslation } from "react-i18next";
import UsersOtpAdmin from "src/components/administration/UsersOtpAdmin";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
};

const ShowUsersOtpModal = ( { isOpen, onClose }: Props ) => {
    /** */
    const t: ( key: TranslationKeys ) => string = useTranslation( "global" )[0];
    // const 

    return (
        <CommonModal size="3xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div className="text-center">
                    <h2 className="title-3">
                        {t("userExceptions")}
                    </h2>
                </div>
                <UsersOtpAdmin />
            </div>
        </CommonModal>
    );
}

export default ShowUsersOtpModal;