import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

const validationTypeAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/generadorBidding`,
    timeout: 20000,
});


const getGeneradorBiddingStatus = async (biddingId: string) => {
    try {
        return await validationTypeAxiosRequest.get(`/biddingId/${biddingId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createGeneradorBiddingStatus = async (body: any) => {
    try {
        return await validationTypeAxiosRequest.put(`/addGeneradorBidding`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};



export default { 
    getGeneradorBiddingStatus, 
    createGeneradorBiddingStatus 
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
