const Item = ({ value, label, done }: { value: string | number; label: string; done: boolean }) => {
    return (
        <div className="text-center flex flex-col items-center">
            <div
                className={`relative z-10 ${
                    done ? "bg-brand-500" : "bg-gray-400"
                }  text-white w-12 h-12 rounded-full text-center leading-[3rem] text-2xl`}
            >
                {value}
            </div>
            <div className={`text-xs leading-tight ${done ? "text-brand-500" : "text-gray-400"} font-light w-[6rem] mt-2 italic`}>
                {label}
            </div>
        </div>
    );
};

type Props = {
    labels: string[];
    currentItem: number;
    className?: string;
    stepTemplate?: string;
};

const Steps = ({ className = "", labels, currentItem,stepTemplate }: Props) => {
    const totalItems = labels.length;
    const annexIsDone = stepTemplate === "borrador";

    const width = (100 / (totalItems - 1)) * (currentItem - 1 );

    return (
        <div className={"flex items-center w-full h-24 relative " + className}>
            {!annexIsDone && 
            <>
            <div className="absolute top-0 bottom-0 flex items-center z-0" style={{ left: "6%", right: "6%" }}>
             <div className="h-2 bg-gray-400 w-full"></div>
            </div>

            <div className="absolute top-0 bottom-0 flex items-center z-0" style={{ left: "6%", right: "6%" }}>
             <div className="h-2 bg-brand-500" style={{ width: `${width}%` }}></div>
            </div>
            </>
            }
            
            <div className="absolute top-[.6rem] bottom-0 left-0 right-0 h-full w-full flex justify-between items-center z-20">
                {labels.map((label, idx) => {
                    return <Item key={label + idx} value={idx + 1} label={label} done={idx < currentItem } />;
                })}
            </div>
        </div>
    );
};

export default Steps;
