import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box } from "@chakra-ui/react";

import React, { useState, useEffect } from "react";
import { RiFileSettingsFill } from "react-icons/ri";
import { extensionIcons } from "../../Utils/iconsSelector";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { BsTable } from "react-icons/bs";

const RadioList = ({ onSaveTypeRequirementId, biddingDocumentTypes, typeOptions }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    const [selectedOptions, setSelectedOptions] = useState([]);

    onSaveTypeRequirementId(selectedOptions);

    useEffect(() => {
        if (typeOptions !== undefined) {
            setSelectedOptions(typeOptions);
        }
    }, [typeOptions]);

    const handleChange = (event) => {
        const selectedValue = event.target.value;

        if (selectedValue === "63c90b4aea18686b30cf3848" || selectedValue === "655ff58b1df4f015436bed44" || selectedValue === "6696df26067ff3bdee38a132" || selectedValue === "6696df4d067ff3bdee38a133") {
            //655ff58b1df4f015436bed44
            setSelectedOptions([selectedValue]);
            onSaveTypeRequirementId(selectedOptions);
        } else {
            if (selectedOptions.includes("63c90b4aea18686b30cf3848") || selectedOptions.includes("655ff58b1df4f015436bed44") || selectedOptions.includes("6696df26067ff3bdee38a132") || selectedOptions.includes("6696df4d067ff3bdee38a133") ) {
                setSelectedOptions([selectedValue]);
                onSaveTypeRequirementId(selectedOptions);
            } else {
                if (selectedOptions.includes(selectedValue)) {
                    setSelectedOptions(selectedOptions.filter((option) => option !== selectedValue));
                    onSaveTypeRequirementId(selectedOptions);
                } else {
                    setSelectedOptions([...selectedOptions, selectedValue]);
                    onSaveTypeRequirementId(selectedOptions);
                }
            }
        }
        onSaveTypeRequirementId(...selectedOptions);
    };

    const isOptionSelected = (option) => selectedOptions.includes(option);

    return (
        <div className="w-full">
            <label className="text-sm leading-8">{t("requirementType")}</label>
            <Accordion allowToggle minW="31.3rem">
                <AccordionItem>
                    <h2>
                        <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                                Seleccionar
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel pb={8} className="text-sm leading-6">
                        <ul>
                            {biddingDocumentTypes.map((option, index) => (
                                <li key={option.id} className="flex items-center mb-2">
                                    <label className="flex-1 pl-2 text-gray-700 flex items-center">
                                        <input
                                            type="checkbox"
                                            name="options"
                                            value={option.id}
                                            checked={isOptionSelected(option.id)}
                                            onChange={handleChange}
                                            className="mr-2"
                                        />
                                        {option.name === "SCM" ? (
                                            <RiFileSettingsFill size="20" className="mr-2" color="#001fb1" />
                                        ) : option.name === "Tabla Dinámica" ? (
                                            <BsTable className="mr-2" size="15" color="#001fb1" />
                                        ) : (
                                            <img
                                                key={index}
                                                src={extensionIcons[option?.extension?.toLowerCase()] || ""}
                                                alt={option}
                                                className="icono-clase-css mr-2 w-6"
                                            />
                                        )}
                                        {`${option.name === "SCM" ? "Documento Dinámico " : option.name}`}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
            {selectedOptions.includes("63c90b4aea18686b30cf3848") && (
                <div className="text-sm leading-7 text-teal-500">
                    <p>No se pueden seleccionar más opciones si el tipo de Requerimiento es SmartContract.</p>
                </div>
            )}
            {selectedOptions.includes("655ff58b1df4f015436bed44") && (
                <div className="text-sm leading-7 text-teal-500">
                    <p>No se pueden seleccionar más opciones si el tipo de Requerimiento es PDF con Firma Digital.</p>
                </div>
            )}
            {selectedOptions.includes("6696df4d067ff3bdee38a133") && (
                <div className="text-sm leading-7 text-teal-500">
                    <p>No se pueden seleccionar más opciones si el tipo de AX ROADMAP.</p>
                </div>
            )}
            {selectedOptions.includes("6696df26067ff3bdee38a132") && (
                <div className="text-sm leading-7 text-teal-500">
                    <p>No se pueden seleccionar más opciones si el tipo de AX CALC.</p>
                </div>
            )}
        </div>
    );
};

export default RadioList;
