import React from "react"

interface BrandBoxProps {
    children: React.ReactNode
    className?: string
}

const BrandBox: React.FC<BrandBoxProps> = ({ children, className }) => {
    /** */
    return (
        <div className={`w-full rounded p-4 shadow-sm bg-white ${className}`}>
            {children}
        </div>
    )
}

export default BrandBox