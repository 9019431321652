import { FormControl, Input, InputGroup } from "@chakra-ui/react";
import { Formik } from "formik";
import debounce from "lodash/debounce";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import escapeRegExp from "lodash/escapeRegExp";
import { replaceAll } from "src/lib/utils";
import { DataGroup, DataGroupType } from "src/models/DataGroup.models";

type Props = {
    dataGroupIdx: number;
    dataGroup: Partial<DataGroup>;
    setPopulatedDataGroups: (prevState: any) => any;
};

const ExpedientDynamicForm = ({ dataGroupIdx, dataGroup, setPopulatedDataGroups }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    //modifico temporalmente idNames para evitar conflicto con libreria formik
    const safedDataGroupTypes = useMemo(() => {
        return dataGroup.types.map((dt, rawIdx) => {
            return { ...dt, isUsed: true, rawIdx, idName: replaceAll(dt.idName, escapeRegExp("."), "___") };
        });
    }, [dataGroup.id]);

    const initialValues = useMemo(() => {
        let temp = {};
        safedDataGroupTypes?.forEach((dt) => {
            temp[dt.idName] = dt.value;
        });
        return temp;
    }, [dataGroup.id]);

    const valuesValidations: { idName: string; required: true; regexp: null }[] = useMemo(
        () => safedDataGroupTypes?.map((dt) => ({ idName: dt.idName, required: true, regexp: null })),
        [dataGroup.id]
    );

    const debouncedHandleDataInput = useCallback(debounce(handleDataInput, 500), []);
    function handleDataInput(value: any, dataTypeIdx: number) {
        setPopulatedDataGroups((prevState) => {
            prevState[dataGroupIdx].types[dataTypeIdx].value = value;
            return [...prevState];
        });
    }

    function validateErrors(values) {
        const errors: any = {};

        valuesValidations.forEach((val) => {
            if (val.required && !values[val.idName]) {
                errors[val.idName] = "Required";
            }
            if (val.regexp && true /*test regexp*/) {
                errors[val.idName] = "Ivalid field"; //custom message
            }
        });

        return errors;
    }

    const usedDataGroupTypes = useMemo(() => {
        return safedDataGroupTypes?.filter((dt) => dt.isUsed);
    }, [dataGroup.id]);

    return (
        <Formik validateOnChange initialValues={initialValues} validate={validateErrors} onSubmit={() => {}}>
            {({ values, errors, touched, handleChange, handleBlur }) => {
                return (
                    <form className="mt-2 grid grid-cols-3 gap-3">
                        {usedDataGroupTypes?.length ? (
                            usedDataGroupTypes.map((dt, idx) => (
                                <FormControl key={dt.idName} isInvalid={!!errors[dt.idName] && !!touched[dt.idName]}>
                                    {getDynamicFormField(
                                        dt,
                                        values[dt.idName],
                                        handleChange,
                                        (e) => debouncedHandleDataInput(e.target.value, dt.rawIdx),
                                        handleBlur
                                    )}
                                    {!!errors[dt.idName] && !!touched[dt.idName] && (
                                        <small className="text-red-500 text-xs">{t("requiredField")}</small>
                                    )}
                                </FormControl>
                            ))
                        ) : (
                            <div className="text-sm font-light italic">{t("noTypeToThisGroup")}</div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

export default ExpedientDynamicForm;

function getDynamicFormField(
    dt: DataGroupType,
    value: any,
    onChange: (value: any) => any,
    onInput: (value: any) => any,
    onBlur: any
): JSX.Element {
    switch (dt.type) {
        case "string":
        default:
            return (
                <>
                    <label htmlFor={dt.idName} className="text-sm leading-8">
                        {dt.name}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            onChange={onChange}
                            onInput={onInput}
                            onBlur={onBlur}
                            name={dt.idName}
                            id={dt.idName}
                            type="text"
                            rounded="none"
                            defaultValue={value}
                        />
                    </InputGroup>
                </>
            );
        case "double":
            return (
                <>
                    <label htmlFor={dt.idName} className="text-sm leading-8">
                        {dt.name}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            onChange={onChange}
                            onInput={onInput}
                            onBlur={onBlur}
                            name={dt.idName}
                            id={dt.idName}
                            type="number"
                            rounded="none"
                            defaultValue={value}
                        />
                    </InputGroup>
                </>
            );

        case "date":
            return (
                <>
                    <label htmlFor={dt.idName} className="text-sm leading-8">
                        {dt.name}
                    </label>
                    <InputGroup borderColor="gray.400">
                        <Input
                            onChange={onChange}
                            onInput={onInput}
                            onBlur={onBlur}
                            name={dt.idName}
                            id={dt.idName}
                            type="date"
                            rounded="none"
                            defaultValue={value}
                        />
                    </InputGroup>
                </>
            );
        // case "boolean":
        //     return (
        //         <>
        //             <label htmlFor={dt.idName} className="text-sm leading-8">
        //                 {dt.name}
        //             </label>
        //             <RadioGroup onChange={(value) => onChange(value)} defaultValue={"SI"}>
        //                 <div className="w-full flex gap-4 items-center py-2 px-4 border">
        //                     <Radio value="SI">Si</Radio>
        //                     <Radio value="NO">No</Radio>
        //                 </div>
        //             </RadioGroup>
        //         </>
        //     );
    }
}
