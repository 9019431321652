import { useEffect, useState } from "react";
import { chunksArray } from "../lib/utils";

function usePagination<T>({ data, itemsPerPage = 10 }: { data: T[]; itemsPerPage?: number }) {
    const [paginatedResults, setPaginatedResults] = useState<T[][]>([]);
    const [currentResutls, setCurrentResults] = useState<T[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState(0);

    function next() {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    function prev() {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    useEffect(() => {
        if (data) {
            const chunks = chunksArray(data, itemsPerPage);
            setPaginatedResults(chunks);
            setCurrentResults(chunks[0]);
            setMaxPages(chunks.length);
            setCurrentPage(1);
        }
    }, [data]);

    useEffect(() => {
        if (paginatedResults?.length) {
            setCurrentResults(paginatedResults[currentPage - 1]);
        }
    }, [currentPage]);

    return { currentResutls, currentPage, maxPages, prev, next, setPage: setCurrentPage };
}

export default usePagination;
