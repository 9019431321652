import { CircularProgress, Progress, Select } from "@chakra-ui/react";
import { useEffect } from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { TiChevronRight } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import contractFolderImg from "../../assets/images/contract-folder.png";
import showMoreImg from "../../assets/images/show-more.png";
import usePageZoom from "../../hooks/usePageZoom";
import { Contract } from "../../models/Contract.models";
import useContractState from "../../store/contractState";
import { formatDate, getHomeContractStatusColor, getStepNameByStatus, sliceText } from "../../lib/utils";
import LinkButton from "../common/buttons/LinkButton";
import { BsCalendarWeek } from "react-icons/bs";
import SlicedText from "../common/SlicedText";
import DottedSubtitle from "../common/DottedSubtitle";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const ContractItem = ({ contract }: { contract: Contract }) => {
    return (
        <Link to={`/contracts/${contract.id}`} className="p-3 block bg-gray-light rounded-lg">
            <div className="h-[60%] w-full bg-white rounded flex items-center justify-center">
                <img className="" src={contractFolderImg} alt="contract folder image" />
            </div>
            <div className="p-2 h-[40%] w-full mt-1 flex flex-col">
                <h5 className="text-brand-500 text-lg leading-none">
                    <SlicedText text={contract.name} maxLength={16} />
                </h5>
                <div className="flex justify-between items-center mt-1">
                    <div className="">
                        <p className="leading-0 text-sm text-brand-700 leading-tight">{sliceText(contract.userName, 16)}</p>
                        <p className="leading-0 text-sm text-brand-700 leading-tight flex items-center gap-1 mt-1">
                            <BsCalendarWeek size={14} color="inherit" />
                            {formatDate(new Date(contract?.createdDate))}
                        </p>
                    </div>
                    <div className="px-2 text-brand-500 flex flex-col items-center">
                        <img alt="icon" src={getIcon(contract.status)} height={35} width={35} />
                        <p
                            className="text-[9px] text-gray-900 font-semibold leading-tight mt-1 break-normal py-[1px] px-1 rounded-sm uppercase opacity-80"
                            style={{ backgroundColor: getHomeContractStatusColor(contract.status) }}
                        >
                            {getStepNameByStatus(contract.status)}
                        </p>
                    </div>
                </div>
            </div>
        </Link>
    );
};

const ContractList = () => {
    const { zoom } = usePageZoom();
    const navigate = useNavigate();
    const { contracts, getContractList } = useContractState();

    const recentContracts = contracts.data.slice(0, zoom <= 100 ? 6 : zoom <= 110 ? 5 : 4);
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    useEffect(() => {
        getContractList(false);
    }, []);

    if (!contracts.data?.length && contracts?.loading) {
        return (
            <div className="w-full h-40 flex justify-center items-center">
                <CircularProgress isIndeterminate />
            </div>
        );
    }

    return (
        <div className="mt-6">
            <DottedSubtitle text={t("recentDocuments")} />

            <div className="contracts-grid mt-4 max-w-full overflow-auto pb-2">
                {recentContracts?.map((item, idx) => (
                    <ContractItem key={item.id} contract={item} />
                ))}
                <div className="flex flex-col gap-4 justify-center items-center p-3 bg-gray-light rounded-lg">
                    <img src={showMoreImg} alt="ver mas" className="w-[70%]" />
                    <LinkButton onClick={() => navigate("/contracts")} fontSize="xl" rightIcon={<TiChevronRight size={20} />}>
                        {t("seeMore")}
                    </LinkButton>
                </div>
            </div>
        </div>
    );
};

export default ContractList;

function getIcon(nameState: string) {
    let icon;
    try {
        icon = require(`../../assets/contract-icons/${nameState?.toLowerCase().split(" ").join("_")}.svg`);
    } catch (error) {
        icon = require("../../assets/contract-icons/default.svg");
    }

    return icon;
}
