import create, { GetState } from "zustand";
import { devtools, NamedSet, persist } from "zustand/middleware";
import documentService from "src/services/documentRevision.service";
import useAuthState from "./authState";
import useModalState from "./modalsState";
import usePdfSignaturesState from "./pdfSignatureState";

import { CONNREFUSED } from "dns";
//getBiddingProfileCompany: (biddingId: string, withLoading?: boolean) => Promise<any | null>;

interface State {
    reviewersList: any; // Cambiado a tipo 'any' para que puedas almacenar cualquier tipo de valor
    infoDocument: any; // Variable infoContract
    isReview: boolean;
    statusDocument: any;
    setInfoDocument: (info: any) => void; // Función para actualizar infoContract
    setStatusDocument: (estatus: any) => void; // Función para actualizar estatusContract
    setIsReview: (info: any) => void; // Función para actualizar infoContract
    sendDocumentToReview: (documentId: string, biddingId: string, users: any, expirationHoursValue: any, withLoading?: boolean) => Promise<boolean>;
    getDocumentReviewStatus: (documentId: string, withLoading?: boolean) => Promise<any | null>;
    updateReviewerAnswer: (documentId: string, reviewer: any, withLoading?: boolean) => Promise<boolean>;
    getAllReviewAssociate: (documentId: string, withLoading?: boolean) => Promise<boolean>;
    deleteReviewDocument: (documentId: string, withLoading?: boolean) => Promise<boolean>;
    getReviewDocument: (documentId: string, withLoading?: boolean) => Promise<boolean>;
    reset: () => void;
}



const initialState = {
    reviewersList: null, // Cambiado a 'null' para indicar que aún no hay datos
    infoDocument: null,
    isReview: false,
    statusDocument: null,
};

const useDocumentRevisionState = create<State>(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                setInfoDocument: setInfoDocument(set, get),
                setStatusDocument: setStatusDocument(set, get),
                setIsReview: setIsReview(set, get),
                sendDocumentToReview: sendDocumentToReview(set, get),
                getDocumentReviewStatus: getDocumentReviewStatus(set, get),
                updateReviewerAnswer: updateReviewerAnswer(set, get),
                getAllReviewAssociate: getAllReviewAssociate(set, get),
                deleteReviewDocument: deleteReviewDocument(set, get),
                getReviewDocument: getReviewDocument(set, get),
                reset: () => set((state) => ({ ...state, ...initialState })),


            }),
            { name: "documentSate", getStorage: () => sessionStorage }
        ),
        { name: "DocumentRevisionState" }
    )
);



export default useDocumentRevisionState;

//FUNCTIONS

function setInfoDocument(set: NamedSet<State>, get: GetState<State>) {
    return (info: any) => {
        set({ infoDocument: info });
    };
}

function setStatusDocument(set: NamedSet<State>, get: GetState<State>) {
    return (status: any) => {
        if (status === "Aprobado") {
            set({ isReview: true });
        }
        set({ isReview: status });
    };
}

function setIsReview(set: NamedSet<State>, get: GetState<State>) {
    return (status: any) => {
        set({ isReview: status });
    };
}

function sendDocumentToReview(set: NamedSet<State>, get: GetState<State>) {
    return async (documentId, biddingId, users, expirationHours, withLoading = true,) => {
        const { companyId, userEmail, userName, userId } = useAuthState.getState();
        const { currentRequiriment,infoContract } = usePdfSignaturesState.getState();
console.log("cuurenReq",currentRequiriment)
        const body = {
            companyId: companyId,
            biddingId: biddingId,
            componentId: biddingId,
            documentId: documentId,
            expirationHours: expirationHours,
            fileId: infoContract.fileId,
            requirementName: currentRequiriment.name,
            owner: {
                email: userEmail,
                id: userId,
                name: userName
            },
            reviewerList: users,
            type: "BIDDING",
        };
        console.log(body)
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await documentService.createDocumentRevision(body);

        //  hasPermissionForAction(res,"Generar revisión de Documentos ",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating revision");
            return false;
        }
    };
}

function getDocumentReviewStatus(set: NamedSet<State>, get: GetState<State>) {
    return async (documentId, withLoading = true,) => {
        //  const { companyId, userEmail, userName, userId } = useAuthState.getState();

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await documentService.getRevisionByDocument(documentId);

        //  hasPermissionForAction(res,"Obtener status del Documento ",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            console.log("res.data.respuesta.reviewerList", res.data.respuesta)
            set({ statusDocument: res.data.respuesta });
            set({ reviewersList: res.data.respuesta});
            return true;
        } else {
            console.log("Error creating revision");
            return false;
        }
    };
}

function getReviewDocument(set: NamedSet<State>, get: GetState<State>) {
    return async (documentId, withLoading = true,) => {
        //  const { companyId, userEmail, userName, userId } = useAuthState.getState();

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await documentService.getReviewDocument(documentId);

        //  hasPermissionForAction(res,"Obtener status del Documento ",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ infoDocument: res.data.respuesta });

            return true;
        } else {
            console.log("Error creating revision");
            return false;
        }
    };
}


function updateReviewerAnswer(set: NamedSet<State>, get: GetState<State>) {
    return async (documentId, reviewer, withLoading = true,) => {
        //  const { companyId, userEmail, userName, userId } = useAuthState.getState();

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await documentService.updateDocumentReview(documentId, reviewer);

        //  hasPermissionForAction(res,"Actualizar status del Documento ",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating revision");
            return false;
        }
    };
}


function getAllReviewAssociate(set: NamedSet<State>, get: GetState<State>) {
    return async (documentId, withLoading = true,) => {
        //  const { companyId, userEmail, userName, userId } = useAuthState.getState();

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await documentService.getRevisionByDocument(documentId);

        //  hasPermissionForAction(res,"Obtener revisores del Documento ",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            console.log("res.data", res.data.respuesta)
            set({ reviewersList: res.data.reviewerList });
            return res.data.respuesta;
        } else {
            console.log("Error creating revision");
            return false;
        }
    };
}


function deleteReviewDocument(set: NamedSet<State>, get: GetState<State>) {
    return async (documentId, withLoading = true,) => {
        //  const { companyId, userEmail, userName, userId } = useAuthState.getState();

        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await documentService.cancelDocumentRevision(documentId);

        //  hasPermissionForAction(res,"Obtener revisores del Documento ",false);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error creating revision");
            return false;
        }
    };
}
