import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { VaultBreadcrumbItem, VaultFolder, VaultFolderChildren, VaultFolderResponse } from "../models/Vault.models";
import vaultService from "../services/vault.service";
import useAuthState from "./authState";
import useModalState from "./modalsState";

interface State {
    currentFolder: { loading: boolean; error: boolean; data: VaultFolderResponse };
    currentBreadcrumb: VaultBreadcrumbItem[];
    selectedFile: VaultFolderChildren;
    selectedFileInfo: any;
    addBreadcrumbItem: (item: VaultBreadcrumbItem) => void;
    sliceBreadcrumb: (index?: number) => void;
    setSelectedFile: (file: VaultFolderChildren) => void;
    setCurrentFolder: (folder: VaultFolderResponse) => void;
    getCompanyRootFolder: (withLoading?: boolean) => Promise<VaultFolder>;
    getSubFolder: (subfolderId: string, withLoading?: boolean) => Promise<VaultFolder>;
    getDocumentInfo: (documentId: string, withLoading?: boolean) => Promise<any>;
    getDocument: (fileId: string, withLoading?: boolean) => Promise<{ file: string; name: string }>;
    getFolderInfo: (folderId: string, withLoading?: boolean) => Promise<any>;
    createFolder: (name: string, parentFolderId?: string, withLoading?: boolean) => any;
    uploadDocument: (
        file: File | Blob | string,
        folderId: string,
        name: string,
        description: string,
        extension: string,
        withLoading?: boolean
    ) => any;
    deleteFolder: (folderId: string, withLoading?: boolean) => any;
    deleteDocument: (documentId: string, withLoading?: boolean) => any;
    downloadDocument: (fileId: string, name: string, withLoading?: boolean) => any;
    addDocumentToSession: (body: any, withLoading?: boolean) => Promise<{ uploaded: boolean; fileExists?: boolean; response?: string }>;
    reset: () => void;
}

const initialState = {
    currentFolder: { loading: false, error: false, data: null },
    currentBreadcrumb: [],
    selectedFile: null,
    selectedFileInfo: null,
};

const useVaultState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            addBreadcrumbItem: addBreadcrumbItem(set, get),
            sliceBreadcrumb: sliceBreadcrumb(set, get),
            setSelectedFile: setSelectedFile(set, get),
            setCurrentFolder: setCurrentFolder(set, get),
            getCompanyRootFolder: getCompanyRootFolder(set, get),
            getSubFolder: getSubFolder(set, get),
            getDocumentInfo: getDocumentInfo(set, get),
            getDocument: getDocument(set, get),
            getFolderInfo: getFolderInfo(set, get),
            createFolder: createFolder(set, get),
            uploadDocument: uploadDocument(set, get),
            deleteFolder: deleteFolder(set, get),
            deleteDocument: deleteDocument(set, get),
            downloadDocument: downloadDocument(set, get),
            addDocumentToSession: addDocumentToSession(set, get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "VaultState" }
    )
);

export default useVaultState;

//FUNCTIONS
function setSelectedFile(set: NamedSet<State>, get: GetState<State>) {
    return (file: VaultFolderChildren) => {
        if (!file) return;
        if (file?.id === get().selectedFile?.id) return;
        set({ selectedFile: file, selectedFileInfo: null });

        //get file info
        if (file?.fileType === "document") {
            get()
                .getDocumentInfo(file.id, false)
                .then((data) => {
                    set({ selectedFileInfo: data });
                });
        } else {
            //TODO: get folderInfo
            get()
                .getFolderInfo(file.id, false)
                .then((res) => {
                    if (res?.codigo === 0) {
                        const data = { ...(res.respuesta || {}), createdBy: res.username };
                        set({ selectedFileInfo: data });
                    }
                });
        }
    };
}

function setCurrentFolder(set: NamedSet<State>, get: GetState<State>) {
    return (folder) => {
        set({ currentFolder: { loading: false, error: false, data: folder } });
    };
}

function addBreadcrumbItem(set: NamedSet<State>, get: GetState<State>) {
    return (item: VaultBreadcrumbItem) => {
        set((state) => ({ currentBreadcrumb: [...state.currentBreadcrumb, { ...item, idx: state.currentBreadcrumb?.length }] }));
    };
}

function sliceBreadcrumb(set: NamedSet<State>, get: GetState<State>) {
    return (index?: number) => {
        set((state) => ({ currentBreadcrumb: typeof index === "number" ? state.currentBreadcrumb?.filter((_, idx) => idx <= index) : [] }));
    };
}

const msgPermission = "No cuenta con el permiso para esta acción.";
function hasPermissionForAction(reponse: any) {
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: "AVISO",
                text3: "Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.",
                onClose: async () => {
                    return { show: false };
                },
            },
        });
    }
}

function getCompanyRootFolder(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;
        set((state) => ({ currentFolder: { loading: true, error: false, data: state.currentFolder.data } }));

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await vaultService.getCompanyRootFolder(idCompany);
        hasPermissionForAction(res);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data) {
            set({ currentFolder: { loading: false, error: false, data: res.data } });
            return res.data;
        } else {
            console.log("Error fetching folder");
            set((state) => ({ currentFolder: { loading: false, error: true, data: state.currentFolder.data } }));
            //clean error
            set((state) => ({ currentFolder: { ...state.currentFolder, error: false } }));
            return null;
        }
    };
}

function getSubFolder(set: NamedSet<State>, get: GetState<State>) {
    return async (subfolderId, withLoading = true) => {
        const idCompany = useAuthState.getState().companyId;

        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await vaultService.getCompanySubFolder(idCompany, subfolderId);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data) {
            set({ currentFolder: { loading: false, error: false, data: res.data } });
            return res.data?.data;
        } else {
            console.log("Error fetching subfolder");
            set((state) => ({ currentFolder: { loading: false, error: true, data: state.currentFolder.data } }));
            //clean error
            set((state) => ({ currentFolder: { ...state.currentFolder, error: false } }));
            return null;
        }
    };
}

function getDocumentInfo(set: NamedSet<State>, get: GetState<State>) {
    return async (docId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await vaultService.getDocumentInfo(docId);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data) {
            return res.data;
        } else {
            console.log("Error fetching subfolder");
            return null;
        }
    };
}

function getFolderInfo(set: NamedSet<State>, get: GetState<State>) {
    return async (folderId, withLoading = true) => {
        const companyId = useAuthState.getState().companyId;
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await vaultService.getFolderData(folderId, companyId);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data) {
            return res.data;
        } else {
            console.log("Error fetching folder info");
            return null;
        }
    };
}

function createFolder(set: NamedSet<State>, get: GetState<State>) {
    return async (name, parentFolderId = null, withLoading = true) => {
        const companyId = useAuthState.getState().companyId;
        withLoading && useModalState.getState().setLoaderModal(true);
        const res: any = await vaultService.createFolder({
            isActive: true,
            name,
            companyId,
            parentFolderId,
        });
        withLoading && useModalState.getState().setLoaderModal(false);

        if (parentFolderId) {
            get().getSubFolder(parentFolderId);
        } else {
            get().getCompanyRootFolder();
        }

        return res;
    };
}

function addDocumentToSession(set: NamedSet<State>, get: GetState<State>) {
    return async (body: any, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res = await vaultService.addDocumentToSession(body.file);

        withLoading && useModalState.getState().setLoaderModal(false);
        if (res?.data?.codigo === 0) {
            sessionStorage.setItem("sessionId", res.data.respuesta);
            return { uploaded: true, fileExists: null, response: res?.data?.respuesta };
        } else {
            console.log("Error adding bidding requirement document");
            return { uploaded: false, fileExists: res?.response?.data?.mensaje?.includes("ya existe"), response: null };
        }
    };
}

function uploadDocument(set: NamedSet<State>, get: GetState<State>) {
    return async (file, folderId, name, description, extension, withLoading = true) => {
        const { userId, companyId } = useAuthState.getState();

        withLoading && useModalState.getState().setLoaderModal(true);

        const documentTemplate: any = {
            companyId,
            createdBy: userId,
            description,
            extension,
            name: name,
            fileBase64: file,
            folderId,
        };

        const createdDocument = await vaultService.createDocument(documentTemplate);

        withLoading && useModalState.getState().setLoaderModal(false);

        if (createdDocument.status === 200) {
            return true;
        } else {
            console.log("Error saving template");
            return false;
        }
    };
}

function deleteFolder(set: NamedSet<State>, get: GetState<State>) {
    return async (folderId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res: any = await vaultService.deleteFolder(folderId);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.status) {
            const state = get();
            state.setSelectedFile(null);
            state.getSubFolder(state.currentFolder.data?.data?.id);
            return true;
        } else {
            return false;
        }
    };
}

function deleteDocument(set: NamedSet<State>, get: GetState<State>) {
    return async (documentId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res: any = await vaultService.deleteDocument(documentId);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.status) {
            const state = get();
            state.setSelectedFile(null);
            state.getSubFolder(state.currentFolder.data?.data?.id);
            return true;
        } else {
            return false;
        }
    };
}

function getDocument(set: NamedSet<State>, get: GetState<State>) {
    return async (fileId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res: any = await vaultService.getDocumentFromVault(fileId);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data) {
            return res.data;
        } else {
            return null;
        }
    };
}

function downloadDocument(set: NamedSet<State>, get: GetState<State>) {
    return async (fileId, filename, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const res: any = await vaultService.downloadDocument(fileId);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res.status == 200) {
            //download file
            const blob = new Blob([res.data], { type: res.headers["content-type"] });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = filename; //res.headers["content-disposition"].split("filename=")[1];
            link.click();
            link.remove();

            return true;
        } else {
            return false;
        }
    };
}
