import { Icon } from "@chakra-ui/react";

const AddNewFilledIcon = (props) => {
    return (
        <Icon viewBox="0 0 73 73" {...props}>
            <path
                d="M36.5 73C56.63 73 73 56.63 73 36.5S56.63 0 36.5 0 0 16.37 0 36.5 16.37 73 36.5 73ZM19.11 35.44c.28-.28.64-.42 1.06-.42h14.85V20.17c0-.42.14-.78.42-1.06s.64-.42 1.06-.42.78.14 1.06.42.42.64.42 1.06v14.85h14.85c.42 0 .78.14 1.06.42.28.28.42.64.42 1.06s-.14.78-.42 1.06c-.28.28-.64.42-1.06.42H37.98v14.85c0 .42-.14.78-.42 1.06-.28.28-.64.42-1.06.42s-.78-.14-1.06-.42c-.28-.28-.42-.64-.42-1.06V37.98H20.17c-.42 0-.78-.14-1.06-.42s-.42-.64-.42-1.06.14-.78.42-1.06Z"
                style={{
                    fill: "#224190",
                }}
            />
            <path
                d="M20.17 37.98h14.85v14.85c0 .42.14.78.42 1.06.28.28.64.42 1.06.42s.78-.14 1.06-.42c.28-.28.42-.64.42-1.06V37.98h14.85c.42 0 .78-.14 1.06-.42.28-.28.42-.64.42-1.06s-.14-.78-.42-1.06c-.28-.28-.64-.42-1.06-.42H37.98V20.17c0-.42-.14-.78-.42-1.06s-.64-.42-1.06-.42-.78.14-1.06.42-.42.64-.42 1.06v14.85H20.17c-.42 0-.78.14-1.06.42s-.42.64-.42 1.06.14.78.42 1.06.64.42 1.06.42Z"
                style={{
                    fill: "#fff",
                }}
            />
        </Icon>
    );
};

export default AddNewFilledIcon;
