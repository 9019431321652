import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { useBoolean } from "@chakra-ui/react";
import { useState } from "react";
import useCustomToast from "../../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useVaultState from "../../../../store/vaultState";
import CommonModal from "../../CommonModal";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const NewVaultFolderModal = ({ isOpen, onClose }: Props) => {
    const toast = useCustomToast();
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const { createFolder, currentFolder } = useVaultState();
    const [name, setName] = useState("");

    const [invalidName, setInvalid] = useBoolean(false);

    async function handleCreate() {
        if (!name.length) {
            return setInvalid.on();
        } else {
            setInvalid.off();
        }

        onClose();
        const savedFolder = await createFolder(name, currentFolder?.data?.data?.id);
        if (savedFolder) {
            toast.success(t("createdFolderSuccessfully"));
        } else {
            toast.error(t("creatingFolderError"));
        }
    }

    return (
        <CommonModal size="sm" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">{t("newFolder")}</h2>

                <form className="w-10/12 flex flex-col items-center" onSubmit={(e) => e.preventDefault()}>
                    <FormControl isInvalid={invalidName}>
                        <label htmlFor="folder_name" className="text-sm leading-8">
                            {t("name")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input id="folder_name" type="text" rounded="none" value={name} onChange={(e) => setName(e.target.value)} />
                        </InputGroup>
                    </FormControl>

                    <div className="mt-10">
                        <Button
                            type="button"
                            rounded="full"
                            size="md"
                            fontWeight="medium"
                            px={"2rem"}
                            isLoading={false}
                            onClick={handleCreate}
                        >
                            {t("add")}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

export default NewVaultFolderModal;
