import { useBoolean } from "@chakra-ui/react";
import { MouseEvent } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import useCustomToast from "../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { ContractType } from "../../../models/Contract.models";
import useContractState from "../../../store/contractState";
import useModalState from "../../../store/modalsState";

type Props = {
    contractType: ContractType;
    label: string;
    onSelect?: (selected: boolean, label: string) => any;
    selected?: boolean;
    size?: "sm" | "md";
};

const ContractTypeButton = ({ contractType, label, onSelect = () => {}, selected = false, size = "md" }: Props) => {
    const setDeleteModal = useModalState((s) => s.setDeleteCommonModal);
    const deleteContractType = useContractState((s) => s.deleteContractType);
    const toast = useCustomToast()
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const [showOptions, setOptions] = useBoolean(false);

    const handleClick = () => {
        onSelect(selected, label);
    };

    async function onDelete() {
        const deleted = await deleteContractType(contractType.id)
        if(deleted){
            toast.success(t("deletedContractTypeSuccess"))
        }else{
            toast.error(t("deletedContractTypeError"))
        }
    }

    function handleDelete(e: MouseEvent) {
        e.stopPropagation();
        setDeleteModal({
            show: true,
            data: { text1: t("typeWillBeDeleted"), text2: contractType.description, onDelete },
        });
    }

    return (
        <button
            onMouseEnter={setOptions.on}
            onMouseLeave={setOptions.off}
            onClick={handleClick}
            className={`relative ${
                size === "md" ? "py-1 px-6 text-base" : "py-1 px-4 text-sm"
            }  rounded-full border transition-colors duration-200 ${"bg-brand-100/40 text-brand-500  border-transparent"}`}
        >
            {label}
            {showOptions && (
                <button onClick={handleDelete} className="ml-2 absolute top-[-0.6rem] right-[-2px] text-red-600 bg-white rounded-full">
                    <IoIosCloseCircleOutline size={22} color="inherit" />
                </button>
            )}
        </button>
    );
};

export default ContractTypeButton;
