import { BiddingValidationType } from "src/models/biddings/BiddingValidationType.models";
import biddingValidationTypeService from "src/services/biddings/biddingValidationType.service";
import generadorStatusService from "src/services/biddings/generadorStatus.service";
import create, { GetState } from "zustand";
import { NamedSet } from "zustand/middleware";
import useAuthState from "../authState";
import useModalState from "../modalsState";

interface State {
    generadorStatus: any;
    getGeneradorBiddingStatus: (biddingId: string ,withLoading?: boolean) => any;
    createGeneradorBiddingStatus: (body: any, withLoading?: boolean) => Promise<boolean>;
    reset: () => void;
}

const initialState = {
    generadorStatus: null,
};

const useGeneradorStatus = create<State>((set, get) => ({
    ...initialState,
    getGeneradorBiddingStatus: getGeneradorBiddingStatus(set, get),
    createGeneradorBiddingStatus: createGeneradorBiddingStatus(set, get),
    reset: () => set((state) => ({ ...state, ...initialState })),
}));

export default useGeneradorStatus;

const msgPermission = 'No cuenta con el permiso para esta acción.';
function hasPermissionForAction (reponse : any, msgPermission : string, redirect : boolean ){
    if (reponse?.data?.codigo === 3 && reponse?.data?.mensaje === "No cuenta con el permiso para esta acción.") {
        useModalState.getState().setRestrictedActionModal({
            show: true,
            data: {
                text1: 'AVISO',
                text2: msgPermission,
                text3: 'Lo sentimos, no cuenta con los permisos necesarios para realizar esta acción. Por favor, contacte a su administrador para configurar sus permisos.',
                redirectHome : redirect,
                onClose: async () => {
                    return { show: false }
                  }
            }
        });
    }
}

//FUNCTIONS
function getGeneradorBiddingStatus(set: NamedSet<State>, get: GetState<State>) {
    return async (biddingId, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await generadorStatusService.getGeneradorBiddingStatus(biddingId);
         
        //hasPermissionForAction(res,"Listar",false);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ generadorStatus: res.data.respuesta });
            return true;
        } else {
            console.log("Error fetching biddingValidationTypes");
            set({ generadorStatus: res.data.respuesta });
            return false;
        }


    };
}

function createGeneradorBiddingStatus(set: NamedSet<State>, get: GetState<State>) {
    return async (body, withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const res = await generadorStatusService.createGeneradorBiddingStatus(body);
         
        //hasPermissionForAction(res,"Listar",false);
        withLoading && useModalState.getState().setLoaderModal(false);

        if (res?.data?.codigo === 0) {
            set({ generadorStatus: res.data.respuesta });
            return true;
        } else {
            console.log("Error fetching biddingValidationTypes");
           // set({ generadorStatus: res.data.respuesta });
            return false;

        }

    };
}
