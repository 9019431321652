import { Navigate } from "react-router";
import useAuthState from "../store/authState";
import { isAuth } from "../lib/utils";
import { UserAuth } from "src/models/User.models";

const PrivateRoute = ({ children }) => {

    const loggedUserData: UserAuth = JSON.parse( sessionStorage.getItem( 'authUser' ) )?.state;
    const { accessToken, reset, isLoggedOut } = useAuthState((s) => s);

    if ( !accessToken || !isAuth(accessToken) ) {
        if(!isLoggedOut){
            reset();
        }
        return <Navigate to="/login" replace />;
    } else if ( !loggedUserData.updatedPassword ) {
        return <Navigate to="/new-password" replace />;
    }

    return children;
};

export default PrivateRoute;
