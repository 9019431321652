import axios from "axios";
import { Notification } from "../models/Notification.models";
import { getAuthHeader } from "../lib/utils";
import { Show, useToast,Button } from '@chakra-ui/react'
import useAuthState from "src/store/authState";
import useModalState from "src/store/modalsState";

export const notificationsAxiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_GENERAL}/api/general/notification`,
    timeout: 10000,
});

const getNotifications = async (body: any) => {
    try {
        return await notificationsAxiosRequest.post(`/`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getNotificationsSize = async (userId: string, appId: string) => {
    try {
        return await notificationsAxiosRequest.get(`/size/idUser/${userId}/idApp/${appId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateNotification = async (body: Notification) => {
    try {
        return await notificationsAxiosRequest.patch(`/`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

// comentado evitar errores con el refresh token 
notificationsAxiosRequest.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Handle 401 error: Unauthorized
         handleUnauthorizedError();
      }
      return Promise.reject(error);
    }
  );
  
  function handleUnauthorizedError() {
    useAuthState.getState().logout();
    useModalState.getState().setSimpleAlertModal({
        show: true,
        data: { text1: "warning", text2: "errorSession", whitTranslation: true },
    });
  
   // alert("Su sesión ha expirado. Será redirigido al inicio.");
  }
  

export default {
    getNotifications,
    updateNotification,
    getNotificationsSize
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
