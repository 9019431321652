import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { FormErrorMessage, Image, Select, Tag, TagCloseButton, TagLabel, useBoolean } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import useCustomToast from "../../../../hooks/useCustomToast";
import CommonModal from "../../CommonModal";
import FileDropzone from "../../FileDropzone";
import { blobToBase64 } from "../../../../lib/utils";
import useAdministrationState from "../../../../store/administrationState";
import ReactFlagsSelect from "react-flags-select";
import { PHONE_PREFIXES } from "../../../../lib/constants";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { NewEditUserModalData } from "src/models/Modals.models";
import LinkButton from "../../buttons/LinkButton";
import { IoClose } from "react-icons/io5";
import useRolesState from "src/store/rolesState";
import { Role } from "src/models/Roles.models";
import useVaultState from "src/store/vaultState";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: NewEditUserModalData;
};

interface FormFields {
    name: string;
    lastName: string;
    //username: string;
    phone: string;
    email: string;
    // password: string;
    // repeatPassword: string;
    rfc: string;
}

const NewEditUserModal = ({ isOpen, onClose, data }: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const { createUser, createExternalUser, getCompanyUsers, updateUser } = useAdministrationState();
    const { roles, getRoles, getRolesByUser, userRolInfo, updateRolesByUser } = useRolesState();
    const [isSubmitting, setIsSubmitting] = useBoolean(false);
    const [file, setFile] = useState<File | null>(null);
    const [rolesData, setRolesData] = useState(roles?.data || []);
    const [userRolesData, setUserRolesData] = useState(userRolInfo?.data[0]?.rolList || []);
    const [userInfoRoleList] = userRolInfo?.data;
    let filteredRoles = rolesData.filter((role) => userRolesData.includes(role.id));

    const [selectedRoles, setRoles] = useState<Role[]>(filteredRoles);
    const [selectedCountry, setSelectedCountry] = useState<string>("MX");
    const [userImageB64, setUserImageB64] = useState<string | null>(data?.user?.image64);
    const uploadDocumentToSession = useVaultState((s) => s.addDocumentToSession);

    const formik = useFormik<FormFields>({
        initialValues: {
            name: data?.user?.name || "",
            lastName: data?.user?.lastName || "",
            //username: data?.user?.username || "",
            phone: data?.user?.phone?.split("-")[1] || "",
            email: data?.user?.email || "",
            rfc: data?.user?.rfc || "",
            // password: "",
            // repeatPassword: "",
        },
        onSubmit: data.type === "new" ? handleCreate : handleUpdate,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
            lastName: Yup.string().required(t("requiredField")),
            username: Yup.string(),
            phone: Yup.string(),
            email: Yup.string().required(t("requiredField")).email(t("invalidEmail")),
            rfc: Yup.string(),
        }),
    });

    async function handleCreate(values: FormFields) {
        //valido roles
        if (!selectedRoles.length) {
            toast.error(t("leastOneRoleRequired"));
            return;
        }

        //valido imagen
        if (userImageB64) {
            if (!file.type.startsWith("image/")) {
                toast.error(t("onlyImagesAllowed"));
                return;
            }
        }

        const formData = new FormData();
        formData.append("file", file);

        const blobBody = {
            file: formData,
        };

        const res = file && (await uploadDocumentToSession(blobBody));

        const userBody = {
            ...values,
            phone: PHONE_PREFIXES[selectedCountry]?.primary + "-" + values.phone,
            roles: selectedRoles.map((r) => r.id),
            image64: res?.response,
            image: file?.type || "",
            status: true,
            username: values.email,
            idApp: "SCM",
        };

        if (res?.uploaded || file === null) {
            const savedUser = data?.userType === "internal" ? await createUser(userBody as any) : await createExternalUser(userBody as any);
            if (savedUser) {
                toast.success(t("userCreatedSuccessfully"));
                getCompanyUsers(false);
                onClose();
            } else {
                toast.error(t("userCreatingError"));
            }
        }
    }

    async function handleUpdate(values: FormFields) {
        //valido roles
        if (!selectedRoles.length) {
            toast.error(t("leastOneRoleRequired"));
            return;
        }

        //valido imagen
        if (userImageB64) {
            if (!file.type.startsWith("image/")) {
                toast.error(t("onlyImagesAllowed"));
                return;
            }
        }

        // for update rolList
        const userBody = {
            ...data.user,
            ...values,
            phone: PHONE_PREFIXES[selectedCountry]?.primary + "-" + values.phone,
            roles: selectedRoles.map((r) => r.id),
            image64: userImageB64,
            image: file?.type,
            status: true,
            username: values.email,
            idApp: "SCM",
        };

        if (userInfoRoleList) {
            const newRolList = selectedRoles.map((r) => r.id);
            // userInfoRoleList.rolList = newRolList;

            await updateRolesByUser(userInfoRoleList.id, newRolList);
        }

        const savedUser = await updateUser(userBody as any);

        if (savedUser) {
            toast.success(t("userUpdatedSuccessfully"));
            getCompanyUsers(false);
            onClose();
        } else {
            toast.error(t("userUpdatingError"));
        }
    }

    function handleRole(e: any) {
        const roleId = e.target.value;
        setRoles((prevRoles) => {
            const exists = prevRoles.find((r) => r.id === roleId);
            const role = roles?.data?.find((r) => r.id === roleId);
            if (!exists && role) {
                return [...prevRoles, role];
            } else {
                return prevRoles;
            }
        });
    }

    function removeRole(role: any) {
        setRoles((prevRoles) => prevRoles.filter((r) => r.id !== role.id));
    }

    function handleImageUpload(file: File) {
        setFile(file);
        blobToBase64(file).then((b64) => setUserImageB64(b64));

            const formData = new FormData();
        formData.append("file", file);


        console.log("rrrrr FORM",formData)

    }

    useEffect(() => {
        const getAllInfoRoles = async () => {
            setRolesData([]); // Esto limpia rolesData
            setUserRolesData([]); // Esto limpia userRolesData

            await getRoles();

            if (data.type === "edit") {
                await getRolesByUser(data?.user?.id);
            }
        };

        getAllInfoRoles();
    }, [data]);

    useEffect(() => {
        const rolesData = roles?.data || [];
        const userRolesData = userRolInfo?.data[0]?.rolList || [];

        // Filtra los roles basándote en la inclusión del ID en userRolesData
        const filteredRoles = rolesData.filter((role) => userRolesData.includes(role.id));
        setRoles(filteredRoles);
    }, [roles, userRolInfo, data.type]);

    function handleClose() {
        setRoles([]); // Esto limpia selectedRoles
        onClose();
    }
    console.log("rrrrSSSr",file)

    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={handleClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <div className="text-center">
                    <h2 className="title-3">{data?.type === "edit" ? t("editUser") : t("newUser")}</h2>
                    <div className="text-sm">({data?.userType === "internal" ? t("internal") : t("external")})</div>
                </div>

                <form
                    className="w-full flex flex-wrap justify-between items-center gap-2 max-h-[75vh] overflow-y-auto px-2"
                    onSubmit={formik.handleSubmit}
                >
                    <FormControl isInvalid={!!formik.errors.name} w="48%">
                        <label htmlFor="name" className="text-sm leading-8">
                            {t("name")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.lastName} w="48%">
                        <label htmlFor="lastName" className="text-sm leading-8">
                            {t("lastName")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="lastName"
                                name="lastName"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.lastName}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.lastName}</FormErrorMessage>
                    </FormControl>
                    {/* 
                    <FormControl isInvalid={!!formik.errors.username}>
                        <label htmlFor="username" className="text-sm leading-8">
                            {t("username")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="username"
                                name="username"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.username}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.username}</FormErrorMessage>
                    </FormControl> */}

                    <FormControl isInvalid={!!formik.errors.email}>
                        <label htmlFor="email" className="text-sm leading-8">
                            {t("email")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>

                    {/* <FormControl isInvalid={!!formik.errors.password} w="48%">
                        <label htmlFor="password" className="text-sm leading-8">
                            Contraseña
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="password"
                                name="password"
                                type={showPass ? "text" : "password"}
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                            <InputRightElement
                                children={
                                    <button type="button" onClick={setShowPass.toggle}>
                                        {showPass ? (
                                            <IoEyeOffOutline size={22} color="inherit" />
                                        ) : (
                                            <IoEyeOutline size={22} color="inherit" />
                                        )}
                                    </button>
                                }
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.repeatPassword} w="48%">
                        <label htmlFor="repeatPassword" className="text-sm leading-8">
                            Repetir contraseña
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="repeatPassword"
                                name="repeatPassword"
                                type={showPass ? "text" : "password"}
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.repeatPassword}
                            />
                            <InputRightElement
                                children={
                                    <button type="button" onClick={setShowPass.toggle}>
                                        {showPass ? (
                                            <IoEyeOffOutline size={22} color="inherit" />
                                        ) : (
                                            <IoEyeOutline size={22} color="inherit" />
                                        )}
                                    </button>
                                }
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl> */}

                    {/** <FormControl isInvalid={!!formik.errors.phone}>
                        <label htmlFor="phone" className="text-sm leading-8">
                            {t("phone")}
                        </label>
                        <InputGroup borderColor="gray.400" style={{ position: "relative" }}>
                            <Input
                                id="phone"
                                name="phone"
                                type="number"
                                rounded="none"
                                paddingLeft={"7rem"}
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                            />
                            <div className="absolute left-0 bottom-0 top-0 z-10 flex flex-col justify-center border-none">
                                <ReactFlagsSelect
                                    placeholder=" "
                                    key={"ReactFlagsSelect" + selectedCountry}
                                    className="p-0 m-0 h-full pt-[5px]"
                                    showSecondaryOptionLabel={false}
                                    optionsSize={16}
                                    selectButtonClassName="no-border h-full"
                                    selected={selectedCountry}
                                    onSelect={(code) => setSelectedCountry(code)}
                                    countries={Object.keys(PHONE_PREFIXES)}
                                    customLabels={PHONE_PREFIXES}
                                />
                            </div>
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>*/}

                    {/** <FormControl isInvalid={!!formik.errors.rfc}>
                        <label htmlFor="rfc" className="text-sm leading-8">
                            RFC
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="rfc"
                                name="rfc"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.rfc}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>
*/}
                    <FormControl>
                        <label htmlFor="role" className="text-sm leading-8">
                            Roles
                        </label>
                        <Select id="role" size="md" rounded="none" onChange={handleRole}>
                            <option value="">{t("select")} roles</option>
                            {roles?.data?.map((role) => {
                                if (!selectedRoles?.find((r) => r.id === role.id)) {
                                    return (
                                        <option key={role.id} value={role.id}>
                                            {role.name}
                                        </option>
                                    );
                                }
                            })}
                        </Select>
                    </FormControl>
                    <div className="w-full flex gap-2 items-center">
                        {selectedRoles?.map((role) => (
                            <Tag key={role.id} size={"sm"} borderRadius="full" variant="solid" colorScheme="brand">
                                <TagLabel>{role.name}</TagLabel>
                                <TagCloseButton onClick={() => removeRole(role)} />
                            </Tag>
                        ))}
                    </div>

                    <div className="mt-2 w-full">
                        <div className="flex justify-between items-center">
                            <h4 className="text-sm mb-2">{t("profileImage")}</h4>
                            {Boolean(userImageB64) && (
                                <LinkButton
                                    fontSize="xs"
                                    colorScheme="red"
                                    leftIcon={<IoClose size={16} />}
                                    onClick={() => setUserImageB64("")}
                                >
                                    Quitar
                                </LinkButton>
                            )}
                        </div>
                        {Boolean(userImageB64) ? (
                            <div
                                className="w-full bg-no-repeat bg-center"
                                style={{ backgroundImage: `url(${userImageB64})`, backgroundSize: "150%" }}
                            >
                                <div
                                    className="w-full h-full flex justify-center backdrop-blur-md p-4 relative"
                                    style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
                                >
                                    <Image
                                        src={userImageB64}
                                        alt="profile"
                                        width={150}
                                        height={120}
                                        objectFit="cover"
                                        className="rounded"
                                    />
                                </div>
                            </div>
                        ) : (
                            <FileDropzone onDropFile={handleImageUpload} accept="image/*" />
                        )}
                    </div>

                    <div className="mt-8 pb-2 w-full text-center">
                        <Button type="submit" rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                            {data?.type === "new" ? t("add") : t("save")}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

export default NewEditUserModal;
