import { useEffect, useState, useRef, memo } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';

const usePdf = (pdfBlobUrl) => {
    const [pdf, setPdf] = useState(null);
    const [numPages, setNumPages] = useState(0);

    useEffect(() => {
        const loadPdf = async () => {
            if (!pdfBlobUrl) return;

            const loadingTask = pdfjsLib.getDocument(pdfBlobUrl);
            const pdf = await loadingTask.promise;

            setPdf(pdf);
            setNumPages(pdf.numPages);
        };

        loadPdf();
    }, [pdfBlobUrl]);

    return { pdf, numPages };
};

const getDynamicHeight = () => {
    const width = window.innerWidth;
    if (width <= 375) {
        return 'calc(80vh - 80px)'; // For very small screens
    } else if (width <= 390) {
        return 'calc(70vh - 80px)'; // For small screens
    } else if (width <= 400) {
        return 'calc(70vh - 80px)'; // For small screens
    } else if (width <= 768) {
        return 'calc(80vh - 80px)'; // For small screens
    } else {
        return 'calc(68vh - 10px)'; // For larger screens
    }
};

type Props = {
    pdfBlobUrl: string;
    height?: string | number;
    width?: string | number;
};

const PdfViewer = ({ pdfBlobUrl, height, width = '100%' }: Props) => {
    const canvasRef = useRef(null);
    const [pageNumber, setPageNumber] = useState(1);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const heightCanva = useBreakpointValue({
        base: 'calc(70vh - 120px)', // Smallest screens
        sm: 'calc(70vh - 120px)', // Small screens
        md: 'calc(130vh - 100px)', // Medium screens
        lg: 'calc(80vh - 80px)', // Large screens
        xl: 'calc(68vh - 10px)', // Extra large screens
    });

    const { pdf, numPages } = usePdf(pdfBlobUrl);

    useEffect(() => {
        const renderPage = async () => {
            if (!pdf || !canvasRef.current) return;

            const page = await pdf.getPage(pageNumber);
            const scale = isMobile ? 1.5 : 1;
            const viewport = page.getViewport({ scale });
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            if (!context) return;

            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };

            await page.render(renderContext).promise;
        };

        renderPage();
    }, [pdf, pageNumber, isMobile]);

    const goToPrevPage = () => {
        setPageNumber(prevPage => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
    };

    return (
        <div className="w-full mx-auto flex flex-col items-center">
        <div className="pdf-container bg-white p-4" style={{ width: '100%', border: '0px solid #e2e8f0' }}>
            {isMobile && pdfBlobUrl ? (
                <div style={{ width: '100%', maxWidth: '100%', margin: '0 auto', borderRadius: '8px' }}>
                <canvas ref={canvasRef} style={{ width: '100%', height: 'calc(80vh - 200px)', borderRadius: '8px' }} />
            </div>
            ) : (
              
                <div className="w-full mx-auto flex flex-col grow items-center min-h-[80vh]">
                {pdfBlobUrl && (
                    <div style={{ width, margin: "0 auto" }}>
                        {/* eslint-disable-next-line */}
                        <object width="100%" height={800} data={pdfBlobUrl} type="application/pdf" />
                    </div>
                )}
            </div>            )}
        </div>
        {isMobile && (
            <div className="flex justify-around pt-2 pb-1 w-full sticky bottom-0 bg-white border-t align-middle">
                <button onClick={goToPrevPage} disabled={pageNumber === 1} className="bg-blue-500 text-white py-1 px-1 rounded-lg focus:outline-none flex-grow">Anterior</button>
                <span className="flex-grow text-center">{pageNumber} / {numPages}</span>
                <button onClick={goToNextPage} disabled={pageNumber === numPages} className="bg-blue-500 text-white py-1 px-1 rounded-lg focus:outline-none flex-grow">Siguiente</button>
            </div>
        )}
    </div>
    
    );
};

export default memo(PdfViewer, (prevProps, nextProps) => {
    // Solo se vuelve a renderizar si pdfBlobUrl cambia
    return prevProps.pdfBlobUrl === nextProps.pdfBlobUrl;
});
