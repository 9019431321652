function on(eventType: string, listener) {
    document.addEventListener(eventType, listener);
}

function off(eventType: string, listener) {
    document.removeEventListener(eventType, listener);
}

function once(eventType: string, listener) {
    on(eventType, handleEventOnce);

    function handleEventOnce(event) {
        listener(event);
        off(eventType, handleEventOnce);
    }
}

function trigger<T>(eventType: string, data: T) {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
}

export { on, once, off, trigger };
