import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import debounce from "lodash/debounce";
import { useCallback } from "react";
import { BiSearch } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const SearchInput = ({ onChange, disabled = false }: { onChange?: (query: string) => any; disabled?: boolean }) => {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];

    const debouncedHandleChange = useCallback(
        debounce((e) => {
            const query = e.target.value;
            onChange && onChange(query);
        }, 400),
        []
    );

    return (
        <div>
            <InputGroup>
                <InputLeftElement h={8} color="gray.400" pointerEvents="none" children={<BiSearch color="inherit" size={20} />} />
                <Input disabled={disabled} size={"sm"} rounded="full" placeholder={t("search")} onChange={debouncedHandleChange} bg="white" />
            </InputGroup>
        </div>
    );
};

export default SearchInput;
