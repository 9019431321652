import { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { PDFDocumentLoadingTask, PDFDocumentProxy } from "pdfjs-dist";
import usePageZoom from "./usePageZoom";
import debounce from "lodash/debounce";

const MIN_SCALE = 0.75;
const MAX_SCALE = 1.26;

const usePdfCanvasRender = (url) => {
    // eslint-disable-next-line no-undef
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const screenRef = useRef<HTMLDivElement>(null);
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const { zoom } = usePageZoom();
    const initialScale = Math.min(Math.max((100 - (zoom - 100)) / 100, MIN_SCALE), MAX_SCALE) + 0.25;

    const [pdfRef, setPdfRef] = useState<PDFDocumentProxy>();
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [scale, setScale] = useState(initialScale);
    const handleNextPage = debounce(() => {
        if (currentPage >= totalPages) return;
        setCurrentPage(currentPage + 1);
    }, 400);

    const handlePrevPage = debounce(() => {
        if (currentPage <= 1) return;
        setCurrentPage(currentPage - 1);
    }, 400);

    const handleZoom = debounce((zoom: "in" | "out") => {
        if (zoom === "in") {
            if (scale >= 2.5) return;
            setScale(scale + 0.2);
        } else {
            if (scale <= 0.5) return;
            setScale(scale - 0.2);
        }
    }, 400);

    const renderPage = async () => {
        if (pdfRef) {
            const screenSizes = screenRef.current.getBoundingClientRect();
            const page = await pdfRef.getPage(currentPage);
            let viewport = page.getViewport({ scale: scale });
            const container = containerRef.current;

            if (isFirstRender) {
                if (viewport.width > screenSizes.width || viewport.height > screenSizes.height) {
                    const newScale = scale - 0.25;
                    setScale(newScale);
                    viewport = page.getViewport({ scale: scale - 0.25 });
                }

                container.style.height = `${viewport.height}px`;
                container.style.width = `${viewport.width}px`;
                container.style.position = "relative";
                setIsFirstRender(false);
            }

            if (screenSizes.width > viewport.width) {
                container.style.width = `${viewport.width}px`;
            }
            const canvas = canvasRef.current;
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: canvas.getContext("2d"),
                viewport: viewport,
            };

            const pageRendering = page.render(renderContext);

            pageRendering.promise.then(
                function () {},
                function (error) {
                    console.error(error);
                }
            );
        }
    };

    // render page when pageNumber changes
    useEffect(() => {
        renderPage();
    }, [currentPage, pdfRef, scale]);

    useEffect(() => {
        if (!url) return;
        const loadingTask: PDFDocumentLoadingTask = pdfjsLib.getDocument(url);
        loadingTask.promise.then(
            (loadedPdf) => {
                setPdfRef(loadedPdf);
                setTotalPages(loadedPdf.numPages);
            },
            function (reason) {
                console.error(reason);
            }
        );
    }, [url]);

    return {
        canvasRef,
        containerRef,
        screenRef,
        handleZoom,
        currentPage,
        totalPages,
        setCurrentPage,
        handleNextPage,
        handlePrevPage,
        isLoaded: Boolean(pdfRef),
    };
};

export default usePdfCanvasRender;
