import PdfIcon from "../assets/documents-icons/PDF.svg";
import DocIcon from "../assets/documents-icons/DOC.svg";
import DocxIcon from "../assets/documents-icons/DOCX.svg";
import Mp3Icon from "../assets/documents-icons/MP3.svg";
import Mp4Icon from "../assets/documents-icons/MP4.svg";
import PptIcon from "../assets/documents-icons/PPT.svg";
import XlsIcon from "../assets/documents-icons/XLS.svg";
import XlsxIcon from "../assets/documents-icons/XLSX.svg";
import XmlIcon from "../assets/documents-icons/XML.svg";
import PngIcon from "../assets/documents-icons/PNG.svg";
import RMIcon from "../assets/documents-icons/ROADMAP.svg"

export const extensionIcons = {
    pdf: PdfIcon,
    doc: DocIcon,
    docx: DocxIcon,
    xls: XlsIcon,
    xlsx: XlsxIcon,
    xml: XmlIcon,
    ppt: PptIcon,
    pptx: PptIcon,
    mp4: Mp4Icon,
    mp3: Mp3Icon,
    png: PngIcon,
    json: RMIcon,
};
