import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { useBoolean } from "@chakra-ui/react";
import { useState } from "react";
import { VALID_FILE_EXTENSIONS } from "../../../../lib/constants";
import useCustomToast from "../../../../hooks/useCustomToast";
import useVaultState from "../../../../store/vaultState";
import CommonModal from "../../CommonModal";
import FileDropzone from "../../FileDropzone";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const UploadVaultDocumentModal = ({ isOpen, onClose }: Props) => {
    const toast = useCustomToast();
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const { uploadDocument, currentFolder, getSubFolder } = useVaultState();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [invalidName, setInvalidName] = useBoolean(false);
    const [invalidDesc, setInvalidDesc] = useBoolean(false);
    const [file, setFile] = useState<File | null>(null);

    const folderId = currentFolder?.data?.data?.id;

    async function handleCreate() {
        if (!name.length) {
            return setInvalidName.on();
        } else if (!description.length) {
            return setInvalidDesc.on();
        } else {
            setInvalidName.off();
            setInvalidDesc.off();
        }

        if (!file) {
            return toast.error(t("fileRequired"));
        }
        const fileExtension = file.name.split(".").reverse()[0]?.toLowerCase() ?? "";
        if (!VALID_FILE_EXTENSIONS.includes(fileExtension)) return toast.error(t("invalidExtension"));

        onClose();
        const savedDocument = await uploadDocument(file, folderId, name, description, fileExtension);
        if (savedDocument?.data?.id) {
            toast.success(t("uploadedDcoumentSuccessfully"));
            getSubFolder(folderId);
        } else {
            toast.error(t("uploadingDocumentError"));
        }
    }

    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">{t("newDocument")}</h2>

                <form className="w-10/12 flex flex-col items-center gap-4" onSubmit={(e) => e.preventDefault()}>
                    <FormControl isInvalid={invalidName && !name}>
                        <label htmlFor="file_name" className="text-sm leading-8">
                            {t("name")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input id="file_name" type="text" rounded="none" value={name} onChange={(e) => setName(e.target.value)} />
                        </InputGroup>
                    </FormControl>

                    <FormControl isInvalid={invalidDesc && !description}>
                        <label htmlFor="file_description" className="text-sm leading-8">
                            {t("description")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="file_description"
                                type="text"
                                rounded="none"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </InputGroup>
                    </FormControl>

                    <div className="mt-6 w-full">
                        <FileDropzone onDropFile={(file) => setFile(file)} />
                    </div>

                    <div className="mt-8">
                        <Button
                            type="button"
                            rounded="full"
                            size="md"
                            fontWeight="medium"
                            px={"2rem"}
                            isLoading={false}
                            onClick={handleCreate}
                        >
                            {t("add")}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

export default UploadVaultDocumentModal;
