import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { Radio } from "@chakra-ui/react";
import { FiEdit } from "react-icons/fi";
import CommonModal from "../CommonModal";
import Nobr from "../Nobr";
import companyIcon from '../../../assets/images/company-icon.png'
import LinkButton from "../buttons/LinkButton";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const EditCompanyModal = ({ isOpen, onClose }: Props) => {
    return (
        <CommonModal size="5xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex items-center py-4">
                {/* left */}
                <div className="w-3/12 flex flex-col items-center border-r border-gray-500 h-full">
                    <div className="text-center">
                        <img className="block w-24 mx-auto" src={companyIcon} alt="company logo" />

                        <LinkButton fontSize="xs" rightIcon={<FiEdit />} colorScheme="gray">
                           Cambiar imagen
                        </LinkButton>

                        <FormControl className="mt-4">
                            <InputGroup>
                                <Input
                                    id="first_name"
                                    defaultValue={"Nombre de empresa"}
                                    textAlign="center"
                                    type="text"
                                    rounded="none"
                                    variant="flushed"
                                />
                            </InputGroup>
                        </FormControl>
                    </div>
                    <div className="grow"></div>
                    <div className="mt-10">
                        <Button rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                            Aceptar
                        </Button>
                    </div>
                </div>

                {/* right */}
                <div className="w-9/12 h-full px-2">
                    <form>
                        {/* block 1 */}
                        <div className="px-[.9rem] py-3 border-b-2 border-dashed border-gray-600">
                            <FormControl>
                                <InputGroup className="space-x-4 items-center justify-between">
                                    <label className="text-sm w-[14%]" htmlFor="first_name">
                                        Razón Social
                                    </label>
                                    <Input id="first_name" type="text" rounded="none" size="sm" maxW={"60%"} />
                                    <Radio value="1">
                                        <span className="text-sm">Persona moral</span>
                                    </Radio>
                                </InputGroup>
                            </FormControl>

                            <FormControl className="mt-4">
                                <InputGroup className="space-x-4 items-center justify-between">
                                    <label className="text-sm w-[14%]" htmlFor="first_name">
                                        RFC
                                    </label>
                                    <Input id="first_name" type="text" rounded="none" size="sm" maxW={"60%"} />
                                    <Radio value="1">
                                        <span className="text-sm">Persona moral</span>
                                    </Radio>
                                </InputGroup>
                            </FormControl>
                        </div>

                        {/* block 2 */}
                        <div className="px-2 py-3 border-b-2 border-dashed border-gray-600">
                            <h3 className="text-base font-bold text-dark-2 mb-4 pl-[.4rem]">Domicilio fiscal</h3>
                            <div className="w-full flex flex-wrap">
                                <FormControl w="100%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[4rem]" htmlFor="first_name">
                                            Calle
                                        </label>
                                        <Input id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="50%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[4rem]" htmlFor="first_name">
                                            <Nobr>N. Exterior</Nobr>
                                        </label>
                                        <Input id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="50%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[4rem]" htmlFor="first_name">
                                            <Nobr>N. Interior</Nobr>
                                        </label>
                                        <Input id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="50%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[4rem]" htmlFor="first_name">
                                            Colonia
                                        </label>
                                        <Input id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="50%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[4rem]" htmlFor="first_name">
                                            Ciudad
                                        </label>
                                        <Input id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="50%" px={".4rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[4rem]" htmlFor="first_name">
                                            CP
                                        </label>
                                        <Input id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="50%" px={".4rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[4rem]" htmlFor="first_name">
                                            País
                                        </label>
                                        <Input id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>
                            </div>
                        </div>
                        {/* block 3 */}
                        <div className="px-2 pt-3">
                            <div className="w-full flex flex-wrap">
                                <FormControl w="100%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[11.3rem]" htmlFor="first_name">
                                            <Nobr>
                                                Nombre<small>(representante legal)</small>
                                            </Nobr>
                                        </label>
                                        <Input w={"auto"} flexGrow={1} id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="100%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[11.3rem]" htmlFor="first_name">
                                            <Nobr>
                                                CURP<small>(representante legal)</small>
                                            </Nobr>
                                        </label>
                                        <Input w={"auto"} flexGrow={1} id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="100%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[11.3rem]" htmlFor="first_name">
                                            <Nobr>Sitio web</Nobr>
                                        </label>
                                        <Input w={"auto"} flexGrow={1} id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="100%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[11.3rem]" htmlFor="first_name">
                                            <Nobr>Sector al que pertenece</Nobr>
                                        </label>
                                        <Input w={"auto"} flexGrow={1} id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="100%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[11.3rem]" htmlFor="first_name">
                                            <Nobr>N. de empleados</Nobr>
                                        </label>
                                        <Input w={"auto"} flexGrow={1} id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>

                                <FormControl w="100%" px={".4rem"} mb={"1rem"}>
                                    <InputGroup className="space-x-4 items-center justify-between">
                                        <label className="text-sm min-w-[11.3rem]" htmlFor="first_name">
                                            <Nobr>Servicios ofrecidos</Nobr>
                                        </label>
                                        <Input w={"auto"} flexGrow={1} id="first_name" type="text" rounded="none" size="sm" />
                                    </InputGroup>
                                </FormControl>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </CommonModal>
    );
};

export default EditCompanyModal;
