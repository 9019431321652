import { Alert, AlertIcon, Select } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import useCustomToast from "../../hooks/useCustomToast";
import usePdfPreview from "../../hooks/usePdfPreview";
import { VaultFolderChildren } from "../../models/Vault.models";
import vaultService from "../../services/vault.service";
import useModalState, { confirmAlert } from "../../store/modalsState";
import useVaultState from "../../store/vaultState";
import VaultItem from "./VaultItem";
import FileSaver from "file-saver";
import { b64toBlob, getMimeType } from "../../lib/utils";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import DottedSubtitle from "../common/DottedSubtitle";
import VaultDetails from "./VaultDetails";
import VaultBreadcrumb from "./VaultBreadcrumb";
import usePagination from "src/hooks/usePagination";
import Paginator from "../common/Paginator";
import usePageZoom from "src/hooks/usePageZoom";

const VaultFileGrid = () => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const {
        currentFolder,
        selectedFile,
        setCurrentFolder,
        setSelectedFile,
        getCompanyRootFolder,
        getSubFolder,
        deleteFolder,
        deleteDocument,
        addBreadcrumbItem,
    } = useVaultState();
    const { zoom } = usePageZoom();
    const { generatePdfVaultUrl } = usePdfPreview();
    const { setLoaderModal, setPdfPreviewModal } = useModalState();
    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [filter, setFilter] = useState<"" | "folder" | "document">("");

    const folderId = searchParams.get("folderId");

    const filteredResults = useMemo(() => {
        let temp = currentFolder.data?.childrens;
        if (filter) {
            temp = temp?.filter((file) => file.fileType === filter);
        }
        if (searchQuery) {
            temp = temp?.filter((file) => file.name.toLowerCase().includes(searchQuery.toLowerCase()));
        }
        return temp;
    }, [searchQuery, filter, currentFolder.data?.childrens]);

    async function handleDelete(file: VaultFolderChildren) {
        const fileTypeName = file.fileType === "folder" ? "Carpeta" : "Documento";

        confirmAlert.show({
            title: `¿Eliminar ${fileTypeName.toLowerCase()}?`,
            msg: `Una vez ${file?.fileType === "folder" ? "eliminada" : "eliminado"}, no se podrá recuperar`,
            severity: "warning",
            onConfirm: async () => {
                const deleted = file?.fileType === "folder" ? await deleteFolder(file.id) : await deleteDocument(file.id);
                if (deleted) {
                    toast.success(`${fileTypeName} ${file?.fileType === "folder" ? "eliminada" : "eliminado"} correctamente`);
                } else {
                    toast.error(`Error al eliminar ${fileTypeName}`);
                }
            },
        });
    }

    async function handleOpen(e: any, file: VaultFolderChildren) {
        e.preventDefault();
        e.stopPropagation();

        if (file.fileType === "folder") {
            openSubFolder(file.id);
        } else if (file.fileType === "document") {
            //is pdf
            if (file.extension?.replace(".", "") === "pdf") {
                previewPdf(file);
            } else {
                //is other
                donwloadDocument(file);
            }
        }
    }

    async function openSubFolder(folderId) {
        const fetchedFolder = await getSubFolder(folderId);
        if (fetchedFolder) {
            setFilter("");
            setSearchQuery("");
            addBreadcrumbItem({ folderId: folderId, label: fetchedFolder.name });
        }
    }

    async function previewPdf(file: VaultFolderChildren) {
        setLoaderModal(true);
        try {
            const pdfBlobUrl = await generatePdfVaultUrl(file.id);
            setLoaderModal(false);
            setPdfPreviewModal({ show: true, data: { pdfBlobUrl } });
        } catch (error) {
            toast.error("Error al generar previsualización");
        } finally {
            setLoaderModal(false);
        }
    }

    async function donwloadDocument(file: VaultFolderChildren) {
        if (file.fileType === "folder") return null;
        try {
            setLoaderModal(true);
            const res = await vaultService.getDocumentFromVault(file.id);
            if (res.data?.file) {
                const blob = b64toBlob(res.data.file, getMimeType(file.extension));
                FileSaver.saveAs(blob, `${file.name}.${file.extension}`);
            } else {
                throw new Error("Error loading pdf");
            }
        } catch (error) {
            toast.error("Error al descargar documento");
        } finally {
            setLoaderModal(false);
        }
    }

    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({
        data: filteredResults,
        itemsPerPage: zoom <= 100 ? 10 : zoom <= 110 ? 8 : 6,
    });

    const itemH = zoom <= 110 ? 12 : 9.5;
    const repeatColFr = zoom <= 100 ? 5 : zoom <= 110 ? 4 : 3;

    useEffect(() => {
        if (folderId) {
            openSubFolder(folderId);
        } else {
            getCompanyRootFolder();
        }
    }, [folderId]);

    //limpia state cuando de desmonta el componente
    useEffect(
        () => () => {
            setSelectedFile(null);
            setCurrentFolder(null);
        },
        []
    );

    return (
        <div className="w-full h-full overflow-y">
            <div className="w-full flex justify-between items-center sticky top-0 z-10 py-2 bg-white">
                <DottedSubtitle
                    key={"search" + currentFolder?.data?.data?.id}
                    text="Resguardo de documentos en blockchain"
                    withSearch
                    onSearch={setSearchQuery}
                    beforeSearchElement={
                        <Select
                            width="max-content"
                            color="brand.500"
                            fontSize="sm"
                            variant="unstyled"
                            placeholder={t("showAll")}
                            colorScheme="brand"
                            onChange={(e) => setFilter(e.target.value as typeof filter)}
                        >
                            <option value="folder">{t("folders")}</option>
                            <option value="document">{t("documents")}</option>
                        </Select>
                    }
                />
            </div>
            <div className="w-full h-full flex justify-between gap-1 pb-16 relative pt-2">
                <div className="w-full -mt-4 top-0 left-0 absolute z-10">
                    <VaultBreadcrumb />
                </div>
                {!currentResutls?.length ? (
                    <div className="mt-8 w-9/12 pr-8">
                        <Alert status="info" colorScheme="gray">
                            <AlertIcon />
                            {t("notElementsFound")}
                        </Alert>
                    </div>
                ) : (
                    <div className="w-9/12 relative pt-2">
                        <div
                            className="w-full grid items-center justify-center flex-wrap gap-4 h-full overflow-y-auto pr-8"
                            style={{
                                gridTemplateColumns: `repeat(${repeatColFr},minmax(12rem,1fr))`,
                                gridTemplateRows: `repeat(2,minmax(${itemH}rem,${itemH}rem))`,
                            }}
                        >
                            {currentResutls?.map((children) => {
                                return (
                                    <VaultItem
                                        key={children.id}
                                        isSelectedFile={selectedFile?.id === children.id}
                                        file={children}
                                        onDelete={handleDelete}
                                        onOpen={handleOpen}
                                        onDownload={donwloadDocument}
                                        onSelect={(file) => setSelectedFile(file)}
                                    />
                                );
                            })}
                        </div>
                        <div className="w-full absolute left-0">
                            <div className="w-full py-2 mx-auto -mb-4">
                                <Paginator
                                    currentPage={currentPage}
                                    maxPages={maxPages}
                                    onPrev={prev}
                                    onNext={next}
                                    onSetPage={(page) => setPage(page)}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="p-4 grow border-l-[3px] border-dotted border-gray-600/80 ml-4">
                    <h2 className="title-3">{t("details")}</h2>
                    <VaultDetails />
                </div>
            </div>
        </div>
    );
};

export default VaultFileGrid;
