import { Icon } from "@chakra-ui/react";

const FileVaultIcon = (props) => {
    return (
        <Icon viewBox="0 0 35 45" {...props}>
            <rect width={35} height={44.635} y={0.206} fill="#2E54B8" rx={10} />
            <path stroke="#fff" strokeLinecap="round" strokeWidth={2} d="M7 11.625h21M7 22.523h21M7 33.424h21" />
        </Icon>
    );
};

export default FileVaultIcon;
