import axios from "axios";
import { ContractRevisionCreateBody, SendReviewBody } from "../models/Contract.models";
import { getAuthHeader } from "../lib/utils";

export const axiosRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1`,
    timeout: 10000,
});

const listUsers = async (idCompany: string) => {
    try {
        return await axiosRequest.get(`/users/${idCompany}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const createDocumentRevision = async (body: any) => {
    try {
        return await axiosRequest.put(`/review`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getRevisionByDocument = async (documentId: string) => {
    try {
        return await axiosRequest.get(`/review/documentId/${documentId}/activeTrue`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getReviewDocument = async (documentId: string) => {
    try {
        return await axiosRequest.get(`/review/${documentId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const cancelDocumentRevision = async (documentId: string) => {
    try {
        return await axiosRequest.delete(`/review/${documentId}`, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updateDocumentReview = async (documentId: string , body: any) => {
    try {
        return await axiosRequest.patch(`/review/documentId/${documentId}`, body, {
            headers: getHeaders(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};


export default {
    listUsers,
    createDocumentRevision,
    getRevisionByDocument,
    cancelDocumentRevision,
    updateDocumentReview,
    getReviewDocument
};

function getHeaders() {
    return {
        "Content-Type": "application/json",
        ...getAuthHeader(),
    };
}
