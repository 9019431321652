import { Button, Switch } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import useAdministrationState from "../../store/administrationState";
import { NotApplicableUser, User } from "src/models/Aministration.models";
import { TranslationKeys } from "src/i18n/en";
import { useTranslation } from "react-i18next";
import Paginator from "../common/Paginator";
import usePagination from "src/hooks/usePagination";
import CommonTh from "../common/table/CommonTh";
import DottedSubtitle from "../common/DottedSubtitle";
import useCustomToast from "src/hooks/useCustomToast";

const TableRow = ({ user, onChange }: { user: User, onChange: ( userId: string ) => any }) => {
    /** */
    return (
        <tr className="border-b hover:bg-slate-100 transition-colors duration-200">
            <td className="p-2 py-3">
                {`${user.name} ${user.lastName}`}
            </td>
            <td className="p-2 py-3">
                {user.email}
            </td>
            <td className="p-2 py-3">
                {user.external ? 'Externo' : 'Interno'}
            </td>
            <td className="px-2 py-2 flex justify-center items-center">
                <Switch id={'sw-policy-otp'+user.id}
                    size="md"
                    isChecked={user.isActive}
                    onChange={() => onChange( user.id )}
                />
            </td>
        </tr>
    );
};

const UsersOtpTable = ({
    users,
    onChange,
    currentPage,
    maxPages,
    prev,
    next,
    setPage,
}: {
    users: User[];
    onChange: ( userId: string ) => any;
    currentPage: number;
    maxPages: number;
    prev: () => any;
    next: () => any;
    setPage: (page: number) => any;
}) => {
    const t: ( key: TranslationKeys ) => string = useTranslation( "global" )[0];
    return (
        <div className="w-full">
            <table className="w-full text-sm text-left text-dark-2">
                <thead className="text-base text-brand-500 font-medium border-b">
                    <tr>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("name")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("email")} />
                        </th>
                        <th scope="col" className="p-2">
                            <CommonTh text={t("userType")} />
                        </th>
                        <th scope="col" className="p-2 flex justify-center">
                            <CommonTh text="Aplica OTP" />
                        </th>
                    </tr>
                    <tr></tr>
                </thead>
                <tbody>
                    {users?.map( ( user, index ) => (
                        <TableRow key={index} user={user} onChange={() => onChange( user.id )} />
                    ))}
                </tbody>
            </table>
            {users?.length ? (
                <div className="w-full py-6">
                    <Paginator currentPage={currentPage} maxPages={maxPages} onPrev={prev} onNext={next} onSetPage={setPage} />
                </div>
            ) : (
                <div className="w-full text-center p-2 text-dark mt-6">{t("notElementsFound")}</div>
            )}
        </div>
    );
};

const UsersOtpAdmin = () => {
    /** */
    const t: ( key: TranslationKeys ) => string = useTranslation( "global" )[0];
    const { getCompanyUsers, getNotApplicableUsers, saveNotApplicableUsers } = useAdministrationState();
    const [ users, setUsers ] = useState<User[]>([]);
    const [ searchQuery, setSearchQuery ] = useState("");
    const filteredResults = useMemo(() => {
        return users?.filter(
            (item) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                ( item.external ? t("external") : t("internal") ).toLowerCase().includes( searchQuery.toLowerCase() )
        );
    }, [searchQuery, users]);
    const { currentPage, currentResutls, maxPages, next, prev, setPage } = usePagination({ data: filteredResults, itemsPerPage: 6 });
    const [ keyComponent, setKeyComponent ] = useState( Math.random() );
    const toast = useCustomToast();

    useEffect(() => {
        getCompanyUsers().then( ( result ) => {
            /** */
            const usersData: User[] = ( result as any )?.respuesta;

            getNotApplicableUsers().then( ( data ) => {
                /** */
                const notApplicableUsers: NotApplicableUser[] = data.respuesta;

                usersData.map( ( item: User ) => {
                    item.isActive = true;

                    for ( const usr of notApplicableUsers ) {
                        item.id === usr.userId && ( item.isActive = false );
                    }
                });

                setUsers( usersData );
            });
        });
    }, []);

    const handleUsersOtp = ( userId: string ) => {
        /** */
        const updatedUsers = users.map( ( usr ) => {
            usr.id === userId && ( usr.isActive = !usr.isActive );
            return usr;
        });

        setKeyComponent( Math.random() );
    };

    const handleSaveUsers = () => {
        /** */
        const ids: string[] = [];

        for ( const usr of users ) {
            !usr.isActive && ( ids.push( usr.id ) );
        }

        saveNotApplicableUsers( ids ).then( result => {
            /** */
            result?.codigo === 0
                ? toast.success( result.mensaje )
                : toast.error( result.mensaje );
        });
    }

    return (
        <>
            <div className="mt-4 mb-4">
                <DottedSubtitle
                    text=""
                    withSearch
                    onSearch={setSearchQuery}
                />
            </div>
            <UsersOtpTable key={keyComponent}
                users={currentResutls}
                currentPage={currentPage}
                maxPages={maxPages}
                onChange={handleUsersOtp}
                prev={prev}
                next={next}
                setPage={setPage}
            />
            <div className="w-full flex justify-center mt-4">
                <Button
                    type="submit"
                    rounded="full"
                    size="md"
                    onClick={handleSaveUsers}
                    fontWeight="medium"
                    px={"2rem"}
                    isLoading={false}>
                    {t("save")}
                </Button>
            </div>
        </>
    );
};

export default UsersOtpAdmin;
