import { useState, useEffect } from "react";
import { Accordion } from "@chakra-ui/react";
import { AiOutlineFileAdd, AiOutlineFolderAdd } from "react-icons/ai";
import LinkButton from "../../components/common/buttons/LinkButton";
import MainLayout from "../../components/layouts/MainLayout";
import VaultAccordionItems from "../../components/vault/VaultDetails";
import VaultBreadcrumb from "../../components/vault/VaultBreadcrumb";
import VaultFileGrid from "../../components/vault/VaultFileGrid";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useModalState from "../../store/modalsState";
import DottedSubtitle from "src/components/common/DottedSubtitle";
import BoxButton from "src/components/common/BoxButton";
import NewFolderIcon from "src/components/icons/iconsV2/NewFolderIcon";
import NewFileIcon from "src/components/icons/iconsV2/NewFileIcon";
import VaultDetails from "../../components/vault/VaultDetails";

const VaultPage = () => {
    const { setNewVaultFolderModal, setUploadVaultFileModal, setInfoCommonModal } = useModalState();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    useEffect(() => {
        setInfoCommonModal({
            show: true,
            data: {
                text1: "IMPORTANTE",
                text3: t("vaultPageInfoModal"),
                onConfirm: () => setInfoCommonModal({ show: false }),
            },
        });
    }, []);
    //severity
    return (
        <MainLayout noScroll>
            <main className="w-full h-full p-6">
                <h2 className="title-3">{t("vault")}</h2>
                {/*<DottedSubtitle text="Resguardo de documentos en blockchain" />*/}

                <div className="flex space-x-6 mt-6">
                    {/* <BoxButton
                        size="small"
                        icon={<NewFolderIcon boxSize={58} />}
                        text={t("createFolder")}
                        onClick={() => setNewVaultFolderModal({ show: true })}
                    />
                    <BoxButton
                        size="small"
                        icon={<NewFileIcon boxSize={58} />}
                        text={t("addDocument")}
                        onClick={() => setUploadVaultFileModal({ show: true })}
    />*/}
                </div>

                <div className="w-full grow pb-4" style={{ height: "calc(100% - 180px)" }}>
                    <VaultFileGrid />
                </div>
            </main>
        </MainLayout>
    );
};

export default VaultPage;
