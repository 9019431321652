import usePdfCanvasRender from "../../../hooks/usePdfCanvasRender";
import { memo, useEffect, useState } from "react";
import useContractSignaturesState from "../../../store/contractSignatureState";

interface PDFViewerCanvasProps {
    /** PDF url, wether a blob or http url */
    url: string;
    /** Page to be rendered in the component. */
    currentPage: number;
}

/** Component that renders  the current page of the opened pdf. */
function SignPdfViewerCanvas({ url, currentPage }: PDFViewerCanvasProps) {
    const [renderizedPage, setRenderizedPage] = useState(false);
    const { canvasRef, renderPage, pdfRef } = usePdfCanvasRender(url);
    const pdfCanvasSizes = canvasRef.current?.getBoundingClientRect();
    const setPdfCanvasSizes = useContractSignaturesState((s) => s.setPdfCanvasSizes);

    useEffect(() => {
        renderPage(currentPage, pdfRef).then(() => {
            setTimeout(() => {
                setRenderizedPage(true);
            }, 1500);
        });
    }, [currentPage, pdfRef]);

    useEffect(() => {
        if (renderizedPage && pdfCanvasSizes?.width && pdfCanvasSizes?.height) {
            setPdfCanvasSizes({
                height: pdfCanvasSizes.height,
                width: pdfCanvasSizes.width,
                top: pdfCanvasSizes.top,
                left: pdfCanvasSizes.left,
            });
        }

        return () => setPdfCanvasSizes(undefined);
    }, [pdfCanvasSizes?.width, pdfCanvasSizes?.height, renderizedPage]);

    //use effect with window resize event listener
    useEffect(() => {
        const handleResize = () => {
            const pdfCanvasSizes = canvasRef.current?.getBoundingClientRect();
            setPdfCanvasSizes({
                height: pdfCanvasSizes.height,
                width: pdfCanvasSizes.width,
                top: pdfCanvasSizes.top,
                left: pdfCanvasSizes.left,
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <>
            <div className="overflow-auto text-center">
                <canvas
                    id="canvas"
                    className="absolute bottom-0 left-0 right-0 top-0 m-auto shadow-xl border max-h-[88vh]  "
                    ref={canvasRef}
                />
            </div>
        </>
    );
}

export default memo(SignPdfViewerCanvas);
