import { useNavigate } from "react-router";
import BoxButton from "../../components/common/BoxButton";
import MainLayout from "../../components/layouts/MainLayout";
import TemplatesTable from "../../components/templates/TemplatesTable";
import { useEffect } from "react";
import useTemplateState from "../../store/templatesState";
import useCustomToast from "../../hooks/useCustomToast";
import useModalState from "../../store/modalsState";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import DottedSubtitle from "src/components/common/DottedSubtitle";
import DataGroupsIcon from "src/components/icons/iconsV2/DataGroupsIcon";
import NewTemplateIcon from "src/components/icons/iconsV2/NewTemplateIcon";
import ContractTypesIcon from "src/components/icons/iconsV2/ContractTypesIcon";
import useComponetState from "src/store/components/componentState";

const TemplatesPage = () => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const navigate = useNavigate();
    const setContractTypeModal = useModalState((s) => s.setContractTypesModal);
    const toast = useCustomToast();
    const {
        templates: { data, error },
        getTemplates,
    } = useTemplateState();
    const uiTemplateOptions = useComponetState((state) => state.uiBtnTemplateOptions);
    const getAllBtnTemplate = useComponetState((s) => s.getAllBtnTemplate);

    useEffect(() => {
        getTemplates();
        getAllBtnTemplate();
    }, []);

    useEffect(() => {
        if (error) {
            toast.error("Fallo al obtener plantillas");
        }
    }, [error]);

    return (
        <MainLayout>
            <main className="w-full p-6">
                <h2 className="title-3">{t("templates")}</h2>
                <DottedSubtitle text="Crear nueva plantilla" />

                <div className="flex space-x-6 mt-4">
                    {!uiTemplateOptions.showBtnTypesDynamicDocument ? ("") : (
                        <BoxButton
                            icon={<ContractTypesIcon boxSize={45} />}
                            text={t("contractsTypes")}
                            tooltip={""}
                            onClick={() => setContractTypeModal({ show: true })}
                        />
                    )}
                    {!uiTemplateOptions.showBtnGroupUsers ? ("") : (
                        <BoxButton
                            icon={<DataGroupsIcon boxSize={45} />}
                            text={t("dataGroups")}
                            tooltip={t("createDataGroupText")}
                            onClick={() => navigate("/data-groups")}
                        />
                    )}
                    {!uiTemplateOptions.showBtnNewTemplate ? ("") : (
                        <BoxButton
                            icon={<NewTemplateIcon boxSize={45} />}
                            text={t("generateTemplate")}
                            tooltip={t("newTemplateText")}
                            onClick={() => navigate("create")}
                        />
                    )}
                </div>
                <div className="mt-4">
                    <TemplatesTable templates={data} />
                </div>
            </main>
        </MainLayout>
    );
};

export default TemplatesPage;
