import { theme as baseTheme, extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const colors = {
    brandOLD: {
        50: "#e6efff",
        100: "#bfcef4",
        200: "#97aee7",
        300: "#6f8edd",
        400: "#476dd2",
        500: "#2e54b8",
        600: "#224190",
        700: "#182e68",
        800: "#0b1c41",
        900: "#02091b",
    },
    brand: {
        50: "#e3eaff",
        100: "#b2c0ff",
        200: "#8095ff",
        300: "#4e6bfd",
        400: "#1d41fc",
        500: "#0327e2",
        600: "#001fb1",
        700: "#001680",
        800: "#000c4f",
        900: "#000320",
    },
    dark: {
        50: "#f2f2f2",
        100: "#d9d9d9",
        200: "#bfbfbf",
        300: "#a6a6a6",
        400: "#8c8c8c",
        500: "#737373",
        600: "#595959",
        700: "#404040",
        800: "#262626",
        900: "#0d0d0d",
    },
};

const styles = {
    global: {
        " *, *::before, ::after": {
            borderColor: "#A0AEC0",
            wordWrap: "break-word",
        },
    },
};

const components = {
    ModalCloseButton: {
        baseStyle: {
            outline: "none",
            _focus: {
                outline: "none",
            },
        },
    },
    // Table: {
    //     parts: ["td", "th"],
    //     baseStyle: {
    //         th: {
    //             padding: "1rem!important",
    //             all: "unset",
    //             colors
    //         },
    //         td: {
    //             padding: ".8rem 1rem!important",
    //         },
    //     },
    // },
};

const theme = extendTheme(
    withDefaultColorScheme({
        colorScheme: "brand",
        components: ["Button", "Checkbox", "Radio", "Input"],
    }),
    { colors, components, styles }
);

export default theme;
