import { Company, CompanyGroup } from "src/models/Company.models";
import HttpClient from "src/Utils/http-client";

export class CompanyService {
    /** */
    private http = new HttpClient( `${process.env.REACT_APP_API_COMPANY}/api/v1` )

    public getGroupsCompany( companyId: string ): Promise<CompanyGroup[]> {
        return this.http.get<CompanyGroup[]>( `/companies/${companyId}/groups` )
    }

    public getSelectedCompany( companyId: string ): Promise<Company> {
        return this.http.get<Company>( `/companies/${companyId}/public` )
    }

}