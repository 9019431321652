import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { FormErrorMessage } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import useCustomToast from "../../../../hooks/useCustomToast";
import CommonModal from "../../CommonModal";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useAdministrationState from "src/store/administrationState";
import { REGEXPS } from "src/lib/constants";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

interface FormFields {
    name: string;
    rfc: string;
    email: string;
}

const CreateNewCompanyModal = ({ isOpen, onClose }: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const { createCompany, getAssociatedCompanies } = useAdministrationState();

    const formik = useFormik<FormFields>({
        initialValues: {
            name: "",
            rfc: "",
            email: "",
        },
        onSubmit: handleCreate,
        validateOnBlur: true,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required(t("requiredField")),
            rfc: Yup.string().required(t("requiredField")).matches(REGEXPS.rfcReg, t("invalidField")),
            email: Yup.string().required(t("requiredField")).email(t("invalidField")),
        }),
    });

    async function handleCreate(values: FormFields) {
        const body = {
            name: values.name,
            rfc: values.rfc,
            enable: true,
            status: "TEMP",
            platform: "SCM",
            user: {
                username: values.email,
                name: values.name,
                rfc: "",
                email: values.email,
                roles: ["Admin"],
                status: true,
                phone: "",
            },
        };
        const created = await createCompany(body);
        if (created) {
            toast.success("Empresa creada con éxito");
            getAssociatedCompanies(false);
            onClose();
        } else {
            toast.error("Error al crear la empresa");
        }
    }

    return (
        <CommonModal size="xl" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">Crear empresa</h2>

                <form
                    className="w-full flex flex-wrap justify-between items-center gap-2 max-h-[75vh] overflow-y-auto px-2"
                    onSubmit={formik.handleSubmit}
                >
                    <FormControl isInvalid={!!formik.errors.name}>
                        <label htmlFor="name" className="text-sm leading-8">
                            {t("name")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="name"
                                name="name"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.rfc}>
                        <label htmlFor="rfc" className="text-sm leading-8">
                            RFC
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="rfc"
                                name="rfc"
                                type="text"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.rfc}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.rfc}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!formik.errors.email}>
                        <label htmlFor="email" className="text-sm leading-8">
                            {t("email")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input
                                id="email"
                                name="email"
                                type="email"
                                rounded="none"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                        </InputGroup>
                        <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    </FormControl>

                    <div className="mt-8 pb-2 w-full text-center">
                        <Button type="submit" rounded="full" size="md" fontWeight="medium" px={"2rem"} isLoading={false}>
                            {t("add")}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

export default CreateNewCompanyModal;
