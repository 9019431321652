import { useState, useEffect } from "react";

import MainLayout from "../components/layouts/MainLayout";
import ProfileBox from "../components/home/ProfileBox";
import ShorcutsList from "../components/home/ShorcutsList";
import ContractList from "../components/home/ContractList";
import useGlobalState from "src/store/globalState";
import { useNavigate } from "react-router";

const OnboardingPage = () => {
    const loggedUserInfo = useGlobalState((s) => s.userInfo);
    const navigate = useNavigate();
    const isSelectedChildCompany = useGlobalState( s => s.isSelectedChildCompany )

    if ( !isSelectedChildCompany ) {
        navigate( "/group-selection" )
    }

    useEffect( () => {
        navigate("/", { replace: true });
    }, [ loggedUserInfo ]);

    return (
        <>
            <MainLayout>
                <main className="w-full p-6">
                    <div className="w-full flex max-h-[25rem] mb-4">
                        <div className="w-full">
                            <ProfileBox />
                            <ShorcutsList />
                            <ContractList />
                        </div>
                    </div>
                </main>
            </MainLayout>
        </>
    );
};

export default OnboardingPage;


          {/* { loading ? 
            (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white-800 bg-opacity-50">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-blue-200 h-12 w-12 mb-4"></div>
                </div>
            ) : showHomePage === false ? 
            (
                <NewPasswordPage />
            ) : (
                <MainLayout>
                    <main className="w-full p-6">
                        <div className="w-full flex max-h-[25rem] mb-4">
                            <div className="w-full">
                                <ProfileBox />
                                <ShorcutsList />
                                <ContractList />
                            </div>
                        </div>
                    </main>
                </MainLayout>
            )} */}