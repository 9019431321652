import { TranslationObject } from "./en";

const es: TranslationObject = {
    global: {
        //words
        new: "Nuevo",
        create: "Crear",
        userUpdatingError: "Error al actualizar el usuario",
        userUpdatedSuccessfully: "Usuario actualizado con éxito",
        external: "Externo",
        internal: "Interno",
        editUser: "Editar usuario",
        uploadRequirement: "Subir",
        documentType: "Tipo de documento",
        loadingDocument: "Cargando documento",
        max120Chars: "Máximo 120 caracteres",
        deletedBiddingTypeError: "Error al eliminar el tipo de proceso",
        deletedBiddingTypeSuccess: "Tipo de proceso eliminado con éxito",
        biddingTypeWillBeDeleted: "Se eliminará el tipo de proceso",
        biddingWillBeDeleted: "Se eliminará el proceso",
        documentSet: "Set de documentos",
        backToBiddings: "Volver a procesos",
        generateBiddingType: "Nuevo tipo de proceso",
        myBiddingTypes: "Mis tipos de proceso",
        listingBiddingsTypesError: "Error al listar los tipos de proceso",
        beginDateGreaterThanEndDate: "La fecha de inicio debe ser menor a la fecha de fin",
        creatingBiddingError: "Error al crear la proceso",
        biddingCreatedSuccessfully: "proceso creada con éxito",
        listingBiddingTypesError: "Error al listar los tipos de proceso",
        listingBiddingsError: "Error al listar los procesos",
        deletedBiddingError: "Error al eliminar la proceso",
        deletedBiddingSuccess: "proceso eliminada con éxito",
        myBiddings: "Mis procesos",
        biddingTypes: "Tipos de proceso",
        generateBidding: "Nuevo proceso",
        fileAlreadyExists: "El archivo ya existe",
        biddings: "procesos",
        saveChangesBeforeRevision: "Guarda cambios antes de crear una revisión",
        uploadDocxFile: "Subir archivo docx",
        createTemplateFromDocx: "Crear plantilla desde un archivo docx",
        invalidDocxFile: "Archivo docx no válido",
        sessionNotFoundDescription: "La sesión no existe o ha expirado",
        sessionNotFound: "Sesión no encontrada",
        sessionCode: "Código de sesión",
        joinToSession: "Unirse a la sesión",
        copy: "Copiar",
        createSession: "Crear sesión",
        videoConference: "Videoconferencia",
        copiedToClipboard: "Copiado al portapapeles",
        contractAlreadyVerified: "documento ya verificado",
        onlyPdfFiles: "Solo se permiten archivos PDF",
        emailAlreadyRegistered: "E-mail ya registrado",
        inputAnotherEmail: "Pro favor ingrese uno diferente",
        headerAndFooter: "Encabezado y pie de página",
        here: "aquí",
        downloadExcelFile: "Descargar archivo de Excel",
        uploadExcelFile: "Subir archivo de Excel",
        contractsBulkLoad: "Carga masiva de documentos",
        invalidFileType: "Tipo de archivo no válido",
        clonedContractSuccess: "documento clonado con éxito",
        clonedContractError: "Error al clonar el documento",
        clonedBiddingTypeSuccess: "Tipo de Proceso clonado con éxito",
        clonedBiddingTypeSError: "Error al clonar tipo de Proceso",
        clonedTemplateSuccess: "Plantilla clonada con éxito",
        clonedTemplateError: "Error al clonar la plantilla",
        sessionExpired: "La sesión ha expirado, por favor inicia sesión nuevamente",
        invalidPage: "Página no válida",
        page: "Página",
        finish: "Finalizar",
        thereAreStillLabelsToPosition: "Todavía hay etiquetas para posicionar",
        finishTagsPositioning: "Terminar posicionamiento de etiquetas",
        newSignatureGenerated: "Nueva firma generada",
        addSignatureFor: "Añadir firma para",
        newSignatureQuestion: "¿Nueva firma?",
        signaturePositionGenerated: "Posición de firma generada correctamente",
        onceSavedCannotBeChanged: "Una vez guardado, no se puede cambiar",
        areYouSure: "¿Estás seguro?",
        userHasNotGeneratedTag: "Este usuario no tiene etiqueta generada",
        savePosition: "Guardar posición",
        addSignature: "Nueva firma",
        generateSignature: "Generar firma",
        atLeastOneSignerRequired: "Se requiere al menos un firmante",
        requiredCommentWhenRejecting: "Comentario requerido cuando no se aprueba",
        fetchingContractError: "Error al cargar el documento",
        anErrorOcurred: "Ha ocurrido un error",
        document: "Documento",
        markAsRead: "Marcar como leído",
        addAnnex: "Anexo",
        uploadedFileSuccessfully: "Archivo subido con éxito",
        mustUploadFile: "Debe subir un archivo",
        fetchingSignaturesUsersError: "Error al obtener usuarios para firmas",
        addType: "Añadir tipo",
        addNewType: "Añadir nuevo tipo",
        contractTypes: "Tipos de documentos dinámicos",
        manageContractTypes: "Administre documentos dinámicos",
        typeAlreadyExists: "El tipo ya existe",
        positionSignatures: "Posicionar firmas",
        noSignersSelectedYet: "No se han seleccionado firmantes todavía",
        selectSigners: "Seleccionar firmantes",
        atLeastOneRevisorRequired: "Se requiere al menos un revisor",
        noReviewerSelectedYet: "No se ha seleccionado ningún revisor todavía",
        newRevision: "Nueva revisión",
        fetchingRevisionUsersError: "Error al obtener usuarios para revisión",
        revisionCreatedSuccessfully: "Proceso de revisión creado con éxito",
        revisionCreationError: "Error al crear el proceso de revisión",
        revisor: "Revisor",
        dataGroupWillBeDeleted: "Se eliminará el grupo de datos",
        selectOneOrManyDataGroups: "Seleccione uno o varios grupos de datos",
        templateWillBeDeleted: "Se eliminará la plantilla",
        statistics: "Estadísticas",
        editProfile: "Editar perfil",
        changeImage: "Cambiar imagen",
        createdDataTypeSuccessfully: "Se ha creado el tipo de dato correctamente",
        creatingDataTypeError: "Error al crear el tipo de dato",
        newDocument: "Nuevo documento",
        uploadingDocumentError: "Error al subir el documento",
        uploadedDcoumentSuccessfully: "Documento subido con éxito",
        invalidExtension: "Extensión no válida",
        fileRequired: "Archivo requerido",
        newFolder: "Nueva carpeta",
        createdFolderSuccessfully: "Carpeta creada con éxito",
        selectFile: "Seleccionar archivo",
        dragFileHereToUpload: "Arrastra un archivo aquí para subirlo",
        onlyOneFilePerTimeCanBeUploaded: "Solo se puede subir un archivo a la vez",
        profileImage: "Imagen de perfil",
        username: "Nombre de usuario",
        userCreatingError: "Error al crear usuario",
        onlyImagesAllowed: "Solo se permiten imágenes",
        leastOneRoleRequired: "Debes seleccionar al menos un rol",
        leastOneNameRequired: "Debes seleccionar al menos un nombre",
        leastOneDescriptionRequired: "Debes seleccionar al menos una descripción",
        invalidEmail: "Email inválido",
        accept: "Aceptar",
        doYouAgree: "¿Estás de acuerdo?",
        addNewUser: "Agregar nuevo usuario",
        addNewUserGroup: "Crear nuevo grupo de usuarios",
        userWillBeDeleted: "Se eliminará el usuario",
        userGroupWillBeDeleted: "Se eliminará el grupo usuarios",
        users: "Usuarios",
        usersGroup: "Grupos de Usuarios",
        tokenize: "Tokenizar",
        cancelSignatures: "Cancelar firmas",
        backToEdit: "Volver al editar",
        applyChanges: "Aplicar cambios",
        changedStateTo: "cambio estado a",
        showOnEtherScan: "Ver en EtherScan",
        contractToken: "Token del documento",
        contractName: "Nombre del documento",
        tokenizedContractSuccessfully: "Documento tokenizado con éxito",
        tokenizingContractFailed: "Error al tokenizar el documento",
        canceledSignaturesSuccessfully: "Firmas canceladas con éxito",
        cancelingSignaturesError: "Error al cancelar firmas",
        sendingSignaturesError: "Error al enviar firmas",
        signaturesSendedSuccessfully: "Firmas enviadas correctamente",
        cancelingRevisionError: "Error al cancelar proceso de revisión",
        revisionCanceled: "Proceso de revisión cancelado",
        noTypeToThisGroup: "No se agregaron tipos de este grupo",
        general: "General",
        savingContractError: "Error al guardar el documento",
        contractSavedSuccessfully: "documento guardado con éxito",
        templateRequired: "Plantilla requerido",
        errorWhileGeneratingPreview: "Error al generar la vista previa",
        fetchingDataGroupsError: "Error al obtener los grupos de datos",
        updatedDataGroupError: "Error al actualizar los datos del grupo",
        typeWillBeDeleted: "Se eliminara el tipo",
        addNewDataType: "Agregar nuevo tipo de datos",
        fetchingDataTypesError: "Error al obtener tipos de datos",
        leastOneDataTypeRequired: "Al menos 1 tipo de dato es requerido",
        editDataGroups: "Editar grupos de datos",
        signatures: "Firmas",
        revisions: "Revisiones",
        generic: "Genérico",
        myDataGroups: "Mis grupos de datos",
        backToTemplates: "Volver a plantillas",
        activationDate: "Fecha de activación",
        editTemplate: "Editar plantilla",
        activatedTemplate: "Plantilla activada",
        loadData: "Cargar datos",
        noDataGroupSelected: "No se seleccionaron grupos de datos",
        addNew: "Añadir nuevo",
        fetchingContractTypesError: "Error al obtener documentos dinámicos",
        thereIsNoContentToPreview: "No hay contenido para previsualizar",
        templateActivatedSuccessfully: "Plantilla activada correctamente",
        templateActivatedFailed: "Error al activar plantilla",
        templateSavedSuccessfully: "Plantilla guardada correctamente",
        templateSavedFailed: "Error al guardar la plantilla",
        templateCreatedSuccessfully: "Plantilla creada correctamente",
        templateCreationFailed: "Error al crear plantilla",
        showAll: "Mostrar todo",
        documents: "Documentos",
        folders: "Carpetas",
        size: "Tamaño",
        extension: "Extensión",
        listingContractsError: "Error al listar documentos dinámicos",
        all: "Todos",
        borrador: "Borrador",
        revision: "Revisión",
        aprobado: "Aprobado",
        rechazado: "Rechazado",
        "en boveda": "En bóveda",
        "pendiente de firmas": "Pendiente de firmas",
        firmado: "Firmado",
        tokenizado: "Tokenizado",
        myContracts: "Mis documentos dinámicos",
        selectAnActiveTemplate: "Seleccione una plantilla activa",
        activo: "Activo",
        warning: "Atención",
        contractTypeRequired: "El tipo de documento es requerido",
        search: "Buscar",
        filter: "Filtrar",
        notElementsFound: "No se encontraron elementos",
        myTemplates: "Mis plantillas",
        cleanFilters: "Limpiar filtros",
        creation: "Creación",
        lastVersion: "Última versión",
        actions: "Acciones",
        goTo: "Ir a",
        alreadyRegistered: "Ya estoy registrado",
        notAccount: "No tengo una cuenta.",
        register: "Registrarse",
        category: "Categoría",
        createUserGroup: "Asigna tus usuarios a grupos",
        newUserGroup: "Nuevo grupo de usuarios",
        editUserGroup: "Editar grupo de usuarios",
        activeGroups: "Activos",
        selectUser: "Usuario",

        //texts
        assignYourUserGroup: "Selecciona los grupos que desees para asignarlos a un usuario",
        youWillBeRedirectedBPM: "Usted será redirigido al BPMN automáticamente, en caso de no ser redirigido haga click",
        smartContractMaker: "Gestor inteligente de documentos y títulos valor",
        newTemplateText:
            "La plantilla es un formato base que permite al usuario poder generar el contenido del documento incluyendo cláusulas y variables de forma ordenada y versionada.",
        activatedTemplateText:
            "La plantilla activa, a pesar de permitir cambios en el cuerpo del documento, mantiene el nombre, tipo de documento y grupo de datos al momento de su activación.",
        editTemplateText:
            "Edite su plantilla hasta obtener una versión con la información deseada, incluyendo grupo de datos, nombre de plantilla y tipo de documento.",
        editDataGroupsText:
            "Modifique el grupo de sus datos agregando o eliminando las variables deseadas, es importante denotar la importancia del tipo de dato para su tokenización, esto permite también facilitar su llenado en pasos más adelante.",
        createDataGroupText:
            "Cree sus grupos de datos como convenga para sus documentos, agrupe de forma ordenada las variables dentro de cada grupo.",
        createContractText: "Cree su documento con las plantillas activas y rellene la información de forma correcta para su revisión.",
        revisionText:
            "Evalúe los puntos necesarios para aceptar o rechazar el documento. En caso de rechazo, determine puntulamente las razones para quien se lo envio.",
        signaturesText: "Firme sus documentos de forma segura y con validez jurídica de acuerdo a la NOM151. ",
        vaultText: "Lugar inequívoco por su seguridad para resguardo de sus documentos y documentos, con base en tecnología blockchain.",
        administrationText: "Registre sus usuarios, verifique sus roles, cambie el idioma e incluso personalice su perfil.",
        newRevisionText: "Agrega a los involucrados que van a participar en la revisión dándoles un orden de participación.",
        newSignerText: "Agrega a los involucrados que van a participar en el proceso de firmas.",
        //v1
        login: "Iniciar sesión",
        logout: "Cerrar sesión",
        welcome: "Te damos la bienvenida",
        history: "Historial",
        generate: "Generar",
        contract: "Documento dinámico",
        contracts: "Documentos dinámicos",
        dynamicDocs: "Documentos dinámicos",
        templates: "Plantillas",
        active: "Activo",
        pending: "Pendiente",
        information: "Información",
        traceability: "Trazabilidad",
        smartContract: "Smart contract",
        name: "Nombre",
        author: "Autor",
        status: "Estado",
        undefined: "No definido",
        draft: "Borrador",
        creationDate: "Fecha de creación",
        completeName: "Nombre completo",
        nationality: "Nacionalidad",
        street: "Calle",
        edition: "Edición",
        preview: "Previsualizar",
        leasing: "Arrendamiento",
        escrow: "Fideicomiso",
        saveChanges: "Guardar cambios",
        addDataGroup: "Agregar grupo de datos",
        add: "Agregar",
        select: "Seleccionar",
        save: "Guardar",
        cancel: "Cancelar",
        publish: "Publicar",
        group: "Grupo",
        remove: "Remover",
        delete: "Eliminar",
        newDataType: "Nuevo tipo de dato",
        generateDataType: "Generar tipo de dato",
        generateNewContract: "Crear documento dinámico",
        //generateContract: "Generar documentos dinámico",
        generateContract: "Inicio",
        generatedContracts: "Documentos generados",
        contractsByStatus: "Documentos por estado",
        top5contracts: "Top 5 Documentos por tipo",
        generalIndicators: "Indicadores generales",
        contractsTypes: "Tipos de documentos dinámicos",
        contractsFinishedIn: "Documentos finalizados en",
        contractsCanceledOrNotNegotiable: "Cancelado o no negociable",
        selectATraceabilityEventForDetails: "Selecciona un evento de trazabilidad para obtener detalles",
        selectDataGroup: "Seleccionar grupo de datos",
        searchDocumnets: "Buscar documentos",
        selectCompany: "Seleccione una empresa",
        companies: "Empresas",
        addDocument: "Agregar documento",
        addFolder: "Agregar carpeta",
        details: "Detalles",
        activity: "Actividad",
        noInformation: "No hay información",
        noElementSelected: "No hay elemento seleccionado",
        uploadedBy: "Subido por",
        file: "Archivo",
        description: "Descripción",
        address: "Dirección",
        lastName: "Apellido",
        date: "Fecha",
        day: "Día",
        month: "Mes",
        year: "Año",
        state: "Estado",
        text: "Texto",
        numeric: "Numérico",
        selectable: "Selección",
        flag: "Bandera",
        home: "Inicio",
        vault: "Bóveda",
        notifications: "Notificaciones",
        dataGroups: "Grupos de datos",
        template: "Plantilla",
        inVault: "En bóveda",
        type: "Tipo",
        noAuthor: "Sin autor",
        generated: "Generado",
        noGenerated: "No generado",
        generateTemplate: "Nueva plantilla",
        generateDataGroups: "Generar nuevo grupo de datos",
        filterByName: "Filtrar por nombre",
        contractsTotal: "Total de documentos",
        templatesTotal: "Total de plantillas",
        dataGroupsTotal: "Total de grupos de datos",
        groupsTotal: "Grupos en total",
        totalSelected: "Total seleccionado",
        requiredField: "Campo requerido",
        password: "Contraseña",
        email: "E-mail",
        keepSession: "Mantener mi sesión abierta",
        forgetPassword: "¿Olvidaste tu contraseña?",
        resetPass: "Reestablecer contraseña",
        enter: "Ingresar",
        approvals: "Aprobaciones",
        subscribers: "Suscriptores",
        download: "Descargar",
        sign: "Firmar",
        user: "Usuario",
        userName: "Nombre de usuario",
        creationOf: "Creación de",
        loadingFolders: "Cargando carpetas",
        createDocument: "Crear documento",
        createFolder: "Crear carpeta",
        documentName: "Nombre del documento",
        folderName: "Nombre de la carpeta",
        attachFiles: "Adjuntar archivos",
        dragDocumentHere: "Arrastra el documento aquí para subirlo",
        or: "O",
        searchInYourFiles: "Busca en tus archivos",
        onlySelectOneFile: "Solamente puedes seleccionar un archivo",
        upgrade: "Actualizar",
        updatedContract: "documento actualizado",
        updatingDataError: "Error al actualizar los datos",
        pendingSignatures: "Firmas pendientes",
        success: "Éxito",
        successFirmamex: "Se envió a firmamex y se notificó a los firmantes por correo",
        signingProcessError: "Error al crear el proceso de firma",
        fileCreatedAndAssocited: "Archivo creado y asociado al documento",
        associateFileError: "No se pudo asociar el archivo",
        creatingFileError: "Error al crear el archivo",
        creatingFolderError: "Error al crear la carpeta",
        dataNameAlreadyExists: "El nombre de los datos ya existe",
        dataGroupCreated: "Grupo de datos creado",
        creatingDataGroupError: "No se pudo crear el grupo de datos",
        dataGroup: "Grupo de datos",
        updatedDataGroup: "Grupo de datos actualizado",
        templateCreated: "Plantilla creada",
        templateUpdated: "Plantilla actualizada",
        creatingContractError: "Error al crear el documento",
        creatingTraceabilityError: "Error al crear la trazabilidad",
        contractCreatedTraceability: "documento creado con trazabilidad",
        edit: "Editar",
        saveDraft: "Guardar borrador",
        back: "Atrás",
        templateType: "Tipo de plantilla",
        selectDataType: "Seleccionar tipo de datos",
        templateName: "Nombre de la plantilla",
        process: "Procesos",
        data: "Datos",
        relevantDate: "Fecha relevante",
        option: "Opción",
        postalCode: "Código Postal",
        activateTemplate: "Activar plantilla",
        documentSetCreated: "Conjunto de documentos creado correctamente",
        unexpectedError: "Se ha producido un error inesperado, inténtelo de nuevo",
        language: "Lenguaje",
        upload: "Subir",
        updatedFile: "Archivo cargado exitosamente",
        uploadingFileError: "Error al cargar archivo",

        approved: "Aprobado",
        rejected: "Rechazado",
        activated: "Activado",
        finalized: "Finalizado",
        smartContractRule: "Regla de documento inteligente",
        approvalReminder: "Recordatorio de aprobación",
        optionalAuditorRejection: "Rechazo opcional del auditor",
        requiredAuditorRejection: "Rechazo del auditor requerido",
        optionalAuditorApproval: "Aprobación opcional del auditor",
        requiredAuditorApproval: "Se requiere aprobación del auditor",
        requiredAuditorRevision: "Revisión de auditor requerida",
        optionalAuditorRevision: "Revisión opcional del auditor",

        donwloadExcelFormat: "Descargar Excel para alta masiva de documentos",
        uploadExcelFormat: "Alta masiva de documentos",
        loading: "Cargando",

        wantDeleteContract: "¿Quieres eliminar el documento?",
        deletedContractSuccess: "documento eliminado correctamente",
        deletedContractError: "No se pudo borrar el documento",
        wantDeleteTemplate: "¿Quieres eliminar la plantilla?",
        deletedTemplateSuccess: "Plantilla eliminada correctamente",
        deletedTemplateError: "No se pudo borrar la plantilla",
        wantDeleteDataGroup: "¿Quieres borrar el grupo de datos?",
        deletedDataGroupSuccess: "Grupo de datos eliminado correctamente",
        deletedDataGroupError: "No se pudo borrar el grupo de datos",

        wantDeleteDataType: "¿Quieres borrar el tipo de dato?",
        deletedDataTypeSuccess: "Tipo de dato eliminado correctamente",
        deletedDataTypeError: "No se pudo borrar el tipo de dato",

        generateNewContractType: "Generar nuevo tipo de documento",
        contractTypeCreated: "Tipo de documento creado",
        creatingContractTypeError: "Error al crear el tipo de documento",
        deletedContractTypeError: "No se pudo borrar el tipo de documento",
        deletedContractTypeSuccess: "Tipo de documento eliminado correctamente",
        wantDeleteContractType: "¿Quieres borrar el tipo de documento?",

        generateContractType: "Generar nuevo tipo de documento",
        contractType: "Tipo de documento",

        confirm: "Confirmar",
        error: "Error",
        created: "Creado",

        dragFilesHere: "Arrastra y suelta archivos aquí para subirlos",
        uploadAnnex: "Subir anexo",
        uploadAnnexes: "Subir anexos",
        files: "Archivos",
        userInfo: "Información de usuario",
        fileType: "Tipo de archivo",
        showInVault: "Ver en bóveda",

        selectParticipants: "Seleccionar participantes",
        submitForReview: "Enviar documento a revisión",
        editor: "Editor",
        order: "Orden",
        submit: "Enviar",
        invalidField: "Campo no válido",
        addExternalUser: "Agregar usuario externo",
        important: "Importante",
        externalUserInfo: "Si desea agregar un usuario interno, vaya a la sección 'Administrador'",
        revisionNotificationSended: "Se ha enviado una notificación a los participantes",
        revisionCancelAlert: "Está a punto de detener el proceso de revisión actual de este documento",
        cancelRevision: "Cancelar revisión",

        IMPORTANT: "Importante",
        revisionScreenAlert: `Valide toda la información que se muestra, si lo desea adjunte los comentarios que considere
    a partir de su revisión (Los comentarios son meramente informativos, ningún contenido de los comentarios representa su
    respuesta de aprobación o rechazo), al presionar el botón "Aprobar" se asume que ha leído y
    entendió el contenido del documento, de tal manera que lo APRUEBA para iniciar el proceso de firma, presionando la
    El botón "Rechazar" asume que ha leído y comprendido el contenido del documento, de tal manera que
    RECHAZAR para iniciar el proceso de firma.`,
        youHaveBeenSelectedRevision1: "Ha sido seleccionado para participar en el proceso de revisión del documento ",
        youHaveBeenSelectedRevision2: "de la empresa ",
        reject: "Rechazar",
        comments: "Comentarios",
        approve: "Aprobar",
        templateNameExist: "Ya existe un template con el mismo nombre",

        deleteSelectedUserAsk: "¿Está seguro de que desea eliminar el usuario seleccionado?",
        newUser: "Nuevo Usuario",
        roles: "Roles",
        administration: "Administración",
        dragIamgeHere: "Arrastra la imagen aquí para subirla",
        attachProfilePhoto: "Adjuntar foto de perfil",
        userCreatedSuccessfully: "Usuario creado correctamente",
        userAlreadyExists: "El usuario ya existe",
        userDeletedSuccessfully: "Usuario eliminado correctamente",
        deletingUserError: "No se pudo eliminar el usuario",
        genericError: "Ha ocurrido un error",
        phone: "Celular",

        userGroupCreatedSuccessfully: "User Group created successfully",
        userGroupCreatingError: "User group could not be deleted",

        revisionAproved: "La revisión ha sido aprobada",
        revisionRejected: "La revisión ha sido rechazada",
        saveInVault: "Guardar en bóveda",
        contractTypeAlredyExists: "El tipo de documento ya existe",

        cancelSigns: "Cancelar firmas",

        reminder: "Recordatorio",
        reminderSendSuccess: "Recordatorio enviado correctamente",
        reminderSendError: "No se pudo enviar el recordatorio",
        docApproved: "Documento aprobado",
        docRejected: "Documento rechazado",
        awaitingResponse: "Esperando respuesta",
        newOnes: "Nuevas",
        previousOnes: "Anteriores",
        withoutNotifications: "No hay notificaciones",

        revisionTracking: "Seguimiento de revisión",
        sendRevision: "Enviar a revisión",
        mustCompleteInfoAndSave: "Debe completar los datos y guardar cambios",
        signatureTracking: "Seguimiento de firmas",

        revisionCompleted: "Revisión completada",
        signaturesCompleted: "Firmas completadas",
        contractAlreadyExists: "Ya existe un documento con el mismo nombre",

        showChanges: "Mostrar cambios",
        hideChanges: "Ocultar cambios",

        signers: "Firmantes",
        open: "Abrir",
        editor_lang: "es",
        typeFieldRequired: "El campo TIPO es requerido",
        nameRequired: "Nombre requerido",
        commentsRequired: "Comentarios requeridos cuando se rechaza la revisíon",
        withoutType: "Sin tipo",
        requiredDataType: "Al menos un tipo de datos es requerido",

        nameBidding: "Nombre proceso",
        bidding: "procesos",
        newBidding: "Nueva proceso",
        typeBidding: "Tipos de proceso",
        editTypeBidding: "Editar Tipo de proceso",
        myBidding: "Mis proceso",
        addNewTypeBiddingStage: "Agregar nueva etapa",
        editTypeBiddingStage: "Editar etapa",
        deleteTypeBiddingStage: "Borrar etapa",
        editTypeBiddingGroup: "Editar grupo",
        deleteTypeBiddingGroup: "Eliminar grupo",
        addNewTypeBiddingGroup: "Agregar nuevo grupo",
        addNewTypeBiddingRequirement: "Agregar nuevo requerimiento",
        editTypeBiddingRequirement: "Editar requerimiento",
        deleteTypeBiddingRequirement: "Eliminar requerimiento",
        addStage: "Agregar nueva etapa",
        addGroup: "Agregar nuevo grupo",
        newTypeBidding: "Nuevo Tipo de proceso",
        addRequirement: "Agregar nuevo requerimiento",
        groups: "Grupos",
        actionsGroup: "Acciones del grupo",
        requirements: "Requerimiento",

        processes: "Procesos",
        newProcess: "Nuevo Proceso",
        typeProcess: "Tipos de Procesos",
        myProccess: "Mis Procesos",
        backToProccess: "Volver a Procesos",
        generateProccessType: "Nuevo Tipo de Proceso",
        myProccessTypes: "Mis tipos de Procesos",
        newTypeProccess: "Nuevo Tipo Proceso",
        editTypeProccess: "Editar Tipo de Proceso ",
        listingProccessTypesError: "Error al cargar tipo de Procesos",
        proccessTypeWillBeDeleted: "Se eliminará el proceso",
        deletedProccessTypeSuccess: "Proceso eliminado con éxito",
        deletedProccessTypeError: "Error al borrar EL Proceso",
        nameProccess: "Nombre del Proceso",
        proccessCreatedSuccessfully: "Proceso creado con éxito ",
        creatingProccessError: "Error al crear el Proceso",
        fileCorrupt: "El archivo está vacío (0 KB) o corrupto",
        groupUser: "Grupo de Usuarios",
        userGroups: " Grupo de Usuarios",
        generateUserGroups: "Grupos de usuarios",
        myUserGroups: "Mis grupos de usuarios",
        permissionLevel: "Nivel de permiso",
        profiles: "Perfiles",
        profilesTitle: "Perfiles del proceso",
        profilesSubTitle: "Visualiza los perfiles creados en este proceso",
        createNewContract: "Crear nuevo documento",
        // process
        vaultPageInfoModal:
            "Este apartado es únicamente para consultar la información que has subido, si deseas subir información ingresa en la pestaña de procesos.",
        createSignatureProcess: "Crear proceso de firma",
        biddingPageTooltip:
            "Son los datos personales que se van recabando durante la creación de tu proceso. Por ejemplo, RFC, Razón social, Nombres, etc...",
        createNewProcessType: "Crear nuevo tipo de proceso",
        generateNewProcess: "Generar un nuevo Proceso",
        createNewProcess: "Crear nuevo proceso",
        formatNameInProcess: "Formato de nombre en nuevos procesos",
        nameFormat: "Formato nombre",
        newProcessTypeCreated: "Nuevo tipo de Proceso creado",
        processNameRequired: "Falta nombre del tipo de Proceso",
        errorCreatingProcessType: "Error al crear tipo de Proceso",
        updatedProcessType: "Tipo de Proceso actualizado",
        errorUpdatingProcessType: "Error al actualizar tipo de Proceso",
        addNewStageToProcess: "Agregar nueva etapa al proceso",
        addProperties: "Agregar propiedades",
        addPropertiesToProcess: "Agregar propiedades al proceso",
        processProperties: "Propiedades del Proceso",
        aditionalFeatures:
            "Las caracteristicas adicionales representan valores por default que se asocian a cada proceso generado apartir de este Tipo de Proceso.",
        processNotificationConfig: "Configuración de notificaciones para este proceso",
        processNotifications: "Notificaciones del proceso",
        seeNotificationsInProcess: "Visualiza las notificaciones creadas en este proceso",
        profileProcessConfig: "Configuración de perfiles para este proceso",
        assignUsersToProcess: "Asignar usuarios a proceso",
        addPermissionsToProcess: "Configurar permisos al proceso",
        processType: "Tipo de Proceso",
        noPowerBIURLasociatedWithProcess: "No cuenta con ningún reporte Power Bi asociado a este proceso",
        generatingProcess: "Generando proceso",
        shortcuts: "Accesos rápidos",
        recentDocuments: "Documentos recientes",
        seeMore: "Ver más",
        aceptado: "Aceptado",
        ownershipCompany: "Empresa propietaria",
        affiliatedCompany: "Empresa asociada",
        startDate: "Fecha de inicio",
        endDate: "Fecha de fin",
        stages: "Etapas",
        editExpedientData: "Editar datos del expediente",
        addReportDashboard: "Agregar tablero de reporte",
        notificationSetting: "Configurar Notificaciones",
        createGroup: "Crear Grupo",
        superCategoryLabel: "Etiqueta Super categoría",
        label: "Etiqueta",
        addLabels: "Añadir campos",
        addNewConfig: "Agregar nueva configuración",
        assignUsersToNotifications: "Asignar usuarios a notificaciones",
        configName: "Nombre de la configuración",
        configEvents: "Configure los eventos en las etapas, grupos, requerimientos y documentos",
        rolesAndPermissions: "Roles y Permisos",
        Insertaunanuevoproceso: "Inserta una nuevo proceso",
        Actualizaunproceso: "Actualiza un proceso",
        Agregaunnuevoanexoaunproceso: "Agrega un nuevo anexo a un proceso",
        Agregaunnuevodocumentoaunproceso: "Agrega un nuevo documento a un proceso",
        AgregaunnuevodocumentotipoSmartContractaunproceso: "Agrega un nuevo documento tipo Smart Contract a un proceso",
        Actualizaunurlpowerbi: "Actualiza un url power bi",
        noBiddingSelected: "No se ha seleccionado ninguna etapa",
        requirementType: "Tipo de requerimiento",
        minDocumentsRequired: "Minimo de documentos requeridos",
        maxDocumentsRequired: "Máximo de documentos requeridos",
        optionalDocs: "La cantidad de documentos es opcional",
        unlimitedDocs: "La cantidad de documentos es ilimitada",
        alwaysNew: "Requerimiento siempre nuevo",
        validationType: "Tipo de validación",
        nameId: "Id",
        documentSendReviewSuccessfully: "Documento enviado a revision exitosamente",
        maxLifeStage: "Etapa máxima",
        // Administration
        configuration: "Configuración",
        authentication: "Autenticación",
        policies: "Políticas",
        noPoliciesData: "No hay políticas definidas para la autenticación a 2 pasos",
        otpTitle: "Autenticación de 2 pasos",
        addExceptions: "Agregar excepciones",
        userType: "Tipo de usuario",
        userExceptions: "Excepciones de usuarios",
        // template settings footer & header
        saveTemplateAsDraft: "Guarda tu plantilla como borrador",
        msgTemplateAsDraft: "Después de guardarla como borrador, podrás ver la opción para agregar encabezado y pie de página.",
        generalInfo : "Detalles del documento",
        calendarTabName: "Calendario",
        errorSession : "Ha habido una interrupción con la conexión a Internet. Por favor, inicie sesión de nuevo para continuar.",
        reviewExternalUserText: "Agrega participantes o revisores externos al proceso de revisión.",
        tooltipExternalUserText : "Estos usuarios son externos ,no cuentan con acceso a la plataforma pero pueden participar en la revisión."
    },
};

export default es;
