import axios from "axios";
import { getAuthHeader } from "../../lib/utils";

export const axiosScmRequest = axios.create({
    baseURL: `${process.env.REACT_APP_API_SCM}/api/v1/permission`,
    timeout: 20000,
});

const createPermission = async (body: any) => {
    try {
        return await axiosScmRequest.put(``, body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const getPermissions = async () => {
    try {
        return await axiosScmRequest.get(``, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const deletePermissionById = async (id: string) => {
    try {
        return await axiosScmRequest.delete(`/id/${id}`, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

const updatePermission = async (body: any) => {
    try {
        return await axiosScmRequest.patch(``, body, {
            headers: getAuthHeader(),
        });
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    createPermission,
    getPermissions,
    deletePermissionById,
    updatePermission,
};
