import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { InputLeftElement, useBoolean, useClipboard } from "@chakra-ui/react";
import CommonModal from "../CommonModal";
import LinkButton from "../buttons/LinkButton";
import { BiPlus } from "react-icons/bi";
import { FaRegKeyboard } from "react-icons/fa";
import { IoArrowForwardCircle } from "react-icons/io5";
import videoMeetingService from "../../../services/videoMeeting.service";
import { useState } from "react";
import useCustomToast from "../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

const VideoMeetingModal = ({ isOpen, onClose }: Props) => {
    const toast = useCustomToast();
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const [sessionId, setSessionId] = useState("");
    const [inputSessionId, setInputSessionId] = useState("");
    const { onCopy } = useClipboard(sessionId);
    const [loading, setLoading] = useBoolean(false);

    async function handleCreateSession() {
        setLoading.on();
        const res = await videoMeetingService.createSession();
        setLoading.off();
        if (res.data?.codigo === 0) {
            setSessionId(res.data?.respuesta);
            setInputSessionId(res.data?.respuesta);
        }
    }

    function handleCopy() {
        onCopy();
        toast.success(t("copiedToClipboard"));
    }

    function handleJoinSession() {
        window.open(`${window.location.origin}/external/meet/${inputSessionId}`, "_blank");
        onClose();
    }
    return (
        <CommonModal size="lg" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center py-4">
                <h3 className="title-3">{t("videoConference")}</h3>

                <div className="mt-8 w-full flex flex-col items-center gap-2">
                    <Button variant={"solid"} size="md" leftIcon={<BiPlus size={20} />} onClick={handleCreateSession} isLoading={loading}>
                        {t("createSession")}
                    </Button>
                    {/* <Button variant={"solid"} size="md" colorScheme={"green"}>
                        Unirse ahora
                    </Button> */}
                    {Boolean(sessionId) && (
                        <div className="w-full mt-4 bg-slate-200 rounded-lg py-2 px-4 flex justify-between items-center gap-1">
                            <div className="break-all text-sm">{sessionId}</div>
                            <LinkButton onClick={handleCopy}>{t("copy")}</LinkButton>
                        </div>
                    )}
                </div>

                <div className="flex flex-col gap-2 items-center my-8 w-full">
                    <h3 className="text-base text-dark font-semibold text-center w-10/12 mx-auto">{t("joinToSession")}:</h3>

                    <div className="flex gap-2 items-center w-full">
                        <FormControl>
                            <InputGroup className="items-center justify-between w-full">
                                <InputLeftElement color="gray.400" pointerEvents="none" children={<FaRegKeyboard size={20} />} />
                                <Input
                                    key={"sessionCode-" + sessionId}
                                    w={"auto"}
                                    flexGrow={1}
                                    type="text"
                                    rounded="none"
                                    size="md"
                                    placeholder={t("sessionCode")}
                                    value={inputSessionId}
                                    onChange={(e) => setInputSessionId(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                        <button className="text-brand-500" onClick={handleJoinSession}>
                            <IoArrowForwardCircle size={30} color="inherit" />
                        </button>
                    </div>
                </div>
            </div>
        </CommonModal>
    );
};

export default VideoMeetingModal;
