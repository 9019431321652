import { GenericApiResponse } from "src/models/generic-api-response"
import HttpClient from "src/Utils/http-client"

export class CalendarSerivce {
    /** */
    private http = new HttpClient( `${process.env.REACT_APP_API_SCM}/api/v1/calendar` )

    public getAllYearsHolidaysDays( companyId: string ): Promise<GenericApiResponse> {
        return this.http.get( `/idCompany/${companyId}` )
    }

    public getHolidaysDaysByYear( companyId: string, year: number ): Promise<GenericApiResponse> {
        return this.http.get( `/idCompany/${companyId}/year/${year}` )
    }

    public addDateToCalendar( calendarId: string, date: string ): Promise<GenericApiResponse> {
        return this.http.patch( `/addDate/${calendarId}`, { date } )
    }

    public removeToCalendar( calendarId: string, date: string ): Promise<GenericApiResponse> {
        return this.http.patch( `/removeDate/${calendarId}`, { date } )
    }

}