import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import CommonModal from "../../CommonModal";
import { Accordion, Button, AccordionButton, AccordionIcon, Badge } from "@chakra-ui/react";
import AccordionItemBrand from "../../AccordionItemBrand";
import useBiddingState from "src/store/biddings/biddingState";
import useBiddingProfileState from "src/store/biddings/biddingProfileState";
import { useEffect, useState } from "react";
import { DataGroup } from "src/models/DataGroup.models";
import { ExpedientModalData } from "src/models/Modals.models";
import useCustomToast from "src/hooks/useCustomToast";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { MdDisabledVisible } from "react-icons/md";
import { BiAddToQueue } from "react-icons/bi";
import { FormControl, Input, InputGroup, Tooltip, useBoolean, Alert, AlertIcon } from "@chakra-ui/react";
import { GrRadialSelected, GrRadial } from "react-icons/gr";
import useAuthState from "../../../../store/authState";
import useAdministrationState from "../../../../store/administrationState";
import CommonTh from "../../../common/table/CommonTh";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import LinkButton from "../../buttons/LinkButton";
import React from "react";
import { UserGroup } from "src/models/Aministration.models";
import { IoClose } from "react-icons/io5";
import UserIcon from "src/components/icons/UserIcon";
import SlicedText from "../../SlicedText";
import useBiddingTypesState from "src/store/biddings/biddingTypesState";
import GoBackLinkButton from "../../buttons/GoBackLinkButton";

const PopulateGroupsBidding = ({ stage, group, updatedData, setUpdatedData }) => {
    const handleToggleValidity = (e, itemName) => {
        e.preventDefault();
        const updatedDataCopy = [...updatedData];
        const updatedDataWithFalse = updateisValidGroup(itemName, updatedDataCopy);
        setUpdatedData(updatedDataWithFalse);
    };

    const updateisValidGroup = (groupName, updatedDataCopy) => {
        const updatedDataWithFalse = updatedDataCopy.map((item) => {
            if (item.name === stage) {
                item.groups.forEach((group) => {
                    if (group.name === groupName) {
                        group.isValid = !group.isValid;
                    }
                    return group;
                });
            }
            return item;
        });
        return updatedDataWithFalse;
    };

    const handleToggleValidityRequiriment = (itemName, groupName) => {
        const updatedDataCopy = [...updatedData];
        const updatedDataWithFalse = updatedDataCopy.map((item) => {
            if (item.name === stage) {
                item.groups.forEach((group) => {
                    if (group.name === groupName) {
                        group.requirements.map((req) => {
                            if (req.name === itemName) {
                                req.isValid = !req.isValid;
                            }
                            if (group.requirements.length === 0) {
                                //agregar validacion cuando no exista ningun req marcado
                                group.isValid = false;
                                updateisValidGroup(groupName, updatedDataCopy);
                                setUpdatedData(updatedDataWithFalse);
                            }
                            return req;
                        });
                    }
                    return group;
                });
            }
            return item;
        });
        setUpdatedData(updatedDataWithFalse);
    };

    return (
        <div>
            {group.map((group, idx) => {
                return (
                    <div>
                        <Tabs id={idx}>
                            <TabList>
                                <Tooltip label={group.isValid ? "Habilitar grupo" : "Deshabilitar grupo"} fontSize="15px">
                                    <Tab onClick={(e) => handleToggleValidity(e, group.name)}>
                                        <div id={idx} className="flex flex-row lg:flex-row  grid-cols-2 gap-4 ">
                                            <div id={idx}>{`Grupo : ${group.name}`}</div>

                                            {group.isValid ? (
                                                <div>
                                                    <button className="text-gray-700">
                                                        <AiOutlineCheckCircle style={{ color: "#708090" }} />
                                                    </button>
                                                </div>
                                            ) : (
                                                <div>
                                                    <button className="text-gray-700">
                                                        <AiOutlineCheckCircle style={{ color: "#00cc00" }} />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </Tab>
                                </Tooltip>
                            </TabList>
                            <TabPanels id={idx}>
                                <TabPanel>
                                    <ul key={idx} className="mb-4">
                                        {group.isValid
                                            ? ""
                                            : group.requirements.map((op, i) => {
                                                  return (
                                                      <li key={i} className="mb-2">
                                                          <label className="flex items-center">
                                                              <input
                                                                  type="checkbox"
                                                                  id={i}
                                                                  name="options"
                                                                  value={op.name}
                                                                  checked={op.isValid}
                                                                  onChange={() => handleToggleValidityRequiriment(op.name, group.name)}
                                                                  className="mr-2"
                                                              />
                                                              {`${op.name}`}
                                                          </label>
                                                      </li>
                                                  );
                                              })}
                                    </ul>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                );
            })}
        </div>
    );
};

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    isNewProfile?: boolean;
    tempData?: any;
    data?: any;
    biddingId?: string;
};

const CreateProfileBidding = ({ isOpen, onClose, isNewProfile, tempData, data, biddingId }: Props) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const toast = useCustomToast();
    const { createBiddingProfile, updateBiddingProfile } = useBiddingProfileState();
    const {
        getBiddingType,
        infoBiddingTypeById: { data: infoBiddingTypeById },
    } = useBiddingTypesState();
    const hasDataGroups = data?.documentSet.stages;
    const [typeName, setTypeName] = useState(tempData && tempData.name !== undefined ? tempData.name : "");
    const [description, setDescription] = useState(tempData && tempData.description !== undefined ? tempData.description : "");

    const [company, setCompany] = useState(null);
    const [biddingStages] = useState(data?.documentSet.stages);
    const [copyBidding, setCopyBidding] = useState([]);
    const [invalidName, setInvalidName] = useBoolean(false);
    const [updatedData, setUpdatedData] = useState(data?.documentSet.stages);

    const [filteredStages, setFilteredStages] = useState<any[]>([]);
    const idCompany = useAuthState.getState().companyId;
    const [biddingTypeData] = useState(data);

    async function handleSave(stages) {
        biddingTypeData.documentSet.stages = stages;
        let body = {
            biddingId: biddingId,
            biddingType: biddingTypeData,
            biddingTypeId: biddingTypeData.id,
            companyOwnerId: idCompany,
            description: description,
            enabled: true,
            name: typeName,
            userProfileIdList: [],
        };

        const saved = createBiddingProfile(body);
        if (saved) {
            toast.success("Se ha guardado el perfil correctamente");
            onClose();
        } else {
            toast.error("Ha ocurrido un error al guardar el perfil");
            onClose();
        }
    }

    async function handleUpdate(stages) {
        tempData.biddingType.documentSet.stages = stages;
        tempData.name = typeName;
        tempData.description = description;

        const saved = updateBiddingProfile(tempData);
        if (saved) {
            toast.success("Se ha actualizado el perfil correctamente");
            onClose();
        } else {
            toast.error("Ha ocurrido un error al actualizar el perfil");
            onClose();
        }
    }

    function isCreateOrEditProfile(stages) {
        if (isNewProfile) {
            handleUpdate(stages);
        } else {
            handleSave(stages);
        }
    }

    const handleToggleValidity = (e, itemName, isValid) => {
        if (isValid!) {
            e.preventDefault();
        }
        const updatedDataCopy = [...updatedData];
        const updatedDataWithToggledValidity = updatedDataCopy.map((item) => {
            if (item.name === itemName) {
                item.isValid = !item.isValid;
            }
            return item;
        });
        setUpdatedData(updatedDataWithToggledValidity);
    };

    const filterIsValidaStage = () => {
        // filtrar si es valido stage-group-requiriment
        const updatedDataCopy = [...updatedData];
        const updatedDataWithFalse = updatedDataCopy.map((item) => {
            item.groups = item.groups.filter((group) => !group.isValid);

            item.groups.forEach((group) => {
                group.requirements = group.requirements.filter((requirement) => requirement.isValid); // Filtrar los requisitos que tienen isValid como false
                return group;
            });
            return item;
        });

        const filteredData = updatedDataWithFalse.filter((item) => !item.isValid);

        isCreateOrEditProfile(filteredData);
        setUpdatedData(filteredData);
    };

    const verifyProfileStages = () => {
        // comparar stages si profile para editar
        const updatedDataCopy = [...updatedData]; // stages del proceso original
        const profileStages = tempData.biddingType.documentSet.stages; // stages del profile a editar

        const updatedDataWithFalse = updatedDataCopy.map((item) => {
            const correspondingItem = profileStages.find((otherItem) => otherItem.name === item.name);

            if (correspondingItem) {
                item.groups.forEach((group) => {
                    const correspondingGroup = correspondingItem.groups.find((otherGroup) => otherGroup.name === group.name);

                    if (correspondingGroup) {
                        group.requirements.forEach((requirement) => {
                            const correspondingRequirement = correspondingGroup.requirements.find(
                                (otherRequirement) => otherRequirement.name === requirement.name
                            );

                            if (correspondingRequirement) {
                                item.isValid = true;
                                group.isValid = true;
                                requirement.isValid = false;
                            }

                            return requirement;
                        });
                    }

                    return group;
                });
            }

            return item;
        });
        setUpdatedData(updatedDataWithFalse);
    };

    const handleUpdateData = () => {
        const updatedDataCopy = [...updatedData];

        const updatedDataWithFalse = updatedDataCopy.map((item) => {
            item.isValid = false;
            item.groups.forEach((group) => {
                group.isValid = false;
                group.requirements.forEach((requirement) => {
                    requirement.isValid = true;
                    return requirement;
                });
                return group;
            });
            return item;
        });
        setUpdatedData(updatedDataWithFalse);
    };

    useEffect(() => {
        setUpdatedData(copyBidding);
        handleUpdateData();
        if (isNewProfile) {
            verifyProfileStages();
        }
    }, []);

    return (
        <div className="w-full flex flex-col h-full relative pb-2 pt-6">
            {
                <div className="w-full flex items-end justify-between gap-6 sticky top-0">
                    <div className="ml-4 text-md">
                        <GoBackLinkButton onClick={() => onClose(true)} />
                    </div>
                    <div className="mr-6">
                        <Button rounded="full" onClick={filterIsValidaStage}>
                            Guardar
                        </Button>
                    </div>
                </div>
            }

            {updatedData ? (
                <div className="px-2 mt-2 overflow-auto">
                    <div className="title-2 w-full flex flex-wrap justify-between items-center gap-2 max-h-[15vh] overflow-y-auto px-2">
                        <FormControl flex={1} isInvalid={invalidName} w="38%">
                            <label htmlFor="type_name" className="text-sm leading-8 ">
                                {"Nombre del perfil"}
                            </label>
                            <InputGroup borderColor="gray.400" className="rounded-lg border-2">
                                <Input
                                    id="type_name"
                                    type="text"
                                    rounded="none"
                                    value={typeName}
                                    placeholder="Nombre"
                                    onChange={(e) => setTypeName(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl flex={1} isInvalid={invalidName} w="48%">
                            <label htmlFor="type_name" className="text-sm leading-8 ">
                                {"Descripción"}
                            </label>
                            <InputGroup borderColor="gray.400" className="rounded-lg border-2">
                                <Input
                                    id="type_name"
                                    type="text"
                                    rounded="none"
                                    value={description}
                                    placeholder="Descripción"
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </InputGroup>
                        </FormControl>
                    </div>
                    <div className="ml-2 pt-2 pb-2">
                        <h2 className="title-3">{t("addPermissionsToProcess")}</h2>
                        <h3 className="text-dark">Asignar las etapas, grupos y requerimientos que podrá visualizar el perfil</h3>
                    </div>
                    <div className="w-full h-full overflow-y-auto">
                        <Accordion allowToggle className="mb-3 overflow-y-auto">
                            {updatedData.map((dg, idx) => (
                                <AccordionItemBrand
                                    key={idx}
                                    // isValid={dg.isValid!}
                                    title={`Etapa : ${dg.name}`}
                                    actions={
                                        <>
                                            {dg.isValid ? (
                                                <Tooltip label={"Habilitar etapa"} fontSize="12px">
                                                    <button
                                                        className="text-gray-700"
                                                        onClick={(e) => handleToggleValidity(e, dg.name, dg.isValid)}
                                                    >
                                                        <AiOutlineCheckCircle style={{ color: "#999999", width: "30px", height: "30px" }} />
                                                    </button>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip label={"Deshabilitar etapa"} fontSize="12px">
                                                    <button
                                                        className="text-gray-700"
                                                        onClick={(e) => handleToggleValidity(e, dg.name, dg.isValid!)}
                                                    >
                                                        <AiOutlineCheckCircle style={{ color: "#00cc00", width: "30px", height: "30px" }} />
                                                    </button>
                                                </Tooltip>
                                            )}
                                        </>
                                    }
                                >
                                    <PopulateGroupsBidding
                                        stage={dg.name}
                                        group={dg.groups}
                                        updatedData={updatedData}
                                        setUpdatedData={setUpdatedData}
                                    />
                                </AccordionItemBrand>
                            ))}
                        </Accordion>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center h-full">
                    <h3 className="text-dark">No hay datos para mostrar</h3>
                </div>
            )}
        </div>
    );
};

export default CreateProfileBidding;
