import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useEffect } from "react";
import { BiChevronRight } from "react-icons/bi";
import { useNavigate, useSearchParams } from "react-router-dom";
import useVaultState from "../../store/vaultState";

const VaultBreadcrumb = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { currentBreadcrumb, sliceBreadcrumb, getCompanyRootFolder, getSubFolder } = useVaultState();

    async function handleClick(idx, folderId) {
        const fetched = await getSubFolder(folderId);
        if (fetched) {
            sliceBreadcrumb(idx);
        }
    }

    async function handleRootClick() {
        const folderId = searchParams.get("folderId");

        if (folderId) {
            sliceBreadcrumb(null);
            navigate("/vault");
        } else {
            const fetched = await getCompanyRootFolder();
            if (fetched) {
                sliceBreadcrumb(null);
            }
        }
    }

    useEffect(() => {
        return () => sliceBreadcrumb(null);
    }, []);

    return (
        <Breadcrumb spacing="3px" separator={<BiChevronRight size={20} className="text-gray-400" />}>
            <BreadcrumbItem>
                <BreadcrumbLink as={"button"} onClick={handleRootClick} className="font-light text-brand-500 italic" color={"brand.500"}>
                    Home
                </BreadcrumbLink>
            </BreadcrumbItem>
            {currentBreadcrumb?.map((item, idx) => {
                return (
                    <BreadcrumbItem key={item.folderId}>
                        <BreadcrumbLink
                            as={"button"}
                            onClick={() => handleClick(idx, item.folderId)}
                            className="max-w-[8.5rem]"
                            color={"brand.500"}
                        >
                            <div className="font-light text-brand-500 italic text-ellipsis overflow-hidden p-1">{item.label}</div>
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};

export default VaultBreadcrumb;
