import { Button } from "@chakra-ui/button";
import { FormControl, Input, InputGroup, useBoolean } from "@chakra-ui/react";
import { useState } from "react";
import useCustomToast from "../../../../hooks/useCustomToast";
import CommonModal from "../../CommonModal";
import { UploadBiddingReqAxCalcModalData } from "../../../../models/Modals.models";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import useBiddingState from "src/store/biddings/biddingState";
import { blobToBase64, getMimeType } from "src/lib/utils";
import BiddingAxCalc from "src/components/bidding/BiddingAxCalc";
import AddFileToCalcModal from "../AddFileToCalcModal";
import BiddingAxRoadmap from "src/components/bidding/AxRoadmap/BiddingAxRoadmap";
import { useAxCalcStore } from "src/store/axCalc.store";
import { useAxRoadmapStore } from "src/store/axRoadmap.store";

type Props = {
  isOpen: boolean;
  onClose: (...args: any) => any;
  data?: UploadBiddingReqAxCalcModalData;
};

const UploadBiddingAxCalcModal = ({ isOpen, onClose, data }: Props) => {
  const toast = useCustomToast();
  const t: (key: TranslationKeys) => string = useTranslation("global")[0];
  const uploadDocument = useBiddingState((s) => s.addBiddingRequirementDocument);
  const edditDocument = useBiddingState(s => s.editBiddingRequirementDocument);
  const uploadDocumentToSession = useBiddingState((s) => s.addDocumentToSession);
  const uploadAnnex = useBiddingState((s) => s.addBiddingRequirementAnnex);
  const [description, setDescription] = useState("");
  const [invalidDesc, setInvalidDesc] = useBoolean(false);
  const [file, setFile] = useState<File | null>(null);
  const validDocExtensions = data.requirement?.documentType?.map((d) => d.extension);
  const { selectedFile, setTableDataForSave } = useAxCalcStore();
  const { tasksRoadmapSelected } = useAxRoadmapStore();

  async function handleEdit() {
    let currentFile: File = null;
    if (description?.length > 120) {
      return setInvalidDesc.on();
    } else {
      setInvalidDesc.off();
    }
    if (data.requirement.documentType[0].name === 'Ax Calc') {
      try {
        const file = await setTableDataForSave();
        if (file) {
          currentFile = file;
        }
      } catch (error) {
        console.error('Error saving table data:', error);
      }
    } else {
      if (tasksRoadmapSelected.length !== 0) {
        const jsonData = JSON.stringify(tasksRoadmapSelected)
        currentFile = new File([jsonData], "roadMap.json", { type: "application/json" })
      }
    }

    if (!currentFile) {
      if (data.requirement.documentType[0].name === 'Ax RoadMap') {
        return toast.error('Debes cargar actividades');
      }
      return toast.error(t("mustUploadFile"));
    }

    const fileExtension = currentFile.name.split(".").reverse()[0]?.toLowerCase() ?? "";
    const documentTypeId = data.requirement?.documentType?.find((d) => d.extension?.toLowerCase() === fileExtension.toLowerCase())?.id;

    if (data.type === "document" && !validDocExtensions.includes(fileExtension)) return toast.error(t("invalidFileType"));

    const formData = new FormData();
    formData.append("file", currentFile);

    const blobBody = {
      file: formData,
    };

    const res = await uploadDocumentToSession(blobBody);
    if (res.uploaded) {
      const docRes = await edditDocument({
        b64File: res.response
      }, data?.biddingId, data.docId)
      if (docRes.uploaded) {
        toast.success(t("uploadedFileSuccessfully"));
      } else {
        if (docRes.fileExists) {
          return toast.error(t("fileAlreadyExists"));
        } else {
          toast.error(t("uploadingFileError"));
        }
      }
      data.afterUpload();
      onClose();
    }
  }
  async function handleCreate() {
    let currentFile: File = null;
    if (description?.length > 120) {
      return setInvalidDesc.on();
    } else {
      setInvalidDesc.off();
    }
    if (data.requirement.documentType[0].name === 'Ax Calc') {
      try {
        const file = await setTableDataForSave();
        if (file) {
          currentFile = file;
        }
      } catch (error) {
        console.error('Error saving table data:', error);
      }
    } else {
      if (tasksRoadmapSelected.length !== 0) {
        const jsonData = JSON.stringify(tasksRoadmapSelected)
        currentFile = new File([jsonData], "roadMap.json", { type: "application/json" })
      }
    }

    if (!currentFile) {
      if (data.requirement.documentType[0].name === 'Ax RoadMap') {
        return toast.error('Debes cargar actividades');
      }
      return toast.error(t("mustUploadFile"));
    }

    const fileExtension = currentFile.name.split(".").reverse()[0]?.toLowerCase() ?? "";
    const documentTypeId = data.requirement?.documentType?.find((d) => d.extension?.toLowerCase() === fileExtension.toLowerCase())?.id;

    if (data.type === "document" && !validDocExtensions.includes(fileExtension)) return toast.error(t("invalidFileType"));

    const formData = new FormData();
    formData.append("file", currentFile);

    const blobBody = {
      file: formData,
    };

    const res = await uploadDocumentToSession(blobBody);

    const bodyWithNoBase64 = {
      year: data.year,
      month: data.month,
      b64File: res?.response,
      description,
      documentTypeId,
    };

    if (res.uploaded) {
      const docRes = await uploadDocument(bodyWithNoBase64, data?.biddingId, data?.requirement?.uuid)

      if (docRes.uploaded) {
        toast.success('Cambios guardados con éxito');
      } else {
        if (docRes.fileExists) {
          return toast.error(t("fileAlreadyExists"));
        } else {
          toast.error(t("uploadingFileError"));
        }
      }

      data.afterUpload();
      onClose();
    } else {
      if (res.fileExists) {
        return toast.error(t("fileAlreadyExists"));
      } else {
        toast.error(t("uploadingFileError"));
      }
    }
  }

  return (
    <CommonModal size="6xl" isOpen={isOpen} onClose={onClose}>
      <div className="w-full  space-y-6 py-4">
        <div>
          <h4 className="text-center text-gray-600 font-semibold">{data.edit ? 'EDITAR' : 'SUBIR'} {data.type === "annex" ? "ANEXO" : "DOCUMENTO"}</h4>
          <h2 className="title-3 text-center">{data?.requirement?.name}</h2>
        </div>
        {!data.edit && <FormControl isInvalid={invalidDesc}>
          <label htmlFor="file_description" className="text-sm leading-8">
            {t("description")}
          </label>
          <InputGroup borderColor="gray.400">
            <Input
              id="file_description"
              type="text"
              rounded="none"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </InputGroup>
          {invalidDesc && <span className="text-xs italic text-red-400">{t("max120Chars")}</span>}
        </FormControl>}
        {
          data.requirement.documentType[0].name === 'Ax Calc' ?
            <BiddingAxCalc />
            :
            <BiddingAxRoadmap />

        }
        <div className="mt-8 text-center">
          <Button
            rounded={"full"}
            size={"md"}
            fontWeight={"medium"}
            px={"2rem"}
            isLoading={false}
            onClick={data.edit ? handleEdit : handleCreate}
          >
            {data.edit ? 'Guardar cambios' : `${t("upload")} documento`}
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};

export default UploadBiddingAxCalcModal;
