import { DataGroup } from "../../models/DataGroup.models";
import ToggableButton from "../common/buttons/ToggableButton";

const DataGroupList = ({ dg, onClick }: { dg: Partial<DataGroup>; onClick: (name: string, idName: string) => any }) => {
    return (
        <div className="mt-2">
            <h3 className="text-base font-medium text-dark pl-1">- {dg.name}</h3>
            <div className="w-full flex flex-wrap gap-2 mt-2">
                {dg.types?.map((type) => (
                    <ToggableButton
                        key={type.idName}
                        toggable={false}
                        selected={true}
                        size="sm"
                        label={type.name}
                        onSelect={() => onClick(type.name, type.idName)}
                    />
                ))}
            </div>
        </div>
    );
};

export default DataGroupList;
