import { Button } from "@chakra-ui/button";
import { FormControl } from "@chakra-ui/form-control";
import { Input, InputGroup } from "@chakra-ui/input";
import { Select, useBoolean } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useCustomToast from "../../../hooks/useCustomToast";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { DataTypesTypes } from "../../../models/DataType.models";
import useDataTypesState from "../../../store/dataTypesState";
import CommonModal from "../CommonModal";
import { format } from "date-fns";
import { IoTrashOutline } from "react-icons/io5";
import { IoMdAddCircleOutline } from "react-icons/io";

type Props = {
    isOpen: boolean;
    onClose: (...args: any) => any;
    data?: any;
};

type FormatoHora = "short" | "medium" | "long" | "full";

const NewDataTypeModal = ({ isOpen, onClose }: Props) => {
    const toast = useCustomToast();
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];
    const createDataType = useDataTypesState((s) => s.createDataType);
    const { formatPatterns, getNumberFormatPatterns, getDateFormatPatterns } = useDataTypesState();

    const [name, setName] = useState("");
    const [type, setType] = useState<DataTypesTypes>("string");
    const [optionsFormat, setOptionsFormat] = useState("");
    const [invalidName, setInvalid] = useBoolean(false);
    const [selectableOptions, setSelectableOptions] = useState<string[]>([]);
    const [questionCount, setQuestionCount] = useState(0);
    const [patternValue, setPatternValue] = useState("");
    const [numberType, setNumberType] = useState("");

    const handleCreate = async () => {
        if (!name.length) {
            return setInvalid.on();
        } else {
            setInvalid.off();
        }

        onClose();
        const savedType = await createDataType(name, type, patternValue, selectableOptions);
        if (savedType) {
            toast.success(t("createdDataTypeSuccessfully"));
        } else {
            toast.error(t("creatingDataTypeError"));
        }
    };

    const addQuestion = () => {
        setQuestionCount(questionCount + 1);
        setSelectableOptions([...selectableOptions, ``]);
    };

    const removeQuestion = (index) => {
        setQuestionCount(questionCount - 1);
        setSelectableOptions(selectableOptions.filter((_, i) => i !== index));
    };

    const handleInputChange = (index, value) => {
        const updatedQuestions = [...selectableOptions];
        updatedQuestions[index] = value;
        setSelectableOptions(updatedQuestions);
    };

    const handlePatternChange = (e) => {
        setPatternValue(e.target.value);
    };

    useEffect(() => {
        if (type === "selectable") {
            addQuestion();
        } else {
            setSelectableOptions([]);
        }
    }, [type]);

    useEffect(() => {
        if (type === "date") {
            getDateFormatPatterns();
            setPatternValue("");
        }
        if (type === "number") {
            getNumberFormatPatterns();
            setPatternValue("");
        }
    }, [type]);

    console.log("patterns: ", formatPatterns);
    console.log("pattern seleccionado es:", patternValue);

    return (
        <CommonModal size="sm" isOpen={isOpen} onClose={onClose}>
            <div className="w-full flex flex-col items-center space-y-6 py-4">
                <h2 className="title-3">{t("newDataType")}</h2>
                <form className="w-10/12 flex flex-col items-center" onSubmit={(e) => e.preventDefault()}>
                    <FormControl isInvalid={invalidName}>
                        <label htmlFor="data_name" className="text-sm leading-8">
                            {t("name")}
                        </label>
                        <InputGroup borderColor="gray.400">
                            <Input id="data_name" type="text" rounded="none" value={name} onChange={(e) => setName(e.target.value)} />
                        </InputGroup>
                    </FormControl>

                    <FormControl className="mt-4">
                        <label htmlFor="data_type" className="text-sm leading-8">
                            {t("type")}
                        </label>
                        <Select
                            id="data_type"
                            size="md"
                            rounded="none"
                            value={type}
                            onChange={(e) => setType(e.target.value as DataTypesTypes)}
                        >
                            <option value="string">{t("text")}</option>
                            <option value="number">{t("numeric")}</option>
                            {/* <option value="boolean">Bandera</option> */}
                            <option value="date">{t("date")}</option>
                            <option value="selectable">{t("selectable")}</option>
                        </Select>
                    </FormControl>

                    {/*type === "date" && (
                <FormControl className="mt-4">
                    <label htmlFor="data_type" className="text-sm leading-8">
                        Tipo de formato
                    </label>
                    <Select id="data_type" size="md" rounded="none" value={patternValue} onChange={(e) => handlePatternChange(e)}>
                        {formatPatterns &&
                            formatPatterns.datePatternList
                                .filter(pattern => pattern.namePattern !== "Default") // Filtrando el patrón "Default"
                                .map((pattern, index) => (
                                    <option key={index} value={pattern.pattern}>
                                        {pattern.namePattern} ({pattern.descriptionPattern})
                                    </option>
                                ))}
                    </Select>
                </FormControl>
            )*/}

              
                        {type === "date" && (
                            <FormControl className="mt-4">
                                <label htmlFor="data_type" className="text-sm leading-8">
                                    Tipo de formato
                                </label>
                                <Select id="data_type" size="md" rounded="none" value={patternValue} onChange={(e) => handlePatternChange(e)}>
                                    <option value="" disabled>Elige una opción</option>
                                    {formatPatterns &&
                                        formatPatterns?.datePatternList?.map((pattern) => {
                                            return (
                                                <option key={pattern?.pattern} value={pattern?.pattern}>
                                                    {pattern?.namePattern} ({pattern?.descriptionPattern})
                                                </option>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        )}

                        {type === "number" && (
                            <FormControl className="mt-4">
                                <label htmlFor="data_type" className="text-sm leading-8">
                                    Tipo de formato
                                </label>
                                <Select id="data_type" size="md" rounded="none" value={patternValue} onChange={(e) => handlePatternChange(e)}>
                                    <option value="" disabled>Elige una opción</option>
                                    {formatPatterns &&
                                        formatPatterns?.numericPatternList?.map((pattern) => {
                                            return (
                                                <option key={pattern?.pattern} value={pattern?.pattern}>
                                                    {pattern?.namePattern} ({pattern?.descriptionPattern})
                                                </option>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        )}


                    {type === "selectable" && (
                        <div className="overflow-y-auto max-h-72 mt-4">
                            <p className="font-bold text-blue-700 text-lg mb-2">Opciones:</p>

                            {selectableOptions.map((question, index) => (
                                <div key={index} className="flex gap-5 items-center mb-3">
                                    <label htmlFor={`question${index}`} className="font-bold text-blue-700 text-lg">
                                        {index + 1}.
                                    </label>
                                    <div className="w-7/12">
                                        <Input
                                            type="text"
                                            id={`question${index}`}
                                            value={question}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                            placeholder="Escribe la opción"
                                        />
                                    </div>

                                    <div className="w-2/12 flex">
                                        <div className="w-1/2">
                                            <button type="button" onClick={() => removeQuestion(index)}>
                                                <IoTrashOutline size={25} />
                                            </button>
                                        </div>
                                        <div className="w-1/2 ml-2">
                                            {index + 1 === questionCount && (
                                                <button type="button" onClick={addQuestion}>
                                                    <IoMdAddCircleOutline size={25} color="green" />
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {/*type === "date" ? <FormatoFecha formatDate={setOptionsFormat} /> : "" */}
                    {/* type === "double" ? <FormatoNumero /> : "" */}

                    <div className="mt-10">
                        <Button
                            type="button"
                            rounded="full"
                            size="md"
                            fontWeight="medium"
                            px={"2rem"}
                            isLoading={false}
                            onClick={handleCreate}
                        >
                            {t("add")}
                        </Button>
                    </div>
                </form>
            </div>
        </CommonModal>
    );
};

const FormatoFecha = ({ formatDate }) => {
    const [patronSeleccionado, setPatronSeleccionado] = useState("default");
    const [fechaFormateada, setFechaFormateada] = useState("");

    useEffect(() => {
        // Definir un objeto de tipo Intl.DateTimeFormatOptions
        const opcionesDeFormato: Intl.DateTimeFormatOptions = {};

        // Configurar opciones de formato según el patrón seleccionado
        if (patronSeleccionado === "numeric") {
            opcionesDeFormato.year = "numeric";
            opcionesDeFormato.month = "numeric";
            opcionesDeFormato.day = "numeric";
        } else if (patronSeleccionado === "long") {
            opcionesDeFormato.year = "numeric";
            opcionesDeFormato.month = "long";
            opcionesDeFormato.day = "numeric";
        } else if (patronSeleccionado === "short") {
            opcionesDeFormato.year = "2-digit";
            opcionesDeFormato.month = "2-digit";
            opcionesDeFormato.day = "2-digit";
        }

        // Función para formatear la fecha
        function formatearFecha() {
            const fecha = new Date();
            const fechaFormateada = fecha.toLocaleDateString("es-ES", opcionesDeFormato);
            setFechaFormateada(fechaFormateada);
            formatDate(opcionesDeFormato);
        }

        // Llamar a la función para formatear la fecha
        formatearFecha();
    }, [patronSeleccionado]);

    return (
        <>
            <FormControl className="mt-4">
                <label htmlFor="data_type" className="text-sm leading-8">
                    {"Selecciona un formato de fecha"}
                </label>
                <Select
                    size="md"
                    rounded="none"
                    id="formatoFecha"
                    value={patronSeleccionado}
                    onChange={(e) => setPatronSeleccionado(e.target.value)}
                >
                    <option value="default">Por defecto</option>
                    <option value="numeric">Numérico</option>
                    <option value="long">Largo</option>
                    <option value="short">Corto</option>
                </Select>
                <label htmlFor="data_type" className="text-sm leading-8">
                    Ejemplo :{fechaFormateada}
                </label>
            </FormControl>
        </>
    );
};

const FormatoNumero = () => {
    const [tipoPatron, setTipoPatron] = useState("default");
    const [tipoMoneda, setTipoMoneda] = useState("USD");
    const [numeroFormateado, setNumeroFormateado] = useState("");

    const formatearNumero = () => {
        const numero = 1000000;
        let formato;

        if (tipoPatron === "numeric") {
            formato = new Intl.NumberFormat("es-ES").format(numero);
        } else if (tipoPatron === "currency") {
            formato = new Intl.NumberFormat("es-ES", { style: "currency", currency: tipoMoneda }).format(numero);
        }

        setNumeroFormateado(formato);
    };

    return (
        <div>
            <label htmlFor="tipoPatron">Selecciona un tipo de patrón:</label>
            <select id="tipoPatron" value={tipoPatron} onChange={(e) => setTipoPatron(e.target.value)}>
                <option value="default">Por defecto</option>
                <option value="numeric">Numérico</option>
                <option value="currency">Moneda</option>
            </select>

            {tipoPatron === "currency" && (
                <div>
                    <label htmlFor="tipoMoneda">Selecciona una moneda:</label>
                    <select id="tipoMoneda" value={tipoMoneda} onChange={(e) => setTipoMoneda(e.target.value)}>
                        <option value="USD">Dólar estadounidense (USD)</option>
                        <option value="EUR">Euro (EUR)</option>
                        <option value="JPY">Yen japonés (JPY)</option>
                    </select>
                </div>
            )}

            <button onClick={formatearNumero}>Formatear Número</button>
            <p>Número formateado: {numeroFormateado}</p>
        </div>
    );
};

export default NewDataTypeModal;
