import { Input, InputGroup, InputLeftElement } from "@chakra-ui/input";
import { Tooltip } from '@chakra-ui/react'
import { BiFilter } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";

const FilterInput = ({ onChangeInput, disabled = false, valueInput, label,clearValue }: { onChangeInput?: any; disabled?: boolean, valueInput?: any, label?: string ,clearValue?:boolean}) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    if(clearValue){
        valueInput="";
    }
    return (
        <div>
            <Tooltip hasArrow label={label} bg='gray.300' color='black'>
                <InputGroup>
                    <InputLeftElement h={8} color="gray.400" pointerEvents="none" children={
                            <BiFilter color="inherit" size={20} />
                    }/>
                    <Input 
                        disabled={disabled} 
                        value={valueInput} 
                        size={"sm"} 
                        rounded="full" 
                        placeholder={t("filter")} 
                        onChange={onChangeInput} 
                        bg="white" 
                    />
                </InputGroup>
            </Tooltip>
        </div>
    );
};

export default FilterInput;
