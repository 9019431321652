import { Button } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import { SignerCertificate } from "../../../models/Signatures.models";
import {memo} from "react";

interface SignerCardProps {
    userName: string;
    userId: string;
    idx: number;
    userCertificate: SignerCertificate;
    tempNames: any[];
    isOriginal?: boolean;
    sizeSigners: number;
    sizeTempSigners: number;
    tagsCount?: number;
    isAddedTag: boolean;
    isAvailable: boolean;
    onGenerateTag: (name: string, userId: string, certificate: SignerCertificate, idx: number) => void;
    onSavePosition: (name: string, idx: number, userId: string) => void;
    onAddNewSigner: (name: string, userId: string, certificate: SignerCertificate, idx: number) => void;
}

function SignerCard({
    userName,
    userId,
    userCertificate,
    idx,
    tempNames,
    sizeSigners,
    sizeTempSigners,
    isOriginal,
    tagsCount = 1,
    isAddedTag,
    isAvailable,
    onGenerateTag,
    onSavePosition,
    onAddNewSigner,
}: SignerCardProps) {
    const t : (key: TranslationKeys) => string = useTranslation("global")[0];
    const verifyUserSticker = (name: any, idx: any) => {
        return Boolean(tempNames?.find((a) => a.name === name && a.id === idx));
    };

    const verifyUserStickerIsOriginal = (idx: any) => {
        return Boolean(tempNames.find((a) => a.id === idx && a.create === true));
    };

    const showAddTagButton = !isOriginal && verifyUserStickerIsOriginal(idx) && sizeTempSigners >= sizeSigners;

    if (verifyUserSticker(userName, idx) && isAddedTag) return null;

    return (
        <div className="w-full max-w-[350px] bg-white border-2 border-brand-500 rounded-lg p-2 flex flex-col gap-2">
            <div className="text-lg font-medium text-brand-600 flex items-center justify-between">
                <span className="w-10/12">{userName}</span>
                {!isOriginal && verifyUserStickerIsOriginal(idx) && (
                    <div className="h-4 w-4 text-sm font-medium text-white bg-brand-400 rounded-full p-3 flex items-center justify-center">
                        {tagsCount}
                    </div>
                )}
            </div>
            {showAddTagButton && (
                <Button
                    variant="solid"
                    size="sm"
                    colorScheme="green"
                    leftIcon={<FaPlus size={16} color="white" />}
                    onClick={() => onAddNewSigner(userName, userId, userCertificate, idx)}
                    disabled={isOriginal}
                >
                    {t("addSignature")}
                </Button>
            )}

            {!showAddTagButton && (
                <Button
                    variant="solid"
                    size="sm"
                    onClick={() => onGenerateTag(userName, userId, userCertificate, idx)}
                    disabled={verifyUserSticker(userName, idx)}
                    isLoading={!isAvailable}
                >
                    {t("generateSignature")}
                </Button>
            )}

            {!showAddTagButton && (
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onSavePosition(userName, idx, userId)}
                    disabled={verifyUserSticker(userName, idx)}
                >
                    {t("savePosition")}
                </Button>
            )}
        </div>
    );
};

export default memo(SignerCard);
