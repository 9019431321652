import { useTranslation } from "react-i18next";
import { TranslationKeys } from "src/i18n/en";
import Steps from "../common/Steps";

const ContractSteps = ({ currentItem, stepTemplate }: { currentItem: number, stepTemplate?: string }) => {
    const t: (key: TranslationKeys) => string = useTranslation("global")[0];

    // const labels = [t("generateContract"), t("revisions"), t("inVault"), t("signatures"), t("tokenizado")];


    let labels;

    switch (stepTemplate) {
        case "borrador":
            labels = [t("borrador")];
            break;
        case "aprobado":
            labels = [t("generateContract"), t("revisions")];
            break;
        case "en_boveda":
            labels = [t("generateContract"), t("revisions"), t("inVault")];
            break;
        case "firmado":
            labels = [t("generateContract"), t("revisions"), t("inVault"), t("signatures")];
            break;
        case "firmado":
            labels = [t("generateContract"), t("revisions"), t("inVault"), t("signatures"), t("tokenizado")]                    
            break;
        // Por defecto, si no coincide con ninguno de los casos anteriores
        default:
            labels = [t("generateContract"), t("revisions"), t("inVault"), t("signatures"), t("tokenizado")];
    }
    return <Steps labels={labels} currentItem={currentItem} stepTemplate={stepTemplate} className="w-full md:w-10/12 lg:w-8/12 mx-auto" />;
};

export default ContractSteps;
