import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { BiChevronRight } from "react-icons/bi";
import SlicedText from "../common/SlicedText";
import VertorUp from "../../assets/bidding-icons/VectorUp.png";
import VertorDown from "../../assets/bidding-icons/VectorDown.png";

const StageButton = ({
    icon,
    text,
    onClick,
    selected,
    percentage,
    disabled,
}: {
    icon: JSX.Element;
    text: string;
    onClick: () => void;
    selected: boolean;
    percentage?: number;
    disabled?: boolean;
}) => {
    const roundedPercentage = Math.round(percentage || 0);
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`w-full h-12 min-w-[277px] border-6 px-2 flex rounded-2xl items-center shadow-lg justify-between hover-border-brand relative ${
                selected ? "bg-sky-100" : "border-gray-400 bg-[#F4F4F4]"
            }`}
        >
            <div className={`flex items-center gap-4 ${selected ? "text-[#3557DB]" : "text-[#3557DB]"}`}>
                {/*icon*/}
                <p className="font-medium text-lg">
                    <SlicedText maxLength={24} text={text} />
                </p>
            </div>
            <div className="flex items-center gap-4">
                <CircularProgress value={roundedPercentage} color={selected ? "brand.500" : "gray.400"} size={"38px"} thickness="8px">
                    <CircularProgressLabel fontSize="10px">{roundedPercentage}%</CircularProgressLabel>
                </CircularProgress>

                {selected ? (
                    <img src={VertorUp} className="w-2" alt="vector up"></img>
                ) : (
                    <img src={VertorDown} className="w-4" alt="vector up"></img>
                )}
            </div>
        </button>
    );
};

export default StageButton;
