import { Button, useBoolean } from "@chakra-ui/react";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";

type Props = {
    text: string;
    onClick?: (...args: any) => any;
    sortable?: boolean;
};

const CommonTh = ({ text, onClick = () => {}, sortable = false }: Props) => {
    const [active, setActive] = useBoolean();

    const handleClick = () => {
        setActive.toggle();
        onClick();
    };
    return (
        <button className="flex items-center font-bold" onClick={handleClick}>
            <span className="mr-1">{text}</span>
            {sortable && (!active ? <GoTriangleDown size={14} /> : <GoTriangleUp size={14} />)}
        </button>
    );
};

export default CommonTh;
