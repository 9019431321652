import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import useModalState from "../modalsState";
//import contractService from "../services/contract.service";
import componentsService from "src/services/compoments/components.service";
import useGlobalState from "../globalState";

interface State {
    loadUiOptions : boolean;
    setLoadUiOptions : (loaded : any , withLoading?: boolean) => any;
    uiTabOptions: {
        showTabAdmin: boolean;
        showTabContract: boolean;
        showTabProcess: boolean;
        showTabTemplates: boolean;
    };
    uiBtnAdminOptions: {
        showBtnConfig: boolean;
        showBtnBpmn: boolean;
        showBtnCompany: boolean;
        showBtnGroupUsers: boolean;
        showBtnRols: boolean;
        showBtnUsers: boolean;
    };
    uiBtnProcessOptions: {
        showBtnCreateNewDocument: boolean;
        showBtnCreateNewProcess: boolean;
        showBtTypeProcces: boolean;
    };
    uiBtnTemplateOptions: {
        showBtnGroupUsers: boolean;
        showBtnNewTemplate: boolean;
        showBtnTypesDynamicDocument: boolean;
    };
    uiBtnGeneradoresOptions: {
        showBtnModalGeneradorStatus: boolean;
    };
    getAllTabs : (withLoading?: boolean) => Promise<any>;
    getAllBtnProcess : (withLoading?: boolean) => Promise<any>;
    getAllBtnAdmin : (withLoading?: boolean) => Promise<any>;
    getAllBtnTemplate : (withLoading?: boolean) => Promise<any>;
    getAllBtnGeneradores : (withLoading?: boolean) => Promise<any>;
    reset: () => void;
}

const initialState = {
    loadUiOptions: false,
  uiTabOptions: {
        showTabAdmin: false,
        showTabContract: false,
        showTabProcess:  false,
        showTabTemplates: false,
    },
    uiBtnAdminOptions: {
        showBtnConfig: false,
        showBtnBpmn: false,
        showBtnCompany: false,
        showBtnGroupUsers: false,
        showBtnRols: false,
        showBtnUsers: false,
    },
    uiBtnProcessOptions: {
        showBtnCreateNewDocument: false,
        showBtnCreateNewProcess: false,
        showBtTypeProcces: false,
    },
    uiBtnTemplateOptions: {
        showBtnGroupUsers: false,
        showBtnNewTemplate: false,
        showBtnTypesDynamicDocument: false,
    },
    uiBtnGeneradoresOptions: {
        showBtnModalGeneradorStatus: false
    }
};

const useComponetState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            setLoadUiOptions : setLoadUiOptions(set,get),
            getAllTabs: getAllTabs(set , get),
            getAllBtnProcess: getAllBtnProcess(set , get),
            getAllBtnAdmin : getAllBtnAdmin(set , get),
            getAllBtnTemplate : getAllBtnTemplate(set , get),
            getAllBtnGeneradores : getAllBtnGeneradores(set ,get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "componetState" }
    )
);

export default useComponetState;

/* -------------------------------------------------------------------------- */
/*                              SETTERS FUNCTIONS                             */
/* -------------------------------------------------------------------------- */

/* MODULE ADMIN BTN PERMISSIONS  */

function getTabAdmin(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const res = await componentsService.getPermissionTabAdmin();

        if (res?.data?.codigo === 0 || res?.data?.respuesta === "ok") {
            return true;
        } else {
            console.log("Error fetching  tap admin");
            return false;
        }
    };
}

function getTabContract(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const user =  useGlobalState.getState().userInfo.data;
        const res = await componentsService.getPermissionTabContract();
        if (res?.data?.codigo === 0 ) {
            return true;
        }else{
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getTabProcess(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const res = await componentsService.getPermissionTabProcess();
        if (res?.data?.codigo === 0) {
            return true;
        } else {
            console.log("Error fetching process");
            return false;
        }
    };
}

function getTabTemplates(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const user =  useGlobalState.getState().userInfo.data;
        const res = await componentsService.getPermissionTabTemplates();

        if (res?.data?.codigo === 0 ) {
            return true;
        }else{
            console.log("Error fetching contract");
            return false;
        }
    };
}

/* MODULE PROCESS BTN PERMISSIONS   */
function getBtnTypeProcess(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const user =  useGlobalState.getState().userInfo.data;
        const res = await componentsService.getPermissionBtnCreateNewTypeProcces();

        if (res?.data?.codigo === 0) { 
            return true;
        }else{
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getBtnNewProcess(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
    
        const res = await componentsService.getPermissionBtnCreateNewProcces();
        if (res?.data?.codigo === 0) { 
            return true;
        } else {
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getBtnCreateDocument(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
    
        const res = await componentsService.getPermissionBtnCreateDynamicDocument();
        if (res?.data?.codigo === 0) { 
            return true;
        } else {
            console.log("Error fetching contract");
            return false;
        }
    };
}

/* MODULE ADMIN BTN PERMISSIONS */

function getBtnAdminConfig(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const user =  useGlobalState.getState().userInfo.data;
        const res = await componentsService.getPermissionBtnConfig();

        if (res?.data?.codigo === 0) {
            return true;
        }else{
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getBtnAdminBpmn(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const user =  useGlobalState.getState().userInfo.data;
        const res = await componentsService.getPermissionBtnBpmn();

        if (res?.data?.codigo === 0) { 
            return true;
        }else{
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getBtnAdminCompany(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
    
        const res = await componentsService.getPermissionBtnCompany();
        if (res?.data?.codigo === 0) { 
            return true;
        } else {
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getBtnAdminGroupUser(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
    
        const res = await componentsService.getPermissionBtnGroupUser();
        if (res?.data?.codigo === 0) { 
            return true;
        } else {
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getBtnAdminRols(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const user =  useGlobalState.getState().userInfo.data;
        const res = await componentsService.getPermissionBtnRols();

        if (res?.data?.codigo === 0) { 
            return true;
        }else{
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getBtnAdminUser(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const user =  useGlobalState.getState().userInfo.data;
        const res = await componentsService.getPermissionBtnUsers();

        if (res?.data?.codigo === 0) { 
            return true;
        }else{
            console.log("Error fetching contract");
            return false;
        }
    };
}

/* MODULE PROCESS BTN PERMISSIONS   */

function getBtnTemplateGroupsData(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
        const user =  useGlobalState.getState().userInfo.data;
        const res = await componentsService.getPermissionBtnTemplateGroupData();

        if (res?.data?.codigo === 0) { 
            return true;
        }else{
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getBtnNewTemplate(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
    
        const res = await componentsService.getPermissionBtnNewTemplate();
        if (res?.data?.codigo === 0) { 
            return true;
        } else {
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getBtnTypeDocuments(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
    
        const res = await componentsService.getPermissionBtnTypeDinamicDocuments();
        if (res?.data?.codigo === 0) { 
            return true;
        } else {
            console.log("Error fetching contract");
            return false;
        }
    };
}

function getMovilTest(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
    
        const res = await componentsService.getTest();
        if (res?.data?.codigo === 0) { 
            return true;
        } else {
            console.log("Error fetching contract");
            return false;
        }
    };
}
/**Generadores Btn */

function getBtnModalStatusGenerador(set: NamedSet<State>, get: GetState<State>) {
    return async () => {
    
        const res = await componentsService.getPermissionBtnModalStatusGenerador();
        if (res?.data?.codigo === 0) { 
            return true;
        } else {
            console.log("Error fetching contract");
            return false;
        }
    };
}


/**Get Tabs User TopBar */

function getAllTabs(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);
        const [tabAdmin, tabContract, tabProcess, tabTemplates] = await Promise.all([
            getTabAdmin(set, get)(),
            getTabContract(set, get)(),
            getTabProcess(set, get)(),
            getTabTemplates(set, get)()
        ]);
        set((state: State) => ({
            ...state,
            uiTabOptions: {
                showTabAdmin: !!tabAdmin,
                showTabContract: !!tabContract,
                showTabProcess: !!tabProcess,
                showTabTemplates: !!tabTemplates
            }
        }));

        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

/**Get BTN Generadores Process */


function getAllBtnGeneradores(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const [btnModalStatusGenerador] = await Promise.all([
            getBtnModalStatusGenerador(set, get)(),
    
        ]);
        set((state: State) => ({
            ...state,
            uiBtnGeneradoresOptions: {
                showBtnModalGeneradorStatus: !!btnModalStatusGenerador,
            }
        }));
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

/**Get BTN Module Process */

function getAllBtnProcess(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const [btnTypeProcess, btnNewProcess, btnCreateDocument] = await Promise.all([
            getBtnTypeProcess(set, get)(),
            getBtnNewProcess(set, get)(),
            getBtnCreateDocument(set, get)(),
        ]);
        set((state: State) => ({
            ...state,
            uiBtnProcessOptions: {
                showBtnCreateNewDocument: !!btnCreateDocument,
                showBtTypeProcces: !!btnTypeProcess,
                showBtnCreateNewProcess: !!btnNewProcess,
            }
        }));
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

/**Get BTN Module Admin */

function getAllBtnAdmin(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const [btnAdminConfig,btnAdminBpmn,btnAdminCompany,btnAdminGroupUser,btnAdminRols,btnAdminUser] = await Promise.all([
            getBtnAdminConfig(set, get)(),
            getBtnAdminBpmn(set, get)(),
            getBtnAdminCompany(set, get)(),
            getBtnAdminGroupUser(set, get)(),
            getBtnAdminRols(set, get)(),
            getBtnAdminUser(set, get)(),
        ]);
        set((state: State) => ({
            ...state,
            uiBtnAdminOptions: {
                showBtnConfig: !!btnAdminConfig,
                showBtnBpmn: !!btnAdminBpmn,
                showBtnCompany: !!btnAdminCompany,
                showBtnGroupUsers: !!btnAdminGroupUser,
                showBtnRols: !!btnAdminRols,
                showBtnUsers: !!btnAdminUser,
            }
        }));
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}


function getAllBtnTemplate(set: NamedSet<State>, get: GetState<State>) {
    return async (withLoading = true) => {
        withLoading && useModalState.getState().setLoaderModal(true);

        const [btnTemplateGroupsData,btnNewTemplate,btnTypeDocuments] = await Promise.all([
            getBtnTemplateGroupsData(set, get)(),
            getBtnNewTemplate(set, get)(),
            getBtnTypeDocuments(set, get)(),
            ,
        ]);
        set((state: State) => ({
            ...state,
            uiBtnTemplateOptions: {
                showBtnGroupUsers:  !!btnTemplateGroupsData,
                showBtnNewTemplate:!!btnNewTemplate,
                showBtnTypesDynamicDocument: !!btnTypeDocuments,
            }
        }));
        withLoading && useModalState.getState().setLoaderModal(false);
    };
}

function setLoadUiOptions(set: NamedSet<State>, get: GetState<State>) {
    return async (loaded) => {
            set({ loadUiOptions: loaded })
    };
}