import { useEffect, useMemo } from "react";
import useCustomToast from "../../../hooks/useCustomToast";
import useContractRevisionState from "../../../store/contractRevisionState";
import { getColorFromStr } from "../../../lib/utils";
import UserIcon from "../../icons/UserIcon";
import { UserRevisor } from "../../../models/Contract.models";
import { BsXCircleFill, BsCheckCircleFill, BsClockFill } from "react-icons/bs";
import { Tooltip } from "@chakra-ui/react";
import useContractSignaturesState from "../../../store/contractSignatureState";
import { Signer } from "../../../models/Signatures.models";

type ItemProps = {
    user: Signer;
};
const Item = ({ user }: ItemProps) => {
    return (
        <li className="flex items-center text-gray-500 py-4">
            <UserIcon boxSize={10} color={getColorFromStr(user.name?.slice(0, 6))} />
            <div className="ml-4">
                <div className="text-brand-500 text-lg font-medium">{user.name}</div>
                <div className="text-gray-500 text-xs leading-none font-light">{user.email}</div>
            </div>
            <div className="grow"></div>
            {!user.signed ? (
                <Tooltip label="Pendiente">
                    <div className="text-yellow-400">
                        <BsClockFill size={32} color="inherit" />
                    </div>
                </Tooltip>
            ) : (
                <Tooltip label="Firmado">
                    <div className="text-green-600">
                        <BsCheckCircleFill size={32} color="inherit" />
                    </div>
                </Tooltip>
            )}
        </li>
    );
};

type Props = {
    documentId: string;
};

const SignaturesStatusList = ({ documentId }: Props) => {
    const toast = useCustomToast();
    const { currentSignaturesStatus, getSignatures, setCurrentSignaturesStatus } = useContractSignaturesState();

    useEffect(() => {
        getSignatures(documentId, false).then((res) => {
            if (!res) {
                toast.error("Error al obtener estatus de firmas");
            }
        });

        return () => setCurrentSignaturesStatus(null);
    }, [documentId]);

    const filteredSigners = useMemo(() => {
        const uniqueIds = [...new Set(currentSignaturesStatus?.signers?.map((signer) => signer.id))];
        const signers = uniqueIds.map((id) => currentSignaturesStatus?.signers?.find((signer) => signer.id === id));
        return signers;
    }, [currentSignaturesStatus]);

    return (
        <ul>
            {filteredSigners?.map((user) => (
                <Item key={user.id} user={user} />
            ))}
        </ul>
    );
};

export default SignaturesStatusList;
