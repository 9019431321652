import create, { GetState } from "zustand";
import { devtools, NamedSet } from "zustand/middleware";
import { Notification } from "../models/Notification.models";
import notificationsService from "../services/notifications.service";
import useAuthState from "./authState";
import useGlobalState from "./globalState";

interface State {
    notifications: Notification[];
    getNotifications: () => Promise<Notification[]>;
    markNotificationAsRead: (notification: Notification) => Promise<void>;
    reset: () => void;
}

const initialState = {
    notifications: [],
};

const useNotificationsState = create<State>(
    devtools(
        // ------------
        (set, get) => ({
            ...initialState,
            getNotifications: getNotifications(set, get),
            markNotificationAsRead: markNotificationAsRead(set, get),
            reset: () => set((state) => ({ ...state, ...initialState })),
        }),
        // ------------
        { name: "NotificationsState" }
    )
);

export default useNotificationsState;

//FUNCTIONS
// function setSelectedFile(set: NamedSet<State>, get: GetState<State>) {
//     return (file) => {
//         set({ selectedFile: file });
//     };
// }

// function setCurrentFolder(set: NamedSet<State>, get: GetState<State>) {
//     return (folder) => {
//         set({ currentFolder: { loading: false, error: false, data: folder } });
//     };
// }
let isInit = true;
let previousNotificationsSize = 0;

function getNotifications(set, get) {
    return async () => {
        const idUser = useAuthState.getState().userId;

        const body = {
            idApp: "SCM",
            idUser,
        };

        let res = null;

        const notificationSize = JSON.parse(sessionStorage.getItem('notificationSize'));

        if (isInit || (notificationSize > previousNotificationsSize)) {
            res = await notificationsService.getNotifications(body);
            
            previousNotificationsSize = notificationSize;
            isInit = false;
        } else {
            const notificationSizeRes = await notificationsService.getNotificationsSize(idUser, body.idApp);

            if (notificationSizeRes?.data?.codigo === 0) {
                const newNotificationSize = notificationSizeRes.data.respuesta;
                sessionStorage.setItem('notificationSize', newNotificationSize);

                if (notificationSize !== newNotificationSize) {
                    res = await notificationsService.getNotifications(body);
                    previousNotificationsSize = newNotificationSize;
                }
            }
        }

        if (res?.data?.codigo === 0) {
            set({ notifications: res.data.respuesta?.reverse() });
            return res.data.respuesta?.reverse();
        } else {
            return null;
        }
    };
}


function markNotificationAsRead(set: NamedSet<State>, get: GetState<State>) {
    return async (notification: Notification) => {
        notification.read = true;
        const idUser = useAuthState.getState().userId;
        const res = await notificationsService.updateNotification(notification);
        const body = {
            idApp: "SCM",
            idUser,
        };

        if (res?.data?.codigo === 0) {
            const notificationUnread = await notificationsService.getNotifications(body);
            set({ notifications: notificationUnread.data.respuesta?.reverse() });
        } else {
            console.log("Error updating notification");
            return null;
        }
    };
}
