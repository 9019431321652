import { Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Tooltip, useBoolean } from "@chakra-ui/react";
import { VaultFolderChildren } from "../../models/Vault.models";
import { BsThreeDots } from "react-icons/bs";
import throttle from "lodash/throttle";
import FolderVaultIcon from "../icons/iconsV2/FolderVaultIcon";
import FileVaultIcon from "../icons/iconsV2/FileVaultIcon";
import SlicedText from "../common/SlicedText";

type Props = {
    file: VaultFolderChildren;
    isSelectedFile: boolean;
    onOpen: (e: any, file: VaultFolderChildren) => void;
    onDelete: (file: VaultFolderChildren) => void;
    onSelect: (file: VaultFolderChildren) => void;
    onDownload: (file: VaultFolderChildren) => void;
};

const VaultItem = ({ file, isSelectedFile, onOpen, onDelete, onDownload, onSelect }: Props) => {
    const [isHovered, setIsHovered] = useBoolean(false);
    const isSelected = isSelectedFile || isHovered;

    const name = file.name + (file.fileType === "document" ? "." + file.extension?.replace(".", "") : "");
    const canBeOpen = file.fileType === "folder" || file.extension?.replace(".", "") === "pdf";
    const canBeDownloaded = file.fileType === "document";

    return (
        <button
            onClick={throttle(() => onSelect(file), 700)}
            onDoubleClick={(e) => onOpen(e, file)}
            onMouseOver={setIsHovered.on}
            onMouseLeave={setIsHovered.off}
            className={`"max-w-[12rem] w-full h-full flex flex-col items-center justify-between rounded-xl pt-4 pb-2 relative ${
                isSelected || isHovered ? "bg-[#E9F6FA]" : "bg-gray-light"
            }`}
        >
            <div className="w-full h-[75%] px-4">
                <div className="bg-white w-full h-full rounded-xl grid place-content-center relative">
                    {file.fileType === "folder" ? <FolderVaultIcon boxSize={10} /> : <FileVaultIcon boxSize={10} />}

                    {(isSelected || isHovered) && (
                        <div className="absolute top-0 right-2">
                            <Menu isLazy>
                                <MenuButton as={"button"} onDoubleClick={(e) => e.stopPropagation()}>
                                    <BsThreeDots size={24} />
                                </MenuButton>
                                <MenuList minW={"unset"}>
                                    {canBeOpen && <MenuItem onClick={(e) => onOpen(e, file)}>Abrir</MenuItem>}
                                    {canBeDownloaded && <MenuItem onClick={() => onDownload(file)}>Descargar</MenuItem>}
                                    <MenuDivider />
                                    <MenuItem onClick={() => onDelete(file)} color="red.500">
                                        Eliminar
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </div>
                    )}
                </div>
            </div>

            <div className="w-full flex items-center justify-between px-4">
                <div className="max-w-[80%] max-h-[16px] overflow-hidden text-sm text-gray-700 font-medium leading-tight text-center">
                    <SlicedText text={name} maxLength={14} />
                </div>

                <Button size="xs" onClick={(e) => onOpen(e, file)}>
                    Ver
                </Button>
            </div>
        </button>
    );
};

export default VaultItem;
